import React from 'react';
import TablaIngresos from './TablaIngresos';
//Redux
import { useSelector } from 'react-redux';
import TablaResumenGenerales from '../TablaResumenGenerales';
import BotonesDescargas from '../BotonesDescargas';
import { getFechaNormalizada } from '../../../../actions/finanzasReportesActions';

const ResultadoReporteIngreso = ({ datosBusqueda }) => {

  const reporteIngresos = useSelector(state => state.finanzasReportes.reporteIngresos);

  const handleUrlDescarga = (tipo) => {
    if (!datosBusqueda) return;  
    const { idProyecto, fechaInicio, fechaFin, idArea } = datosBusqueda;
    const fechaInicioConvertido = getFechaNormalizada(fechaInicio)
    const fechaFinConvertido = getFechaNormalizada(fechaFin);
    return `${process.env.REACT_APP_API_URL}/admin/${tipo}/reporte/ingresos/${idProyecto}/${fechaInicioConvertido}/${fechaFinConvertido}/${idArea}`;
  }

  return ( 
    <div className="ResultadoReporteIngreso mt-3">
      <BotonesDescargas
        handleUrlDescarga={handleUrlDescarga}
        pdf={true}
        excel={true}
      />
      <TablaIngresos
        tablas={reporteIngresos.tablas}
      />
      <div className="resumen-generales d-flex justify-content-between align-items-center my-4">
        <div className='d-flex'>
          <h4 className='title-head'>P. Valido: {reporteIngresos.cantidad_valido}</h4>
          <h4 className='title-head ml-5'>P. Anulados: {reporteIngresos.cantidad_anulado}</h4>
        </div>
        <h3 className="title-head mr-2">Total: S/. {reporteIngresos.total_ingreso}</h3>
      </div>
      <TablaResumenGenerales
        banco={reporteIngresos.subtotal_banco}
        cheque={reporteIngresos.subtotal_cheque}
        efectivo={reporteIngresos.subtotal_efectivo}
        tarjeta={reporteIngresos.subtotal_tarjeta}
        total={reporteIngresos.total_ingreso}
      />
    </div>
  );
}
 
export default ResultadoReporteIngreso;