import React from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { getColorEstadoPago } from "../../../../helpers/functions";
import * as moment from "moment";

//Redux
import { useDispatch } from "react-redux";
import {
  updateStateModalBasic,
  updateStateModalCustom,
} from "../../../../actions/modalActions";
import RegistroFacturaExtra from "../../../../containers/FinanzasContainers/RegistroFacturaExtra";
import {
  eliminarCuotaExtra,
  registrarDetallesPagos,
} from "../../../../actions/pagosActions";
import Swal from "sweetalert2";
import FormularioAnularPago from "../FormularioAnularPago";

const FilaInfoPagos = ({ index, extraordinario, idMatricula }) => {
  const {
    id_pago_extraordinario,
    concepto_pago,
    fecha_limite,
    fecha_pago,
    monto_concepto,
    nombre_tp_estado_pago,
  } = extraordinario;

  const dispatch = useDispatch();

  const handleModalExaminar = () => {
    dispatch(registrarDetallesPagos("extraordinario", id_pago_extraordinario));
    dispatch(
      updateStateModalCustom(
        <RegistroFacturaExtra />,
        "Examinar pagos extraordinarios",
        "modal-wl"
      )
    );
  };

  const handleEliminaCuota = () => {
    if (nombre_tp_estado_pago === "PAGADO") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No se puede eliminar esta cuota porque ha sido pagada.",
      });
      return;
    }
    dispatch(eliminarCuotaExtra(idMatricula, id_pago_extraordinario));
  };

  const handleAnularCuota = () => {
    dispatch(
      updateStateModalBasic(
        <FormularioAnularPago
          id={id_pago_extraordinario}
          tipo="extraordinario"
        />,
        "Anular Pago"
      )
    );
  };

  return (
    <tr>
      <td align="center">{index + 1}</td>
      <td align="center">{concepto_pago}</td>
      <td align="center">
        {fecha_limite && moment(fecha_limite).format("DD MMM YYYY")}
      </td>
      <td align="center">
        {fecha_pago && moment(fecha_pago).format("DD MMM YYYY")}
      </td>
      <td align="center">{monto_concepto}</td>
      <td align="center">
        <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`}>
          {nombre_tp_estado_pago}
        </Badge>
      </td>
      <td align="center">
        <DropdownButton
          as={ButtonGroup}
          variant="warning"
          title="Opciones"
          id="options-dropdown"
          size='xs'
        >
          <Dropdown.Item
            eventKey="1"
            onClick={handleModalExaminar}
            disabled={nombre_tp_estado_pago === "PAGADO" ? true : false}
          >
            Pagar
          </Dropdown.Item>
          {nombre_tp_estado_pago === "PAGADO" ? (
            <Dropdown.Item eventKey="1" onClick={handleAnularCuota}>
              Anular pago
            </Dropdown.Item>
          ) : (
            false
          )}
          <Dropdown.Item
            eventKey="2"
            disabled={nombre_tp_estado_pago === "PAGADO" ? true : false}
            onClick={handleEliminaCuota}
          >
            Eliminar
          </Dropdown.Item>
        </DropdownButton>
      </td>
    </tr>
  );
};

export default FilaInfoPagos;
