import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';
import EditarResumenCvWithTrabajador from '../../containers/TrabajadoresContainers/EditarResumenCvWithTrabajador';

const withResumenCv = WrappedComponent => props => {
  
  const [trabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleEditar = () => {
    dispatch( updateStateModalCustom(<EditarResumenCvWithTrabajador />, 'Editar Resumen de CV', 'modal-w30') )
  }

  return (
    <WrappedComponent
      {...props}
      resumenCv={trabajador.descripcion}
      handleEditar={handleEditar}
    />
   );
}
 
export default withResumenCv;