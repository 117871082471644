import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import './RegistroUsuarios.scss';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { listarAreasTrabajadores, obtenerListaClasificacionTrabajadores } from '../../../../actions/trabajadoresActions';

const RegistroUsuarios = ({ handleSubmitAction, docenteState, editar, areas }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { datos: listaClasificaciones = [] } = useSelector(state => state.trabajadores.clasificacion);
  const { todo: listaAreas = [] } = useSelector(state => state.trabajadores.areas);
  const docente = useSelector(state => state.profesores.profesor)
  const stateNuevoDocente = useSelector(state => state.profesores.nuevoDocente)
  const [datosDocente, setDatosDocente] = useState(initialState())

  const {
    nombre_usuario,
    usuario,
    password,
    apellido_usuario,
    fecha_nac,
    email,
    dni,
    direccion,
    provincia,
    departamento,
    distrito,
    celular,
    clasificacion,
    area
  } = datosDocente;

  const handleChange = e => {
    setDatosDocente({
      ...datosDocente,
      [e.target.name]: e.target.value
    })
  }

  const handleCloseModal = () => {
    dispatch( updateStateModalBasic() )
    setDatosDocente( initialState() )
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (nombre_usuario === '' || apellido_usuario === '' || usuario === ''
      || password === '' || dni === '') {
      avisoError('Faltan llenar campos obligatorios')
      return;
    }
    await handleSubmitAction(datosDocente)
    dispatch( updateStateModalBasic() )
  }

  useEffect(() => {
    if (stateNuevoDocente) {
      history.push(`/profesores/editar/${docente.id_docente}`) 
    }
  }, [stateNuevoDocente])

  useEffect(() => {
    if (docenteState) {
      setDatosDocente(docenteState)
    }
  }, [docenteState])

  useEffect(() => {
    dispatch(obtenerListaClasificacionTrabajadores());
    dispatch(listarAreasTrabajadores());
  }, [])

  return ( 
    <div className="registro-docente">
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="datos-personales__content">
          <Form.Group>
            <Form.Label className='label-required'>Nombre</Form.Label>
            <Form.Control type="text" name='nombre_usuario' value={nombre_usuario} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>Apellido</Form.Label>
            <Form.Control type="text" name='apellido_usuario' value={apellido_usuario} onChange={handleChange} />
          </Form.Group>
          <Form.Row >
            <Form.Group as={Col} md={6}>
              <Form.Label>Fecha de nacimiento</Form.Label>
              <Form.Control type="date" name='fecha_nac' value={fecha_nac} onChange={handleChange} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label className='label-required'>DNI</Form.Label>
              <Form.Control type="text" name='dni' value={dni} onChange={handleChange} />
            </Form.Group>
          </Form.Row>
          <Form.Group clasificacion='mt-3'>
            <Form.Label>Correo</Form.Label>
            <Form.Control type="email" name='email' value={email} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Dirección</Form.Label>
            <Form.Control type="text" name='direccion' value={direccion} onChange={handleChange} />
            <Form.Row className='mt-3'>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name='departamento'
                  placeholder='Departamento'
                  value={departamento}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name='provincia'
                  placeholder='Provincia'
                  value={provincia}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name='distrito'
                  placeholder='Distrito'
                  value={distrito}
                  onChange={handleChange}
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Celular</Form.Label>
            <Form.Control type="text" name='celular' value={celular} onChange={handleChange} />
          </Form.Group>
          {
            areas && <Form.Row>
              <Col md={6}>
                <Form.Label className='label-required'>Clasificación</Form.Label>
                <Form.Control as="select" name='clasificacion' defaultValue='' value={clasificacion} onChange={handleChange}>
                  <option disabled value=''>--Seleccionar--</option>
                  {
                    listaClasificaciones.map(clasificacion => (
                      <option
                        value={clasificacion.id_tp_clasificacion_usuario}
                        key={clasificacion.id_tp_clasificacion_usuario}
                      >
                        {clasificacion.nombre_clasificacion}
                      </option>
                    ))
                  }
                </Form.Control>
              </Col>
              <Col md={6}>
                <Form.Label className='label-required'>Área</Form.Label>
                <Form.Control as="select" defaultValue={''} name='area' value={area} onChange={handleChange}>
                  <option disabled value="">--Seleccionar--</option>
                  {
                    listaAreas.map(area => (
                      <option
                        value={area.id_tp_nivel_usuario}
                        key={area.id_tp_nivel_usuario}
                      >
                        {area.nombre_tp_nivel_usuario}
                      </option>
                    )) 
                  }
                </Form.Control>
              </Col>
            </Form.Row>
          }
          {
            !editar && <Form.Row className='mt-3'>
            <Col md={6}>
              <Form.Label className='label-required'>Usuario</Form.Label>
              <Form.Control type="text" name='usuario' value={usuario} onChange={handleChange} />
            </Col>
            <Col md={6}>
              <Form.Label className='label-required'>Contraseña</Form.Label>
              <Form.Control type="password" name='password' value={password} onChange={handleChange} />
            </Col>
          </Form.Row> 
          }
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            className='btn-icon'
            type='button'
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button
            type='submit'
            className='btn-icon'
            variant='info'
          >
            <FontAwesomeIcon icon={editar ? faSave : faPlus} />
            {
              editar ? 'Guardar' : 'Agregar'
            }
          </Button>
        </Modal.Footer>
      </Form>
    </div>
   );
}
 
export default RegistroUsuarios;

const initialState = () => (
  {
    nombre_usuario: '',
    apellido_usuario: '',
    fecha_nac: '',
    dni: '',
    direccion: '',
    distrito: '',
    provincia: '',
    departamento: '',
    celular: '',
    celular_ref: '',
    email: '',
    usuario: '',
    password: '',
    id_estado: '',
    clasificacion: '',
    area: ''
  }
)
