import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faEdit } from '@fortawesome/free-solid-svg-icons';

const ResumenCv = ({ resumenCv, handleEditar }) => {

  return ( 
     <div className='ResumenCv'>
        <div className="ResumenCv__head px-3 py-3 border-bottom d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faBriefcase} className='mr-1' />
            <h5 className='ResumenCv__title'>Resumen de CV</h5>
          </div>
          <div className='d-flex align-items-center cursor-pointer'>
            <FontAwesomeIcon icon={faEdit} className='mr-1' />
            <span onClick={() => handleEditar()}>Editar</span>
          </div>
        </div>
        <div className="ResumenCV__body px-3 my-3">
          <div className='ResumenCv__desc'>
            { resumenCv ? ReactHtmlParser(resumenCv) : 'No hay texto registrado' }
          </div>
        </div>
      </div>
   );
}

ResumenCv.propTypes = {
  resumenCs: PropTypes.string,
  handleEditar: PropTypes.func
}

export default ResumenCv;