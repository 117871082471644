import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';
import EditarDatosSesion from '../../view/components/RegistroUsuarios/DatosSesion/EditarDatosSesion/EditarDatosSesionDocente';

const withVistaDatosSesion = WrappedComponent => props => {

  const dispatch = useDispatch();
  const [docente] = useGetDocente();
  
  const handleEditar = () => {
    dispatch(updateStateModalCustom(<EditarDatosSesion />, 'Editar datos de sesión', 'modal-w30'))
  }

  return (  
    <WrappedComponent 
      {...props}
      usuario={docente.usuario}
      handleEditar={handleEditar}
    />
  );
}
 
export default withVistaDatosSesion;