import { modulosTypes } from "../actions/types";

const initialState = {
  modulos: [],
  modulo: null,
  loading: false,
  error: null,
  moduloLoading: false,
  moduloError: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case modulosTypes.OBTENER_MODULOS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case modulosTypes.OBTENER_MODULOS_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        modulos: action.payload
      } 
    case modulosTypes.NUEVO_MODULO:
    case modulosTypes.EDITAR_MODULO:
      return {
        ...state,
        moduloLoading: true,
        moduloError: null
      }
    case modulosTypes.NUEVO_MODULO_EXITO:
      return {
        ...state,
        moduloLoading: false,
        moduloError: null,
        modulos: [...state.modulos, action.payload]
      }
    case modulosTypes.NUEVO_MODULO_ERROR:
    case modulosTypes.EDITAR_MODULO_ERROR:
      return {
        ...state,
        moduloLoading: false,
        moduloError: action.payload
      }
    case modulosTypes.ELIMINAR_MODULO_EXITO:
      return {
        ...state,
        modulos: [...state.modulos].filter(modulo => modulo.id_modulo !== action.payload)
      }
    case modulosTypes.EDITAR_MODULO_EXITO:
      return {
        ...state,
        moduloLoading: false,
        moduloError: null,
        modulos: [...state.modulos].map(
          modulo => modulo.id_modulo === action.payload.idModulo ? action.payload.datos : modulo
        )
      }
    default:
      return state;
  }
}

