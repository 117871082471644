import React from "react";
import { useHistory } from "react-router-dom";
import {
  avisoCargando,
  avisoCorrecto,
  avisoError,
} from "../../../components/AvisosMensajes/MensajesSwalf";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  REQUIRED,
  TYPE_NUMBER_POSITIVE,
  TYPENUMBER,
  TYPE_NUMBER_INT,
  CORREO_TYPE,
} from "../../../../helpers/validationForms";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  insertarEstudiantes,
  editarEstudiante,
} from "../../../../actions/estudiantesActions";
import { Button, Form } from "react-bootstrap";

const FormularioEstudiante = ({ estudiante = null }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { usuario } = useSelector((state) => state.usuario);

  const formik = useFormik({
    initialValues: estudiante ? {
      ...estudiante,
      password_nuevo: '',
      repetir_password: ''
    } : initialValues(usuario),
    validationSchema: yup.object(validationSchema(estudiante)),
    onSubmit: async (valores) => {
      avisoCargando("Guardando ...");
      if (!estudiante) {
        if ((await dispatch(insertarEstudiantes(valores))).mensaje) {
          avisoCorrecto("Se inserto nuevo estudiante");
          history.push("/estudiantes");
          return;
        }
        avisoError("Error en agregar estudiante");
        return;
      }
      //sino es undefined va a editar
      if (
        (await dispatch(editarEstudiante(estudiante.id_usuario, valores))).mensaje
      ) {
        avisoCorrecto("Se edito estudiante correctamente");
        history.push("/estudiantes");
        return;
      }
      avisoError("Error en editar estudiante");
    },
  });

  return (
    <div className="content_new_prof row mt-2 mt-5 ">
      <div className="col-lg-8 order-lg-2">
        <Form onSubmit={formik.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Nombre
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="nombre_usuario"
                value={formik.values.nombre_usuario}
                onChange={formik.handleChange}
                isInvalid={formik.errors.nombre_usuario}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nombre_usuario}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Apellido
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="apellido_usuario"
                value={formik.values.apellido_usuario}
                onChange={formik.handleChange}
                isInvalid={formik.errors.apellido_usuario}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.apellido_usuario}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Fecha Nacimiento
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="date"
                name="fecha_nac"
                value={formik.values.fecha_nac}
                onChange={formik.handleChange}
                isInvalid={formik.errors.fecha_nac}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fecha_nac}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              E-mail
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              DNI
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="dni"
                value={formik.values.dni}
                onChange={formik.handleChange}
                isInvalid={formik.errors.dni}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.dni}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Dirección
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="direccion"
                value={formik.values.direccion}
                onChange={formik.handleChange}
                isInvalid={formik.errors.direccion}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.direccion}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label form-control-label"></label>
            <div className="col-lg-3">
              <Form.Control
                type="text"
                name="distrito"
                value={formik.values.distrito}
                onChange={formik.handleChange}
                isInvalid={formik.errors.distrito}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.distrito}
              </Form.Control.Feedback>
            </div>
            <div className="col-lg-3">
              <Form.Control
                type="text"
                name="provincia"
                value={formik.values.provincia}
                onChange={formik.handleChange}
                isInvalid={formik.errors.provincia}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.provincia}
              </Form.Control.Feedback>
            </div>
            <div className="col-lg-3">
              <Form.Control
                type="text"
                name="departamento"
                value={formik.values.departamento}
                onChange={formik.handleChange}
                isInvalid={formik.errors.departamento}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.departamento}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Tel/Celular
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                isInvalid={formik.errors.celular}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.celular}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label form-control-label">
              Celular Ref.
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="celular_ref"
                value={formik.values.celular_ref}
                onChange={formik.handleChange}
                isInvalid={formik.errors.celular_ref}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.celular_ref}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Contrato
            </label>
            <div className="col-lg-9">
              <Form.Check
                type="radio"
                label="SI"
                value="SI"
                inline
                id="si-contrato"
                onChange={formik.handleChange}
                checked={
                  formik.values.confirmacion_contrato === "SI" ? true : false
                }
                isInvalid={formik.errors.confirmacion_contrato}
                name="confirmacion_contrato"
              />
              <Form.Check
                type="radio"
                label="NO"
                value="NO"
                checked={
                  formik.values.confirmacion_contrato === "NO" ? true : false
                }
                inline
                id="no-contrato"
                onChange={formik.handleChange}
                isInvalid={formik.errors.confirmacion_contrato}
                name="confirmacion_contrato"
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.confirmacion_contrato}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Usuario
            </label>
            <div className="col-lg-9">
              <Form.Control
                type="text"
                name="usuario"
                value={formik.values.usuario}
                onChange={formik.handleChange}
                isInvalid={formik.errors.usuario}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.usuario}
              </Form.Control.Feedback>
            </div>
          </div>
          {
            estudiante ? (
              <>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Nueva contraseña
                  </label>
                  <div className="col-lg-9">
                    <Form.Control
                      type="password"
                      name="password_nuevo"
                      value={formik.values.password_nuevo}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.password_nuevo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password_nuevo}
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label form-control-label">
                    Repetir contraseña
                  </label>
                  <div className="col-lg-9">
                    <Form.Control
                      type="password"
                      name="repetir_password"
                      value={formik.values.repetir_password}
                      onChange={formik.handleChange}
                      isInvalid={formik.errors.repetir_password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.repetir_password}
                    </Form.Control.Feedback>
                  </div>
                </div>
              </>
            ) : (
              <div className="form-group row">
                <label className="col-lg-3 label-required col-form-label form-control-label">
                  Contraseña
                </label>
                <div className="col-lg-9">
                  <Form.Control
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </div>
              </div>
            )
          }
          <div className="form-group row">
            <label className="col-lg-3 label-required col-form-label form-control-label">
              Estado
            </label>
            <div className="col-lg-9 mt-2">
              <Form.Check
                type="radio"
                label="Activo"
                value="1"
                inline
                id="si-estado"
                onChange={formik.handleChange}
                checked={
                  formik.values.id_tp_estado === "1" ? true : false
                }
                name="id_tp_estado"
              />
              <Form.Check
                type="radio"
                label="Inactivo"
                value="2"
                checked={
                  formik.values.id_tp_estado === "2" ? true : false
                }
                inline
                id="no-estado"
                onChange={formik.handleChange}
                name="id_tp_estado"
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="observaciones"
              className="col-lg-3 col-form-label form-control-label"
            >
              Observaciones
            </label>
            <div className="col-lg-9">
              <Form.Control
                id="observaciones"
                as="textarea"
                cols="20"
                rows="5"
                name="observaciones"
                value={formik.values.observaciones}
                onChange={formik.handleChange}
                isInvalid={formik.errors.observaciones}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.observaciones}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3"></div>
            <div className="col-md-9"></div>
            <label className="col-lg-3 col-form-label form-control-label"></label>
            <div className="boton_aceptar col-lg-9">
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => history.push("/estudiantes")}
              >
                Cancelar
              </button>
              <Button variant="info" type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <div className="subir_foto col-lg-4 order-lg-1 text-center">
          <img
            src={
              estudiante === null || estudiante.img_usuario === null
                ? "/images/user.png"
                : `${process.env.REACT_APP_API_URL}/${estudiante.img_usuario}`
            }
            className="mx-auto img-fluid img-circle d-block"
            style={{ width: "50%", margin: "auto" }}
            alt="avatar"
          />
        <label className="custom-file">
          <input type="file" id="file" className="custom-file-input" />
          <span className="custom-file-control btn btn-success">
            Subir Imagen
          </span>
        </label>
      </div>
    </div>
  );
};

const initialValues = (usuario) => ({
  id_usuario_a: usuario.id_usuario,
  nombre_usuario: "",
  apellido_usuario: "",
  fecha_nac: "",
  dni: "",
  direccion: "",
  distrito: "",
  provincia: "",
  departamento: "",
  celular: "",
  celular_ref: "",
  email: "",
  id_tp_estado: '1',
  usuario: "",
  password: "",
  id_asesor: 1,
  confirmacion_contrato: "SI",
  observaciones: "",
});

const validationSchema = (estudianteProp) => {
  let initialSchema = {
  nombre_usuario: yup.string().required(REQUIRED),
  apellido_usuario: yup.string().required(REQUIRED),
  fecha_nac: yup.string().required(REQUIRED),
  direccion: yup
    .string()
    .required(REQUIRED)
    .nullable(),
  distrito: yup
    .string()
    .required(REQUIRED)
    .nullable(),
  provincia: yup
    .string()
    .required(REQUIRED)
    .nullable(),
  departamento: yup
    .string()
    .required(REQUIRED)
    .nullable(),
  celular: yup
    .number()
    .typeError(TYPENUMBER)
    .positive(TYPE_NUMBER_POSITIVE)
    .integer(TYPE_NUMBER_INT)
    .required(REQUIRED),
  celular_ref: yup
    .number()
    .typeError(TYPENUMBER)
    .positive(TYPE_NUMBER_POSITIVE)
    .integer(TYPE_NUMBER_INT)
    .nullable(),
  email: yup
    .string()
    .email(CORREO_TYPE)
    .required(REQUIRED),
  usuario: yup.string().required(REQUIRED),
  password_nuevo: yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .oneOf([yup.ref('repetir_password')], 'Las contraseñas no son iguales.')
    .nullable(),
  repetir_password: yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .oneOf([yup.ref('password_nuevo')], 'Las contraseñas no son iguales.')
    .nullable(),
  confirmacion_contrato: yup.string().required(REQUIRED),
  observaciones: yup.string().nullable(),
  dni: yup
    .number()
    .typeError(TYPENUMBER)
    .positive(TYPE_NUMBER_POSITIVE)
    .integer(TYPE_NUMBER_INT)
    .required(REQUIRED),
  }
  if (!estudianteProp) {
    initialSchema = {
      ...initialSchema,
      password: yup.string().min(6, 'Mínimo 6 caracteres').required(REQUIRED)
    }
  }
  return initialSchema;
};

export default FormularioEstudiante;
