import { faArrowDown, faArrowUp, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Table,Card} from 'react-bootstrap';
const ResultadoReporte = ({reporte}) => {

    if(reporte===undefined || !reporte || reporte.lenght===0 ) return (
        <>
        <Card className="p-3 mb-3">
         <Card.Title>Reporte de Afiliaciones</Card.Title>
         <Card.Body>
            No hay datos para mostrar
         </Card.Body>
        </Card>
        </>
    );
    return (
        <>
        <Card className="p-3 mb-3">
         <Card.Title>Reporte de Afiliaciones</Card.Title>
         <Card.Body>
       <Table striped bordered hover>
        <thead>
            <tr>
            <th>Rango</th>
            <th>Meta (Suscripción)</th>
            <th>Suscritos</th>
            <th>Brecha</th>
            <th>Pérdida (desertados)</th>
            <th>Detalle</th>
            </tr>
        </thead>
        <tbody>
        {
            reporte.map((dato,i)=>(
                <tr key={i}>
            <td>{dato.fecha_inicio} hasta {dato.fecha_fin}</td>
            <td className="text-center">{dato.meta.cantidad} <strong> ({dato.meta.porcentaje!=='NAN%' ? dato.meta.porcentaje : 0})</strong></td>
            <td className="text-center">{dato.afiliados.cantidad ? dato.afiliados.cantidad : 0}<strong> ({dato.afiliados.porcentaje!=='NAN%'  ? dato.afiliados.porcentaje : 0 })</strong></td>
            <td className="text-center">{dato.brecha.cantidad ? dato.brecha.cantidad : 0} <strong>({dato.brecha.porcentaje!=='NAN%'  ? dato.brecha.porcentaje : 0})</strong></td>
            <td className="text-center">{dato.desertados.cantidad ? dato.desertados.cantidad : 0} <strong>({dato.desertados.porcentaje!=='NAN%'  ? dato.desertados.porcentaje : 0})</strong></td>
            <td className="d-flex"><a href={dato.enlaceDetalle} className="btn btn-info" rel="noopener noreferrer" title="Detalle de Afiliados"><FontAwesomeIcon icon={faChartLine} /></a></td>
            </tr>

            ))
        }
           
        </tbody>
        </Table>
        </Card.Body>
        </Card>
        </>
    );
};

export default ResultadoReporte;
