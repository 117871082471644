import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';
import EditarTemasAreaTrabajo from '../../view/components/RegistroUsuarios/Clasificacion/EditarTemasArea/EditarTemasAreaTrabajador';

const wihtListaTemasArea = WrappedComponet => props => {
  
  const [trabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleEditar = () => {
    dispatch(updateStateModalCustom(<EditarTemasAreaTrabajo/>, 'Editar temas del trabajador', 'modal-w30'))
  }

  return ( 
    <WrappedComponet
      {...props}
      temas={trabajador.temas}
      nombre_area_dictado={trabajador.nombre_tp_nivel_usuario}
      handleEditar={handleEditar}
    />
   );
}
 
export default wihtListaTemasArea;