import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap'

//Redux
import { connect } from 'react-redux'
import { editarRecurso } from '../../../../actions/recursosActions';

import './EditarRecursos.scss'

const EditarRecursos = (props) => {

  const { recurso } = props

  //Funciones redux
  const { editarRecurso, usuario } = props

  const [nombre, setNombre] = useState('')
  const [aprobado, setAprobado] = useState(false)
  const handleChange = e => {
    setNombre(e.target.value)
  }

  const onSubmit = e => {
    e.preventDefault()

    const data = {
      new_nombre_archivo: nombre,
      id_sesion: recurso.id_sesion,
      id_usuario: recurso.id_usuario,
      aprobado: aprobado ? 1:0,
    }

    editarRecurso(recurso.id_archivo_sesion ,data)
  }

  const autocompletar = () => {
    setNombre(recurso.nombre_archivo)
    setAprobado(recurso.aprobado==="1"?true:false)
  }

  useEffect(() => {
    autocompletar()
  }, [recurso])

  return ( 
    <div className="editar-recurso">
      <Form onSubmit={onSubmit}>
      <Modal.Body>

     
        <Form.Group>
          <Form.Label id='nombre' >
            Nombre
          </Form.Label>
          <Form.Control 
            type='text'
            name='nombre'
            value={nombre}
            onChange={handleChange}
          />
        </Form.Group>
    { usuario.id_tp_nivel_usuario!=="2" ?   <Form.Group>
          <Form.Label id='nombre' >
            Aprobado
          </Form.Label>
          <Form.Check type="checkbox" label="" checked={aprobado} value={aprobado} onChange={() => {setAprobado(!aprobado);}} />
        </Form.Group> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='submit'
          variant='info'
        >
          Guardar
        </Button>
      </Modal.Footer>
      </Form>
    </div>
   );
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  editarRecurso
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarRecursos);