import React, { useState } from 'react';
import SimpleSelect from '../../../plugins/SimpleSelect';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ListaRangoProfesional = ({ clasificacion, handleAgregarIndicador}) => {

  const [seleccion, setSeleccion] = useState(null)
  
  const { idDocente } = useParams();

  const handleButton = () => {
    if (!seleccion) {
      avisoError('No hay selección');
      return;
    }
    handleAgregarIndicador(seleccion, idDocente)
  }

  return ( 
    <div className="ListaRangoProfesional">
      <div className="ListaRangoProfesional__container">
        <div className='ListaRangoProfesional__lista'>
          <div className='Category'>
            <span className='Category__title'>Lista de Clasificación</span>
            <div className='mt-3'>
              <SimpleSelect
                options={clasificacion}
                getSelection={setSeleccion}
              />
              <div className='text-right mt-3'>
                <Button
                  type='button'
                  variant='info'
                  className='text-white btn-icon'
                  onClick={() => handleButton()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Agregar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default ListaRangoProfesional;