import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { nuevaPlantilla } from '../../../actions/basesCertActions';
import { traerUltimoEvento } from '../../../actions/basesCertActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import SelectVariable from '../../components/SelectVariable/SelectVariable';
import Editor from '../Comunicados/EditorPopUp';

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const meses = [
    {
      id:1,
      value:"Enero"
    },
    {
      id:2,
      value:"Febrero"
    },
    {
      id:3,
      value:"Marzo"
    },
    {
      id:4,
      value:"Abril"
    }
    ,
    {
      id:5,
      value:"Mayo"
    }
    ,
    {
      id:6,
      value:"Junio"
    }
    ,
    {
      id:7,
      value:"Julio"
    }
    ,
    {
      id:8,
      value:"Agosto"
    }
    ,
    {
      id:9,
      value:"Septiembre"
    }
    ,
    {
      id:10,
      value:"Octubre"
    }
    ,
    {
      id:11,
      value:"Noviembre"
    }
    ,
    {
      id:12,
      value:"Diciembre"
    }
]

const anios = [
  {
    id:"2019",
    value:"2019"
  },
  {
    id:"2020",
    value:"2020"
  },
  {
    id:"2021",
    value:"2021"
  },
  {
    id:"2022",
    value:"2022"
  },
  {
    id:"2023",
    value:"2023"
  },
  {
    id:"2024",
    value:"2024"
  }
  ,
  {
    id:"2025",
    value:"2025"
  }
  ,
  {
    id:"2026",
    value:"2026"
  }
  ,
  {
    id:"2027",
    value:"2027"
  }
]


 const FormularioBaseCertificado = () => {
 const dt = new Date()
 const {usuario} = useSelector(state=>state.usuario)
 const [datosCertificado, setDatosCertificado] = useState(
   {
     titulo:'',
     periodo:dt.getFullYear(),
     producto:null,
     n_evento:'',
     n_libro:'',
     tabla_contenido:null,
     responsable:usuario.id_usuario,
   }
 )

 const [proyecto, SelectProyecto] = useSelectProyecto(null)
 const [archivo,setArchivo] = useState(null)
 const dispatch = useDispatch();

 const handleChange = (e) =>{
  setDatosCertificado({
      ...datosCertificado,
        [e.target.name]:e.target.value
    })
  }

 const handleSubmit = (e) =>{
    e.preventDefault();
    console.log(datosCertificado)
    if(datosCertificado.titulo!=='' && datosCertificado.producto!==null)
    {dispatch(nuevaPlantilla(datosCertificado))}
    else
    {
      avisoError('Debe llenar algunos campos aún ...')
    }
  }

 
 const subirArchivo = e=>{
  setArchivo(e)
}

 const setTitulo = (dato) =>{
      setDatosCertificado({
        ...datosCertificado,
        titulo:dato
      })
 }

 useEffect(()=>{
 const fetchEvento = async()=>{
  const datos = await dispatch(traerUltimoEvento())
  setDatosCertificado({
    ...datosCertificado,
    n_evento:datos.n_evento,
    n_libro:datos.n_libro
  })
 }
 fetchEvento();
},[])

 useEffect(()=>{
  if(proyecto)
  {

    setDatosCertificado({
      ...datosCertificado,
      producto:proyecto?proyecto:''
    })
  }
  if(archivo)
  {
    setDatosCertificado({
      ...datosCertificado,
      tabla_contenido:archivo
    })
  }
 },[proyecto,archivo])



 return (
 
                <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
                      <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Periodo:
                      </label>
                    </div>
                    <div className="col-md-9">
                      <SelectVariable name="periodo" datos={anios} value={datosCertificado.periodo} primerSelect="Seleccione un periodo" handleChange={handleChange} isRequired={true} isDisabled={true} />
                    </div>
                    
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Número de Evento:
                      </label>
                    </div>
                    <div className="col-md-9">
                     <input type="text" className="form-control" value={datosCertificado.n_evento} name="n_evento" onChange={handleChange} required readOnly/>
                    </div>
                  
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Número de Libro:
                      </label>
                    </div>
                    <div className="col-md-9">
                     <input type="text" className="form-control" value={datosCertificado.n_libro} name="n_libro" onChange={handleChange} required readOnly/>
                    </div>
                   
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Título Certificado:
                      </label>
                    </div>
                    <div className="col-md-9">
                      <Editor setContenido ={setTitulo} />
                    </div>
                
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-9">
                      <SelectProyecto />
                    </div>
              
                  </div>
            
                  <div className="form-group">
                      <div className="col-md-3">
                        <label htmlFor="titulopop" className="mb-0">
                          Tabla de Contenido:
                        </label>
                      </div>
                      <div className="col-md-9">
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e)=> subirArchivo(e.target.files[0])}
                      />
                      </div>
                    
                  </div>
                  
                  <div className="form-group">
                      <div className="col-md-3">
                      <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                      </div>
                     
                  </div>
                  
                </form>
  
    );
};

export default FormularioBaseCertificado;