import {
    SUBIR_RECURSO,
    LISTAR_ARCHIVOS,
    ELIMINAR_VIDEO,
    ELIMINAR_RECURSO,
    LISTAR_VIDEOS,
    INSERTAR_VIDEOS,
    EDITANDO_RECURSO,
    RECURSO_EDITADO,
    ERROR_CONSULTA
} from './types'

import { avisoCorrecto, avisoCargando,avisoError } from '../view/components/AvisosMensajes/MensajesSwalf'
import authAxios from '../config/authAxios'

export const subirRecurso = (dato, config) => async dispatch => {
    
    try {
        const respuesta = await authAxios.post(`/admin/SubirArchivoSesion`,dato, config)
    
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: SUBIR_RECURSO
            })

            return {mensaje: true, textMensaje: 'Recurso insertado correctamente'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
    
    
}

export const listarRecurso = idSesion => async dispatch => {
    let recursos = await authAxios.get(`/admin/se_mostrarArchivos__IdSesion/${idSesion}`)

    dispatch({
        payload: recursos.data,
        type: LISTAR_ARCHIVOS
    })
}

export const eliminarRecursos = idArchivoSesion => async dispatch => {

    try {
        let respuesta = await authAxios.delete(`/admin/eliminarArchivoSesionId/${idArchivoSesion}`)
    
        if (!respuesta.data.error) {
            dispatch({
                payload:respuesta.data,
                type: ELIMINAR_RECURSO
            })
            
            return {mensaje: true, texMensaje: 'Eliminado correctamente'}
        }
        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
    

}

export const insertarVideo = dataVideo => async dispatch => {
    
    try {
        let respuesta = await authAxios.post(`/admin/insertarVideoSesion`, dataVideo)

    dispatch({
        payload: respuesta.data,
        type: INSERTAR_VIDEOS
    })
    } catch (error) {
        
    }
    
}

export const listarVideos = idSesion => async dispatch => {
    try {
      let videos = await authAxios.get(`/admin/mostrarVideoSesionPorIdSesion/${idSesion}`)
  
      dispatch({
        payload: videos.data,
        type: LISTAR_VIDEOS
      })
  
    } catch (error) {
      console.log(error)
    }
  
  
  }

export const eliminarVideos = (idVideoSesion, idSesion) => async dispatch => {
    try {
        let videos = await authAxios.delete(`/admin/eliminarVideoSesion/${idVideoSesion}/${idSesion}`)

        dispatch({
            payload: videos.data,
            type: ELIMINAR_VIDEO
        })

    } catch (error) {
        
    }
}

// export const mostrarVideos = () => async dispatch => {
//     let respuesta = await authAxios.get(``)
// }

export const editarRecurso = (idArchivoSesion, data) => {
    return async dispatch => {
        try {
            loadingConsulta()
            avisoCargando('Guardando...')
            const respuesta = await authAxios.put(`/admin/se_editarArchivoSesionId/${idArchivoSesion}`, data)

            if (!respuesta.data.error && !respuesta.data.message) {
                dispatch( guardarArchivos(respuesta.data) )
                avisoCorrecto('Guardado correctamente')
              } else {
                dispatch( errorConsulta(respuesta.data.error || respuesta.data.message) )
                avisoError(respuesta.data.error || respuesta.data.message)
              }
        

        } catch (error) {
            console.log(error)
            dispatch(errorConsulta(error))
            avisoError(error)
        }

    } 
}

const loadingConsulta = () => {
    return {
        type: EDITANDO_RECURSO
    }
}

const guardarArchivos = data => {
    return {
        type: RECURSO_EDITADO,
        payload: data
    }
}

const errorConsulta = error => {
    return {
        type: ERROR_CONSULTA,
        payload: error
    }
}