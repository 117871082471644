import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2'
import './BarChart.scss'

const BarChart = () => {

  const [barData, setBarData] = useState({
    labels: ['Eduardo', 'José', 'Junior'],
    datasets: [
      {
        label:  'Asistencias',
        data: [
          15,
          14,
          13
        ],
        backgroundColor: [
          '#F39C12',
          '#3498DB',
          '#18BC9C'
        ]
      }
    ]
  })
  
  return (
    <div className="bar-chart">
      <Bar
        data={barData}
        options={{
          maintainAspectRatio: false
        }}
      /> 
    </div>
  )

}

export default BarChart;
