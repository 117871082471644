import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editarProveedor, nuevoContratoP, nuevoProveedor, traerSelectProveedores } from '../../../actions/empresaAction';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import SimpleSelect from '../../components/plugins/SimpleSelect';
const FormularioContratoProveedor = ({registro=null}) => {
    const {usuario} = useSelector(state=>state.usuario)
    const style = {
        boton: {
            background: "#28314A",
            color: "#fff"
        },
        buttonText: {
            fontSize: "13px",
            background: "#28314A",
            color: '#fff'
        },
        textArchivo: {
            fontSize: "12px",
            fontWeight: "600",
            color: "#aaa"
        }
      }
    
    const initialValues = {
        id_contrato:registro?registro.id_contrato:null,
        razon_contrato:registro?registro.razon_contrato:'',
        proveedor:registro?registro.proveedor:'',
        id_usuario:usuario.id_usuario
      }
    const [datosEmpleo, setDatosEmpleo] = useState(initialValues);
    const [selectProveedores,setSelectProveedores] = useState([])
    const [contrato,setContrato] = useState(null)
    const dispatch = useDispatch();
    const handleChange= (event) => {
            const value = event.target.value;
            setDatosEmpleo({
            ...datosEmpleo,
            [event.target.name]: value
            });
        
    }
    const setProveedor = (e) =>{
      setDatosEmpleo({
        ...datosEmpleo,
        proveedor: e.value
      })
    }
    const handleSubmit = (e) =>
     {
       e.preventDefault()

       if(datosEmpleo.proveedor!=='')
       console.log(datosEmpleo,contrato)
       else
       avisoError('Falta seleccionar un Proveedor')
      if(!registro)
       dispatch(nuevoContratoP(contrato,datosEmpleo,usuario.id_usuario))
       else
       dispatch(editarProveedor(datosEmpleo))
     }

     const fillSelectProveedores = async () => {
      let dataGenerado = [];
  
       const listaProveedores = await dispatch(traerSelectProveedores())
       listaProveedores.map((proveedor) => {
        dataGenerado = [
          ...dataGenerado,
          {
            value: proveedor.id_proveedor,
            label: proveedor.nombre_rs,
          },
        ];
      });
      setSelectProveedores(dataGenerado);
    };
  
    useEffect(() => {
      fillSelectProveedores();
    }, []);

    return (
       
        <form id="formNuevo2" className="px-md-5 py-3" onSubmit={handleSubmit} >

        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="nombre_puesto" className="mb-0">
              Razón de Contrato:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="razon_contrato"
              onChange={handleChange}
              value={datosEmpleo.razon_contrato}
              type="text"
              className="form-control"
              id="razon_contrato"
              required
            />
          </div>
        
        </div>    
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
            Proveedor:
            </label>
          </div>
          <div className="col-md-9">
            <SimpleSelect    options={selectProveedores}
                  getSelection={setProveedor} />
            
          </div>
  
        </div>

        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
             Contrato (PDF, DOCX):
            </label>
          </div>
          <div className="col-md-9">
            <input
              onChange={(e)=> setContrato(e.target.files[0])}
              type="file"
              className="form-control"
              required
              name="contrato_p"
            />
          </div>
  
        </div>
  
        <button type="submit" className="btn" style={style.boton}>
          <i className="fa fa-floppy-o"></i> Guardar
        </button>
      </form>

    );
};

export default FormularioContratoProveedor;