import React, { useEffect } from 'react';
import Layout from '../../../../app/Layout';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import FormularioEstudiante from '../creacionEstudiante/FormularioEstudiante';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listarEstudiante } from '../../../../actions/estudiantesActions';
import { useParams } from 'react-router-dom';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import Fatal from '../../../components/MessageAlert/Fatal';

const EditarEstudiante = () => {

  const { idEstudiante } = useParams();
  const dispatch = useDispatch();
  const { estudiante, loading, error } = useSelector(state => state.estudiantes);

  const mostrarContenido = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    return <FormularioEstudiante estudiante={estudiante} />
  }

  useEffect(() => {
    dispatch(listarEstudiante(idEstudiante));
  }, [dispatch, idEstudiante])

  return ( 
    <Layout>
      <div className="EditarEstudiante">
        <div className="EditarEstudiante__header">
          <HeaderContent title='Editar discente' />
          <Breadcrumb
            link={[
              {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
              {url:'/estudiantes', nombre: 'Discentes'},
              {url:'', nombre: "Editar discente"}
            ]}
          />
        </div>
        <div className="EditarEstudiante__body">
          {mostrarContenido()}
        </div>
      </div>
    </Layout>
  );
}
 
export default EditarEstudiante;