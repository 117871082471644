import { useParams } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

const useGetTrabajador = idUsuarioParam => {
  
  const { idTrabajador } = useParams();
  const idRetorno = !idUsuarioParam ? idTrabajador : idUsuarioParam
  const trabajador = useSelector(state => state.trabajadores.trabajador[idRetorno])
  return [trabajador, idRetorno]
}
 
export default useGetTrabajador;