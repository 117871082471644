import React from 'react';
import { Button } from 'react-bootstrap';
import MaterialTableDemo from '../../plugins/MaterialTableDemo';
import { faEye, faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TablaProyectosMatricula = ({ proyectos, setProyectoSeleccionado }) => {
  
  const botonAccion = (record) => (
    <Button
      variant='warning'
      size='xs'
      onClick={() => setProyectoSeleccionado(record)}
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  )
  
  return ( 
    <div className="TablaProyectosMatricula">
      <div className="form-header ml-0">
        <FontAwesomeIcon icon={faBook} />
        Tabla de proyectos
      </div>
      <MaterialTableDemo 
          colums={columns(botonAccion)}
          tabla={proyectos}
          addConfig={{ key_column: 'id_proyecto' }}
        />
    </div>
  );
}
 
export default TablaProyectosMatricula;

const columns = (botonAccion) => [
  {
    key: 'index',
    text: '#',
    width: 1,
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    key: 'nombre_proyecto',
    text: 'Proyecto',
    width: 400
  },
  {
    key: 'examinar',
    text: 'Examinar',
    width: 10,
    className: 'text-center',
    cell: record => botonAccion(record)
  }
]
