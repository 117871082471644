import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { Row, Col } from 'react-bootstrap';
import "./CurriculumDocente.scss";

//Redux
import { useSelector } from "react-redux";

const CurriculumDocente = () => {
  const { activo: sesionActivo } = useSelector(
    (state) => state.control_capacitacion.area_trabajo
  );

  const nombreDocente = () => `${sesionActivo.docente_confirmado[0].nombre_usuario} 
  ${sesionActivo.docente_confirmado[0].apellido_usuario}`;
  
  const getAvatarDocente = () => {
    if(sesionActivo.docente_confirmado[0].img_usuario){
      return `${process.env.REACT_APP_API_URL}/${sesionActivo.docente_confirmado[0].img_usuario}`
    }
    return '/images/user.png';
  }

 
  if(!sesionActivo.docente_confirmado[0]) return <p>No hay docente confirmado</p>;

  return (
    <section className="CurriculumDocente">
      <Row className='CurriculumDocente__body my-3'>
        <Col sm={12} md={6} lg={4} className='CurriculumDocente__avatar'>
          <img className='img img-fluid rounded-circle' src={getAvatarDocente()} alt="avatar docente"/>
        </Col>
        <Col sm={12} md={6} lg={8} className='CurriculumDocente__desc'>
          <h6>{sesionActivo.docente_confirmado.length && nombreDocente()}</h6>
          {ReactHtmlParser(sesionActivo.cv)}
        </Col>
      </Row>
    </section>
  );
};

export default CurriculumDocente;
