import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./ResultadoDiscente.scss";
import { Col, Row, Table } from "react-bootstrap";

//Redux
import { useSelector } from "react-redux";
import FilaTablaMatricula from "./FilaTablaMatricula";

const ResultadoDiscente = () => {
  const datosDiscente = useSelector(
    (state) => state.pagos.datosFinanzasDiscente.todo
  );
  const { tipo, valor } = useSelector((state) => state.pagos.datosFormulario);

  const mostrarMatriculas = () => {
    const { matriculas } = datosDiscente;

    if (!matriculas || !matriculas.length) {
      return (
        <tr>
          <td colSpan="10" align="center">
            No Hay matrículas para mostrar
          </td>
        </tr>
      );
    } else {
      return matriculas.map((matricula, index) => (
        <FilaTablaMatricula
          key={matricula.id_matricula}
          index={index}
          tipo={tipo}
          valor={valor}
          matricula={matricula}
        />
      ));
    }
  };

  return (
    <section className="ResultadoDiscente mt-3">
      <h4 className="title-head">
        <FontAwesomeIcon icon={faUser} />
        Datos del discente
      </h4>
      <div className="ResultadoDiscente__body">
        <Row>
          <Col md={3}>
            <div className="box">
              <div className="ResultadoDiscente__matriculas box-body">
                <Table responsive>
                  <tbody>
                    <tr>
                      <td>Nombre</td>
                      <td>{datosDiscente.nombres}</td>
                    </tr>
                    <tr>
                      <td>Apellido</td>
                      <td>{datosDiscente.apellidos}</td>
                    </tr>
                    <tr>
                      <td>DNI</td>
                      <td>{datosDiscente.dni}</td>
                    </tr>
                    <tr>
                      <td>Teléfono</td>
                      <td>{datosDiscente.celular}</td>
                    </tr>
                    <tr>
                      <td>Correo</td>
                      <td>{datosDiscente.email}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col md={9}>
            <div className="box">
              <div className="box-body">
                <div className="ResultadoDiscente__matriculas">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Evento</th>
                        <th>Fecha matrícula</th>
                        <th>Estado matrícula</th>
                        <th>Registrar</th>
                      </tr>
                    </thead>
                    <tbody>{mostrarMatriculas()}</tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ResultadoDiscente;
