import React from "react";
import FilasTabla from './FilasTabla'
//REDUX
import {connect} from 'react-redux'

const Tabla = ({ profesoresFaltantes, guardarDocentes}) => {

    return (
      <table className="table table-sm table-fixed">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">DNI</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody >
          {profesoresFaltantes.length
            ? profesoresFaltantes.map(profesor => (
                <FilasTabla
                  key={profesor.id_docente}
                  profesor={profesor}
                  guardarDocentes={guardarDocentes}
                />
              ))
            : null}
        </tbody>
      </table>
    );
};

const mapStateToProps = state => ({
    profesoresFaltantes: state.profesores.profesoresFaltantes
})

export default connect(mapStateToProps, {})(Tabla);
