import React, { useState } from 'react';
//import { Modal } from 'react-bootstrap';
import Modal from "./Modal"
import { useDispatch } from 'react-redux';

const Comunicado = ({comunicado,show,cerrarComunicado}) => {
    const dispatch=useDispatch();
    return (

      <Modal
        active={show ? true : false}
        toggle={() => dispatch(cerrarComunicado())}
      >
        <div style={{marginTop:'100px',height:"350px",color:'white' , fontFamily:'Poppins'}}>
        <div style={{width:'50%', padding:'25px', fontSize:'32px',fontWeight:'bold', lineHeight:'28px'}}  dangerouslySetInnerHTML={{__html:comunicado.titulo}} ></div> 
        <div style={{width:'50%', padding:'20px', fontSize:'25px'}} className='text-justify' dangerouslySetInnerHTML={{__html:comunicado.mensaje}} ></div> 
        <div className='text-left mt-3' style={{padding:'20px'}}>
            { 
              comunicado.enlace ? <><a href={`${comunicado.enlace}`} target='_blank' rel="noopener noreferrer" className='btn btn-primary'>
                Saber más
              </a></> : ''
            }
            </div>
        </div>
  
       { //<Modal.Footer>
         // <Button variant="danger" onClick={() => dispatch(showAlerta())}>
        //    Close
        //  </Button>
       // </Modal.Footer>
        }
      </Modal>
    /*    <Modal
        size="md"
        show={show}
        onHide={() =>{ dispatch(cerrarComunicado())}}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal-basic'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h4>{comunicado.titulo}</h4>
          </Modal.Title>
        </Modal.Header>
         <Modal.Body>
           <div style={{padding:'15px'}} dangerouslySetInnerHTML={{__html:comunicado.mensaje}}></div>
           { comunicado.enlace!='' ? <div className="p-2 mt-2"> <a className="btn btn-danger mt-3" href={comunicado.enlace} target="_blank">Más información...</a></div> :''}
         </Modal.Body>
      </Modal>*/
    );
};

export default Comunicado;