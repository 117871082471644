import React, { useEffect } from 'react';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import ModalLargewithRedux from '../../../containers/ModalLargewhithRedux';
import FormularioContratoProveedor from './FormularioContratoProveedor';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarContratoP, listarContratosP } from '../../../actions/empresaAction';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { updateStateModalLarge } from '../../../actions/modalActions';
import { faEdit, faLink, faListAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';



const ContratosProveedores = () => {


    const {loading, contratos} = useSelector(state=> state.empresa)
    const {usuario} = useSelector(state=>state.usuario)
    const dispatch = useDispatch();

    const handleNuevoRegistro = () => {
        dispatch( updateStateModalLarge(<FormularioContratoProveedor />, 'Nuevo Contrato' ) )
      } 

      const handleEditarRegistro = (registro) => {
        dispatch( updateStateModalLarge(<FormularioContratoProveedor registro={registro} />, 'Editar Contrato' ) )
      } 


    const handleEliminarRegistro = (id) =>{
      dispatch(eliminarContratoP(id))
    }

    useEffect(()=>{
        dispatch(listarContratosP())
    },[])


    return (
        <Layout>
        <div className="Trabajadores">
          <div className="Trabajadores__container">
          <ModalLargewithRedux />
            <div className="Trabajadores__head">
              <HeaderContent title="Contratos de Proveedores" icon="group" />
              <Breadcrumb
                link={[
                  { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                  { url: "/contratos-proveedores", nombre: "Contratos" },
                ]}
              />
            </div>
            <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
              <SpinnerLoading loading={loading}>
                <div className="Trabajadores__acciones mb-2">
                  <div className="text-right">
                    <Button
                      type='button'
                      variant="primary"
                      onClick={handleNuevoRegistro}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      Nuevo Contrato
                    </Button>
                  </div>
                </div>
                <div className="box mt-5">
                  <div className="box-header with-border">
                    <h5
                        style={{ color: "#434343" }}
                      >
                      <FontAwesomeIcon icon={faListAlt} /> Contratos registrados
                    </h5>
                  </div>
                  <div className="box-primary">
                    <MaterialTableDemo
                      colums={colums(handleEliminarRegistro,handleEditarRegistro)}
                      keyCol="id_contrato"
                      tabla={contratos}
                    />
                  </div>
                </div>
              </SpinnerLoading>
            </div>
          </div>
        </div>
      </Layout>
    );
};

export default ContratosProveedores;

const colums = (handleEliminarRegistro,handleEditarRegistro) => [

    {
      text: "ID",
      key: "id_contrato",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 50
    },
    {
      text: "Razón",
      key: "razon_contrato",
      align: "center",
      sortable: true,
    },
    {
      text: "Proveedor",
      key: "nombre_rs",
      align: "center",
      sortable: true,
      className: "text-center",
    },
    {
      text: "Contrato",
      key: "contrato",
      align: "center",
      sortable: true,
      cell: (record) => (
        <>
          <a

          href={process.env.REACT_APP_API_URL+'/'+record.contrato}
          className="btn btn-info"
          target="_blank"
          rel="noopener noreferrer"          
          >
            <FontAwesomeIcon icon={faLink} />
          </a>
        </>
      )
    },
    {
      text: "Acciones",
      key: "button",
      align: "center",
      sortable: true,
      className: "text-center",
      cell: (record) => (
        <>
          <Button
            type="button"
            variant='danger'
            size='xs'
            onClick={() => handleEliminarRegistro(record.id_contrato)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
         
        </>
      ),
    },
  ];