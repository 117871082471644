import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import * as moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ListaAvatars = (props) => {
  const { avatars = [] } = props;

  const classes = useStyles();

  return (
    <List className={`${classes.root} lista-avatars`}>
      {avatars.length > 0
        ? avatars.map((avatar, index) => (
            <ListItem index={index} className="item-avatar">
              <ListItemAvatar>
                <Avatar
                  src={
                    avatar.img_usuario
                      ? `${process.env.REACT_APP_API_URL}/${avatar.img_usuario}`
                      : "/images/user.png"
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${avatar.nombre_usuario} ${avatar.apellido_usuario}`}
                secondary={avatar.fecha ? moment(avatar.fecha).format('LLLL') : '--'}
              />
            </ListItem>
          ))
        : null}
    </List>
  );
};

export default ListaAvatars;
