import React from 'react'
import ReactHtmlParser from 'react-html-parser';

const HeaderContent = ({title, icon, subItem}) => {
    return (
      <div className="w-100 d-flex align-items-center pt-2 pb-4">
        <div className="header-title-page">
          <h2 className="text-uppercase mb-0">
            {ReactHtmlParser(title)}
          </h2>
          {subItem ? (
            <p
              className="mb-0 mt-2 ml-2"
              style={{ fontSize: "1.1rem", color: "#000" }}
            >
              {subItem}
            </p>
          ) : null}
        </div>
      </div>
    );
}
 
export default HeaderContent;