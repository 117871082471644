import React from 'react';
import Layout from '../../../../app/Layout';
import HeaderContainer from '../../General/HeaderContent';

const ExaminarPagosExtra = () => {
  return ( 
    <div className="ExaminarPagosExtra">
      <Layout>
        <div className="ExaminarPagosExtra__head">
          <HeaderContainer title='Examinar pago extraordinario' />
        </div>
        <div className="ExaminarPagosExtra__bod">
          
        </div>
      </Layout>
    </div>
  );
}
 
export default ExaminarPagosExtra;