import React from 'react';
import { Modal } from  'react-bootstrap';
import PropTypes from 'prop-types';

import './ModalCustomWidth.scss';

const ModalCustomWidth = ({ show = false, updateShowModalCustom, title = '', children, customWidth = ''}) => {
  
  return ( 
      <Modal
        show={show}
        onHide={() => updateShowModalCustom(false)}
        dialogClassName={`custom-modal ${customWidth}`}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            { title }
          </Modal.Title>
        </Modal.Header>
        { children }
      </Modal>
   );
}

ModalCustomWidth.propTypes = {
  show: PropTypes.bool.isRequired,
  updateShowModalCustom: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  customWidth: PropTypes.string
}

export default ModalCustomWidth;