import React from 'react';
import './InfoLabels.scss';

const Infolabels = (props) => {

  const { info } = props

  return ( 
    <div className="info-label ">
      <p className='info-label__mensaje'>{ info }</p>
    </div>
   );
}
 
export default Infolabels;