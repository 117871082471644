import React, { Component } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class GridExample extends Component {
  constructor(props){
      super(props)
      this.state = {
          columnDefs: [
              {headerName: 'Make', field: 'make', sortable: true, filter: true},
              {headerName: 'Model', field: 'model', sortable: true, filter: true},
              {headerName: 'price', field: 'price', sortable: true, filter: true},
          ],
          rowData: [
              {make: 'Toyota', model: 'Celica', price: 35000 },
              {make: 'Ford', model: 'Mondeo', price: 32000 },
              {make: 'Porsche', model: 'Boxter', price: 35000 },
          ]
      }
  }

  render(){
      return(
          <div className="ag-theme-balham w-100"
            style={{
                height: 400
            }}
          >
            <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData = {this.state.rowData}
            />
          </div>
      )
  }

}

export default GridExample;