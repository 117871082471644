import { faFileImport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { Button, Table } from 'react-bootstrap';
import * as moment from 'moment';
//Redux
const ResultadosImportacion = ({resultados}) => {

  const handleUrlDescarga = () => {
    return `${process.env.REACT_APP_API_URL}/admin/csv/export/mailchimp/${moment().format('YYYY-MM-DD')}`;
  }


 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faFileImport} /> Resultados de Importación
                  </h5>
                
                </div>
      <div className="row p-2">
        <div className="col-12 mb-2">
         <p>Total de Leads Registrados: {resultados.resultados_asignacion.cantidad_leads_registrados}</p>
         <br/>
         <p>Importar a MailChimp:  <Button href={handleUrlDescarga()} variant="primary"> CSV MailChimp </Button> </p>
         <br/>
         <Table striped bordered hover>
            <thead>
                <tr>
                <th>Asesor</th>
                <th>Cantidad</th>
                </tr>
            </thead>
            <tbody>
            {
              resultados.resultados_asignacion.resultados_asignacion ?  resultados.resultados_asignacion.resultados_asignacion.map((resultado,i)=>(
                        <tr key={i}>
                        <td>{resultado.asesor}</td>
                        <td style={{textAlign:'center'}}>{resultado.cantidad}</td>
                        </tr>
                )) : ''
            }
               
            </tbody>
            </Table>
         
        </div>     
      </div>
    </div>
  
  
    );
};

export default ResultadosImportacion;
