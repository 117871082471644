import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectCampaigns from "../../../hooks/useSelectCampaigns";
import {insertarWebinar} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioWebinar = () => {

const [campaign, SelectCampaigns] = useSelectCampaigns(null)
const [nombre_evento, setNombreEvento]=useState('')
const [fecha_realizacion, setFechaRealizacion]=useState('')
const [cantidad_leads, setCantidadLeads]=useState('')
const [fechaInicio, setFechaInicio]=useState(null)
const [fechaFin, setFechaFin]=useState(null)
const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      id_camp: campaign?campaign.id_camp:null,
      nombre_evento: nombre_evento!==''?nombre_evento:null,
      cantidad_leads: cantidad_leads!==''? cantidad_leads :null,
      fecha_realizacion: fecha_realizacion ? moment(fecha_realizacion).format('YYYY-MM-DD') : null,
      fecha_inicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fecha_fin: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
    }
 // console.log(datosCamp)
  const retornarListaActualizada = async () =>{
     await (dispatch(insertarWebinar(datosCamp)))
    }
  
    
  // console.log(datosPop)
    if(campaign && cantidad_leads!=='' && nombre_evento!=='')
    { 
      retornarListaActualizada()
    }
    else
    {
      avisoError('Faltan campos por rellenar...')
    }
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Campaña:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectCampaigns/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Evento:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_evento} onChange={(e)=>setNombreEvento(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha Realización
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fecha_realizacion}
                        onChange={(date) =>setFechaRealizacion(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaRealizacion"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Cantidad Leads:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="objetivo" value={cantidad_leads} onChange={(e)=>setCantidadLeads(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Leads Desde:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fechaInicio}
                        onChange={(date) =>setFechaInicio(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaIni"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Leads Hasta:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fechaFin}
                        onChange={(date) =>setFechaFin(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaFi"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioWebinar;
