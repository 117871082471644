import React from "react";
import { useState, useEffect } from "react";
import {
  insertarAsistencia,
  editarAsistecia,
  removerInfoAsistencia,
} from "../../../actions/asistenciaActions";
import HeaderContent from "../General/HeaderContent";
import {
  avisoCargando,
  avisoCorrecto,
  avisoError,
} from "../../components/AvisosMensajes/MensajesSwalf";
import Layout from "../../../app/Layout";
import ModalEditar from "./ModalEditar";
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import Fatal from '../../components/MessageAlert/Fatal';

//REDUX
import { useSelector, useDispatch } from "react-redux";
import TablaAsistencia from "../../components/Asistencia/TablaAsistencia";
import FormAccionMasiva from "../../components/Asistencia/FormAccionMasiva";
import FormObtenerAsistencias from "../../components/Asistencia/FormObtenerAsistencias";

const Asistencia = () => {

  const { cantidadesTotales, asistencias, loading, error } = useSelector(state => state.asistencias);
  const dispatch = useDispatch();

  const [estudianteSeleccion, setEstudianteSeleccion] = useState({});
  const [asistenciasChecks, setAsistenciasCheck] = useState([]);

  const [datosObtenerAsistencia, setDatosObtenerAsistencia] = useState({
    proyectoSeleccion: null,
    sesionSeleccion: null,
    horarioSeleccion: null
  });

  //Editar asistencia
  const editarAsistencia = async (idEstuSesion, hora) => {
    avisoCargando("Guardando...");

    const { mensaje, textMensaje } = await dispatch(editarAsistecia(
      idEstuSesion,
      hora,
      datosObtenerAsistencia.horarioSeleccion.value
    ));

    if (mensaje) {
      avisoCorrecto(textMensaje);
      return;
    }

    avisoError(textMensaje);
  };

  //Ingresando nueva asistencia de un alumno
  const neuevaAsistenciaEstudiante = async (dni) => {
    avisoCargando("Guardando ...");
    const data = {
      id_tipo_consulta: datosObtenerAsistencia.horarioSeleccion.value,
      id_sesion: datosObtenerAsistencia.sesionSeleccion.id_sesion,
      id_proyecto: datosObtenerAsistencia.proyectoSeleccion.id_proyecto,
    };

    if ((await dispatch(insertarAsistencia(dni, data))).mensaje) {
      avisoCorrecto("Asistencia agregada");
      return;
    }

    avisoError("Hubo un error al guardar asistencia");
  };

  const agregarAsistenciaCheck = (record, todo = false) => {
    if (todo) {
      setAsistenciasCheck(record);
    } else {
      setAsistenciasCheck((datos) => [...datos, record]);
    }
  };

  const eliminarAsistenciaCheck = (record, todo = false) => {
    if (todo) {
      setAsistenciasCheck([]);
    } else {
      const nuevaAsistenciaCheck = [...asistenciasChecks].filter(
        (asistencia) =>
          asistencia.id_estudiante_sesion !== record.id_estudiante_sesion
      );
      setAsistenciasCheck(nuevaAsistenciaCheck);
    }
  };

  const mostrarContenidoTabla = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    if(loading !== null && asistencias.length && datosObtenerAsistencia.proyectoSeleccion) {
      return <div className="box box-primary mt-4">
        <div className="box-body">
          <FormAccionMasiva
            asistenciasChecks={asistenciasChecks}
            horarioSeleccion={datosObtenerAsistencia.horarioSeleccion}
            sesionSeleccion={datosObtenerAsistencia.sesionSeleccion}
          />
          <TablaAsistencia
            asistencias={asistencias}
            setEstudianteSeleccion={setEstudianteSeleccion}
            agregarAsistenciaCheck={agregarAsistenciaCheck}
            eliminarAsistenciaCheck={eliminarAsistenciaCheck}
          />
           <div style={{marginTop:'15px', padding:'8px' , display:'flex'}}>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Puntualidad:</span> {cantidadesTotales.total_puntuales}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Justidicadas:</span> {cantidadesTotales.total_justificadas}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Tardanzas:</span> {cantidadesTotales.total_tardanzas}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Inasistencias:</span> {cantidadesTotales.total_inasistencias}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Pendientes:</span> {cantidadesTotales.total_pendientes}
                    </div>
            </div>
        </div>
      </div>
    }
  }

  useEffect(() => {
    dispatch(removerInfoAsistencia());
  }, [dispatch]);

  return (
    <Layout>
      <HeaderContent title={"Asistencias"} />
      <div className="constainer_asistencia">
        <div className="row ">
          <div className="col-md-9">
            <div className="needs-validation" noValidate>
                <FormObtenerAsistencias 
                  setDatosObtenerAsistencia={setDatosObtenerAsistencia}
                  datosObtenerAsistencia={datosObtenerAsistencia}
                />
              <div className="form-row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Código</label>
                    <input
                      type="number"
                      className="form-control form-control-sm rounded"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="DNI"
                      max="8"
                      onChange={(e) => {
                        if (e.target.value.length === 8) {
                          neuevaAsistenciaEstudiante(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div style={{ textAlign: "center", padding: "0" }}>
              <iframe
                src="https://www.zeitverschiebung.net/clock-widget-iframe-v2?language=es&size=large&timezone=America%2FLima"
                width="100%"
                height="140"
                frameBorder="0"
                seamless
              ></iframe>{" "}
            </div>
          </div>
        </div>
      </div>
      {mostrarContenidoTabla()}
      <ModalEditar
        asistencia={estudianteSeleccion}
        editarAsistencia={editarAsistencia}
      />
    </Layout>
  );
};

export default Asistencia;
