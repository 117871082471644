const AREA_TRABAJO = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: "Prototipado",
      url: "/prototipado",
    }
    ,
    {
      label: "Producto",
      url: "/proyectos",
    }
    ,
    {
      label: "Sub-Producto",
      url: "/modulos",
    },
    {
      label: "Temas",
      url: "/temasPorAreas",
    }
    
    
  ],
}

const REPORTES = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES'],
  subItem: [
    {
      label: 'Naranja',
      url: '/reporte/naranja',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Afiliación',
      url: '/reporte/afiliaciones',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES']
    }
    ,
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES']
    }
    ,
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Deudores',
      url: '/reporte-deudores',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
   
  ]
}

const REPORTES_ACADEMICA = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['PRODUCTO','UXMANAGER','OPERACIONES'],
  subItem: [
    {
      label: 'Naranja',
      url: '/reporte/naranja/academica',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['OPERACIONES','UXMANAGER','PRODUCTO']
    },
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['PRODUCTO','UXMANAGER','OPERACIONES']
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Crecimiento I',
      url: '/sugerencias',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Crecimiento II',
      url: '/profundizar-temas-sesion',
      rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
    }
  ]
}

const REPORTES_MKT = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['MARKETING','OPERACIONES'],
  subItem: [
    {
      label: 'Reporte de Campaña',
      url: '/reporte-campaign',
      rol: ['MARKETING', 'OPERACIONES']
    },
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['MARKETING', 'OPERACIONES']
    },
    {
      label: 'Diario',
      url: '/reporte-diario-leads',
      rol: ['MARKETING', 'OPERACIONES']
    }
    ,
    {
      label: 'Cantidad Leads',
      url: '/reporte-cantidad-leads',
      rol: ['MARKETING', 'OPERACIONES']
    }
    ,
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['MARKETING','OPERACIONES']
    }
    ,
    {
      label: "Status Recursos",
      url: "/status-recursos",
      rol: ['OPERACIONES','MARKETING'],
    }
    ,
    {
      label: 'Calidad',
      url: '/reporte/calidad',
      rol: ['MARKETING', 'OPERACIONES']
    }
    ,
    {
      label: 'Satisfacción',
      url: '/proyectos-satisfaccion/',
      rol: ['MARKETING', 'OPERACIONES']
    }
  ]
}

const REPORTES_VENTAS = {
  label: 'Reportes',
  icon: 'fas fa-chart-bar mr-2',
  rol: ['VENTAS','OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['VENTAS', 'OPERACIONES','JEFE VENTAS']
    },
    {
      label: 'Diario',
      url: '/reporte-diario-leads',
      rol: ['VENTAS', 'OPERACIONES','JEFE VENTAS']
    },
    {
      label: 'Buyer Persona',
      url: '/reporte-indicadores',
      rol: ['VENTAS','OPERACIONES','JEFE VENTAS']
    }
  ]
}


const COMENTARIOS_VIDEO = {
  label: 'Ver Comentarios',
  icon: 'fas fa-comment mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: '/comentarios-videos'
}

const TEMAS_FORO = {
  label: 'Ver Temas',
  icon: 'fas fa-list mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: '/foro-producto'
}

const RECURSOS_DOCENTE = {
  label: 'Subir Materiales',
  icon: 'fas fa-upload mr-2',
  rol: ['DOCENTE', 'OPERACIONES'],
  url: "/recursos",
}

const PROVEEDORES_M = {
  label: 'Lista Proveedores',
  icon: 'fas fa-briefcase mr-2',
  rol: ['PRODUCTO', 'OPERACIONES','EXPCLIENTE','MARKETING','UXMANAGER'],
  url: "/proveedores-m",
}

const CONTRATOS_PROV = {
  label: 'Contratos',
  icon: 'fas fa-list mr-2',
  rol: ['PRODUCTO', 'OPERACIONES','EXPCLIENTE','MARKETING','UXMANAGER'],
  url: "/contratos-proveedores",
}

const BOLSA_DE_TRABAJO = {
  label: 'Ofertas laborales',
  icon: 'fas fa-edit mr-2',
  rol: ['RECLUTADOR', 'OPERACIONES'],
  url: '/bolsa-de-trabajo'
}

const POPUP = {
  label: 'Mensaje Intranet',
  icon: 'fas fa-commenting mr-2',
  rol: ['EXPCLIENTE', 'OPERACIONES'],
  subItem: [
    {
      label: 'POP-UP Intranet',
      url: '/popup/intranet',
      rol: ['EXPCLIENTE', 'OPERACIONES']
    }
  ]
}
const CONTRATOS = {
  label: 'Contratos',
  icon: 'fas fa-desktop mr-2',
  rol: ['FINANZAS', 'OPERACIONES'],
  url: '/contratos'
}

const AREA_TRABAJO_PV = {
  label: 'Área de trabajo',
  icon: 'fas fa-briefcase mr-2',
  rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: 'Progreso',
      url: '/reporte/seguimiento',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Crecimiento I',
      url: '/sugerencias',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Crecimiento II',
      url: '/profundizar-temas-sesion',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'SOS',
      url: '/reporte/desertados',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: "Discentes",
      url: "/matriculas",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
    },
    {
      label: 'PopUp',
      url: '/popup/intranet',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: "WhatsApp",
      url: "/grupos-whatsapp",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: "Producto",
      url: "/proyectos",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: "Docentes",
      url: "/profesores",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
  ]
}
const PROYECTOS = {
  label: "Producto",
  icon: "fas fa-desktop mr-2",
  rol: ['EXPCLIENTE', 'PRODUCTO', 'OPERACIONES'],
  url: '/proyectos'
}

const RANKINGVENTAS = {
  label: "Ranking",
  icon: "fas fa-line-chart mr-2",
  rol: ['EXPCLIENTE', 'VENTAS', 'OPERACIONES','JEFE VENTAS'],
  url: '/ventas/ranking'
}
const MODULOS = {
  label: "Sub-Producto",
  icon: "fas fa-desktop mr-2",
  rol: ['PRODUCTO', 'OPERACIONES'],
  url: '/modulos'
}

const DISCENTES =  {
  label: "Registro y Matrícula Discentes",
  icon: "fas fa-user mr-2",
  rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
  subItem: [
    {
      label: "Nuevo Discente",
      url: '/estudiantes',
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS']
    },
    {
      label: "Nueva matrícula",
      url: "/nueva-matricula",
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
    },
    {
      label: "Registro de Matrículas",
      url: "/matriculas",
      rol: ['PRODUCTO', 'OPERACIONES','FINANZAS'],
    }
  ],
}

const GESTION_CAMP =  {
  label: "Gestion de Campañas",
  icon: "fas fa-bullhorn mr-2",
  rol: ['OPERACIONES','MARKETING'],
  subItem: [
    {
      label: "Campañas",
      url: '/campaigns',
      rol: ['OPERACIONES','MARKETING']
    },
    {
      label: "Recursos",
      url: "/recursos-campaign",
      rol: ['OPERACIONES','MARKETING'],
    },
    {
      label: "Anuncios",
      url: "/anuncios-campaign",
      rol: ['OPERACIONES','MARKETING'],
    }
    ,
    {
      label: "Webinars",
      url: "/webinars-ventas",
      rol: ['OPERACIONES','MARKETING'],
    }
    
  ],
}


const DOCENTES = {
  label: 'Docentes',
  icon: 'fas fa-chalkboard-teacher mr-2',
  rol: ['PRODUCTO', 'EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  url: "/profesores"
}

const CAMPAIGNS = {
  label: 'Campañas',
  icon: 'fas fa-book mr-2',
  rol: ['MARKETING', 'OPERACIONES'],
  url: "/campaigns"
}

const LEADS = {
  label: 'Leads',
  icon: 'fas fa-list mr-2',
  rol: ['VENTAS', 'MARKETING', 'OPERACIONES','JEFE VENTAS'],
  url: "/leads"
}
const WEBINARS = {
  label: 'Webinars',
  icon: 'fas fa-video-camera mr-2',
  rol: ['OPERACIONES','JEFE VENTAS'],
  url: "/webinars-ventas"
}
const RECURSOS_CAMP = {
  label: 'Recursos',
  icon: 'fas fa-file-code-o mr-2',
  rol: ['OPERACIONES','JEFE VENTAS','VENTAS'],
  url: "/recursos-campaign"
}

  
const ENLACES = {
  label: 'Enlaces',
  icon: 'fas fa-link mr-2',
  rol: ['FINANZAS', 'OPERACIONES','JEFE VENTAS'],
  url: "/enlaces-finanzas"
}

const ASISTENCIA = {
  label: 'Asistencia Discentes',
  icon: 'fas fa-calendar-check mr-2',
  rol: ['EXPCLIENTE', 'OPERACIONES', 'TUTORIA'],
  subItem: [
    {
      label: "Registrar Asistencia",
      url: "/registro-asistencia",
    }
  ],
}

const RECURSOS = {
  label: 'Recursos',
  icon: 'fas fa-folder-plus mr-2',
  //style: {fontSize: '19px', marginRight: '5px'},
  rol: ['PRODUCTO', 'OPERACIONES','UXMANAGER','DOCENTE','EDITOR AUDIOVISUAL'],
  subItem: [
    {
      label: "Los materiales",
      url: "/recursos",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO','DOCENTE','EDITOR AUDIOVISUAL']
    },
    {
      label: 'La Actividad',
      url: '/tareas',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO']
    },
    {
      label: 'El Quiz',
      url: '/examenes',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO']
    }
  ],
}

const DIPLOMAS = {
  label: 'Diplomas',
  icon: 'fas fa-folder-plus mr-2',
  //style: {fontSize: '19px', marginRight: '5px'},
  rol: ['PRODUCTO', 'OPERACIONES','UXMANAGER'],
  subItem: [
    {
      label: "Numeración y Plantillas",
      url: "/bases-certificados",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO']
    },
    {
      label: "Emitir Diplomas",
      url: "/emitir-aptos",
      rol:['OPERACIONES','UXMANAGER','PRODUCTO']
    },
    {
      label: 'Diplomas Emitidos',
      url: '/diplomas-emitidos',
      rol:['OPERACIONES','UXMANAGER','PRODUCTO']
    }
  ],
}


const TUTORIA = {
  label: 'Gestión del Servicio',
  icon: 'fas fa-chalkboard-teacher mr-2',
  rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES'],
  subItem: [
    {
      label: 'Tutores',
      url: '/tutores',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    },
    {
      label: 'Mensajes',
      url: '/mensajeria',
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
    ,
    {
      label: 'Asistencias',
      url: "/registro-asistencia",
      rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
    }
  ]
}

const CONTROL_PAGOS = {
  label: 'Registro de Pagos',
  icon: 'fas fa-balance-scale mr-2',
  rol: ['PRODUCTO', 'OPERACIONES', 'FINANZAS'],
  url: '/pagos'

}

const FINANZAS = {
  label: 'Reportes',
  icon: 'fas fa-table mr-2',
  rol: ['PRODUCTO', 'OPERACIONES', 'FINANZAS'],
  subItem: [
    {
      label: 'Ingresos',
      url: '/reporte-ingresos'
    },
    {
      label: 'Cargos y descuentos',
      url: '/reporte-cargosdescuentos',
      rol: ['OPERACIONES','FINANZAS']
    } ,
    {
      label: 'Deudores',
      url: '/reporte-deudores'
    }
    ,
    {
      label: 'Viabilidad',
      url: '/indicadores-proyectos',
      rol: ['OPERACIONES','FINANZAS']
    }
  ]
}

const MENSAJERIA = {
  label: 'Mensajeria con Discentes',
  icon: 'fas fa-envelope mr-2',
  rol: ['EXPCLIENTE','OPERACIONES'],
  subItem: [
    {
      label: 'Mensajes',
      url: '/mensajeria'
    }
  ]
}

const WEBCHECKIN = {
  label: 'WebCheckin',
  icon: 'fas fa-calendar-check',
  rol: ['PRODUCTO', 'EXPCLIENTE', 'OPERACIONES', 'FINANZAS'],
  url: '/webcheckin'
}


const TRABAJADORES = {
  label: 'Tabajadores',
  icon: 'fas fa-users mr-2',
  rol: ['OPERACIONES','FINANZAS'],
  url: '/trabajadores'
}
const COMUNICADOS = {
  label: 'Comunicados',
  icon: 'fas fa-comment mr-2',
  rol: ['OPERACIONES'],
  url: '/listar-comunicados'
}
const BOLETASDEPAGO = {
  label: 'Boletas de Pago',
  icon: 'fas fa-file mr-2',
  rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','DOCENTE','VENTAS','JEFE VENTAS','RECLUTADOR','TI'],
  url: '/boletas-de-pago'
}
const ENLACESFORM = {
  label: 'Contratos',
  icon: 'fas fa-file mr-2',
  rol: ['VENTAS','EXPCLIENTE','OPERACIONES','JEFE VENTAS'],
  subItem: [
    {
      label: "Crear Enlace",
      url: "/contratos/enlaces",
    },
    {
      label: "Contratos Web",
      url: "/contratos",
    },
  ],
}

const EVENTOS = {
  label: "Eventos",
  icon: "fas fa-graduation-cap mr-2",
  rol: ['TUTORIA','EXPCLIENTE'],
  subItem: [
    {
      label: "Eventos",
      url: "/tutor/eventos",
    },
  ],
}

export const SIDEBAR_PRINCIPAL = [
 
/* {
    title: 'WebCheckin',
    rol: ['OPERACIONES'],
    rutas: [
      WEBCHECKIN,
    ]
  },*/
  {
    title: 'OPERACIONES',
    rol: ['OPERACIONES'],
    rutas: [
      COMUNICADOS,
      BOLETASDEPAGO,
    ]
  },
  {
    title: 'DOCUMENTOS',
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','VENTAS','JEFE VENTAS','RECLUTADOR','TI'],
    rutas: [
      BOLETASDEPAGO,
    ]
  },
  {
    title: 'COMENTARIOS',
    rol: ['DOCENTE'],
    rutas: [
      COMENTARIOS_VIDEO,
    ]
  },
  {
    title: 'RECURSOS',
    rol: ['DOCENTE'],
    rutas: [
      RECURSOS_DOCENTE,
    ]
  },
  {
    title: 'Foro',
    rol: ['DOCENTE'],
    rutas: [
      TEMAS_FORO,
    ]
  },
  {
    title: 'BOLSA DE TRABAJO',
    rol: ['RECLUTADOR','OPERACIONES'],
    rutas: [
      BOLSA_DE_TRABAJO,
    ]
  },

  {
    title: 'PROVEEDORES',
    rol: ['PRODUCTO', 'OPERACIONES','EXPCLIENTE','MARKETING','UXMANAGER'],
    rutas: [
      PROVEEDORES_M,
      CONTRATOS_PROV,
    ]
  },
  
  {
    title: 'PERSONAS',
    rol: ['EXPCLIENTE', 'OPERACIONES','UXMANAGER'],
    rutas: [
      AREA_TRABAJO_PV,
      REPORTES,
      TUTORIA
    ]
  },
  {
    title: 'MARKETING',
    rol: ['MARKETING', 'OPERACIONES'],
    rutas: [
      LEADS,
      GESTION_CAMP,
      REPORTES_MKT
    ]
  },
  {
    title: 'CURADURíA',
    rol: ['PRODUCTO', 'OPERACIONES','UXMANAGER','EDITOR AUDIOVISUAL'],
    rutas: [
      AREA_TRABAJO,
      DIPLOMAS,
      DOCENTES,
      RECURSOS,
      REPORTES_ACADEMICA
    ]
  },
  {
    title: 'FINANZAS',
    rol: ['FINANZAS', 'OPERACIONES'],
    rutas: [
      TRABAJADORES,
      DISCENTES,
      CONTROL_PAGOS,
      FINANZAS,
      CONTRATOS,
      ENLACES
    ]
  },
  {
    title: 'TUTORÍA',
    rol: ['TUTORIA'],
    rutas: [
      EVENTOS,
      ASISTENCIA,
      MENSAJERIA
    ]
  }, 
  {
    title: 'VENTAS',
    rol: ['VENTAS','EXPCLIENTE','OPERACIONES','JEFE VENTAS'],
    rutas: [
      LEADS,
      WEBINARS,,
      RECURSOS_CAMP,
      ENLACESFORM,
      RANKINGVENTAS,
      REPORTES_VENTAS
    ]
  },
]

// export const SIDEBAR_ADMIN = [
//   AREA_TRABAJO,
//   REPORTES,
//   PROYECTOS,
//   MATRICULA,
//   DOCENTES, 
//   ASISTENCIA,
//   RECURSOS,
//   DISCENTES,
//   TUTORIA,
//   FINANZAS,
//   MENSAJERIA,
//   TRABAJADORES
// ];


// export const SIDEBAR_ACADEMICA = [
//   AREA_TRABAJO,
//   REPORTES_ACANDEMICA,
//   PROYECTOS,
//   DOCENTES,
//   DISCENTES,
//   RECURSOS,
//   MENSAJERIA
// ]

// export const SIDEBAR_POSVENTA = [
//   REPORTES,
//   MENSAJERIA,
//   ASISTENCIA,
//   TUTORIA,
//   PROYECTOS,
//   DOCENTES,
//   DISCENTES,
//   FINANZAS
// ]

// export const SIDEBAR_CONTABILIDAD = [
//   FINANZAS
// ]

// export const SIDEBAR_TUTORIA = [
//   EVENTOS,
//   ASISTENCIA
// ]