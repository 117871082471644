import React, { useEffect, useState } from "react";
import Layout from "../../../../../app/Layout";
import HeaderContent from "../../../General/HeaderContent";
import Breadcrumb from "../../../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faListAlt, faPlus,faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../../../components/Spinners/SpinnerLoading";
import FormularioNuevoTema from "./FormularioNuevoTema"
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { obtenerTemas, eliminarTema } from "../../../../../actions/profesoresActions";
import { Button } from "react-bootstrap";
import { updateStateModalBasic } from "../../../../../actions/modalActions";
import ModalBasicwithRedux from "../../../../../containers/ModalBasicwithRedux";

const Temas = () => {
  //State
  const dispatch = useDispatch();

  const[loading, setLoading] = useState(true)
 const temas = useSelector(state=> state.profesores.temas)
  const handleModalNuevo = () => {
    dispatch(updateStateModalBasic(<FormularioNuevoTema />, 'Nuevo Tema'));
  }

  const confirmarEliminiacion = (datos) => {
    dispatch(eliminarTema(datos))
  }

  useEffect(() => {
    const fetchData = async () =>{
      const respuesta = await (dispatch(obtenerTemas()))
      setLoading(false);
    }

    fetchData();


  }, []);

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Temas por Áreas" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Área de Trabajo" },
                 { url: "", nombre: "Temas" },
              ]}
            />
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
            <div className="Trabajadores__acciones mb-2">
                <div className="text-right">
                  <Button
                    type='button'
                    variant="primary"
                    onClick={handleModalNuevo}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-1" />
                    Nuevo Tema
                  </Button>
                </div>
              </div>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Temas
                  </h5>
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(confirmarEliminiacion)}
                    tabla={temas}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Temas;

/**====================== TABLA =========== */

const colums = (confirmarEliminiacion) => [
  {
    text: '#',
    key: 'id',
    className: 'id',
    width: 50,
    cell: (_, index) => index+1
  },
  {
    text: "Tema",
    key: "tema_area",
    align: "center",
    sortable: true,
    className: "tema_area",
  },
  {
    text: "Area",
    key: "nombre_area",
    align: "area",
    className:"nombre_area",
    sortable: true,
    width:250
  },
  {
    text: "Acciones",
    key: "acciones",
    align: "area",
    className:"acciones",
    sortable: true,
    width:50,
    cell:(record)=>
     (<div className="text-center"><Button
          type="button"
          variant='danger'
          size='xs'
          onClick={()=>confirmarEliminiacion(record)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button></div>)
    
  },
 
];
