import { useState } from "react";

const useTotalCargos = (initialState = null) => {
  
  const [totalCargos, setTotalCargos] = useState(initialState);
  
  const obtenerTotalCargos = (cargosVariables) => {
    let totalCargosObtenido = 0;
    if (cargosVariables.length) {
      cargosVariables.forEach(cargos => {
        totalCargosObtenido+=parseFloat(cargos.total);
      })
    }
    setTotalCargos(totalCargosObtenido);
  }

  return { totalCargos, obtenerTotalCargos };
}
 
export default useTotalCargos;