import React from 'react';
import { Link, useHistory } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux'; 
import { guardarCapacitacionActivo } from '../../../actions/controlCapacitacionesActions';

const CardControlCapacitacion = ({ capacitacion }) => {

  const { nombre_proyecto = '', id_proyecto } = capacitacion
  const history = useHistory()
  const dispatch = useDispatch()

  const redireccionAreaTrabajo = () => {
    dispatch( guardarCapacitacionActivo(capacitacion) )
    history.push(`/control-capacitaciones/${id_proyecto}/espacio-trabajo`)
  }

  return ( 
    <div className="card-capacitacion rounded shadow">
      <div className="content detalles-evento p-3">
        <h5 className="mb-3 detalles-evento__title">Nombre de evento:</h5>
        <p>{nombre_proyecto}</p>
        <button
          className='btn btn-success mt-4'
          onClick={() => redireccionAreaTrabajo()}
        >
          Ir a panel de control
        </button>
      </div>
    </div>
   );
}
 
export default CardControlCapacitacion;