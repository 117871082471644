import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ListaDocentesDisponibles from './ListaDocentesDisponibles';
import ListaDocenteTentativos from './ListaDocentesTentativos';
import ListaDocentesConfirmados from './ListaDocentesConfirmados';
import './AccionesTablaControl.scss';
import ImagesConfirmacion from './ImagesConfirmacion';
import ImagesEvidencia from './ImagesEvidencia'
const AccionesTablaControl = () => {
  return ( 
    <div className="acciones-tabla-control">
      <section>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <ListaDocentesDisponibles />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <ListaDocenteTentativos />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <ListaDocentesConfirmados />
          </Col>
          <Col xs={12} className='mt-3'>
            <ImagesConfirmacion/>
          </Col>
          <Col xs={12} className='mt-3'>
            <ImagesEvidencia/>
          </Col>
          
        </Row>
      </section>
    </div>
   );
}
 
export default AccionesTablaControl;