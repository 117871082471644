import { 
    LISTAR_ESTUDIANTES,
    ELIMINAR_MATRICUA_MODULO,
    LISTAR_MODULOS_COMPRADOS,
    REMOVER_STATE_ESTUDIANTE,
    REMOVER_MATRICULAR_PROYECTO,
    LISTAR_MODULOS_ESTUDIANTES,
    LISTAR_ESTUDANTE_PROYECTO,
     ELIMINAR_ESTUDIANTE,
     MOSTRAR_ESTUDIANTE,
     EDITAR_ESTUDIANTE,
     LISTAR_ESTUDIANTE,
     LISTAR_PROYECTOS_ESTUDIANTES,
     REMOVER_ESTADO,
     INSERTAR_ESTUDIANTES,
     SELECT_ESTUDIANTE_OBTENIDO,
     estudianteTypes
} from './types';

import authAxios from '../config/authAxios';
import Swal from 'sweetalert2/src/sweetalert2';

export const listarEstudiantes = () => async dispatch => {

    try {
        let estudiantes = await authAxios.get(`/admin/es_mostrar_Estudiantes`)

    dispatch({
        payload: estudiantes.data,
        type: LISTAR_ESTUDIANTES
    })
    } catch (error) {
        console.log(error)
    }
    
}

//Listar estudiantes para el select
export const listarEstudiantesSelect = () => {
    return async dispatch => {

        try {
            const discentes = await authAxios.get(`/admin/es_mostrar_Estudiantes`)

            let dataDiscentesSelect = []
            discentes.data.map(discente => {
                dataDiscentesSelect = [...dataDiscentesSelect, {
                    value: discente.id_estudiante,
                    label: `${discente.nombre_usuario} ${discente.apellido_usuario}`
                }]
            })

            dispatch( guardarEstudiantesSelect(dataDiscentesSelect) )
        } catch (error) {
            console.log(error)
        }
    }
}

const guardarEstudiantesSelect = dataDiscentesSelect => {
    return {
        type: SELECT_ESTUDIANTE_OBTENIDO,
        payload: dataDiscentesSelect
    }
}

export const mostrarEstudiante = (value, q = 'id') => async dispatch => {
    try {
        let estudiante = await authAxios.get(`/admin/mostrarEstudianteId?q=${q}&value=${value}`)

        dispatch({
            payload: estudiante.data,
            type: MOSTRAR_ESTUDIANTE
        })
    } catch (error) {
        console.log(error)
    }
    
}

export const listarProyectosEstudiante = idProyecto => async dispatch => {
    try {
        let estudiantes = await authAxios.get(`/admin/pryt_mostrar_MatriculasProyecto__IdEstudiante/${idProyecto}`)
        dispatch({
            payload: estudiantes.data,
            type: LISTAR_PROYECTOS_ESTUDIANTES
        })
    } catch (error) {
        console.log(error)
    }
   
}

export const listarEstudianteProyecto = idProyecto => async dispatch => {
    try {
        let estudiantesProyectos = await authAxios.get(`/admin/es_mostrarEstudiantes__IdProyecto/${idProyecto}`)
    
        dispatch({
            payload: estudiantesProyectos.data,
            type: LISTAR_ESTUDANTE_PROYECTO
        })
    } catch (error) {
        console.log(error)
    }
} 

export const listarModulosEstudiantes = (idProyecto, idEstudiante) => async dispatch => {
    try {
        let modulosEstudiantes = await authAxios.get(`/admin/mdlo_MostrarModulos__IdEstIdPryt/${idEstudiante}/${idProyecto}`)
        
        dispatch({
            payload: modulosEstudiantes.data,
            type: LISTAR_MODULOS_ESTUDIANTES
        })
    } catch (error) {
        console.log(error)
    }
}

export const listarModulosCompradosEstudiante = (idEstudiante) => async dispatch => {
    try {
        let modulos = await authAxios.get(`/admin/mdlo_mostrar_MatriculasModulo__IdEstudiante/${idEstudiante}`)

        dispatch({
            payload: modulos.data,
            type: LISTAR_MODULOS_COMPRADOS
        })
    } catch (error) {
        console.log(error)
    }
}

export const removerProyectosEstudiante = idMatricula => async dispatch => {
    try {
        let respuesta = await authAxios.delete(`/admin/matr_eliminarMatriculaProyecto/${idMatricula}`)
        
       // console.log(respuesta.data)
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: REMOVER_MATRICULAR_PROYECTO
            })

            return{mensaje: true}
        }
        return {mensaje: false}
    } catch (error) {
        console.log(error)
    }
} 

export const eliminarModuloCompradoE = idMatriculaModulo => async dispatch => {
    try {
        let respuesta = await authAxios.delete(`/admin/matr_eliminarMatriculaModulo/${idMatriculaModulo}`)        
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: ELIMINAR_MATRICUA_MODULO
            })

            return {mensaje: true}
        }
        return {mensaje: false}
    } catch (error) {
        console.log(error)
        return {mensaje: false}
    }
}

export const insertarEstudiantes = datosEstudiante => async dispatch => {
    try {
        let estudiantes = await authAxios.post(`/admin/insertarEstudiantePorAdmin`, datosEstudiante)
    let payload = {
        estudiantes: estudiantes.data,
        estadoInsercion: estudiantes.status
    }
    if (!estudiantes.data.error) {
        
        dispatch({
            payload: payload,
            type: INSERTAR_ESTUDIANTES
        })

        return {mensaje: true}
    }

    return {mensaje: false}
    } catch (error) {
        console.log(error)
        return{mensaje: false}
    }
    
}

export const enviarCorreo = datosEstudiante => {

    return async dispatch => {

        const swalWithBootstrapButtons = Swal.mixin({
         
        });
        swalWithBootstrapButtons
          .fire({
            title: "¿Enviar correo?",
            text: "Recuerda no reenviar correos repetidamente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, continuar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async function() {
                try {
    
                    let correo = await authAxios.post(`/admin/enviarCorreoCredenciales`, datosEstudiante)
                    if (!correo.data.error) {
                       return true
                    }

                    } catch (error) {
                       Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: error.response.data.msg
                        })
                    }
          },
            allowOutsideClick: () => !Swal.isLoading()
          })
          .then(result => {
            //mensaje que se cambio la contraseña satisfactoriamente
            // console.log(result)
            if (result.value === true) {
            
              swalWithBootstrapButtons.fire(
                "Enviado!",
                "Correo enviado con éxito.",
                "success"
              );
             
            }
          });
    };
   
}


export const listarEstudiante = (value, q = 'id') => async dispatch => {
    dispatch({
        type: estudianteTypes.OBTENER_ESTUDIANTE
    })
    try {
        let estudiantes = await authAxios.get(`/admin/mostrarEstudianteId?q=${q}&value=${value}`)
    
    dispatch({
        payload: estudiantes.data,
        type: estudianteTypes.OBTENER_ESTUDIANTE_EXITO
    })
    } catch (error) {
        console.log(error)
    }
}

export const editarEstudiante = (idUsuario, dataEstudiante) => async dispatch => {

    try {
        let respuesta = await authAxios.put(`/admin/editarEstudiante/${idUsuario}`, dataEstudiante)
        
        let payload = {
            estudiantes: respuesta.data,
            estadoInsercion: respuesta.status
        }
        if (!respuesta.data.error) {
            dispatch({
                payload: payload,
                type: EDITAR_ESTUDIANTE
            })
            return {mensaje: true}
        }
        return {mensaje: false}
    } catch (error) {
        console.log(error)
        return {mensaje: false}
    }

    
}

export const listarCursosEstudiante = idEstudiante => async dispatch => {
    try {
        let cursos = await authAxios.get(`/admin/mostrarCursosIdEstudiante/${idEstudiante}`)

        dispatch({
            payload: cursos.data,
            type: LISTAR_PROYECTOS_ESTUDIANTES
        })
    } catch (error) {
        console.log(error)
    }
}

export const eliminarEstudiante = idUsuario => async dispatch => {
    try {
    let respuesta = await authAxios.delete(`/admin/eliminarUsuarioIdUsuario/${idUsuario}`)

    if (!respuesta.data.error) {
        let payload = {
            estudiantes: respuesta.data,
        }
    
        dispatch({
            payload: payload,
            type: ELIMINAR_ESTUDIANTE
        })

        return {mensaje: true}
    }
    return {mensaje: false}
    

    } catch (error) {
        console.log(error)
        return {mensaje: false}
    }
}    

export const removerEstado = () => dispatch => {
    dispatch({
        payload: 0,
        type: REMOVER_ESTADO
    })
}

export const removerModulos = () => dispatch => {
    dispatch({
        payload: 0,
        type: REMOVER_ESTADO
    })
}

export const removerStateEstudiante = () => dispatch => {
    dispatch({
        type: REMOVER_STATE_ESTUDIANTE
    })
}