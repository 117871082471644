import React from 'react';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

const BotonesDescargas = ({ handleUrlDescarga, pdf = true, excel = true, csv = false }) => {

  return ( 
    <div className="BotonesDescargas">
      <div className="botones-acciones mt-3">
        {
          excel && <Button href={handleUrlDescarga('excel')} variant='light' className='btn-icon'>
            <FontAwesomeIcon icon={faFileExcel} />
            Descargar Todo .xlsx
          </Button> 
        }
        {
          pdf && <Button href={handleUrlDescarga('pdf')} variant='light' className='btn-icon'>
            <FontAwesomeIcon icon={faFilePdf} />
            Descargar .pdf
          </Button> 
        }
        {
          csv && <Button href={handleUrlDescarga('csv')} variant='light' className='btn-icon'>
            <FontAwesomeIcon icon={faFilePdf} />
              Descargar .csv
          </Button> 
        }
      </div>
    </div>
  );
}
 
export default BotonesDescargas;