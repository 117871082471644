import React from 'react';
import Layout from '../../../../app/Layout';
import ResultadoDiscente from '../../../components/Finanzas/ResultadoDiscente';
import FormBusquedaDiscente from '../../../components/Finanzas/FormBusquedaDiscente';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import './RegistroPagos.scss';

//Redux
import { useSelector } from 'react-redux';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';

const RegistroPagos = () => {

  const resultadoDiscente = useSelector(state => state.pagos.datosFinanzasDiscente.todo);
  const { error, loading } = useSelector(state => state.pagos);
  const params = new URLSearchParams(window.location.search);
  const tipo = params.get('q');
  const value = params.get('value');  

  const mostrarResultados = () => {
    if (tipo && value && Object.keys(resultadoDiscente).length && !error) {
      return <ResultadoDiscente />;
    }
    return null;
  }

  return (
    <Layout>
      <div className="RegistroPagos">
        <div className="RegostroPagos__head">
          <HeaderContent title='Registro de pagos' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/pagos", nombre: "Registro de pagos" },
            ]}
          />    
        </div>
        <div className="RegistroPagos__body">
          <FormBusquedaDiscente />
          <SpinnerLoading loading={loading} error={error}>
            {mostrarResultados()}
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}

export default RegistroPagos;