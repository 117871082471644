import React from 'react';
import TimeLine from "react-gantt-timeline";

const GanttDiagram = ({proyectos, modo}) => {

    let data=[]
    const config={
      header:{ //Targert the time header containing the information month/day of the week, day and time.
          top:{//Tartget the month elements
              style:{backgroundColor:"#1E4D77"} //The style applied to the month elements
          },
          middle:{//Tartget elements displaying the day of week info
              style:{backgroundColor:"#FF532B"}, //The style applied to the day of week elements
              selectedStyle:{backgroundColor:"#b13525"}//The style applied to the day of week elements when is selected
          },
          bottom:{//Tartget elements displaying the day number or time 
              style:{background:"#94C9FF",fontSize:9, color:'black'},//the style tp be applied 
              selectedStyle:{backgroundColor:"#b13525",fontWeight:  'bold'}//the style tp be applied  when selected
          }
      },
      taskList:{//the right side task list
          title:{//The title od the task list
              label:"Productos",//The caption to display as title
              style:{backgroundColor:  '#1E4D77',borderBottom:  'solid 1px silver',
                     color:  'white',textAlign:  'center'}//The style to be applied to the title
          },
          task:{// The items inside the list diplaying the task
              style:{backgroundColor:  '#fbf9f9', textAlign:'left'},// the style to be applied,
              
          },
          verticalSeparator:{//the vertical seperator use to resize he width of the task list
              style:{backgroundColor:  '#1E4D77',},//the style
              grip:{//the four square grip inside the vertical separator
                  style:{backgroundColor:  '#cfcfcd'}//the style to be applied
              }
          },
      },
      dataViewPort:{//The are where we display the task
          rows:{//the row constainting a task
              style:{backgroundColor:"#fbf9f9",borderBottom:'solid 0.5px #cfcfcd'}
              },
          task:{//the task itself
              showLabel:false,//If the task display the a lable
              style:{position:  'absolute',borderRadius:14,color:'white',
                     textAlign:'center',backgroundColor:'grey'},
               selectedStyle:{}//the style tp be applied  when selected
          }
      },
      links:{//The link between two task
          color:'black',
          selectedColor:'#ff00fa'
      }
  }

    const getRandomColor = () => {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
    const setDataGantt = ()=>{
        let datos = []
        proyectos.map((proyecto,i)=>{
          
              let fila = {
                  id : proyecto.id_proyecto,
                  start : proyecto.fecha_inicio_proyecto,
                  end : proyecto.fecha_fin_proyecto,
                  name : proyecto.nombre_proyecto,
                  color : proyecto.color ? proyecto.color : '#1E4D77'
              }
              datos.push(fila)
        })

      return datos
    }

    data = setDataGantt()
    return (
        <TimeLine data={data} mode={modo} config={config} nonEditableName="Disable" itemheight={25}/>
    );
};

export default GanttDiagram;