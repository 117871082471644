import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { guardarHonorariosDocente } from '../../actions/profesoresActions';

const withEditarHonorarios = WrappedComponent => props => {

  const [docente, idDocente] = useGetDocente();
  const dispatch = useDispatch();

  const handleEditarHonorarios = (datos) => {
    dispatch( guardarHonorariosDocente(datos, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      honorarios={docente.honorarios}
      handleEditarHonorarios={handleEditarHonorarios}
    />
   );
}
 
export default withEditarHonorarios;