import React from 'react';
import { Modal } from 'react-bootstrap'
import './ModalLarge.scss';

const ModalLarge = (props) => {
 
  const { show,updateModalLarge, title, children } = props

  return ( 
    <Modal
      show={show}
      onHide={() => updateModalLarge()}
      dialogClassName="modal-100w"
      aria-labelledby="example-custom-modal-styling-title"
      size='lg'
      className='ModalLarge'
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <h4>{ title }</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }
      </Modal.Body>
    </Modal>
   );
}
 
export default ModalLarge;