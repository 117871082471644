import React from "react";
import HeaderContent from "../General/HeaderContent";
import { useEffect, useState } from "react";
import MaterialtableDemo from '../../components/plugins/MaterialTableDemo'
import ModalInsertarSesiones from './modal/ModalInsertarSesiones'
import * as moment from 'moment'
import Swal from 'sweetalert2'
//REDUX
import { buscarClass} from '../../../helpers/functions'
import { connect } from "react-redux";
import { buscarCurso, listarSesionesModulo, mostrarCursos, eliminarSesion, listarSesion} from "../../../actions/cursosActions";
import Breadcrumbs from "../General/Breadcrumb";
import { useHistory, useParams } from 'react-router-dom';
import Layout from "../../../app/Layout";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";

const SesionesCurso = (props) => {

  const { idProyecto } = useParams();
  const { curso, listarSesion,usuario, buscarCurso, sesionesCurso, listarSesionesModulo, mostrarCursos, eliminarSesion }= props

  const [guardarSeleccionSesion, setguardarSeleccionSesion] = useState(null)
  const [datatablaCurso, setDataTablaCurso] = useState([])
  const [sesionSeleccionado, setSesionSeleccionado] = useState({})

  const history = useHistory()
  const guardarSeleccion = idSesion => {
    setguardarSeleccionSesion(idSesion)
    actualizarBotones(false)
  }

  //ACTUALIZAR ESTADO DE LOS BOTONES
  const actualizarBotones = valor => {
    const botones = buscarClass('acciones-header')
    botones.forEach(boton => {
      if (valor) {
        boton.setAttribute('disabled', `true`)
      } else {
        boton.removeAttribute('disabled')
      }
    })
  }
  //REDIRIGIR A LOS PROFESORES POR SESION
  const redirigirProfesores = (e) => {
    e.preventDefault();
    history.push(`sesiones/${guardarSeleccionSesion}/profesores`);
  }
  //REDIRIGIR A LOS RECURSOS POR SESION
  const redirigirRecursos = (e) => {
    e.preventDefault();
    history.push(`sesiones/${guardarSeleccionSesion}/recursos`);
  }
  //REDIRIGIR A LOS NOTAS POR SESION
  const redirigirNotas = (e) => {
    e.preventDefault();
    history.push(`sesiones/${guardarSeleccionSesion}/notas`);
  }

  //BUSCAR SESION PARA EDITAR
  const buscarSesion = (e, sesion) => {
    e.preventDefault()
    listarSesion(sesion.id_sesion)
  }

  //ELIMINAR SESION
  const eliminarSesionSeleccionado = (sesion) => {

    if (usuario.id_tp_nivel_usuario === '5') {
      avisoError('Acceso denegado')
      return
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title:`Desea eliminar "${sesion.nombre_sesion}"`,
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          if((await eliminarSesion(sesion.id_sesion, idProyecto)).mensaje) {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Hubo un error"
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Sesión se elimino correctamente",
            "success"
          );
        }
      });
    
  }
  //FUNCION PARA LLENAR TABLA
  const llenarDataSesionesTabla = () => {
    let dataGenerado = [];
    sesionesCurso.map(sesion => {
      dataGenerado = [
        ...dataGenerado,
        {
          radio: 
            <div className="custom-control custom-radio">
              <input type="radio" className="custom-control-input" onChange={() =>
                guardarSeleccion(sesion.id_sesion)} id={sesion.id_sesion} name="sesiones" />
              <label className="custom-control-label" htmlFor={sesion.id_sesion}></label>
            </div>,
          // <input onChange={() =>
          //   guardarSeleccion(sesion.id_sesion)
          // } type="radio" name="curso" id={sesion.id_sesion} />,
          orden: sesion.nro_orden,
          id: sesion.id_sesion,
          nombre_sesion: sesion.nombre_sesion,
          fecha_sesion: moment(sesion.fecha_sesion).format('DD MMMM YYYY'),
          button: (
            <React.Fragment>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={() => eliminarSesionSeleccionado(sesion)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
              <button
                className="btn btn-warning text-white btn-sm"
                onClick={() => setSesionSeleccionado(sesion)}
                data-toggle="modal" data-target=".modal-sesiones-curso" data-whatever="@mdo"
              >
                <i className="far fa-edit"></i>
              </button>
            </React.Fragment>
          )
        }
      ];
    });
    setDataTablaCurso(dataGenerado)
  };

  useEffect(() => {
    buscarCurso(idProyecto); //Llamar al curso
    mostrarCursos()
    actualizarBotones(true)
      
    listarSesionesModulo(idProyecto)//Llamar  sesiones

  }, []);

  useEffect(() => {
    
      llenarDataSesionesTabla()
    
  }, [sesionesCurso])

  return (
    <Layout>
      <HeaderContent
        title={"Sesiones"}
        icon={"menu_book"}
        subItem={curso.nombre_modulo}
      />
      <Breadcrumbs
        link={[
          {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
          {url:'/cursos', nombre: 'Módulos'},
          {url:'', nombre: 'Sesiones'}
        ]}
      />

      <div className="clearfix">
        <div className="container-fluid px-0 content-actions my-3">
          <div className="row">
            <div className="col-md-9">
              <button
                type="button"
                className="mr-1 btn btn-success btn-sm align-items-center acciones-header"
                onClick={e => redirigirProfesores(e)}
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons mr-1">list_alt</i>Profesores
                </p>
              </button>
              {/* <button
                type="button"
                className="mr-1 btn btn-info btn-sm align-items-center acciones-header"
                onClick={e => redirigirRecursos(e)}
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">create_new_folder</i>Recursos
                </p>
              </button> */}
              <button
                type="button"
                className="mr-1 btn btn-danger btn-sm align-items-center acciones-header"
                onClick={e => redirigirNotas(e)}
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">create_new_folder</i>Notas Sesion
                </p>
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary float-right text-white"
                data-toggle="modal" data-target=".modal-sesiones-curso"
                onClick={() => setSesionSeleccionado({})}
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">add</i>
                  <span className="text-white"> Añadir sesiones</span>
                </p>
              </button>
              <ModalInsertarSesiones
                idCurso={idProyecto}
                sesion={sesionSeleccionado}
              />
            </div>
          </div>
        </div>

        <div className="p-0 mb-5 bg-white">
          <div className="continer-fluid border-bottom-0 border">
            <h5
              className="p-3 d-flex align-items-center mb-0"
              style={{ color: "#434343" }}
            >
              <i className="fas fa-list pr-2"></i>Lista de Sesiones
            </h5>
          </div>
          <div className="tabla border">
            <React.Fragment>
              <MaterialtableDemo colums={colums} tabla={datatablaCurso} />
            </React.Fragment>
          </div>
        </div>
      </div>
      
    </Layout>
  );
};
const mapStateToProps = state => ({
  curso: state.cursos.curso,
  sesionesCurso: state.cursos.sesionesCurso,
  usuario: state.usuario.usuario
});

export default connect(mapStateToProps, { buscarCurso, listarSesion, listarSesionesModulo, mostrarCursos, eliminarSesion })(SesionesCurso);

const colums = [
    {
        text: "",
        key: "radio",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Id",
        key: "id",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Nombre sesión",
        key: "nombre_sesion",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Fecha",
        key: "fecha_sesion",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Acciones",
        key: "button",
        align: "left",
        sortable: true,
        className: "type_of_food"
    }
]