import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';


const PieChart = ({asistencia, inasistencia}) => {
    
  const [labels, setLabels] = useState([
      'Asistencia', 'Inasistencia', 'Justicadas'
  ])
  const [dataSets, setDataSets] = useState([{
      data: [20, 30, 2],
      backgroundColor: ['#18BB60', '#E74C3C', 'yellow']
  }])
  // if (asistencia === 0 && inasistencia === 0 ) {
  //     return(
  //         <div className="asistencia-nulo bg-warning text-white">
  //             <p>No hay asistencias</p> 
  //         </div>
          
  //     )
  // }
  return ( 
      <div>
          <Pie
              data={{
                  labels: labels,
                  datasets: dataSets
              }}
              height={200}
          />
      </div>
   );
}

export default PieChart;