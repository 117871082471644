import React from "react";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Swal from "sweetalert2";
//REDUX
import { connect } from "react-redux";
import {
  listarNotas,
  modificarNotas,
  removerEstadoNotas,
} from "../../../actions/notasActions";
import { listarSesion } from "../../../actions/cursosActions";
import { useEffect } from "react";
import TablaListadoNotas from "./tablas/TablaListadoNotas";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import Layout from "../../../app/Layout";

const NotasSesiones = (props) => {

  const { idSesion } = useParams();
  //State
  const { notasSesiones, sesion, estadoSesion } = props;
  //funciones
  const {
    listarNotas,
    modificarNotas,
    removerEstadoNotas,
    listarSesion,
  } = props;

  const [estudiantesEditados, setEstudiantesEditados] = useState([]);

  const llamarAlumnosNotas = () => {
    listarNotas(idSesion);
  };

  const guardarNotas = (e) => {
    e.preventDefault();
    modificarNotas(idSesion, estudiantesEditados);
  };

  const avisoConfirmacion = () => {
    if (estadoSesion === 200) {
      Swal.fire("Notas guardadas", "Las notas se guardaron", "success");
    }
  };

  useEffect(() => {
    avisoConfirmacion();
    removerEstadoNotas();
    listarSesion(idSesion);
  }, [estadoSesion]);

  useEffect(() => {
    llamarAlumnosNotas();
  }, [notasSesiones.length]);

  return (
    <Layout>
      <HeaderContent title="Notas" subItem={`${sesion.nombre_sesion}`} />
      <Breadcrumb />
      <form onSubmit={(e) => guardarNotas(e)}>
        <TablaListadoNotas
          setEstudiantesEditados={setEstudiantesEditados}
          estudiantesEditados={estudiantesEditados}
        />
      </form>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  notasSesiones: state.notas.notasSesiones,
  estadoSesion: state.notas.estadoSesion,
  sesion: state.cursos.sesion,
});

export default connect(mapStateToProps, {
  listarNotas,
  modificarNotas,
  listarSesion,
  removerEstadoNotas,
})(NotasSesiones);
