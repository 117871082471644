import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { pagarCuotaExtra } from '../../actions/pagosActions';

const withRegistroFacturaExtra = WrappedComponent => props => {
  
  const dispatch = useDispatch();

  const handleRegistrarPagoExtra = (datosFactura, idMatricula) => {
    dispatch(pagarCuotaExtra(datosFactura, idMatricula));
  }

  return ( 
    <WrappedComponent
      {...props}
      handleFormSubmit={handleRegistrarPagoExtra}
    />
  );
}
 
export default withRegistroFacturaExtra;