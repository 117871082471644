import React from 'react';
import ListaMensajes from './ListaMensajes';
import VistaMensaje from './VistaMensaje';

//Redux
import {connect} from 'react-redux'

const Enviados = (props) => {

  const {enviadosMensaje, guardarMensajesSeleccionados, moverMensajePapelera} = props

    return (
      <div className="row">
        <div className="col-md-5 pr-0">
          <div className="container-lista bg-white border shadow" style={{ height: "500px" }}>
            <div className="container px-3">
              <ListaMensajes title="Enviados"
                mensajes={enviadosMensaje}
                guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                moverMensajePapelera={moverMensajePapelera}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <VistaMensaje />
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
  enviadosMensaje: state.mensajes.enviadosMensaje
})
 
export default connect(mapStateToProps, {})(Enviados);