import React from 'react';

const TotalPagar = ({ montoResumen }) => {
  return ( 
    <div className="TotalPagar">
      <h4>Subtotal S/{montoResumen}</h4>
    </div>
  );
}
 
export default TotalPagar;