import React from 'react';
import { useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listarProfesores } from '../actions/profesoresActions';

const whithGetDocentes = WrappedComponent => props => {
  
  const dispatch = useDispatch()
  const docentes = useSelector(state => state.profesores.profesores)

  useEffect(() => {
    if (!docentes.length) {
      dispatch( listarProfesores() ) 
    }
  }, [])
  
  return ( 
    <WrappedComponent
      {...props}
      docentes={docentes}
    />
  );
}
 
export default whithGetDocentes;