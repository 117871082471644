import React, { useState } from 'react';
import Select from 'react-select';

const useSelectProyecto = (initialSelect = null) => {

  const anios = [
    {
      id:"2019",
      value:"2019"
    },
    {
      id:"2020",
      value:"2020"
    },
    {
      id:"2021",
      value:"2021"
    },
    {
      id:"2022",
      value:"2022"
    },
    {
      id:"2023",
      value:"2023"
    },
    {
      id:"2024",
      value:"2024"
    }
    ,
    {
      id:"2025",
      value:"2025"
    }
    ,
    {
      id:"2026",
      value:"2026"
    }
    ,
    {
      id:"2027",
      value:"2027"
    }
]
  const [anioSeleccionado, setanioSeleccionado] = useState(null); 

    const handleChange = anioSeleccionado =>{
        setanioSeleccionado(anioSeleccionado);
       // console.log(anioSeleccionado);
    }

  const SimpleSelect = props => {
    return (
      <Select
        isMulti
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isSearchable={true}
        onChange={handleChange}
        value={anioSeleccionado}
        getOptionValue={anios => anios.id}
        getOptionLabel={anios => anios.value}
        options={anios}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        closeMenuOnSelect={false}
        {...props}
      />
    )
  }

  return [anioSeleccionado, SimpleSelect, setanioSeleccionado]

}
 
export default useSelectProyecto;