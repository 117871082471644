import React from 'react';
import EditarUsuarioSesionWithTrabajador from '../../../../../../containers/TrabajadoresContainers/EditarUsuarioSesionWithTrabajador';
import EditarPasswordWithTrabajador from '../../../../../../containers/TrabajadoresContainers/EditarPasswordWithTrabajador';
import { Modal } from 'react-bootstrap';

const EditarDatosSesionTrabajador = () => {
  return ( 
    <div className="EditarDatosSesionTrabajador">
      <Modal.Body>
        <EditarUsuarioSesionWithTrabajador />
        <EditarPasswordWithTrabajador />
      </Modal.Body>
    </div>
   );
}
 
export default EditarDatosSesionTrabajador;