import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const EditarNombreUsuario = ({ usuario, handleSubmitUsuario }) => {

  const [nombre, setNombre] = useState('')

  const handleSubmit = e => {
    e.preventDefault();
    if (nombre === '') {
      avisoError('No se ha ingresado el nombre de usuario');
      return;
    }
    handleSubmitUsuario(nombre);
  }

  useEffect(() => {
    if (usuario) {
      setNombre(usuario)
    }
  }, [usuario])

  return ( 
    <div className="EditarNombreUsuario">
      <div className="EditarNombreUsuario__container">
        <div className="EditarNombreUsuario__head">
          <h5>Editar nombre de usuario</h5>
        </div>
        <div className="EditarNombreUsuario__body mt-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control
                type='text'
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='text-right'>
              <Button
                type='submit'
                variant='info'
                className='text-white btn-icon'
              >
                <FontAwesomeIcon icon={faSave} />
                Guardar
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
   );
}

EditarNombreUsuario.propTypes = {
  usuario: PropTypes.string,
  handleSubmitUsuario: PropTypes.func.isRequired
}
 
export default EditarNombreUsuario;