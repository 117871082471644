import React from 'react';

const SelectVariable = props => {
    const {datos,primerSelect, name, handleChange, value, isMulti} = props;

    return (
        <select multiple={isMulti} className="form-control" value={value} name={name} onChange={handleChange} >
            <option value="">{primerSelect}</option>
            {
                datos.map((dato,i)=>(
                  <option key={i} value={dato.id}>{dato.value}</option>
                ))
            }
        </select>
    );
};

export default SelectVariable;