import {leadstypes} from '../actions/types'

const initialState = {
    leads: [],
    loading:false,
    asesores:[],
    estados:[],
    filtrado:[],
    respuesta_importacion:null,
    observaciones:[]
}

export default function(state = initialState, action){
    switch (action.type) {
        case leadstypes.LISTAR_LEADS_EXITO:
            return{
                ...state,
                leads: action.payload,
                loading:false,
                filtrado:[]
            }
        case leadstypes.LISTAR_LEADS:
                return{
                    ...state,
                    loading:true,
                }
        case leadstypes.LISTAR_ASESORES:
                return{
                    ...state,
                    asesores: action.payload,
                }
        case leadstypes.LISTAR_ESTADOS_LEAD:
            return{
                ...state,
                estados: action.payload,
            }
        case leadstypes.LISTAR_OBSERVACIONES_LEAD:
            return{
                ...state,
                observaciones: action.payload,
            }
        case leadstypes.FILTRAR_LEADS:
                return{
                    ...state,
                    leads: action.payload,
                    filtrado:action.payload,
                    loading:false
                }
        case leadstypes.IMPORTAR_LEADS_EXITO:
            return{
                ...state,
                respuesta_importacion: action.payload,
            }
        default:
            return state
    }
} 