import React from 'react';
import { Form } from 'react-bootstrap';
import './VistaClasificacion.scss';
import ModalLargewhithRedux from '../../../../../containers/ModalLargewhithRedux';
import DocenteClasificacionwithRedux from '../../../../../containers/DocentesContainers/DocenteClasificacionwithRedux';
import DocenteTemasAreawithRedux from '../../../../../containers/DocentesContainers/DocenteTemasAreawithRedux';
import ModalCustomWidthwithRedux from '../../../../../containers/ModalCustomWidthwithRedux';

const VistaClasificacionDocente = () => {
  return ( 
    <Form.Group className="VistaClasificacionDocente">
      <DocenteClasificacionwithRedux />
      <DocenteTemasAreawithRedux />
      <ModalLargewhithRedux />
      <ModalCustomWidthwithRedux />
    </Form.Group>
   );
}
 
export default VistaClasificacionDocente;