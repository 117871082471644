import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioPopUp from './FormularioPopUp';
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { eliminarComunicado, listarComunicados } from "../../../actions/popupsActions";
import { Button } from "react-bootstrap";
 
const Comunicados = () => {
  //State
  const dispatch = useDispatch();

  const {comunicados,loading} = useSelector(state=>state.comunicados)

  useEffect(() => {
   dispatch(listarComunicados())
  }, []);

  const handleModalNuevo = () => {
    dispatch(updateStateModalLarge(<FormularioPopUp />, 'Nuevo Comunicado'));
  }

  const handleEliminar = (id) =>{
    dispatch(eliminarComunicado(id))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Comunicados" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Comunicados" },
              ]}
            />
            <button className="btn btn-primary" onClick={()=>handleModalNuevo()}> Nuevo comunicado </button>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Comunicados
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar)}
                    tabla={comunicados}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Comunicados;

/**====================== TABLA =========== */

const colums = (handleEliminar) => [
  {
    text: 'ID',
    key: 'id_comunicado',
    className: 'id_comunicado',
    width: 50
  }
  ,
  
  {
    text: "Título",
    key: "titulo",
    align: "center",
    sortable: true,
    className: "titulo",
  },
  {
    text: "Mensaje",
    key: "mensaje",
    align: "center",
    sortable: true,
    className: "mensaje",
    cell: (record) => <div dangerouslySetInnerHTML={{__html:record.mensaje}} ></div>
  }
  ,
  {
    text: "Fecha Registro",
    key: "fecha_registro",
    align: "center",
    className: "fecha_registro",
    sortable: true
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_comunicado)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </>
  }
  
];
