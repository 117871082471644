import React from 'react'

const FilasTabla = ({ profesor, guardarDocentes}) => {
    return ( 
        <tr>
            <td>{profesor.id_docente}</td>
            <td>{profesor.nombre_usuario}</td>
            <td>{profesor.apellido_usuario}</td>
            <td>{profesor.dni}</td>
            <td>
                <button type="button" className="btn btn-primary btn-sm rounded-circle text-white" onClick={() => guardarDocentes(profesor)}>
                <i className="fas fa-plus"></i>
                </button>
            </td>
        </tr>
     );
}
 
export default FilasTabla;