import React, { useEffect } from "react";
import $ from "jquery";
import debounce from "react-debouncing";
import { Link, useHistory } from "react-router-dom";
import { Accordion, Button, Card } from 'react-bootstrap';
import "./app.scss";
//Reedux
import { useSelector, useDispatch } from "react-redux";
import { SIDEBAR_PRINCIPAL } from "./Sidebars/initialSedebars";
import { removerUsuario } from "../actions/userActions";

const Sidemenu = (props) => {
  const initSidebar = () => {
    const CURRENT_URL = window.location.href.split("#")[0].split("?")[0],
      $BODY = $("body"),
      $SIDEBAR_MENU = $("#sidebar-menu"),
      $SIDEBAR_FOOTER = $(".sidebar-footer"),
      $LEFT_COL = $(".left_col"),
      $RIGHT_COL = $(".right_col"),
      $NAV_MENU = $(".nav_menu"),
      $FOOTER = $("footer");
    // TODO: This is some kind of easy fix, maybe we can improve this
  //  console.log("init_sidebar");
    var setContentHeight = function() {
      // reset height
      $RIGHT_COL.css("min-height", $(window).height());

      var bodyHeight = $BODY.outerHeight(),
        footerHeight = $BODY.hasClass("footer_fixed") ? -10 : $FOOTER.height(),
        leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
        contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

      // normalize content
      contentHeight -= $NAV_MENU.height() + footerHeight;

      $RIGHT_COL.css("min-height", contentHeight);
    };

    $SIDEBAR_MENU.find("a").on("click", function(ev) {
   //   console.log("clicked - sidebar_menu");
      var $li = $(this).parent();

      if ($li.is(".active")) {
        $li.removeClass("active active-sm");
        $("ul:first", $li).slideUp(function() {
          setContentHeight();
        });
      } else {
        // prevent closing menu if we are on child menu
        if (!$li.parent().is(".child_menu")) {
          $SIDEBAR_MENU.find("li").removeClass("active active-sm");
          $SIDEBAR_MENU.find("li ul").slideUp();
        } else {
          if ($BODY.is(".nav-sm")) {
            $li
              .parent()
              .find("li")
              .removeClass("active active-sm");
            $li
              .parent()
              .find("li ul")
              .slideUp();
          }
        }
        $li.addClass("active");

        $("ul:first", $li).slideDown(function() {
          setContentHeight();
        });
      }
    });
    //deboucing
    $(window).bind("resize", debounce(setContentHeight, 100));

    // check active menu
    $SIDEBAR_MENU
      .find('a[href="' + CURRENT_URL + '"]')
      .parent("li")
      .addClass("current-page");

    $SIDEBAR_MENU
      .find("a")
      .filter(function() {
        return this.href === CURRENT_URL;
      })
      .parent("li")
      .addClass("current-page")
      .parents("ul")
      .slideDown(function() {
        setContentHeight();
      })
      .parent()
      .addClass("active");

    setContentHeight();

    // fixed sidebar
    if ($.fn.mCustomScrollbar) {
      $(".menu_fixed").mCustomScrollbar({
        autoHideScrollbar: true,
        theme: "minimal",
        mouseWheel: {
          preventDefault: true,
        },
      });
    }
  };
  const { usuario } = useSelector(state => state.usuario);
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(removerUsuario());
    history.push('/login');
  }

  useEffect(() => {
    initSidebar();
  }, [usuario]);

  return (
    <div className='side-menu'>
      <div className="left_col scroll-view" style={{ background: "#27314A" }}>
        <div
          className="navbar nav_title"
          style={{
            border: 0,
            background: "#27314A",
          }}
        >
          <Link to="/inicio" className="site_title">
            <img
              src="/images/icon_inedi2.png"
              className="img img-fluid mr-2"
              alt=""
              style={{
                width: "30px",
              }}
            />
            <span style={{ fontSize: "20px" }}>INEDI Posgrados &#xae;</span>
          </Link>
        </div>
        <div className="clearfix" /> {/* menu profile quick info */}
        <div className="profile clearfix">
          <div className="profile_pic">
            <img
              src={`${
                usuario.img_usuario
                  ? process.env.REACT_APP_API_URL + "/" + usuario.img_usuario
                  : "/images/user.png"
              }`}
              alt="..."
              className="img-circle profile_img"
            />
          </div>
          <div className="profile_info">
           
            <h2 className="text-capitalize">
            <span style={{color:'#fff', fontWeight:'bold'}}>Hola!</span>, {usuario.nombre_usuario} {usuario.apellido_usuario}
            </h2>
            <span style={{color:'#fff', fontWeight:'bold'}}>Sácale el jugo al sistema!</span>
          </div>
        </div>
        {/* /menu profile quick info */}
        <br /> {/* sidebar menu */}
        <div
          id="sidebar-menu"
          className="main_menu_side hidden-print main_menu"
        >
          <div id="menu_section" className="menu_section scroll-menu">
              {
                SIDEBAR_PRINCIPAL.map((item, index) => (
                  <ItemNavigation
                    key={index}
                    item={item}
                    usuario={usuario}
                  />
                ))
              }
          </div>
        </div>
        {/* /sidebar menu */}
        {/* /menu footer buttons */}
        <div className="sidebar-footer hidden-small">
          <Link
            to="/empresa"
            data-toggle="tooltip"
            data-placement="top"
            title="Settings"
          >
            <span className="glyphicon glyphicon-cog" aria-hidden="true" />
          </Link>
          <Button
            variant='link'
            href="#"
            data-toggle="tooltip"
            data-placement="top"
            title="Logout"
            onClick={logout}
          >
            <span className="glyphicon glyphicon-off" aria-hidden="true" />
          </Button>
        </div>
        {/* /menu footer buttons */}
      </div>
    </div>
  );
};

export default Sidemenu;

const ItemNavigation = (props) => {
  const { item, usuario } = props;

  const mostrarItems = () => {
    let existeRol = item.rol.indexOf(usuario.nombre_tp_nivel_usuario);
    if(existeRol < 0) {
      return null;
    }
    return <>
    <Accordion >
      <Card bg="primary" style={{color:'#fff', fontWeight:'600'}}>
        <Accordion.Toggle as={Card.Header} eventKey={item.title.toUpperCase()}>
        {item.title.toUpperCase()}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={item.title.toUpperCase()}>
          <Card.Body style={{padding:'0', paddingLeft:'8px'}}> {
        item.rutas.map((ruta, index) => {
          let existeRolRuta = ruta.rol.indexOf(usuario.nombre_tp_nivel_usuario);
          if(existeRolRuta >= 0) {
            return (
              <ul key={index} className="nav side-menu">
                  {
                    ruta.subItem ? (
                      <li>
                        <a href="#">
                          <i className={ruta.icon} style={ruta.style ? ruta.style : {}} />
                          {ruta.label}
                          <span className="fa fa-chevron-down" />
                        </a>
                        <ul className="nav child_menu">
                          {ruta.subItem.map((sub, j) => {
                            if(sub.rol){
                              let existeRolSub = sub.rol.indexOf(usuario.nombre_tp_nivel_usuario);
                              if(existeRolSub >= 0) {
                                return <li key={j}>
                                  <Link to={sub.url}>{sub.label}</Link>
                                </li>
                              } else {
                                return null;
                              }
                            } else {
                              return <li key={j}>
                                  <Link to={sub.url}>{sub.label}</Link>
                                </li>
                            }
                          })}
                        </ul>
                      </li>
                    ) : (
                      <li className="individual">
                        <Link to={ruta.url}>
                          <i className={ruta.icon} style={ruta.style ? ruta.style : {}} />
                          {ruta.label}
                        </Link>
                      </li>
                    )
                  }
              </ul>
            )
          }
          return null
        })
      }</Card.Body>
      </Accordion.Collapse>
      </Card>
    </Accordion> 
    </>
  }

  return mostrarItems()
};

