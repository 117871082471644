import React, { useEffect, useState } from 'react';
import TablaResumenPension from './TablaResumenPension.js/TablaResumenPension';
import TotalPagar from './TotalPagar';
import { useSelector } from 'react-redux';
import useTotalDescontado from '../../../../hooks/useTotalDescontado';
import useTotalCargos from '../../../../hooks/useTotalCargos';

const ResumenPension = () => {

  const { todo }  = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones)
  const { descuentos = [], cargos_variables = [], saldo, total, subtotal } = todo;
  const { totalDescuento, obtenerTotalDescuento } = useTotalDescontado();
  const { totalCargos, obtenerTotalCargos } = useTotalCargos();

  useEffect(() => {
    obtenerTotalDescuento(descuentos);
    obtenerTotalCargos(cargos_variables);
  }, [descuentos, cargos_variables, saldo])

  return ( 
    <div className="ResumenPension">
      <TotalPagar 
        montoResumen={parseFloat(subtotal)}
      />  
      <TablaResumenPension 
        totalDescuento={totalDescuento}
        totalCargos={totalCargos}
        totalCuota={total}
        saldo={saldo}
      />
    </div>
  );
}
 
export default ResumenPension;