import React from 'react';
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo" 
import HeaderContent from '../General/HeaderContent'
import Breadcrumb from '../General/Breadcrumb'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'

//REDUX
import { useSelector, useDispatch } from 'react-redux'
import { listarEstudiantes, eliminarEstudiante, removerStateEstudiante, enviarCorreo} from '../../../actions/estudiantesActions';
import { useEffect } from 'react';
import Layout from '../../../app/Layout';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faTrash, faMailBulk } from '@fortawesome/free-solid-svg-icons';

const Estudiantes = () => {
  
  const { usuario } = useSelector(state => state.usuario);
  const { estudiantes } = useSelector(state => state.estudiantes);
  const dispatch = useDispatch();

  const eliminarEstudianteTabla = idUsuario => {

    if (usuario.id_tp_nivel_usuario === '5') {
      avisoError('Acceso denegado')
      return
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Estas seguro?',
        text: "Se eliminaran toda información con relacion con el estudiante",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          if((await dispatch(eliminarEstudiante(idUsuario))).mensaje) {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Hubo un error"
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Estudiante se elimino correctamente",
            "success"
          );
        }
      });
  }
  const enviarCredenciales = async (datosAlumno) => {
   const respuesta = await dispatch(enviarCorreo(datosAlumno))
   console.log(respuesta)
   if(respuesta.mensaje)
   {
     avisoCorrecto("Correo Enviado.")
   }
   else
   {
     avisoError("Acceso Denegado.")
   }
  }

  const botones = (record) => {
    return (
      <>
        <button className="btn btn-danger mr-1 btn-xs" onClick={() => eliminarEstudianteTabla(record.id_usuario)} type="button" title="Eliminar">
          <FontAwesomeIcon icon={faTrash} />
        </button>
        <Link
          className="btn text-white btn-warning mr-0 btn-xs"
          type="button"
          title="Ver"
          to={`/estudiantes/editar/${record.id_estudiante}`}
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
       { /*<button className="btn btn-info mr-1 btn-xs" onClick={() => enviarCredenciales(record)} type="button" title="Enviar Correo">
          <FontAwesomeIcon icon={faMailBulk} />
        </button>*/}
      </>
    )
  }

  useEffect(() => {
    dispatch(removerStateEstudiante());
    if(!estudiantes.length) {
      dispatch(listarEstudiantes());
    }
  }, []);

    return (
        <Layout>
        <HeaderContent title={"Discentes registrados"} icon={"people_alt"} subItem={usuario.id_tp_nivel_usuario==='5' ? '¡Envía el correo de bienvenida a cada discente nuevo y revisa que haya ingresado al grupo de WhatsApp!':null} />
        <Breadcrumb
              link={[
                {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
                {url:'/estudiantes', nombre: 'Discentes registrados'}
              ]}
            />
        <div className="px-0 content-actions text-right my-3">
        {usuario.id_tp_nivel_usuario!== '5' ? <Link className="btn btn-icon btn-primary" to="/estudiantes/nuevo">
            <FontAwesomeIcon icon={faPlus} />
            Nuevo discente
          </Link> : ''}
        </div>
        <div className="box box-primary my-4">
          <div className="box-header with-border">
            <h5
              style={{ color: "#434343" }}
            >
              <i className="fas fa-list pr-2"></i>Tabla de discentes
            </h5>
          </div>
          <div className="box-body">
            <MaterialTableDemo
              colums={colums(botones)}
              tabla={estudiantes}
              keyCol="id_estudiante"
            />
          </div>
        </div>
        </Layout>
    );    
}

export default Estudiantes;

//----------------------DATATABLE/---------------
const colums = (botones) => [
  {
    key: 'index',
    text: '#',
    align: 'center',
    className: 'text-center',
    width: 50,
    cell: (_, index) => index+1
  },
  {
    text: "ID",
    key: "id_estudiante",
    align: 'center',
    sortable: true,
    className: "text-center"
  },
  {
    text: "Nombre",
    key: "nombre_usuario",
    align: 'center',
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Apellido",
    key: "apellido_usuario",
    align: 'center',
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "DNI",
    key: "dni",
    align: 'center',
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Celular",
    key: "celular",
    align: 'center',
    className: "text-center"
  },
  {
    text: "Correo",
    key: "email",
    align: 'center',
  },
  {
    text: 'Fecha nacimiento',
    key: "fecha_nac",
    sortable: true,
    className: 'text-center'
  },
  {
    text: "Usuario",
    key: "usuario",
    align: 'center',
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Estado",
    key: "nombre_tp_estado",
    align: 'center',
    sortable: true,
    className: "text-center",
    cell: record => <span className={`badge ${record.id_tp_estado === '1' ? 'badge-success' : 'badge-danger'}`}>
      {record.nombre_tp_estado}
    </span>
  },
  {
    text: "Acciones",
    key: "button",
    align: 'center',
    sortable: true,
    className: "text-center",
    width: 100,
    cell: record => botones(record)
  },
];