import React from 'react'

const Error = ({mensaje}) => {
    return ( 
        <div className="alert alert-danger d-flex align-items-center" role="alert">
            <i class="material-icons mr-2">warning</i>{mensaje}
        </div>  
     );
}
 
export default Error;