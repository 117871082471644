import React from "react";

//Redux
import { connect } from "react-redux";

const ContentLeft = (props) => {

  const { empresa, setFormulario } = props;

  return (
    <div className="perfil-empresa__content-left">
      
      <div className="encabezado">
        <div className="titulo">
          <i className="far fa-building"></i>
          Perfíl de empresa
        </div>
        <div className="boton-editar">
          <i className="far fa-edit"></i>
          <a
            href=""
            data-toggle="modal"
            data-target="#modal-generico"
            onClick={e => {
              e.preventDefault()
              setFormulario('empresa')
            }}
          >
            Editar
          </a>
        </div>
      </div>
      <div className="avatar descripcion">
        <AvatarEmpresa />
      </div>

      <div className="encabezado">
        <div className="titulo">
          <i className="far fa-building"></i>
          Perfíl de empresa
        </div>
      </div>
      <div className="informacion-empresarial">
        <div className="item">
          <p className="indicador">
            Razon social
          </p>
          <p className="valor">{empresa.razon_social}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">
            Nombre comercial
          </p>
          <p className="valor">{empresa.nombre_comercial}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">R.U.C N:</p>
          <p className="valor">{empresa.nro_ruc}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">Dirección</p>
          <p className="valor">{empresa.direccion}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">Officina Ica</p>
          <p className="valor">{empresa.oficina_ica}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  empresa: state.empresa.empresa,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContentLeft);

const AvatarEmpresa = () => {
  return (
    <>
      <div className="logo">
        <img src="/images/user.png" alt="" className="img rounded-circle" />
      </div>

      <div className="nombre-empresa">
        <p className="abreviado">INEDI Posgrados</p>
        <p className="completo">
          Empresa para el desarollo de la empresa e inversion
        </p>
      </div>
    </>
  );
};
