import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { pagarPensionFraccion } from '../../actions/pagosActions';

const withRegistroFactura = WrappedComponent => props => {
  
  const dispatch = useDispatch();
  
  const handleRegistrarPagoOrdinario = (datosFactura, idMatricula) => {
    dispatch(pagarPensionFraccion(datosFactura, idMatricula))
  }
  
  return ( 
    <WrappedComponent
      {...props}
      handleFormSubmit={handleRegistrarPagoOrdinario}
    />
  );
}
 
export default withRegistroFactura;