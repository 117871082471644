import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editarEmpleo, nuevoEmpleo } from '../../../actions/empresaAction';
import Editor from '../PopUps/EditorPopUp';

const FormularioEmpleo = ({registro=null}) => {
    const {usuario} = useSelector(state=>state.usuario)
    const style = {
        boton: {
            background: "#28314A",
            color: "#fff"
        },
        buttonText: {
            fontSize: "13px",
            background: "#28314A",
            color: '#fff'
        },
        textArchivo: {
            fontSize: "12px",
            fontWeight: "600",
            color: "#aaa"
        }
      }
    
    const initialValues = {
        id_trabajo:registro?registro.id_trabajo:null,
        nombre_puesto:registro?registro.nombre_puesto:'',
        descripcion_puesto:registro?registro.descripcion_puesto:'',
        funciones:registro?registro.funciones:'',
        empresa:registro?registro.empresa:'',
        categoria:registro?registro.categoria:'',
        experiencia:registro?registro.experiencia:'',
        tiempo_empleo:registro?registro.tiempo_empleo:'',
        salario:registro?registro.salario:'',
        link_contacto:registro?registro.link_contacto:'',
        id_usuario:usuario.id_usuario
      }
    const [datosEmpleo, setDatosEmpleo] = useState(initialValues);
    const dispatch = useDispatch();
    const handleChange= (event) => {
            const value = event.target.value;
            setDatosEmpleo({
            ...datosEmpleo,
            [event.target.name]: value
            });
        
    }
    const setFunciones = (a) => {
        const value = a;
        //console.log(value);
        setDatosEmpleo({
            ...datosEmpleo,
            funciones: value
        });
     }
    
     const setDescripcion = (a) => {
        const value = a;
        //console.log(value);
        setDatosEmpleo({
            ...datosEmpleo,
            descripcion_puesto: value
        });
     }
    
     const handleSubmit = (e) =>
     {
       e.preventDefault()

      // console.log(datosEmpleo)
      if(!registro)
       dispatch(nuevoEmpleo(datosEmpleo))
       else
       dispatch(editarEmpleo(datosEmpleo))
     }

    return (
       
        <form id="formNuevo2" className="px-md-5 py-3" onSubmit={handleSubmit} >
               
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="nombre_puesto" className="mb-0">
              Nombre Puesto:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="nombre_puesto"
              onChange={handleChange}
              value={datosEmpleo.nombre_puesto}
              type="text"
              className="form-control"
              id="nombre_puesto"
              required
            />
          </div>
        
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="empresa" className="mb-0">
              Empresa:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="empresa"
              onChange={handleChange}
              value={datosEmpleo.empresa}
              type="text"
              className="form-control"
              id="empresa"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
              Categoría:
            </label>
          </div>
          <div className="col-md-9">
         { 
           /* <select   id="categoria"  className="form-control" name="categoria" value={datosEmpleo.categoria} onChange={handleChange} >
              <option value="">Seleccione una categoría</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Legal">Legal</option>
              <option value="Recursos Humanos">Recursos Humanos</option>
            </select>*/
}
            <input
              name="categoria"
              onChange={handleChange}
              value={datosEmpleo.categoria}
              type="text"
              className="form-control"
              id="categoria"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="tiempo_empleo" className="mb-0">
              Tiempo de Trabajo:
            </label>
          </div>
          <div className="col-md-9">
  
            <select id="tiempo_empleo"  className="form-control" name="tiempo_empleo" value={datosEmpleo.tiempo_empleo} onChange={handleChange} required >
              <option value="">Seleccione una opción</option>
              <option value="Full Time">Full Time</option>
              <option value="Part Time">Part Time</option>
              <option value="Por Horas">Por Horas</option>
            </select>

          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="salario" className="mb-0">
              Salario:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="salario"
              onChange={handleChange}
              value={datosEmpleo.salario}
              type="number"
              min={0}
              className="form-control"
              id="salario"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="experiencia" className="mb-0">
              Experiencia (años):
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="experiencia"
              onChange={handleChange}
              value={datosEmpleo.experiencia}
              type="number"
              min={0}
              className="form-control"
              id="experiencia" 
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="link_contacto" className="mb-0">
              Enlace de Contacto:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="link_contacto"
              onChange={handleChange}
              value={datosEmpleo.link_contacto}
              type="text"
              className="form-control"
              id="link_contacto"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
            <div className="col-md-3">
                <label  className="mb-0">
                Descripción del puesto:
                </label>
            </div>
            <div className="col-md-9">
            <Editor
                setContenido={setDescripcion}
                dataDefault={datosEmpleo.descripcion_puesto}
            />
            </div>
        </div>
        <div className="form-row align-items-center mb-3">
        <div className="col-md-3">
            <label className="mb-0">
              Funciones:
            </label>
        </div>
        <div className="col-md-9">
          <Editor
            setContenido={setFunciones}
            dataDefault={datosEmpleo.funciones}
          />
        </div>
        </div>
   
        <button type="submit" className="btn" style={style.boton}>
          <i className="fa fa-floppy-o"></i> Guardar
        </button>
      </form>

    );
};

export default FormularioEmpleo;