import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

const withClasificacionDocente = (WrappedComponent) => props => {
  
  const [docente] = useGetDocente();

  return ( 
    <WrappedComponent
      {...props}
      {...docente.clasificacion.dt_clasificacion}
      list={docente.clasificacion.data}
      listComplete={docente.clasificaciones}
    />
   );
}
 
export default withClasificacionDocente;