import React from 'react'
import Moment from 'react-moment';
//Redux
import {connect} from 'react-redux'
import { mostrarMensaje} from '../../../../actions/mensajesActions'

const estiloBoton = (title) => {

  return (title === "Papelera") ? "btn-danger" : "btn-dark"; 
}

const ListaMensajes = (props) => {
  
  //State
  const {mensajes, title, usuario} = props
  //Funciones
  const { mostrarMensaje,
    guardarMensajesSeleccionados,
    moverMensajePapelera,
    eliminarMensajeCompleto
  } = props

    return (
      <div className="row">
        <div className={`col-12 border-bottom px-2`}>
          <div className="title">
            <h3>{title}</h3>
          </div>
          <div className="botones-control mb-1">
            <button type="button" className="btn btn-sm mr-1 btn-dark">
              <i className="fas fa-refresh"></i>
            </button>
            <button type="button" className="btn btn-sm mr-1 btn-dark">
              <i className="fas fa-eye"></i>
            </button>
            <button
              type="button"
              className={`btn btn-sm mr-1 ${estiloBoton(title)}`}
              onClick={() => {
                (title !== 'Papelera') ? moverMensajePapelera() : eliminarMensajeCompleto()
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <div
          className={`col-12`}
          style={{ overflowY: "auto", maxHeight: "388px" }}
        >
          {mensajes.length > 0 ? (
            mensajes.map(mensaje => (
              <MensajeInfo 
                key={mensaje.id_mensaje}
                mensaje={mensaje}
                mostrarMensaje={mostrarMensaje} 
                usuario={usuario}
                guardarMensajesSeleccionados={guardarMensajesSeleccionados}
              />
            ))
          ) : (
            <div className="alert alert-dismissible alert-light">
              <span className="text-center">No hay mensajes</span>
              
            </div>
          )}
        </div>
      </div>
    );
}
 
const mapStateToProps = state => ({
  usuario: state.usuario.usuario
})

export default connect(mapStateToProps, { mostrarMensaje})(ListaMensajes);

const MensajeInfo = ({ mensaje, mostrarMensaje, usuario, guardarMensajesSeleccionados}) => {
  return (
    <div className="border-bottom row">
      <div className="col-1 p-0">
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={mensaje.id_mensaje}
              onChange={e => guardarMensajesSeleccionados(e.target)}
            />
            <label
              className="custom-control-label"
              htmlFor={mensaje.id_mensaje}
            ></label>
          </div>
        </div>
      </div>
      <div className="col-1 p-0">
        <div className="icon">
          <i className="material-icons">inbox</i>
        </div>
      </div>
      <div
        className="col-8 p-0"
        onClick={() => mostrarMensaje(mensaje.id_mensaje, usuario.id_usuario)}
      >
        <label htmlFor="">
          {mensaje.nombre_emisor ||
            `${mensaje.nombre_receptor} ${mensaje.apellido_receptor}`}
        </label>
        <p className="mb-0">{mensaje.asunto}</p>
      </div>
      <div className="col-2 p-0">
        <div className="fecha-mensaje">
          <Moment format="D MMM YYYY" withTitle>
            {mensaje.hora_msj}
          </Moment>
          {/* <p style={{ fontSize: "11px" }}></p> */}
        </div>
      </div>
    </div>
  );
}