import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { obtenerListaClasificacionTrabajadores, listarAreasTrabajadores, editarConfiguracionAreaTrabajador } from '../../../../../actions/trabajadoresActions';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { updateStateModalBasic } from '../../../../../actions/modalActions';

const FormEditarConfiguracionAreaTrabajador = ({ datosConfiguracion }) => {

  const { idTrabajador } = useParams();
  const dispatch = useDispatch();
  const [loadingForm, setLoadingForm] = useState(null);
  const { loading, datos: listaClasificaciones = [] } = useSelector(state => state.trabajadores.clasificacion);
  const { todo: listaAreas = [] } = useSelector(state => state.trabajadores.areas);

  const formik = useFormik({
    initialValues: datosConfiguracion || {
      area: null,
      clasificacion: null
    },
    validationSchema: yup.object({
      area: yup.object().nullable().required('Este campo es requerido'),
      clasificacion: yup.object().nullable().required('Este campo es requerido.')
    }),
    onSubmit: async valores => {
      setLoadingForm(true);
      await dispatch(editarConfiguracionAreaTrabajador(valores, idTrabajador));
      setLoadingForm(false);
    }
  });

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }

  useEffect(() => {
    dispatch(obtenerListaClasificacionTrabajadores());
    dispatch(listarAreasTrabajadores());
  }, [])

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Clasificación</Form.Label>
            <Select
              options={listaClasificaciones}
              getOptionValue={(option) => parseInt(option.id_tp_clasificacion_usuario)}
              getOptionLabel={(option) => option.nombre_clasificacion}
              isLoading={loading}
              loadingMessage={() => 'Cargando...'}
              noOptionsMessage={() => 'No hay resultados'}
              placeholder='--Seleccione--'
              isLoading={loading}
              onChange={e => formik.setFieldValue('clasificacion', e)}
              value={formik.values.clasificacion}
            />
            {
              formik.errors.clasificacion && (
                <Form.Text className='text-danger'>
                  {formik.errors.clasificacion}
                </Form.Text>
              )
            }
        </Form.Group>
        <Form.Group>
          <Form.Label>Área</Form.Label>
            <Select
              options={listaAreas}
              getOptionValue={(option) => parseInt(option.id_tp_nivel_usuario)}
              getOptionLabel={(option) => option.nombre_tp_nivel_usuario}
              isLoading={loading}
              loadingMessage={() => 'Cargando...'}
              noOptionsMessage={() => 'No hay resultados'}
              placeholder='--Seleccione--'
              isLoading={loading}
              onChange={e => formik.setFieldValue('area', e)}
              value={formik.values.area}
            />
            {
              formik.errors.area && (
                <Form.Text className='text-danger'>
                  {formik.errors.area}
                </Form.Text>
              )
            }
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='dark'
          disabled={loadingForm}
          onClick={handleModal}
        >
          Cancelar
        </Button>
        <Button
          type='submit'
          variant='info'
          disabled={loadingForm}
          className='btn-spinner'
        >
          {
            loadingForm && <Spinner as='span' animation='border' size='sm' />
          }
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormEditarConfiguracionAreaTrabajador;