import { 
  OPEN_LIGHTBOX,
  CLOSE_LIGHTBOX,
  SET_SOURCE_LIGHTBOX,
  NEXT_SLIDE,
  PREV_SLIDE
 } from './types';

export const updateToogleLigthBox = (sources = [], index = null) => {
  return (dispatch, getState) => {
    
    const toggler = getState().lightBox.toggler

    if (!toggler) {
      dispatch({
        type: OPEN_LIGHTBOX
      })
      dispatch( setSourceLigthBox(sources, index) )
    } else {
      dispatch({
        type: CLOSE_LIGHTBOX
      })
    }
  }
}

export const nextSlide = () => {
  return dispatch => {
    dispatch({
      type: NEXT_SLIDE
    })
  }
}

export const prevSlide = () => {
  return dispatch => {
    dispatch({
      type: PREV_SLIDE
    })
  }
}

export const setSourceLigthBox = (sources, index) => {

  return (dispatch) => {
    dispatch({
      type: SET_SOURCE_LIGHTBOX,
      payload: {
        sources: sources.map(dato => `${process.env.REACT_APP_API_URL}/${dato.ruta_archivo}`),
        index
      }
    })
  }
}