import authAxios from '../config/authAxios';
import { programacionesTypes } from './types';
import { avisoCargando, avisoError, avisoCorrecto } from '../view/components/AvisosMensajes/MensajesSwalf';
import Swal from 'sweetalert2'
import { updateStateModalBasic } from './modalActions';
import { mostrarErrorApi } from '../helpers/functions';

export const obtenerProgramaciones = () => {
  return async dispatch => {
    try {
      dispatch({
        type: programacionesTypes.OBTENER_PROGRAMACIONES
      });
      const respuesta = await authAxios.get('/admin/programacionAnual');
      
      dispatch({
        type: programacionesTypes.OBTENER_PROGRAMACIONES_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: programacionesTypes.OBTENER_PROGRAMACIONES_ERROR
      })
      return;
    }
  }
}

export const nuevaProgramacion = datosProgramacion => {
  return async dispatch => {
    avisoCargando('Guardando...')
    try {
      const respuesta = await authAxios.post('/admin/programacionAnual', datosProgramacion);  
      dispatch({
        type: programacionesTypes.NUEVO_PROGRAMACION,
        payload: respuesta.data
      })

      avisoCorrecto('Programación guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
    }
  }
}

export const programacionActiva = (idProgramacion) => {
  return (dispatch, getState) => {
    if (!idProgramacion) {
      dispatch({
        type: programacionesTypes.PROGRAMACION_ACTIVA,
        payload: null
      })
      return;
    }
    const programaciones = getState().programaciones.programaciones;
    const activo = programaciones.find(
      programacion => parseInt(programacion.id) === parseInt(idProgramacion)
    );
  
    if(activo === undefined) {
      avisoError('No cargo se cargo la informacion de la programación');
      return;
    } 

    dispatch({
      type: programacionesTypes.PROGRAMACION_ACTIVA,
      payload: activo
    })
  }
}

export const editarProgramacionAnual = datosProgramacion => {
  return async (dispatch, getState) => {
    avisoCargando('Guardando...');
    try {
      const activo = getState().programaciones.programacionActiva
      const respuesta = await authAxios.put(`/admin/programacionAnual/${activo.id}`, datosProgramacion);

      dispatch({
        type: programacionesTypes.EDITAR_PROGRAMACION,
        payload: respuesta.data
      })
      avisoCorrecto('Editado correctamente')
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
    }
  }
}

export const eliminarProgramacion = (idProgramacion) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro?',
          text: "Se eliminaran todo lo relacionado con esta programación",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/programacionAnual/${idProgramacion}`)
              dispatch({
                  payload: idProgramacion,
                  type: programacionesTypes.ELIMINAR_PROGRAMACION
              })
              return true;
          } catch (error) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Programación eliminada correctamente",
              "success"
          );
          }
      });
  }
}

export const obtenerProgramacion = (idProgramacion) => {
  return async (dispatch, getState) => {
    try {
      //Si no hay programaciones, se pasa a consultar
      const programaciones = getState().programaciones.programaciones
      if (!programaciones.length) {
        await dispatch(obtenerProgramaciones());
      }
      //Poneido la prgramacion como Activo para traer los datos como titulo
      await dispatch(programacionActiva(idProgramacion));

      //Verificar si el proyecto ya esta en state
      const programacion = getState().programaciones.programacion.todo;
      const existeProgramacion = programacion ? programacion[idProgramacion] : programacion;

      if (!existeProgramacion) {
        dispatch({
          type: programacionesTypes.OBTENER_CICLOS_PROGRAMACION
        }) 
      }

      const respuesta = await authAxios.get(`/admin/programacionAnual/${idProgramacion}`);

      dispatch({
        type: programacionesTypes.OBTENER_CICLOS_PROGRAMACION_EXITO,
        payload: {
          idProgramacion,
          datos: respuesta.data
        }
      })

    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: programacionesTypes.OBTENER_CICLOS_PROGRAMACION_ERROR
      })
    }
  }
}

export const nuevoProyecto = (datos) => {
  return async dispatch => {
    avisoCargando('Guardando...');
   // console.log(datos)
    try {
      const respuesta = await authAxios.post('/admin/programacionAnual/proyecto', datos);
      dispatch({
        type: programacionesTypes.NUEVO_PROYECTO_PROGRAMACION_EXITO,
        payload: {
          idProgramacion: datos.idProgrmAnual,
          datos: respuesta.data
        }
      })
      avisoCorrecto('Creado correctamente');
      dispatch(updateStateModalBasic());
    } catch (error) {
      console.log(error);
      dispatch({
        type: programacionesTypes.NUEVO_PROYECTO_PROGRAMACION_ERROR
      })
      mostrarErrorApi(error);
    }
  }
}

export const eliminarProyectoProgramacion = (idProgramacion, idProyecto) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro?',
          text: "Se eliminaran todo lo relacionado con este proyecto",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/programacionAnual/proyecto/${idProyecto}`)
              dispatch({
                type: programacionesTypes.ELIMINAR_PROYECTO_PROGRAMACION_EXITO,
                payload: {
                  idProgramacion,
                  idProyecto
                }
              })
              return true;
          } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response !== undefined ? error.respose.data.msg : error
            })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Proyecto eliminado correctamente",
              "success"
          );
          }
      });
  }
}

export const proyectoProgramacionActivo = (idProgramacion, idProyecto) => {
  return (dispatch, getState) => {

    if (!idProgramacion) {
      dispatch({
        type: programacionesTypes.PROYECTO_PROGRAMACION_ACTIVO,
        payload: null
      })
      return;
    }
    const proyectos = getState().programaciones.programacion.todo[idProgramacion];
    const proyectoActivo = proyectos.find(proyecto => proyecto.id === idProyecto);

    if (!proyectoActivo) {
      avisoError('No se encontro el proyecto');
      return;
    }
    dispatch({
      type: programacionesTypes.PROYECTO_PROGRAMACION_ACTIVO,
      payload: proyectoActivo
    })
  }
}

export const editarProyectoProgramacion = datos => {
  return async dispatch => {
    avisoCargando('Guardando los cambios');
    try {
      const { idProgrmAnual: idProgramacion, idProgrmProyecto: idProyecto } = datos;
      const respuesta = await authAxios.put(`/admin/programacionAnual/proyecto/${idProyecto}`, datos);
      dispatch({
        type: programacionesTypes.EDITAR_PROYECTO_PROGRAMACION_EXITO,
        payload: {
          idProgramacion,
          idProyecto,
          datos: respuesta.data
        }
      })
      avisoCorrecto('Proyecto editado correctamente');
      dispatch(updateStateModalBasic());
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
    }
  }
}

export const nuevoContrato = (datos, idProgramacion, idProyecto) => {
  return async (dispatch) => {
    try {
      const respuesta = await authAxios.post(`admin/contratoProveedor/${idProyecto}`, datos, {
        onUploadProgress(e) {
          const progress = Math.round((e.loaded * 100) / e.total);
          dispatch({
            type: programacionesTypes.ACTUALIZANDO_PROCENTAJE_SUBIDA_ARCHIVO,
            payload: progress
          })
        }
      })

      dispatch({
        type: programacionesTypes.NUEVO_CONTRATO_EXITO,
        payload: {
          idProgramacion,
          idProyecto,
          datos: respuesta.data
        }
      })
      avisoCorrecto('Contrato registrado correctamente');
      setTimeout(() => {
        dispatch({
          type: programacionesTypes.ACTUALIZANDO_PROCENTAJE_SUBIDA_ARCHIVO,
          payload: null
        })
      }, 1000)
      dispatch(updateStateModalBasic());
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: programacionesTypes.NUEVO_CONTRATO_ERROR
      })
    }
  }
}

export const contratoActivo = (idProgramacion, idProyecto, idContrato) => {
  return async (dispatch, getState) => {
    await dispatch(proyectoProgramacionActivo(idProgramacion, idProyecto));
    const proyectoActivo = getState().programaciones.programacion.proyectoActivo;
    const contratoActivo = proyectoActivo.proveedores.find(proveedor => proveedor.id === idContrato);
    dispatch({
      type: programacionesTypes.CONTRATO_ACTIVO,
      payload: contratoActivo
    })
  }
}

export const eliminarContrato = (idProgramacion, idProyecto, idProveedorProyecto) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro?',
          text: "No se podrá volver a recuperar este contrato",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              const respuesta = await authAxios.delete(`/admin/proveedores/file/${idProveedorProyecto}`)
              dispatch({
                type: programacionesTypes.ELIMINAR_CONTRATO_EXITO,
                payload: {
                  idProgramacion,
                  idProyecto,
                  datos: respuesta.data
                }
              })
              dispatch(updateStateModalBasic());
              return true;
          } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response !== undefined ? error.respose.data.msg : error
            })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Contrato eliminado correctamente",
              "success"
          );
          }
      });
  }
}
