import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import useSimpleSelect from '../../../../hooks/useSimpleSelect';  
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REQUIRED } from '../../../../helpers/validationForms';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { editarPlanProyecto, nuevoPlan } from '../../../../actions/cursosActions';

const FormNuevoPlan = ({ onCloseModal, plan: datosPlan, idTipoModalidad }) => {

  const { SimpleSelect } = useSimpleSelect(options());
  const { idProyecto } = useParams();
  const { loadingPlan } = useSelector(state => state.cursos);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: datosPlan ? {...datosPlan, plan: {value: datosPlan.id_tp_plan, label: datosPlan.tp_plan}} : initialValues(idTipoModalidad, idProyecto),
    validationSchema: yup.object({
      id_tp_plan: yup.string().required(REQUIRED),
      plan: yup.object().required(REQUIRED),
      nombre_plan: yup.string().required(REQUIRED)
    }),
    onSubmit: async valores => {
      if(datosPlan) {
        dispatch(editarPlanProyecto(idProyecto, valores, onCloseModal));
      } else {
        dispatch(nuevoPlan(idProyecto, valores, onCloseModal));
      }
    }
  });

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre del plan</Form.Label>
          <Form.Control
            type='text' 
            name='nombre_plan'
            value={formik.values.nombre_plan}
            onChange={formik.handleChange}
            isInvalid={formik.errors.nombre_plan}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.nombre_plan}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Seleccione tipo de plan</Form.Label>
          <SimpleSelect
            value={formik.values.plan}
            onChange={e => {
              formik.setFieldValue('plan', e)
              formik.setFieldValue('id_tp_plan', e.value)
            }}
          />
          {
            formik.errors.id_tp_plan && <Form.Text className='text-danger'>
              {formik.errors.id_tp_plan}
            </Form.Text> 
          }
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' disabled={loadingPlan} onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button variant='info' disabled={loadingPlan} type='submit' className='btn-spinner'>
          {
            loadingPlan && <Spinner animation='border' size='sm' />
          }
          {
            datosPlan ? 'Guardar' : 'Agregar'
          }
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormNuevoPlan;

const initialValues = (idTipoModalidad, idProyecto) => ({
  id_tp_plan: '',
  plan: null,
  nombre_plan: '',
  id_tp_modalidad: idTipoModalidad,
  id_proyecto: idProyecto
})

const options = () => [
  {
    value: 1,
    label: 'GENERAL'
  },
  {
    value: 2,
    label: 'CORPORATIVO'
  }
]

