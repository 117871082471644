import React from "react";
import FormInsersionManual from './FormInsersionManual'

import { useState } from "react";
import FormInsersionMasiva from "./FormInsercionMasivo";

const ModalInsertarSesiones = ({idCurso, sesion}) => {

  //STATE GENERAL
  const [idCursoActual, setIdCursoActual] = useState('')
  const [idEstado, setIDEstado] = useState('')
  //STATE INSERTAR MANUAL
  const [nombreSesion, setNombreSesion] = useState('')
  //STATE

  return (
    <div
      className="modal fade bd-example-modal-sm modal-sesiones-curso"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
      
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content p-3" style={{height: "auto", minHeight:"400px"}}>
          {/* <div className="row">
            <div className="col-3">
              <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Manual</a>
                <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Importar</a>
                
              </div>
            </div>
            <div className="col-9"> */}
              <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <FormInsersionManual
                    idCurso={idCurso}
                    sesion={sesion}
                  />
                </div>
                {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <FormInsersionMasiva idCurso={idCurso}/>
                </div> */}
              </div>
            {/* </div> */}
          
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ModalInsertarSesiones;
