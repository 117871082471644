import React from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import { Row, Col, Container, Card, CardBody,CardHeader} from 'react-bootstrap';
import Clock from '../../components/Clock/Clock';
const AsistenciasEmpleados = () => {

    return (
        <Layout>
          <div className="RegistroPagos">
            <div className="RegostroPagos__head">
              <HeaderContent title='Marcador de Asistencia' />
              <Breadcrumb
                link={[
                  { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                  { url: "/webcheckin", nombre: "Marcador", icon: "fas fa-clock" },
                ]}
              />    
            </div>
            <div className="RegistroPagos__body">
            <Container fluid>
                <Row>
                    <Col md={6}> 
                        <Card className="shadow p-3 align-items-center">
                        <Card.Title className="text-center">
            
                        <div className="col">  
                          <h6 className="text-uppercase ls-1 mb-1">
                          Marcador
                          </h6>
                        </div>
                     
                        </Card.Title>
                        <Clock/>
                        </Card>
                    </Col>
                    <Col md={6}> 
                        <Card className="shadow p-3 align-items-center">
                        <Card.Title className="text-center">
            
                        <div className="col">  
                        <h2 className="mb-0">Opciones de Marcación</h2>
                        </div>
                     
                        </Card.Title>
                        
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}> 
                        <Card className="shadow p-3 align-items-center">
                        <Card.Title className="text-center">
            
                        <div className="col">  
                        <h6 className="text-uppercase ls-1 mb-1">
                        Marcador
                        </h6>
                        <h2 className="mb-0">Opciones de Marcación</h2>
                        </div>
                     
                        </Card.Title>
                   
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
          </div>
        </Layout>
      );


}
 
export default AsistenciasEmpleados;
