import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { filtrarLeads, listarLeads } from '../../../actions/leadsActions';
import useSelectAsesores from "../../../hooks/useSelectAsesores";
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import useSelectEstadosLeads from "../../../hooks/useSelectEstadosLeads";
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { Button } from 'react-bootstrap';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioFiltro = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const {filtrado} = useSelector(state=>state.leads)
const [asesor, SelectAsesor] = useSelectAsesores(null)
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [estado, SelectEstado] = useSelectEstadosLeads(null)
const [fechaFiltro, setFechaFiltro] = useState(null)
const [fechaFiltroHasta, setFechaFiltroHasta] = useState(null)
const [fuente,setFuente] = useState('')
const handleUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/toky/${usuario.id_tp_nivel_usuario}/${asesor?asesor.id_usuario:null}/${proyecto?proyecto.id_proyecto:null}/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}/${fechaFiltroHasta?moment(fechaFiltroHasta).format('YYYY-MM-DD'):null}/${estado?estado.id_estado:null}`;
}
const handleMailUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/mailchimp/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}/${fechaFiltroHasta?moment(fechaFiltroHasta).format('YYYY-MM-DD'):null}`;
}

const traerDatos = () =>{
if(fechaFiltro!==null && fechaFiltroHasta!==null)
{ const datosFiltro = {
    asesor:asesor?asesor:null,
    fechaFiltroDesde: fechaFiltro ? moment(fechaFiltro).format('YYYY-MM-DD') : null,
    fechaFiltroHasta: fechaFiltroHasta ? moment(fechaFiltroHasta).format('YYYY-MM-DD') : null,
    proyecto: proyecto ? proyecto : null,
    estado: estado ? estado : null,
    fuente: fuente!=='' ? fuente: null,
  }

 // console.log(datosFiltro)

  dispatch(filtrarLeads(datosFiltro,usuario))}
  else
  {
    avisoError("Debe seleccionar un rango de fecha por lo menos.")
  }
  
}

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
      <div className="row p-2">
      
        <div className="col-4 mb-2">
          <label htmlFor="">Asesor:</label>
          <SelectAsesor/>
        </div>
        <div className="col-5 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="">Fuente:</label>
          <select className="form-control" name="fuente" value={fuente} onChange={(e)=>setFuente(e.target.value)}>
            <option value="">Seleccione...</option>
            <option value="facebook">FaceBook</option>
            <option value="google">Google</option>
            <option value="webinar">Webinar</option>
            <option value="linkedin">LinkedIn</option>
          </select>
        </div>
        <div className="col-4">
          <label htmlFor="">Desde:</label>
          <DatePicker
                selected={fechaFiltro}
                onChange={(date) =>setFechaFiltro(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaFiltro"
                startDate={fechaFiltro}
                className="form-control"
                endDate={fechaFiltroHasta}
              />
        </div>
        <div className="col-4">
          <label htmlFor="">Hasta:</label>
          <DatePicker
                selected={fechaFiltroHasta}
                onChange={(date) =>setFechaFiltroHasta(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                className="form-control"
                name="fechaFiltroHasta"
                startDate={fechaFiltro}
                endDate={fechaFiltroHasta}
                minDate={fechaFiltro}
                autoComplete="off"
              />
        </div>
        <div className="col-4 mb-2">
          <label htmlFor="">Estado:</label>
          <SelectEstado/>
        </div>
        <div className="col-12 pt-4">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        {
          filtrado.length>0 && (asesor && (proyecto || (fechaFiltro && fechaFiltroHasta))) ? <Button href={handleUrlDescarga()} variant="primary"> CSV Toky </Button> : ''
        } 
        {
          filtrado.length>0 && fechaFiltro && fechaFiltroHasta ?  <Button href={handleMailUrlDescarga()} variant="primary"> CSV MailChimp </Button> : ''
        }</div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltro;
