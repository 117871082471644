import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

//Redux
import { useDispatch } from 'react-redux';
import { eliminarDescuentoCargo } from '../../../../../actions/pagosActions';

const FilaTablaDescuento = ({ index, descuento, acciones }) => {
  
  const dispatch = useDispatch();
  const { motivo, monto, total, cantidad, id_cargo_variable } = descuento;

  const handleEliminarFila = () => {
    dispatch(eliminarDescuentoCargo(id_cargo_variable));
  }

  return ( 
    <tr>
      <td align='center'>{index + 1}</td>
      <td align='center'>{motivo}</td>
      <td align='center'>{monto}</td>
      <td align='center'>{cantidad}</td>
      <td align='center'>{total}</td>
      {
        acciones && <td align='center'>
          <Button
            variant='danger'
            size='xs'
            onClick={handleEliminarFila}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </td>
      }
    </tr>
  );
}
 
export default FilaTablaDescuento;