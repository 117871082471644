import React from 'react';
import Rating from "../../../components/plugins/Rating";
import { Form } from 'react-bootstrap';

export const PreguntaEncuesta = props  => {

  const { pregunta, index, guardarPreguntaEditar } = props;

  return (
    <div
      key={index}
      className="item__pregunta"
      onClick={() => guardarPreguntaEditar(pregunta)}
    >
      <p className="pregunta">
        {index + 1}.- {pregunta.pregunta}
      </p>
      <p className='pregunta-decripcion'>{pregunta.descripcion}</p>
      {pregunta.id_tp_pregunta === "1" ? (
        <div className="item__pregunta_start">
          <Rating />
        </div>
      ) : (
        <Form.Control
          as="textarea"
          className="textarea-pregunta mt-2 "
          rows="3"
          placeholder="Escriba su respuesta"
        />
      )}
    </div>
  )
}

export const PreguntaEncuestaDocente = props => {

  const { pregunta, index } = props;

  return (
    <div
      key={index}
      className="item__pregunta"
    >
      <p className="pregunta">
        {index + 1}.- {pregunta.pregunta}
      </p>
      <p className='pregunta-decripcion'>{pregunta.descripcion}</p>
      {pregunta.id_tp_pregunta === "1" ? (
        <div className="item__pregunta_start">
          <Rating defaultValue={1} disabled={true}/>
        </div>
      ) : (
        <Form.Control
          as="textarea"
          className="textarea-pregunta mt-2 "
          rows="3"
          value={ 5}
          disabled
          placeholder="Escriba su respuesta"
        />
      )}
    </div>
  )
}