import React, { useEffect } from 'react';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import ModalLargewithRedux from '../../../containers/ModalLargewhithRedux';
import FormularioEmpleo from './FormularioEmpleo';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarEmpleo, listarEmpleos } from '../../../actions/empresaAction';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { updateStateModalLarge } from '../../../actions/modalActions';
import { faEdit, faLink, faListAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';



const BolsaDeTrabajo = () => {


    const {loading, ofertas} = useSelector(state=> state.empresa)
    const {usuario} = useSelector(state=>state.usuario)
    const dispatch = useDispatch();

    const handleNuevoRegistro = () => {
        dispatch( updateStateModalLarge(<FormularioEmpleo />, 'Nuevo Empleo' ) )
      } 

      const handleEditarRegistro = (registro) => {
        dispatch( updateStateModalLarge(<FormularioEmpleo registro={registro} />, 'Editar Empleo' ) )
      } 


    const handleEliminarRegistro = (id) =>{
      dispatch(eliminarEmpleo(id))
    }

    useEffect(()=>{
        dispatch(listarEmpleos(usuario.id_usuario))
    },[])


    return (
        <Layout>
        <div className="Trabajadores">
          <div className="Trabajadores__container">
          <ModalLargewithRedux />
            <div className="Trabajadores__head">
              <HeaderContent title="Bolsa de Trabajo" icon="group" />
              <Breadcrumb
                link={[
                  { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                  { url: "/bolsa-de-trabajo", nombre: "Bolsa de Trabajo" },
                ]}
              />
            </div>
            <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
              <SpinnerLoading loading={loading}>
                <div className="Trabajadores__acciones mb-2">
                  <div className="text-right">
                    <Button
                      type='button'
                      variant="primary"
                      onClick={handleNuevoRegistro}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      Nuevo Empleo
                    </Button>
                  </div>
                </div>
                <div className="box mt-5">
                  <div className="box-header with-border">
                    <h5
                        style={{ color: "#434343" }}
                      >
                      <FontAwesomeIcon icon={faListAlt} /> Empleos registrados
                    </h5>
                  </div>
                  <div className="box-primary">
                    <MaterialTableDemo
                      colums={colums(handleEliminarRegistro,handleEditarRegistro)}
                      keyCol="id_trabajo"
                      tabla={ofertas}
                    />
                  </div>
                </div>
              </SpinnerLoading>
            </div>
          </div>
        </div>
      </Layout>
    );
};

export default BolsaDeTrabajo;

const colums = (handleEliminarRegistro,handleEditarRegistro) => [

    {
      text: "ID",
      key: "id_trabajo",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 50
    },
    {
      text: "Empleo",
      key: "nombre_puesto",
      align: "center",
      sortable: true,
    },
    {
      text: "Categoría",
      key: "categoria",
      align: "center",
      sortable: true,
      className: "text-center",
    },
    {
      text: "Experiencia",
      key: "experiencia",
      align: "center",
      sortable: true,
      className: "type_of_food",
    },
    {
      text: "Tiempo de Trabajo",
      key: "tiempo_empleo",
      align: "center",
      sortable: true
    },
    {
      text: "Salario",
      key: "salario",
      align: "center",
      sortable: true
    },
    {
      text: "Enlace",
      key: "link_contacto",
      align: "center",
      sortable: true,
      cell: (record) => (
        <>
          <a

          href={record.link_contacto}
          className="btn btn-info"
          target="_blank"
          rel="noopener noreferrer"          
          >
            <FontAwesomeIcon icon={faLink} />
          </a>
        </>
      )
    },
    {
      text: "Fecha Publicación",
      key: "fecha_publicacion",
      align: "center",
      sortable: true
    },
    {
      text: "Acciones",
      key: "button",
      align: "center",
      sortable: true,
      className: "text-center",
      cell: (record) => (
        <>
          <Button
            type="button"
            variant='danger'
            size='xs'
            onClick={() => handleEliminarRegistro(record.id_trabajo)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            type="button"
            variant='warning'
            size='xs'
            onClick={() => handleEditarRegistro(record)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
         
        </>
      ),
    },
  ];