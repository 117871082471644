import React, {useState, useEffect} from 'react';

import SimpleSelect from 'react-select';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { guardarTema, obtenerTemas } from '../../../../../actions/profesoresActions';


const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioPopUp = () => {

const [datosTema, setPopUpDatos] = useState(initialValues);
const areas = useSelector(state=>state.profesores.area.areas);
 const dispatch = useDispatch();
 const handleChange= (event) => {
        const value = event.target.value;
        setPopUpDatos({
          ...datosTema,
          [event.target.name]: value
        });
    
 }

  const handleSubmit = (e) =>{
    e.preventDefault();
 // console.log(datosTema)
  dispatch(guardarTema(datosTema))
  }

 return (
    <div className="box box-primary">
        <div className="box-body">
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label className="mb-0">
                        Área:
                      </label>
                    </div>
                    <div className="col-md-7">
                     
                              <SimpleSelect 
                                options={areas}
                                
                                getOptionLabel={item =>  item.nombre}
                                getOptionValue={item => item.id}
                                value={datosTema.AREA}
                                onChange={e => setPopUpDatos(
                                    datosTema => ({...datosTema, AREA: e})
                                )}
                 
                            />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="tema" className="mb-0">
                       Tema:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="TEMA"
                        onChange={handleChange}
                        value={datosTema.TEMA}
                        type="text"
                        className="form-control"
                        id="tema"
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
        </div>
      </div> 
    );
};

export default FormularioPopUp;
const initialValues = {
    AREA:'',
    TEMA:''
  }