import React from 'react';
import EditarHonorariosWithTrabajador from '../../containers/TrabajadoresContainers/EditarHonorariosWithTrabajador';
import useGetTrabajador from '../../hooks/useGetTrabajador';
//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';


const withHonorarios = WrappedComponent => props => {

  const dispatch = useDispatch();
  const [trabajador] = useGetTrabajador();

  const handleEditar = () => {
    dispatch( updateStateModalCustom(<EditarHonorariosWithTrabajador />, 'Editar honorarios', 'modal-w30') );
  }

  return ( 
    <WrappedComponent
      {...props}
      {...trabajador.honorarios}
      handleEditar={handleEditar}
    />
   );
}
 
export default withHonorarios;