import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { useSelector } from "react-redux";
import FormularioEmitidos from "./FormularioEmitidos";
import { Badge } from "react-bootstrap";
 
const CertificadosEmitidos = () => {

  //State
  const {emitidos, loading} = useSelector(state=>state.bases)

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Certificados emitidos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Certificados emitidos" },
              ]}
            />
          <div className="row mt-2">
            <div className="col-md-6">
              <FormularioEmitidos />
            </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Listado certificados
                  </h5>
        
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums()}
                    tabla={emitidos}
                    keyCol="id_certificado"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CertificadosEmitidos;

/**====================== TABLA =========== */

const colums = () => [
  {
    text: 'ID',
    key: 'id_certificado',
    className: 'id_registro',
    width: 50
  }
  ,
  {
    text: "Fecha Emisión",
    key: "fecha_emision",
    align: "center",
    sortable: true,
    className: "periodo text-center",
  }
  ,
  {
    text: "Título",
    key: "titulo_certificado",
    align: "center",
    sortable: true,
    className: "titulo",
    cell: (record) => 
    <div className="text-center" dangerouslySetInnerHTML={{__html:record.titulo_certificado}}>
    </div>
  
  }
  ,
  {
    text:"N° Evento",
    key: "n_evento",
    align: "center",
    className: "n_evento text-center",
    sortable: true
  }
  ,
  {
    text:"N° Libro",
    key: "n_libro",
    align: "center",
    className: "n_libro text-center",
    sortable: true
    
  }
  ,
  {
    text:"Certificado",
    key: "archivo",
    align: "center",
    className: "archivo",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.estado!=="1" ? <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_emitidos/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>:<a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/certificados_firmados/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>}
      </div>
    
  }
  ,
  {
    text:"Estado",
    key: "estado",
    align: "center",
    className: "estado",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
      { record.estado!=="1" ? <Badge variant={'danger'} >Por firmar</Badge> :<Badge variant={'success'} >Firmado</Badge> }
      </div>
    
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    <a className="btn btn-info btn-xs" href={`watana:?url_logo=${process.env.REACT_APP_API_URL}/files_inedi/icon_inedi2.png&url_descarga=${process.env.REACT_APP_API_URL}/files_inedi/certificados_emitidos/${record.archivo}&url_subida=${process.env.REACT_APP_API_URL}/admin/subirFirmados/${record.archivo}&firma_x=140&firma_y=270&firma_largo=180&firma_alto=70&firma_pagina=1&firma_texto=%3CFIRMANTE%3E%0A%3CORGANIZACION%3E%0A%3CTITULO%3E%0AFecha%3A%20%3CFECHA%3E%0A`} target="_blank" download><FontAwesomeIcon icon={faEdit}/></a>
  </>
  }
  
  
];
