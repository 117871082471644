import React from 'react';
import { Card } from 'react-bootstrap';
import {HorizontalBar} from 'react-chartjs-2';


const HorizontalBarChart = (props) => {
  
    const backgroundColorSelector = ["rgba(194,249,248,0.4)","rgba(39,152,178,0.4)","rgba(127,83,198,0.4)","rgba(216,171,93,0.4)","rgba(244,204,255,0.4)","rgba(27,149,193,0.4)","rgba(226,117,83,0.4)","rgba(4,177,239,0.4)","rgba(53,75,221,0.4)","rgba(123,224,189,0.4)","rgba(178,205,247,0.4)","rgba(6,155,93,0.4)","rgba(137,244,199,0.4)","rgba(28,147,160,0.4)","rgba(213,139,249,0.4)","rgba(145,10,10,0.4)","rgba(106,232,104,0.4)","rgba(95,216,71,0.4)","rgba(81,90,214,0.4)","rgba(201,108,42,0.4)","rgba(204,204,26,0.4)","rgba(86,28,158,0.4)","rgba(34,132,181,0.4)","rgba(249,205,169,0.4)"];
    const borderColorSelector = ["rgba(194,249,248,1)","rgba(39,152,178,1)","rgba(127,83,198,1)","rgba(216,171,93,1)","rgba(244,204,255,1)","rgba(27,149,193,1)","rgba(226,117,83,1)","rgba(4,177,239,1)","rgba(53,75,221,1)","rgba(123,224,189,1)","rgba(178,205,247,1)","rgba(6,155,93,1)","rgba(137,244,199,1)","rgba(28,147,160,1)","rgba(213,139,249,1)","rgba(145,10,10,1)","rgba(106,232,104,1)","rgba(95,216,71,1)","rgba(81,90,214,1)","rgba(201,108,42,1)","rgba(204,204,26,1)","rgba(86,28,158,1)","rgba(34,132,181,1)","rgba(249,205,169,1)"];
    const state = {
      labels: props.info.labels,
      datasets: [
        {
          label: props.info.label,
          backgroundColor: backgroundColorSelector,
          borderColor:borderColorSelector,
          borderWidth: 1,
          data: props.info.data
        }
      ]
    }
      
   return (
    <Card className="p-3 mb-3">
      <Card.Body>
              <HorizontalBar
               data={state}
              options={{
                title:{
                  display:true,
                  text:props.info.title,
                  fontSize:20
                },
               scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            >  
            </HorizontalBar>
      </Card.Body>
    </Card>
    );
};

export default HorizontalBarChart;