import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { registrarCvDocente } from '../../actions/profesoresActions';

const withEditarResumenProfesional = WrappedComponent => props => {
  
  const [docente, idDocente] = useGetDocente();
  const dispatch = useDispatch();

  const handleSubmit = cv => {
    dispatch( registrarCvDocente(cv, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      resumenCv={docente.descripcion}
      handleSubmit={handleSubmit}
    />
   );
}
 
export default withEditarResumenProfesional;