import React from 'react';
import "./Clock.css";

import moment from 'moment-timezone';


const Clock = () => {

    setInterval( function() {
        var seconds = moment().tz('America/Lima').format('s');
        var sdegree = seconds * 6;
        var srotate = "rotate(" + sdegree + "deg)";
        try{
         document.getElementById('sec').style.transform = srotate;
        }
        catch(e)
        {
            
        }
        var hours = moment().tz('America/Lima').format('H');
        var mins = moment().tz('America/Lima').format('m');
         if(seconds<10)
           seconds="0"+seconds;
          if(mins<10)
          mins="0"+mins;
         var analogo= hours + ":"+ mins +":"+seconds;
         try{
         document.getElementById('digital').innerHTML=analogo;
         }
         catch(e){

         }
          }, 1000 );
        
      setInterval( function() {
        var hours = moment().tz('America/Lima').format('H');
        var mins = moment().tz('America/Lima').format('m');
        var hdegree = hours * 30 + (mins / 2);
        var hrotate = "rotate(" + hdegree + "deg)";
        try{
        document.getElementById('hour').style.transform = hrotate;
        }
        catch(e){

        }   
        }, 1000 );
    
    
    setInterval( function() {
        var mins =moment().tz('America/Lima').format('m');
        var mdegree = mins * 6;
        var mrotate = "rotate(" + mdegree + "deg)";
        try{
        document.getElementById('min').style.transform = mrotate;
        }
        catch(e){

        }
        }, 1000 );


    return (
        <>
            <ul id="clock">	
                <li id="sec"></li>
                <li id="hour"></li>
                <li id="min"></li>
			</ul>
            <div id="digital"></div>
            
        </>
    );
};

export default Clock;