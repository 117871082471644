import React from 'react';
import Header from './Header';
import ListItem from './ListItem';

const UserList = (props) => {

  if(!props.dataArr || props.dataArr.length===0)
  {
    return "No hay Datos para mostrar";
  }

    return (
        <div id="contenedor-ranking" className="clearfix">
        <Header mes={props.mes} />
      	<ul id="user-list">
        	{ props.dataArr ? props.dataArr.map((user, index) => (
            <ListItem 
              key={index}
              index={index + 1}
              img={user.img_usuario}
              name={user.nombre_usuario} 
              n_ventas={user.cantidad}
              gender = {user.sexo}
            />
          )): ''}
        </ul>
        </div>
    );
};

export default UserList;