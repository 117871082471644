import {
    LISTAR_ESTUDIANTES,
    REMOVER_STATE_ESTUDIANTE,
    ELIMINAR_MATRICUA_MODULO,
    LISTAR_MODULOS_COMPRADOS,
    REMOVER_MATRICULAR_PROYECTO,
    LISTAR_MODULOS_ESTUDIANTES,
    LISTAR_ESTUDANTE_PROYECTO,
    LISTAR_PROYECTOS_ESTUDIANTES,
    ELIMINAR_ESTUDIANTE,
    EDITAR_ESTUDIANTE,
    MOSTRAR_ESTUDIANTE,
    REMOVER_ESTADO,
    LISTAR_ESTUDIANTE,
    LISTAR_ALUMNOS_CURSO,
    INSERTAR_ESTUDIANTES,
    SELECT_ESTUDIANTE_OBTENIDO,
    estudianteTypes
} from '../actions/types';

const initialState = {
    estudiantes: [],
    estudiante: {},
    estudiantesCurso: [],
    estudiantesProyecto: [],
    modulosEstudiante: [],
    proyectosEstudiante: [],
    selectEstudiante: [],
    loading: false,
    error: null,
    estadoConsulta: 0
}

export default function(state = initialState, action){
    switch (action.type) {
        case LISTAR_ESTUDIANTES:
            return{
                ...state,
                estudiantes: action.payload
            } 
        case MOSTRAR_ESTUDIANTE: 
            return{
                ...state,
                estudiante: action.payload
            }
        case LISTAR_ALUMNOS_CURSO:
            return{
                ...state,
                estudiantesCurso: action.payload.datos
            }
        case LISTAR_ESTUDANTE_PROYECTO:
            return{
                ...state,
                estudiantesProyecto: action.payload 
            }
        case LISTAR_MODULOS_ESTUDIANTES:
            return{
                ...state,
                modulosEstudiante: action.payload
            }
        case LISTAR_MODULOS_COMPRADOS:
            return{
                ...state,
                modulosEstudiante: action.payload
            }
        case INSERTAR_ESTUDIANTES:
            return {
                ...state,
                estudiantes: action.payload.estudiantes,
                estadoConsulta: action.payload.estadoInsercion
            }
        case REMOVER_MATRICULAR_PROYECTO:
            return{
                ...state,
                proyectosEstudiante: action.payload
            }
        case ELIMINAR_MATRICUA_MODULO:
            return{
                ...state,
                modulosEstudiante: action.payload
            }
        case REMOVER_ESTADO:
            return{
                ...state,
                estadoConsulta: action.payload,
                estudiante: {}
            }
        case estudianteTypes.OBTENER_ESTUDIANTE:
            return {
                ...state,
                loading: true,
                error: null
            }
        case estudianteTypes.OBTENER_ESTUDIANTE_EXITO:
            return {
                ...state,
                loading: false,
                error: null,
                estudiante: action.payload
            }
        case LISTAR_ESTUDIANTE:
            return{
                ...state,
                estudiante: action.payload
            }
        case EDITAR_ESTUDIANTE:
            return{
                ...state,
                estudiantes: action.payload.estudiantes,
                estadoConsulta: action.payload.estadoInsercion
            }
        case LISTAR_PROYECTOS_ESTUDIANTES:
            return{
                ...state,
                proyectosEstudiante: action.payload
            }
        case ELIMINAR_ESTUDIANTE:
            return{
                ...state,
                estudiantes: action.payload.estudiantes,
                estadoConsulta: action.payload.estadoEliminar,
                estudiante: {},
                proyectosEstudiante: []
            }
        case SELECT_ESTUDIANTE_OBTENIDO: 
            return {
                ...state,
                selectEstudiante: action.payload 
            }
        case REMOVER_STATE_ESTUDIANTE:
            return{
                ...state,
                estudiante: {}
            }
        default:
            return state
    }
}