import React from 'react';

const Alerta = ({mensaje}) => {
    return ( 
        <div className="text-white alert alert-warning d-flex align-items-center" role="alert">
            <i className="fas fa-exclamation mr-2"></i> {mensaje}
        </div>
     );
}
 
export default Alerta;