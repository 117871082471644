import React, { useEffect, useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import useSelectProyecto from '../../../../hooks/useSelectProyectos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import './FormularioFiltroReporte.scss';
import useSimpleSelect from '../../../../hooks/useSimpleSelect';

const FormularioFiltroReporte = ({ onSubmit, setValores, ingresos = false }) => {
  
  const queryString = new URLSearchParams(window.location.search);
  const idProyecto = queryString.get('q');
  const fechaInicioQ = queryString.get('fechaInicio');
  const fechaFinQ = queryString.get('fechaFin');
  const idAreaQ = queryString.get('area');
  const [submitState, setSubmitState] = useState(false);
  const { defaultValue, SimpleSelect } = useSimpleSelect(opcionesAreas(), {value: idAreaQ});
  const [seleccionProyecto, SelectProyecto] = useSelectProyecto(idProyecto);
  const history = useHistory()


  const formik = useFormik({
    initialValues: {
      idProyecto: idProyecto,
      nombreProyecto: '',
      area: null,
      idArea: idAreaQ,
      fechaInicio: fechaInicioQ ? new Date(fechaInicioQ) : new Date(),
      fechaFin: fechaFinQ ? new Date(fechaFinQ) : new Date()
    },
    validationSchema: yup.object({
      fechaInicio: yup.date()
        .typeError('Entrada no válida: fecha por favor.')
        .required('Este campo es requerido.'),
      fechaFin: yup.date()
        .typeError('Entrada no válida: fecha por favor.')
        .required('Este campo es requerido.')
    }),
    onSubmit: valores => {
      history.push(`?q=${valores.idProyecto}&fechaInicio=${moment(valores.fechaInicio).format('L')}&fechaFin=${moment(valores.fechaFin).format('L')}&area=${valores.idArea}`)
    }
  });

  useEffect(() => {
    if(fechaInicioQ && fechaFinQ){
      onSubmit(formik.values);
      setSubmitState(true);
      if(setValores) {
        setValores(formik.values);
      }
    }
  }, [fechaInicioQ, fechaFinQ, idProyecto, idAreaQ])


  useEffect(() => {
    if(defaultValue) {
      formik.setFieldValue('area', defaultValue);
    }
  }, [defaultValue])

  useEffect(() => {
    formik.setFieldValue('idProyecto', seleccionProyecto ? seleccionProyecto.id_proyecto : null);
    formik.setFieldValue('nombreProyecto', seleccionProyecto ? seleccionProyecto.nombre_proyecto : null);
    if(seleccionProyecto !== undefined && submitState && setValores) {
      setValores(busqueda => ({
        ...busqueda,
        nombreProyecto: seleccionProyecto ? seleccionProyecto.nombre_proyecto : ''
      }));
      setSubmitState(false);
    }
  }, [seleccionProyecto, submitState])

  return (
    <div className="FormularioFiltroReporte">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={2}>
            <Form.Label>Producto</Form.Label>
            <SelectProyecto />
            {formik.errors.proyecto && (
              <Form.Text className="text-danger">
                {formik.errors.proyecto}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md={2}>
              <Form.Label className="w-100">Desde</Form.Label>
              <DatePicker
                selected={formik.values.fechaInicio}
                onChange={(date) => formik.setFieldValue("fechaInicio", date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                name="fechaInicio"
                startDate={formik.values.fechaInicio}
                className="form-control"
                endDate={formik.values.fechaFin}
              />
              {formik.errors.fechaInicio && (
                <Form.Text className="text-danger">
                  {formik.errors.fechaInicio}
                </Form.Text>
              )}
            </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label className="w-100">Hasta</Form.Label>
            <DatePicker
              selected={formik.values.fechaFin}
              onChange={(date) => formik.setFieldValue("fechaFin", date)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selectsEnd
              name="fechaFin"
              startDate={formik.values.fechaInicio}
              endDate={formik.values.fechaFin}
              minDate={formik.values.fechaInicio}
            />
            {formik.errors.fechaFin && (
              <Form.Text className="text-danger">
                {formik.errors.fechaFin}
              </Form.Text>
            )}
          </Form.Group>
          {
            ingresos && <Form.Group as={Col} md={2}>
              <Form.Label>Área</Form.Label>
              <SimpleSelect 
                name='area'
                value={formik.values.area}
                onChange={seleccion => {
                  formik.setFieldValue('area', seleccion);
                  formik.setFieldValue('idArea', seleccion ? seleccion.value : null);
                }}
                isClearable={true}
              />
            </Form.Group>
          }
          <Form.Group
            as={Col}
            md={2}
            className="d-flex align-items-end justify-content-end"
          >
            <Button variant='primary' type="submit" className="btn-icon">
              <FontAwesomeIcon icon={faSearch} />
              Buscar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormularioFiltroReporte;

const opcionesAreas = () => [
  {
    value: '1',
    label: 'CONSULTORÍA'
  },
  {
    value: '2',
    label: 'COMERCIAL'
  },
  {
    value: '3',
    label: 'MARKETING'
  },
  {
    value: '4',
    label: 'POSVENTA'
  }
]
