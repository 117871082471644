import React from 'react';
import './Agenda.scss'
import Cabecera from './Cabecera';
import ListaAgenda from './ListaAgenda';

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { nuevoApunte, editarApunte, eliminarApunte } from '../../../actions/apuntesActions';
import SpinnerLoading from '../Spinners/SpinnerLoading';

const Agenda = () => {

  const dispatch = useDispatch()
  const agregarApunte = (datos, id) => dispatch(nuevoApunte(datos, id))
  const edicionApunte = (datos, id) => dispatch(editarApunte(datos, id))
  const deleteApunte = (id) => dispatch(eliminarApunte(id))
  const loading = useSelector(state => state.apuntes.loading)
  const id = useSelector(state => state.apuntes.id)

  const handleNuevo = datos => {
    agregarApunte(datos, id)
  }

  const handleEditar = (datos, idDt) => {
    edicionApunte(datos, idDt)
  }

  const handleEliminar = id => {
    deleteApunte(id)
  }

  return ( 
    <div className="agenda">
      <SpinnerLoading
        loading={loading}
      >
        <Cabecera
          handleNuevo={handleNuevo}
          handleEditar={handleEditar}
        />
        <ListaAgenda
          handleEliminar={handleEliminar}
        />
      </SpinnerLoading>
      
    </div>
   );
}
 
export default Agenda;