import {
  
    boletasTypes
  
  } from "./types";
  
  
  import authAxios from "../config/authAxios";
  import response from '../config/response';
  import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
  import Swal from "sweetalert2/src/sweetalert2";
  import { updateStateModalLarge } from "./modalActions";

  export const listarBoletas = (usuario) => {

    return async dispatch => {
        dispatch({
          type: boletasTypes.OBTENER_BOLETAS
        });
        try {
    
            const comunicados = await authAxios.get(
                `/admin/listarBoletasDePago/${usuario.dni}/${usuario.id_tp_nivel_usuario}`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: comunicados.data.boletas,
              type: boletasTypes.OBTENER_BOLETAS_EXITO
            });
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
      }
  };


  export const subirBoletas = (datos,archivo,usuario) => async dispatch => {
  
    const file = new FormData();

    archivo.forEach(element => {
      file.append('archivos[]', element);
    });
    
      file.append('periodo',datos.periodo);
      file.append('mes',datos.mes);
    try 
    {
          avisoCargando('Subiendo archivos, espere por favor...')
          const subida = await authAxios.post(
                  `/admin/subirBoletas`,file,{headers:{'Context-type':'multipart/form-data'}}
                );

          // console.log(subida)
            dispatch(updateStateModalLarge())
            dispatch(listarBoletas(usuario))
    } catch (error) {
          console.log(error)
          avisoError('Hubo un error al subir los archivos')
        }
 };
 
 
  export const eliminarBoleta = (id) => {

    return async dispatch => {
      const datos={
        id_registro:id,
      }
      const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Estas seguro?",
          text: "Se eliminara todo registro",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
            try {
    
              const boletas = await authAxios.delete(
                  `/admin/eliminarBoleta/${datos.id_registro}`
                );
            
              dispatch({
                payload: boletas.data.boletas,
                type: boletasTypes.OBTENER_BOLETAS_EXITO
              })
              return true
             
          
          } catch (error) {
            avisoError('Hubo un error al eliminar el documento')
          }
        },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          // console.log(result)
          if (result.value === true) {
          
            swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Documento eliminado correctamente",
              "success"
            );
           
          }
        });
  };
}
