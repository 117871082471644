import React, { useState } from 'react';

const ReadMore = ({children,len}) => {
    const text = children;
    const largo = len ? len : 450
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
    <>
        { text.length>largo ?   <p className="text">
                {isReadMore ? text.slice(0, largo) : text}
                <a onClick={toggleReadMore} style={{fontWeight:'bold', marginLeft:'8px',color:'red', cursor:'pointer'}}>
                {isReadMore ? "...Leer más" : " mostrar menos"}
                </a>
            </p> : <p className="text"> {text} </p>
        }
      </>
    );
};

export default ReadMore;