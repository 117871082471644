import React from 'react';
import Indicador from './Indicador';

const ListarReporte = (props) => {

    const {asesores} = props;

    if(asesores===undefined || !asesores || asesores.lenght===0)
    {
        return "No hay datos."
    }

    return (
        <div className="row">
            {
                asesores ? asesores.map((asesor,i)=>(
                  <Indicador key={i} datos={asesor}/>
                )): ''
            }
        </div>
    );
};

export default ListarReporte;