import React from 'react';
import { Table } from 'react-bootstrap';
import FilaTablaDescuento from './FilaTablaDescuentos';



const TablaDescuentos = ({ descuentos = [], acciones = true }) => {
  

  const mostrarFilas = () => {
    if(!descuentos.length || !descuentos) {
      return <tr>
        <td colSpan={`${acciones ? '6' : '5'}`} align='center'>No hay descuentos</td>
      </tr>
    } else {
      return descuentos.map((descuento, index) => (
        <FilaTablaDescuento
          key={descuento.id_cargo_variable}
          index={index}
          descuento={descuento}
          acciones={acciones}
        />
      ))
    }
  }
  
  return ( 
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Concepto</th>
          <th>SubTotal (S/)</th>
          <th>Cantidad</th>
          <th>Total (S/)</th>
          { acciones && <th>Eliminar</th> }
        </tr>
      </thead>
      <tbody>
        {mostrarFilas()}
      </tbody>
    </Table>
  );
}
 
export default TablaDescuentos;