import React, { useState, useEffect } from 'react';
import { Toast, Button, Card, Table } from 'react-bootstrap';
import Modal from './Modal'
import authAxios from '../../../config/authAxios';
import './ModalDetalleProveedor.scss';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateMostrarComunicado } from '../../../actions/popupsActions';
//import { CSSTransition,TransitionGroup } from 'react-transition-group';
import * as moment from 'moment'
import { Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons';
const DetalleProveedor = () => {
  const [proveedorData, setProveedor] = useState(null);

  const {show, id_proveedor} = useSelector(state => state.popUp);
  const usuario = useSelector(state=> state.usuario.usuario);
  const dispatch = useDispatch();

  const getProveedor = async () => {
   
    const proveedor = await authAxios.get(`/admin/detalleProveedor/${id_proveedor}`);
    //console.log(proveedor)
    setProveedor(proveedor.data.proveedor[0]);
  }

  useEffect(() => {
    if (show) {
      getProveedor();
      window.scrollTo(0, 0)
      window.document.body.style.overflow = "hidden"
    }
    else{
      window.document.body.style.overflow = "auto"
    }
  }, [show])


  if (!proveedorData) return null; 

  return (
  
    <Modal
        active={show && proveedorData ? true : false}
       // toggle={() => dispatch(updateMostrarComunicado())}
      
      >
       <div className="detalle">
          <span className="d-flex justify-content-center align-items-center" style={{position:'absolute', width:'30px', height:'30px', background:'#fff', borderRadius:'100%', fontSize:'20px', padding:'2px',right:20, top:20, cursor:'pointer'}} onClick={()=> { 
            dispatch(updateMostrarComunicado())
            setProveedor(null) 
          } }>
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <img src="/images/detalleEmpleoHeader.jpg" alt="Avatar" style={{width:'100%', zIndex:'1'}} />
         
          
          <div className="detalle-body">
            <div className="image-detalle">
              <div style={{backgroundColor:'#5FBAA7',borderRadius:'5px', padding:'5px'}}>
                <img src="/images/contrato.png" style={{width:'100%'}} />
              </div>
            </div>
            <div className="contenido-detalle">
              <div className="head-detalle">
                <h4><b>{proveedorData.nombre_rs}</b></h4>
              </div>
              <div className="row p-1">
               <div className="col-12 col-md-10 p-2">  
                  <p style={{textAlign:'left', padding:'5px'}}><strong>RUC:</strong> {proveedorData.ruc}</p>
                  <p style={{textAlign:'left', padding:'5px'}}><strong>Dirección:</strong> {proveedorData.direccion}</p>
                  <p style={{textAlign:'left', padding:'5px'}}><strong>Catálogo:</strong><a href={proveedorData.catalogo}
                                                                            className="btn btn-info btn-xs"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"          
                                                                            >
                                                                              <FontAwesomeIcon icon={faLink} />
                                                                            </a></p>
               </div>
               <div className="col-12 col-md-2 p-2">  
                  <p style={{textAlign:'left'}}><strong>Área:</strong> {proveedorData.area}</p>
               </div>
              </div>
            <div style={{marginTop:'10px'}} >
                <Table style={{textAlign:'center'}}>
                <thead>
                  <tr>
                    <th>Contacto</th>
                    <th>Teléfono</th>
                    <th>Correo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{proveedorData.nombre_contacto}</td>
                    <td>{proveedorData.numero_contacto}/{proveedorData.telefono_fijo?proveedorData.telefono_fijo:null}</td>
                    <td>{proveedorData.correo?proveedorData.correo:'---'}</td>
                  </tr>
       
                </tbody>
                </Table>
              </div>
              <div className="p-2">
                 <h4> <b>Productos:</b></h4>
                 <div className="p-3" dangerouslySetInnerHTML={{__html:proveedorData.productos ? proveedorData.productos : 'No hay descripción.'}} ></div>
        
              </div>
            </div>
          </div>
        </div>
      </Modal>
   );
}
 
export default DetalleProveedor;