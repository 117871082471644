export const cambiarAside = () => {
    let aside = Array.from(document.getElementsByClassName("nav-md"))
    const menuSection = document.getElementById('menu_section')
    if (aside.length > 0) {
    
        aside[0].classList.remove('nav-md')
        menuSection.classList.remove('scroll-menu')
        aside[0].classList.add('nav-sm')
        return
   }
    aside = Array.from(document.getElementsByClassName("nav-sm"));
    if (aside.length > 0) {

      aside[0].classList.remove("nav-sm");
      aside[0].classList.add("nav-md");
      menuSection.classList.add('scroll-menu')
      return;
    }
}