import React from 'react';
import {Button} from "react-bootstrap";
import ListarOpcionesExamen from "./ListarOpcionesExamen";
const Pregunta = ({pregunta, handleChange ,opciones}) => {


  return ( 
    <div style={{borderBottom:"1px dashed #ebe", marginBottom:"5px"}}>
        <div className="row" ><div className="col-md-10 col-sm-10 col-xs-10"><h2  className="form-label">{pregunta ? pregunta.pregunta : 'no hay pregunta'}</h2></div><div className="col-md-2 col-sm-2 col-xs-2" style={{lineHeight:"40px", textAlign:"center"}}><span> Puntaje: {pregunta.puntaje}</span></div></div>
        {pregunta.descripcion!=='' ? <div className="form-text" style={{padding:"5px", marginBottom:"8px"}}>{pregunta.descripcion}</div> : '' }

        <ListarOpcionesExamen opciones={opciones.opciones_obtenidas} handleChange={handleChange} />  
    </div>
  );
}
 
export default Pregunta;