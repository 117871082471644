import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
//import { eliminarComentarioVideo } from '../../actions/cursosActions';
import { useParams } from 'react-router-dom';
import NuevaRespuesta from './NuevaRespuesta';
import Respuestas from './Respuestas';

const Comentario = ({comentario}) => {
    const dispatch=useDispatch()
    const {idSesion} = useParams()
    const usuario = useSelector(state=> state.usuario.usuario);
    const eliminarComentario = (id)=>{
    //  dispatch(eliminarComentarioVideo(id))
    }
    return (
    <div className="row" style={{padding:'15px', marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
            <div className="col-md-1">
            {
                comentario.img_usuario === null ? <img
               
                      alt=""
                      src='/images/user.png'
                     style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    /> :
                  <img
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}/${comentario.img_usuario}`}
                      style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    />
                
            }
            </div>
            <div className="col-md-6">
            <h4>{comentario.nombre_usuario + ' ' + comentario.apellido_usuario}</h4>
            <p>Publicado el: {comentario.fecha_registro}</p>
            </div>
            <div className="col-md-5">
            <p style={{fontSize:'15px'}}><strong>Producto:</strong> {comentario.nombre_proyecto}</p>
            <p style={{fontSize:'15px'}}><strong>Sesión:</strong> {comentario.nombre_sesion}</p>
            <p style={{fontSize:'15px'}}><strong>Video:</strong> {comentario.nombre_archivo}</p>
            <NuevaRespuesta id_comentario={comentario.id_comentario} />
            </div>
            <div className="col-12 p-3 mt-2" style={{fontSize:'13px', borderTop:'1px solid #ebe'}} > <p>{comentario.comentario}</p></div>
          { comentario.respuestas.length>0 ? <Respuestas respuestas={comentario.respuestas} index={comentario.id_comentario} /> : '' }
    </div>
    );
};

export default Comentario;