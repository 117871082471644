import zIndex from '@material-ui/core/styles/zIndex';
import React, { Component } from 'react';
import Portal from './Portal';
class Modal extends Component {

   

    render() {

     const {children,toggle,active,bakground} = this.props
     const styles={
        wrapper:{
            position:'fixed',
            top:0,
            left:0,
            width:'100%',
            height:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'start',
            zIndex:1002,
            background:'rgba(0,0,0,0.4)',
            paddingBottom:'100px',
            overflow:'auto'
        },
        window:{
            position:'relative',
            borderRadius:5,
            padding:0,
            boxShadow:'2px 2px 10px rbga(0,0,0,0.3)',
            zIndex:1003,
            maxWidth:600,
            width:'80%',
            height:'auto',
            marginTop:'85px'
        },
    
    
    }
      return( <Portal>
        {active && 
            <div style={styles.wrapper}>
                <div style={styles.window}>
                    <div>{children}</div>
                </div>
           
            </div>
        }
       </Portal>
       )
    }
}

export default Modal;
