import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarEstados } from '../actions/leadsActions';

const useSelectEstadosLeads = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const {estados} = useSelector(state => state.leads)

  const [estadoSeleccionado, setEstadoSeleccionado] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const estadoSelect = estados.find(estado => estado.id_estado === parseInt(initialSelect)); 
      if(estadoSelect) {
        setEstadoSeleccionado(estadoSelect);
      }
    }
  }

  useEffect(() => {
    if(!estados.length) {
      dispatch(listarEstados())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [estados])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!estados.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={estado => setEstadoSeleccionado(estado)}
        value={estadoSeleccionado}
        getOptionValue={estados => estados.id_estado}
        getOptionLabel={estados => estados.nombre_estado}
        options={estados}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [estadoSeleccionado, SimpleSelect, setEstadoSeleccionado]

}
 
export default useSelectEstadosLeads;