import React from 'react';
import { useState, useRef } from 'react';
import ListaProfeSelect from './ListaProfesSelect';
import Tabla from '../insercion/Tabla'
import {avisoError, avisoCargando, avisoCorrecto} from '../../../../components/AvisosMensajes/MensajesSwalf'
//REDUX
import {connect} from 'react-redux'
import { listarProfesores, insertarDocenteCurso} from '../../../../../actions/profesoresActions'
import MaterialTableDemo from '../../../../components/plugins/MaterialTableDemo';

const ModalAsignacionDocenteCopy = ({idCurso, insertarDocenteCurso,profesoresFaltantes}) => {
  
  const botonBorrarRef = useRef()

  const [array_docentes, setArrayDocentes] = useState([]) //solo contiene el id del docente
  const [error, setError] = useState()

  const guardarDocentes = (docente) => {

    let verificador = false

    if (array_docentes.length > 0) {
        array_docentes.map(item => {
          if(item.id_docente === docente.id_docente){
            verificador = true
            return
          }
        })
    }
    if(!verificador){
      setArrayDocentes([...array_docentes, docente])
      return
    } 

    avisoError('Profesor ya seleccionado')
  }

  const guardarDocentesCurso = async e => {
    e.preventDefault()
    setError(false)
    if (array_docentes.length < 1) {
      avisoError('No se a seleccionado profesores')
      return
    }
    avisoCargando('Guardando ...')
    const dataDocentes = {
      id_curso: idCurso,
      array_docentes
    }
    let {mensaje, textMensaje} = await insertarDocenteCurso(dataDocentes)
    if(mensaje){
      avisoCorrecto(textMensaje)
      botonBorrarRef.current.click()
      return
    }
    avisoError(textMensaje)
  }

  const quitarElementoLista = (docente) => {
    let newArrayDocente = array_docentes.filter(item => item.id_docente !== docente.id_docente)

    setArrayDocentes(newArrayDocente)
  }

  return (
    <div
      className="modal fade bd-example-modal-lg modal-asignar-profesor"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content p-3">
          <h3>Agregar Profesores</h3>
          <ListaProfeSelect 
            array_docentes={array_docentes}
            quitarElementoLista={quitarElementoLista}
          />
          {error ? 
            error ? 
            <div className="container-error container-fluid mt-3">
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                <i className="material-icons">warning</i>No se ha elegido ningun profesor a asignar
              </div>
            </div>
            : null
          :null
          }
          <div className="container-fluid d-flex w-100 my-3">
          
            <button className="btn btn-danger" onClick={e => guardarDocentesCurso(e)}>
              Guardar
            </button>
            <button className="btn ml-2 btn-dark" ref={botonBorrarRef} onClick={() => {
              setArrayDocentes([])
            }}>
              Borrar
            </button>
          </div>
          <MaterialTableDemo
            colums={colums(guardarDocentes)}
            tabla={profesoresFaltantes}
          />
         
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  profesores: state.profesores.profesores,
  profesoresCurso: state.profesores.profesoresCurso,
  curso: state.cursos.curso,
  profesoresFaltantes: state.profesores.profesoresFaltantes
})
 
export default connect(mapStateToProps, { listarProfesores, insertarDocenteCurso })(
  ModalAsignacionDocenteCopy
);

const colums = (guardarDocentes) =>[
  {
    text: 'id',
    key: 'id_docente',
    className: 'id',
    width: 50
  },
  {
    text: "Nombres",
    key: "nombre_usuario",
    className:"nombre_usuario",
    sortable: true
  },
  {
    text: "Apellidos",
    key: "apellido_usuario",
    className:"apellido_usuario",
    sortable: true
  },
  {
    text: "DNI",
    key: "dni",
    className:"dni",
    sortable: true
  },
  {
    text: "Acciones",
    key: "agregar",
    align: "center",
    className:"agregar",
    sortable: true,
    cell: (record)=>
    <button type="button" className="btn btn-primary btn-sm rounded-circle text-white" onClick={() => guardarDocentes(record)}>
    <i className="fas fa-plus"></i>
    </button>
  },
]