import {
    LISTAR_INFO_EMPRESA,
    LISTAR_OFERTAS,
    LISTAR_OFERTAS_EXITO,
    LISTAR_PROVEEDORES,
    LISTAR_PROVEEDORES_EXITO,
    LISTAR_CONTRATOS_P,
    LISTAR_CONTRATOS_P_EXITO,
} from '../actions/types';

const initialState = {
    empresa: {},
    ofertas:[],
    proveedores:[],
    contratos:[],
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case LISTAR_INFO_EMPRESA:
            return {
                ...state,
                empresa: action.payload
            }
        case LISTAR_OFERTAS:
            return {
                ...state,
                loading: true,
            }
        case LISTAR_OFERTAS_EXITO:
            return {
                ...state,
                loading: false,
                ofertas:action.payload
            }
        
        case LISTAR_PROVEEDORES:
            return {
                ...state,
                loading: true,
            }
        case LISTAR_PROVEEDORES_EXITO:
            return {
                ...state,
                loading: false,
                proveedores:action.payload
            }
        
        case LISTAR_CONTRATOS_P:
            return {
                ...state,
                loading: true,
            }
        case LISTAR_CONTRATOS_P_EXITO:
            return {
                ...state,
                loading: false,
                contratos:action.payload
            }
                
        default:
            return state
    }
}