import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actualizarEstadoIndicador } from '../../actions/controlCapacitacionesActions';

const withIndicador = WappedComponet => props  => {

  const { idCapacitacion } = useParams();
  const dispatch = useDispatch();

  const handleEstadoIndicador = (id, estado) => {
    dispatch( actualizarEstadoIndicador(idCapacitacion, estado, id ));
  }

  return ( 
    <WappedComponet
      {...props}
      handleEstadoIndicador={handleEstadoIndicador}
    />
   );
}
 
export default withIndicador;