import React, { useState, useEffect } from "react";
import Layout from "../../../../app/Layout";
import Paper from "@material-ui/core/Paper";
import PreguntaReporteTutor from "../../../components/PreguntaReporteTutor";
import { Button, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory, Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import {
  obtenerReporte,
  guardarReporteTutor,
} from "../../../../actions/encuestasAction";
import "./EncuestaReporte.scss";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import { avisoCargando } from "../../../components/AvisosMensajes/MensajesSwalf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const EncuestaReporte = (props) => {
  const params = useParams();
  const classes = useStyles();

  const { idTutorForm } = params;
  const history = useHistory();

  const {
    loading,
    reporteTutorGuardado,
    reporteTutor,
    obtenerReporte,
    guardarReporteTutor,
  } = props;

  const [dataEncuestaRespuesta, setDataEncuestaRespuesta] = useState({});
  const [desabilitarBoton, setDesabilitarBoton] = useState(false);

  const guardarEstrellas = (idPregunta, estrellas) => {
    const { respuestas } = dataEncuestaRespuesta;

    let copiaRespuestas = [...respuestas];
    console.log(idPregunta);
    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_tp_tema_encuesta === idPregunta) {
        respuesta.calificacion = estrellas || 0;
      }
    });

    setDataEncuestaRespuesta({
      ...dataEncuestaRespuesta,
      respuestas: copiaRespuestas,
    });
  };

  const generarInitialState = () => {
    let initialState = {
      id_tutor_formulario: idTutorForm,
      respuestas: [],
    };

    let preguntas = new Array();
    reporteTutor.preguntas.map((pregunta) => {
      preguntas = [
        ...preguntas,
        {
          id_tp_tema_encuesta: pregunta.id_tp_tema_encuesta, //Id de la pregunta
          id_respuesta_tutor: pregunta.id_respuesta_tutor,
          calificacion: 0, //La cantidad de estrellas
          propuestas: pregunta.propuestas,
        },
      ];
    });

    initialState.respuestas = preguntas;

    if (reporteTutor.id_tp_estado_formulario === "2") {
      avisoCargando(
        "El reporte ya ah sido enviado, no se podran hacer cambios"
      );
      setDesabilitarBoton(true);
    }

    setDataEncuestaRespuesta(initialState);
  };

  //Guardando las propuestas para el envio
  const guardarColeccionPropuestas = (idPropuesta, propuestas) => {
    const { respuestas } = dataEncuestaRespuesta;

    let copiaRespuestas = [...respuestas];

    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_tp_tema_encuesta === idPropuesta) {
        respuesta.propuestas.push(propuestas);
      }
    });

    setDataEncuestaRespuesta({
      ...dataEncuestaRespuesta,
      respuestas: copiaRespuestas,
    });
  };

  const editarColeccionPropuestas = (idPropuesta, propuesta) => {
    const { respuestas } = dataEncuestaRespuesta;

    let copiaRespuestas = [...respuestas];

    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_tp_tema_encuesta === idPropuesta) {
        respuesta.propuestas.map((item) => {
          if (item.id_sugerencia_tutor === propuesta.id_sugerencia_tutor) {
            item.propuesta = propuesta.propuesta;
          }
        });
      }
    });

    setDataEncuestaRespuesta({
      ...dataEncuestaRespuesta,
      respuestas: copiaRespuestas,
    });
  };

  const eliminarColeccionPropuestas = (idPropuesta, idEliminar) => {
    const { respuestas } = dataEncuestaRespuesta;

    let copiaRespuestas = [...respuestas];

    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_tp_tema_encuesta === idPropuesta) {
        let copiaPropuestas = [...respuesta.propuestas];
        copiaPropuestas = copiaPropuestas.filter(
          (p) => p.id_sugerencia_tutor !== idEliminar
        );
        respuesta.propuestas = copiaPropuestas;
      }
    });

    setDataEncuestaRespuesta({
      ...dataEncuestaRespuesta,
      respuestas: copiaRespuestas,
    });
  };

  //Guardar reporte tutor
  const guardarReporte = () => {
    guardarReporteTutor(dataEncuestaRespuesta);
  };

  useEffect(() => {
    obtenerReporte(idTutorForm);
  }, []);

  useEffect(() => {
    generarInitialState();
  }, [reporteTutor]);

  useEffect(() => {
    if (reporteTutorGuardado) {
      history.push("/tutor/eventos");
    }
  }, [reporteTutorGuardado]);

  return (
    <Layout>
      <div className="encuesta-reporte">
        <div className={`w-100 ${classes.root}`}>
          <Paper className="w-75 h-100 mx-auto pb-5 pt-0">
            <SpinnerLoading loading={loading}>
              <div className="logo w-100">
                <Image
                  src="/images/logo_intra2.png"
                  className="img img-fluid w_20"
                  alt="logo inedi"
                />
              </div>
              <div className="encuesta-reporte__encabezado w-100 text-center bg-primary mt-2 p-2">
                <Link
                  to="/tutor/eventos"
                  className="btn btn-primary float-left btn-sm"
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </Link>
                <h5 className="text-white font-weight-light">Reporte tutor</h5>
              </div>
              <div className="encuesta-reporte__cuerpo  mt-3">
                <Row className="border info-proyecto py-3 w-75 mx-auto">
                  <Col>
                    <p className="font-weight-bold">Evento</p>
                    <p className=""> {reporteTutor.nombre_proyecto} </p>
                    <p className="font-weight-bold mt-2">Sede</p>
                    <p className=""> {reporteTutor.sede} </p>
                  </Col>
                  <Col>
                    <p className="font-weight-bold">Días clase</p>
                    <p className=""> {reporteTutor.dias_clase} </p>
                  </Col>
                </Row>
                <div className="content-preguntas w-75 mt-4 mx-auto">
                  {reporteTutor.preguntas.map((pregunta, index) => (
                    <PreguntaReporteTutor
                      key={pregunta.id_respuesta_tutor}
                      pregunta={pregunta}
                      index={index}
                      guardarColeccionPropuestas={guardarColeccionPropuestas}
                      editarColeccionPropuestas={editarColeccionPropuestas}
                      eliminarColeccionPropuestas={eliminarColeccionPropuestas}
                      guardarEstrellas={guardarEstrellas}
                      desabilitarBoton={desabilitarBoton}
                    />
                  ))}
                </div>
              </div>
              <div className="botones w-75 mx-auto">
                <Button
                  disabled={desabilitarBoton}
                  variant="primary"
                  onClick={() => guardarReporte()}
                  className=""
                >
                  <FontAwesomeIcon icon={faPaperPlane} className='mr-2' />
                  Enviar
                </Button>
              </div>
            </SpinnerLoading>
          </Paper>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.encuestas.loading,
  reporteTutor: state.encuestas.reporteTutor,
  reporteTutorGuardado: state.encuestas.reporteTutorGuardado,
});

const mapDispatchToProps = {
  obtenerReporte,
  guardarReporteTutor,
};

export default connect(mapStateToProps, mapDispatchToProps)(EncuestaReporte);
