import { LISTAR_MENSAJES,LISTAR_PAPELERA,MANDAR_MENSAJE_E_PAPELERA, LISTAR_MENSAJES_ENVIADOS, MOSTRAR_MENSAJE, LISTAR_CONTACTOS, ENVIAR_MENSAJE, ACTUALIZAR_HILO, ACTUALIZAR_TAB_MENSAJERIA } from "../actions/types";

const initialState = {
    bandejaMensaje: [],
    enviadosMensaje: [],
    papeleraMensaje: [],
    mensaje: {},
    contactos: {},
    hilo:'',
    currentTab:'bandejaEntrada',
}

export default function(state = initialState, action){
    switch (action.type) {
      case LISTAR_MENSAJES:
        return{
            ...state,
            bandejaMensaje: action.payload
        }
      case LISTAR_MENSAJES_ENVIADOS:
        return{
          ...state,
          enviadosMensaje: action.payload
        }
      case MOSTRAR_MENSAJE:
        return{
          ...state,
          mensaje: action.payload
        }
      case ACTUALIZAR_HILO:
          return{
            ...state,
            hilo: action.payload
          }
      case ACTUALIZAR_TAB_MENSAJERIA:
        return{
          ...state,
          currentTab: action.payload
        }
      case LISTAR_CONTACTOS:
        return{
          ...state,
          contactos: action.payload
        }
      case MANDAR_MENSAJE_E_PAPELERA:
        return {
          ...state,
          papeleraMensaje: action.payload.papeleraMensaje,
          enviadosMensaje: action.payload.enviadosMensaje
        }
      case LISTAR_PAPELERA:
        return {
          ...state,
          papeleraMensaje: action.payload
        }
      case ENVIAR_MENSAJE:
        return{
          ...state,
          enviadosMensaje: action.payload.mensajes.datos
        }
      default:
        return state
    }
}