import React, { useState } from "react";
import { guardarApunteEditar, actualizarEstado } from '../../../../actions/apuntesActions';

//Redux
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from "react-bootstrap";

import './ListaAgenda.scss';

const ListaAgenda = props => {
  
  const { handleEliminar} = props
  const listado = useSelector(state => state.apuntes.apuntes)
  const [apunte, setApunte] = useState('')

  return (
    <div className="lista-agenda">
      { listado.length > 0 ? (
        listado.map((item, index) => (
          <ItemListado
            key={index}
            item={item}
            index={index}
            setApunte={setApunte}
            handleEliminar={handleEliminar}
          />
        ))
      ) : (
        <p>No hay comentarios</p>
      )}
      
    </div>
  );
};

export default ListaAgenda;

const ItemListado = props => {
  const dispatch = useDispatch()
  const edicionApunte = (datos) => dispatch(guardarApunteEditar(datos))
  const updateState = (id) => dispatch(actualizarEstado(id))

  const { item, handleEliminar, index } = props

  return (
    <Row className='lista-agenda__item'>
      <Col md={7} className='parrafo'>
        <p>
          {index+1}.- 
          {item.comentario}
        </p>
      </Col>
      <Col md={5}>
        <div className='acciones'>
          <button
            type='button'
            onClick={() => updateState(item.id_dt_reptec_desertado)}
            className={`estado ${item.id_estado_comentario === '0' ? 'incompleto' : 'completo'} btn btn-sm`}
          >
            {item.id_estado_comentario === '0' ? 'incompleto' : 'completado' }
          </button>
          <button 
            type='button'
            className='btn-editar btn'
            onClick={() => edicionApunte(item)}
          >
            Editar
          </button>
          <button
            typ='button'
            className='btn-eliminar btn'
            onClick={() => handleEliminar(item.id_dt_reptec_desertado)}
          >
            Eliminar
          </button>
        </div>
        
      </Col>
    </Row>
  )
}
