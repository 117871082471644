import { programacionesTypes } from '../actions/types';

const initialState = {
  programaciones: [],
  programacion: {
    todo: null,
    proyectoActivo: null,
    contratoActivo: null,
    progresoSubidaArchivo: null,
  },
  programacionActiva: null,
  loading: null,
  error: null,
  errorProgramacion: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case programacionesTypes.OBTENER_PROGRAMACIONES:
      return {
        ...state,
        loading: true
      };
    case programacionesTypes.OBTENER_PROGRAMACIONES_ERROR:
    case programacionesTypes.OBTENER_CICLOS_PROGRAMACION_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }
    case programacionesTypes.NUEVO_PROYECTO_PROGRAMACION_ERROR:
      return {
        ...state,
        errorProgramacion: true
      }
    case programacionesTypes.OBTENER_PROGRAMACIONES_EXITO:
      return {
        ...state,
        programaciones: action.payload,
        loading: null
      }
    case programacionesTypes.NUEVO_PROGRAMACION:
      return {
        ...state,
        programaciones: [...state.programaciones, action.payload]
      }
    case programacionesTypes.PROGRAMACION_ACTIVA:
      if(action.payload === undefined) {
        return {
          ...state,
          error: true,
          activa: null
        }
      }
      return {
        ...state,
        programacionActiva: action.payload
      }
    case programacionesTypes.EDITAR_PROGRAMACION:
      return {
        ...state,
        programaciones: [...state.programaciones].map(
          programacion => parseInt(programacion.id) === action.payload.id ? action.payload : programacion
        )
      }
    case programacionesTypes.ELIMINAR_PROGRAMACION:
      return {
        ...state,
        programaciones: [...state.programaciones].filter(
          programacion => programacion.id !== action.payload 
        )
      }
    case programacionesTypes.OBTENER_CICLOS_PROGRAMACION:
      return {
        ...state,
        loading: true,
        error: null
      }
    case programacionesTypes.OBTENER_CICLOS_PROGRAMACION_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        programacion: {
          ...state.programacion,
          todo: {
            ...state.programacion.todo,
            [action.payload.idProgramacion]: action.payload.datos
          }
        }
      }
    case programacionesTypes.NUEVO_PROYECTO_PROGRAMACION_EXITO:
      return {
        ...state,
        errorProgramacion: null,
        programacion: {
          ...state.programacion,
          todo: {
            ...state.programacion.todo,
            [action.payload.idProgramacion]: [
              ...state.programacion.todo[action.payload.idProgramacion],
              action.payload.datos
            ]
          }
        }
      }
    case programacionesTypes.ELIMINAR_PROYECTO_PROGRAMACION_EXITO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          todo: {
            ...state.programacion.todo,
            [action.payload.idProgramacion]: [...state.programacion.todo[action.payload.idProgramacion]].filter(
              proyecto => proyecto.id !== action.payload.idProyecto)
          }
        }
      }
    case programacionesTypes.PROYECTO_PROGRAMACION_ACTIVO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          proyectoActivo: action.payload
        }
      }
    case programacionesTypes.EDITAR_PROYECTO_PROGRAMACION_EXITO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          todo: {
            ...state.programacion.todo,
            [action.payload.idProgramacion]: [...state.programacion.todo[action.payload.idProgramacion].map(
              programacion => programacion.idProgrmProyecto === action.payload.idProyecto ? action.payload.datos : programacion
            )]
          }
        }
      }
    case programacionesTypes.CONTRATO_ACTIVO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          contratoActivo: action.payload
        }
      }
    case programacionesTypes.ACTUALIZANDO_PROCENTAJE_SUBIDA_ARCHIVO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          progresoSubidaArchivo: action.payload
        }
      }
    case programacionesTypes.NUEVO_CONTRATO_EXITO:
    case programacionesTypes.ELIMINAR_CONTRATO_EXITO:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          todo: {
            ...state.programacion.todo,
            [action.payload.idProgramacion]: state.programacion.todo[action.payload.idProgramacion].map(
              proyecto => parseInt(proyecto.id) !== parseInt(action.payload.idProyecto) ? proyecto :
              {
                ...proyecto,
                proveedores: proyecto.proveedores.map(proveedor => 
                  parseInt(proveedor.idProveedor) === parseInt(action.payload.datos.idProveedor)
                    ? action.payload.datos 
                    : proveedor 
                )
              }
            )
          }
        }
      }
    case programacionesTypes.NUEVO_CONTRATO_ERROR:
      return {
        ...state,
        programacion: {
          ...state.programacion,
          progresoSubidaArchivo: null
        }
      }
    default:
      return state;
  }
}