
import React from 'react';
import { Table } from 'react-bootstrap';
//Redux
const ResultadosFiltro = ({resultados}) => {
  console.log(resultados)
 return (
 
      <div className="row p-2">
       { /*<div className="col-12 mb-2">
         <h4>Campaña: {resultados?.campaign} </h4>   
        </div>*/}
        <div className="col-md-6">

            <div className="embudo">
              <div className="embudo_1">
                <h3>{resultados.resumen.base_leads}</h3>
                <p>Leads recolectados</p>
              </div>
              <div className="embudo_2">
                <h3>{resultados.resumen.contestados}</h3>
                <p>Contestaron</p>
              </div>
              <div className="embudo_3">
                <h3 style={{marginTop:'-30px'}}>{resultados.resumen.clientes}</h3>
                <p style={{marginTop:'-15px'}}>Clientes</p>
              </div>
            </div>

        </div>    
        <div className="col-md-6">
        <Table >
              <thead>
                <tr>
                  <th>
                    Inversión Total
                  </th>
                  <th>
                   Costo x Lead
                  </th>
                  <th>
                   Costo x Adquisición
                  </th>
                </tr>
              </thead>
              <tbody>
                      <tr>
                      <td style={{textAlign:'center'}}>S/. {resultados.costos.inversion_t}</td>
                      <td style={{textAlign:'center'}}>S/. {isNaN(resultados.costos.inversion_t/resultados.resumen.base_leads) ? 0 : parseFloat(resultados.costos.inversion_t/resultados.resumen.base_leads).toFixed(2) }</td>
                      <td style={{textAlign:'center'}}>S/. {isNaN(resultados.costos.inversion_t/resultados.resumen.clientes) ? 0 : parseFloat(resultados.costos.inversion_t/resultados.resumen.clientes).toFixed(2) }</td>
                      </tr>
              </tbody>
              
          </Table>
          <Table style={{marginTop:'45px'}}>
              <thead>
                <tr>
                  <th>
                      Descripción
                  </th>
                  <th>
                    Cantidad
                  </th>
                  <th>
                    Porcentaje (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {
                    resultados.clasificaciones.map((estado,i)=>(
                      <tr>
                      <td>{estado.nombre_estado}</td>
                      <td style={{textAlign:'right'}}>{estado.cantidad}</td>
                      <td style={{textAlign:'right'}}>{parseFloat((estado.cantidad/resultados.resumen.base_leads)*100).toFixed(2)}%</td>
                      </tr>
                    ))
                  }
              </tbody>
              
          </Table>

         
        </div>     
      </div>
    
  
  
    );
};

export default ResultadosFiltro;
