import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import {actualizarComentarioWebinar} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const ComentarioWebinar = ({campaign,tipo}) => {


const {resumen_webinar} = useSelector(state=>state.campaigns)

const [comentario, setComentario]=useState(resumen_webinar[tipo]?resumen_webinar[tipo]:'')

const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      id_camp: campaign?campaign:null,
      campo: tipo,
      comentario: comentario!==''?comentario:'',
    }
  console.log(datosCamp)
  const retornarListaActualizada = async () =>{
    await (dispatch(actualizarComentarioWebinar(datosCamp)))
    }
  
    
  // console.log(datosPop)
    if(datosCamp.comentario!=='')
    { 
      console.log(datosCamp.comentario)
      retornarListaActualizada()
    }
    else
    {
      avisoError('Faltan campos por rellenar...')
    }
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Campaña:
                      </label>
                    </div>
                    <div className="col-md-7">
                       <p>{resumen_webinar.campaign}</p>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Comentario:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <textarea className="form-control" name="comentario" value={comentario} onChange={(e)=>setComentario(e.target.value)} >
                      </textarea>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default ComentarioWebinar;
