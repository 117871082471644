import authAxios from '../config/authAxios';


export const crearEnlace = () => async dispatch => {
    try {
      let respuesta = await authAxios.get(`/admin/crearCodigoFormulario`)
  
      if (!respuesta.data.error) 
      {
        return {mensaje: true, url: respuesta.data.url}
      }
      else
        {   
          return {mensaje: false, textMensaje: respuesta.data.error}
        }
      
    } catch (error) {
    //  console.log(error)
      return {mensaje: false, textMensaje: error}
    }
    
  }

  
export const obtenerContratos = () => async dispatch => {
  try {
    let respuesta = await authAxios.get(`/admin/obtenerContratos`)
    //console.log(respuesta)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, contratos: respuesta.data}
    }
    else
      {   
        return {mensaje: false, contratos: null}
      }
    
  } catch (error) {
   // console.log(error)
    return {mensaje: false, textMensaje: error}
  }
  
}
  
export const obtenerTopVentas = (consulta) => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/obtenerTopVentas`,consulta)
    //console.log(respuesta)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, top: respuesta.data}
    }
    else
      {   
        return {mensaje: false, top: null}
      }
    
  } catch (error) {
    //console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}
  