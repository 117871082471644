import React from 'react';
import './CompartirEncuesta.scss';
import { Form, Button } from 'react-bootstrap';

const CompartirEncuesta = props => {

  const { updateShowModal, encuesta } = props

  const onSubmit = e => {
    e.preventDefault()
    updateShowModal(false)
  }

  return ( 
    <div className="compartir-encuesta">
    <Form onSubmit={onSubmit}>
        <Form.Control
          style={{borderRadius: '10px', fontSize: "12px", padding: '10px', height: '40px'}}
          placeholder='Link para compartir'
          className='mb-2'
          defaultValue={`https://encuestas.inedi.edu.pe/form/${encuesta.url}`}/>
    </Form>
    </div>
   );
}
 
export default CompartirEncuesta;