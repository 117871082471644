import axios from 'axios';
import { deleteJWt } from '../helpers/functions';

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
 
});

authAxios.interceptors.response.use(
  response => new Promise((resolve, reject) => {
    resolve(response);
  }),
  error => {
    if(!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      })
    }

    if(error.response.status === 403) {
      deleteJWt();
      window.location = '/login';
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      })
    }
  }
)

export default authAxios;