import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import{ useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { editarProgramacionAnual, nuevaProgramacion} from '../../../../actions/programacionesActions';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import useForm from '../../../../hooks/useForm';

const FormularioProgramacion = () => {

  const dispatch = useDispatch();
  const activo = useSelector(state => state.programaciones.programacionActiva)  
  const initialState = {
    programacionAnual: '',
    prototipa: '',
    mide: '',
    formula:''
  }

  const [datosProgramacion, setDatosProgramacion] = useForm(activo || initialState) 

  const { programacionAnual, prototipa, mide, formula } = datosProgramacion;

  const handleSubmit = async e => {
    e.preventDefault();
    if (programacionAnual === '') {
      avisoError('No se ha ingresado nombre de la programación');
      return;
    }
    if (activo) {
      //funcion para editar
      await dispatch(editarProgramacionAnual(datosProgramacion))
    } else {
      //funcion para insertar
      await dispatch(nuevaProgramacion(datosProgramacion));
    }
    handleClose()
  }

  const handleClose = () => {
    dispatch(updateStateModalBasic());
  }

  return ( 
    <section className="FormularioProgramacion">
      <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label className='label-required'>Nombre:</Form.Label>
          <Form.Control
            type='text'
            name='programacionAnual'
            value={programacionAnual}
            onChange={setDatosProgramacion}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>URL Inversión:</Form.Label>
          <Form.Control
            type='text'
            name='prototipa'
            value={prototipa}
            onChange={setDatosProgramacion}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>URL Medición:</Form.Label>
          <Form.Control
            type='text'
            name='mide'
            value={mide}
            onChange={setDatosProgramacion}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>URL Formulación:</Form.Label>
          <Form.Control
            type='text'
            name='formula'
            value={formula}
            onChange={setDatosProgramacion}
          />
        </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            className='btn-icon'
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button
            variant='info'
            className='btn-icon'
            type='submit'
          >
            <FontAwesomeIcon icon={faSave}/>
            { activo ? 'Guardar' : 'Agregar' }
          </Button>
        </Modal.Footer>
      </Form>
    </section>
  );
}
 
export default FormularioProgramacion;