import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faListAlt} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";

import "./Trabajadores.scss";

//Reduxt
import {useDispatch } from "react-redux";
import { obtenerContratos } from "../../../actions/comercialActions";
import Voucher from "./Voucher";

 
const Trabajadores = () => {
  //State
  const dispatch = useDispatch();

  const[loading, setLoading] = useState(true)
  const[contratos, setContratos] = useState(null);  

  useEffect(() => {
    const fetchData = async () =>{
      const respuesta = await (dispatch(obtenerContratos()))
      setContratos(respuesta.contratos)
      setLoading(false);
    }

    fetchData();


  }, []);
  const handleModalNuevo = (ruta) => {
    dispatch(updateStateModalBasic(<Voucher rutaImagen={ruta} />, 'Voucher'));
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Contratos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "Contratos" },
              ]}
            />
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de contratos
                  </h5>
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleModalNuevo)}
                    tabla={contratos}
                    keyCol="id_contrato"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Trabajadores;

/**====================== TABLA =========== */

const colums = (handleModalNuevo) => [
  {
    text: 'ID',
    key: 'id_contrato',
    className: 'id_contrato',
    width: 50
  },
  {
    text: "Fecha Registro",
    key: "fecha_registro",
    align: "center",
    className: "fecha_registro",
    sortable: true
  },
  {
    text: "Proyecto",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto",
  },
  {
    text: "Asesor",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    className: "nombre_usuario",
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "dni",
    width: 50
  },
  {
    text: "Nombres",
    key: "nombres",
    align: "center",
    className:"nombres",
    sortable: true,
  },
  {
    text: "Apellidos",
    key: "apellidos",
    align: "center",
    className: "apellidos",
    sortable: true,
  },
  {
    text: "Celular",
    key: "celular",
    align: "center",
    className:"celular",
    sortable: true,
  },
  {
    text: "Correo",
    key: "correo",
    align: "center",
    className: "correo",
    sortable: true,

  },
  {
    text: "Inversión",
    key: "inversion",
    align: "center",
    className: "inversion",
    sortable: true,

  }
  ,
  {
    text: "Tipo de Compra",
    key: "tipo_compra",
    align: "center",
    className: "tipo_compra",
    sortable: true,

  }
  ,
  {
    text: "Fecha inicio",
    key: "fecha_inicio_membresia",
    align: "center",
    className: "fecha_inicio_membresia",
    sortable: true,

  }
  ,
  {
    text: "Fecha Fin",
    key: "fecha_fin_membresia",
    align: "center",
    className: "fecha_fin_membresia",
    sortable: true,

  },
  {
    text: "N° Operación",
    key: "n_operacion",
    align: "center",
    className: "n_operacion",
    sortable: true
  }
  ,
  {
    text: "Comprobante",
    key: "tipo_comprobante",
    align: "center",
    className: "tipo_comprobante",
    sortable: true,
    cell: (record) => <> {record.tipo_comprobante!=="0" ? record.tipo_comprobante==="1"? 'Boleta' : 'Factura': 'No determina'} </>
  }
  ,
  {
    text: "Ruc",
    key: "ruc",
    align: "center",
    className: "ruc",
    sortable: true
  }
  ,
  {
    text: "Voucher",
    key: "voucher",
    align: "center",
    className: "voucher",
    sortable: false,
    cell: (record) => <>
    <img style={{maxWidth:"150px"}} src={process.env.REACT_APP_API_URL+'/vouchers/'+record.voucher} alt="imagen" onClick={()=>handleModalNuevo(process.env.REACT_APP_API_URL+'/vouchers/'+record.voucher)} />
  </>
  }
  ,
 
];
