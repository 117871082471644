import React from 'react';
import { useState } from 'react';

const FormMisionVisión = () => {

    const [contenido, setContenido] = useState({
        mision: '',
        vision: ''
    })

    const {mision, vision} = contenido

    const onChange = e => {
        setContenido({
            [e.target.name]: e.target.value
        })
    }

    return ( 
        <form>
            <div className="form-group">
                <label htmlFor="mision">
                    Misión:
                </label>
                <textarea
                    name="mision"
                    id="mision"
                    className='form-control'
                    cols="30"
                    value={mision}
                    onChange={onChange}
                    rows="5"
                >
                </textarea>
            </div>
            <div className="form-group">
                <label htmlFor="vision">
                    Visión:
                </label>
                <textarea
                    name="vision"
                    id="vision"
                    className='form-control'
                    cols="30"
                    value={vision}
                    onChange={onChange}
                    rows="5"
                >
                </textarea>
            </div>
        </form>
     );
}
 
export default FormMisionVisión;