import React, { Fragment } from 'react';

import Select from 'react-select';
import { useState } from 'react';

const  SimpleSelect = (props) => {

  const {
    setIdProyectoSeleccion,
    setIdCursoSeleccion,
    defaultValue,
    options,
    setIdSesionSeleccion,
    setHorarioSeleccion,
    getSelection,
    name='',
    ...rest
  } = props


  const [selectedOption, setSelectedOption] = useState(null)
 
  const handleChange = selectedOption => {

    setSelectedOption(selectedOption)
    if (selectedOption !== null) {
      if (setIdCursoSeleccion !== undefined) {
        setIdProyectoSeleccion(selectedOption)
        return
      }
      if (setIdSesionSeleccion !== undefined) {
        setIdSesionSeleccion(selectedOption)
        return
      }
      if (setHorarioSeleccion !== undefined) {
        setHorarioSeleccion(selectedOption)
        return
      }
    }
    if (getSelection !== undefined) {
      getSelection(selectedOption !== null ? selectedOption : {})
      return
    }
  };


  return ( 
    <Fragment>
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={selectedOption || defaultValue}
          onChange={handleChange}
          name={name}
          options={options}
          {...rest}
        />
      </Fragment>
   );
}
 
export default React.memo(SimpleSelect);