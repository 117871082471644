import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Layout from '../../../../app/Layout';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import ResumenFactura from '../../../components/Finanzas/ResumenFactura';
import DescuentosFactura from '../../../components/Finanzas/DescuentosFactura';
import CargosVariablesFactura from '../../../components/Finanzas/CargosVariablesFatura';
import { useParams } from 'react-router-dom';
import './ExaminarReporteIngresos.scss'

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerDatosFactura } from '../../../../actions/finanzasReportesActions';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import DatosGeneralesFactura from '../../../components/Finanzas/DatosGeneralesFactura';

const ExaminarReporteIngresos = () => {
  
  const { idIngreso } = useParams();
  const { loading, error } = useSelector(state => state.finanzasReportes);
  const { descuentos, cargos_variables: cargosVariables, ...rest } = useSelector(state => state.finanzasReportes.facturas.activo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(obtenerDatosFactura(idIngreso));
  }, [dispatch, idIngreso])

  return (
    <Layout>
      <div className="ExaminarReporteIngresos">
        <div className="ExaminarReporteIngresos__head">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <HeaderContent title='Examinar ingreso'/>
            {
              !loading && <h3><span>N°&nbsp;Comprobante:&nbsp;{rest.nro_documento}</span></h3>
            }
          </div>
          <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/reporte-ingresos", nombre: "Reporte ingresos" },
                { url: "/faturas/", nombre: "Examinar ingreso" },
              ]}
            />
        </div>
        <div className="ExaminarReporteIngresos__body">
          <SpinnerLoading loading={loading} error={error}>
            <Row className='mt-3'>
              <Col md={12}>
                <DatosGeneralesFactura 
                  datosFactura={rest}
                />
              </Col>
              <Col md={4}>
                <DescuentosFactura 
                  descuentos={descuentos}
                />
                <CargosVariablesFactura
                  cargosVariables={cargosVariables}
                />
              </Col>
              <Col md={8} className='ExaminarReporteIngresos__bottom--right'>
                <ResumenFactura 
                  descuentos={descuentos}
                  cargosVariables={cargosVariables}
                  total={rest.monto}
                  estado={rest.tp_estado_registro}
                  detallesAnulacion={rest.detalle_anulacion}
                />
              </Col>
            </Row>
          </SpinnerLoading>
        </div>
      </div>
    </Layout> 
    
  );
}
 
export default ExaminarReporteIngresos;