import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';

import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import {crearEnlace} from '../../../actions/comercialActions';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { useDispatch } from 'react-redux';

const CrearEnlacesFormularios = () => {

  const [link,setLink] = useState('');
  const dispatch = useDispatch();
  
    const fetchData = async () => {
      const respuesta = await dispatch(crearEnlace())
      if(respuesta.mensaje)
      {
      avisoCorrecto("Link creado con éxito")
      setLink (respuesta.url)
      }
      else
      {
        avisoError("Hubo un error al crear el link.")
      }
    }



  

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Generar enlace de Contrato' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/contratos/enlaces", nombre: "Enlaces" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
            <div className="row">
              <div className="col-md-2 text-center">
                <button className="btn btn-primary mt-2" onClick={fetchData}>Crear Enlace</button>
              </div>
              <div className="col-md-10">
                <label>Enlace:</label>
                <input className="form-control" type="text" value={link} readOnly={true}></input>
              </div>
            </div>
            </div>
          </div>    
        </div>
      </div>
    </Layout>
  );
}
 
export default CrearEnlacesFormularios;