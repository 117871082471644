import React from 'react';
import Clasificacion from './Clasificacion';
import EditarClasificacion from '../EditarClasificacion';


//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../../../../actions/modalActions';
import ListaClasificacionHead from './ListaClasificacionHead';
import ResultadoClasificacion from './ResultadoClasificacion';

const ListaClasificacion = ({ list, nombre_estado }) => {

  const dispatch = useDispatch()
  
  const handleModalEditar = () => {
    dispatch( updateStateModalCustom(<EditarClasificacion /> , 'Editar clasificación del docente', 'modal-w40') )
  }
 
  return ( 
    <div className="ListaClasificacion">
      <div className='ListaClasificacion__head border-bottom py-3 d-flex justify-content-between align-items-center'>
        <ListaClasificacionHead
          handleModalEditar={handleModalEditar}
        />
      </div>
      <div className='ListaClasificacion__body my-4'>
        <ul className="ListaClasificacion__list pl-0 clearfix">
          {list.map(item => (
            <Clasificacion 
              key={item.id_clasificacion_dc}
              {...item}
            />
          ))}
        </ul>
        <div className='ListaClasificacion__resultado'>
          <ResultadoClasificacion 
            nombre_estado={nombre_estado}
          />
        </div>
      </div>
    </div>
   );
}
 
export default ListaClasificacion;