import React from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb';
import ListaReportesTutor from '../../../components/ListaReportesTutor';

const SesionesTutor = () => {
  return ( 
    <Layout>
      <HeaderContent
        title='Sesiones'
      />
      <Breadcrumb
          link={[
            {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
            {url:'/eventos/tutor', nombre: 'Eventos'},
            {url:'', nombre: 'Sesiones'},
          ]}
        />
      <div className="sesiones-tutor">
          <ListaReportesTutor/>
      </div>
    </Layout>
   );
}
 
export default SesionesTutor;