import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';

import { actualizarLead } from '../../../actions/leadsActions';
import Timer from '../../components/TimerCall/Timer';
const EditarLead = ({registro}) => {


  const usuario = useSelector(state=> state.usuario.usuario);
  const {leads,estados,observaciones} = useSelector(state=> state.leads);
  const [seconds,setSeconds] = useState(registro.tiempo_llamada ? parseInt(registro.tiempo_llamada) : 0)
  const dispatch = useDispatch();
  const initialState = {
    notas: registro.notas ? registro.notas : '',
    lead:registro.id_lead,
    id_usuario: usuario ? usuario.id_usuario : null,
    id_tp_nivel_usuario: usuario ? usuario.id_tp_nivel_usuario : null,
    estado: registro.estado,
    observacion:registro.observaciones,
    nombres: registro.nombres,
    apellidos: registro.apellidos,
    fecha_llamada:registro.fecha_llamada ? registro.fecha_llamada : '',
    fecha_zoho:registro.fecha_zoho ? registro.fecha_zoho : '',
    tiempo_llamada: registro.tiempo_llamada ? parseInt(registro.tiempo_llamada):0,
  }
  const [datosLead,setdatosLead]=useState(initialState)

  const {notas,estado,nombres,apellidos,observacion, tiempo_llamada} = datosLead

  const handleChange = (evt) => {
    const value = evt.target.value;
    setdatosLead({
      ...datosLead,
      [evt.target.name]: value
    });
  }

  const setTiempoLLamada = (tiempo)=>{
    setdatosLead({
      ...datosLead,
      tiempo_llamada:tiempo
    })
  }
  const editarLead = async (e) =>{
    e.preventDefault();
   await dispatch(actualizarLead(datosLead,leads,estados,observaciones))
  //console.log(datosLead)
  }

  useEffect(()=>{
    setTiempoLLamada(seconds)
  },[seconds])


  return (
    <div style={{padding:'15px'}}>
      <form onSubmit={(e) => editarLead(e)}>
        <div className="row">
          <div className="col-6">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Nombres</label>
            <p>{nombres}</p>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Apellidos</label>
            <p>{apellidos}</p>
          </Box>
          </div>
          <div className="col-6">
            <Timer seconds={seconds} setSeconds={setSeconds} />
          </div>
          <div className="col-12">
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Estado</label>
            <select name="estado" className="form-control" value={estado} onChange={handleChange}>
            { estados.length>0 ? estados.map((estado,i)=>{
               return <option key={i} value={estado.id_estado}>{estado.nombre_estado}</option>
              }):null}
            </select>
          </Box>

          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Observaciones</label>
            <select name="observacion" className="form-control" value={observacion} onChange={handleChange}>
              { observaciones.length>0 ? observaciones.map((observacion_x,i)=>{
               return <option key={i} value={observacion_x.id_observacion}>{observacion_x.observacion_n}</option>
              }):null}
            </select>
          </Box>
          
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Notas</label>
            <textarea name="notas" className="form-control" value={notas} onChange={handleChange} />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
           Guardar Información
          </button>
          </Box>
          </div>
        </div>
      </form>
  </div>
   );
}
 
export default EditarLead;