import { useState } from "react";

const useTotalDescontado = (initialState = null) => {
  
  const [totalDescuento, setTotalDescuento] = useState(initialState);
  
  const obtenerTotalDescuento = (descuentos) => {
    let totalDescuentoObtenido = 0;
    if (descuentos.length) {
      descuentos.forEach(descuento => {
        totalDescuentoObtenido+=parseFloat(descuento.total);
      })
    }
    setTotalDescuento(totalDescuentoObtenido);
  }

  return { totalDescuento, obtenerTotalDescuento };
}
 
export default useTotalDescontado;