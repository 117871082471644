import React, { useState, useEffect } from 'react';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb'
import DrawerMenu from '../../../components/DrawerMenu';
import Layout from '../../../../app/Layout';
import FiltroReporteEncuesta from '../../../components/FiltroReporteEncuesta';
import ChartArea from '../../../components/plugins/ChartArea';
import MensajeLoading from '../../../components/MensajeLoading';
import TablaReporteEncuestas from '../../../components/Tablas/TablaReporteEncuestas';

//Redux
import { connect } from 'react-redux';
import { listarReporteEncuesta, actualizarStateInicial } from '../../../../actions/reportesActions';

import './ReportesTutoria.scss'


const ReportesTutoria = props => {

  const { sesionesSelecionadas, listarReporteEncuesta, actualizarStateInicial } = props

  const [infoProyecto, setProyecto] = useState({})
  const [sesionesSeleccionadas, setSesionesSeleccionadas] = useState([])
  const [categoriasEncuestados, setCategoriasEncuestados] = useState({})
  const [dataEncuestados, setDataEncuestados] = useState({})

  const guardarCategorias = (valor) => {
    setCategoriasEncuestados(valor)
  }

  const guardarData = (valor) => {
    setDataEncuestados(valor)
  }

  const guardarSesiones = (indice, valor) => {

    let copiaSesionesSelect = [...sesionesSeleccionadas]
    
    for (let index = 0; index < copiaSesionesSelect.length; index++) {
      if( index === indice){
        copiaSesionesSelect[index] = valor
      }
    }
    actualizarStateInicial(copiaSesionesSelect)
  }


  useEffect(() => {
    listarReporteEncuesta(infoProyecto.value)
  }, [infoProyecto])

  useEffect(() => {
    setSesionesSeleccionadas(sesionesSelecionadas)
  }, [sesionesSelecionadas])

  return ( 
    <Layout>
      <HeaderContent
        title='Reporte de Calidad'
        icon='insert_chart_outlined'
      />
      <Breadcrumb
        link={[
          {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
          { url: "", nombre: "Reporte" },
          { url: "", nombre: "Calidad" },
        ]}
      />
      
      <div className="reportes">
        {sesionesSeleccionadas.length === 0 ||  Object.keys(infoProyecto).length === 0 ? (
          <MensajeLoading
            primerMensaje='Presiona el botón de filtrar'
            segundoMensaje='para comenzar a mostrar el reporte'
            imagen='/images/chart.jpg'
          />
        ) : (
          <>
            <TablaReporteEncuestas/>
            <ChartArea
            titulo='Gráfico estadístico de calificación al docente por el discente'
            categories={categoriasEncuestados.docente}
            data={dataEncuestados.docente}
            />
            <ChartArea
              titulo='Gráfico estadístico de calificación al tutor por el discente'
              categories={categoriasEncuestados.tutor}
              data={dataEncuestados.tutor}
            />
            <ChartArea
              titulo='Gráfico estadístico de calificación al tutor por el docente'
              categories={categoriasEncuestados.docente_tutor}
              data={dataEncuestados.docente_tutor}
            />
          </>
        ) }
        <DrawerMenu>
          <FiltroReporteEncuesta
            setProyecto={setProyecto}
            infoProyecto={infoProyecto}
            guardarCategorias={guardarCategorias}
            sesionesSeleccionadas={sesionesSeleccionadas}
            guardarData={guardarData}
            sesionesSeleccionadas={sesionesSeleccionadas}
            guardarSesiones={guardarSesiones}
          />
        </DrawerMenu>
      </div>
    </Layout>
   );
}
 
const mapStateToProps = (state) => {
  return {
    reporteTutoria: state.reporte.reporteTutoria,
    sesionesSelecionadas: state.reporte.sesionesSelecionadas
  }
}

export default connect(mapStateToProps, {listarReporteEncuesta, actualizarStateInicial})(ReportesTutoria);