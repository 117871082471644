import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import './ContenidoPlan.scss';
import ConceptoItemResumen from './ConceptoItemResumen';
import * as moment from 'moment';

const ContenidoPlan = ({ plan = {}, setConceptosFinales, conceptosFinales, handleEditarConcepto }) => {

  const { dt_plan: conceptos = [], id_tp_modalidad: idModalidad } = plan;
  

  const mostarItems = () => {
    if(!Object.keys(plan).length) return <Alert variant='info'>
      Selecciona un plan para mostrar contenido
    </Alert>

    if(!conceptos.length) {
      return <Alert variant='danger'>
        No hay contendio en este plan
      </Alert>
    } else {      
      return [...conceptosFinales].map((concepto, index) => (
        <ConceptoItemResumen
          key={index}
          handleEditarConcepto={handleEditarConcepto}
          idModalidad={idModalidad}
          concepto={concepto}
        />
      ))
    }
  }

  const actualizarFechas = () => {
    let copiaConceptos = [...conceptos];
    if(idModalidad !==2) { //Si es Mensualidad
      let fechaActual = new Date(moment());
      let dia = 0;
      console.log(fechaActual.getDate());
      if(fechaActual.getDate()<25)
      { dia = 2;}
      let fechaStandar = new Date(moment(new Date(fechaActual)).add(dia,'day'));
     
      copiaConceptos.forEach(concepto => {
        concepto.fecha_limite = moment(new Date(fechaStandar)).format('YYYY-MM-DD');
        fechaStandar = new Date(moment(new Date(fechaStandar)).add(1, 'month'));
        if(fechaStandar.getMonth()+1 !== 2) {
          fechaStandar = `${fechaStandar.getFullYear()}-${fechaStandar.getMonth()+1}-30 00:00:00`;
        } 
      });
    }
    setConceptosFinales(copiaConceptos);
  }

  useEffect(() => {
    actualizarFechas()
  }, [plan])

  return ( 
    <div className="ContenidoPlan mt-3">
      {mostarItems()}
    </div>
  );
}
 
export default ContenidoPlan;