import React from "react";

//Connect
import { connect } from "react-redux";

import "./TablaNotaDiscente.scss";
import MaterialTableDemo from "../../plugins/MaterialTableDemo";
import SpinnerLoading from "../../Spinners/SpinnerLoading";

const TablaNotaDiscente = (props) => {
  //State redux
  const { notasSesionesTabla } = props;

  const { loadingNotas } = props;

  return (
    <div className="tabla-nota">
      <SpinnerLoading loading={loadingNotas}>
        <MaterialTableDemo colums={colums} tabla={notasSesionesTabla} />
      </SpinnerLoading>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingNotas: state.reporte.loadingNotas,
  notasSesionesTabla: state.reporte.notasSesionesTabla,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TablaNotaDiscente);

const colums = [
  {
    text: "Sesión",
    key: "sesion",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 30,
  },
  {
    text: "Nombre de sesión",
    key: "nombresesion",
    align: "center",
    width: 500,
    sortable: true,
  },
  {
    text: "Fecha",
    key: "fecha",
    align: "center",
    className: "text-center",
    sortable: true,
  },
  {
    text: "Pts.",
    key: "puntos",
    align: "center",
    className: "tabla__td tabla__td__puntos",
    TrOnlyClassName: "tabla__th__puntos tabla__th",
    sortable: true,
  },
  {
    text: "Nota",
    key: "nota",
    align: "left",
    className: "tabla__td tabla__td__nota",
    TrOnlyClassName: "tabla__th__nota tabla__th",
    sortable: true,
  },
  {
    text: "Asistencia",
    key: "asistencia",
    align: "left",
    className: "tabla__td tabla__td__asistencia",
    TrOnlyClassName: "tabla__th__asistencia tabla__th",
    sortable: true,
  },
];
