import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import './ListaPropuestas.scss'

const ListaPropuestas = props => {
  
  const { propuestas,desabilitarBoton, guardarEditado, eliminarPropuesta } = props

  return (
    <div className="lista-propuestas mt-2">
      {propuestas.map((propuesta, index) => (
        <Propuesta
          key={propuesta.id_sugerencia_tutor}
          index={index}
          propuesta={propuesta}
          guardarEditado={guardarEditado}
          eliminarPropuesta={eliminarPropuesta}
          desabilitarBoton={desabilitarBoton}
        />
      ))}
    </div>
  );
};

export default ListaPropuestas;

const Propuesta = props => {
  
  const { propuesta,desabilitarBoton, index, guardarEditado, eliminarPropuesta } = props

  return (
    <div className="propuesta w-100">
      <Row className='px-2'>
        <Col md={8} className='texto-propuesta'>
          <span className='font-weight-bold'>{index + 1}.-</span> {propuesta.propuesta}
        </Col>
        <Col md={4} className='d-flex justify-content-end acciones-propuesta'>
          <Button
            type='button'
            disabled={desabilitarBoton}
            onClick={() => guardarEditado(propuesta)}
          >
            <FontAwesomeIcon icon={faEdit}/>
          </Button>
          <Button
            variant='danger'
            type='button'
            disabled={desabilitarBoton}
            onClick={() => eliminarPropuesta(propuesta.id_sugerencia_tutor)}
          >
            <FontAwesomeIcon icon={faTrashAlt}/>
          </Button>
        </Col>
      </Row>
      
    </div>
  )
}