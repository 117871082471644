import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Clasificacion = ({ nombre_clasificacion, id_estado }) => {
  const placement = 'top'
  return ( 
    <li className="Clasificacion list-unstyled my-2" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${placement}`}>
          <span> { nombre_clasificacion } </span>
        </Tooltip>
      }
    >
      <Button variant={id_estado==='1'? 'success' : 'danger'}>  <FontAwesomeIcon icon={faGraduationCap} /><span style={{fontWeight: 200}}>{id_estado==='1'? '1 punto': '--'}</span></Button>
    </OverlayTrigger>
    </li>
   );
}

Clasificacion.propTypes = {
  nombre_clasificacion: PropTypes.string.isRequired
}
 
export default Clasificacion;