import {
    LISTAR_TAREAS,
    LISTAR_TAREAS_ESTUDIANTES,
    INSERTAR_TAREAS,
    ELIMINAR_TAREAS
} from './types'

import authAxios from '../config/authAxios'

export const listarTareas = id => async dispatch => {
    const tareas = await authAxios.get(`/admin/se_mostrarTareasIdSesionAdmin/${id}`) 

    dispatch ({
        payload: tareas.data,
        type: LISTAR_TAREAS
    })

}

export const listarTareaEstudiantes = idTarea => async dispatch => {
    
    const tareasEstudiantes = await authAxios.get(`/admin/se_mostrarEstudiantesIdTarea/${idTarea}`)
    dispatch({
        payload: tareasEstudiantes.data,
        type: LISTAR_TAREAS_ESTUDIANTES
    })   
}

export const insertarTarea = dataTarea => async dispatch => {
    try {
        const respuesta = await authAxios.post(`/admin/subirTareasAdmin`, dataTarea)
        if(!respuesta.data.error) {
        dispatch({
            payload: respuesta.data,
            type: INSERTAR_TAREAS
        })
            return {mensaje: true, textMensaje: 'Tarea insertada correctamente'}
        }
        return {mensaje: false, textMensaje: respuesta.data.error}
        
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}

export const eliminarTareaAdmin = idTarea => async dispatch => {
    try {
        const respuesta = await authAxios.delete(`/admin/eliminarTareaEmitadaPorAdminId/${idTarea}`)
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: ELIMINAR_TAREAS
            })

            return{mensaje: true, textMensaje: 'Eliminado correctamente'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
    
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}