import React from 'react'
import Select from './Select';
import shortid from 'shortid'

const ListaProfesSelect = ({ array_docentes, quitarElementoLista}) => {

    return (
        <div
        className="border container d-flex flex-wrap p-2"
        style={{ minHeight: "100px" }}
        >
            {array_docentes.length > 0 ?
                array_docentes.map(docente => (
                    <Select
                        docente={docente}
                        key={shortid.generate()}
                        quitarElementoLista={quitarElementoLista}
                    />
                ))
                :null}
    </div> );
}
 
export default ListaProfesSelect;