import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { eliminarTemaDocente, guardarOrdenTemasDocente } from '../../actions/profesoresActions';

const withEditarTemasSeleccionados = WrappedComponent => props => {
  
  const [ docente , idDocente] = useGetDocente();
  const dispatch = useDispatch();

  const handleEliminarTema = idTemaDocente => {
    dispatch( eliminarTemaDocente(idTemaDocente, idDocente));
  }

  const handleGuardarNuevoOrden = temas => {
    dispatch( guardarOrdenTemasDocente(idDocente, temas) )
  }

  return ( 
    <WrappedComponent
      {...props}
      temas={docente.temas}
      handleEliminarTema={handleEliminarTema}
      handleGuardarNuevoOrden={handleGuardarNuevoOrden}
    />
   );
}
 
export default withEditarTemasSeleccionados;