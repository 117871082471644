import {
  LISTAR_MENSAJES,
  MANDAR_MENSAJE_R_PAPELERA,
  LISTAR_MENSAJES_ENVIADOS,
  ENVIAR_MENSAJE,
  MOSTRAR_MENSAJE,
  LISTAR_CONTACTOS,
  MANDAR_MENSAJE_E_PAPELERA,
  RESTAURAR_MENSAJE,
  ELIMINAR_MENSAJE,
  LIMPIAR_MENSAJE,
  LISTAR_PAPELERA,
  ACTUALIZAR_HILO
} from "./types";


import authAxios from "../config/authAxios";

export const listarBandeja = idUsuario => async dispatch => {
  let bandeja = await authAxios.get(
    `/admin/mostrarBandejaEntra/${idUsuario}`
  );

  dispatch({
    payload: bandeja.data,
    type: LISTAR_MENSAJES
  });
};

export const listarMensajesEnviados = idUsuario => async dispatch => {
  let mensajes = await authAxios.get(
    `/admin/mostrarEnviados/${idUsuario}`
  );


  dispatch({
    payload: mensajes.data,
    type: LISTAR_MENSAJES_ENVIADOS
  });
};

export const mostrarMensaje = (idMensaje, idUsuario) => async dispatch => {
  try {
    let mensaje = await authAxios.get(
      `/admin/mostrarMensajeId/${idMensaje}/${idUsuario}`
    );

    dispatch({
      payload: mensaje.data,
      type: MOSTRAR_MENSAJE
    });

    dispatch({
      payload: "<p></p><p>------------------</p><p>Emisor: "+ mensaje.data.datosMensaje.nombre_emisor+" "+ mensaje.data.datosMensaje.apellido_emisor +"</p> En respuesta de:"+mensaje.data.datosMensaje.mensaje,
      type: ACTUALIZAR_HILO
    });
  } catch (error) {
    console.log(error)
  }
  
};
export const listarPapelera = idUsuario => async dispatch => {
  
  try {
    let papelera = await authAxios.get(`/admin/mostrarPapeleraIdUsuario/${idUsuario}`)

  dispatch({
    payload: papelera.data.datos,
    type: LISTAR_PAPELERA
  })
  } catch (error) {
    console.log(error)
  }
  
}
export const enviarMensaje = (file, config) => async dispatch => {

  try {
    let respuesta = await authAxios.post(`/admin/insertarMensaje`, file, config);
    //console.log(respuesta)
    let estado = 200;
    if (respuesta.status === 200) {
      estado = 200;

      dispatch({
        payload: {
          mensajes: respuesta.data,
          estado
        },
        type: ENVIAR_MENSAJE
      })

      return {mensaje: true, textMensaje: 'Mensaje enviado'}
    }

    return {mensaje: false, textMensaje: 'Error al enviar mensaje'}
  } catch (error) {
    console.log(error);
    return {mensaje: false, textMensaje: error}
  }
};

export const listarContactos = (idUsuario, nivelUsuario) => async dispatch => {
  let contactos = await authAxios.get(`/admin/mostrarListaDeContactoMensaje/15/1`)//id Usuario, nivel Usuario

  dispatch({
    payload: contactos.data,
    type: LISTAR_CONTACTOS
  })
} 

export const mandarMensajeBandejaPapelera = (idUsuario, mensajes) => async dispatch => {

  let dataMensajeCambio = {
    id_usuario: idUsuario,
    mensajesParaPapelera: mensajes
  }

  let respuesta = await authAxios.put(`/admin/mandarPapeleraIdMensajeIdUsuarioBandeja`, dataMensajeCambio)

  if (respuesta.data.mensaje === 'SE CAMBIO EL ESTADO DE MENSAJE'
    || respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR'
    || respuesta.data.mensaje === 'SE MOSTRO CON EXITO LA PAPELERA') {

    dispatch({
      payload:{
        papeleraMensaje: respuesta.data.papelera,
        bandejaMensaje: respuesta.data.bandeja
      },
      type: MANDAR_MENSAJE_R_PAPELERA
    })

    return { mensaje: true }
  }

  return { mensaje: false }

}


export const mandarMensajeEnviadosPapelera = (idUsuario, mensajes) => async dispatch => {
  let dataMensajeCambio = {
    id_usuario: idUsuario,
    mensajesParaPapelera: mensajes
  }

  let respuesta = await authAxios.put(`/admin/mandarPapeleraIdMensajeIdUsuarioEnviados`, dataMensajeCambio)
  //console.log(respuesta.data)

  if (
    respuesta.data.mensaje === "SE MOSTRO CON EXITO LA PAPELERA" ||
    respuesta.data.mensaje === "NO HAY FILAS PARA MOSTRAR"
  ) {
    dispatch({
      payload: {
        papeleraMensaje: respuesta.data.papelera,
        enviadosMensaje: respuesta.data.enviados
      },
      type: MANDAR_MENSAJE_E_PAPELERA
    });

    return { mensaje: true };
  }

  return {mensaje: false}
}


export const retaurarMensaje = (idUsuario, mensajes) => async dispatch => {

  const dataMensajes = {
    id_usuario: idUsuario,
    restaurados: mensajes
  }

  let respuesta = await authAxios.put(`/admin/restaurarMensaje`, dataMensajes)
  
  if (respuesta.data.mensaje === 'SE MOSTRO CON EXITO LA PAPELERA'
    || respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR') {
    dispatch({
      payload: respuesta.data.datos,
      type: RESTAURAR_MENSAJE
    })

    return {mensaje: true}
  }

  return {mensaje: false}
}

export const eliminarMensaje = (dataMensajesEliminados) => async dispatch => {

  let respuesta = await authAxios.put(`/admin/eliminarMensajeId`, dataMensajesEliminados);
  console.log(dataMensajesEliminados)
  console.log(respuesta.data)
  if (
    respuesta.data.mensaje === "SE MOSTRO CON EXITO LA PAPELERA" ||
    respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR'
  ) {
    dispatch({
      payload: respuesta.data.datos,
      type: ELIMINAR_MENSAJE
    });
    return { mensaje: true };
  }

  return{mensaje: false}
} 

export const limpiarMensaje = () => dispatch => {
  dispatch({
    payload: [],
    type: LIMPIAR_MENSAJE
  })
}