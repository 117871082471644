import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarProyectos } from '../actions/cursosActions';

const useSelectProyecto = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const proyectos = useSelector(state => state.cursos.proyectos)

  const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const proyectoSelect = proyectos.find(proyecto => proyecto.id_proyecto === parseInt(initialSelect)); 
      if(proyectoSelect) {
        setProyectoSeleccionado(proyectoSelect);
      }
    }
  }

  useEffect(() => {
    if(!proyectos.length) {
      dispatch(listarProyectos())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [proyectos])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!proyectos.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={proyecto => setProyectoSeleccionado(proyecto)}
        value={proyectoSeleccionado}
        getOptionValue={proyectos => proyectos.id_proyecto}
        getOptionLabel={proyectos => proyectos.nombre_proyecto}
        options={proyectos}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [proyectoSeleccionado, SimpleSelect, setProyectoSeleccionado]

}
 
export default useSelectProyecto;