import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from '../view/pages/login/LoginPage';
import DashboardRoutes from './DashboardRoutes';
import PrivateRoute from './PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { validarInicioSesion } from '../actions/userActions';
import { getJWT } from '../helpers/functions';

const AppRouter = () => {
  const { isLogin } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  useEffect(() => {
    if(getJWT() && isLogin !== true) {
      dispatch(validarInicioSesion());
    }
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute
          path='/'
          component={DashboardRoutes}
        />
        <Redirect to='/login' />
      </Switch>
    </Router>
  );
}
 
export default AppRouter;