import React from 'react';
import './EditarClasificacion.scss';
import RangoProfesionalSeleccionadowithRedux from '../../../../../containers/DocentesContainers/RangoProfesionalSeleccionadowithRedux';
import ListaRangoProfesionalwithRedux from '../../../../../containers/DocentesContainers/ListaRangoProfesionalwithRedux';
import { Modal } from 'react-bootstrap';

const EditarClasificacion = () => {
  return ( 
    <div className="EditarClasificacion">
      <Modal.Body>
        <ListaRangoProfesionalwithRedux />
        <RangoProfesionalSeleccionadowithRedux />   
      </Modal.Body> 
    </div>
   );
}
 
export default EditarClasificacion;