import React, { useState, useEffect } from "react";

import "./NuevoTitulo.scss";
import { Form, Button, Modal } from "react-bootstrap";

const NuevoTitulo = (props) => {
  const { encabezado, guardarEncabezado } = props;

  const [infoEncabezado, setInfoEncabezado] = useState(initialSatate());

  const { titulo, descripcion } = infoEncabezado;

  const handleChange = (e) => {
    setInfoEncabezado({
      ...infoEncabezado,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    guardarEncabezado(infoEncabezado);
  };

  useEffect(() => {
    setInfoEncabezado(encabezado)
  }, [encabezado])

  return (
    <div className="nuevo-titulo">
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nuevo titulo"
              name="titulo"
              onChange={handleChange}
              value={titulo}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="descripcion"
              value={descripcion}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant='info'>Guardar</Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default NuevoTitulo;

const initialSatate = () => ({
  titulo: "",
  descripcion: "",
});
