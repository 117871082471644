import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../../../../actions/modalActions';

const EditarCv = ({ resumenCv, handleSubmit }) => {

  const [ cv, setCv ] = useState('')
  const dispatch = useDispatch();

  const handleSubmitForm = e => {
    e.preventDefault();
    handleSubmit(cv)
  }

  const handleModal = () => {
    dispatch(updateStateModalCustom());
  }

  useEffect(() => {
    if (resumenCv) {
      setCv(resumenCv)
    }
  }, [resumenCv])

  return ( 
    <div className="EditarCv">
      <div className='EditarCv__content'>
        <Form onSubmit={handleSubmitForm}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Resumen de cv:</Form.Label>
            <CKEditor
              style={{height: "200px"}}
              editor={ClassicEditor}
              data={resumenCv}
              onInit={editor => {
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCv(data)
                // console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                // console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor);
              }}
            />
          </Form.Group>
          </Modal.Body>
          <Modal.Footer className='text-right'>
            <Button
              variant='dark'
              className='btn-icon'
              onClick={handleModal}
            >
              <FontAwesomeIcon icon={faTrash} />
              Cancelar
            </Button>
            <Button
              variant='info'
              className='text-white btn-icon'
              type='submit'
            >
              <FontAwesomeIcon icon={faSave} />
              Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </div>
    </div>
   );
}
 
export default EditarCv;