import React from 'react';
import useGetCapacitacionControl from '../../../../../../hooks/useGetCapacitacionControl';
import Indicador from '../../../../../../containers/ControlCapacitacionesComtainers/Indicador';

const ListaIndicadores = () => {

  const [{ activo: {proceso} }] = useGetCapacitacionControl();

  return ( 
    <div className="ListaIndicadores my-3">
      {[...proceso].map((item, index) => (
        <Indicador key={item.id} index={index} {...item} />
      ))}
    </div>
   );
}
 
export default ListaIndicadores;