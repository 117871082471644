import React, { useEffect, useState } from 'react';
import Infolabels from '../../plugins/InfoLabels';

import './FormEventosTutor.scss'; 
import SimpleSelect from '../../plugins/SimpleSelect';
import MultiSelect from '../../plugins/MultiSelect';
import { Button } from 'react-bootstrap';

//redux
import { connect } from 'react-redux';
import { obtenerTutoresSelect, guardarAsignación } from '../../../../actions/tutoresActions';
import { listarProyectosSelect } from '../../../../actions/cursosActions';

const FormEventosTutor = props => {

  //state redux
  const { selectTutores, selectProyectos } = props
  //Funciones redux
  const { obtenerTutoresSelect,guardarAsignación, listarProyectosSelect } = props

  const [tutorAsignar, setTutorAsignar] = useState({})
  const [eventosAsignar, setEventosAsignar] = useState([])

  const onSubmit = e => {
    e.preventDefault()

    const dataAsignacion = {
      id_tutor: tutorAsignar.value,
      proyectos: eventosAsignar
    }

    guardarAsignación(dataAsignacion)
  }

  useEffect(() => {
    obtenerTutoresSelect()
    listarProyectosSelect()
  }, [])

  return ( 
    <form onSubmit={onSubmit} className='form-content'>
      <Infolabels
        info={'1.- Seleccione un tutor a asignar'}
      />
      <div className="form-select">
        <SimpleSelect
          options={selectTutores}
          getSelection={setTutorAsignar}
        />
      </div>
      <Infolabels
        info={'2.- Seleccione un evento a asignar'}
      />
       <div className="form-select">
        <MultiSelect
          options={selectProyectos}
          guardarSeleccion={setEventosAsignar}
          value={eventosAsignar}
        />
      </div>
      <Infolabels
        info={'3.- Verifique los detalles del evento'}
      />
      <div className="detalles-eventos bg-white p-3">
        {eventosAsignar.length > 0 ? (
          eventosAsignar.map((evento, index) => (
            <Evento
              key={evento.value}
              evento={evento}
              index={index}
            />
          ))
        ) : (
          'No hay eventos'
        ) }
      </div>
      

      <Button type='submit' variant='primary' className='btn-block'>
        Guardar
      </Button>
    </form>
   );
}

const mapStateToProps = state => ({
  selectTutores: state.tutores.selectTutores,
  selectProyectos: state.cursos.select_proyectos
})

const mapDispatchtoProps = {
  obtenerTutoresSelect,
  listarProyectosSelect,
  guardarAsignación
}
 
export default connect(mapStateToProps, mapDispatchtoProps)(FormEventosTutor);

const Evento = props => {

  const { evento, index } = props

  return (
    <div className='evento mb-2'>
      <span>{index +1}.-</span> {evento.nombre_proyecto}
    </div>
  )
}