import React, { useEffect } from "react";
import CardEncuestas from "../CardEncuestas";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import { nuevaEncuesta } from '../../../actions/encuestasAction';

import './ListaEncuestas.scss';

const ListaEncuestas = (props) => {

  const history = useHistory()

  const {
    updateShowModal, 
    encuestas,
    encuesta,
    encuestaCreada,
    idEventoTutor, 
    idEvento,
    guardarEncuesta,
    nuevaEncuesta
   } = props;

  const onNuevaEncuesta = () => {

    const data = {
      id_tutor_sesion: encuestas.id_tutor_sesion,
      id_sesion: encuestas.id_sesion
    }

    nuevaEncuesta(data)
  }

  const redirigirNuevaEncuesta = () => {
    if (encuestaCreada) {
      history.push(`/tutor/eventos/${idEvento}/${idEventoTutor}/encuestas/${encuesta.id_formulario}`)
    }
  }

  useEffect(() => {
    redirigirNuevaEncuesta()
  }, [encuestaCreada])

  return (
    <Container fluid className='lista-encuestas'>
      <Row>
        <Col lg={3} md={4}>
          <Button
            variant=''
            onClick={() => onNuevaEncuesta()}
            className="btn bg-white text-black-50 border btn-nuevo-cuestionario text-white"
          >
            <Image src='/images/icon-new-form-normal.png' alt='icono'/>
            <br/>
            Nuevo Formulario
          </Button>
        </Col>
        {encuestas.formularios.map((encuesta) => (
          <Col lg={3} md={4} className="mb-3" key={encuesta.id_formulario}>
            <CardEncuestas
              updateShowModal={updateShowModal}
              encuesta={encuesta}
              guardarEncuesta={guardarEncuesta}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  encuestas: state.encuestas.encuestas,
  encuestaCreada: state.encuestas.encuestaCreada,
  encuesta: state.encuestas.encuesta
});

const mapDispatchToProps = {
  nuevaEncuesta
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaEncuestas);
