import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';


//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';
import EditaCvDocenteContainer from '../../containers/DocentesContainers/EditaCvDocenteContainer';

const withListarResumenProfesional = WrappedComponent => props => {
  
  const [docente] = useGetDocente();
  const dispatch = useDispatch();

  //Click en editar
  const handleEditar = () => {
    dispatch( updateStateModalCustom(<EditaCvDocenteContainer />, 'Editar perfíl profesional', 'modal-w40') )
  }

  return ( 
    <WrappedComponent
      {...props}
      resumenCv={docente.descripcion}
      handleEditar={handleEditar}
    />
   );
}
 
export default withListarResumenProfesional;