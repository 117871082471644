  import { 
  OPEN_LIGHTBOX,
  CLOSE_LIGHTBOX,
  SET_SOURCE_LIGHTBOX,
  NEXT_SLIDE,
  PREV_SLIDE
 } from '../actions/types';

 const initialState = {
   toggler: false,
   sources: [],
   slide: null
 }

 export default function(state = initialState, action) {
   switch (action.type) {
    case OPEN_LIGHTBOX:
      return {
        ...state,
        toggler: true
      }
    case CLOSE_LIGHTBOX:
      return {
        ...state,
        toggler: false
      }
    case SET_SOURCE_LIGHTBOX:
      return {
        ...state,
        sources: action.payload.sources,
        slide: action.payload.index
      }
    case PREV_SLIDE:
      return {
        ...state,
        slide: (state.slide + state.sources - 1) % state.sources.length
      }
    case NEXT_SLIDE:
      return {
        ...state,
        slide: (state.slide + 1) % state.sources.length
      }
    default:
      return state;
   }
 }