import React from 'react';
import EditarUsuarioSesionContainer from '../../../../../../containers/DocentesContainers/EditarUsuarioSesionContainer';
import EditarPasswordContainer from '../../../../../../containers/DocentesContainers/EditarPasswordContainer';
import { Modal } from 'react-bootstrap';

const EditarDatosSesionDocente = () => {
  return ( 
    <div className="EditarDatosSesion">
      <Modal.Body>
        <EditarUsuarioSesionContainer />
        <EditarPasswordContainer />
      </Modal.Body>
    </div>
   );
}
 
export default EditarDatosSesionDocente;