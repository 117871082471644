import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../app/Layout";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import Breadcrumb from "../General/Breadcrumb";
import HeaderContent from "../General/HeaderContent";
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import { Button } from "react-bootstrap";

//REDUX
import { useDispatch } from "react-redux";
import useModalBasic from "../../../hooks/useModalBasic";
import FormularioModulo from "../../components/ControlModulos/FormularioModulo/FormularioModulo";
import Fatal from "../../components/MessageAlert/Fatal";
import useGetModulos from "../../../hooks/useGetModulos";
import { eliminarModulo } from "../../../actions/modulosActions";
import ModalBasic from "../../components/Modals/ModalBasic";

const ModulosProyecto = () => {
  const { modulos, loading, error } = useGetModulos();

  const mostrarContenido = () => {
    if (loading) {
      return <SpinnerLoading loading={true} />;
    }
    if (error) {
      return <Fatal message={error} />;
    }
    return <ModulosProyectoBody
      modulos={modulos}
    />
  };

  return (
    <Layout>
      <HeaderContent title={"Sub-Productos"} icon={"menu_book"} />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/modulos", nombre: "Sub-Productos" },
        ]}
      />
      {mostrarContenido()}
    </Layout>
  );
};
export default ModulosProyecto;

const ModulosProyectoBody = ({ modulos }) => {
  
  const [datosModuloEditar, setDatosModuloEditar] = useState(null);
  const dispatch = useDispatch();
  const { onCloseModal, onShowModal, showModal, setShowModal } = useModalBasic(false);
 // console.log(showModal);
  const botonesAcciones = (record) => {
    return (
      <>
        <Button
          variant="danger"
          size="xs"
          title="Eliminar"
          onClick={() => eliminarCurso(record.id_modulo)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          variant="info"
          size="xs"
          title="Editar"
          onClick={() => {
            onShowModal();
            setDatosModuloEditar(record);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </>
    );
  };

  const eliminarCurso = (id_modulo) => {
    dispatch(eliminarModulo(id_modulo));
  };

  return (
    <div className="ModulosProyecto__body">
      <ModalBasic
        title={datosModuloEditar ? "Editar Sub-Producto" : "Nuevo Sub-Producto"}
        show={showModal}
        updateShowModal={onCloseModal}
      >
        <FormularioModulo
          datosModulo={datosModuloEditar}
          onCloseModal={onCloseModal}
        />
      </ModalBasic>
      <div className="container-fluid px-0 content-actions my-3">
        <div className="text-right">
          <Button
            className="btn btn-primary btn-icon"
            onClick={() => {
              onShowModal();
              setDatosModuloEditar(null);
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> Nuevo Sub-Producto
          </Button>
        </div>
      </div>

      <div className="p-0 mb-5 bg-white">
        <div className="continer-fluid border-bottom-0 border"></div>
        <div className="box box-primary">
          <div className="box-header with-border">
            <h5 style={{ color: "#434343" }}>
              <i className="fas fa-list pr-2"></i>Lista de Sub-Productos
            </h5>
          </div>
          <div className="box-body">
            <MaterialTableDemo
              colums={colums(botonesAcciones)}
              tabla={modulos}
              addConfig={{key_column: 'id_modulo'}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**====================== TABLA =========== */

const colums = (botonesAcciones) => [
  {
    text: "#",
    key: "index",
    align: "center",
    width: 30,
    className: "text-center",
    cell: (_, index) => index + 1,
  },
  {
    text: "Nombre",
    key: "nombre_modulo",
    align: "center",
    sortable: true,
  },
  {
    text: "Fecha inicio",
    key: "fecha_inicio_modulo",
    align: "center",
    sortable: true,
    className: "text-center",
  },
  {
    text: "Fecha fin",
    key: "fecha_fin_modulo",
    align: "center",
    sortable: true,
    className: "text-center",
  },
  {
    text: "Sesiones",
    key: "cantidad_sesion ",
    width: 50,
    className: "text-center",
    cell: ({ id_modulo, cantidad_sesion  }) => (
      <Link
        to={`/cursos/${id_modulo}/sesiones`}
        className="btn btn-light btn-xs"
      >
        {cantidad_sesion } <FontAwesomeIcon className="ml-1" icon={faEye} />
      </Link>
    ),
  },
  {
    text: "Docentes",
    key: "cantidad_docente",
    width: 50,
    className: "text-center",
    cell: ({ id_modulo, cantidad_docente }) => (
      <Link
        to={`/cursos/${id_modulo}/asignar/docente`}
        className="btn btn-light btn-xs"
      >
        {cantidad_docente} <FontAwesomeIcon className="ml-1" icon={faEye} />
      </Link>
    ),
  },
  {
    text: "Acciones",
    key: "acciones",
    align: "center",
    width: 90,
    cell: (record) => botonesAcciones(record),
  },
];
