import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import TablaMatricula from './TablaMatricula';

const ResultadoReporteMatriculas = ({ matriculas, datosBusqueda }) => {

  const { idEvento , estado } = datosBusqueda;

  return (
    <div className="ResultadoReporteMatriculas mt-3">
      <div className="botones-acciones mt-3">
        <Button
          href={`${process.env.REACT_APP_API_URL}/admin/excel/reporte/matriculas?idEvento=${idEvento}&estado=${estado}`}
          variant='light'
          className='btn-icon'
        >
          <FontAwesomeIcon icon={faFileExcel} />
          Descargar .xlsx
        </Button>
        <Button
          href={`${process.env.REACT_APP_API_URL}/admin/pdf/reporte/matriculas?idEvento=${idEvento}&estado=${estado}`}
          variant='light'
          className='btn-icon'
        >
          <FontAwesomeIcon icon={faFilePdf} />
          Descargar .pdf
        </Button>
      </div>
       <div className="box box-primary mt-3">
          <div className="box-body">
            <TablaMatricula
              matriculas={matriculas}
              idEvento = {idEvento}
            />
          </div>
        </div>
    </div>
  );
}

export default ResultadoReporteMatriculas;