import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function MultiSelect({ options,value = [], guardarSeleccion}) {

    const handleChange = (item) => {
        guardarSeleccion(item || []);
    }   


    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={value}
            isMulti
            onChange={item => handleChange(item)}
            options={options}
        />
    );
}