import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import "./ReporteNaranja.scss";
import { obtenerReporteNaranja } from "../../../actions/controlCapacitacionesActions"
//Reduxt
import {useDispatch } from "react-redux";
import SelectVariable from "./Componentes/SelectVariable";
import { avisoCorrecto, avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import useSelectAnios from '../../../hooks/useMultiSelectAnios';
import ResultadoReporteNaranja from './contenidoReporte'
const ReporteNaranja = () => {
  //State
  const dispatch = useDispatch();
  const dt = new Date();
  const initialState={
    anio:dt.getFullYear(),
    mes:dt.getMonth()+1,
  }

  const[loading, setLoading] = useState(false)
  const[consulta, setConsulta] = useState(initialState);
  const[informacion, setInformacion] = useState(null)
  const {mes,anio} = consulta;
 // const [seleccionAnio, SelectAnios] = useSelectAnios(null);
  const [datoTitulo,setDatoTitulo] = useState(null)
  const anios = [
    {
      id:"2019",
      value:"2019"
    },
    {
      id:"2020",
      value:"2020"
    },
    {
      id:"2021",
      value:"2021"
    },
    {
      id:"2022",
      value:"2022"
    },
    {
      id:"2023",
      value:"2023"
    },
    {
      id:"2024",
      value:"2024"
    }
    ,
    {
      id:"2025",
      value:"2025"
    }
    ,
    {
      id:"2026",
      value:"2026"
    }
    ,
    {
      id:"2027",
      value:"2027"
    }
]
  const meses = [
          {
            id:1,
            value:"Enero"
          },
          {
            id:2,
            value:"Febrero"
          },
          {
            id:3,
            value:"Marzo"
          },
          {
            id:4,
            value:"Abril"
          }
          ,
          {
            id:5,
            value:"Mayo"
          }
          ,
          {
            id:6,
            value:"Junio"
          }
          ,
          {
            id:7,
            value:"Julio"
          }
          ,
          {
            id:8,
            value:"Agosto"
          }
          ,
          {
            id:9,
            value:"Septiembre"
          }
          ,
          {
            id:10,
            value:"Octubre"
          }
          ,
          {
            id:11,
            value:"Noviembre"
          }
          ,
          {
            id:12,
            value:"Diciembre"
          }
  ]
  
  const handleChange = (e) =>{
    setConsulta({
      ...consulta,
        [e.target.name]:e.target.value
    })
  }

useEffect(()=>{
  traerReporte()
},[])

 const traerReporte = () => {
  // console.log(seleccionAnio)
    if(anio!=='' && mes!=='')
    { 
      
        setLoading(true);
        const fetchData = async () =>{
        const respuesta = await (dispatch(obtenerReporteNaranja(consulta)))
        setInformacion(respuesta.informacion)
        setDatoTitulo(mes)
        setLoading(false)
        }
        fetchData();
    }
    else
    {
        avisoError("Debe seleccionar periodo y mes.")
    }
  }

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte Naranja" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Reportes Académica" },
                { url: "", nombre: "Reporte Naranja" },
              ]}
            />
          </div>
          <div className="box p-3">
            <div className="row">
            
                <div className="col-4">
                <SelectVariable name="anio" datos={anios} value={anio} primerSelect="Seleccione un año" handleChange={handleChange} />
                </div>
                <div className="col-4">
                <SelectVariable name="mes" datos={meses} value={mes} primerSelect="Seleccione un mes" handleChange={handleChange} />
                </div>
                <div className="col-4">
                <button className="btn btn-primary" onClick = {traerReporte} >Consultar</button>
                </div>
            </div>
          </div>
          <div className="Trabajadores__body">
           <SpinnerLoading loading={loading}>
            { informacion ? <ResultadoReporteNaranja resultado={informacion} /> : '' }
            </SpinnerLoading>
      
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteNaranja;