import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import shortid from "shortid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { useEffect } from "react";
import { avisoCorrecto, avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import ListarOpciones  from "./ListarOpciones"
import { useDispatch } from "react-redux";
const PurpleSwitch = withStyles({
  switchBase: {
    color: blue[700],
    "&$checked": {
      color: blue[800],
    },
    "&$checked + $track": {
      backgroundColor: blue[800],
    },
  },
  checked: {},
  track: {},
})(Switch);

const NuevaPregunta = (props) => {

const {guardarPregunta,idExamen, updateShowModal} = props;

const initialState = () => ({
        id_examen:idExamen,
        pregunta: "",
        puntaje:"",
        descripcion: "",
        explicacion_correcta:"",
        opciones:[]
    });
const initialStateOpcion = () => ({
       id_opcion:"",
       opcion_text: "",
       respuesta_correcta:false
    });
const dispatch=useDispatch();
const [infoPregunta, setInfoPregunta] = useState(initialState());
const [opcion, setOpcion] = useState(initialStateOpcion())
const {
    pregunta,
    puntaje,
    descripcion,
    explicacion_correcta,
    opciones
  } = infoPregunta;
const {opcion_text, respuesta_correcta} = opcion;
  const toggleChecked = () => {
    
    setOpcion({
        ...opcion,
        respuesta_correcta: !respuesta_correcta,
      });
  };

  const handleChange = (e) => {
    setInfoPregunta({
      ...infoPregunta,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeOpcion = (e) => {
    setOpcion({
      ...opcion,
      [e.target.name]: e.target.value,
    });
  };

  const agregarOpcion = (op) =>{
      if(op.opcion_text!=="")
        {  op.id_opcion = new Date().getTime();
            const nuevoArreglo = [...infoPregunta.opciones, op];
            setInfoPregunta({
                ...infoPregunta,
                opciones: nuevoArreglo,
            });
            setOpcion(initialStateOpcion())
            avisoCorrecto("Opción Agregada")
        }
        else
        {
            avisoError("Debe llenar el campo opción")
        }
  }

  const eliminarOpcion = id => {
    const copiaOpciones = [...infoPregunta.opciones];
    const arregloActualizado = copiaOpciones.filter(opcion => opcion.id_opcion !== id);
    setInfoPregunta({
        ...infoPregunta,
        opciones: arregloActualizado,
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(guardarPregunta(infoPregunta));
    setInfoPregunta(initialState());
    updateShowModal(false);
  };

  useEffect(()=>{
   // console.log(infoPregunta.opciones)
  },[infoPregunta.opciones])

  return (
    <>
    
      <Form onSubmit={onSubmit}>
        <Modal.Body>
    <div className="nueva-pregunta">
        <Form.Group>
          <Form.Label>Pregunta</Form.Label>
          <Form.Control
            required
            name="pregunta"
            type="text"
            value={pregunta}
            onChange={handleChange}
            placeholder="Tu pregunta"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Descripción de la pregunta</Form.Label>
          <Form.Control
            as='textarea'
            name='descripcion'
            value={descripcion}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Explicacion de Respuesta Correcta</Form.Label>
          <Form.Control
            as='textarea'
            name='explicacion_correcta'
            value={explicacion_correcta}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Puntaje</Form.Label>
          <Form.Control
            required
            type='number'
            name='puntaje'
            value={puntaje}
            onChange={handleChange}
          />
        </Form.Group>
    </div>
    <div className="">
        <Form.Group>
          <Form.Label>Opción</Form.Label>
          <Form.Control
            name="opcion_text"
            value={opcion_text}
            type="text"
            onChange={handleChangeOpcion}
            placeholder="Tu opción"
          />
        </Form.Group>
        <Form.Group>
          <FormControlLabel
            control={
              <PurpleSwitch
                size="small"
                name="respuesta_correcta"
                checked={respuesta_correcta}
                onChange={toggleChecked}
              />
            }
            label="Respuesta Correcta"
          />
        </Form.Group>
        <div>
            <ListarOpciones opciones={opciones} eliminarOpcion={eliminarOpcion} />
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='success'  onClick={() => {
                agregarOpcion(opcion)
                }} >Agregar Opción</Button>
            <Button variant='info' type="submit">Guardar Pregunta</Button>
        </Modal.Footer>
      </Form>
  
    </>
  );
};

export default NuevaPregunta;


