import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editarProveedor, nuevoProveedor } from '../../../actions/empresaAction';
import Editor from '../PopUps/EditorPopUp';

const FormularioProveedor = ({registro=null}) => {
    const {usuario} = useSelector(state=>state.usuario)
    const style = {
        boton: {
            background: "#28314A",
            color: "#fff"
        },
        buttonText: {
            fontSize: "13px",
            background: "#28314A",
            color: '#fff'
        },
        textArchivo: {
            fontSize: "12px",
            fontWeight: "600",
            color: "#aaa"
        }
      }
    
    const initialValues = {
        id_proveedor:registro?registro.id_proveedor:null,
        ruc:registro?registro.ruc:'',
        nombre_rs:registro?registro.nombre_rs:'',
        direccion:registro?registro.direccion:'',
        nombre_contacto:registro?registro.nombre_contacto:'',
        numero_contacto:registro?registro.numero_contacto:'',
        telefono_fijo:registro?registro.telefono_fijo:'',
        correo:registro?registro.correo:'',
        area:registro?registro.area:'',
        productos:registro?registro.productos:'',
        catalogo:registro?registro.catalogo:'',
        id_usuario:usuario.id_usuario
      }
    const [datosEmpleo, setDatosEmpleo] = useState(initialValues);
    const dispatch = useDispatch();
    const handleChange= (event) => {
            const value = event.target.value;
            setDatosEmpleo({
            ...datosEmpleo,
            [event.target.name]: value
            });
        
    }
    const setProductos = (a) => {
        const value = a;
        //console.log(value);
        setDatosEmpleo({
            ...datosEmpleo,
            productos: value
        });
     }
    
     const handleSubmit = (e) =>
     {
      e.preventDefault()
      if(!registro)
       dispatch(nuevoProveedor(datosEmpleo))
       else
       dispatch(editarProveedor(datosEmpleo))
     }

    return (
       
        <form id="formNuevo2" className="px-md-5 py-3" onSubmit={handleSubmit} >

        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="nombre_puesto" className="mb-0">
              RUC:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="ruc"
              onChange={handleChange}
              value={datosEmpleo.ruc}
              type="text"
              className="form-control"
              id="ruc"
              required
            />
          </div>
        
        </div>    
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="nombre_puesto" className="mb-0">
              Nombre/Razón Social:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="nombre_rs"
              onChange={handleChange}
              value={datosEmpleo.nombre_rs}
              type="text"
              className="form-control"
              id="nombre_rs"
              required
            />
          </div>
        
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="empresa" className="mb-0">
              Dirección:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="direccion"
              onChange={handleChange}
              value={datosEmpleo.direccion}
              type="text"
              className="form-control"
              id="direccion"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
            Nombre Contacto:
            </label>
          </div>
          <div className="col-md-9">
         { 
           /* <select   id="categoria"  className="form-control" name="categoria" value={datosEmpleo.categoria} onChange={handleChange} >
              <option value="">Seleccione una categoría</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Legal">Legal</option>
              <option value="Recursos Humanos">Recursos Humanos</option>
            </select>*/
}
            <input
              name="nombre_contacto"
              onChange={handleChange}
              value={datosEmpleo.nombre_contacto}
              type="text"
              className="form-control"
              id="nombre_contacto"
              required
            />
          </div>
  
        </div>

        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
            Número Contacto:
            </label>
          </div>
          <div className="col-md-9">
         { 
           /* <select   id="categoria"  className="form-control" name="categoria" value={datosEmpleo.categoria} onChange={handleChange} >
              <option value="">Seleccione una categoría</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Legal">Legal</option>
              <option value="Recursos Humanos">Recursos Humanos</option>
            </select>*/
}
            <input
              name="numero_contacto"
              onChange={handleChange}
              value={datosEmpleo.numero_contacto}
              type="text"
              className="form-control"
              id="numero_contacto"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
            Número Fijo:
            </label>
          </div>
          <div className="col-md-9">
         { 
           /* <select   id="categoria"  className="form-control" name="categoria" value={datosEmpleo.categoria} onChange={handleChange} >
              <option value="">Seleccione una categoría</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Legal">Legal</option>
              <option value="Recursos Humanos">Recursos Humanos</option>
            </select>*/
}
            <input
              name="telefono_fijo"
              onChange={handleChange}
              value={datosEmpleo.telefono_fijo}
              type="text"
              className="form-control"
              id="telefono_fijo"
              
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="categoria" className="mb-0">
            Correo:
            </label>
          </div>
          <div className="col-md-9">
         { 
           /* <select   id="categoria"  className="form-control" name="categoria" value={datosEmpleo.categoria} onChange={handleChange} >
              <option value="">Seleccione una categoría</option>
              <option value="Contabilidad">Contabilidad</option>
              <option value="Legal">Legal</option>
              <option value="Recursos Humanos">Recursos Humanos</option>
            </select>*/
}
            <input
              name="correo"
              onChange={handleChange}
              value={datosEmpleo.correo}
              type="text"
              className="form-control"
              id="correo"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="tiempo_empleo" className="mb-0">
              Área:
            </label>
          </div>
          <div className="col-md-9">
  
            <select id="area"  className="form-control" name="area" value={datosEmpleo.area} onChange={handleChange} required >
              <option value="">Seleccione una opción</option>
              <option value="Marketing">Marketing</option>
              <option value="Curaduría">Curaduría</option>
              <option value="Experiencia">Experiencia</option>
            </select>

          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
          <div className="col-md-3">
            <label htmlFor="link_contacto" className="mb-0">
              Link de Catálogo:
            </label>
          </div>
          <div className="col-md-9">
            <input
              name="catalogo"
              onChange={handleChange}
              value={datosEmpleo.catalogo}
              type="text"
              className="form-control"
              id="catalogo"
              required
            />
          </div>
  
        </div>
        <div className="form-row align-items-center mb-3">
            <div className="col-md-3">
                <label  className="mb-0">
               Lista de Productos:
                </label>
            </div>
            <div className="col-md-9">
            <Editor
                setContenido={setProductos}
                dataDefault={datosEmpleo.productos}
            />
            </div>
        </div>
  
        <button type="submit" className="btn" style={style.boton}>
          <i className="fa fa-floppy-o"></i> Guardar
        </button>
      </form>

    );
};

export default FormularioProveedor;