import React from 'react';
import { Card, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import './CardPlan.scss';

const CardPlan = ({ plan, handleExaminar, handleEliminar, handleEditar }) => {
  
  const { tp_plan, dt_plan: conceptos, nombre_plan: planPlan } = plan
  
  const mostrarContenido = () => {
    if(!conceptos.length) {
      return <span>No hay datos para mostrar</span>
    } else {
      return conceptos.map((concepto, index) => (
        <div key={index} className='d-flex justify-content-between align-content-center'>
          <span>{concepto.nombre_concepto}</span>
          <span>: S/ {concepto.monto}</span>
        </div>
      ))
    }
  }

  return ( 
    <Card className='CardPlan'>
      <Card.Header>{planPlan} - {tp_plan}</Card.Header>
      <Card.Body>
        <div className='my-1'>
          {mostrarContenido()}
        </div>
      </Card.Body>
      <Card.Footer>
        <DropdownButton as={ButtonGroup} variant='primary' title="Opciones" size='xs'>
          <Dropdown.Item
            size='xs'
            eventKey="1"
            onClick={() => handleExaminar(plan)}
          >Examinar</Dropdown.Item>
          <Dropdown.Item
            size='xs'
            eventKey="1"
            onClick={() => handleEditar(plan)}
          >Editar</Dropdown.Item>
          <Dropdown.Item
            size='xs'
            eventKey="2"
            onClick={() => handleEliminar(plan.id_plan)}
          >
            Eliminar
          </Dropdown.Item>
        </DropdownButton>
      </Card.Footer>
    </Card>
  );
}
 
export default CardPlan;