import {
  LISTAR_PROFESORES,
  ELIMINAR_DOCENTE_SESION,
  EDITAR_PROFESOR,
  ELIMINAR_DOCENTE_CURSO,
  QUITAR_INSERTAR_DOCENTE_EXITO,
  INSERTAR_PROFESOR_SESION,
  INSERTAR_PROFESOR_CURSO,
  ELIMINAR_PROFESOR,
  LISTAR_PROFESORES_SESION,
  LISTAR_PROFESORES_FALTANTES,
  INSERTAR_DOCENTE_EXITO,
  LISTAR_PROFESORES_CURSO,
  LISTAR_PROFESOR,
  OBTENER_DOCENTE_EXITO,
  LISTAR_CURSOS_PROFESORES,
  AGREGAR_INDICADOR_DOCENTE_EXITO,
  ELIMINAR_INDICADOR_DOCENTE,
  ELIMINAR_INDICADOR_DOCENTE_EXITO,
  OBTERNER_TEMAS_AREA_EXITO,
  OBTENER_TEMAS_AREA_EXITO,
  AREA_DOCENTE_ACTIVO,
  ASIGNAR_AREA_DICTADO_DOCENTE,
  ASIGNAR_TEMA_DOCENTE,
  GUARDAR_NUEVO_ORDEN_TEMAS,
  ELIMINAR_TEMA_DOCENTE,
  GUARDAR_CV_DOCENTE,
  ARCHIVO_ADJUNTO_SUBIDO,
  ELIMINAR_ARCHIVO_DOCENTE,
  EDITAR_HONORARIO_DOCENTE,
  EDITAR_USUARIO_DOCENTE,
  EDITAR_PASSWORD_DOCENTE,
  EDITAR_REGISTRO_DOCENTE,
  SUBIR_AVATAR_DOCENTE
} from "../actions/types";

const initialState = {
  profesores: [],
  docente: {},
  nuevoDocente: null,
  area: {
    areas: [
      {
        nombre: 'GESTION PÚBLICA',
        id:1
      },
      {
        nombre: 'GESTION CONTABLE',
        id:2
      },
      {
        nombre: 'GESTION DE LA CALIDAD',
        id:3
      }
      ,
      {
        nombre: 'GESTION DE RECURSOS HUMANOS',
        id:4
      }
      ,
      {
        nombre: 'INGENIERÍA',
        id:5
      }
    ],
    areaActiva: {},
    temas: []
  },
  profesoresCurso: [],
  profesor: {},
  cursosProfe: [],
  profesoresFaltantes: [],
  profesoresSesion: [],
  temas:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LISTAR_PROFESORES:
      return {
        ...state,
        profesores: action.payload,
      };
    case LISTAR_CURSOS_PROFESORES:
      return {
        ...state,
        cursosProfe: action.payload,
      };
    case LISTAR_PROFESOR:
      return {
        ...state,
        profesor: action.payload,
      };
    case OBTENER_DOCENTE_EXITO:
      return {
        ...state,
        docente: action.payload,
      };
    case INSERTAR_DOCENTE_EXITO:
      return {
        ...state,
        profesores: [...state.profesores, action.payload],
        profesor: action.payload,
        nuevoDocente: true,
      };
    case AGREGAR_INDICADOR_DOCENTE_EXITO:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            clasificacion: {
              data: [
                ...state.docente[action.payload.idDocente].clasificacion.data,
                action.payload.nuevaIndicador,
              ],
              dt_clasificacion: action.payload.nuevaClasificacion,
            },
          },
        },
      };
    case ELIMINAR_INDICADOR_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            clasificacion: {
              ...state.docente[action.payload.idDocente].clasificacion,
              data: [...state.docente[action.payload.idDocente].clasificacion.data].filter(
                item => item.id !== action.payload.idClasificacion 
              )
            },
          },
        },
      };
    case OBTERNER_TEMAS_AREA_EXITO:
      return {
        ...state,
        area: {
          ...state.area,
          temas: action.payload
        }
      }
      case OBTENER_TEMAS_AREA_EXITO:
      return {
        ...state,
        temas: action.payload
      }
    case AREA_DOCENTE_ACTIVO:
      return {
        ...state,
        area: {
          ...state.area,
          areaActiva: action.payload
        }
      }
    case ASIGNAR_AREA_DICTADO_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            area: action.payload.nuevaArea
          },
        },
      }
    case ASIGNAR_TEMA_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            temas: [
              ...state.docente[action.payload.idDocente].temas,
              action.payload.nuevoTema
            ]
          }
        }
      }
    case GUARDAR_NUEVO_ORDEN_TEMAS:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            temas: action.payload.nuevoOrden
          }
        }
      }
    case ELIMINAR_TEMA_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            temas: [...state.docente[action.payload.idDocente].temas].filter(
              item => item.id_tema_area !== action.payload.idTemaDocente
            )
          }
        }
      }
    case GUARDAR_CV_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            descripcion: action.payload.nuevoCv
          }
        }
      }
    case ARCHIVO_ADJUNTO_SUBIDO:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            documentos: {
              ...state.docente[action.payload.idDocente].documentos,
              [action.payload.nombreDocumento]: action.payload.nuevoArchivo
            }
          }
        }
      }
    case ELIMINAR_ARCHIVO_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            documentos: {
              ...state.docente[action.payload.idDocente].documentos,
              [action.payload.nombreDocumento]: null
            }
          }
        }
      }
    case EDITAR_HONORARIO_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            honorarios: action.payload.datos
          }
        }
      }
    case EDITAR_USUARIO_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            usuario: action.payload.nombreUsuario
          }
        }
      }
    case EDITAR_PASSWORD_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            password: action.payload.nuevoHash
          }
        }
      }
    case EDITAR_REGISTRO_DOCENTE:
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            ...action.payload.nuevosDatos
          }
        }
      }
    case SUBIR_AVATAR_DOCENTE: 
      return {
        ...state,
        docente: {
          ...state.docente,
          [action.payload.idDocente]: {
            ...state.docente[action.payload.idDocente],
            img_usuario: action.payload.nuevoAvatar
          }
        }
      }
    case ELIMINAR_INDICADOR_DOCENTE_EXITO:
      return state
    case QUITAR_INSERTAR_DOCENTE_EXITO:
      return {
        ...state,
        nuevoDocente: null,
      };
    case LISTAR_PROFESORES_CURSO:
      return {
        ...state,
        profesoresCurso: action.payload,
      };
    case INSERTAR_PROFESOR_CURSO:
      return {
        ...state,
        profesoresCurso: action.payload.datos,
        profesoresFaltantes: action.payload.filtro,
      };
    case LISTAR_PROFESORES_FALTANTES:
      return {
        ...state,
        profesoresFaltantes: action.payload,
      };
    case LISTAR_PROFESORES_SESION:
      return {
        ...state,
        profesoresSesion: action.payload,
      };
    case INSERTAR_PROFESOR_SESION:
      return {
        ...state,
        profesoresSesion: action.payload.datos,
      };
    case ELIMINAR_PROFESOR:
      return {
        ...state,
        profesores: [...state.profesores].filter(
          (profesor) => profesor.id_usuario !== action.payload
        ),
      };
    case ELIMINAR_DOCENTE_CURSO:
      return {
        ...state,
        profesoresCurso: action.payload,
      };
    case ELIMINAR_DOCENTE_SESION:
      return {
        ...state,
        profesoresSesion: action.payload,
      };
    case EDITAR_PROFESOR:
      return {
        ...state,
        profesores: action.payload,
      };
    default:
      return state;
  }
}
