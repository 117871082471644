import React from 'react';
import * as moment from 'moment';
import { getColorEstadoPago } from '../../../../helpers/functions';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const FilaTablaPensiones = ({ index, tipo, valor, cuotaOrdinaria = {} }) => {
  
  const {
    concepto,
    fecha_limite,
    tp_cuota,
    subtotal,
    total,
    saldo,
    nombre_tp_estado_pago,
    id_matricula,
    id_dt_matricula
  } = cuotaOrdinaria;
  
  return ( 
      <tr>
        <td align='center' valign='middle'>{index+1}</td>
        <td align='center' valign='middle'>{concepto}</td>
        <td align='center' valign='middle'>{fecha_limite && moment(fecha_limite).format('DD MMM YYYY')}</td>
        <td align='center' valign='middle'>{tp_cuota}</td>
        <td align='center' valign='middle'>{subtotal}</td>
        <td align='center' valign='middle'>{total}</td>
        <td align='center' valign='middle'>{saldo}</td>
        <td align='center' valign='middle' >
          <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`} >
            {nombre_tp_estado_pago}
          </Badge>
        </td>
        <td align='center'>
        <Link to={`/pagos/examinar-pension?q=${tipo}&value=${valor}&matricula=${id_matricula}&pension=${id_dt_matricula}`}>
          <Button
            variant='warning'
            title='Examinar'
            size='xs'
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </Link>
        </td>
      </tr>
  );
}

FilaTablaPensiones.propType = {
  index: PropTypes.number,
  tipo: PropTypes.string,
  valor: PropTypes.string,
  cuotaOrdinaria: PropTypes.object
}
 
export default FilaTablaPensiones;