import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarCampaignsN } from '../actions/campaignsActions';

const useSelectCampaigns = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const campaigns = useSelector(state => state.campaigns.campaigns)

  const [campaignSelected, setCampaignsSelected] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const campaignSelect = campaigns.find(campaign => campaign.id_camp === parseInt(initialSelect)); 
      if(campaignSelect) {
        setCampaignsSelected(campaignSelect);
      }
    }
  }

  useEffect(() => {
    if(!campaigns.length) {
      dispatch(listarCampaignsN())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [campaigns])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!campaigns.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={campaign => setCampaignsSelected(campaign)}
        value={campaignSelected}
        getOptionValue={campaigns => campaigns.id_camp}
        getOptionLabel={campaigns => campaigns.nombre_proyecto}
        options={campaigns}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [campaignSelected, SimpleSelect, setCampaignsSelected]

}
 
export default useSelectCampaigns;