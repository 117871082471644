import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalCustom } from '../../../../../actions/modalActions';
import { obtenerListaClasificacionTrabajadores } from '../../../../../actions/trabajadoresActions';
import Fatal from '../../../MessageAlert/Fatal';

const EditarClasificacionTrabajador = ({ clasificacion = null, handleSubmitForm }) => {

  const dispatch = useDispatch();
  const { loading, error, datos: listaClasificaciones = [] } = useSelector(state => state.trabajadores.clasificacion);
  const [optionSelect, setOptionSelect] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    handleSubmitForm(optionSelect.id_tp_clasificacion_usuario);
  }

  const handleModal = () => {
    dispatch(updateStateModalCustom());
  }

  useEffect(() => {
    dispatch(obtenerListaClasificacionTrabajadores());
    if(clasificacion) {
      setOptionSelect(clasificacion)
    }
  }, [])

  if(error) return <Modal.Body>
    <Fatal message={error} />
  </Modal.Body>

  return ( 
    <div className="EditarClasificacionTrabajador">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Clasificación</Form.Label>
            <Select
              options={listaClasificaciones}
              getOptionValue={(option) => option.id_tp_clasificacion_usuario}
              getOptionLabel={(option) => option.nombre_clasificacion}
              isLoading={loading}
              loadingMessage={() => 'Cargando...'}
              noOptionsMessage={() => 'No hay resultados'}
              placeholder='--Seleccione--'
              onChange={e => setOptionSelect(e)}
              value={optionSelect}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            className='btn-icon'
            onClick={handleModal}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button type='submit' variant='info' className='btn-icon'>
            <FontAwesomeIcon icon={faSave} />
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </div>
   );
}
 
export default EditarClasificacionTrabajador;