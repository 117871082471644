import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import FormularioPopUp from './FormularioPopUp';
import Editor from './EditorPopUp'


//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarFondosPuIntranet, listarpopup } from '../../../actions/popupsActions';
import ModalBasicwithRedux from '../../../containers/ModalBasicwithRedux';
import { updateStateModalBasic } from '../../../actions/modalActions';
import NuevoFondo from './NuevoFondo';

const PopUps = () => {
  const { usuario } = useSelector(state => state.usuario);
  const {loading, error, popups} = useSelector(state => state.popUp);
  const dispatch = useDispatch();

  const handleModalNuevo = () => {
    dispatch(updateStateModalBasic(<NuevoFondo/>, 'Nuevo Fondo'));
  }


  useEffect(() => {
    dispatch(listarpopup());
    dispatch(listarFondosPuIntranet());
  }, [])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='PopUp' subItem={usuario.id_tp_nivel_usuario==='5' ? '¡Contagia entusiasmo mediante motores de alegría!' : null} />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "PopUp" },
            ]}
          />
            <button className="btn btn-primary mb-2" onClick={()=>handleModalNuevo()}> Nuevo Fondo </button>
        </div>
        <div className="ControlReportes__body">
        <ModalBasicwithRedux />
          <SpinnerLoading loading={loading} error={error}>
            {
               popups ? <FormularioPopUp popupsDatos={popups} /> : 'Sin Datos'
              
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default PopUps;