import React from "react";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import { Button } from 'react-bootstrap'

const LeftContent = ({ reporteAsistenciaTabla }) => {
  return (
    <div className="p-0 my-4 bg-white">
      <div className="continer-fluid border-bottom-0 border">
        <h5
          className="p-3 d-flex align-items-center mb-0"
          style={{ color: "#434343" }}
        >
          <i className="fas fa-list pr-2"></i>Lista de proyectos
        </h5>
      </div>
      <div className="tabla border">
        <MaterialTableDemo 
          colums={colums} 
          tabla={reporteAsistenciaTabla}
          responsive={true} 
        />
      </div>
    </div>
  );
};

export default LeftContent;

const colums = [
  {
    text: "Estado",
    key: "estado",
    align: "left",
    sortable: true,
    TrOnlyClassName: "encabezado estado",
  },
  {
    text: "Nombre",
    key: "nombre",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado nombre",
  },
  {
    text: "Apellido",
    key: "apellido",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado apellido",
  },
  {
    text: "Turno",
    key: "turno",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado turno",
  },
  {
    text: "Ingreso mañana",
    key: "ingreso_m",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado ingreso_m",
  },
  {
    text: "Salida tarde",
    key: "salida_m",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado salida_m",
  },
  {
    text: "Ingreso tarde",
    key: "ingreso_t",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado ingreso_t",
  },
  {
    text: "Salida tarde",
    key: "salida_t",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado salida_t",
  },
  {
    text: "Fecha",
    key: "fecha",
    align: "center",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado fecha",
  },
  {
    text: "Acciones",
    key: "button",
    align: "left",
    sortable: true,
    className: "type_of_food",
    TrOnlyClassName: "encabezado acciones",
    cell: record => {
      return (
        <>
          <Button
              className="btn btn-primary btn-sm"
              onClick={() => console.log(record)}
              style={{marginRight: '5px'}}>
              <i className="fa fa-edit"></i>
          </Button>
          <Button
              className="btn btn-danger btn-sm" 
              onClick={() => console.log(record)}>
              <i className="fa fa-trash"></i>
          </Button>
        </>
      )
    }
  },
];
