import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const RangoProfesional = ({ nombre_clasificacion, id, handleDeleteIndicador }) => {

  const handleButton = () => {
    handleDeleteIndicador(id)
  }

  return ( 
    <div className="RangoProfesional rounded border my-2 p-2">
      <div className='RangoProfesional__content d-flex justify-content-between align-items-center'>
        <span className="RangoProfesional__nombre">
          { nombre_clasificacion }
        </span>
        <div className="RangoProfesional__acciones">
          <Button
            size='sm'
            variant='light'
            onClick={() => handleButton()}
          >
            &times;
          </Button>
        </div>
      </div>
    </div>
   );
}

RangoProfesional.propTypes = {
  nombre_clasificacion: PropTypes.string,
  deleteIndicador: PropTypes.func.isRequired
}
 
export default RangoProfesional;