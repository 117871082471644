import React, {useState, useEffect} from 'react';
import useGetProyectos from '../../../hooks/useGetProyectos';
import { useDispatch, useSelector, connect } from 'react-redux';
import { listarSesionesCursos } from '../../../actions/cursosActions';
import {obtenerRespuestasProfundizar} from '../../../actions/encuestasAction';
import SimpleSelect from 'react-select';
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import { Container} from "react-bootstrap";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import MensajeLoading from "../../components/MensajeLoading";
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faListAlt} from "@fortawesome/free-solid-svg-icons";
import BotonesDescargas from '../../components/Finanzas/BotonesDescargas';
const Sugerencias = () => {

    const { usuario } = useSelector(state => state.usuario);
    const { proyectos } = useGetProyectos();
    const { sesionesCurso } = useSelector(state => state.cursos);
    const [sesion, setSesion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [datoProyecto, setDatoProyecto] = useState({
        proyectoSeleccion: null
      });

    const [datosSugerencias, setDatosSugerencias] = useState(null);

    const dispatch = useDispatch();
    const handleUrlDescarga = (tipo) => {
      if (!datoProyecto) return;  
      const { id_proyecto } = datoProyecto.proyectoSeleccion;
      return `${process.env.REACT_APP_API_URL}/admin/${tipo}/reporte/profundizar/${id_proyecto}`;
    }
    useEffect(() => {
      if(datoProyecto.proyectoSeleccion) {
          dispatch(listarSesionesCursos(datoProyecto.proyectoSeleccion.id_proyecto));
          setSesion(null);
        }
    }, [datoProyecto.proyectoSeleccion])

    useEffect(() => {
      if(sesion) {
        const fetchData = async () =>{
            const respuesta = await (dispatch(obtenerRespuestasProfundizar(sesion)))
            setDatosSugerencias(respuesta.datos)
            setLoading(false);
          }
          fetchData();
        }
    }, [sesion])

    return (
        <Layout>
        <HeaderContent title="Crecimiento II" subItem={usuario.id_tp_nivel_usuario==='5'? '¡Identifica en qué puntos desean profundizar y plantea oportunidades de nuevos productos!':null} />
        <Breadcrumb
          link={[
            { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
            { url: "", nombre: "Área de Trabajo" },
            { url: "", nombre: "Crecimiento II" },
          ]}
        />
        <div className="encuestas">
         
          <div className="container-fluid px-0 content-actions my-3">
            <div className="row encuetas__content-filtro">
            <div className="col-md-4 filtro">
                <label htmlFor="">Productos</label>
                <SimpleSelect
              options={proyectos}
              getOptionLabel={item =>  item.nombre_proyecto}
              getOptionValue={item => item.id_proyecto}
              value={datoProyecto.proyectoSeleccion}
              onChange={e => setDatoProyecto(
                datos => ({...datos, proyectoSeleccion: e})
              )}
            />
            </div>
              <div className="col-md-4 filtro">
                <label htmlFor="">Sesiones</label>
                <SimpleSelect
                options={sesionesCurso}
                getOptionLabel={item => item.nombre_sesion}
                getOptionValue={item => item.id_sesion}
                value={sesion}
                onChange={e => setSesion(e)}
                />
              </div>
              { datoProyecto.proyectoSeleccion ? <div className="col-md-4 filtro p-2">
                <BotonesDescargas 
                      handleUrlDescarga={handleUrlDescarga}
                      pdf={false}
                      excel={true}
                      csv={false}
                    />
              </div> : '' }   
            </div>
          </div>
          { !sesion ? (
            <MensajeLoading/>
        ) : (
          <Container>
            <SpinnerLoading loading={loading}>
                <div className="box mt-5">
                    <div className="box-header with-border">
                    <h5
                        style={{ color: "#434343" }}
                        >
                        <FontAwesomeIcon icon={faListAlt} /> Lista de Respuestas a: ¿Te gustaría profundizar este tema con una especialización o diplomado?  Sí /  No, me queda claro.
                    </h5>
                  
                    </div>
                    <div className="box-primary">
                    <MaterialTableDemo   colums={colums()}
                        tabla={datosSugerencias} />
                    </div>
                </div>  
            </SpinnerLoading>
          </Container>
        )}
        </div>
      </Layout>
    );

};
export default Sugerencias;

/*============ Tabla ========= */

const colums = () => [
    {
      text: '#',
      key: 'id',
      className: 'id',
      width: 50
    },
    {
      text: "Respuesta",
      key: "respuesta",
      className:"respuesta",
      sortable: true,
      width:800
    },
    {
      text: "Fecha",
      key: "fecha_sesion",
      align: "center",
      className:"fecha_sesion",
      sortable: true,
      cell: (record)=>
        <p className="text-center">{record.fecha_sesion}</p>
    },
];