import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarNombreUsuario } from '../../actions/trabajadoresActions';

const withEditarUsuarioSesion = WrappedComponent => props => {
  
  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleSubmitUsuario = (nombreUsuario) => {
    dispatch( editarNombreUsuario(nombreUsuario, idTrabajador) )
  }

  return ( 
    <WrappedComponent
      {...props}
      usuario={trabajador.usuario}
      handleSubmitUsuario={handleSubmitUsuario}
    />
   );
}
 
export default withEditarUsuarioSesion;