import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

const ArchivosAdjuntos = ({
  Curriculum_vitae: curriculum_vitae,
  Titulo: titulo, 
  Experiencia: experiencia,
  Publicaciones: publicaciones,
  handleSubirArchivo,
  handleEliminarArchivo
 }) => {

  const [idTipoArchivo, setIdTipoArchivo] = useState(null)
  const inputFile = useRef()

  const handleClickCargar = idTipo => {
    setIdTipoArchivo(idTipo);
    inputFile.current.click();
  }

  const handleChangeFile = e => {
    if (e.target.files[0] !== undefined) {
      handleSubirArchivo(e.target.files[0], idTipoArchivo)
      return
    }

    avisoError('No se ha elejido ningún archivo')
  }

  const handleEliminar = (archivo, idTipo) => {
    handleEliminarArchivo(archivo.id_documento_usuario, idTipo)
  }

  return ( 
    <div className="ArchivosAdjuntos">
      <div className="ArchivosAdjuntos__container">
        <div className="ArchivosAdjuntos__head px-3 py-3 border-bottom d-flex align-items-center">
          <FontAwesomeIcon icon={faBriefcase} className='mr-1' />
          <h5>Archivos adjuntos</h5>
        </div>
        <div className='ArchivosAdjuntos__body px-3 my-3'>
          <div className="ArchivosAjuntos_list">
            <div className='Adjunto d-flex justify-content-between align-items-center'>
              <div>
                <span className='font-weight-bold'>CV Documentado</span> {curriculum_vitae && <span>{curriculum_vitae.nombre_archivo}</span>}
              </div>
              <div className="Adjunto__acciones">
                <Button
                  variant='danger'
                  disabled={!curriculum_vitae}
                  onClick={() => handleEliminar(curriculum_vitae, 1)}
                >
                  Eliminar
                </Button>
                <Button
                  onClick={() => handleClickCargar(1)}
                  variant='warning'
                  className='text-white'
                >
                  Cargar
                </Button>
                <Button
                  href={`${process.env.REACT_APP_API_URL}/${curriculum_vitae && curriculum_vitae.ruta_archivo}`}
                  variant='success'
                  disabled={!curriculum_vitae}
                >
                  Ver
                </Button>
              </div>
            </div>
        {  /*  <div className='Adjunto d-flex justify-content-between align-items-center'>
              <div>
                <span className='font-weight-bold'>Títulos</span> {titulo && <span>{titulo.nombre_archivo}</span>}
              </div>
              <div className="Adjunto__acciones">
                <Button
                  variant='danger'
                  disabled={!titulo}
                  onClick={() => handleEliminar(titulo, 3)}
                >
                  Eliminar
                </Button>
                <Button
                  onClick={() => handleClickCargar(3)}
                  variant='warning'
                  className='text-white'
                >
                  Cargar
                </Button>
                <Button
                  href={`${process.env.REACT_APP_API_URL}/${titulo && titulo.ruta_archivo}`}
                  variant='success'
                  disabled={!titulo}
                >
                  Ver
                </Button>
              </div>
            </div>
            <div className='Adjunto d-flex justify-content-between align-items-center'>
              <div>
                <span className='font-weight-bold'>Experiencia laboral</span> {experiencia && <span>{experiencia.nombre_archivo}</span>}
              </div>
              <div className="Adjunto__acciones">
                <Button
                  variant='danger'
                  disabled={!experiencia}
                  onClick={() => handleEliminar(experiencia, 4)}
                >
                  Eliminar
                </Button>
                <Button
                  onClick={() => handleClickCargar(4)}
                  variant='warning'
                  className='text-white'
                >
                  Cargar
                </Button>
                <Button
                  href={`${process.env.REACT_APP_API_URL}/${experiencia && experiencia.ruta_archivo}`}
                  variant='success'
                  disabled={!experiencia}
                >
                  Ver
                </Button>
              </div>
            </div>
            <div className='Adjunto d-flex justify-content-between align-items-center'>
              <div>
                <span className='font-weight-bold'>Publicaciones</span> {publicaciones && <span>{publicaciones.nombre_archivo}</span>}
              </div>
              <div className="Adjunto__acciones">
                <Button
                  variant='danger'
                  disabled={!publicaciones}
                  onClick={() => handleEliminar(publicaciones, 5)}
                >
                  Eliminar
                </Button>
                <Button
                  onClick={() => handleClickCargar(5)}
                  variant='warning'
                  className='text-white'
                >
                  Cargar
                </Button>
                <Button
                  href={`${process.env.REACT_APP_API_URL}/${publicaciones && publicaciones.ruta_archivo}`}
                  variant='success'
                  disabled={!publicaciones}
                >
                  Ver
                </Button>
              </div>
            </div>*/}
          </div>
          <input
            type="file"
            hidden
            ref={inputFile}
            onChange={handleChangeFile}
          />
        </div>
      </div>
    </div>
   );
}
 
export default ArchivosAdjuntos;