import React from 'react'

//Redux
import { connect } from 'react-redux'
import { actualizarLimitador } from '../../../../actions/reportesActions'

const LimitadorFilas = (props) => {

  const { limitador, actualizarLimitador } = props

  return ( 

    <>
      <label htmlFor="pages">
        Listar
      </label>
      <select name="pages" className='select-entries' id='pages' value={limitador} onChange={e => actualizarLimitador(parseInt(e.target.value))} id="">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="100">500</option>
        <option value="100">1000</option>
        <option value="100">10000</option>
      </select>
      <label htmlFor="pages">
        entradas
      </label>
    </>
   );
}

const mapStateToprops = state => ({
  limitador: state.reporte.limitador
})

const mapDispatchToProps = {
  actualizarLimitador
}
 
export default connect(mapStateToprops, mapDispatchToProps)(LimitadorFilas);