import React from 'react';
import EditarDatosSesionTrabajador from '../../view/components/RegistroUsuarios/DatosSesion/EditarDatosSesion/EditarDatosSesionTrabajador';
import useGetTrabajador from '../../hooks/useGetTrabajador';
//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';


const withDatosSesion = WrappedComponent => props => {
  
  const dispatch = useDispatch();
  const [trabajador] = useGetTrabajador();

  const handleEditar = () => {
    dispatch(updateStateModalCustom(<EditarDatosSesionTrabajador />, 'Editar datos de sesión', 'modal-w30'))
  }

  return ( 
    <WrappedComponent
      {...props}
      usuario={trabajador.usuario}
      handleEditar={handleEditar}
    />
   );
}
 
export default withDatosSesion;