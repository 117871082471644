import React, { useEffect } from 'react';
import Layout from '../../../../../app/Layout';
import { useParams } from 'react-router-dom';
import HeaderContent from '../../../General/HeaderContent';
import TbResumenCtrlCapacitaciones from '../../../../components/Tablas/ControlCapacitaciones/TbResumenCtrlCapacitaciones/TbResumenCtrlCapacitaciones';
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';
import TablaPonenteCtrl from '../../../../components/Tablas/ControlCapacitaciones/TablaPonentesCtrl/TablaPonentesCtrl';
import ModalLargewhithRedux from '../../../../../containers/ModalLargewhithRedux';
import ModalBasicWithRedux from '../../../../../containers/ModalBasicwithRedux';
import LightBoxwithRedux from '../../../../../containers/LightBoxwithRedux';
import TablaControlCapacitaciones from '../../../../../containers/ControlCapacitacionesComtainers/TablaControlCapacitaciones';
import { Row, Col } from 'react-bootstrap';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerInfoControl } from '../../../../../actions/controlCapacitacionesActions'; 


const ControlCapacitacion = () => {

  const { idCapacitacion } = useParams()
  const dispatch = useDispatch()
  const { area_trabajo: { datos, loading} } = useSelector(state => state.control_capacitacion)

  useEffect(() => {
    if (!(idCapacitacion in datos)) {
      dispatch(obtenerInfoControl(idCapacitacion))
    }
  }, [])

  return (  
    <Layout>
      <HeaderContent title='Control capacitacion' icon='supervisor_account'/>
      <SpinnerLoading loading={loading}>
        <Row>
          <Col md={4}>
            <TbResumenCtrlCapacitaciones/>
          </Col>
          <Col md={5}>
            <TablaPonenteCtrl/>
          </Col>
        </Row>
        <TablaControlCapacitaciones/>
        <ModalLargewhithRedux/>
        <ModalBasicWithRedux/>
        <LightBoxwithRedux/>
      </SpinnerLoading>
    </Layout>
  );
}
 
export default ControlCapacitacion;
