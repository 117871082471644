import { LISTAR_NOTAS, EDITAR_NOTAS, REMOVER_ESTADO_NOTAS} from '../actions/types'

const initialState = {
    notasSesiones: [],
    estadoSesion: 0
}

export default function(state = initialState, action){
    switch (action.type) {
        case LISTAR_NOTAS:
            return{
                ...state,
                notasSesiones: action.payload.datos
            }
        case EDITAR_NOTAS:
            return{
                ...state,
                notasSesiones: action.payload.notas,
                estadoSesion: action.payload.statusEditar
            }
        case REMOVER_ESTADO_NOTAS:
            return{
                ...state,
                estadoSesion: action.payload
            }
        default:
            return state
    }
}