import {
    LISTAR_TAREAS,
    LISTAR_TAREAS_ESTUDIANTES,
    INSERTAR_TAREAS,
    ELIMINAR_TAREAS
} from '../actions/types'

const initialState = {
    tareas: [],
    tareasEstudiantes: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case LISTAR_TAREAS:
            return {
                ...state,
                tareas: action.payload
            }
        case LISTAR_TAREAS_ESTUDIANTES:
            return {
                ...state,
                tareasEstudiantes: action.payload 
            }
        case INSERTAR_TAREAS:
            return {
                ...state,
                tareas: action.payload
            }
        case ELIMINAR_TAREAS:
            return {
                ...state,
                tareas: action.payload
            }
        default:
            return state
    }
}