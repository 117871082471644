import React, { useEffect, useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import useSelectProyecto from '../../../hooks/useMultiSelectProyectos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import '../Finanzas/FormularioFiltroReporte/FormularioFiltroReporte.scss';

const FormularioFiltroReporteNaranja = ({ onSubmit, setValores, ingresos = false }) => {
  
  const queryString = new URLSearchParams(window.location.search);
  const proyectoSeleccionado = queryString.get('q');
  const fechaInicioQ = queryString.get('fechaInicio');
  const fechaFinQ = queryString.get('fechaFin');

  const [submitState, setSubmitState] = useState(false);

  const [seleccionProyecto, SelectProyecto] = useSelectProyecto(proyectoSeleccionado);
  const history = useHistory()


  const formik = useFormik({
    initialValues: {
      proyectoSeleccionado: {proyectoSeleccionado},
      fechaInicio: fechaInicioQ ? new Date(fechaInicioQ) : new Date(),
      fechaFin: fechaFinQ ? new Date(fechaFinQ) : new Date()
    },
    validationSchema: yup.object({
      fechaInicio: yup.date()
        .typeError('Entrada no válida: fecha por favor.')
        .required('Este campo es requerido.'),
      fechaFin: yup.date()
        .typeError('Entrada no válida: fecha por favor.')
        .required('Este campo es requerido.')
    }),
    onSubmit: valores => {
     // console.log(seleccionProyecto);
      history.push(`?q=${valores.proyectoSeleccionado}&fechaInicio=${moment(valores.fechaInicio).format('L')}&fechaFin=${moment(valores.fechaFin).format('L')}`)
    }
  });

  useEffect(() => {
    
    if(fechaInicioQ && fechaFinQ){
     // console.log(formik.values)
      onSubmit(formik.values);
      setSubmitState(true);
      if(setValores) {
        setValores(formik.values);
      }
    }
  }, [fechaInicioQ, fechaFinQ, proyectoSeleccionado])


  useEffect(() => {
    formik.setFieldValue('proyectoSeleccionado', seleccionProyecto ? seleccionProyecto : null);
 
    if(seleccionProyecto !== undefined && submitState && setValores) {
 
      setSubmitState(false);
    }
  }, [seleccionProyecto, submitState])

  return (
    <div className="FormularioFiltroReporte">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Producto (Calidad / Fidelización)</Form.Label>
            <SelectProyecto selectAll/>
            {formik.errors.proyecto && (
              <Form.Text className="text-danger">
                {formik.errors.proyecto}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md={2}>
              <Form.Label className="w-100">Recaudación: Desde</Form.Label>
              <DatePicker
                selected={formik.values.fechaInicio}
                onChange={(date) => formik.setFieldValue("fechaInicio", date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                name="fechaInicio"
                startDate={formik.values.fechaInicio}
                className="form-control"
                endDate={formik.values.fechaFin}
              />
              {formik.errors.fechaInicio && (
                <Form.Text className="text-danger">
                  {formik.errors.fechaInicio}
                </Form.Text>
              )}
            </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label className="w-100">Recaudación: Hasta</Form.Label>
            <DatePicker
              selected={formik.values.fechaFin}
              onChange={(date) => formik.setFieldValue("fechaFin", date)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selectsEnd
              name="fechaFin"
              startDate={formik.values.fechaInicio}
              endDate={formik.values.fechaFin}
              minDate={formik.values.fechaInicio}
            />
            {formik.errors.fechaFin && (
              <Form.Text className="text-danger">
                {formik.errors.fechaFin}
              </Form.Text>
            )}
          </Form.Group>
        
          <Form.Group
            as={Col}
            md={2}
            className="d-flex align-items-end justify-content-end"
          >
            <Button variant='primary' type="submit" className="btn-icon">
              <FontAwesomeIcon icon={faSearch} />
              Buscar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormularioFiltroReporteNaranja;

