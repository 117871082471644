import React from 'react';
import { Table } from 'react-bootstrap';
import FilaCuota from './FilaCuota';
import './TablaCuotas.scss';

//Redux
import  { useSelector } from 'react-redux';

const TablaCuotas = () => {

  const { cuotas } = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones.todo);

  return ( 
    <div className="TablaCuotas tableFixHead" style={{height:'220px'}}>
      <div className="box">
        <Table responsive='md'>
          <thead>
            <tr>
              <th>#</th>
              <th>Monto establecido</th>
              <th>Fecha límite</th>
              <th>Estado</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cuotas.length ? (
              cuotas.map((cuota, item) => (
                <FilaCuota 
                  key={cuota.id_fraccion}
                  item={item}
                  {...cuota}
                />
              ))
            ) : <tr>
              <td colSpan='5' className='text-center'>No hay datos</td>
            </tr>}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
 
export default TablaCuotas;