import React from 'react';

const Select = ({ docente, quitarElementoLista}) => {
    return ( 
        <div className="d-flex border ml-1 p-1 rounded bg-white" style={{maxHeight: "25px"}}>
            <p>{`${docente.nombre_usuario}${' '}${docente.apellido_usuario}`}</p>
            <button type="button" className="border p-1 bg-danger" onClick={e => {
                e.preventDefault();
                quitarElementoLista(docente)
            }} style={{ fontSize: "10px" }}><i className="fas fa-user-times"></i></button>
        </div>
     );
}
 
export default Select;