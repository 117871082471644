import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import SimpleSelect from 'react-select';
import useGetProyectos from '../../../../hooks/useGetProyectos';
import { useDispatch, useSelector } from 'react-redux';
import { listarSesionesCursos } from '../../../../actions/cursosActions';
import { listarAsistencias } from '../../../../actions/asistenciaActions';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';


const FormObtenerAsistencias = ({ datosObtenerAsistencia, setDatosObtenerAsistencia }) => {

  const { proyectos, loading } = useGetProyectos();
  const { sesionesCurso } = useSelector(state => state.cursos);
  const dispatch = useDispatch();

  const mostrarAsistencias = async () => {
    const { mensaje, textMensaje } = await dispatch(listarAsistencias(
      datosObtenerAsistencia.horarioSeleccion.value,
      datosObtenerAsistencia.sesionSeleccion.id_sesion
    ));

    if (mensaje === false) {
      avisoError(textMensaje);
    }
  };

  useEffect(() => {
    setDatosObtenerAsistencia(datos => ({...datos, sesionSeleccion: null}));
    setDatosObtenerAsistencia(datos => ({...datos, horarioSeleccion: null}));
    if(datosObtenerAsistencia.proyectoSeleccion) {
      dispatch(listarSesionesCursos(datosObtenerAsistencia.proyectoSeleccion.id_proyecto));
    }
  }, [datosObtenerAsistencia.proyectoSeleccion])

  useEffect(() => {
    if(
      datosObtenerAsistencia.proyectoSeleccion &&
      datosObtenerAsistencia.sesionSeleccion &&
      datosObtenerAsistencia.horarioSeleccion
    ) {
      mostrarAsistencias();
    }
  }, [datosObtenerAsistencia])

  return ( 
    <div className="FormObtenerAsistencias">
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Productos</Form.Label>
            <SimpleSelect
              options={proyectos}
              isLoading={loading}
              getOptionLabel={item =>  item.nombre_proyecto}
              getOptionValue={item => item.id_proyecto}
              value={datosObtenerAsistencia.proyectoSeleccion}
              onChange={e => setDatosObtenerAsistencia(
                datos => ({...datos, proyectoSeleccion: e})
              )}
            />
          </Form.Group>
     {   datosObtenerAsistencia.proyectoSeleccion ?  
          <Form.Group as={Col} md={4}>
            <Form.Label>Reporte Asistencia</Form.Label><br/>
            <a href={`https://apisistema.gestoraperu.pe/admin/excel/reporte/asistencias/${datosObtenerAsistencia.proyectoSeleccion.id_proyecto}`} className="btn btn-primary" > Descargar ... </a>
          </Form.Group> : '' }
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Sesiones</Form.Label>
            <SimpleSelect
              options={sesionesCurso}
              getOptionLabel={item => item.nombre_sesion}
              getOptionValue={item => item.id_sesion}
              value={datosObtenerAsistencia.sesionSeleccion}
              onChange={e => setDatosObtenerAsistencia(
                datos => ({...datos, sesionSeleccion: e})
              )}
            />
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Horario</Form.Label>
            <SimpleSelect
              options={[
                { value: 1, label: "Ingreso Mañana" },
                { value: 2, label: "Salida Mañana" },
                { value: 3, label: "Ingreso Tarde" },
                { value: 4, label: "Salida Tarde" },
              ]}
              value={datosObtenerAsistencia.horarioSeleccion}
              onChange={e => setDatosObtenerAsistencia(
                datos => ({...datos, horarioSeleccion: e})
              )}
            />
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormObtenerAsistencias;