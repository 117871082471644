import React from 'react';
import Opcion from './OpcionExamen';

const listarOpcionesExamen = ({ opciones, handleChange }) => {

  const mostrarOpciones = () => {
    if(opciones.length > 0) {
      return opciones.map(opcion => (
       <Opcion 
       key={opcion.id_opcion}
       {...opcion}
     />
      ))
    } else {
      return <p>No hay opciones</p>
    }
  }

  return ( 
    <div style={{display:'flex', flexDirection:'column', paddingLeft:'60px'}} className="mb-3 form-check" onChange={handleChange}>
      {mostrarOpciones()}
    </div>
  );
}
 
export default listarOpcionesExamen;