import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { getColorEstadoMatricula } from '../../../../helpers/functions';

const FilaTablaMatricula = ({ index, matricula, tipo, valor }) => {
  
  const {
    id_matricula,
    nombre_programa,
    fecha_matricula,
    nombre_estado_matricula,
  } = matricula

  return ( 
    <tr>
      <td align='center'>{index+1}</td>
      <td align='center'>{id_matricula}</td>
      <td>{nombre_programa}</td>
      <td align='center'>{fecha_matricula}</td>
      <td align='center'>
        <Badge variant={`${getColorEstadoMatricula(nombre_estado_matricula)}`}>
          {nombre_estado_matricula && nombre_estado_matricula.toUpperCase()}
        </Badge>
      </td>
      <td align='center'>
        <Link to={`/pagos/examinar-matricula?q=${tipo}&value=${valor}&matricula=${id_matricula}`}>
          <Button size='xs' variant='warning'>
            <FontAwesomeIcon icon={faEye}/>
          </Button>
        </Link>
      </td>
    </tr>
  );
}
 
export default FilaTablaMatricula;