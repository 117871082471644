import React, { useEffect, useState } from 'react';
import {Modal, Button } from 'react-bootstrap';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { nuevaRespuestaDocente } from '../../../actions/cursosActions';
//import { guardarComentarioVideo, showForm } from '../../actions/cursosActions';
//import { useParams } from 'react-router-dom';

const NuevaRespuesta = ({id_comentario}) => {

  const [show,setShow] = useState(false)
  const usuario = useSelector(state=> state.usuario.usuario);
  const dispatch = useDispatch();
  const initialState = {
    respuesta:'',
    comentario:id_comentario,
    id_usuario: usuario ? usuario.id_usuario : null,
    docente: usuario ? usuario.id_empleado : null
  }
  const [datosRespuesta,setDatosRespuesta]=useState(initialState)

  const {respuesta} = datosRespuesta

  const handleChange = (evt) => {
    const value = evt.target.value;
    setDatosRespuesta({
      ...datosRespuesta,
      [evt.target.name]: value
    });
  }
  const enviarComentario = async (e) =>{
    e.preventDefault();
    await dispatch(nuevaRespuestaDocente(datosRespuesta))
    //console.log(datosRespuesta)
    setDatosRespuesta(initialState)
    setShow(!show)
  }

  return (
    <>
    <Button variant="primary" size="lg" className="float-right" onClick={() => setShow(!show)} style={{marginLeft:'10px'}}>
     Responder
    </Button>

    <Modal
      show={show}
      onHide={() => setShow(!show)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Nueva Respuesta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={(e) => enviarComentario(e)}>

          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Contenido de la respuesta</label>
            <textarea name="respuesta" className="form-control" value={respuesta} onChange={handleChange} />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
            Publicar respuesta
          </button>
          <button type="reset" className="btn btn-danger" onClick={() => setShow(!show)}>
           Cancelar
          </button>
          </Box>
      </form>
      </Modal.Body>
    </Modal>
  </>
   );
}
 
export default NuevaRespuesta;