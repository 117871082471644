import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import UpdateObservationsReporte from "../../components/Formularios/UpdateObservationsReporte";
import TableReport from "./reportesExcel/TableReport";
import ModalBasic from "../../components/Modals/ModalBasic";
import ExportExcel from "./reportesExcel/ExportExcel";
import ModalLarge from "../../components/Modals/ModalLarge/ModalLarge";
import TablaNotaDiscente from "../../components/Tablas/TablaNotaDiscente";
import MensajeLoading from "../../components/MensajeLoading";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import * as moment from "moment";

import "./generalPosventa.scss";
//Redux
import { connect, useSelector } from "react-redux";
import {
  listarProyectos,
  listarSesionesCursos,
} from "../../../actions/cursosActions";
import {
  listarReporteGeneralPosventa,
  listarNotasDiscente,
  actualizarEstadoDesertado
} from "../../../actions/reportesActions";
import useSelectProyecto from "../../../hooks/useMultiSelectProyectos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const GeneralPosventa = (props) => {
  //State redux
  const { cantidadesTotales , reporte, loading } = props;

  //Funciones
  const {
    listarReporteGeneralPosventa,
    listarNotasDiscente,
    actualizarEstadoDesertado
  } = props;

  const [dataReporte, setDataReporte] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalLarge, setShowModalLarge] = useState(false);
  const [reporteEstudiante, setReporteEstudiante] = useState({});
  const [proyecto, SelectProyecto] = useSelectProyecto(null)
  const { usuario } = useSelector(state => state.usuario);
  //Guardar Reporte para editar
  const guardarReporte = (report) => {
    setReporteEstudiante(report);
    setShowModal(true);
  };

  //Ocultar modal
  const updateShowModal = (value) => {
    setShowModal(value);
  };

  const verNotasSesiones = (idMatriculaDiscente) => {
    listarNotasDiscente(idMatriculaDiscente);
    updateModalLarge(true);
  };

  const updateModalLarge = (value) => {
    setShowModalLarge(value);
  };


  const llenarDataReporte = async () => {
    let dataGenerado = [];
    reporte.map((report, index) => {
      dataGenerado = [
        ...dataGenerado,
        {
          id: index + 1,
          id_matricula_proyecto: report.id_matricula_proyecto,
          color_estado: report.color_estado,
          proyecto: report.nombre_proyecto,
          nombre: report.nombre_usuario,
          apellido: report.apellido_usuario,
          correo: report.email,
          telefono: report.celular,
          importe_deuda: (report.importe_deuda).toFixed(2),
          cant_total_sesiones: report.cant_total_sesiones,
          cant_se_realizada: report.cant_se_realizada,
          cant_asistidas: report.cant_asistidas,
          promedio_optimo: report.promedio_optimo,
          promedio_final: report.promedio_final,
          fecha_edicion: report.fecha_edicion
            ? moment(report.fecha_edicion).format("DD MMM YYYY")
            : "---",
          hora_edicion: report.fecha_edicion
            ? moment(report.fecha_edicion).format("LTS")
            : "---",
          turno: report.turno,
          estado_pago: report.pago,
          observaciones: report.observaciones || "No hay observaciones",
          estado_academico:
            report.id_estado_est === 1
              ? "Activo"
              : report.id_estado_est === 2
              ? "Ambar"
              : report.id_estado_est === 3
              ? "Desertado"
              : "",
          button: (
            <>
              <Button
                type="button"
                variant='info'
                size='xs'
                onClick={() => guardarReporte(report)}
              > 
                <FontAwesomeIcon icon={faEdit}/>
              </Button>
            </>
          ),
        },
      ];
    });

    setDataReporte(dataGenerado);
  };

  //Listar sesiones del proyecto cuando se seleccione un proyecto
 /* useEffect(() => {
    if (proyecto && Object.keys(proyecto).length > 0) {
     // console.log(proyecto);
     listarReporteGeneralPosventa(proyecto);
    }
  }, [proyecto]);*/

const consultarSeguimiento = () =>{
   // if (proyecto && Object.keys(proyecto).length > 0) {
     console.log(proyecto);
     listarReporteGeneralPosventa(proyecto);
  //  }
}


  useEffect(() => {
   llenarDataReporte();
  }, [reporte]);

  return (
    <Layout>
      <HeaderContent title="Progreso" icon="insert_chart_outlined" subItem={usuario.id_tp_nivel_usuario==='5'? 'Imagina si nuestros estudiantes terminaran todo lo que comenzaran: ¿Cómo cambiarías sus vidas?': null} />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "Progreso" },
        ]}
      />
      <div className="general-posventa">
        <div className="row content-select-curso my-4">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 filtro">
                <label htmlFor="">Producto</label>
                <SelectProyecto/>
               </div>
              <div className="col-md-6 pt-4">
                <button className="btn btn-primary" onClick={consultarSeguimiento} > <i className="fa fa-search" style={{marginRight:'5px'}}></i>Consultar</button>
              </div>
              <div className="col-md-4 filtro"></div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>

        {loading !== null ? (
          <>
            <SpinnerLoading loading={loading}>
              <div className="box box-secondary">
                <h5
                  className="p-3 d-flex align-items-center mb-0"
                  style={{ color: "#434343" }}
                >
                  <i className="fas fa-list pr-2"></i>Lista de estudiantes
                </h5>
                <div className="box-body">
                <TableReport
                  rows={dataReporte}
                  verNotasSesiones={verNotasSesiones}
                />
                 <div style={{marginTop:'15px', padding:'8px' , display:'flex'}}>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total de Deuda:</span> S/. {cantidadesTotales.total_deuda}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total de Discentes:</span> {cantidadesTotales.cant_discentes}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total Rojos:</span> {cantidadesTotales.discentes_rojo}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total Ambar:</span> {cantidadesTotales.discentes_ambar}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total Verde:</span> {cantidadesTotales.discentes_verde}
                    </div>
                 </div>
              </div>
             
              </div>
             
            </SpinnerLoading>
          </>
        ) : (
          <MensajeLoading />
        )}
      </div>
      <ModalLarge
        title="Notas"
        show={showModalLarge}
        updateModalLarge={updateModalLarge}
      >
        <TablaNotaDiscente />
      </ModalLarge>
      <ModalBasic
        show={showModal}
        updateShowModal={updateShowModal}
        title="Reporte estudiante"
      >
        <UpdateObservationsReporte reporte={reporteEstudiante} proyectos={proyecto} />
      </ModalBasic>
      <ExportExcel />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  cantidadesTotales: state.reporte.cantidadesTotales,
  reporte: state.reporte.reporte,
  proyectos: state.cursos.proyectos,
  sesionesCurso: state.cursos.sesionesCurso,
  loading: state.reporte.loading,
});

const mapDispatchToProps = {
  listarProyectos,
  listarSesionesCursos,
  listarReporteGeneralPosventa,
  listarNotasDiscente,
  actualizarEstadoDesertado
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPosventa);
