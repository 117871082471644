import {
    LISTAR_POP_UP,
    ACTUALIZAR_POP_UP,
    ELIMINAR_POP_UP,
    OBTENIENDO_POPUP,
    OBTENIENDO_POPUP_ERROR,
    ACTUALIZAR_POP_UP_ERROR,
    CLOSE_MODAL_LARGE,
    MOSTRAR_COMUNICADO,
    LISTAR_FONDOS_INTRANET,
    comunicadostypes
  
  } from "./types";
  
  
  import authAxios from "../config/authAxios";
  import response from '../config/response';
  import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
  import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalBasic } from "./modalActions";
  export const listarpopup = () => {

    return async dispatch => {
        dispatch({
          type: OBTENIENDO_POPUP
        })

        try {
    
            const popup = await authAxios.get(
                `/admin/verPagoCercano/`
              );
            // console.log(popup.data.data);
            
              dispatch({
                payload: popup.data.data,
                type: LISTAR_POP_UP
              });
        
        } catch (error) {
          response.error(error, dispatch,OBTENIENDO_POPUP_ERROR)
        }
      }


 
  };

  export const listarFondosPuIntranet = () => {

    return async dispatch => {
        try {
    
            const popup = await authAxios.get(
                `/admin/fondosPopUpIntranet/`
              );
            // console.log(popup.data.fondos);
            
            dispatch({
                payload: popup.data.fondos,
                type: LISTAR_FONDOS_INTRANET
              });
        
        } catch (error) {
          response.error(error, dispatch,OBTENIENDO_POPUP_ERROR)
        }
      }


 
  };

  export const listarComunicados = () => {

    return async dispatch => {
        dispatch({
          type: comunicadostypes.LISTAR_COMUNICADOS
        });
        try {
    
            const comunicados = await authAxios.get(
                `/admin/listarComunicados/`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: comunicados.data.data,
              type: comunicadostypes.LISTAR_COMUNICADOS_EXITO
            });
        
        } catch (error) {
          avisoError('Hubo un error al listar los comunicados')
        }
      }


 
  };
  export const listarUltimosComunicados = () => {

    return async dispatch => {
        dispatch({
          type: comunicadostypes.LISTAR_COMUNICADOS
        });
        try {
    
            const comunicados = await authAxios.get(
                `/admin/listarUltimosComunicados/`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: comunicados.data.data,
              type: comunicadostypes.LISTAR_COMUNICADOS_EXITO
            });
        
        } catch (error) {
          avisoError('Hubo un error al listar los comunicados')
        }
      }


 
  };
 
  export const ultimoComunicado = () => {

    return async dispatch => {
      
        try {
    
            const comunicado = await authAxios.get(
                `/admin/getUltimoComunicado/`
              );
            // console.log(comunicado.data);
            
             return comunicado.data
           
        
        } catch (error) {
          avisoError('Hubo un error al obtener el comunicado')
        }
      }


 
  };
 
  export const eliminarComunicado = (id) => {

    return async dispatch => {
      const datos={
        id_comunicado:id
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Estas seguro?",
          text: "Se eliminara todo registro",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
            try {
    
              const comunicado = await authAxios.post(
                  `/admin/eliminarComunicado/`,datos
                );
              // console.log(comunicado.data);
              dispatch({
                payload: comunicado.data.data,
                type: comunicadostypes.LISTAR_COMUNICADOS_EXITO
              })
              return true
             
          
          } catch (error) {
            avisoError('Hubo un error al eliminar el comunicado')
          }
        },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          // console.log(result)
          if (result.value === true) {
          
            swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Curso se elimino correctamente",
              "success"
            );
           
          }
        });
  };
}

  export const updateMostrarComunicado = () => {
    return (dispatch, getState) => {
  
      const { show } = getState().popUp
  

       dispatch({
          type: MOSTRAR_COMUNICADO,
          payload: {
            show: !show
          }
        })
      
    }
  }
  
  
  export const actualizarpopup = (datos) => async dispatch => {
  

     // console.log(datos);

   try {
      let respuesta = await authAxios.post(`/admin/actualizarPopUp/`,datos);
     // console.log(respuesta.data)
      if (respuesta && respuesta.data.status === "ok") {
        avisoCorrecto('Se modificó el pop-up correctamente')
      }
      else
      {
        avisoError('No se pudo modificar el pop-up')
      }

    } catch (error) {
     
    }
  };
  
  export const subirFondoPopUp = (archivo) => async dispatch => {
  

    // console.log(datos);
    const file = new FormData();
    file.append('archivo', archivo);


    try 
    {
          avisoCargando('Subiendo imagen, espere por favor...')
          const subida = await authAxios.post(
                  `/admin/subirFondoPopUp`,file,{headers:{'Context-type':'multipart/form-data'}}
                );
                
            
          // console.log(subida)
            dispatch(updateStateModalBasic())
            dispatch(listarFondosPuIntranet())
    } catch (error) {
          console.log(error)
          avisoError('Hubo un error al importar los leads')
        }
 };
 
  
  export const insertarComunicado = (datos) => async dispatch => {
  

    // console.log(datos);

  try {
     let respuesta = await authAxios.post(`/admin/insertarComunicado/`,datos);
     if (respuesta.data) {
        dispatch({
          type: CLOSE_MODAL_LARGE
        })
     
        dispatch({
          payload: respuesta.data.data,
          type: comunicadostypes.LISTAR_COMUNICADOS_EXITO
        })
      avisoCorrecto('Se guardó correctamente')
     }
     else
     {
       avisoError('No se pudo guardar el comunicado')
     }

   } catch (error) {
    avisoError('No se pudo guardar el comunicado')
   }
 };
 
  export const eliminarpopup = (popup) => async dispatch => {
  
    let respuesta = await authAxios.put(`/admin/eliminarMensajeId`, popup);
   // console.log(popup)
   // console.log(respuesta.data)
    if (
      respuesta.data.mensaje === "SE MOSTRO CON EXITO LA PAPELERA" ||
      respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR'
    ) {
      dispatch({
        payload: respuesta.data.datos,
        type: ELIMINAR_POP_UP
      });
      return { mensaje: true };
    }
  
    return{mensaje: false}
  } 
  