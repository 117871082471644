import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './DatosGeneralesFactura.scss';

const DatosGeneralesFactura = ({ datosFactura = {} }) => {

  const retornarComponenteMediosPagos = () => {
    if(datosFactura.medio_pago === 'Banco'){
      return <MetodoBanco detalle={datosFactura.detalle} />
    }
    if(datosFactura.medio_pago === 'Cheque') {
      return <MetodoCheque detalle={datosFactura.detalle}/>
    }
    if(datosFactura.medio_pago === 'Tarjeta') {
      return <MetodoTarjeta detalle={datosFactura.detalle}/>
    }
  }

  return ( 
    <div className="DatosGeneralesFactura">
      <h4 className="title-head">Datos Generales</h4>
        <div className="box datos-recibo">
          <div className="box-body">
            <Row>
              <Col md={4}>
                <p>Nombre y apellidos: <span>{datosFactura.nombre_discente}</span></p>
              </Col>
              <Col md={8}>
                <p>Evento: <span>{datosFactura.nombre_proyecto}</span></p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={4}>
                <p>Concepto: <span>{datosFactura.concepto}</span></p>
              </Col>
              <Col md={4}>
                <p>Área: <span>{datosFactura.tp_area}</span></p>
              </Col>
              <Col md={4}>
                <p>Fecha: <span>{datosFactura.fecha}</span></p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={4}>
                <p>Medio de pago: <span>{datosFactura.medio_pago}</span></p>
              </Col>
              {
                datosFactura.detalle && retornarComponenteMediosPagos() 
              }
            </Row>
            <Row className='mt-3'>
              <Col md={12}>
                <p>Observaciones: <span>{datosFactura.observaciones}</span></p>
              </Col>
            </Row>
          </div>
        </div>
    </div>
  );
}
 
export default DatosGeneralesFactura;

const MetodoCheque = ({ detalle = {} }) => {
  return (
    <>
      <Col md={4}>
        <p>Nombre Banco: <span>{detalle.nombre_banco}</span></p>
      </Col>
      <Col md={4}>
        <p>N° Operación: <span>{detalle.nro_cheque}</span></p>
      </Col>
    </>
  )
}

const MetodoBanco = ({ detalle = {} }) => {
  return (
    <>
      <Col md={4}>
        <p>Nombre Banco: <span>{detalle.nombre_banco}</span></p>
      </Col>
      <Col md={4}>
        <p>N° Operación: <span>{detalle.vaucher}</span></p>
      </Col>
    </>
  )
}


const MetodoTarjeta = ({ detalle = {} }) => {
  return (
    <>
      <Col md={4}>
        <p>N° Operación: <span>{detalle.ticket}</span></p>
      </Col>
    </>
  )
}