import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarRegistroTrabajador } from '../../actions/trabajadoresActions';

const withDatosPersonalesEditar = WrappedComponent => props => {
  
  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleSubmitAction = (datos) => {
    dispatch( editarRegistroTrabajador(datos, idTrabajador) );
  }

  return ( 
    <WrappedComponent
      {...props}
      docenteState={trabajador}
      handleSubmitAction={handleSubmitAction}
      editar={true}
    />
   );
}
 
export default withDatosPersonalesEditar;