import {
  OPEN_MODAL_BASIC,
  CLOSE_MODAL_BASIC,
  OPEN_MODAL_LARGE,
  CLOSE_MODAL_LARGE,
  ACTUALIZAR_CHILDREN_MODAL_LARGE,
  OPEN_MODAL_CUSTOM,
  CLOSE_MODAL_CUSTOM,
} from './types';

export const updateStateModalBasic = (Component, title) => {
  return (dispatch, getState) => {

    const { show } = getState().modal.modalBasic

    if (!show) {
      dispatch({
        type: OPEN_MODAL_BASIC,
        payload: {
          show: !show,
          Component,
          title
        }
      })
    } else {
      dispatch({
        type: CLOSE_MODAL_BASIC
      })
    }
  }
}

export const updateStateModalLarge = (Component, title) => {
  return (dispatch, getState) => {
    
    const { show } = getState().modal.modalLarge

    if (!show) {
      dispatch({
        type: OPEN_MODAL_LARGE,
        payload: {
          show: !show,
          Component,
          title
        }
      })
    } else {
      dispatch({
        type: CLOSE_MODAL_LARGE
      })
    }
  }
}

export const updateStateModalCustom = (Component, title, customWidth) => {
  return (dispatch, getState) => {
    
    const { show } = getState().modal.modalCustom

    if (!show) {
      dispatch({
        type: OPEN_MODAL_CUSTOM,
        payload: {
          show: !show,
          Component,
          title,
          customWidth
        }
      })
    } else {
      dispatch({
        type: CLOSE_MODAL_CUSTOM
      })
    }
  }
}

