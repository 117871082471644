import React, {Component} from 'react'
import {cambiarAside} from '../helpers/functionSidebar'
export default class Navigation extends Component {

    render() {
        return (
          <div>
            {/* top navigation */}
            <div className="top_nav navigation">
              <div className="nav_menu" style={{background: 'none'}}>
                <div className="nav toggle">
                  <a href='#' id="menu_toggle" onClick={e => {e.preventDefault(); cambiarAside()}}>
                    <i className="fa fa-bars"></i>
                  </a>
                </div>
                <nav className="nav navbar-nav">
                  <ul className=" navbar-right">
                    <li
                      className="nav-item dropdown open"
                      style={{
                        paddingLeft: "15px"
                      }}
                    >
                      
                      <div
                        className="dropdown-menu dropdown-usermenu pull-right"
                        aria-labelledby="navbarDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <span className="badge bg-red pull-right">50%</span>
                          <span>Settings</span>
                        </a>
                        <a className="dropdown-item" href="#">
                          Help
                        </a>
                        <a className="dropdown-item" href="login.html">
                          <i className="fa fa-sign-out pull-right" />
                          Log Out
                        </a>
                      </div>
                    </li>
                    <li role="presentation" style={{minHeight: "20px"}} className="nav-item dropdown open">
                      {/* <button
                        type='button'
                        className="btn btn-sm btn-logout"
                        id="btn-logout"
                      >
                        Cerrar sesión
                      </button>
                       */}
                       {this.props.children}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/* /top navigation */}
          </div>
        );
    }
}
