import React from 'react';
import { useParams } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { guardarEditarPasswordDocente } from '../../actions/profesoresActions';

const withEditarPasswordSesion = WrappedComponent => props => {
  
  const { idDocente } = useParams();
  const dispatch = useDispatch();

  const handleEditarPassword = nuevoPassword => {
    dispatch( guardarEditarPasswordDocente(nuevoPassword, idDocente) );
  }

  return ( 
    <WrappedComponent
      {...props}
      handleEditarPassword={handleEditarPassword}
    />
   );
}
 
export default withEditarPasswordSesion;