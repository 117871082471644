import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/vendors/bootstrap/dist/css/bootstrap.min.css';
import './assets/vendors/font-awesome/css/font-awesome.min.css';
import './assets/css/custom.css';
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
//REDUX
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);


