import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const DatosMatriculasResumen = ({ matricula }) => {

  const { nombre_proyecto, fecha_matricula, nombre_tp_programa } = matricula;

  return ( 
    <div className="DatosMatriculasResumen">
      <div className="box-header with-border">
        <h6 className='title-head mb-0'>
          <FontAwesomeIcon icon={faListAlt} />
          Datos de la matrícula
        </h6>
      </div>
      <div className="box-body py-4">
        <div className="datos-matricula px-4">
          <Row>
            <Col md={4}>
              <p className='font-weight-bold'>Nombre del Proyecto</p>
            </Col>
            <Col md={8}>
              <p>{nombre_proyecto}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className='font-weight-bold'>Fecha de matrícula</p>
            </Col>
            <Col md={8}>
              <p>{fecha_matricula}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
 
export default DatosMatriculasResumen;