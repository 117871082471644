import React, { useState } from 'react';
import Layout from '../../../../app/Layout';
import TablaFactura from '../../../components/Finanzas/TablaFacturas';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';

// Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { obtenerFacturas } from '../../../../actions/finanzasReportesActions';
import FormularioFiltroReporte from '../../../components/Finanzas/FormularioFiltroReporte';

const Facturas = () => {

  const { loading, error, facturas } = useSelector(state => state.finanzasReportes);
  const [datosBusqueda, setDatosBusqueda] = useState(null);

  const dispatch = useDispatch();

  const handleObtener = valores => {
    dispatch(obtenerFacturas(valores));
  }

  return (
    <Layout>
      <div className="Facturas">
        <div className="Facturas__head">
          <HeaderContent title='Facturas' />
          <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/facturas", nombre: "Facturas" },
              ]}
            />
        </div>
        <div className="Facturas__body">
          <div className="title-head">
            <h4>Formulario de filtro</h4>
          </div>
          <FormularioFiltroReporte
            onSubmit={handleObtener}
            setValores={setDatosBusqueda}
          />
          <div className="mt-3">
            <SpinnerLoading loading={loading} error={error}>
              {
                facturas.todos.length > 0 && <TablaFactura datosBusqueda={datosBusqueda}/> 
              }
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout> 
    
  );
}
 
export default Facturas;