import React, { useState } from 'react';
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {verExamen, guardarRespuestasExamen} from '../../../actions/examenesActions'
import { useDispatch } from 'react-redux';
import ListarPreguntasExamen from './components/ListarPreguntasExamen';
import { Card, Spinner } from "react-bootstrap";
const VistaPreviaExamen = () => {
    const { idExamen } = useParams();
    const dispatch = useDispatch();
   
    const initialStatePreguntas = () =>({
        id_examen:idExamen,
        respuestas_marcadas:[]
    })
    const [examen_vista, setExamen] = useState(null);
    const [respuestas, setRespuestas] = useState(initialStatePreguntas());

    useEffect(()=>{
        async function fetchData() {
            // You can await here
            const response = await dispatch(verExamen(idExamen));
            setExamen(response.examen);
            // ...
          }
          fetchData();
        
    },[idExamen])

    const handleChange = (event) =>{
            let arregloActualizado = [];
            let respuesta_sel = {id:event.target.id,pregunta:event.target.name,respuesta:event.target.value};
        
            const copiaEliminar = [...respuestas.respuestas_marcadas];
            arregloActualizado = copiaEliminar.filter(respuesta => respuesta.pregunta !== respuesta_sel.pregunta);
            const copiaAgregar = [...arregloActualizado, respuesta_sel];
            setRespuestas({
                ...respuestas,
                respuestas_marcadas: copiaAgregar,
            });
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        dispatch(guardarRespuestasExamen(respuestas))   
    }
    return (

        <Layout>
        <HeaderContent title="Vista Previa" />
        <Breadcrumb
          link={[
            { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
            { url: "/examenes", nombre: "Examenes", icon: "fas fa-edit" },
            { url: "/examenes/vista-previa", nombre: "Vista Previa" }
          ]}
        />
        <div className="content">
        { examen_vista ? examen_vista.length>0 ?
          <div style={{maxWidth:"550px", margin:"auto"}}>
            <Card >
            
                <Card.Header>
                <h3>{examen_vista ? examen_vista[0].pregunta.nombre_examen : ''}</h3>
                <p>{examen_vista ? examen_vista[0].pregunta.descripcion_examen: ''}</p>
                </Card.Header>
                <Card.Body>
                    <form id="examen" onSubmit={handleSubmit}> 

                    { examen_vista && <ListarPreguntasExamen preguntas={examen_vista} handleChange={handleChange}/>}
                
                    {
                      //<button type="submit" className="btn btn-primary">Enviar</button>
                    }
                    </form>
                </Card.Body>
              
            </Card>
          </div>
       : <> No hay Datos </>: <> Cargando Datos... <Spinner animation="border" /> </>}
         
        </div>
      
      </Layout>

    );
};

export default VistaPreviaExamen;