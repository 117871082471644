import React from 'react';
import { Button } from 'react-bootstrap';
import MaterialTableDemo from '../../plugins/MaterialTableDemo';
import FormControlModulosProyetos from '../FormControlModulosProyetos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './ModulosProyectosAsociados.scss';
import { useDispatch } from 'react-redux';
import { eliminarModuloProyecto } from '../../../../actions/cursosActions';

const ModulosProyectosAsociados = ({ modulos = [], onShowModal, onCloseModal }) => {

  const dispatch = useDispatch();

  const acciones = (idModuloProyecto) => <>
    <Button
      variant='danger'
      size='xs'
      onClick={() => dispatch(eliminarModuloProyecto(idModuloProyecto))}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </>

  return (
    <div className="ModulosProyectosAsociados">
      <div className='ModulosProyectosAsociados__accion'>
        <Button
          variant='success'
          onClick={() => onShowModal(<FormControlModulosProyetos
            onCloseModal={onCloseModal}
          />, 'Módulos')}
        >
          Agregar
        </Button>
      </div>
      <div className="ModulosProyectosAsociados__tabla">
        <MaterialTableDemo
          colums={columns(acciones)}
          tabla={modulos}
          addConfig={{ key_column: 'id_proyecto_modulo' }}
        />
      </div> 
    </div>
  );
}
 
export default ModulosProyectosAsociados;

const columns = (acciones) => [
  {
    key: '',
    text: '#',
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    key: 'nombre_modulo',
    text: 'Nombre de módulo',
  },
  {
    key: 'fecha_inicio_modulo',
    text: 'Fecha incio',
    className: 'text-center'
  },
  {
    key: 'fecha_fin_modulo',
    text: 'Fecha fín',
    className: 'text-center'
  },
  {
    key: 'acciones',
    text: 'Acciones',
    width: 90,
    className: 'text-center',
    cell: record => acciones(record.id_proyecto_modulo)
  }
]
