import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import CardEvento from '../CardEvento';

//Redux
import { connect } from 'react-redux';

import './ListaEventos.scss'
import ModalLarge from '../Modals/ModalLarge/ModalLarge';
import SpinnerLoading from '../Spinners/SpinnerLoading';

const ListaEventos = (props) => {

  const { eventosTutor, loadingReportes } = props

  const [show, setShow] = useState(false)
  const [listaReporte, setListaReporte] = useState(null)
  
  const updateShowModal = (valor) => {
    setShow(valor);
  };

  return ( 
    <Container fluid className='lista-eventos px-0'>
      <Row>
        {eventosTutor.eventos_tutor.map(evento => (
          <Col md={4} key={evento.id_proyecto}>
            <CardEvento
              evento={evento}
              updateShowModal={updateShowModal}
              setListaReporte={setListaReporte}
            />
          </Col>
        ))}
      </Row>
      <ModalLarge
        show={show}
        updateModalLarge={updateShowModal}
        title='Reportes'
      >
        <SpinnerLoading loading={loadingReportes}>
          { listaReporte }
        </SpinnerLoading>
      </ModalLarge>
      
    </Container>
   );
}

const mapStateToProps = state => ({
  eventosTutor: state.tutores.eventosTutor,
  loadingReportes: state.encuestas.loadingReportes
}) 
 
export default connect(mapStateToProps, {})(ListaEventos);