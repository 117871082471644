import React from 'react';
import ListDocenteItem from '../RelacionDocentes/ListDocenteItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  guardarTentativoConfirmado,
  eliminarDocenteTentativoConfirmado
} from "../../../../../actions/controlCapacitacionesActions";

const ListaDocenteTentativos = () => {

  const { idCapacitacion } = useParams();
  const sesionActivo = useSelector(state => state.control_capacitacion.area_trabajo.activo)
  const { docentes_tentativos } = sesionActivo;
  const dispatch = useDispatch();

  const handleTentativoConfirmado = ({ id_filtro_sesion }) => {
    dispatch( guardarTentativoConfirmado(id_filtro_sesion, idCapacitacion) )
  }

  const handleEliminarRelacion = ({ id_filtro_sesion }) => {
    dispatch( eliminarDocenteTentativoConfirmado(id_filtro_sesion) )
  }

  return ( 
    <div className="lista-docentes-tentativos relacion-docentes">
      <p className='acciones-tabla-control__subtitle'>
        Docentes tentativos / Jurado / Clausura
      </p>
      <div className='lista-docentes__scroll'>
        {docentes_tentativos.length ? (
        <ListDocenteItem
          docentes={docentes_tentativos}
          render={(docente) => (
            <div>
              <button 
                className='btn-danger btn btn-sm'
                onClick={() => handleEliminarRelacion(docente)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <button 
                className='btn-success btn btn-sm'
                onClick={() => handleTentativoConfirmado(docente)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button> 
            </div>
          )}
        />) : (
          <p>No hay docentes para esta sesión.</p>
        )}
      </div>
    </div>
   );
}
 
export default ListaDocenteTentativos;