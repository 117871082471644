import { avisoError } from "../view/components/AvisosMensajes/MensajesSwalf"

export const error = (error, dispatch, type) => {
  console.log(error);
  if(error.response !== undefined) {
    if(error.response.data) {
      avisoError(error.response.data.msg);
      if(dispatch) {
        dispatch({
          type: type,
          payload: error.response.data.msg
        })
      }
      return;
    }
    avisoError('Error inesperado, intente más tarde.');
    if(dispatch) {
      dispatch({
        type: type,
        payload: 'Error inesperado, intente más tarde.'
      })
    }
    return;
  }

  if(!error.status) {
    if(error.message === 'Network Error') {
      avisoError('Error inesperado, consulta no encontrada.')
      if(dispatch) {
        dispatch({
          type: type,
          payload: 'Error inesperado, consulta no encontrada.'
        })
      }
    } else {
      avisoError('Error inesperado, intente más tarde.')
      if(dispatch) {
        dispatch({
          type: type,
          payload: 'Error inesperado, intente más tarde.'
        })
      }
    }
  }
}

export default {
  error
}