import React from 'react'

const MenuMensajeria = ({vista, setVista, setMensajesSeleccionados}) => {
    return (
      <div className="">
        <div className="header-menu d-flex justify-content-end">
          <button
            onClick={() => setVista("nuevoMensaje")}
            type="button"
            className="btn btn-primary btn-block"
          >
            Crear mensaje
          </button>
        </div>

        <ul className="list-group mt-3">
          <li
            onClick={() => {
              setVista("bandejaEntrada")
              if(vista !== 'bandejaEntrada'){
                setMensajesSeleccionados([])
              }
            }}
            style={{cursor: 'pointer'}}
            className="py-1  d-flex justify-content-between text-dark align-items-center"
          >
            <p className="d-flex mb-0 align-items-center">
              <i className="material-icons">inbox</i>Recibidos
            </p>
            {/* <span className="badge badge-primary badge-pill">14</span> */}
          </li>
          <li
            onClick={() => {
              setVista("enviados")
              if(vista !== 'enviados') {
                setMensajesSeleccionados([])
              }
            }}
            style={{cursor: 'pointer'}}
            id = 'menu-enviados'
            className="border-top py-1  d-flex text-dark justify-content-between align-items-center"
          >
            <p className="d-flex mb-0 align-items-center">
              <i className="material-icons">markunread</i>Enviados
            </p>
            {/* <span className="badge badge-primary badge-pill">2</span> */}
          </li>
          <li
            style={{cursor: 'pointer'}}
            className="border-top py-1 border-bottom  d-flex text-dark justify-content-between align-items-center"
            onClick={() => {
              setVista('papelera')
              if(vista !== 'papelera') {
                setMensajesSeleccionados([])
              }
            }}
            >
            <p className="d-flex mb-0 align-items-center">
              <i className="material-icons">delete_sweep</i>Papelera
            </p>
            {/* <span className="badge badge-primary badge-pill">1</span> */}
          </li>
        </ul>
      </div>
    );
}
 
export default MenuMensajeria;