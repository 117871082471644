import React from "react";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Col, Row } from "react-bootstrap";
import ListaConceptos from "./ListaConceptos";

const DatosFinanzasResumen = ({ matricula }) => {
  const { nombre_plan, tp_modalidad, tp_plan, pension: conceptos } = matricula;

  return (
    <div className="DatosFinanzasResumen">
      <div className="box-header with-border mb-4">
        <h6 className="title-head mb-0">
          <FontAwesomeIcon icon={faMoneyBillAlt} />
          Resumen de finanzas
        </h6>
      </div>
      <div className="box-body py-2">
        <div className="px-4">
          <Row>
            <Col md={4}>
              <p className="font-weight-bold">Nombre del plan</p>
            </Col>
            <Col md={8}>
              <p>{nombre_plan}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className="font-weight-bold">Modalidad</p>
            </Col>
            <Col md={8}>
              <p>{tp_modalidad}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p className="font-weight-bold">Tipo de plan</p>
            </Col>
            <Col md={8}>
              <p>{tp_plan}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="font-weight-bold mb-3">Detalles de conceptos</p>
              <ListaConceptos
                conceptos={conceptos}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DatosFinanzasResumen;
