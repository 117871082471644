import { useEffect, useState } from 'react';

const useProgress = (label, indicadores) => {

  const [porcentaje, setPorcentaje] = useState();
  const [totalCompletado, setTotalCompletado] = useState();

  const updateProgress = (indicadoresUpdate) => {
    
    const indicadorUsing = indicadores || indicadoresUpdate;

    const totalIndicadores = indicadorUsing.length;
    let indicadoresCompletados = 0;
    [...indicadorUsing].forEach(element => {
      if (element.estado === label) {
        indicadoresCompletados++;
      }
    });
    setPorcentaje((indicadoresCompletados/totalIndicadores)*100);
    setTotalCompletado(indicadoresCompletados);
  }

  useEffect(() => {
    updateProgress();
  }, [indicadores, updateProgress])
  
  return [porcentaje, updateProgress, totalCompletado]
}
 
export default useProgress;