import React from 'react';
import { Table } from 'react-bootstrap';

const TablaResumenPension = ({ totalDescuento, totalCargos, totalCuota, saldo }) => {
  
  return ( 
    <div style={{marginTop: '15px'}} className="TablaResumenPension box p-0">
      <Table>
        <tbody>
          <tr>
            <td className='w-50'>Total descontado (S/):</td>
            <td><b>{isNaN(totalDescuento) && '- '}{totalDescuento}</b></td>
          </tr>
          <tr>
            <td className='w-50'>Total de cargos Variables (S/):</td>
            <td><b>{isNaN(totalCargos) && '+ '}{totalCargos}</b></td>
          </tr>
          <tr>
            <td className='w-50'>Total (S/):</td>
            <td><b>{totalCuota}</b></td>
          </tr>
          { saldo && <tr>
            <td className='w-50'>Saldo (S/):</td>
            <td><b>{saldo}</b></td>
          </tr> }
        </tbody>
      </Table>
    </div>
  );
}
 
export default TablaResumenPension;