import React from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb';
import ListaEventos from '../../../components/ListaEventos';
import { useParams } from 'react-router-dom'

//Redux
import { connect } from 'react-redux';
import { obtenerEventosTutor } from '../../../../actions/tutoresActions';
import { useEffect } from 'react';

const TutoresInfo = props => {

  const params = useParams()
  const { idTutor } = params
  const { eventosTutor, obtenerEventosTutor } = props

  useEffect(() => {
    obtenerEventosTutor(idTutor)
  }, [])

  return ( 
    <Layout>
      <HeaderContent
        title='Tutor'
        subItem={`${eventosTutor.nombre_usuario} ${eventosTutor.apellido_usuario}`}
      />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/tutores", nombre: "Tutores" },
          { url: '', nombre: 'Tutor detalles'}
        ]}
      />
      <div className="tutores-info">
        <ListaEventos/>
      </div>
    </Layout>
   );
}
 
const mapStateToProps = state => ({
  eventosTutor: state.tutores.eventosTutor
})

const mapDispatchToProps = {
  obtenerEventosTutor
}

export default connect(mapStateToProps, mapDispatchToProps)(TutoresInfo);