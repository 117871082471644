import $ from 'jquery'
import axios from 'axios'
import Swal from 'sweetalert2'
import { avisoError } from '../view/components/AvisosMensajes/MensajesSwalf';

var proggresCompleted = 0;
const totalProgreso = 2;

export const setJwt = (data) => {
  window.sessionStorage.setItem('adm-JWT', JSON.stringify(data).replace(/['"]+/g, ''))
}

export const getJWT = () => {
    return window.sessionStorage.getItem('adm-JWT') || '';
}

export const deleteJWt = () => {
    return window.sessionStorage.removeItem('adm-JWT');
}

export const getDecode = async (Jwt) => {
    try {
      const decode = await axios.get(
        `${process.env.REACT_APP_API_URL}/decode`,
        {
          headers: { Authorization: `Bearer ${Jwt}` }
        }
      );
      if (decode.data.datos) {
        let datosCo = {
          usuario: decode.data.datos[0],
          detalleNivel: decode.data.detalleNivel
        };
        return datosCo;
      } else {
        return { error: "Hay error" };
      }
    } catch (error) {
      console.log(error);
      if (error.response !== undefined) {
        if (error.response.status === 403) {
          return { error: error.response.status };
        }
      }
    }
}

export const actualizarAvance = () => {
    const barraAvance = document.getElementById('barra-avance')
    
    if (proggresCompleted < 2) {
        proggresCompleted++
        const avance = Math.round((proggresCompleted / totalProgreso) * 100)
        barraAvance.style.width = `${avance}%`
    }

    return (proggresCompleted)
}

//Muestra los errores de la api
export const mostrarErrorApi = error => {
  
  if (error.response !== undefined) {
    avisoError(`Hubo un error: ${error.response.data.msg}`)
    return;
  }
  avisoError(`Hubo un error inesperado`);
}

/**
 * Funcion que me retorna el colore de la fila de la tabla luego
 * de evaluar el estado del pago
 */
export const getColorEstadoPago = (estado) => {
  switch (estado) {
    case 'PAGADO':
      return 'success';
    case 'PENDIENTE ':
      return 'warning';
    case 'MOROSO':
      return 'danger';
    default:
      return estado;
  }
}

/**
 * Funcion que me retorna el colore de la fila de la tabla luego
 * de evaluar el estado de la matrícula
 */
export const getColorEstadoMatricula = (estado) => {
  switch (estado) {
    case 'Activo':
      return 'success';
    case 'Desertado':
      return 'danger';
    default:
      return estado;
  }
}

export const cancelarAvance = () => {
    const barraAvance = document.getElementById('barra-avance')
    barraAvance.style.width = "0"
    proggresCompleted=0
}

export const ocultarModal = () => {
    const btnClose = document.getElementById('button-close-modal')
    const formAgregar = document.getElementById('form-agregar')
    if (btnClose && formAgregar) {
        btnClose.click()
        formAgregar.reset()
    }
}

export const buscarId = valor => {
    const elemento = document.getElementById(`${valor}`)

    return elemento
} 

export const limpiarForm = id => {
    const form = document.getElementById(`${id}`)
    form.reset()
}
export const buscarClass = className => {
    const elementos = Array.from(document.getElementsByClassName(`${className}`))
    return elementos
}

export const limpiarInputCostoMensualidad = () => {
    const inputPrecioP = document.getElementById('costo-mensualidad-publico')
    const inputPrecioC = document.getElementById('costo-mensualidad-corporativo')
    const fecha = Array.from(document.querySelectorAll('.fecha-v'))

    fecha.map(item => {
        item.value = ''
    })
    
    inputPrecioP.value = ''
    inputPrecioC.value = ''

}

export const redirigir = (idCurso) => {
    // useHistory().push(`/cursos/${idCurso}`)
} 

export const seleccionarFila = () => {
    $("tbody").delegate("tr", "click", function() {
      var element = $(this);
      return(element[0].childNodes[0].textContent);
    });
}

//VALIDACION
export const validarDni = (valor) => {
    var letras = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];

    if (!(/^\d{8}[A-Z]$/.test(valor.dni))) {
        return false;
    }
    if (valor.charAt(8) !== letras[((valor.dni).substring(0, 8)) % 23]) {
        return false;
    }
    if (!(/^\d{9}$/.test(valor.celular))) {
        return false;
    }
    if (!(/^\d{9}$/.test(valor.celular_ref))) {
        return false;
    }
    return true
}



//agregar nombre de archivo selecionado
export const agregarNombre = nombre => {
    const inputNombre = document.getElementById('fileArchivo')

    inputNombre.innerHTML = `<p>${nombre}</p>`

}

export const aparecerSeleccion = () => {
    const inputFile = document.getElementById('fileInput')

    inputFile.click()
}

export const getElement= id => {
    return document.getElementById(id)
}


       
        function abortRead() {
            var reader;
            reader.abort();
        }

        function errorHandler(evt) {
            switch (evt.target.error.code) {
                case evt.target.error.NOT_FOUND_ERR:
                    alert('File Not Found!');
                    break;
                case evt.target.error.NOT_READABLE_ERR:
                    alert('File is not readable');
                    break;
                case evt.target.error.ABORT_ERR:
                    break; // noop
                default:
                    alert('An error occurred reading this file.');
            };
        }

        function updateProgress(evt) {
            // evt is an ProgressEvent.
            if (evt.lengthComputable) {
                var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                // Increase the progress bar length.
                var progress = document.querySelector('.percent');

                if (percentLoaded < 100) {
                    progress.style.width = percentLoaded + '%';
                    progress.textContent = percentLoaded + '%';
                }
            }
        }

       export function handleFileSelect(evt) {
           var reader;
           var progress = document.querySelector('.percent');

            // Reset progress indicator on new file selection.
            progress.style.width = '0%';
            progress.textContent = '0%';

            reader = new FileReader();
            reader.onerror = errorHandler;
            reader.onprogress = updateProgress;
            reader.onabort = function (e) {
                alert('File read cancelled');
            };
            reader.onloadstart = function (e) {
                document.getElementById('progress_bar').className = 'loading';
            };
            reader.onload = function (e) {
                // Ensure that the progress bar displays 100% at the end.
                progress.style.width = '100%';
                progress.textContent = '100%';
                setTimeout("document.getElementById('progress_bar').className='';", 2000);
            }

            // Read in the image file as a binary string.
            reader.readAsBinaryString(evt.target.files[0]);
        }




/** ESTILOS PAGOS */

export const colorInput = estado => {

    return(
        estado === 'MOROSO' ? 'bg-danger' : 
        estado === 'PAGADO' || estado === 'AL DÍA' ? 'bg-success' :
        estado === 'PENDIENTE ' ? 'bg-warning' : '' 
    )
}

export const metodoConfimacion = (dataMetodo) => {

    const {funcion, id,data, mensajeAdvertencia, mensajeExito} = dataMetodo

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons
        .fire({
          title: 'Estas seguro?',
          text: mensajeAdvertencia,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {

              const {mensaje, textMensaje} = id && data ? await funcion(id, data) :  await funcion(id)
            //consultando para ver el mensaje de aprovación
            if(mensaje) {
              return {
                resultado: mensaje,
                textResultado: textMensaje
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: textMensaje
              });
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          const {resultado, textResultado} = result.value || {}

          if (resultado === true) {
            swalWithBootstrapButtons.fire(
                mensajeExito,
              textResultado,
              "success"
            );
          }
        });
}

export const romanize = n => {
	var
		values = [1, 5, 10, 50, 100, 500, 1000],
		letras = ['I', 'V', 'X', 'L', 'C', 'D', 'M'],
		res = [],
		num, letra, val, pos, insert

	for(var i = 6; num = values[i], letra = letras[i]; i--) {
		if(n >= num) {
			var r = Math.floor(n / num); 
			n -= r * num; 

			if(r < 4){
				while(r--){
					res.push(letra);
				}
			} else {
				val = res.pop();
				pos = (val ? letras.indexOf(val) : i) + 1; 
				insert = letra + (letras[pos] || 'M'); 
				res.push(insert);
			}
		} else {
			res.push('');
		}
	}
	return res.join('');
}
