import React from 'react';
import { Button, Form, Modal, Row, Spinner, InputGroup } from 'react-bootstrap';
import useListaPension from '../../../../hooks/useListaPension';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TYPENUMBER, REQUIRED, TYPE_NUMBER_POSITIVE } from '../../../../helpers/validationForms';
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { guardarPension } from '../../../../actions/cursosActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const FormPlanMensualidades = ({ onCloseModal, plan }) => {

  const { idProyecto } = useParams();
  const { id_plan: idPlan, dt_plan: pensionesPlan } = plan;
  const { pensiones, setPensiones, mostrarPensiones } = useListaPension(pensionesPlan || [], 1);
  const dispatch = useDispatch();
  const { loadingPlan } = useSelector(state => state.cursos);

  const formik = useFormik({
    initialValues: {
      monto: '',
      id_tp_concepto: '',
      fecha_limite: ''
    },
    validationSchema: yup.object({
      monto: yup.number()
        .typeError(TYPENUMBER)
        .positive(TYPE_NUMBER_POSITIVE)
        .required(REQUIRED),
      fecha_limite: yup.date().required(REQUIRED),
      id_tp_concepto: yup.string().required(REQUIRED)
    })
  });

  const handleClickAddPension = () => {
    const validateMonto = yup.number().typeError(TYPENUMBER).positive(TYPE_NUMBER_POSITIVE).required(REQUIRED) 
    const validateFecha = yup.date().required(REQUIRED);
    const validateIdConcepto = yup.string().required(REQUIRED);
    
    if(!validateMonto.isValidSync(formik.values.monto)) {
      formik.setFieldError('monto', REQUIRED);
      return;
    }
    if(!validateIdConcepto.isValidSync(formik.values.id_tp_concepto)) {
      formik.setFieldError('id_tp_concepto', REQUIRED);
      return;
    }
    if(!validateFecha.isValidSync(formik.values.fecha_limite)) {
      formik.setFieldError('fecha_limite', REQUIRED);
      return;
    }
    setPensiones([
      ...pensiones,
      {
        id_dt_plan: `c-${new Date().getTime()}`,
        monto: formik.values.monto,
        fecha_limite: formik.values.fecha_limite,
        id_tp_concepto: formik.values.id_tp_concepto
      }
    ])
    formik.setFieldValue('monto', '');
    formik.setFieldValue('fecha_limite', '');
    formik.setFieldValue('id_tp_concepto', '');
  }

  const handleSubmit = async e => {
    e.preventDefault();
    //Funcion para guardar las pensiones
    dispatch(guardarPension(idProyecto, idPlan, pensiones, 1, onCloseModal));
  }

  return ( 
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Group>
            <Form.Label>Nombre de plan</Form.Label>
            <Form.Control type='text' defaultValue={plan.nombre_plan} disabled />
          </Form.Group>
          <Form.Group>
            <Form.Label>Tipo de plan</Form.Label>
            <Form.Control type='text' defaultValue={plan.tp_plan} disabled />
          </Form.Group>
        </Form.Group>
        <h6 className='form-header ml-0'>Mensualidades</h6>
        <Form.Group>
          <Form.Label>Concepto</Form.Label>
          <Form.Control 
            as='select'
            name='id_tp_concepto'
            value={formik.values.id_tp_concepto}
            onChange={formik.handleChange}
            isInvalid={formik.errors.id_tp_concepto}
          >
            <option value="">--Seleccione--</option>
            <option value={1}>MATRICULA</option>
            <option value={2}>MENSUALIDAD</option>
            <option value={5}>CERTIFICADO</option>
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.id_tp_concepto}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Monto</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>S/</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              name='monto'
              value={formik.values.monto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.monto}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Fecha límite</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control 
              type='date'
              name='fecha_limite'
              value={formik.values.fecha_limite}
              onChange={formik.handleChange}
              isIvalid={formik.errors.fecha_limite}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.fecha_limite}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='text-right'>
          <Button variant='info' onClick={handleClickAddPension}>
            Agregar
          </Button>
        </Form.Group>
        <Form.Group>
          {mostrarPensiones()}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button variant='info' disabled={loadingPlan} type='submit' className='btn-spinner'>
          {
            loadingPlan && <Spinner as='span' animation='border' size='sm' />
          }
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormPlanMensualidades;