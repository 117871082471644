import React from 'react';
import MaterialTableDemo from '../../plugins/MaterialTableDemo';

const TablaReporteCargosDescuentos = ({ tabla = [] }) => {

  return ( 
    <>
      <MaterialTableDemo
        columns={columsDescuentos()}
        tabla={tabla}
      />
    </>
  );
}
 
export default TablaReporteCargosDescuentos;

const columsDescuentos = () => [
  {
    key: 'index',
    text: '#',
    width: 50,
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    key: 'nro_documento',
    text: 'N° comprobante',
    sortable: true,
    width: 80
  },
  {
    key: 'nombre_discente',
    text: 'Nombres y apellidos',
    sortable: true
  },
  {
    key: 'concepto',
    text: 'Concepto'
  },
  {
    key: 'motivo',
    text: 'Motivo'
  },
  {
    key: 'fecha',
    text: 'Fecha'
  },
  {
    key: 'monto',
    text: 'Monto'
  },
  {
    key: 'cantidad_cargo',
    text: 'Cantidad'
  },
  {
    key: 'acumulado',
    text: 'Total'
  }
]

