import React from 'react';
import './InicioTutor.scss'
import Contadores from '../../components/plugins/Contadores';
import HeaderContent from '../General/HeaderContent';

const InicioTutor = () => {
  return ( 
    <div className="inicio-tutor">
      <HeaderContent title='Inicio'/>
      <div className="inicio-tutor__content box pb-5">

     
        <div className="contadores">
          <Contadores />
        </div>
        <div className="frase">
          <blockquote>
            <p>
              <strong>El secreto de la felicidad </strong>
              no está en hacer siempre lo que se quiere,
              <strong> sino en querer </strong>
              siempre lo que se hace.
            </p>
            <cite>León Tolstoi.</cite>
          </blockquote>
        </div>

        <div className="vision-mision row">
          <div className="mision col-md-6">
            <p className="titulo">Sistema de seguimiento de posventa</p>
            <p className="texto">
              El modelo educativo de INEDI Posgrados® está centrado en el aprendizaje y la
              formación integral de los discentes; por ello se cuenta con un sistema
              Institucional de Tutoría para proporcionar a los discentes los elementos
              necesarios que permitan tener un monitoreo constante, así como un
              seguimiento inteligente que contribuyan a que el discente 1 termine el programa
              al que fue matriculado.
            </p>
          </div>
          <div className="vision mt-3 mt-md-0 col-md-6">
            <p className="titulo">Tutoria</p>
            <p className="texto">
              La tutoría es una modalidad de la actividad académica que consiste en un
              proceso sistemático de orientación y acompañamiento al discente de carácter
              académico y personal durante su estancia en INEDI Posgrados®, que se
              concretiza en la atención individualizada que brinda el tutor a un discente o a
              un grupo de discentes.
            </p>
            <p className="titulo mt-3">¿Que hace el tutor?</p>
            <p className="texto">
              Cumple metas económicas de recaudación mensual.
              <ul>
                <li>Establece una conexión fidedigna entre el discente y la empresa.</li>
                <li>Promueve a culminar sus estudios hasta la obtención del título académico.</li>
                <li>Promueve en el discente el mejoramiento de su desempeño académico.</li>
                <li>
                  Orienta al discente en los problemas académicos que surjan durante el proceso
                  formativo (dificultades en el aprendizaje; en las relaciones docente - discente;
                  entre discentes; problemáticas económico financieras, dificultades en los
                  procesos académicos, etc.)
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="desplegable mt-4">
        <div id="accordionExample" className="accordion">
          <div className="card">
            <div
              id="headingThree"
              className="card-header bg-primary shadow-sm border-0 rounded py-2"
            >
              <h6 className="mb-0 font-weight-bold">
                <a
                  href="#"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  className="d-block position-relative collapsed text-white text-uppercase collapsible-link"
                >
                  Nosotros
                </a>
              </h6>
            </div>
            <div
              id="collapseOne"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
              className="collapse show"
              style={{fontSize: '13px'}}
            >
                <div className="info-extra p-3">
                <span className='font-weight-bold'>R.U.C.:</span> 20605393901 <br />
                <span className='font-weight-bold'>Razon social:</span> Corporación TALES S.A.C. <br />
                <span className='font-weight-bold'>Direccion: </span> Jirón Manuel del Pino N° 351. Edificio los
                           pinos Oficina 1103. Urbanización Sta. Beatriz - 
                      Cercado de Lima. <br/>
                <span className='font-weight-bold'>Oficina Ica:</span> Calle Bolivar 244 - Ica - Ica - Ica
                </div>
            </div>
          </div>
        </div>
        </div>
        </div>
    </div>
   );
}
 
export default InicioTutor;