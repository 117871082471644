import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory, useParams } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import { eliminarEncuesta, cambiarEstadoForm } from "../../../actions/encuestasAction";

import "./CardEncuestas.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const CardEncuestas = (props) => {
  const history = useHistory();
  const params = useParams();
  const { updateShowModal, encuesta, guardarEncuesta, cambiarEstadoForm } = props;

  //funciones redux
  const { eliminarEncuesta } = props;

  const { idEventoTutor, idEvento } = params;

  const classes = useStyles();

  const compartir = () => {
    guardarEncuesta(encuesta);
    updateShowModal(true);
  };

  const actualizarAcceso = estado => {
    //usar la funcion de cambiar visibilidad.
    cambiarEstadoForm(encuesta.id_formulario, estado)
  };

  const eliminarEncuestaSeleccionada = () => {
    eliminarEncuesta(encuesta.id_formulario);
  };

  return (
    <Card className={`${classes.root} card-form`}>
      <CardActionArea
        className="card-action"
        onClick={() =>
          history.push(
            `/tutor/eventos/${idEvento}/${idEventoTutor}/encuestas/${encuesta.id_formulario}`
          )
        }
      >
        <CardMedia
          className={`${classes.media} card-imagen`}
          image="/images/form.png"
          title="Contemplative Reptile"
        />

        <CardContent className="card-contenido">
          <Typography
            gutterBottom
            className="title-card"
            variant="h5"
            component="h4"
          >
            {encuesta.nombre_formulario}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions className="card-footer">
        <div className="button-actions">
          <Tooltip title="Compartir">
            <IconButton
              onClick={() => compartir(encuesta)}
              className="btn-shared"
              aria-label="share"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          {parseInt(encuesta.id_tp_estado) === 1 ? (
            <Tooltip title="Cambiar a inactivo">
              <IconButton onClick={() => actualizarAcceso(2)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Cambiar a activo'>
              <IconButton onClick={() => actualizarAcceso(1)}>
                <VisibilityOffIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Tooltip title="Eliminar">
          <IconButton
            className="btn-delete"
            aria-label="add to favorites"
            onClick={() => eliminarEncuestaSeleccionada()}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  eliminarEncuesta,
  cambiarEstadoForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CardEncuestas);
