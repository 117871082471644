import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const withOptionSimpleSelect = WrappedComponent => props => {

  const { options: optionsPrimary, setValuesOption, setLabelOptions, defaultValue: defaultValuePrimary } = props 
  const [options, setOptions] = useState([])
  const [defaultValue, setDefaultValue] = useState(null)

  const handleConfigData = () => {
    const nuevosOptions = optionsPrimary.map(item => ({value: item[setValuesOption], label: item[setLabelOptions]}))
    setOptions(nuevosOptions)

    if (defaultValuePrimary) {
      let nuevosDefault = {
        value: parseInt(defaultValuePrimary[setValuesOption]),
        label: defaultValuePrimary[setLabelOptions]
      };
      setDefaultValue(nuevosDefault);
    }
  }

  useEffect(() => {
    if (optionsPrimary.length) {
      handleConfigData()
    }
  }, [optionsPrimary, defaultValuePrimary])

  return ( 
    <WrappedComponent 
      {...props}
      options={options}
      defaultValue={defaultValue}
    />
   );
}
 
withOptionSimpleSelect.propTypes = {
  options: PropTypes.array.isRequired,
  setValuesOption: PropTypes.string.isRequired,
  setLabelOptions: PropTypes.string.isRequired
}

export default withOptionSimpleSelect;