import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';

const EditarPassword = ({ handleEditarPassword }) => {

  const [datosPassword, setDatosPassword] = useState({
    nuevoPassword: '',
    repitePassword: ''
  })

  const { nuevoPassword, repitePassword } = datosPassword;

  const handleChange = e => {
    setDatosPassword({
      ...datosPassword,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    if(nuevoPassword === '' ||  repitePassword === '') {
      avisoError('Ambos campos son obligatorios')
      return;
    }
    if (nuevoPassword !== repitePassword) {
      avisoError('No coinciden las contraseñas');
      return;
    }

    handleEditarPassword(nuevoPassword)
  }

  return ( 
    <div className="EditarPassword">
      <div className="EditarPassword__container">
        <div className="EditarPassword__head">
          <h5>Editar contraseña</h5>
        </div>
        <div className="EditarPassword__body mt-3">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Nueva contraseña:</Form.Label>
              <Form.Control
                type='password'
                name='nuevoPassword'
                value={nuevoPassword}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Repetir contraseña:</Form.Label>
              <Form.Control
                type='password'
                name='repitePassword'
                value={repitePassword}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className='text-right'>
              <Button
                type='submit'
                variant='info'
                className='text-white btn-icon'
              >
                <FontAwesomeIcon icon={faSave} />
                Guardar
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
   );
}
 
export default EditarPassword;