import React from "react";
//Redux
import {connect} from 'react-redux'

const ContentRight = (props) => {

  const {empresa, setFormulario} = props

  return (
    <div className="perfil-empresa__content-right">

    
      <div className="encabezado">
        <div className="titulo">
          <i className="far fa-building"></i>
          Perfíl de empresa
        </div>
        <div className="boton-editar">
          <i className="far fa-edit"></i>
          <a
            href=""
            data-toggle="modal"
            data-target="#modal-generico"
            onClick={e => {
              e.preventDefault()
              setFormulario('vision')
            }}
          >Editar</a>
        </div>
      </div>
      <div className="vision-mision">
        <div className="contenido">
          <p className="titulo">
            <i className="fa fas-archive"></i>
            Misión
          </p>
          <p className="contenido">
            {empresa.mision}
          </p>
        </div>
        <div className="contenido">
          <p className="titulo">
            <i className="fa fas-archive"></i>
            Visión
          </p>
          <p className="contenido">
            {empresa.vision}
          </p>
        </div>
      </div>
      <div className="encabezado">
        <div className="titulo">
          <i className="far fa-building"></i>
          Informacion bancaria
        </div>
        <div className="boton-editar">
          <i className="far fa-edit"></i>
          <a
            href=""
            data-toggle="modal"
            data-target="#modal-generico"
            onClick={e => {
              e.preventDefault()
              setFormulario('banco')
            }}
          >Editar</a>
        </div>
      </div>
      <div className="info-bancaria">
        <div className="item">
          <p className="indicador">
            Banco
          </p>
         
          {empresa.cuentas_bancarias !== undefined ?
            empresa.cuentas_bancarias.lenght !== 0 ?
            <ul>
              {empresa.cuentas_bancarias.map(cuenta => (
                <li  key={cuenta.id_cuentas_banco} className='mb-0'>
                  {cuenta.nombre_banco} ({cuenta.nro_cuenta_corriente})
                </li>
              ))}
            </ul>
            :
            <p>No hay bancos asociados</p>
          : null}
        </div>
        
        <div className="titulo item">
          <p className="indicador">N° C.C.I:</p>
          <p className="valor">{empresa.nro_ruc}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">Titular</p>
          <p className="valor">{empresa.titular}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">Cuenta detracción</p>
          <p className="valor">{empresa.cuenta_detraccion}</p>
        </div>
        <div className="titulo item">
          <p className="indicador">RNP</p>
          <p className="valor">{empresa.rnp}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
    empresa: state.empresa.empresa
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ContentRight);
