import React from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as moment from 'moment';
//Redux
import { useDispatch } from 'react-redux';
import { insertarCuotaFraccionada } from '../../../../actions/pagosActions';
import { updateStateModalBasic } from '../../../../actions/modalActions';


const FromularioCuotas = () => {

  const params = new URLSearchParams(window.location.search);
  const idPension = params.get('pension');
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      id_dt_matricula: idPension,
      fecha_limite: ''
    },
    validationSchema: yup.object({
      id_dt_matricula: yup.number().required(),
      monto_establecido: yup.number()
        .positive('La cantidad tiene que ser positiva.')
        .typeError('No es una entrada válida')
        .required('Este campo es requerido.'),
      fecha_limite: yup.date('No es una entra válida')
        .typeError('No es una entrada válida')
        .required('Este campo es requerido.')
    }),
    onSubmit: valores => {
      dispatch(insertarCuotaFraccionada(valores));
    }
  });

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }

  return ( 
    <div className="FormularioCuotas">
      <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor='monto_establecido'>Monto</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id='monto_establecido'>
                S/.
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              id='monto_establecido'
              name='monto_establecido'
              value={formik.values.monto_establecido}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto_establecido}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.monto_establecido}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='fecha_limite'>Fecha límite</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id='fecha_limite'>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='date'
              id='fecha_limite'
              name='fecha_limite'
              min={moment().format('YYYY-MM-DD')}
              value={formik.values.fecha_limite}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_limite}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.fecha_limite}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            type='submit'
            className='btn-icon'
            onClick={handleModal}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button variant='info' type='submit' className='btn-icon'>
            <FontAwesomeIcon icon={faSave} />
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
}
 
export default FromularioCuotas;