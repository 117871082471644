import {boletasTypes} from '../actions/types'

const initialState = {
    boletas: [],
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case boletasTypes.OBTENER_BOLETAS_EXITO:
            return{
                ...state,
                boletas: action.payload,
                loading:false,
            }
        case boletasTypes.OBTENER_BOLETAS:
                return{
                    ...state,
                    loading:true,
                }
        default:
            return state
    }
} 