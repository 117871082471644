import { Alert } from 'react-bootstrap';
import React from 'react';

const Fatal = ({ message }) => {
  return ( 
    <Alert variant='danger'>
      {message}
    </Alert>
  );
}
 
export default Fatal;