import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import FilterListIcon from '@material-ui/icons/FilterList';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import './DrawerMenu.scss';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  fab: {
    margin: theme.spacing(2),
  }
}));

export default function TemporaryDrawer(props) {

  const { children } = props

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor, children) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      { children }
    </div>
  );

  return (
    <div className='drawer-menu'>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor, children)}
          </Drawer>
          <Tooltip title="Filtrar" aria-label="add">
        <Fab onClick={toggleDrawer(anchor, true)} color="primary" className={`${classes.fab} btn-despliegue`}>
          <FilterListIcon />
        </Fab>
      </Tooltip>
        </React.Fragment>
      ))}
      
    </div>
  );
}