import React, { useEffect, useState } from 'react';
import Layout from '../../../../app/Layout';
import FormularioFiltroReporte from '../../../components/Finanzas/FormularioFiltroReporte';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading'
import ResultadoReporteIngreso from '../../../components/Finanzas/ResultadoReporteIngreso';
import './ReporteIngresos.scss';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { limpiarResultadoBusqueda, obtenerReporteIngreso } from '../../../../actions/finanzasReportesActions';

const ControlReportes = () => {

  const [datosBusqueda, setDatosBusqueda] = useState(null);
  const { loading, error, reporteIngresos } = useSelector(state => state.finanzasReportes);
  const dispatch = useDispatch();

  const handleObtenerReporteIngresos = (datos) => {
    dispatch(obtenerReporteIngreso(datos));
  }

  useEffect(() => {
    dispatch(limpiarResultadoBusqueda());
  }, [])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte de ingresos' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/pagos", nombre: "Reporte de ingresos" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
              <FormularioFiltroReporte
                onSubmit={handleObtenerReporteIngresos}
                setValores={setDatosBusqueda}
                ingresos
              />
            </div>
          </div>    
          <SpinnerLoading loading={loading} error={error}>
            {
              reporteIngresos.tablas && <ResultadoReporteIngreso datosBusqueda={datosBusqueda} />
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ControlReportes;