import React, { useState } from "react";
import MaterialTable from "../../components/plugins/MaterialTableDemo";
import ModalVerVideo from "./ModalVerVideo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';

//Redux
import { useSelector } from "react-redux";
import ModalBasic from "../../components/Modals/ModalBasic";
import EditarRecursos from "../../components/Formularios/EditarRecursos";

const Videos = (props) => {
  const { tp_videos: videos = [] } = useSelector(
    (state) => state.recursos.recursos
  );
  
  //Funciones
  const { eliminarRecurso } = props;

  const [video, setVideo] = useState({});
  const [recurso, setRecurso] = useState({});
  const [showModal, setShowModal] = useState(false);

  const botonesAcciones = (record) => (
    <>
      <Button
        variant="danger"
        size="xs"
        onClick={() => eliminarRecurso(record.id_archivo_sesion)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Button
        variant="warning"
        size="xs"
        onClick={() => setVideo(record)}
        data-toggle="modal"
        data-target="#modal-video"
      >
        <FontAwesomeIcon icon={faEye} />
      </Button>
      <Button
        type="button"
        variant="info"
        size="xs"
        onClick={() => guardarRecursosEditar(record)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    </>
  );

  const guardarRecursosEditar = (archivo) => {
    setRecurso(archivo);
    setShowModal(true);
  };

  const updateShowModal = (valor) => {
    setShowModal(valor);
  };

  return (
    <div className="recursos__videos">
      <div className="p-0 bg-white">
        <div className="tabla">
          <MaterialTable
            colums={colums(botonesAcciones)} 
            tabla={videos}
            addConfig={{key_column: 'id_archivo_sesion'}}
          />
        </div>
      </div>
      <ModalVerVideo video={video} />

      <ModalBasic
        show={showModal}
        title="Editar recurso"
        updateShowModal={updateShowModal}
      >
        <EditarRecursos recurso={recurso} />
      </ModalBasic>
    </div>
  );
};

export default Videos;

const colums = (botonesAcciones) => [
  {
    text: "#",
    key: "index",
    align: "center",
    sortable: true,
    width: 70,
    className: "text-center",
    cell: (_, index) => index+1
  },
  {
    text: "Nombre de video",
    key: "nombre_archivo",
    align: "center",
    sortable: true,
  },
  {
    text: "Formato",
    key: "formato_archivo",
    align: "center",
    sortable: true,
    width: 100,
    className: "text-center",
  },
  {
    text: "Publicado",
    key: "fecha",
    align: "center",
    sortable: true,
    width: 150,
    className: "text-center",
  },
  {
    text: "Acciones",
    key: "button",
    align: "center",
    className: "text-center",
    width: 130,
    cell: (record) => botonesAcciones(record),
  },
];
