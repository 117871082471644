import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { subirArchivosAdjuntosCv, eliminarArchivoAdjunto } from '../../actions/trabajadoresActions';

const withArchivosAdjuntos = WrappedComponent => props => {

  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleSubirArchivo = (datos, idTipo) => {
    dispatch( subirArchivosAdjuntosCv(datos, idTrabajador, idTipo) )
  }

  const handleEliminarArchivo = (idDocumento, idTipo) => {
    dispatch( eliminarArchivoAdjunto(idDocumento, idTipo, idTrabajador) )
  }

  return ( 
    <WrappedComponent
      {...props}
      {...trabajador.documentos}
      handleSubirArchivo={handleSubirArchivo}
      handleEliminarArchivo={handleEliminarArchivo}
    />
   );
}
 
export default withArchivosAdjuntos;