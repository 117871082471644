import React from 'react';

const Footer = () => {
    return (
        <div>
          {/* footer content */}
          <footer style={{background: '#f7f7f7'}}>
            <div className="pull-right">
              © {new Date().getFullYear()} Copyright: INEDI Posgrados &#174;
            </div>
            <div className="clearfix" />
          </footer>
          {/* /footer content */}         
        </div>
    );
};

export default Footer;