import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'



//Redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerReporteCalificacion } from '../../../actions/cursosActions';
import ResultadoCalificacion from './resultadoCalificacion';

const ReporteNaranja = () => {

  const { loading, error, reporteCalificacion } = useSelector(state => state.reporteCalificacion);
  const dispatch = useDispatch();

  useEffect(() => {

     dispatch(obtenerReporteCalificacion());
  }, [])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte de Satisfacción' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
              { url: "", nombre: "Satisfacción" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
            
          <SpinnerLoading loading={loading} error={error}>
            {
              <ResultadoCalificacion calificaciones={reporteCalificacion.calificaciones} />
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteNaranja;