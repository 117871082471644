import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarClasificacionTrabajador } from '../../actions/trabajadoresActions';

const withEditarClasificacionTrabajador = WrappedComponent => props => {

  const dispatch = useDispatch();
  const [trabajador, idTrabajador] = useGetTrabajador();
  
  const handleSubmitTrabajador = ( idClasificacion) => {
    dispatch( editarClasificacionTrabajador(idTrabajador, idClasificacion) );
  }

  return ( 
    <WrappedComponent
      {...props}
      handleSubmitForm={handleSubmitTrabajador}
      clasificacion={trabajador.clasificacion}
    />
   );
}
 
export default withEditarClasificacionTrabajador;