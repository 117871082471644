import React, { useEffect } from 'react';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import ModalLargewithRedux from '../../../containers/ModalLargewhithRedux';
import FormularioProveedor from './FormularioProveedor';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarProveedor, listarProveedores } from '../../../actions/empresaAction';
import { updateMostrarComunicado } from '../../../actions/popupsActions';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { updateStateModalLarge } from '../../../actions/modalActions';
import { faEdit, faEye, faLink, faListAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';
import {SET_ID_PROVEEDOR} from '../../../actions/types'
import DetalleProveedor from '../../components/DetalleProveedor/ModalDetalleProveedor';


const Proveedores = () => {


    const {loading, proveedores} = useSelector(state=> state.empresa)
    const {usuario} = useSelector(state=>state.usuario)
    const dispatch = useDispatch();

    const handleNuevoRegistro = () => {
        dispatch( updateStateModalLarge(<FormularioProveedor />, 'Nuevo Proveedor' ) )
      } 

      const handleEditarRegistro = (registro) => {
        dispatch( updateStateModalLarge(<FormularioProveedor registro={registro} />, 'Editar Proveedor' ) )
      } 


    const handleEliminarRegistro = (id) =>{
      dispatch(eliminarProveedor(id))
    }
    const handleDetalle = (id) =>{
      dispatch({
          type:SET_ID_PROVEEDOR,
          payload: id
      })
      dispatch(updateMostrarComunicado())
  }
    useEffect(()=>{
        dispatch(listarProveedores())
    },[])

    return (
        <Layout>
        <DetalleProveedor />
        <div className="Trabajadores">
          <div className="Trabajadores__container">
          <ModalLargewithRedux />
            <div className="Trabajadores__head">
              <HeaderContent title="Proveedores" icon="group" />
              <Breadcrumb
                link={[
                  { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                  { url: "/proveedores-m", nombre: "Proveedores" },
                ]}
              />
            </div>
            <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
              <SpinnerLoading loading={loading}>
                <div className="Trabajadores__acciones mb-2">
                  <div className="text-right">
                    <Button
                      type='button'
                      variant="primary"
                      onClick={handleNuevoRegistro}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      Nuevo Proveedor
                    </Button>
                  </div>
                </div>
                <div className="box mt-5">
                  <div className="box-header with-border">
                    <h5
                        style={{ color: "#434343" }}
                      >
                      <FontAwesomeIcon icon={faListAlt} /> Proveedores registrados
                    </h5>
                  </div>
                  <div className="box-primary">
                    <MaterialTableDemo
                      colums={colums(handleEliminarRegistro,handleEditarRegistro,handleDetalle)}
                      keyCol="id_proveedor"
                      tabla={proveedores}
                    />
                  </div>
                </div>
              </SpinnerLoading>
            </div>
          </div>
        </div>
      </Layout>
    );
};

export default Proveedores;

const colums = (handleEliminarRegistro,handleEditarRegistro,handleDetalle) => [

    {
      text: "ID",
      key: "id_proveedor",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 50
    },
    {
      text: "RUC",
      key: "ruc",
      align: "center",
      sortable: true,
    },
    {
      text: "Nombre/Razón Social",
      key: "nombre_rs",
      align: "center",
      sortable: true,
      className: "text-center",
    },
    {
      text: "Contacto",
      key: "nombre_contacto",
      align: "center",
      sortable: true
    },
    {
      text: "Tel. Movil",
      key: "numero_contacto",
      align: "center",
      sortable: true,
      className: "type_of_food",
    },
    {
      text: "Tel. Fijo",
      key: "telefono_fijo",
      align: "center",
      sortable: true
    },
    {
      text: "Correo",
      key: "correo",
      align: "center",
      sortable: true
    },
    {
      text: "Área",
      key: "area",
      align: "center",
      sortable: true
    },
    {
      text: "Catálogo",
      key: "catalogo",
      align: "center",
      sortable: true,
      cell: (record) => (
        <div style={{textAlign:'center'}}>
          <a

          href={record.catalogo}
          className="btn btn-info"
          target="_blank"
          rel="noopener noreferrer"          
          >
            <FontAwesomeIcon icon={faLink} />
          </a>
        </div>
      )
    },
    {
      text: "Acciones",
      key: "button",
      align: "center",
      sortable: true,
      className: "text-center",
      cell: (record) => (
        <>
          <Button
            type="button"
            variant='danger'
            size='xs'
            onClick={() => handleEliminarRegistro(record.id_proveedor)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            type="button"
            variant='warning'
            size='xs'
            onClick={() => handleEditarRegistro(record)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>

          <Button
            type="button"
            variant='info'
            size='xs'
            onClick={() => handleDetalle(record.id_proveedor)}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
         
        </>
      ),
    },
  ];