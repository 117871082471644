import React from 'react';
import TablaCuotasVariables from '../CargosVariables/TablaCargosVariables';

const CargosVariablesFactura = ({ cargosVariables }) => {
  return ( 
    <div className="CargosVariablesFactura">
      <h4 className="title-head">
        Cargos variables
      </h4>
      <div className="box">
        <div className="box-body">
          <TablaCuotasVariables
            acciones={false}
            cargos_variables={cargosVariables}
          />
        </div>
      </div>
    </div>
  );
}
 
export default CargosVariablesFactura;