import React, { useEffect } from "react";
import Layout from "../../../../app/Layout";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumb from "../../General/Breadcrumb";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import VistaPerfiProfesionaTrabajador from "../../../components/RegistroUsuarios/PerfilProfesional/VistaPerfilProfesional/VistaPerfilProfesionalTrabajador";
import HonorariosWithTrabajador from "../../../../containers/TrabajadoresContainers/HonorariosWithTrabajador";
import DatosSesionWithTrabajadores from "../../../../containers/TrabajadoresContainers/DatosSesionWithTrabajadores";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import AvatarUsuarioWithTrabajador from "../../../../containers/AvatarContainers/AvatarUsuarioWithTrabajador";
import VistaDatosPersonalesWithTrabajador from "../../../../containers/TrabajadoresContainers/VistaDatosPersonalesWithTrabajador";
import VistaClasificacionTrabajadorContainer from "../../../../containers/TrabajadoresContainers/VistaClasificacionTrabajadorContainer";
import ModalCustomWidthwithRedux from "../../../../containers/ModalCustomWidthwithRedux";
import useGetTrabajador from "../../../../hooks/useGetTrabajador";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faGraduationCap, faUserCog, faUsersCog } from "@fortawesome/free-solid-svg-icons";
import './InspeccionarTrabajador.scss';
//Redux
import { useDispatch, useSelector } from "react-redux";
import { obtenerTrabajador } from "../../../../actions/trabajadoresActions";
import ConfiguracionTrabajador from "../../../components/RegistroUsuarios/ConfiguracionTrabajador";

const EditarUsuario = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.trabajadores);
  const [trabajador, idTrabajador] = useGetTrabajador()

  useEffect(() => {
    dispatch(obtenerTrabajador(idTrabajador));
  }, []);

  return (
    <Layout>
      <div className="InspeccionarTrabajador">
        <div className="InspeccionarTrabajador__container">
        <ModalCustomWidthwithRedux />
          <div className="InspeccionarTrabajador__head">
            <HeaderContent
              title="Editar trabajador"
              icon="person"
            />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/trabajadores", nombre: "Trabajadores" },
                { url: "", nombre: "Editar trabajador" },
              ]}
            />
          </div>
          <div className="InspeccionarTrabajador__body container-fluid mt-3 px-md-3">
            <SpinnerLoading loading={!trabajador} error={error}>
              <Row>
                <Col md={4}>
                  <div className="bg-white py-4 shadow-sm border">
                    <AvatarUsuarioWithTrabajador />
                    <VistaDatosPersonalesWithTrabajador />
                  </div>
                </Col>
                <Col md={8}>
                  <div className='bg-white shadow-sm border'>
                    <Tabs
                      defaultActiveKey="clasificacion"
                      className='InspeccionarTrabajador__panelTab'
                      id="trabajador-tab"
                    >
                      <Tab eventKey="clasificacion" title={
                        <>
                          <div className='text-center w-100 mb-2'>
                            <FontAwesomeIcon icon={faUserCog} />
                          </div>
                          Clasificación
                        </>
                      }>
                        <ConfiguracionTrabajador 
                          trabajador={trabajador}
                        />
                      </Tab>
                      <Tab eventKey="perfil" title={
                        <>
                          <div className='text-center w-100 mb-2'>
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </div>
                          Perfíl profesional
                        </>
                      }>
                        <VistaPerfiProfesionaTrabajador />
                      </Tab>
                      <Tab eventKey="honorarios" title={
                        <>
                          <div className='text-center w-100 mb-2'>
                            <FontAwesomeIcon icon={faDollarSign} />
                          </div>
                          Honorarios
                        </>
                      }>
                        <HonorariosWithTrabajador />
                      </Tab>
                      <Tab eventKey="usuario" title={
                        <>
                          <div className='text-center w-100 mb-2'>
                            <FontAwesomeIcon icon={faUsersCog} />
                          </div>
                          Datos de usuarios
                        </>
                      }>
                        <DatosSesionWithTrabajadores />
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditarUsuario;
