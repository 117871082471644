import React from 'react';
import ListaMensajes from './ListaMensajes'
import VistaMensaje from './VistaMensaje';

//Redux
import {connect} from 'react-redux'

const BandejaEntrada = (props) => {

  //State
  const {bandejaMensaje, guardarMensajesSeleccionados, moverMensajePapelera} = props
  
    return (
      <div className="row">
        <div className="col-md-5 pr-0">
          <div
            className="container-lista bg-white border shadow-sm"
            style={{ minHeight: "500px"}}
          >
            <div className="container position-relative px-3">
                    <ListaMensajes
                      title="Recibidos"
                      mensajes={bandejaMensaje}
                      guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                      moverMensajePapelera={moverMensajePapelera}
                    />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <VistaMensaje />
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
  bandejaMensaje: state.mensajes.bandejaMensaje
})

export default connect(mapStateToProps, {})(BandejaEntrada);