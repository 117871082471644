import { useEffect } from "react";
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerModulos } from "../actions/modulosActions";

const useGetModulos = () => {
  
  const dispatch = useDispatch();
  const { modulos, loading, error } = useSelector(state => state.modulos);

  useEffect(() => {
    dispatch(obtenerModulos());
  }, [dispatch])
  
  return { modulos, loading, error }
}
 
export default useGetModulos;