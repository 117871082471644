import React from 'react';
import { faCheckSquare, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlanesCuotas from '../PlanesCuotas';
import PlanesMensualidades from '../PlanesMensualidades';
import PlanesPagoUnico from '../PlanesPagoUnico';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './FinanzasProyectos.scss';
import { eliminarPlan } from '../../../../actions/cursosActions';

const FinanzasProyectos = ({ onShowModal, onCloseModal }) => {
  const dispatch = useDispatch();
  const { idProyecto } = useParams();

  const handleEliminarPlan = (idModalidad, idPlan) => {
    dispatch(eliminarPlan(idProyecto, idModalidad, idPlan));
  }
  return ( 
    <div className='FinanzasProyectos'>
      <section>
        <PlanesPagoUnico
          onShowModal={onShowModal}
          onCloseModal={onCloseModal}
          handleEliminarPlan={handleEliminarPlan}
        />
      </section> 
      <section>
        <PlanesCuotas 
          onShowModal={onShowModal}
          onCloseModal={onCloseModal}
          handleEliminarPlan={handleEliminarPlan}
        />
      </section> 
      <section>
        <PlanesMensualidades
          onShowModal={onShowModal}
          onCloseModal={onCloseModal}
          handleEliminarPlan={handleEliminarPlan}
        />
      </section> 
    </div>
  );
}
 
export default FinanzasProyectos;