import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import useGetCapacitacionControl from '../../../../../../hooks/useGetCapacitacionControl';
import useProgress from '../../../../../../hooks/useProgress';

//Redux

const IndicadorAvance = () => {

  const [{ activo: {calidad} }] = useGetCapacitacionControl();
  const [porcentaje] = useProgress('Completo', calidad);

  return ( 
    <div className="IndicadorAvance mt-3">
      <ProgressBar striped variant='success' now={porcentaje} />
    </div>
   );
}
 
export default IndicadorAvance;