import React, { useEffect } from 'react'
import { useState } from 'react'

//redux
import { connect } from 'react-redux'

const Paginacion = (props) => {

  const {limitador, totalRows, paginate } = props


  const [pageNumbers, setPageNumbers] = useState([])

  const updateNumberPanitation = () => {
    let newPageNumbers = []

    for (let index = 1; index <= Math.ceil(totalRows/limitador); index++) {
      newPageNumbers.push(index)
    }
    setPageNumbers(newPageNumbers)

  }

  useEffect(() => {
    updateNumberPanitation()
  }, [totalRows, limitador])

  return (
    <div>
      <ul className="pagination">
        <li className="page-item">
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              paginate(1);
            }}
          >
            «
          </a>
        </li>

        {pageNumbers.map((number, index) => (
          <li key={index} className="page-item">
            <a
              onClick={(e) => {
                e.preventDefault();
                paginate(number);
              }}
              className="page-link"
              href="#"
            >
              {number}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              paginate(pageNumbers.length);
            }}
          >
            »
          </a>
        </li>
      </ul>
      

    </div>
  );
}

const mapStateToProps = state => ({
  limitador: state.reporte.limitador
})

export default connect(mapStateToProps, {})(Paginacion);