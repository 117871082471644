import React from 'react';

const ListDocenteItem = ({docentes, render}) => {
  return ( 
    <div className="list-docente-item">
      {docentes.map((docente, index) => (
        <li key={index}>
          <div className='list-docente-item--direccion'>
            <p>{docente.nombre_usuario} {docente.apellido_usuario}</p>
            {render && render(docente)}
          </div>
        </li>
      ))}
    </div>
  );
}
 
export default ListDocenteItem;