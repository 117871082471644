import React, { useEffect } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt, faTrash, faEye} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { Badge, Button } from "react-bootstrap";
import RegistroTrabajadorWithNuevo from "../../../containers/TrabajadoresContainers/RegistroTrabajadorWithNuevo";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import "./Trabajadores.scss";

//Reduxt
import { useSelector, useDispatch } from "react-redux";
import { updateStateModalBasic } from "../../../actions/modalActions";
import {
  listarTrabajadores,
  eliminarTrabajadorSistema,
} from "../../../actions/trabajadoresActions";


const Trabajadores = () => {
  //State
  const dispatch = useDispatch();
  const { trabajadores, loading } = useSelector((state) => state.trabajadores);

  const eliminarUsuarioSistema = (idUsuario) => {
    dispatch(eliminarTrabajadorSistema(idUsuario));
  };

  const handleModalRegistro = () => {
    dispatch( updateStateModalBasic(<RegistroTrabajadorWithNuevo />, 'Nuevo trabajador' ) )
  }

  useEffect(() => {
    if (!trabajadores.length) {
      dispatch(listarTrabajadores());
    }
  }, []);

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <ModalBasicwithRedux />
          <div className="Trabajadores__head">
            <HeaderContent title="Trabajadores" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/trabajadores", nombre: "Trabajadores" },
              ]}
            />
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
              <div className="Trabajadores__acciones mb-2">
                <div className="text-right">
                  <Button
                    type='button'
                    variant="primary"
                    onClick={handleModalRegistro}
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-1" />
                    Nuevo trabajador
                  </Button>
                </div>
              </div>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Tabla de trabajadores
                  </h5>
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(eliminarUsuarioSistema)}
                    tabla={trabajadores}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Trabajadores;

/**====================== TABLA =========== */

const colums = (eliminarUsuarioSistema) => [
  {
    text: '#',
    key: 'index',
    className: 'text-center',
    width: 50,
    cell: (_, index) => index+1
  },
  {
    text: "ID",
    key: "id_usuario",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 50
  },
  {
    text: "Nombre",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
  },
  {
    text: "Apellido",
    key: "apellido_usuario",
    align: "center",
    sortable: true,
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "text-center",
  },
  {
    text: "Celular",
    key: "celular",
    align: "center",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Dirección",
    key: "direccion",
    align: "center",
    sortable: true
  },
  {
    text: "Distrito",
    key: "distrito",
    align: "center",
    sortable: true
  },
  {
    text: "Provincia",
    key: "provincia",
    align: "center",
    sortable: true
  },
  {
    text: "Departamento",
    key: "departamento",
    align: "center",
    sortable: true
  },
  {
    text: "Correo",
    key: "email",
    align: "center",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Área",
    key: "nombre_tp_nivel_usuario",
    align: "center",
    sortable: true,
    className: "type_of_food",
  },
  {
    text: "Estado",
    key: "nombre_tp_estado",
    align: "center",
    className: 'text-center',
    sortable: true,
    cell: (record) => <Badge variant={`${record.id_tp_estado === '1' ? 'success' : 'danger'}`}>
      {record.nombre_tp_estado}
    </Badge>
  },
  {
    text: "Acciones",
    key: "button",
    align: "center",
    sortable: true,
    className: "text-center",
    cell: (record) => (
      <>
        <Button
          type="button"
          variant='danger'
          size='xs'
          onClick={() => eliminarUsuarioSistema(record.id_usuario)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Link
          to={`trabajadores/${record.id_empleado}/editar`}
          className="btn btn-warning btn-xs"
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
      </>
    ),
  },
];
