import { useParams } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

const useGetDocente = (idDocenteParam) => {
  
  const { idDocente } = useParams();
  const docente = useSelector(state => state.profesores.docente[!idDocenteParam ? idDocente : idDocenteParam])
  return [docente, !idDocenteParam ? idDocente : idDocenteParam]
}
 
export default useGetDocente;