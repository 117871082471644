import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarAvatarUsuario } from '../../actions/trabajadoresActions';

const withAvatarTrabajador = WrappedComponent => props => {
  
  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleChangeAvatar = e => {
    dispatch( editarAvatarUsuario(e.target.files[0], idTrabajador) )
  }
  
  return ( 
    <WrappedComponent
      {...props}
      urlAvatar={trabajador.img_usuario}
      handleChangeAvatar={handleChangeAvatar}
    />
   );
}
 
export default withAvatarTrabajador;