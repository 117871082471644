import PerfilEmpresa from '../view/pages/empresa/PerfilEmpresa'
import Inicio from '../view/pages/inicio/Inicio'
import Estudiantes from '../view/pages/estudiantes/Estudiantes';
import Asistencia from '../view/pages/asistencia/Asistencia';
import ReporteAsistencia from '../view/pages/ReporteAsistencia';
import Profesores from '../view/pages/Docentes/Profesores';
import NuevoProfesor from '../view/pages/Docentes/NuevoProfesor'
import InspeccionarDocente from '../view/pages/Docentes/InspeccionarDocente'
import AsignacionDocentes from '../view/pages/cursos/AsignacionDocentes'
import Proyectos from '../view/pages/Proyectos';
import SesionesCurso from '../view/pages/Sesiones/SesionesCurso'
import ProfesoresSesion from '../view/pages/Sesiones/ProfesoresSesion'
import NotasSesiones from '../view/pages/Notas/NotasSesiones'
import RegistroPagos from '../view/pages/Finanzas'
import Mensajeria from '../view/pages/mensajeria/Mensajeria'
import NuevoEstudiante from '../view/pages/estudiantes/NuevoEstudiante';
import Trabajadores from '../view/pages/Trabajadores/Trabajadores';
import NuevoTrabajador from '../view/pages/Trabajadores/NuevoTrabajador';
import InspeccionarTrabajador from '../view/pages/Trabajadores/InspeccionarTrabajador';
import Recursos from '../view/pages/RecursosEstudiante/Recursos';
import TareasEstudiantes from '../view/pages/tareas/TareasEstudiantes';
import Tareas from '../view/pages/tareas/Tareas';
import GeneralPosventa from '../view/pages/reportes/GeneralPosventa';
import EventosTutor from '../view/pages/Tutoria/EventosTutor';
import SesionesTutor from '../view/pages/Tutoria/SesionesTutor';
import Tutores from '../view/pages/Tutoria/Tutores';
import TutoresAsignacion from '../view/pages/Tutoria/TutoresAsignacion';
import TutoresInfo from '../view/pages/Tutoria/TutoresInfo';
import Encuestas from '../view/pages/Tutoria/EncuestasTutor/Encuestas';
import NuevaEncuesta from '../view/pages/Tutoria/EncuestasTutor/NuevaEncuesta/NuevaEncuesta';
import EncuestaReporte from '../view/pages/Tutoria/EncuestaReporte';
import ReportesTutoria from '../view/pages/Tutoria/ReportesTutoria/index.';
import EncuestaDocente from '../view/pages/Tutoria/EncuestaDocente';
import ReporteDesertados from '../view/pages/ReporteDesertados';
import PageCapacitaciones from '../view/pages/AreaTrabajo/Academica/PageCapacitaciones';
import Temas from '../view/pages/AreaTrabajo/Academica/Temas/Temas';
import ControlCapacitacion from '../view/pages/AreaTrabajo/Academica/ControlCapacitacion'
import ProgramacionAnual from '../view/pages/AreaTrabajo/Academica/ProgramacionAnual';
import AreaCiclos from '../view/pages/AreaTrabajo/Academica/AreaCiclos';
import ExaminarCuota from '../view/pages/Finanzas/ExaminarCuota';
import ExaminarMatricula from '../view/pages/Finanzas/ExaminarMatricula/ExaminarMatricula';
import ExaminarPagosExtra from '../view/pages/Finanzas/ExaminarPagoExtra';
import ReporteIngresos from '../view/pages/Finanzas/ReporteIngresos';
import ReporteDeudores from '../view/pages/Finanzas/ReporteDeudores';
import Facturas from '../view/pages/Finanzas/Facturas';
import ExaminarReporteIngresos from '../view/pages/Finanzas/ExaminarReporteIngresos';
import ReporteCargoDescuentos from '../view/pages/Finanzas/ReporteCargoDescuentos';
import ModuloProyecto from '../view/pages/ModulosProyecto/ModuloProyecto';
import ModulosProyecto from '../view/pages/ModulosProyecto';
import ExaminarProyecto from '../view/pages/Proyectos/ExaminarProyecto/ExaminarProyecto';
import NuevaMatricula from '../view/pages/Matriculas/NuevaMatricula';
import Matriculas from '../view/pages/Matriculas/Matriculas';
import InspeccionarMatricula from '../view/pages/Matriculas/InspeccionarMatricula';
import EditarEstudiante from '../view/pages/estudiantes/EditarEstudiante';
import AsistenciasEmpleados from '../view/pages/AsistenciasEmpleados/AsistenciasEmpleados';
import ReporteNaranja from '../view/pages/ReporteNaranja/ReporteNaranja';
import ReportePagoRecurrente from '../view/pages/ReportePagoRecurrente/ReportePagoRecurrente';
import CrearEnlacesFormularios from '../view/pages/FormulariosContrato/CrearEnlacesFormularios'
import Contratos from '../view/pages/Contratos/Contratos'
import Examenes from '../view/pages/Examenes/Examenes';
import Sugerencias from '../view/pages/Sugerencias/Sugerencias';
import ProfundizarTema from '../view/pages/ProfundizarTemas/ProfundizarTemas';
import PopUpIntranet from '../view/pages/PopUps/PopUps';
import ProyectosValoraciones from '../view/pages/Valoraciones/ProyectoValoraciones';
import VistaPreviaExamen from '../view/pages/Examenes/VistaPreviaExamen';
import ReporteIndicadores from '../view/pages/ReporteIndicadoresPosventa/ReporteIndicadores';
import Ranking from '../view/pages/Rankin/Rankin'
import IndicadorFinanciero from '../view/pages/IndicadorFinanciero/IndicadorFinanciero'
import Grupos from '../view/pages/GruposWhatsApp/Grupos'
import Enlaces from '../view/pages/EnlacesFinanzas/Enlaces'
import Comunicados from '../view/pages/Comunicados/ListaPopUps';
import WebCheckin from '../view/pages/AsistenciasEmpleados/AsistenciasEmpleados'
import ReporteNaranjaAcademica from '../view/pages/ReporteNaranjaAcademica/ReporteNaranja'
import ComentariosVideos from '../view/pages/RespuestasDocente/ComentariosVideos';
import ListaLeads from '../view/pages/Leads/ListaLeads';
import ReporteDiario from '../view/pages/Leads/ReporteDiario/ReporteDiario';
import ReporteLeadsGeneral from '../view/pages/Leads/ReporteDiario/ReporteLeadsGeneral';
import Campaigns from '../view/pages/Compañas/ListaCampañas';
import ListaRecursosCampaign from '../view/pages/RecursosCampaña/ListaRecursosCampaign';
import ListaAnunciosCampaign from '../view/pages/AnunciosCampaña/ListaAnunciosCampaign';
import ListaWebinars from '../view/pages/Webinars/ListaWebinars';
import ReporteStatusRecursos from '../view/pages/Compañas/ReporteStatusRecursos';
import BolsaDeTrabajo from '../view/pages/BolsaDeTrabajo/BolsaDeTrabajo';
import ForoDocente from '../view/pages/ForoDocente/ForoDocente';
import TemaForo from '../view/pages/TemaForo/TemaForo';
import Proveedores from '../view/pages/Proveedores/Proveedores';
import ContratosProveedores from '../view/pages/ContratosProveedores/ContratosProveedores';
import BoletasDePago from '../view/pages/BoletasDePago/BoletasDePago';
import BasesCertificados from '../view/pages/Certificados/BasesCertificados';
import EmitirCertificados from '../view/pages/Certificados/EmitirCertificados';
import CertificadosEmitidos from '../view/pages/Certificados/CertificadosEmitidos';
import ReporteGeneralCampaign from '../view/pages/ReporteGeneralCampaña/ReporteGeneralCampaign';

/***
 * bton de nuevo profesor a posventa
 * 
 */
export default [

  {
    path: '/bolsa-de-trabajo',
    exact: true,
    page: BolsaDeTrabajo,
    rol: ['RECLUTADOR', 'OPERACIONES']
  }
  ,
  {
    path: '/reporte/afiliaciones',
    exact: true,
    page: ReportePagoRecurrente,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  }
  ,
  {
    path: '/reporte/naranja',
    exact: true,
    page: ReporteNaranja,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  }
  ,
  {
    path: '/comentarios-videos',
    exact: true,
    page: ComentariosVideos,
    rol: ['DOCENTE', 'OPERACIONES']
  }
  ,
  {
    path: '/foro-producto',
    exact: true,
    page: ForoDocente,
    rol: ['DOCENTE', 'OPERACIONES']
  }
  ,
  {
    path: '/foro/tema/:tema',
    exact: true,
    page: TemaForo,
    rol: ['DOCENTE']
  }
  ,

  {
    path: '/proveedores-m',
    exact: true,
    page: Proveedores,
    rol: ['FINANZAS','OPERACIONES','MARKETING','PRODUCTO','UXMANAGER','EXPCLIENTE','VENTAS','JEFE VENTAS']
  }

  ,

  {
    path: '/contratos-proveedores',
    exact: true,
    page: ContratosProveedores,
    rol: ['FINANZAS','OPERACIONES','MARKETING','PRODUCTO','UXMANAGER','EXPCLIENTE','VENTAS','JEFE VENTAS']
  }
  ,
  {
    path: '/reporte/naranja/academica',
    exact: true,
    page: ReporteNaranjaAcademica,
    rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
  }
  ,
  {
    path: '/webcheckin',
    exact: true,
    page: WebCheckin,
    rol: ['EXPCLIENTE', 'OPERACIONES','FINANZAS','PRODUCTO','UXMANAGER','VENTAS','JEFE VENTAS']
  }
  ,
  {
    path: '/grupos-whatsapp',
    exact: true,
    page: Grupos,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  }
  ,
  {
    path: '/enlaces-finanzas',
    exact: true,
    page: Enlaces,
    rol: ['FINANZAS', 'OPERACIONES']
  }
  ,
  {
    path: '/indicadores-proyectos',
    exact: true,
    page: IndicadorFinanciero,
    rol: ['FINANZAS','OPERACIONES','MARKETING','PRODUCTO','UXMANAGER','EXPCLIENTE','VENTAS','JEFE VENTAS']
  }
  ,
  {
    path: '/ventas/ranking',
    exact: true,
    page: Ranking,
    rol: ['EXPCLIENTE','UXMANAGER','VENTAS', 'OPERACIONES','JEFE VENTAS']
  }
  ,
  {
    path: '/reporte-indicadores',
    exact: true,
    page: ReporteIndicadores,
    rol: ['EXPCLIENTE', 'OPERACIONES','PRODUCTO','UXMANAGER','VENTAS','MARKETING','JEFE VENTAS']
  }
  ,
  {
    path: '/reporte-diario-leads',
    exact: true,
    page: ReporteDiario,
    rol: ['MARKETING', 'OPERACIONES','VENTAS','JEFE VENTAS']
  }
  ,
  {
    path: '/reporte-cantidad-leads',
    exact: true,
    page: ReporteLeadsGeneral,
    rol: ['MARKETING', 'OPERACIONES']
  }
  ,
  {
    path: '/listar-comunicados',
    exact: true,
    page: Comunicados,
    rol: ['OPERACIONES']
  }
  ,
  {
    path: '/contratos/enlaces',
    exact: true,
    page: CrearEnlacesFormularios,
    rol: ['VENTAS','EXPCLIENTE','UXMANAGER','OPERACIONES','JEFE VENTAS']
  },
  {
    path: '/temasPorAreas',
    exact: true,
    page: Temas,
    rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/contratos',
    exact: true,
    page: Contratos,
    rol: ['EXPCLIENTE','UXMANAGER','FINANZAS', 'OPERACIONES','VENTAS','JEFE VENTAS']
  },
  {
    path: '/sugerencias',
    exact: true,
    page: Sugerencias,
    rol: ['EXPCLIENTE', 'OPERACIONES','PRODUCTO','UXMANAGER']
  },
  {
    path: '/profundizar-temas-sesion',
    exact: true,
    page: ProfundizarTema,
    rol: ['EXPCLIENTE', 'OPERACIONES','PRODUCTO','UXMANAGER']
  },
  {
    path: '/examenes/vistaPrevia/:idExamen',
    exact: true,
    page: VistaPreviaExamen,
    rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/examenes',
    exact: true,
    page: Examenes,
    rol: ['PRODUCTO','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/popup/intranet',
    exact: true,
    page: PopUpIntranet,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/estudiantes',
    exact: true,
    page: Estudiantes,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/estudiantes/nuevo',
    exact: true,
    page: NuevoEstudiante,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/estudiantes/editar/:idEstudiante',
    exact: true,
    page: EditarEstudiante,
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/nueva-matricula',
    exact: true,
    page: NuevaMatricula,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/matriculas',
    exact: true,
    page: Matriculas,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },

  {
    path: '/matriculas/:idMatricula',
    exact: true,
    page: InspeccionarMatricula,
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/pagos',
    exact: true,
    page: RegistroPagos,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/inicio',
    exact: true,
    page: Inicio,
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','DOCENTE','VENTAS','JEFE VENTAS','RECLUTADOR','TI','EDITOR AUDIOVISUAL']
  },
  {
    path: '/leads',
    exact: true,
    page: ListaLeads,
    rol: ['OPERACIONES','MARKETING','VENTAS','JEFE VENTAS']
  },
  {
    path: '/campaigns',
    exact: true,
    page: Campaigns,
    rol: ['OPERACIONES','MARKETING']
  }
  ,
  {
    path: '/recursos-campaign',
    exact: true,
    page: ListaRecursosCampaign,
    rol: ['OPERACIONES','MARKETING','JEFE VENTAS','VENTAS']
  },
  {
    path: '/anuncios-campaign',
    exact: true,
    page: ListaAnunciosCampaign,
    rol: ['OPERACIONES','MARKETING']
  },
  {
    path: '/status-recursos',
    exact: true,
    page: ReporteStatusRecursos,
    rol: ['OPERACIONES','MARKETING']
  },
  {
    path: '/webinars-ventas',
    exact: true,
    page: ListaWebinars,
    rol: ['OPERACIONES','MARKETING','JEFE VENTAS']
  },
  {
    path: '/pagos/examinar-matricula',
    exact: true,
    page: ExaminarMatricula,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/pagos/examinar-pension',
    exact: true,
    page: ExaminarCuota,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/pagos/examinar-pago-extraordinario',
    exact: true,
    page: ExaminarPagosExtra,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/facturas',
    exact: true,
    page: Facturas,
    rol: ['OPERACIONES', 'FINANZAS']
  },
  {
    path: '/reporte-ingresos',
    exact: true,
    page: ReporteIngresos,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/reporte-ingresos/:idIngreso',
    exact: true,
    page: ExaminarReporteIngresos,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/reporte-deudores',
    exact: true,
    page: ReporteDeudores,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/reporte-cargosdescuentos',
    exact: true,
    page: ReporteCargoDescuentos,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/registro-asistencia',
    exact: true,
    page: Asistencia,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/reporte-asistencia',
    exact: true,
    page: ReporteAsistencia,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/profesores',
    exact: true,
    page: Profesores,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/profesores/nuevo',
    exact: true,
    page: NuevoProfesor,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER', 'FINANZAS']
  },
  {
    path: '/profesores/editar/:idDocente',
    exact: true,
    page: InspeccionarDocente,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/proyectos',
    exact: true,
    page: Proyectos,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/proyectos-satisfaccion/',
    exact: true,
    page: ProyectosValoraciones,
    rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES','MARKETING']
  },
  {
    path: '/proyectos/:idProyecto',
    exact: true,
    page: ExaminarProyecto,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/cursos/:idProyecto/sesiones',
    exact: true,
    page: SesionesCurso,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/cursos/:idCurso/sesiones/:idSesion/notas',
    exact: true,
    page: NotasSesiones,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/cursos/:idProyecto/sesiones/:idSesion/profesores',
    exact: true,
    page: ProfesoresSesion,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/cursos/:idProyecto/asignar/docente',
    exact: true,
    page: AsignacionDocentes,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/modulos',
    exact: true,
    page: ModulosProyecto,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/modulo/:idModulo',
    exact: true,
    page: ModuloProyecto,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/mensajeria',
    exact: true,
    page: Mensajeria,
    rol: ['EXPCLIENTE','UXMANAGER','OPERACIONES']
  },
  {
    path: '/trabajadores',
    exact: true,
    page: Trabajadores,
    rol: ['OPERACIONES','FINANZAS']
  },
  {
    path: '/trabajadores/nuevo',
    exact: true,
    page: NuevoTrabajador,
    rol: ['OPERACIONES','FINANZAS']
  },
  {
    path: '/trabajadores/:idTrabajador/editar',
    exact: true,
    page: InspeccionarTrabajador,
    rol: ['OPERACIONES','FINANZAS']
  },
  {
    path: '/recursos',
    exact: true,
    page: Recursos,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER','DOCENTE','EDITOR AUDIOVISUAL']
  },
  {
    path: '/bases-certificados',
    exact: true,
    page: BasesCertificados,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/emitir-aptos',
    exact: true,
    page: EmitirCertificados,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/diplomas-emitidos',
    exact: true,
    page: CertificadosEmitidos,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/reporte-campaign',
    exact: true,
    page: ReporteGeneralCampaign,
    rol: ['OPERACIONES', 'MARKETING','UXMANAGER']
  },
  {
    path: '/tareas',
    exact: true,
    page: Tareas,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/tareas/:idTarea',
    exact: true,
    page: TareasEstudiantes,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/empresa',
    exact: true,
    page: PerfilEmpresa,
    rol: ['OPERACIONES']
  },
  {
    path: '/reporte/seguimiento',
    exact: true,
    page: GeneralPosventa,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/reporte/desertados',
    exact: true,
    page: ReporteDesertados,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/control-capacitaciones',
    exact: true,
    page: PageCapacitaciones,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/control-capacitaciones/:idCapacitacion/espacio-trabajo',
    exact: true,
    page: ControlCapacitacion,
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/prototipado',
    exact: true,
    page: ProgramacionAnual,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/prototipado/:idProgramacion',
    exact: true,
    page: AreaCiclos,
    rol: ['OPERACIONES', 'PRODUCTO','UXMANAGER']
  },
  {
    path: '/tutores',
    exact: true,
    page: Tutores,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/boletas-de-pago',
    exact: true,
    page: BoletasDePago,
    rol: ['EXPCLIENTE','OPERACIONES', 'PRODUCTO','UXMANAGER', 'TUTORIA', 'FINANZAS','MARKETING','DOCENTE','VENTAS','JEFE VENTAS','RECLUTADOR','TI']
  },
  {
    path: '/tutores/:idTutor/detalles',
    exact: true,
    page: TutoresInfo,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/tutores/:idTutor/detalles/encuesta-docente/:idDocenteFormulario',
    exact: true,
    page: EncuestaDocente,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/tutores/asignar',
    exact: true,
    page: TutoresAsignacion,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES']
  },
  {
    path: '/tutor/eventos',
    exact: true,
    page: EventosTutor,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/tutor/eventos/encuestas/:idTutorForm',
    exact: true,
    page: EncuestaReporte,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },{
    path: '/tutor/eventos/:idEvento/:idEventoTutor/encuestas',
    exact: true,
    page: Encuestas,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/tutor/eventos/:idEvento/:idEventoTutor/encuestas/:idFormulario',
    exact: true,
    page: NuevaEncuesta,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/tutor/eventos/:idEvento/:idEventoTutor/encuestas/:idSesion/:idTutorSesion/nuevo',
    exact: true,
    page: NuevaEncuesta,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/eventos/tutor/:idEvento/sesiones',
    exact: true,
    page: SesionesTutor,
    rol: ['EXPCLIENTE','UXMANAGER', 'OPERACIONES', 'TUTORIA']
  },
  {
    path: '/reporte/calidad',
    exact: true,
    page: ReportesTutoria,
    rol: ['EXPCLIENTE', 'OPERACIONES', 'PRODUCTO','UXMANAGER','MARKETING']
  }
]