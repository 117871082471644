import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { romanize } from '../../../../helpers/functions';
import './PensionesItem.scss';

const PensionesItem = ({ id_dt_plan:id, item, monto, index, nombreConcepto, fecha_limite: fechaLimite, handleEliminarCuota }) => {
  
  
  return (
    <tr>
      <td>{item+1}</td>
      <td>{ index !== null && romanize(index+1)} {nombreConcepto}</td>
      <td>S/{monto}</td>
      <td align='center'>{
          fechaLimite ? fechaLimite : 'No hay datos'
        }</td>
      <td align='center'>{
        handleEliminarCuota && <Button
          variant='danger'
          size='xs'
          onClick={() => handleEliminarCuota(id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      }</td>
    </tr>
  );
}
 
export default PensionesItem;