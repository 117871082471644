import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarHonorariosTrabajador } from '../../actions/trabajadoresActions';

const withEditarHonorarios = WrappedComponent => props => {

  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleEditarHonorarios = honorarios => {
    dispatch( editarHonorariosTrabajador(idTrabajador, honorarios) );
  }

  return ( 
    <WrappedComponent
      {...props}
      honorarios={trabajador.honorarios}
      handleEditarHonorarios={handleEditarHonorarios}
    />
   );
}
 
export default withEditarHonorarios;