import React from 'react';
import Indicador from './Indicador';

const ListarIndicadores = (props) => {

    const {proyectos} = props;

    if(proyectos===undefined || !proyectos || proyectos.lenght===0)
    {
        return "No hay datos."
    }

    return (
        <div className="row">
            {
                proyectos ? proyectos.map((proyecto,i)=>(
                  <Indicador key={i} datos={proyecto}/>
                )): ''
            }
        </div>
    );
};

export default ListarIndicadores;