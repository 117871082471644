import React, {useState, useEffect} from 'react';
import useGetProyectos from '../../../hooks/useGetProyectos';
import { useDispatch, useSelector, connect } from 'react-redux';
import { listarSesionesCursos } from '../../../actions/cursosActions';
import { obtenerExamen, guardarExamen, guardarPregunta, eliminarPregunta} from '../../../actions/examenesActions';
import SimpleSelect from 'react-select';
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import NuevoExamen from "../Examenes/nuevoExamen";
import { Container} from "react-bootstrap";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import MensajeLoading from "../../components/MensajeLoading";
const Examenes = (props) => {

    const {
        loading,
        examen
      } = props;

    const { proyectos } = useGetProyectos();
    const { sesionesCurso } = useSelector(state => state.cursos);
    const [sesion, setSesion] = useState(null);
    const [datoProyecto, setDatoProyecto] = useState({
        proyectoSeleccion: null
      });

    const dispatch = useDispatch();

    useEffect(() => {
      if(datoProyecto.proyectoSeleccion) {
          dispatch(listarSesionesCursos(datoProyecto.proyectoSeleccion.id_proyecto));
          setSesion(null);
        }
    }, [datoProyecto.proyectoSeleccion])

    useEffect(() => {
      if(sesion) {
          dispatch(obtenerExamen(sesion));
  
        }
    }, [sesion])

    return (
        <Layout>
        <HeaderContent title="Quiz" />
        <Breadcrumb
          link={[
            { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
            { url: "/examenes", nombre: "Quiz" }
          ]}
        />
        <div className="encuestas">
         
          <div className="container-fluid px-0 content-actions my-3">
            <div className="row encuetas__content-filtro">
            <div className="col-md-4 filtro">
                <label htmlFor="">Producto</label>
                <SimpleSelect
              options={proyectos}
              isLoading={loading}
              getOptionLabel={item =>  item.nombre_proyecto}
              getOptionValue={item => item.id_proyecto}
              value={datoProyecto.proyectoSeleccion}
              onChange={e => setDatoProyecto(
                datos => ({...datos, proyectoSeleccion: e})
              )}
            />
            </div>
              <div className="col-md-4 filtro">
                <label htmlFor="">Sesiones</label>
                <SimpleSelect
                options={sesionesCurso}
                getOptionLabel={item => item.nombre_sesion}
                getOptionValue={item => item.id_sesion}
                value={sesion}
                onChange={e => setSesion(e)}
                />
              </div>   
            </div>
          </div>
          { !sesion ? (
            <MensajeLoading/>
        ) : (
          <Container>
            <SpinnerLoading loading={loading}>
              <NuevoExamen examen={examen} sesion_p={sesion} guardarExamen={guardarExamen} guardarPregunta={guardarPregunta} eliminarPregunta={eliminarPregunta}/>
            </SpinnerLoading>
          </Container>
        )}
        </div>
      </Layout>
    );

};
const mapStateToProps = (state) => ({
  loading: state.examenes.loading,
  examen: state.examenes.examen
});
export default connect(mapStateToProps)(Examenes) ;