import React from 'react';

//REdux
import { useDispatch } from 'react-redux';
import { nuevoTrabajador } from '../../actions/trabajadoresActions';

const withRegistroTrabajador = WrappedComponent => props => {

  const dispatch = useDispatch();

  const handleNuevoTrabajador = (datos) => {
    dispatch( nuevoTrabajador(datos) )
  }

  return ( 
    <WrappedComponent
      {...props}
      handleSubmitAction={handleNuevoTrabajador}
      areas={true}
    />
   );
}
 
export default withRegistroTrabajador;