import {
  OPEN_MODAL_BASIC,
  CLOSE_MODAL_BASIC,
  OPEN_MODAL_LARGE,
  CLOSE_MODAL_LARGE,
  OPEN_MODAL_CUSTOM,
  CLOSE_MODAL_CUSTOM
} from '../actions/types';


const initialState = {
  modalBasic: {
    show: false
  },
  modalLarge: {
    show: false,
    children: null,
    title: null
  },
  modalCustom: {
    show: false,
    children: null,
    title: null,
    customWidth: null
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL_BASIC:
      return {
        ...state,
        modalBasic: {
          ...state.modalBasic,
          show: true,
          children: action.payload.Component,
          title: action.payload.title
        }
      }
    case CLOSE_MODAL_BASIC:
      return {
        ...state,
        modalBasic: {
          ...state.modalBasic,
          show: false,
          children: null
        }
      }
    case OPEN_MODAL_LARGE:
      return {
        ...state,
        modalLarge: {
          ...state.modalLarge,
          show: action.payload.show,
          children: action.payload.Component,
          title: action.payload.title
        }
      }
    case CLOSE_MODAL_LARGE:
      return {
        ...state,
        modalLarge: {
          ...state.modalLarge,
          show: false,
          children: null
        }
      }
      case OPEN_MODAL_CUSTOM:
        return {
          ...state,
          modalCustom: {
            ...state.modalCustom,
            show: true,
            children: action.payload.Component,
            title: action.payload.title,
            customWidth: action.payload.customWidth
          }
        }
      case CLOSE_MODAL_CUSTOM:
        return {
          ...state,
          modalCustom: {
            ...state.modalCustom,
            show: false,
            children: null
          }
        }
    default:
      return state;
  }
}