import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../../../../actions/modalActions';

const EditarHonorarios = ({ honorarios, handleEditarHonorarios }) => {

  const dispatch = useDispatch();
  const [datosHonorarios, setDatosHonorarios] = useState({
    pago_mes: '',
    pago_hora: '',
    banco:'',
    cci:'',
    n_cuenta:'',
  })

  const { pago_hora, pago_mes,banco,n_cuenta,cci } = datosHonorarios;

  const handleChange = e => {
    setDatosHonorarios({
      ...datosHonorarios,
      [e.target.name]: e.target.value
    })
  }

  const handleModal = () => {
    dispatch(updateStateModalCustom());
  }

  const handleSubmit = e => {
    e.preventDefault();
    handleEditarHonorarios(datosHonorarios)
  }

  useEffect(() => {
    if (honorarios) {
      setDatosHonorarios(honorarios)
    }
  }, [honorarios])

  return ( 
    <div className="EditarHonorarios">
      <div className='EditarHonorarios__container'>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
          <Form.Group>
              <Form.Label>Banco:</Form.Label>
              <Form.Control type='text' name='banco' value={banco} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>N° Cuenta:</Form.Label>
              <Form.Control type='text' name='n_cuenta' value={n_cuenta} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>CCI:</Form.Label>
              <Form.Control type='text' name='cci' value={cci} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Por hora:</Form.Label>
              <Form.Control type='text' name='pago_hora' value={pago_hora} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Por mes:</Form.Label>
              <Form.Control type='text' name='pago_mes' value={pago_mes} onChange={handleChange}/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='text-right'>
            <Button
              variant='dark'
              className='btn-icon'
              onClick={handleModal}
            >
              <FontAwesomeIcon icon={faTrash} />
              Cancelar
            </Button>
            <Button
              type='submit'
              variant='info'
              className='btn-icon'
            >
              <FontAwesomeIcon icon={faSave} />
              Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </div>
    </div>
   );
}

EditarHonorarios.propTypes = {
  honorarios: PropTypes.object.isRequired,
  handleEditarHonorarios: PropTypes.func.isRequired
}
 
export default EditarHonorarios;