import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import FormularioDescuento from '../FormularioDescuentoCargos';
import TablaDescuentos from './TablaDescuentos';

//Redux
import { useSelector, useDispatch } from 'react-redux';

const Descuentos = ({ estadoCuota }) => {

  const dispatch = useDispatch();
  const { descuentos } = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones.todo);

  const handleModalNuevo = () => {
    dispatch(updateStateModalBasic(<FormularioDescuento tipoRegistro='descuentos'/>, 'Nuevo descuento'));
  }

  return ( 
    <div className="Descuentos">
      <div className="Descuentos__head d-flex align-items-center justify-content-between">
        <h4>Descuentos</h4>   
        <span
          onClick={handleModalNuevo}
          className={`btn-span btn-icon-scope ${estadoCuota === 'PAGADO' ? 'error': 'auto'}`}
        >
          <FontAwesomeIcon icon={faPlus} />
          Nuevo
        </span>
      </div>
      <div className="Descuentos__content box mt-3">
        <TablaDescuentos
          descuentos={descuentos}
        />
      </div>
    </div>
  );
}
 
export default Descuentos;