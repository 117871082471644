import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
//import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
//import ListarReporte from "./ListarReporte";
//import DatePicker from "react-datepicker";
//import * as moment from 'moment';
import { reporteStatusCampaign } from '../../../actions/campaignsActions';
import { Table } from "react-bootstrap";
 
const ReporteStatusRecursos = () => {
  const dispatch = useDispatch();
  const[loading, setLoading] = useState(false)
  const[reporteG,setReporteG] = useState(null)
 /* const[fechaInicio,setFechaInicio] = useState(null)
  const[fechaFin,setFechaFin] = useState(null)
  const {usuario} = useSelector(state=>state.usuario)*/

  const fetchData = async () =>{
   setLoading(true);
   const respuesta = await (dispatch(reporteStatusCampaign()))
  // console.log(respuesta)
   setReporteG(respuesta)
    setLoading(false);
  }
  const traerDatos = () => {
    
    fetchData()

  }

  useEffect(()=>{
    traerDatos()
  },[])

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Reporte Status Recursos" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Campañas" },
                { url: "", nombre: "Reportes" },
                { url: "", nombre: "Reporte Status Recursos" },
              ]}
            />
          </div>
   
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <SpinnerLoading loading={loading}>

         { reporteG ?  <div className="box p-3">  <Table responsive>
                        <thead>
                          <tr>
                            <th>Campaña</th>
                            { reporteG ? reporteG.labels.map((label, index) => (
                              <th key={index}>{label}</th>
                            )) : ''}
                          </tr>
                        </thead>
                        <tbody>
                        { reporteG ? reporteG.data.map((registro, index) => (
                            <tr key={index}>
                                <td> {registro.nombre_proyecto} </td>
                                { reporteG.campos.map((campo,i)=>(

                                    <td className="text-center" key={i}>{ parseInt(reporteG.data[index][campo])>=1 ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i> }</td>

                                )) }
                            </tr>
                            )) : ''}
                        </tbody>
                      </Table> </div> : ''
          }
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReporteStatusRecursos;