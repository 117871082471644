import React, { useState } from 'react';
import withRangoProfesionalDocente from "../../hoc/Docentes/withRangoProfesionalDocente";
import ListaRangoProfesional from "../../view/components/RegistroUsuarios/Clasificacion/EditarClasificacion/ListaRangoProfesional";
import { compose } from "redux";

//Redux
import { useDispatch } from 'react-redux';
import { agregarIndicador } from '../../actions/profesoresActions';

const withListaRangoProfesionaActions = WrappedComponent => props => {

  const dispatch = useDispatch();
  const [clasificacion, setClasificacion] = useState([
    {
      label: 'Docente universitario (de cátedra) en pregrado y/o posgrado.',
      value: '1',
    },
    {
      label: 'Laboralmente se dempeña en el tema a dictar u ocupa un de alto cargo en entidad o empresa del sector.',
      value: '2',
    },
    {
      label: 'Autor de libros relacionados al tema a dictar.',
      value: '3',
    },
    {
      label: 'Estudios de posgrado, deseable en el extranjero.',
      value: '4',
    },
    {
      label: 'Estudios de formación profesional en Universidades reconocidas del País. Deseable; en la PUCP, UL, UP o UNMSM.',
      value: '5',
    }
  ])

  const handleAgregarNuevoindicador = (seleccion, idDocente) => {
    dispatch( agregarIndicador(seleccion.value, idDocente) )
  }

  return (
    <WrappedComponent
      {...props}
      handleAgregarIndicador={handleAgregarNuevoindicador}
      clasificacion={clasificacion}
    />
  )
}

export default compose(withRangoProfesionalDocente, withListaRangoProfesionaActions)(ListaRangoProfesional);