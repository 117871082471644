import React from 'react';
import ResumenCvwithQuery from '../../../../../../containers/DocentesContainers/ResumenCvwithQuery';
import './VistaPerfilProfesionalDocente.scss';
import ArchivosAdjuntosContainer from '../../../../../../containers/DocentesContainers/ArchivosAdjuntosContainer';

const VistaPerfilProfesionarDocente = () => {
  return ( 
    <div className="VistaPerfilProfesionarDocente">
      <div className="VistaPerfilProfesionarDocente__container">
        <ResumenCvwithQuery />
        <ArchivosAdjuntosContainer />
      </div>
    </div>
   );
}
 
export default VistaPerfilProfesionarDocente;