import React from 'react';
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { eliminarIndicadorDocente } from '../../actions/profesoresActions';

const withRangoProfesionalDocente = WrappedComponent => props => {

  const dispatch = useDispatch();
  const { idDocente } = useParams();

  const { data, dt_clasificacion: { cant_puntos: puntos, nombre_estado: estado } } = useSelector(
    state => state.profesores.docente[idDocente].clasificacion
  )

  const nuevo = data.filter(item=>item.id_estado==='1')

  const handleDeleteIndicador = (idClasificaion) => {
    dispatch( eliminarIndicadorDocente(idClasificaion, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      listaSeleccionado={nuevo}
      puntos={parseInt(puntos)}
      estado={estado}
      handleDeleteIndicador={handleDeleteIndicador}
    />
   );
}
 
export default withRangoProfesionalDocente;