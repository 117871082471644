import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { subirAvatarDocente } from '../../actions/profesoresActions';

const withAvatarUsuarioDocente = (WrappedComponent) => props => {
  
  const dispatch = useDispatch();
  const [docente, idDocente] = useGetDocente();

  const handleChangeAvatar = e => {
    dispatch(subirAvatarDocente(e.target.files[0], idDocente));
  }

  return ( 
    <WrappedComponent
      {...props}
      urlAvatar={docente.img_usuario}
      handleChangeAvatar={handleChangeAvatar}
    />
   );
}
 
export default withAvatarUsuarioDocente;