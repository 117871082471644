import React from 'react';
import {Button} from "react-bootstrap";
import { useDispatch } from 'react-redux';
const Pregunta = ({id_examen,id_pregunta,pregunta,puntaje,eliminarPregunta}) => {

  const dispatch = useDispatch();
  const datosPregunta ={
    idExamen:id_examen,
    idPregunta:id_pregunta
  }

  const confirmarEliminiacion = (datos) => {
   
    dispatch(eliminarPregunta(datosPregunta))
  }
  return ( 
    <div style={{display:"flex", textAlign:"left", padding:"10px"}}>
      <div style={{width:'350px'}}>{pregunta}</div>
      <div style={{minWidth:"60px", textAlign:'center'}}>{puntaje}</div>
      <div ><Button variant='danger'  onClick={() => confirmarEliminiacion(datosPregunta)}> <i className="fa fa-trash" aria-hidden="true"></i> </Button></div>
    </div>
  );
}
 
export default Pregunta;