import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';
import EditarHonorariosContainer from '../../containers/DocentesContainers/EditarHonorariosContainer';

const withVistaHonorarios = WrappedComponent => props => {
  
  const [docente] = useGetDocente();
  const dispatch = useDispatch();

  const handleEditar = () => {
    dispatch( updateStateModalCustom(<EditarHonorariosContainer />, 'Editar Honorarios', 'modal-w30') )
  }

  return ( 
    <WrappedComponent
      {...props}
      {...docente.honorarios}
      handleEditar={handleEditar}
    />
   );
}
 
export default withVistaHonorarios;