import React from "react";
import ListDocenteItem from "../RelacionDocentes/ListDocenteItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { guardarPonenteTentativo } from "../../../../../actions/controlCapacitacionesActions";

const ListaDocentesDisponibles = () => {
  const { idCapacitacion } = useParams();
  const { docentes_filtro } = useSelector(
    (state) => state.control_capacitacion.area_trabajo.datos[idCapacitacion]
  );
  const { activo: sesion } = useSelector(
    (state) => state.control_capacitacion.area_trabajo
  );
  const dispatch = useDispatch();

  const handleButton = (docente) => {
    
    dispatch(
      guardarPonenteTentativo(
        sesion.id_sesion,
        docente.id_filtro_docente,
        sesion.id_reporte_control_capacitacion,
        idCapacitacion
      )
    );
  };

  return (
    <div className="lista-docente-disponibles relacion-docentes">
      <p className='acciones-tabla-control__subtitle'>
        Lista docentes
      </p>
      <div className='lista-docentes__scroll'>
        {docentes_filtro.length ? (
          <ListDocenteItem
            docentes={docentes_filtro}
            render={(docente) => (
              <div>
                <button
                  className="btn-success btn btn-sm"
                  onClick={() => handleButton(docente)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            )}
          />
        ) : (
          <p>No hay docentes relacionados a esta capacitación.</p>
        )}
      </div>
    </div>
  );
};

export default ListaDocentesDisponibles;
