import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarAsesores } from '../actions/leadsActions';

const useSelectAsesores = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const {asesores} = useSelector(state => state.leads)

  const [asesorSeleccionado, setAsesorSeleccionado] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const proyectoSelect = asesores.find(asesor => asesor.id_usuario === parseInt(initialSelect)); 
      if(proyectoSelect) {
        setAsesorSeleccionado(proyectoSelect);
      }
    }
  }

  useEffect(() => {
    if(!asesores.length) {
      dispatch(listarAsesores())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [asesores])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!asesores.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={asesor => setAsesorSeleccionado(asesor)}
        value={asesorSeleccionado}
        getOptionValue={asesores => asesores.id_usuario}
        getOptionLabel={asesores => asesores.nombre_usuario}
        options={asesores}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [asesorSeleccionado, SimpleSelect, setAsesorSeleccionado]

}
 
export default useSelectAsesores;