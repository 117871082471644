import React from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../actions/modalActions';

const withModalBasicContainer = WrappedComponent => props => {
  
  const dispatch = useDispatch()
  const { show, children, title } = useSelector(state => state.modal.modalBasic)

  const actualizarEstado = estado => {
    dispatch( updateStateModalBasic(estado) )
  }

  return ( 
    <WrappedComponent
      {...props}
      show={show}
      updateShowModal={actualizarEstado}
      children={children}
      title={title}
    />
   );
}
 
export default withModalBasicContainer;