import React from 'react';
import { Table, Row, Col } from 'react-bootstrap'
import TablaResumenEventoEncuesta from '../TablaResumenEventoEncuesta/TablaResumenEventoEncuesta';

//Redux
import { connect } from 'react-redux'

import './TablaReporteEncuestas.scss';

const TablaReporteEncuestas = props => {

  const { reporteTutoria } = props
  const { tablaSeguimiento } = reporteTutoria

  if (tablaSeguimiento === undefined) return null

  return ( 
    <Row className='tabla-reporte-encuestas'>
    <Col md={8} xs={12}>
      <TablaResumenEventoEncuesta/>
    </Col>
    <Col md={2} className='px-0'>
    <RakingMayor
          reporteTutoria={reporteTutoria}
        />
    </Col>
    <Col md={2} className='px-0'>
    <RakingMenor
          reporteTutoria={reporteTutoria}
        />
    </Col>
    <Col md={12} className=" table-responsive-lg pr-1">
      
      <ReporteResumen
        reporteTutoria={reporteTutoria}
      />
    </Col>
    
      
    </Row>
   );
}
 
const mapStateToProps = state => ({
  reporteTutoria: state.reporte.reporteTutoria
})

export default connect(mapStateToProps, {})(TablaReporteEncuestas);

const ReporteResumen = props => {

  const { reporteTutoria } = props
  const { tablaSeguimiento } = reporteTutoria

  return (
    <Table striped bordered hover responsive className='border'>
        <thead>
          <tr>
            <th className='th__fecha'>Fecha</th>
            {tablaSeguimiento.length > 0 ? (
              tablaSeguimiento.map( (item, index) => (
                <th key={index} className='th__final--valor'>
                  {item.fecha}
                </th>
              ))                
            ) : (
              <th>No hay encuestas</th>
            )}
            <th className='th__final'>PORCENTAJE FINAL DE</th>
        
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ponente</td>
            {tablaSeguimiento.length > 0 ? (
              tablaSeguimiento.map((item, index) => (
                <td key={index}>{(parseFloat(item.porcentajePonente) * 100).toFixed(2)}%</td>
              ))
            ) : ('No hay')}
            <td>{ (parseFloat(reporteTutoria.porcentajeDocente) * 100).toFixed(2)}%</td>
           
          </tr>
          <tr>
          <td>Tutor</td>
            {tablaSeguimiento.length > 0 ? (
                tablaSeguimiento.map((item, index) => (
                  <td key={index}>{(parseFloat(item.porcentajeTutor) * 100).toFixed(2)}%</td>
                ))
            ) : ('ss')}
            <td>{(parseFloat(reporteTutoria.porcentajeTutor) * 100).toFixed(2)}%</td>
           
          </tr>
          <tr>
           <td>N° Universo</td>
           {tablaSeguimiento.length > 0 ? (
              tablaSeguimiento.map((item, index) => (
                <td key={index}>{item.CantidadUniverso}</td>
              ))
            ) : ('ss')}
           </tr>
            <tr>
            <td>N° Muestras</td>
            {tablaSeguimiento.length > 0 ? (
              tablaSeguimiento.map((item, index) => (
                <td key={index}>{item.CantidadMuestra}</td>
              ))
            ) : ('ss')}
            </tr>
          
        </tbody>
      </Table>
  )
}

const RakingMayor = props => {

  const  { reporteTutoria } = props
  return(
    <>
    <Table striped bordered hover responsive className='border'>
      <thead>
        <tr>
          <th className='th__rating'>RANKING PONENTES MAYOR PUNTAJE</th>

        </tr>
      </thead>
      <tbody>
      { reporteTutoria.RankignPonenteMayorPuntaje[0] !== undefined  ? (
          reporteTutoria.RankignPonenteMayorPuntaje.map((ranking, index) => (
            <tr key={index}>
              <td>
              {`${ranking.nombre_usuario} 
                ${ranking.apellido_usuario}
                ${((ranking.porcentaje) * 100).toFixed(2)}%`}
              </td>
            </tr>
          ))
                
              ) : (
                ''
              )}
      </tbody>
    </Table>
    
    </>
  )
}

const RakingMenor = props => {
  const  { reporteTutoria } = props

  return (
    <Table striped bordered hover responsive className='border'>
      <thead>
        <tr>
         
          <th className='th__rating'>RANKING PONENTES MENOR PUNTAJE</th>
        </tr>
      </thead>
      <tbody>
      { reporteTutoria.RankignPonenteMenorPuntaje[0] !== undefined  ? (
          reporteTutoria.RankignPonenteMenorPuntaje.map((ranking, index) => (
            <tr key={index}>
              <td>
              {`${ranking.nombre_usuario} 
                ${ranking.apellido_usuario}
                ${((ranking.porcentaje) * 100).toFixed(2)}%`}
              </td>
            </tr>
          ))
                
              ) : (
                ''
              )}
      </tbody>
    </Table>
  )
}