import React from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalLarge } from '../actions/modalActions';

const withModalLargeShow = WrappedComponent => props => {
  
  const dispatch = useDispatch()
  const { show, children, title } = useSelector(state => state.modal.modalLarge)
  
  const actualizarEstado = estado => {
    dispatch( updateStateModalLarge(estado) )
  }

  return ( 
    <WrappedComponent
      {...props}
      show={show}
      updateModalLarge={actualizarEstado}
      children={children}
      title={title}
    />
   );
}
 
export default withModalLargeShow;