import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import * as moment from "moment";
import LeftContent from "./LeftContent";
import RigthContent from "./RigthContent";
import SimpleSelect from "../../components/plugins/SimpleSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./ReporteAsistencia.scss";

//Redux
import { connect } from "react-redux";
import { obtenerAsistencias } from "../../../actions/reportesActions";
import { listarProyectosSelect } from "../../../actions/cursosActions";
import { listarEstudiantesSelect } from "../../../actions/estudiantesActions";
import MensajeLoading from "../../components/MensajeLoading";

const ReporteAsistencia = (props) => {
  //State redux
  const {
    select_proyectos,
    selectEstudiante,
    loading,
    reporteAsistenciaTabla,
  } = props;
  //Funciones redux
  const {
    obtenerAsistencias,
    listarProyectosSelect,
    listarEstudiantesSelect,
  } = props;

  //state
  const [dataConsulta, setDataConsulta] = useState({
    start: "",
    end: "",
    idDiscente: 0,
    idProyecto: "",
  });

  const { start, end, idDiscente, idProyecto } = dataConsulta;

  //Funcion para guardar las fechas
  const setFecha = (fechaStart, fechaEnd) => {
    setDataConsulta({
      ...dataConsulta,
      start: moment(fechaStart).format("YYYY-MM-DD"),
      end: moment(fechaEnd).format("YYYY-MM-DD"),
    });
  };

  //Funcion para guardar el idProyecto
  const guardarIdProyecto = (option) => {
    setDataConsulta({
      ...dataConsulta,
      idProyecto: option.value,
    });
  };

  //Funcion para obtener iniciar la consulta para obetner el reporte de asistencias
  const obtenerReporteAsistencia = () => {
    if (start === "" || end === "" || idProyecto === "") {
      return;
    }
    obtenerAsistencias(dataConsulta);
  };

  useEffect(() => {
    listarProyectosSelect();
    listarEstudiantesSelect();
  }, []);

  useEffect(() => {
    obtenerReporteAsistencia();
  }, [dataConsulta]);

  return (
    <Layout>
      <div className="reporte-asistencia">
        <HeaderContent title="Reportes asistencia" icon={"assessment"} />
        <Breadcrumb
          link={[
            { nombre: "Inicio", url: "/inicio", icon: "fas fa-home" },
            { nombre: "Reporte asistencia", url: "/reporte-asistencia" },
          ]}
        />

        <div className="content-shearch d-flex justify-content-start">
          <div className="contenido">
          </div>
          <div className="contenido">
            <SimpleSelect
              defaultValue={[{ label: "--Proyectos--", value: "" }]}
              options={select_proyectos}
              getSelection={guardarIdProyecto}
            />
          </div>
          <div className="contenido">
            <SimpleSelect
              defaultValue={[{ label: "--Discentes--", value: "" }]}
              options={selectEstudiante}
            />
          </div>
          <Button type="button" clasName="btn btn-buscar">
            <FontAwesomeIcon icon={faSearch} />
            Buscar
          </Button>
        </div>
        <Container fluid className='px-0'>
          {loading !== null ? (
            loading === true ? (
              <div className="">
                <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
              </div>
            ) : (
              <Row>
                <Col md={9} className="pl-0 left-content">
                  <LeftContent
                    reporteAsistenciaTabla={reporteAsistenciaTabla}
                  />
                </Col>
                <Col md={3} className="right-content border shadow-sm">
                  <RigthContent />
                </Col>
              </Row>
            )
          ) : (
            <MensajeLoading/>
          )}
        </Container>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  select_proyectos: state.cursos.select_proyectos,
  selectEstudiante: state.estudiantes.selectEstudiante,
  reporteAsistenciaTabla: state.reporte.reporteAsistenciaTabla,
  loading: state.reporte.loading,
});

const mapDispatchToprops = {
  obtenerAsistencias,
  listarProyectosSelect,
  listarEstudiantesSelect,
};

export default connect(mapStateToProps, mapDispatchToprops)(ReporteAsistencia);
