import {
    SUBIR_RECURSO,
    RECURSO_EDITADO,
    ELIMINAR_RECURSO,
    ELIMINAR_VIDEO,
    INSERTAR_VIDEOS,
    LISTAR_ARCHIVOS,
    LISTAR_VIDEOS,
    ERROR_CONSULTA
} from '../actions/types'

const initialState = {
    recursos: [],
    videos: [],
    podcasts:[],
    error: null
}

export default function(state = initialState, action){
    switch (action.type) {
        case SUBIR_RECURSO:
            return{
                ...state,
                recursos: action.payload
            }
        case LISTAR_ARCHIVOS:
            return{
                ...state,
                recursos: action.payload
            }
        case ELIMINAR_RECURSO:
            return{
                ...state,
                recursos: action.payload
            }
        case INSERTAR_VIDEOS:
            return{
                ...state,
                videos: action.payload
            }
        case ELIMINAR_VIDEO:
            return{
                ...state,
                videos: action.payload
            }
        case LISTAR_VIDEOS:
            return{
                ...state,
                videos: action.payload
            }
        case RECURSO_EDITADO:
            return {
                ...state,
                recursos: action.payload
            }
        case ERROR_CONSULTA:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}