import React, {useEffect, useState} from 'react';
import HeaderContent from '../General/HeaderContent'
import Layout from '../../../app/Layout'
import Breadcrumb from '../General/Breadcrumb'
import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight'
import './perfilEmpresa.scss';

//Redux
import {connect} from 'react-redux'
import {listarInfoEmpresa} from '../../../actions/empresaAction';
import FormInfoEmpresa from './FormInfoEmpresa';
import FormMisionVisión from './FormMisionVision';
import Modal from './Modal';
import FormCuentaB from './FormCuentaB';

const PerfilEmpresa = (props) => {

    //Funciones
    const {listarInfoEmpresa} = props
    const [formulario, setFormulario] = useState('') 


    useEffect(() => {
        listarInfoEmpresa()
    }, [listarInfoEmpresa])

    return ( 
        <Layout>
            <HeaderContent
                title='Empresa'
            />
            <Breadcrumb
                link={[
                    {nombre: 'Inicio', url:'/inicio', icon: 'fas fa-home'},
                    {nombre: 'Empresa', url:'/empresa'}
                ]}
            />

            <div className="perfil-empresa">
                <div className="row">
                    <div className="col-md-4">
                        <ContentLeft
                            setFormulario={setFormulario}
                            formulario={formulario}
                        />
                    </div>
                    <div className="col-md-8">
                        <ContentRight
                            setFormulario={setFormulario}
                            formulario={formulario}
                        />
                    </div>
                </div>
            </div>
            <Modal
                titulo={formulario}
            >
            {formulario === 'vision' ?
                <FormMisionVisión/>
            : 
            formulario === 'empresa'?
                <FormInfoEmpresa/>
            : 
                <FormCuentaB/>
            }
            </Modal>
        </Layout>
     );
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
    listarInfoEmpresa
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PerfilEmpresa);