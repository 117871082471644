import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import './HeaderSeccionModalidad.scss';

const HeaderSeccionModalidad = ({ title, icon, handleClick }) => {
  return ( 
    <div className='HeaderSeccionModalidad form-header'>
      <div className='title'>
        {icon && <FontAwesomeIcon icon={icon} />}
        {title}
      </div>
      <Button variant='success' onClick={handleClick}>
        Nuevo plan
      </Button>
    </div>
   );
}
 
export default HeaderSeccionModalidad;