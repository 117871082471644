import React from 'react';
import {Table,Card} from 'react-bootstrap';
import Rating from '@material-ui/lab/Rating';
const resultadocalificaciones = ({calificaciones}) => {

   // console.log(calificaciones)
    if(calificaciones===undefined || !calificaciones || calificaciones.lenght===0) return (
        <>
        <Card className="p-3 mb-3">
         <Card.Title>Reporte de Satisfacción</Card.Title>
         <Card.Body>
            No hay datos para mostrar
         </Card.Body>
        </Card>
        </>
    );
    return (
        <>
{
        calificaciones.map((proyecto,i)=>(

            <Card key={i} className="p-3 mb-3">
          
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th colSpan="2">Producto</th>
                            <th>Calificación</th>
                            </tr>
                        </thead>
                        <tbody>
                
                        <tr>
                        <td style={{width:'350px'}} className="text-center" colSpan="2">{proyecto.nombre_proyecto}</td>
                        <td className="text-center"><Rating defaultValue={parseInt(proyecto.promedio)} max={10} readOnly/></td>
                        </tr>

                        <tr>
                        <td className="text-center">Total: {proyecto.cantidad ? proyecto.cantidad : 0}</td>
                        <td className="text-center">Universo: {proyecto.activos ? (parseInt(proyecto.activos) + parseInt(proyecto.culminado))+ ' ('+ parseFloat(((parseInt(proyecto.activos) + parseInt(proyecto.culminado))/parseInt(proyecto.cantidad))*100).toFixed(2) +'%) del Total' : 0}</td>
                        <td className="text-center">Muestra: {proyecto.cantidad ? proyecto.cantidad_calificaciones + ' ('+ parseFloat((proyecto.cantidad_calificaciones/(parseInt(proyecto.activos) + parseInt(proyecto.culminado)))*100).toFixed(2) +'%) del Universo': 0}</td>
                        </tr>
                    
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

        ))

}
        </>
    );
};

export default resultadocalificaciones;
