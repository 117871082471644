import {
  OBTERNET_APUNTES,
  OBTERNET_APUNTES_EXITO,
  AGREGAR_APUNTE_EXITO,
  EDITAR_APUNTE_EXITO,
  AGREGAR_APUNTE_ERROR,
  GUARDAR_APUNTE_EXITO,
  ELIMINAR_APUNTE_EXITO,
  ACTUALIZAR_ESTADO_APUNTE
} from '../actions/types';

const initialState = {
  apuntes:  [],
  apunteEditar: {},
  id: null,
  loading: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTERNET_APUNTES:
      return {
        ...state,
        loading: true
      }
    case OBTERNET_APUNTES_EXITO:
      return {
        ...state,
        apuntes: action.payload.datos,
        id: action.payload.id,
        loading: null
      }
    case AGREGAR_APUNTE_EXITO:
    case EDITAR_APUNTE_EXITO:
    case ACTUALIZAR_ESTADO_APUNTE:
      return {
        ...state,
        apuntes: action.payload
      }
    case GUARDAR_APUNTE_EXITO:
      return {
        ...state,
        apunteEditar: action.payload
      }
    case 'LIMPIAR_EDITAR_STATE':
      return {
        ...state,
        apunteEditar: {}
      }
    case ELIMINAR_APUNTE_EXITO:
      return {
        ...state,
        apuntes: action.payload
      }
    default:
      return state;
  }
}