import React from 'react';
import { Card } from 'react-bootstrap';
import {Bar} from 'react-chartjs-2';


const BarChart = (props) => {
  
    const  backgroundColorSelector = [
      'rgba(255, 99, 132, 0.4)',
      'rgba(54, 162, 235, 0.4)',
      'rgba(255, 206, 86, 0.4)',
      'rgba(75, 192, 192, 0.4)',
      'rgba(153, 102, 255, 0.4)',
      'rgba(255, 159, 64, 0.4)'
    ];
    const borderColorSelector = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ];
    const state = {
        labels: props.info.labels,
        datasets: [
          {
            label: props.info.label,
            backgroundColor: backgroundColorSelector[props.color],
            borderColor:borderColorSelector[props.color],
            borderWidth: 1,
            data: props.info.data
          }
        ]
      }
      
   return (
    <Card className="p-3 mb-3">
      <Card.Body>
              <Bar
              data={state}
              options={{
                title:{
                  display:true,
                  text:props.info.title,
                  fontSize:20
                },
                legend:{
                  display:true,
                  position:'bottom'
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            >  
            </Bar>
      </Card.Body>
    </Card>
    );
};

export default BarChart;