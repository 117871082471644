import React, { useEffect, useState } from "react";
import HeaderContent from "../General/HeaderContent";
import Breadcrumbs from "../General/Breadcrumb";
import MaterialtableDemo from '../../components/plugins/MaterialTableDemo'
import ModalAsignacionDocenteCopy from "./creacion/modal/ModalAsignacionDocenteCopy";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2'
//REDUX
import {connect} from 'react-redux'
import { listarProfesores, listarProfesoresCursos, listarProfesoresFaltantes, eliminarDocenteCurso} from "../../../actions/profesoresActions";
import { buscarCurso } from "../../../actions/cursosActions";
import Layout from "../../../app/Layout";

const AsignacionDocentes = ({ profesoresCurso,eliminarDocenteCurso, listarProfesoresFaltantes, listarProfesores,listarProfesoresCursos, buscarCurso,curso }) => {

  const { idProyecto } = useParams()

  const [dataProfesores, setDataProfesores] = useState([])

  const llenarDataProfesores = () => {
    let dataGenerado = []

    profesoresCurso.map(profesor => {
      dataGenerado = [...dataGenerado,{
        id: profesor.id_docente,
        id_profesor: profesor.id_docente,
        nombre_profesor: `${profesor.nombre_usuario} ${profesor.apellido_usuario}`,
        descripcion_profesor: `${profesor.descripcion}`,
        button: <button
          className="btn btn-danger btn-sm"
          onClick={() => eliminarProfesorCursos(profesor.id_docente_modulo)}
          >
            <i className='fas fa-trash'></i>
          </button>
      }]
    })

    setDataProfesores(dataGenerado)
  }
  //Eliminar profesor del curso
  const eliminarProfesorCursos = (idDocenteCurso) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          if((await  eliminarDocenteCurso(idDocenteCurso, idProyecto)).mensaje) {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Hubo un error"
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Curso se elimino correctamente",
            "success"
          );
        }
      });
   
  }

  useEffect(() => {
    listarProfesores();
    buscarCurso(idProyecto)
    listarProfesoresCursos(idProyecto)
    listarProfesoresFaltantes(idProyecto)
  }, []);

  useEffect(() => {
      llenarDataProfesores()

  }, [profesoresCurso])

  return (
    <Layout>
      <HeaderContent
        title={"Asignar Docentes"}
        icon={"supervised_user_circle"}
        subItem={curso.nombre_curso}
      />
      <Breadcrumbs
        link={[
          {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
          {url:'/cursos', nombre: 'Módulos'},
          {url:'', nombre: 'Docentes'}
        ]}
      />

      <div className="clearfix">
        <div className="container-fluid px-0 content-actions my-3">
          <div className="row">
            <div className="col-md-9">
              
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary float-right text-white"
                data-toggle="modal"
                data-target=".modal-asignar-profesor"
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">add</i>
                  <span className="text-white"> Añadir Docente</span>
                </p>
              </button>
              <ModalAsignacionDocenteCopy
                idCurso={idProyecto}
              />
            </div>
          </div>
        </div>
        <div className="p-0 mb-5 bg-white">
          {profesoresCurso.length > 0 ? (
            <React.Fragment>
              <div className="continer-fluid border-bottom-0 border">
                <h5
                  className="p-3 d-flex align-items-center mb-0"
                  style={{ color: "#434343" }}
                >
                  <i className="fas fa-list pr-2"></i>Lista de profesores asignados
                </h5>
              </div>
              <div className="tabla border">
                <MaterialtableDemo
                  colums={column}
                  tabla={dataProfesores} />
              </div>
            </React.Fragment>
          ) : (
            <div
              className="alert alert-danger my-5 d-flex align-items-center"
              role="alert"
            >
              <i className="material-icons mr-2">warning</i>No hay docentes
              regitrados en este curso
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
const mapstateToProps = state => ({
    curso: state.cursos.curso,
    profesoresCurso: state.profesores.profesoresCurso,
    profesores: state.profesores.profesores
}) 
export default connect(mapstateToProps, {eliminarDocenteCurso, listarProfesores, listarProfesoresCursos, listarProfesoresFaltantes, buscarCurso}) (AsignacionDocentes);

const column = [
  {
    text: "ID",
    key: "id_profesor",
    align: "left",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Nombre",
    key: "nombre_profesor",
    align: "left",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Descripcion",
    key: "descripcion_profesor",
    align: "left",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Acciones",
    key: "button",
    align: "rigth",
    sortable: true,
    className: "type_of_food"
  }
];
