import React from 'react';
import ListaIndicadores from './ListaIndicadores';
import IndicadorAvance from './IndicadorAvance';
import './ProcesosIntranet.scss';
import { Modal } from 'react-bootstrap';

const ProcesosIntranet = () => {
  return ( 
    <div className="ProcesosIntranet">
      <Modal.Body>
        <section>
          <span className='ProcesosIntranet__title sub-title'>Lista de indicadores</span>
          <ListaIndicadores />
        </section>
        <section>
          <span className='ProcesosIntranet__title sub-title'>Indicador de avance</span>
          <IndicadorAvance />
        </section>
      </Modal.Body>
    </div>
   );
}
 
export default ProcesosIntranet;