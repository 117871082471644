import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import useTotalCargos from '../../../../hooks/useTotalCargos';
import useTotalDescontado from '../../../../hooks/useTotalDescontado';
import TablaResumenPension from '../ResumenPension/TablaResumenPension.js/TablaResumenPension';

const ResumenFactura = ({ descuentos = [], cargosVariables = [], total, estado, detallesAnulacion = {} }) => {

  const { totalDescuento, obtenerTotalDescuento } = useTotalDescontado();
  const { totalCargos, obtenerTotalCargos } = useTotalCargos();

  useEffect(() => {
    obtenerTotalDescuento(descuentos)
    obtenerTotalCargos(cargosVariables)
  }, [descuentos, cargosVariables])

  return ( 
    <div className="ResumenFactura">
      <h4 className='title-head'>Resumen de factura</h4>
      <TablaResumenPension
        totalDescuento={totalDescuento}
        totalCargos={totalCargos}
        totalCuota={total}
      />
      <Alert variant={estado === 'Valido' ? 'success' : 'danger'}>
        <Alert.Heading>Estado: {estado}</Alert.Heading>
        {
          estado === 'Anulado' && <>
            <hr/>
            <p><span className='font-weight-bold'>Motivo:</span> {detallesAnulacion.motivo}</p>
            <p><span className='font-weight-bold'>Usuario responsable:</span> {detallesAnulacion.nombre_empleado}</p>
            <p><span className='font-weight-bold'>Fecha anulado:</span> {detallesAnulacion.fecha}</p>
          </>
        }
      </Alert>
    </div>
  );
}
 
export default ResumenFactura;