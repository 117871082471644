import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';

const TemaArea = ({ tema_area }) => {
  return ( 
    <li className="TemasArea list-unstyled my-2">
      <FontAwesomeIcon icon={faArchive} className='mr-1' />
      <span>{ tema_area }</span>
    </li>
   );
}
 
TemaArea.propTypes = {
  tema_area: PropTypes.string.isRequired
}

export default TemaArea;