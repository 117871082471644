import React, { useEffect, useState } from 'react';
import { Form, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import MaterialTableDemo from '../../plugins/MaterialTableDemo';


const TablaAsistencia = props => {

  const [asistenciasCustom, setAsistenciasCustom] = useState([]);
  const [checkTodo, setCheckTodo] = useState(false);
  const { 
    asistencias = [],
    setEstudianteSeleccion,
    agregarAsistenciaCheck,
    eliminarAsistenciaCheck
  } = props;

  const agregarStatusCheck = () => {
    const nuevaAsistencias = [...asistencias].map(asistencia => ({
      ...asistencia,
      check: false
    }));
    setAsistenciasCustom(nuevaAsistencias);
  }

  const actualizarCheck = (record) => {
    if(!record.check) {
      agregarAsistenciaCheck(record);
    } else {
      eliminarAsistenciaCheck(record);
    }
    const nuevaAsistencias = [...asistenciasCustom].map(
      asistencia => asistencia.id_estudiante_sesion === record.id_estudiante_sesion
        ? {...asistencia, check: !asistencia.check }
        : asistencia
    );
    setAsistenciasCustom(nuevaAsistencias);
  }

  const actualizarCheckTodo = () => {
    if(!checkTodo) {
      actualizarTodaFila(true);
    } else {
      actualizarTodaFila(false)
    }
  }

  const actualizarTodaFila = (estado) => {
    const nuevaAsistencias = [...asistenciasCustom].map(
      asistencia => ({ ...asistencia, check: estado })
    );
    if(estado) {
      agregarAsistenciaCheck(nuevaAsistencias, true);
    } else {
      eliminarAsistenciaCheck(nuevaAsistencias, true);
    }
    setAsistenciasCustom(nuevaAsistencias);
    setCheckTodo(estado);
  }


  const checkBox = () => {
    return ({
      text: <Form.Check
        type='checkbox'
        checked={checkTodo}
        onChange={actualizarCheckTodo}
      /> ,
      key: "check",
      width: 50,
      className: 'text-center',
      cell: record => (
        <Form.Check
          type='checkbox'
          onChange={() => actualizarCheck(record)}
          checked={record.check}
        />
      )
    })
  }

  useEffect(() => {
    if(asistencias.length > 0) {
      agregarStatusCheck();
    }
    agregarAsistenciaCheck([], true);
  }, [asistencias]);

  useEffect(() => {
    (() => {
      if(asistenciasCustom.length > 0) {
        const cantidadEstadosTrue = [...asistenciasCustom].filter(asistencia => asistencia.check === true);
        if(cantidadEstadosTrue.length !== asistenciasCustom.length) {
          setCheckTodo(false);
        } else {
          setCheckTodo(true);
        }
      }
    })();
  }, [asistenciasCustom]);


  const accionBoton = (record) => (
    <Button
      type='button'
      variant='warning'
      size='xs'  
      title='editar'
      data-toggle="modal"
      data-target="#editar-asistencia"
      onClick={() => setEstudianteSeleccion(record)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  )

  return ( 
    <div className="TablaAsistencia">
      <MaterialTableDemo
        colums={colums(accionBoton, checkBox)}
        tabla={asistenciasCustom}
        addConfig={{key_column: 'id_estudiante_sesion'}}
        className='table-hover'
      />
    </div>
  );
}
 
export default TablaAsistencia;



const colums = (accionBoton, checkBox) => [
  checkBox(),
  {
    text: '#',
    key: 'index',
    width: 50,
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    text: "Id discente",
    key: "id_estudiante",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 90
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 90
  },
  {
    text: "Nombres discente",
    key: "nombre_usuario",
    align: "center",
    sortable: true,
    width: 200
  },
  {
    text: "Apellidos discente",
    key: "apellido_usuario",
    align: "center",
    sortable: true,
    width: 200
  },
  {
    text: "Hora",
    key: "hora",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 100
  },
  {
    text: "Estado",
    key: "estado",
    align: "center",
    sortable: true,
    className: "text-center",
    width: 100,
    cell: record => {
      let variant = '';
      switch (record.estado) {
        case 'PUNTUALIDAD':
          variant = 'success';
          break;
        case 'JUSTIFICADA':
            variant = 'success';
            break;
        case 'TARDANZA':
          variant = 'warning';
          break;
        case 'INASISTENCIA': 
          variant = 'danger';
          break;
        case 'PENDIENTE': 
          variant = 'dark';
          break;
        default:
          break;
      };
      return <Badge variant={variant}>
        {record.estado}
      </Badge>
    }
  },
  {
    text: "Acciones",
    key: "button",
    align: "center",
    width: 90,
    className: "text-center",
    cell: (record) => accionBoton(record)
  }
]