import {
 leadstypes
} from "./types";


import authAxios from "../config/authAxios";
import response from '../config/response';
import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
//import Swal from "sweetalert2/src/sweetalert2";
import { updateStateModalBasic } from "./modalActions";
import * as moment from 'moment';
import Swal from "sweetalert2";


export const listarLeads = (usuario) => {

  return async dispatch => {
      dispatch({
        type: leadstypes.LISTAR_LEADS
      });
      try {
  
          const leads = await authAxios.get(
              `/admin/listarLeads/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}`
            );
         //  console.log(leads);
          
          dispatch({
            payload: leads.data.data,
            type: leadstypes.LISTAR_LEADS_EXITO
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los comunicados')
      }
    }
}


export const filtrarLeads = (datosLeads,usuario) => {

  return async dispatch => {
      dispatch({
        type: leadstypes.LISTAR_LEADS
      });
      try {
  
          const leads = await authAxios.post(
              `/admin/filtrarLeads/${usuario.id_usuario}/${usuario.id_tp_nivel_usuario}`,datosLeads
            );
         //  console.log(leads);
          
          dispatch({
            payload: leads.data.data,
            type: leadstypes.FILTRAR_LEADS
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los comunicados')
      }
    }
}

export const asignarLeadsManualmente = (datosLeads,usuario) => {

  return async dispatch => {
  
      try {
  
          const leads = await authAxios.post(
              `/admin/asignarLeadsManual/`,datosLeads
            );
        // console.log(leads);
         dispatch(listarLeads(usuario));
         dispatch(updateStateModalBasic())
      } catch (error) {
        avisoError('Hubo un error al asignar leads')
      }
    }
}
export const registrarClick = (datosClick) => {

  return async dispatch => {
  
      try {
  
          const leads = await authAxios.post(
              `/admin/controlWhatsApp`,datosClick
            );
         // console.log(leads);
         
      } catch (error) {
        avisoError('Hubo un error al registrar evento')
      }
    }
}


export const enviarCorreoLeadProducto = datosLead => {

  return async dispatch => {

      const swalWithBootstrapButtons = Swal.mixin({
       
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Enviar correo?",
          text: "Recuerda no reenviar correos repetidamente",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
              try {
  
                  let correo = await authAxios.post(`/admin/enviarCorreoLeadProducto`, datosLead)
                  if (!correo.data.error) {
                     return true
                  }

                  } catch (error) {
                     Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: error.response.data.msg
                      })
                  }
        },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          // console.log(result)
          if (result.value === true) {
          
            swalWithBootstrapButtons.fire(
              "Enviado!",
              "Correo enviado con éxito.",
              "success"
            );
           
          }
        });
  };
 
}


export const listarAsesores = () => {

  return async dispatch => {
      try {
  
          const asesores = await authAxios.get(
              `/admin/listarAsesores/`
            );
         //  console.log(leads);
          
        dispatch({
            payload: asesores.data.data,
            type: leadstypes.LISTAR_ASESORES
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los asesores')
      }
    }
}


export const listarEstados = () => {

  return async dispatch => {
      try {
  
          const estados = await authAxios.get(
              `/admin/listarEstadosLeads/`
            );
         //  console.log(estados);
          
        dispatch({
            payload: estados.data.data,
            type: leadstypes.LISTAR_ESTADOS_LEAD
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los estados')
      }
    }
}

export const listarObservaciones = () => {

  return async dispatch => {
      try {
  
          const observaciones = await authAxios.get(
              `/admin/listarObservaciones/`
            );
         //  console.log(observaciones);
          
        dispatch({
            payload: observaciones.data.data,
            type: leadstypes.LISTAR_OBSERVACIONES_LEAD
          });
      
      } catch (error) {
        avisoError('Hubo un error al listar los estados')
      }
    }
}

export const reporteDiario = (fecha,usuario) => {

  //console.log(fecha)
  return async dispatch => {

        if(usuario.id_tp_nivel_usuario!=='11')
        {
              try {
          
                  const reporte = await authAxios.get(
                      `/admin/reporteMKT/${fecha}`
                    );
                //  console.log(estados);
                  
                return reporte.data.data

              /*  dispatch({
                    payload: estados.data.data,
                    type: leadstypes.LISTAR_ESTADOS_LEAD
                  });*/
              
              } catch (error) {
                avisoError('Hubo un error al listar los estados')
              }
        }
        else
        {

          try {
          
            const reporte = await authAxios.get(
                `/admin/reporteAsesor/${fecha}/${usuario.id_usuario}`
              );
          //  console.log(estados);
            
          return reporte.data.data

        /*  dispatch({
              payload: estados.data.data,
              type: leadstypes.LISTAR_ESTADOS_LEAD
            });*/
        
        } catch (error) {
          avisoError('Hubo un error al listar los estados')
        }


        }
    }
}

export const reporteLeadsGeneral = (fecha,usuario) => {

  return async dispatch => {

        if(usuario.id_tp_nivel_usuario!=='11')
        {
              try {
          
                  const reporte = await authAxios.get(
                      `/admin/reporteOrganico/${fecha.proyecto}/${fecha.fechaInicio}/${fecha.fechaFin}`
                    );
                //  console.log(estados);
                  
                return reporte.data

              /*  dispatch({
                    payload: estados.data.data,
                    type: leadstypes.LISTAR_ESTADOS_LEAD
                  });*/
              
              } catch (error) {
                avisoError('Hubo un error al listar los estados')
              }
        }

    }
}



export const importarLeads = (archivo,proyecto,asignarAuto,mailChimp,id_usuario,nivel_usuario) => {
  
  return async dispatch => {

    const file = new FormData();
    file.append('archivo', archivo);
    file.append('proyecto', proyecto);
    file.append("asignar",asignarAuto ? '1':'0')
    file.append("mailChimp",mailChimp ? '1':'0')

    const usuario =
    {
      id_usuario:id_usuario,
      id_tp_nivel_usuario:nivel_usuario
    }
 
      try {
          avisoCargando('Importando datos, espere por favor...')
          const leads = await authAxios.post(
              `/admin/importarLeads/`,file,{headers:{'Context-type':'multipart/form-data'}}
            );
            
         
       // console.log(leads)
        dispatch({
          payload: leads.data,
          type: leadstypes.IMPORTAR_LEADS_EXITO
        }); 
         dispatch(listarLeads(usuario));
         dispatch(updateStateModalBasic())
      
      } catch (error) {
        console.log(error)
        avisoError('Hubo un error al importar los leads')
      }
    }
}

export const actualizarLead = (datosLead,leads,estados,observaciones) => {
  return async dispatch => {

    try {
     
           await authAxios.post(
              `/admin/actualizarLead/`,datosLead
            );
        
        
         const nestado = estados.filter(estado => estado.id_estado===datosLead.estado)
         const nobservacion = observaciones.filter(observacion => observacion.id_observacion===datosLead.observacion)
         leads.forEach((lead)=> {
           if(lead.id_lead===datosLead.lead)
            { 
              lead.notas=datosLead.notas
              lead.fecha_llamada=moment().format('YYYY-MM-DD')
              lead.estado=datosLead.estado
              lead.nombre_estado=nestado[0].nombre_estado
              lead.observacion_n=nobservacion[0].observacion_n
              lead.observaciones=datosLead.observacion
            }
          })
          dispatch({
            payload: leads,
            type: leadstypes.LISTAR_LEADS_EXITO
          });
         //dispatch(listarLeads(usuario));
         dispatch(updateStateModalBasic())
         avisoCorrecto('Lead editado correctamente.')
      
      } catch (error) {
        avisoError(error.response.data?.msg)
      }
    }
}

export const exportarPosiblesClientes = (datos = [],usuario) => {

 let leads =[];
  return new Promise(async (resolve, reject) => {
    try {
      if(!datos.length) {
        reject({
          exito: false,
          message: 'No se encontraron datos para exportar.'
        });
      }
  
      leads = datos.map(item => ({
        Zip_Code:item.id_lead,
        First_Name: item.nombres ? item.nombres : '',
        Last_Name: item.apellidos ? item.apellidos : '',
        Mobile: item.telefono ? item.telefono : '',
        Email: item.correo ? item.correo : '',
        Description: item.nombre_proyecto ? item.nombre_proyecto : '',
        Fecha_de_registro: moment().format('YYYY-MM-DD'),
        Observaciones_llamada:item.notas,
        Estado_de_llamada: item.nombre_estado
      }));
    
      const datosEnviar = {
        data: leads
      }
    
       await authAxios.post('/servicio/asesor/leads/'+usuario, datosEnviar);
        //console.log(rpta)
          resolve({
            exito: true,
            message: 'Se exporto correctamente.'
          });
        } catch (error) {
          console.log(error);
          reject({
            exito: false,
            message: 'Ocurrió un error inesperado al exportar.'
          });
        }
  });
}