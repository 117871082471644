import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listarProyectos } from '../actions/cursosActions';

const useGetProyectos = () => {
  
  const dispatch = useDispatch();
  const { proyectos, loading, error } = useSelector(state => state.cursos);

  useEffect(() => {
    dispatch(listarProyectos());
  }, [])

  return ({ proyectos, loading, error });
}
 
export default useGetProyectos;