import Swal from "sweetalert2/src/sweetalert2.js";


export const avisoError = (mensaje) => {

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: "error",
          title: `<p class='text-white text-bold d-flex align-items-center mb-0'>${mensaje}</p>`,
          background: "#E12E1C",
          padding: '.5rem 1rem'
        });
}

export const avisoCorrecto = mensaje => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
  });

  Toast.fire({
    icon: "success",
    title:
      `<p class='text-bold d-flex align-items-center mb-0'>${mensaje}</p>`,
    padding: ".5rem 1rem"
  });
}

export const avisoCargando = mensaje => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer:2000,
    onOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
  });

  Toast.fire({
    icon: "info",
    title:
      `<p class=' text-bold d-flex align-items-center mb-0'>${mensaje}</p>`,
    padding: ".5rem 1rem"
  });
}