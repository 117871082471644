import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import "./Rankin.scss";
import { obtenerTopVentas} from "../../../actions/comercialActions"
//Reduxt
import {useDispatch } from "react-redux";
import UserList from "./UserList";
import SelectVariable from "./Componentes/SelectVariable";
import { avisoCorrecto, avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import useSelectAnios from '../../../hooks/useMultiSelectAnios';
 
const Rankin = () => {
  //State
  const dispatch = useDispatch();
  const dt = new Date();
  const initialState={
    anio:[
    {  id:dt.getFullYear(),
      value:dt.getFullYear()}
    ],
    mes: dt.getMonth()+1,
  }

  const[loading, setLoading] = useState(false)
  const[consulta, setConsulta] = useState(initialState);
  const[vendedores, setVendedores] = useState(null)
  const {mes,anio} = consulta;
  const [seleccionAnio, SelectAnios] = useSelectAnios(null);
  const [datoTitulo,setDatoTitulo] = useState(null)
  const meses = [
          {
            id:1,
            value:"Enero"
          },
          {
            id:2,
            value:"Febrero"
          },
          {
            id:3,
            value:"Marzo"
          },
          {
            id:4,
            value:"Abril"
          }
          ,
          {
            id:5,
            value:"Mayo"
          }
          ,
          {
            id:6,
            value:"Junio"
          }
          ,
          {
            id:7,
            value:"Julio"
          }
          ,
          {
            id:8,
            value:"Agosto"
          }
          ,
          {
            id:9,
            value:"Septiembre"
          }
          ,
          {
            id:10,
            value:"Octubre"
          }
          ,
          {
            id:11,
            value:"Noviembre"
          }
          ,
          {
            id:12,
            value:"Diciembre"
          }
  ]
  
  const handleChange = (e) =>{
    setConsulta({
      ...consulta,
        [e.target.name]:e.target.value
    })
  }

useEffect(()=>{
  if(seleccionAnio)
  {
    setConsulta({
      ...consulta,
      anio:seleccionAnio
    })
  }
  else
  {
    setConsulta({
      ...consulta,
      anio:null
    })
  }

},[seleccionAnio])

useEffect(()=>{

  traerTop()

},[])
 const traerTop = () => {
  // console.log(consulta)
    if(anio)
    { 
      
        setLoading(true);
        const fetchData = async () =>{
        const respuesta = await (dispatch(obtenerTopVentas(consulta)))
        setVendedores(respuesta.top)
        setDatoTitulo(mes)
        setLoading(false)
        }
        fetchData();
    }
    else
    {
        avisoError("Debe seleccionar como minimo un periodo.")
    }
  }

  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Ranking de Ventas" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Ranking de Ventas" },
              ]}
            />
          </div>
          <div className="box p-3">
          <div className="row">
           
              <div className="col-4">
              <SelectAnios />
               </div>
              <div className="col-4">
               <SelectVariable name="mes" datos={meses} value={mes} primerSelect="Seleccione un mes" handleChange={handleChange} />
              </div>
              <div className="col-4">
               <button className="btn btn-primary" onClick = {traerTop} >Consultar</button>
              </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
              <div className="box mt-2 p-3">
               <UserList dataArr={vendedores} mes ={ datoTitulo ? meses[datoTitulo-1].value : ''} />
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Rankin;