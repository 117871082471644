import React from "react";
import { useHistory } from "react-router-dom";
import "./loginPage.scss";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loginUsuario } from "../../../actions/userActions";
import { useEffect } from "react";
import { useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { getJWT } from "../../../helpers/functions";

const LoginPage = () => {

  const { isLogin, loading, error } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const verificar = async (e) => {
    e.preventDefault();
    let datos = {
      usuario: userName,
      password: password,
    };
    dispatch(loginUsuario(datos));
  };

  useEffect(() => {
    if(getJWT()) {
      history.push('/inicio');
    }
    if(!loading && isLogin === true){
      history.push("/inicio");
    }
  }, [isLogin]);

  return (
    <div className="singin-singup">
      <div className="singin-singup__content-form">
        <img
          className="img img-fluid"
          src="/images/logo_intra2.png"
          alt="logo inedi"
        />
        <Form onSubmit={verificar}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="usuario"
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              className="form-control"
              placeholder="contraseña"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          {
            error && <Alert variant='danger'>
              { error }
            </Alert>
          }
          <Form.Group>
            <Button
              variant='primary'
              className='btn-spinner'
              block
              disabled={loading}
              type="submit"
            >
              {
                loading && <Spinner as='span' animation='border' size='sm' />
              }
              Iniciar sesión
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
