import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import FilaInfoPagos from "./FilaInfoPagos";
import "./TablaOtroPagos.scss";
import ModalBasicwithRedux from "../../../../containers/ModalBasicwithRedux";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateStateModalBasic } from "../../../../actions/modalActions";
import FormNuevoPagoExtra from "../FormNuevoPagoExtra";
import ModalCustomWidthwithRedux from "../../../../containers/ModalCustomWidthwithRedux";

const TablaOtroPagos = ({ idMatricula, tipo, valor }) => {
  const dispatch = useDispatch();
  const matriculaActiva = useSelector(
    (state) => state.pagos.datosFinanzasDiscente.matriculaActiva
  );
  const { cuotas: { extraordinarios = [] } = {} } = matriculaActiva;

  const mostrarFilas = () => {
    if (!extraordinarios || !extraordinarios.length) {
      return (
        <tr>
          <td colSpan="8" align="center">
            No hay otros pagos disponibles
          </td>
        </tr>
      );
    } else {
      return extraordinarios.map((extraordinario, index) => (
        <FilaInfoPagos
          key={extraordinario.id_pago_extraordinario}
          index={index}
          tipo={tipo}
          valor={valor}
          extraordinario={extraordinario}
          idMatricula={idMatricula}
        />
      ));
    }
  };

  const handleModalPago = () => {
    dispatch(
      updateStateModalBasic(
        <FormNuevoPagoExtra idMatricula={idMatricula} />,
        "Nuevo pago"
      )
    );
  };

  return (
    <div className="TablaOtrosPagos mt-3">
      <ModalBasicwithRedux />
      <ModalCustomWidthwithRedux />
      <div className="box col-md-8">
        <div className="box-header with-border">
          <h5 className='title-head mb-0'>
            <FontAwesomeIcon icon={faFileSignature} />
            Otros pagos
          </h5>
          <div className="btn-icon cursor-pointer" onClick={handleModalPago}>
          <FontAwesomeIcon icon={faPlus} />
          <span>Nuevo pago</span>
        </div>
        </div>
        <div className="box-body">
          <Table striped responsive="md">
            <thead>
              <tr>
                <th>#</th>
                <th>Concepto</th>
                <th>Fecha Límite</th>
                <th>Fecha Pago</th>
                <th>Monto Total(S/.)</th>
                <th>Estado</th>
                <th>Registrar</th>
              </tr>
            </thead>
            <tbody>{mostrarFilas()}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TablaOtroPagos;
