import {
  EDITAR_ASISTENCIA,
  REMOVER_ASSITENCIAS_INFO,
  INSERTAR_ASISTENCIA,
  asistenciaTypes,
} from "../actions/types";

const initialState = {
  asistencias: [],
  cantidadesTotales:[],
  loading: null,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case asistenciaTypes.OBTENER_ASISTENCIAS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case asistenciaTypes.OBTENER_ASISTENCIAS_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        asistencias: action.payload.data.data,
        cantidadesTotales: action.payload.data.totales
      };
    case asistenciaTypes.OBTENER_ASISTENCIAS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INSERTAR_ASISTENCIA:
      return {
        ...state,
        loading: false,
        error: null,
        asistencias: action.payload.data,
        cantidadesTotales: action.payload.totales
      };
    case EDITAR_ASISTENCIA:
      return {
        ...state,
        loading: false,
        error: null,
        asistencias: action.payload.data,
        cantidadesTotales: action.payload.totales
      };
    case REMOVER_ASSITENCIAS_INFO:
      return {
        ...state,
        asistencias: [],
      };
    default:
      return state;
  }
}
