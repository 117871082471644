import {
  MOSTRAR_CURSOS,
  MOSTRAR_CURSO,
  ELIMINAR_MODULO,
  LISTAR_SESION,
  EDITAR_SESION,
  ELIMINAR_SESION,
  LLENAR_SELECT_PROYECTOS,
  LISTAR_SESIONES_CURSO_IMPORT,
  LLENAR_SELECT_CURSOS,
  INSERTAR_SESIONES_MANUAL,
  LLENAR_SELECT_CURSOS_POSGRADO,
  LLENAR_SELECT_TC,
  LISTAR_SESIONES_CURSOS,
  LISTAR_SESIONES_MODULO,
  REMOVER_SESION,
  LISTAR_PROYECTOS,
  INSERTAR_PROYECTO,
  REMOVER_MODULO,
  ELIMINAR_PROYECTO,
  LISTAR_PROYECTO,
  REMOVER_PROYECTO,
  LISTAR_MODULO_PROYECTO,
  LISTAR_MODULOS_PROYECTO_TODO,
  REMOVER_SESIONES_MODULO,
  REMOVER_STATE_MODULO,
  EDITAAR_PROYECTO,
  EDITAR_MODULO,
  GUARDANDO_SESIONES_EVENTOS_SELECT,
  LISTAR_PROYECTOS_EXITO,
  GUARDAR_GRUPO,
  OBTENER_COMENTARIOS_VIDEOS,
  proyectosTypes,
  reporteCalificacionTypes,
  respuestasComentariostypes,
  temasTypes
} from "./types";

import authAxios from '../config/authAxios';
import Swal from 'sweetalert2';
import { updateStateModalBasic } from "./modalActions";
import { avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
import { mostrarErrorApi } from "../helpers/functions";

export const mostrarCursos = () =>async dispatch => {
  // dispatch({
  //   type: proyectosTypes.OBTENER_MODULOS
  // })
  try {
    let cursos = await authAxios.get(`/admin/mdlo_mostrarModulos`);
    dispatch({
      payload: cursos.data,
      type: proyectosTypes.OBTENER_PROYECTO_EXITO
    });
  } catch (error) {
  //  console.log(error)
    // dispatch({
    //   type: proyectosTypes.OBTENER_MODULOS_ERROR,
    //   payload: 'Hubo un error inesperado al obtener los módulos'
    // })
  }  
}

export const buscarCurso = idCurso => async dispatch => {
  let curso = await authAxios.get(`/admin/mdlo_mostrarModulo__idModulo/${idCurso}`);
  dispatch({
    payload: curso.data,
    type: MOSTRAR_CURSO
  })
}

export const listarModuloMatricula = idModulo => async dispatch => {
  try {
    let modulo = await authAxios.get(`/admin/mdlo_mostrarAllDatos__idModulo/${idModulo}`)

  dispatch({
    payload: modulo.data,
    type: LISTAR_MODULO_PROYECTO
  })

  } catch (error) {
    console.log(error)
  }
  
}

export const traerComentariosVideos = datos => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/traerComentariosVideos/${datos.docente}`)

 if(!respuesta.error)
 {
  dispatch({
    type: respuestasComentariostypes.LISTAR_COMENTARIOS_EXITO,
    payload: {
      comentarios: respuesta.data
    }
  })
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const traerProyectosDocente = docente => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/traerProyectosDocente/${docente}`)

 if(!respuesta.error)
 {
   return respuesta.data
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const traerTemas = producto => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/obtenerTemasForo/${producto}`)
  //  console.log(respuesta)
 if(!respuesta.error)
 {
  dispatch({
    type: temasTypes.OBTENER_TEMAS_EXITO,
    payload: {
      temas: respuesta.data
    }
  })
  // return respuesta.data
 }

} catch (error) {
  console.log(error)
  return null
}
}


export const guardarTema = datosTema => async dispatch => {


  try {
   const respuesta = await authAxios.post(`/admin/guardarTemaForo`,datosTema)
   if(!respuesta.error)
   {
    //dispatch(consultarPuntos(datosTema.id_estudiante))
    dispatch(traerTemas(datosTema.id_proyecto))
    Swal.fire({
      icon: "success",
      title: "Éxito",
      text: "Tema publicado correctamente."
    })
   }
   else
   {
     return null
   }
  
  } catch (error) {
    console.log(error)
  }

}


export const traerTema = (slug,usuario=null) => async dispatch => {
  //console.log(slug,usuario)
  try {
  const respuesta = await authAxios.get(`/admin/traerTemaForo/${slug}/${usuario}`)
   // console.log(respuesta)
  if(!respuesta.error)
  {
   dispatch({
     type: temasTypes.OBTENER_TEMA_EXITO,
     payload: {
       tema: respuesta.data
     }
   })
    return true
  }
  else{
    return false
  }

} catch (error) {
  console.log(error)
  return false
}
}

export const showForm = () => {
  return dispatch => {
    dispatch({
      type: temasTypes.SHOW_FORM
    })
  }
}

export const likeComentarioTemaForo = datos => async dispatch => {
  // console.log(datos)
   try {
   const respuesta = await authAxios.post(`/admin/likeComentarioTemaForo`,datos)
 
  if(!respuesta.error)
  {
  // dispatch(consultarPuntos(datos.id_usuario))
   dispatch(traerTema(datos.slug,datos.id_usuario))
    return true
  }
  else{
    return null
  }
 
 } catch (error) {
   console.log(error)
   return null
 }
 }
 
 export const disLikeComentarioTemaForo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/disLikeComentarioTemaForo`,datos)

 if(!respuesta.error)
 {
  dispatch(traerTema(datos.slug,datos.id_usuario))
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}


export const eliminarComent = datosComentario =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          cancelButtonColor: "#d33",
          preConfirm: async function() {

          try {
           let respuesta = await authAxios.post(`/admin/eliminarComentarioTemaForo`, datosComentario);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
                return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          dispatch(traerTema(datosComentario.tema,datosComentario.id_usuario))
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu comentario ha sido borrado.",
              "success"
          );
        
          }
      });
  }
}

export const eliminarTemaForo = datosTema =>{
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "Se borrará la información ligada a este tema.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          cancelButtonColor: "#d33",
          preConfirm: async function() {

          try {
           let respuesta = await authAxios.post(`/admin/eliminarTemaForo`, datosTema);
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
                return true;
          } catch (error) {
            
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: 'Error al realizar la operación...'
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          dispatch(traerTemas(datosTema.proyecto))
          swalWithBootstrapButtons.fire(
              "Éxito!",
              "El tema ha sido correctamente.",
              "success"
          );
        
          }
      });
  }
}

export const eliminarRespuestaTemaForo = datos => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          cancelButtonColor: "#d33",
          preConfirm: async function() {
          

     
          try {
            const respuesta = await authAxios.post(`/admin/eliminarRespuestaTemaForo`,datos)
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
              return true;
          } catch (error) {
            
            swalWithBootstrapButtons.fire(
              "Error!",
              "No se pudo borrar tu respuesta.",
              "error"
          );
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
            dispatch(traerTema(datos.slug,datos.id_usuario))
            swalWithBootstrapButtons.fire(
              "Éxito!",
              "Tu respuesta ha sido borrada.",
              "success"
          );
          }
      });
  }
}


export const nuevaRespuestaTemaForo = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/nuevaRespuestaTemaForo`,datos)

 if(!respuesta.error)
 {
  //dispatch(consultarPuntos(datos.id_usuario))
  dispatch(traerTema(datos.slug,datos.id_usuario))
  Swal.fire({
    icon: "success",
    title: "Éxito",
    text: "Respuesta publicada correctamente."
  })
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}

export const guardarComentario = datosTema => async dispatch => {


  try {
   const respuesta = await authAxios.post(`/admin/guardarComentarioTemaForo`,datosTema)
   if(!respuesta.error)
   {
    //dispatch(consultarPuntos(datosTema.id_usuario))
    dispatch(traerTema(datosTema.slug,datosTema.id_usuario))
    Swal.fire({
      icon: "success",
      title: "Éxito",
      text: "Comentario publicado correctamente."
    })
   }
   else
   {
     return null
   }
  
  } catch (error) {
    console.log(error)
  }

}



export const traerSesionesDocente = (docente,idProyecto) => async dispatch => {
  try {
  const respuesta = await authAxios.get(`/admin/traerSesionesDocente/${docente}/${idProyecto}`)

 if(!respuesta.error)
 {
   return respuesta.data
 }

} catch (error) {
  console.log(error)
  return null
}
}



export const nuevaRespuestaDocente = datos => async dispatch => {
  try {
  const respuesta = await authAxios.post(`/admin/nuevaRespuestaComentario`,datos)

 if(!respuesta.error)
 {
  dispatch(traerComentariosVideos(datos))
  avisoCorrecto('Respuesta publicada corectamente')
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
}



export const eliminarRespuestaComentario = datos => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "No se podrá recuperar la información.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: false,
          showLoaderOnConfirm: true,  confirmButtonColor: "#27314A",
          cancelButtonColor: "#d33",
          preConfirm: async function() {
          

     
          try {
            const respuesta = await authAxios.post(`/admin/eliminarRespuestaComentario`,datos)
            if (!respuesta.data.error) {
               // console.log(respuesta.data)
            }
              return true;
          } catch (error) {
            
             avisoError('La respuesta no pudo borrarse')
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
            dispatch(traerComentariosVideos(datos))
            avisoCorrecto('Respuesta eliminada corectamente')
          }
      });
  }











  /** 
   * 
   *   try {
  const respuesta = await authAxios.post(`/admin/eliminarRespuestaComentario`,datos)

 if(!respuesta.error)
 {
  dispatch(traerComentariosVideos(datos))
  avisoCorrecto('Respuesta eliminada corectamente')
   return true
 }
 else{
   return null
 }

} catch (error) {
  console.log(error)
  return null
}
   */
}


export const insertarProyecto = (datos) => async dispatch => {
  dispatch({
    type: proyectosTypes.NUEVO_PROYECTO,
  })
  try {
    const respuesta = await authAxios.post('/admin/proyectos', datos);
    dispatch({
      type: proyectosTypes.NUEVO_PROYECTO_EXITO,
      payload: respuesta.data.data
    })
    dispatch(updateStateModalBasic());
    setTimeout(() => {
      dispatch({
        type: proyectosTypes.NUEVO_PROYECTO_EXITO_RESET_REDIRECCION
      })
    }, 1000);
  } catch (error) {
    //console.log(error);
    dispatch({
      type: proyectosTypes.NUEVO_PROYECTO_ERROR,
      payload: error.response.data.msg
    })
  }
}

export const editarNivelAcademico = (idProyecto, datos) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.EDITAR_PROYECTO_NIVEL_ACADEMICO
    })
    try {
      await authAxios.put(`/admin/proyectos/${idProyecto}/academico`, datos);
      dispatch({
        type: proyectosTypes.EDITAR_PROYECTO_NIVEL_ACADEMICO_EXITO,
      })
    } catch (error) {
     // console.log(error);
      dispatch({
        type: proyectosTypes.EDITAR_PROYECTO_NIVEL_ACADEMICO_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const insertarCursoNoVenta = datosNuevoCurso => async dispatch => {
  let respuesta = await authAxios.post(`/admin/mdlo_insertar_ModuloNoVenta`, datosNuevoCurso)
  //no creado el type
 // console.log(respuesta.data)
}

export const deleteModulo = idModulo => async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "Se eliminara todo registro",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
            const respuesta = await authAxios.delete(`/admin/mdlo_eliminarModulo__idModulo/${idModulo}`);
            if (!(respuesta.data.error)) {
              dispatch({
                payload: idModulo,
                type: ELIMINAR_MODULO
              })
            }
            return true;
          } catch (error) {
              console.log(error)
              const msg = error.response !== undefined ? error.response.data.msg : error 
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se elimino el archivo correctamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Se elimino el archivo correctamente",
              "success"
          );
          }
      });
}

export const eliminarProyecto = idProyecto => async dispatch => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
  });
  swalWithBootstrapButtons
    .fire({
      title: "¿Estas seguro?",
      text: "Se eliminara todo registro",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, continuar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async function() {
        try {
          const respuesta = await authAxios.delete(`/admin/pryt_eliminarProyecto__idProyecto/${idProyecto}`)
          if (!(respuesta.data.error)) {
            dispatch({
              payload: idProyecto,
              type: ELIMINAR_PROYECTO
            })
          }
          return true;
        } catch (error) {
            console.log(error)
            const msg = error.response !== undefined ? error.response.data.msg : error 
            Swal.fire({
                icon: "error",
                title: "Aviso...",
                text: 'Hay registros dependientes. No se puede eliminar'
            })
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
    .then(result => {
      //mensaje que se cambio la contraseña satisfactoriamente
      if (result.value === true) {
        swalWithBootstrapButtons.fire(
          "Eliminado!",
          "Curso se elimino correctamente",
          "success"
        );
      }
    });
}

export const listarProyectosSelect = () => {
  return async dispatch => {
    try {
      let proyectos = await authAxios.get(`/admin/pryt_mostrar_Proyecto`)

      let proyectosSelect = []
      proyectos.data.map(proyecto => {
         proyectosSelect = [...proyectosSelect, {
           value: proyecto.id_proyecto,
           label: proyecto.nombre_proyecto,
           ...proyecto
         }]
      })

      dispatch( guardarProyectosSelect(proyectosSelect))
    } catch (error) {
      console.log(error)
    }
  }
}

const guardarProyectosSelect = proyectosSelect => {
  return {
    type: LLENAR_SELECT_PROYECTOS,
    payload: proyectosSelect
  }
}

export const listarProyectos = () => async dispatch => {

  try {
    dispatch({
      type: LISTAR_PROYECTOS
    })
    const proyectos = await authAxios.get(`/admin/proyectos`);

    dispatch({
      payload: proyectos.data,
      type: LISTAR_PROYECTOS_EXITO
    })
  } catch (error) {
    console.log(error)
  }
  
}
export const listarProyectosGantt = () => async dispatch => {

  try {
    const proyectos = await authAxios.get(`/admin/proyectosGantt`);

    if(!proyectos.error)
    {
      return proyectos.data
    }

  } catch (error) {
    console.log(error)
    return null
  }
  
}
export const obtenerReporteCalificacion = () => async dispatch => {

  try {
    dispatch({
      type: reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION
    })
    const proyectos = await authAxios.get(`/admin/reporteCalificacion/`);
    dispatch({
      payload: proyectos.data,
      type: reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION_EXITO
    })
  } catch (error) {
    dispatch({
      payload:error,
      type: reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION_ERROR
    })
  }
  
}


export const listarProyecto = idProyecto => async dispatch => {
  
  dispatch({
    type: proyectosTypes.OBTENER_PROYECTO
  })
  try {
    let proyecto = await authAxios.get(`/admin/proyectos/${idProyecto}`)

    let planPagoUnico = [];
    let planPagoCuotas = [];
    let planPagosMensualidades = [];

    proyecto.data.data.planes.forEach(plan => {
      if(plan.id_tp_modalidad === 2) {
        planPagoUnico.push(plan);
      } else if(plan.id_tp_modalidad === 1) {
        planPagosMensualidades.push(plan);
      } else if(plan.id_tp_modalidad === 3) {
        planPagoCuotas.push(plan);
      }
    })

    proyecto.data.data.planes = {
      planPagoUnico,
      planPagoCuotas,
      planPagosMensualidades
    }

    dispatch({
      payload: proyecto.data.data,
      type: proyectosTypes.OBTENER_PROYECTO_EXITO
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: proyectosTypes.OBTENER_PROYECTO_ERROR,
      payload: error.response.data.msg
    })
  }
}

export const nuevoPlan = (idProyecto, datos, onCloseModal) => {
  return async dispatch => {
      dispatch({
        type: proyectosTypes.NUEVO_PLAN
      })
      try {
        const respuesta = await authAxios.post(`/admin/proyectos/${idProyecto}/plan`, datos);
        
        dispatch({
          type: proyectosTypes.NUEVO_PLAN_EXITO,
          payload: {
            modalidad: getModalidad(datos.id_tp_modalidad), 
            data: respuesta.data.data
          }
        })
        avisoCorrecto('Se creo el plan correctamente');
        if(onCloseModal) onCloseModal();
      } catch (error) {
        console.log(error);
        avisoError(error.response.data.msg);
        dispatch({
          type: proyectosTypes.NUEVO_PLAN_ERROR,
          error: error.response.data.msg
        })
        
      }
    }
}

const getModalidad = (idModalidad) => {
  let modalidad = '';
  if(idModalidad === 2) {
    modalidad = 'planPagoUnico';
  } else if(idModalidad === 1) {
    modalidad = 'planPagosMensualidades';
  } else if(idModalidad === 3) {
    modalidad = 'planPagoCuotas';
  }
  return modalidad;
}

export const editarNivelAcademicoProyecto = (idProyecto, datos) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO
    })
    try {
      console.log(datos);
      await authAxios.put(`/admin/proyectos/${idProyecto}/academico`, datos);
      dispatch({
        type: proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO_EXITO
      })
      avisoCorrecto('Se actualizo correctamente');  
    } catch (error) {
      avisoError(error.response.data.msg);
      console.log(error);
      dispatch({
        type: proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const editarHorarioProyecto = (idProyecto, datos) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.EDITAR_PROYECTO_HORARIO
    })
    try {
      await authAxios.put(`/admin/proyectos/${idProyecto}/horario`, datos);
   

      avisoCorrecto('Se actualizó correctamente')
   
      dispatch({
        type: proyectosTypes.EDITAR_PROYECTO_HORARIO_EXITO,
      })
    } catch (error) {
      avisoError(error.response.data.msg);
      dispatch({
        type: proyectosTypes.EDITAR_PROYECTO_HORARIO_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const guardarPension = (idProyecto, idPlan, pensiones, idModalidad, onCloseModal) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.GUARDAR_PENSION
    })
    try {
      const datosPension = {
        id_tp_modalidad: idModalidad,
        pension: pensiones
      }
      const respuesta = await authAxios.post(`/admin/proyectos/${idProyecto}/plan/${idPlan}/pension`, datosPension)

      dispatch({
        type: proyectosTypes.GUARDAR_PENSION_EXITO,
        payload: {
          modalidad: getModalidad(idModalidad),
          datos: respuesta.data.data
        }
      })
      avisoCorrecto('Se inserto correctamente');
      if(onCloseModal) onCloseModal();
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: proyectosTypes.GUARDAR_PENSION_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const agregarModuloProyecto = (idProyecto, idModulo, onCloseModal) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.AGREGAR_MODULO_PROYECTO
    })
    try {
      const datos = {
        "id_proyecto": idProyecto,
        "id_modulo": idModulo
      }
      const respuesta = await authAxios.post(`/admin/modulo/enlazar`, datos);
      dispatch({
        type: proyectosTypes.AGREGAR_MODULO_PROYECTO_EXITO,
        payload: respuesta.data.data
      })
      avisoCorrecto('Se inserto correctamente');
      if(onCloseModal) onCloseModal();
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: proyectosTypes.AGREGAR_MODULO_PROYECTO_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const eliminarModuloProyecto = (idModuloProyecto) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          try {
            await authAxios.delete(`/admin/modulo/desenlazar/${idModuloProyecto}`);
      
            dispatch({
              type: proyectosTypes.ELIMINAR_MODULO_PROYECTO_EXITO,
              payload: {
                idModuloProyecto
              }
            })
            return true;
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.msg
            })
            console.log(error);
            dispatch({
              type: proyectosTypes.ELIMINAR_MODULO_PROYECTO_ERROR
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Curso se elimino correctamente",
            "success"
          );
        }
      });
  }
}

export const editarPlanProyecto = (idProyecto, datos, onCloseModal) => {
  return async dispatch => {
    dispatch({
      type: proyectosTypes.EDITAR_PLAN_PROYECTO
    })
    
    try {
      const respuesta = await authAxios.put(`/admin/proyectos/${idProyecto}/plan/${datos.id_plan}`, datos);

      dispatch({
        type: proyectosTypes.EDITAR_PLAN_PROYECTO_EXITO,
        payload: {
          modalidad: getModalidad(datos.id_tp_modalidad),
          plan: respuesta.data.data
        }
      })
      avisoCorrecto('Se edito el plan correctamente');
      if(onCloseModal) onCloseModal();
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: proyectosTypes.EDITAR_PLAN_PROYECTO_ERROR
      })
    }
  }
}

export const eliminarPlan = (idProyecto, idModalidad, idPlan) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "Se eliminara todo registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          try {
            await authAxios.delete(`/admin/proyectos/${idProyecto}/plan/${idPlan}`);
            dispatch({
              type: proyectosTypes.ELIMINAR_PLAN_PROYECTO_EXITO,
              payload: {
                modalidad: getModalidad(idModalidad),
                idPlan: idPlan
              }
            })
            return true;
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.msg
            })
            console.log(error);
            dispatch({
              type: proyectosTypes.ELIMINAR_PLAN_PROYECTO_ERROR,
              payload: error.response.data.msg
            })
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Curso se elimino correctamente",
            "success"
          );
        }
      });
  }
}

export const removerProyecto = () => dispatch => {
  dispatch({
    payload: {},
    type: REMOVER_PROYECTO 
  })
}

export const llenarSelectProyectos = () => async dispatch => {
  let proyectos = await authAxios.get(`/admin/pryt_mostrar_Proyecto`)
 // console.log(proyectos)
  dispatch({
    payload: proyectos.data,
    type: LLENAR_SELECT_PROYECTOS
  })
}

export const listarModulosProyecto = idProyecto => async dispatch => {
  try {
    let modulos = await authAxios.get(`/admin/mdlo_mostrarModulos__IdProyecto/${idProyecto}`)
    
    dispatch({
      payload: modulos.data,
      type: LISTAR_MODULOS_PROYECTO_TODO
    })
  } catch (error) {
    console.log(error)
  }
} 

export const llenarSelectTC = idProyecto => async dispatch => {
  let config = {
    "id_post": idProyecto
  }
  let tc = await authAxios.get(`/admin/mostrarTipoPrograma`)

  dispatch({
    payload: tc.data,
    type: LLENAR_SELECT_TC
  })
}

export const cursosTipoPrograma = (idtprograma, idPosgrado) => async dispatch => {
  let cursos = await authAxios.get(`/admin/mostrarCursoIdTpProgramaPostgrado/${idtprograma}/${idPosgrado}`)

  dispatch({
    payload: cursos.data,
    type: LLENAR_SELECT_CURSOS_POSGRADO
  })
}

export const llenarSelectCursos = idRTP => async dispatch => {
  let config = {
    "id_tp_prog": idRTP
  }

  let cursos = await authAxios.get(`/admin/mostrarCursos`)

  dispatch({
    payload: cursos.data.datos,
    type: LLENAR_SELECT_CURSOS
  })
}

const guardarSesionesEventosSelect = sesiones => {

  let sesionesSelect = new Array;

  sesiones.map((sesion, index) => {
    sesionesSelect = [...sesionesSelect, {
      value: sesion.id_sesion,
      label: `${index + 1}.- ${sesion.nombre_sesion}`,
    }]
  })

  return {
    type: GUARDANDO_SESIONES_EVENTOS_SELECT,
    payload: sesionesSelect
  }
}

export const listarSesionesCursos = (idProyecto, origen='') => {

  return async dispatch => {
    let type = LISTAR_SESIONES_CURSOS
    if (origen !== '') {
      type = LISTAR_SESIONES_CURSO_IMPORT
    }
    let sesiones = await authAxios.get(`/admin/se_mostrarSesion__IdProyecto/${idProyecto}`)
    
    dispatch( guardarSesionesEventosSelect(sesiones.data) )

    dispatch({
      payload: sesiones.data,
      type: type
    })
  }
  
}

export const getEnlaceWhatsApp = (idProyecto) => {

  return async dispatch => {
    
    let respuesta = await authAxios.get(`/admin/getGrupoWhatsApp/${idProyecto}`)
    if(respuesta.data.enlace_grupo===null)
    {
      respuesta.data.enlace_grupo=""
    }
    return respuesta.data
  }
}

export const updateGrupo = dataActualizar => async dispatch => {

  try {
    let respuesta = await authAxios.post(`/admin/actualizarGrupoWhatsApp`, dataActualizar)

    if (!respuesta.data.error) {
      avisoCorrecto("Grupo Actualizado con éxito.")
    }
    else{
      avisoError("Hubo problemas al actualizar el grupo.")
    }
   
    
  } catch (error) {
    console.log(error)
    avisoError("Hubo problemas al actualizar el grupo. Error de registro")
  }
}

export const listarSesionesModulo = (idModulo) => async dispatch => {
  let sesiones = await authAxios.get(`/admin/se_mostrarSesion__idModulo/${idModulo}`)

  dispatch({
    payload: sesiones.data,
    type: LISTAR_SESIONES_MODULO
  })
}

export const insertarSesionesManual = dataSesion => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/insertarSesion`, dataSesion)

    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data.datos,
        type: INSERTAR_SESIONES_MANUAL
      })

      return {mensaje: true, textMensaje: 'Sesión insertado correctamente'}
    }

    return {mensaje: false, textMensaje: respuesta.data.error}
    
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
  
}

export const editarProyecto = (idProyecto, data) => async dispatch => {
  try {
    const respuesta = await authAxios.put(`/admin/pryt_editar_Proyecto__IdProyecto/${idProyecto}`, data)
    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: EDITAAR_PROYECTO
      })

      return {mensaje: true, textMensaje: 'Proyecto editado correctamente'}
    }

    return {mensaje: false, textMensaje: respuesta.data.error}
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}

export const editarModulo = (idModulo, data) => async dispatch => {
  try {
    const respuesta = await authAxios.put(`/admin/mdlo_editar_Modulo__IdModulo/${idModulo}`, data)
  
    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: EDITAR_MODULO
      })

      return {mensaje: true, textMensaje: 'Se edito modulo correctamente'}
    }
    return {mensaje: false, textMensaje: respuesta.data.error}
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
}

export const listarSesion = idSesion => async dispatch => {
  let sesion = await authAxios.get(`/admin/mostrarSesionId/${idSesion}`)
  console.log(sesion)
  dispatch({
    payload: sesion.data,
    type: LISTAR_SESION
  })
}


export const eliminarSesion = (idSesion, idModulo) => async dispatch => {
  try {
    let respuesta = await authAxios.delete(`/admin/eliminarSesionId/${idSesion}/${idModulo}`)
  
    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: ELIMINAR_SESION
      })
      return {mensaje: true, textMensaje:'Se eliminado correctamente'}
    }
    return {mensaje: false, textMensaje:'Error en eliminar sesión'}
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje:'Error en la petición'}
  }
  
  
}

export const editarSesion = (data, idSesion) => async dispatch => {
 
  try {
    let respuesta = await authAxios.put(`/admin/editarSesionId/${idSesion}`, data)
  
    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: EDITAR_SESION
      })

      return {mensaje: true, textMensaje: 'Sesión editada correctamente'}
    }

    return {mensaje: false, textMensaje: respuesta.data.error}
    
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }

}

export const removerStateModulo = () => dispatch => {
  dispatch({
    type: REMOVER_STATE_MODULO
  })
}

export const removerSesion = () => dispatch => {
  dispatch({
    payload: {},
    type: REMOVER_SESION
  })
}

export const removerSesionesCurso = () => dispatch => {
  dispatch({
    type: REMOVER_SESIONES_MODULO
  })
}