import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import FormularioFiltroReporte from '../../components/ReporteNaranja/FormularioFiltroReporteNaranja';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import DatePicker from "react-datepicker";


//Redux
import { useSelector, useDispatch } from 'react-redux';
import { limpiarResultadoBusqueda, obtenerReporteRecurrente } from '../../../actions/finanzasReportesActions';
import ResultadoReporte from './ResultadoReporte';

import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

const ReportePagoRecurrente = () => {

  const[fechaInicio,setFechaInicio] = useState(null)
  const[fechaFin,setFechaFin] = useState(null)

  const { loading, error, reporteRecurrente } = useSelector(state => state.reporteNaranja);
  const dispatch = useDispatch();
  const traerDatos = () => {
    //console.log(datos);
    const datos ={
      fecha_inicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fecha_fin : fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null
    }
   // console.log(datos)
    dispatch(obtenerReporteRecurrente(datos));
  }

  useEffect(() => {
    dispatch(limpiarResultadoBusqueda());
  }, [])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte Afiliaciones' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
          { url: "", nombre: "Afiliaciones" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
 
          <div className="box">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
                <div className="row p-3">
                      <div className="col-4">
                        <label htmlFor="">Desde:</label>
                        <DatePicker
                        selected={fechaInicio}
                        onChange={(date) =>setFechaInicio(date)}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                        name="fechaInicio"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                      </div>
                      <div className="col-4">
                        <label htmlFor="">Hasta:</label>
                        <DatePicker
                        selected={fechaFin}
                        onChange={(date) =>setFechaFin(date)}
                        dateFormat="dd/MM/yyyy"
                        name="fechaFin"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      // endDate={formik.values.fechaFin}
                        startDate={fechaInicio}
                        endDate={fechaFin}
                        minDate={fechaInicio}
                      />
                      </div>
                      <div className="col-4 pt-4">
                        <button className="btn btn-primary" onClick={()=>traerDatos()} >Consultar</button>   
                  
                      </div>
                </div>
        
        
          </div>    
          <SpinnerLoading loading={loading} error={error}>
            {
            //console.log(reporteNaranja)
             <ResultadoReporte reporte={reporteRecurrente.afiliacion} />
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReportePagoRecurrente;