import React from 'react';
import { Modal } from 'react-bootstrap';
import AreasDictadowithActions from '../../../../../../containers/DocentesContainers/AreasDictadowithActions';
import TemasAreawithListActions from '../../../../../../containers/DocentesContainers/TemasAreawithListActions';
import TemasSeleccionadoswithQuery from '../../../../../../containers/DocentesContainers/TemasSeleccionadoswithQuery.js';

import '../EditarTemasArea.scss';

const EditarTemasAreaDocente = () => {
  return ( 
    <div className="EditarTemasArea">
      <Modal.Body>
        <AreasDictadowithActions />
        <TemasAreawithListActions />
        <TemasSeleccionadoswithQuery />
      </Modal.Body>
    </div>
   );
}
 
export default EditarTemasAreaDocente;