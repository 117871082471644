import React, { useEffect } from 'react';
import Layout from '../../../../app/Layout';
import FormularioFiltroCargosDescuentos from '../../../components/Finanzas/FormularioFiltroCargosDescuentos';
import TablaReporteCargosDescuentos from '../../../components/Finanzas/TablaReporteCargosDescuentos';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import { limpiarResultadoBusqueda } from '../../../../actions/finanzasReportesActions';

const ReporteCargoDescuentos = () => {
  
  const { reporteDescuentosCargos = {}, loading, error } = useSelector(state => state.finanzasReportes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(limpiarResultadoBusqueda());
  }, [dispatch])

  return ( 
    <Layout>
      <div className="ReporteCargoDescuentos">
        <div className="ReporteCargoDescuentos__header">
          <HeaderContent title='Reportes Cargos y Descuentos' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/", nombre: "Reporte de cargos y descuentos" },
            ]}
          />
        </div>
        <div className="ReporteCargoDescuentos__body">
          <FormularioFiltroCargosDescuentos />
          <SpinnerLoading loading={loading} error={error}>
            {
              (reporteDescuentosCargos) && (
                <>
                <div className="box box-primary mt-3">
                  <div className="box-body">
                    <TablaReporteCargosDescuentos
                      tabla={reporteDescuentosCargos.tabla}
                    />
                  </div>
                </div>
                <div className='text-right my-4'>
                  <h4 className='title-header'>Total: S/{reporteDescuentosCargos.total}</h4>
                </div>
                </>
              )
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteCargoDescuentos;