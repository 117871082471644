import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import { actualizarCampaign, actualizarCampaña } from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { useSelector } from 'react-redux';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarCampaña = ({registro}) => {


const [fechaInicio, setFechaInicio]=useState(registro.fecha_inicio)
const [fechaFin, setFechaFin]=useState(registro.fecha_fin)
const [estado, setEstado]=useState(registro.estado)
const {campaigns} = useSelector(state=>state.campaigns)
  const dispatch = useDispatch();

  const editarCampaign = async (e) =>{
    e.preventDefault();
    
    const datosCampaign = {
      id_camp: registro.id_camp,
      fecha_inicio:fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD'):null,
      fecha_fin:fechaFin ? moment(fechaFin).format('YYYY-MM-DD'):null,
      estado:estado ? estado :null
    }

    await dispatch(actualizarCampaign(datosCampaign,campaigns))
   // console.log(datosLead)
  }
 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editarCampaign(e)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                        {registro.nombre_proyecto}
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha inicio
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input type="date" name="fechaInicio" value={fechaInicio} className="form-control" onChange={(e)=>setFechaInicio(e.target.value)}/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha Fin
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fechaFin" value={fechaFin} className="form-control" onChange={(e)=>setFechaFin(e.target.value)}/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Estado
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="estado" className="form-control" value={estado} onChange={(e)=>setEstado(e.target.value)} >
                      <option value="1">En progreso</option>
                      <option value="2">Culminado</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarCampaña;
