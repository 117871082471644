import React from "react";
import HeaderContent from "../General/HeaderContent";
import Breadcrumbs from "../General/Breadcrumb";
import ModalInsertarProfesores from "./modal/ModalInsertarProfesores";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
//REDUX
import {connect} from 'react-redux'
import { listarSesion} from '../../../actions/cursosActions'
import { listarProfesoresSesiones, listarProfesoresCursos, eliminarProfesorSesion} from '../../../actions/profesoresActions'
import { useEffect, useState } from "react";
import Layout from "../../../app/Layout";

const ProfesoresSesion = (props) => {

  const { idProyecto, idSesion } = useParams();
    //STATE
  const { sesion, profesoresSesion} = props
    //FUNCIONES
  const { listarSesion, listarProfesoresSesiones, listarProfesoresCursos, eliminarProfesorSesion} = props 

  const [dataProfesorSesion, setDataProfesorSesion] = useState([])

  const llenarData = (profesoresSesion) => {
    let dataGenerado = [];
    profesoresSesion.map(profesor => {
      dataGenerado = [
        ...dataGenerado,
        {
          id: profesor.id_docente,
          nombre_profesor: `${profesor.nombre_usuario} ${profesor.apellido_usuario}`,
          descripcion: profesor.descripcion,
          button: (
            <React.Fragment>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                title='Eliminar'
                onClick={() => eliminarDocenteSesion(profesor.id_docente_sesion, idSesion)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </React.Fragment>
          )
        }
      ];
    });
    setDataProfesorSesion(dataGenerado)
  };

  const eliminarDocenteSesion = (idDoceteSesion, idSesion) => {
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Estas seguro?',
        text: 'Se borrara toda informacion',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {

          const {mensaje, textMensaje} = await eliminarProfesorSesion(idDoceteSesion, idSesion)          
          //consultando para ver el mensaje de aprovación
          if(mensaje) {
            return {
              resultado: mensaje,
              textResultado: textMensaje
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: textMensaje
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        const {resultado, textResultado} = result.value || {}

        if (resultado === true) {
          swalWithBootstrapButtons.fire(
            'Eliminado',
            textResultado,
            "success"
          );
        }
      });
  }

    useEffect(() => {
        listarSesion(idSesion)
        listarProfesoresSesiones(idSesion)
        listarProfesoresCursos(idProyecto)
    }, [])

    useEffect(() => {
      llenarData(profesoresSesion)
    }, [profesoresSesion])

  return (
    <Layout>
      <HeaderContent
        title={"Sesion: Asignar Docente"}
        icon={"menu_book"}
        subItem={`${sesion.nombre_sesion}`}
      />

    <Breadcrumbs/>

      <div className="clearfix">
        <div className="container-fluid px-0 content-actions my-3">
          <div className="row">
            <div className="col-md-9">
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-primary float-right text-white"
                data-toggle="modal"
                data-target=".insertar-profesores-sesiones"
              >
                <p className="mb-0 d-flex align-items-center">
                  <i className="material-icons">add</i>
                  <span className="text-white"> Añadir Profesores</span>
                </p>
              </button>
              <ModalInsertarProfesores
                idSesion={idSesion}
              />
            </div>
          </div>
        </div>
        <div className="p-0 mb-5 bg-white">
          <div className="continer-fluid border-bottom-0 border">
            <h5
              className="p-3 d-flex align-items-center mb-0"
              style={{ color: "#434343" }}
            >
              <i class="fas fa-list pr-2"></i>Lista de Docentes
            </h5>
          </div>
          <div className="tabla border">
            <React.Fragment>
              <MaterialTableDemo colums={colums} tabla={dataProfesorSesion} />
            </React.Fragment>
          </div>
        </div>
      </div>
          
    </Layout>
  );
};

const mapStateToProps = state => ({
  sesion: state.cursos.sesion,
  profesoresSesion: state.profesores.profesoresSesion
})

export default connect(mapStateToProps, {
  listarSesion,
  listarProfesoresSesiones,
  listarProfesoresCursos,
  eliminarProfesorSesion
})(ProfesoresSesion);

const colums = [
    {
        text: "Id",
        key: "id",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Profesor",
        key: "nombre_profesor",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Descripcion",
        key: "descripcion",
        align: "left",
        sortable: true,
        className: "type_of_food"
    },
    {
        text: "Acciones",
        key: "button",
        align: "left",
        sortable: true,
        className: "type_of_food"
    }
]