import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { exportarPosiblesClientes, filtrarLeads, listarLeads } from '../../../actions/leadsActions';
import useSelectEstadosLeads from "../../../hooks/useSelectEstadosLeads";
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioFiltroAsesor = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const {leads,filtrado} = useSelector(state=>state.leads)
const [estado, SelectEstado] = useSelectEstadosLeads(null)
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [fechaFiltro, setFechaFiltro] = useState(null)
const [fechaCampaing, setFechaCampaing] = useState(null)


const handleExportar = async () => {
  
  const respuesta = await exportarPosiblesClientes(leads,usuario.id_usuario);
 
  if(respuesta.exito) {
    avisoCorrecto(respuesta.message);
  } else {
    avisoError(respuesta.message);
  }
}


const traerDatos = () =>{
  if(!estado && !proyecto && (!fechaFiltro || fechaFiltro==='') && !fechaCampaing)
  {
    dispatch(listarLeads(usuario))
  }
  else{
  const datosFiltro = {
      estado:estado,
      fechaFiltro: fechaFiltro ? moment(fechaFiltro).format('YYYY-MM-DD') : null,
      proyecto: proyecto ? proyecto : null,
      fechaCampaing: fechaCampaing ? moment(fechaCampaing).format('YYYY-MM-DD') : null,
    }

    //console.log(datosFiltro)

    dispatch(filtrarLeads(datosFiltro,usuario))
  }
}



 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
      <div className="row p-2">
      
        <div className="col-5 mb-2">
          <label htmlFor="">Estado:</label>
          <SelectEstado/>
        </div>
        <div className="col-7 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-4">
          <label htmlFor="">Fecha Llamada:</label>
          <DatePicker
                selected={fechaFiltro}
                onChange={(date) =>setFechaFiltro(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaFiltro"
               // startDate={formik.values.fechaInicio}
                className="form-control"
              
               // endDate={formik.values.fechaFin}
              />
        </div>
        <div className="col-4">
          <label htmlFor="">Fecha Registro:</label>
          <DatePicker
                selected={fechaCampaing}
                onChange={(date) =>setFechaCampaing(date)}
                selectsStart
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                name="fechaCampaing"
               // startDate={formik.values.fechaInicio}
                className="form-control"
              
               // endDate={formik.values.fechaFin}
              />
        </div>
        <div className="col-12 pt-2">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        {
          filtrado.length>0 && (estado || proyecto || fechaFiltro) ? <button className="btn btn-primary" onClick={()=>handleExportar()}> Enviar a Zoho </button> : ''
        }
      { /* <button className="btn btn-primary" onClick ={ordenarDiscentes} > <i className="fa fa-arrow-down" style={{marginRight:'5px'}}>Discentes</i></button>
        <button className="btn btn-primary" onClick ={ordenarDesercion}> <i className="fa fa-arrow-down" style={{marginRight:'5px'}}>Deserción</i></button>
     */ }  </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltroAsesor;
