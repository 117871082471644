import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import {actualizarWebinar} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarWebinar = ({registro}) => {

const [nombre_evento, setNombreEvento]=useState(registro.nombre_evento)
const [promedio_asistentes_mx, setPromedioMx]=useState(registro.promedio_asistentes_mx?registro.promedio_asistentes_mx:'')
const [promedio_asistentes_mn, setPromedioMn]=useState(registro.promedio_asistentes_mn?registro.promedio_asistentes_mn:'')
const [total_asistentes, setTotalAsistentes]=useState(registro.total_asistentes?registro.total_asistentes:'')
const [inscritos, setInscritos]=useState(registro.inscritos?registro.inscritos:'')
const [cantidad_leads, setCantidadLeads]=useState(registro.cantidad_leads?registro.cantidad_leads:'')
const [fecha_realizacion, setFechaRealizacion]=useState(registro.fecha_realizacion)
const [fechaInicio, setFechaInicio]=useState(registro.desde?registro.desde:'')
const [fechaFin, setFechaFin]=useState(registro.hasta?registro.hasta:'')
const [grabacion, setGrabacion]=useState(registro.grabacion?registro.grabacion:'')
const [observaciones, setObservaciones]=useState(registro.observaciones?registro.observaciones:'')
const dispatch = useDispatch();
const {webinars} = useSelector(state=>state.campaigns)


 /* const handleChanceLeads = (e) =>{
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value !== '' && re.test(e.target.value)) {
     //  setLeads(e.target.value)
    }
    else
    //{ setLeads('')}
  }*/


  const editarCampaign = async (e) =>{
    e.preventDefault();
    
    const datosCamp={
      id_webinar: registro.id_webinar,
      nombre_evento: nombre_evento!==''?nombre_evento:null,
      promedio_asistentes_mx: promedio_asistentes_mx!==''?promedio_asistentes_mx:null,
      promedio_asistentes_mn: promedio_asistentes_mn!==''?promedio_asistentes_mn:null,
      total_asistentes: total_asistentes !=='' ? total_asistentes : null,
      inscritos: inscritos !=='' ? inscritos : null,
      desde: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      hasta: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
      fecha_realizacion: fecha_realizacion ? moment(fecha_realizacion).format('YYYY-MM-DD') : null,
      cantidad_leads: cantidad_leads!=='' ? cantidad_leads : null,
      grabacion: grabacion!=='' ? grabacion : null,
      observaciones: observaciones!==''?observaciones:null
    }

  await dispatch(actualizarWebinar(datosCamp,webinars))
   //console.log(datosCamp)
  }
 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editarCampaign(e)}>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Evento:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_evento} onChange={(e)=>setNombreEvento(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha Realización
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fecha_realizacion" value={fecha_realizacion} className="form-control" onChange={(e)=>setFechaRealizacion(e.target.value)}/>
                  
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Cantidad Leads:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="objetivo" value={cantidad_leads} onChange={(e)=>setCantidadLeads(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Leads Desde:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fechaInicio" value={fechaInicio} className="form-control" onChange={(e)=>setFechaInicio(e.target.value)}/>
                  
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Leads Hasta:
                      </label>
                    </div>
                    <div className="col-md-7">
                    <input type="date" name="fechaFin" value={fechaFin} className="form-control" onChange={(e)=>setFechaFin(e.target.value)}/>
                  
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Asistentes Totales:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="total_asistentes" value={total_asistentes} onChange={(e)=>setTotalAsistentes(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Prom. Máx. Asistentes:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="prom_mx" value={promedio_asistentes_mx} onChange={(e)=>setPromedioMx(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Prom. Mín. Asistentes:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="prom_mn" value={promedio_asistentes_mn} onChange={(e)=>setPromedioMn(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Inscritos:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="inscritos" value={inscritos} onChange={(e)=>setInscritos(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Enlace de Grabación:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="grabacion" value={grabacion} onChange={(e)=>setGrabacion(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Observaciones:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <textarea className="form-control" name="observaciones" value={observaciones} onChange={(e)=>setObservaciones(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarWebinar;
