import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listarCertificadosEmitidos } from '../../../actions/basesCertActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';


const FormularioEmitidos = () => {
const dispatch = useDispatch();
const [proyecto, SelectProyecto] = useSelectProyecto(null)
const {csv_firma} = useSelector(state=>state.bases)
const traerDatos = () =>{
  if(proyecto)
  { 
    const datosFiltro = {
      proyecto: proyecto ? proyecto.id_proyecto : null,
  }

  // console.log(datosFiltro)
  dispatch(listarCertificadosEmitidos(datosFiltro))

  }
  else
  {
    avisoError("Debe seleccionar un producto...")
  }
  
}

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Filtrar documentos emitidos:
                  </h5>
                
                </div>
      <div className="row p-2">
        <div className="col-9 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-12 pt-4">
          <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
          { csv_firma ? <a className="btn btn-primary" href={`watana:?url_logo=${process.env.REACT_APP_API_URL}/files_inedi/icon_inedi2.png&url_masivo_csv=${process.env.REACT_APP_API_URL}/files_inedi/${csv_firma}`} target="_blank">Firmar Todos <FontAwesomeIcon icon={faEdit}/></a>:null}
        </div>
      </div>
    </div>
  
  
    );
};

export default FormularioEmitidos;
