import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ListDocenteItem from './ListDocenteItem';

//Redux
import { useDispatch } from 'react-redux';
import { agregarDocenteCapacitacion } from '../../../../../actions/controlCapacitacionesActions';
import { useParams } from 'react-router-dom';
import MaterialTableDemo from '../../../plugins/MaterialTableDemo';

const TodoDocentes = ({ docentes }) => {
  
  const dispatch = useDispatch()
  const { idCapacitacion } = useParams()
  console.log(docentes)
  const nuevaRelacionDocente = (idDocente) => {
    dispatch( agregarDocenteCapacitacion(idCapacitacion, idDocente) )
  }

  if(!docentes) return <p>cargando...</p>

  return ( 
    <section className="todo-docentes">
      <h5>Todos</h5>
        <div className='todo-docentes__lista'>
          {docentes.length ? (
            <MaterialTableDemo
            colums={colums(nuevaRelacionDocente)}
            tabla={docentes}
          />
            
          ) : (
            <p>No hay docentes relacionados a esta capacitación.</p>
          )}
        </div>
    </section>
   );
}
 
export default TodoDocentes;
const colums = (nuevaRelacionDocente) =>[
  {
    text: 'id',
    key: 'id_docente',
    className: 'id',
    width: 50
  },
  {
    text: "Nombre",
    key: "nombre_usuario",
    className:"nombre_usuario",
    sortable: true,
  
  },
  {
    text: "Apellido",
    key: "apellido_usuario",
    className:"apellido_usuario",
    sortable: true,
  },
  {
    text: "Acciones",
    key: "agregar",
    align: "center",
    className:"agregar",
    sortable: true,
    cell: (record)=>
    <button
                    className='btn-success btn btn-sm'
                    onClick={() => nuevaRelacionDocente(record.id_docente)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
      </button> 
  },
]