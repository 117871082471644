import React, { useState, useEffect } from 'react';
import Layout from '../../../../app/Layout';
import FormularioFiltroReporte from '../../../components/Finanzas/FormularioFiltroReporte';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import { useDispatch, useSelector } from 'react-redux';
import { limpiarResultadoBusqueda, obtenerReporteDeudores } from '../../../../actions/finanzasReportesActions';
import ResultadoReporteDeudas from '../../../components/Finanzas/ResultadoReporteDeudas';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';

const ReporteDeudores = () => {

  const [datosBusqueda, setDatosBusqueda] = useState(null);
  const dispatch = useDispatch()
  const { loading, error, reporteDeudas } = useSelector(state => state.finanzasReportes);
  const handleObtenerReporteDeudas = (datos) => {
    dispatch(obtenerReporteDeudores(datos))
  }
  
  useEffect(() => {
    dispatch(limpiarResultadoBusqueda());
  }, [])

  return ( 
    <Layout>
      <div className="ReporteDeudores">
        <div className="ReporteDeudores__head">
          <HeaderContent title='Reporte de Deudores' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reporte" },
              { url: "", nombre: "Deudores" },
            ]}
          />
        </div>
        <div className="ReporteDeudores__body">
          <div className="box box-primary">
            <div className="box-body">
              <FormularioFiltroReporte
                onSubmit={handleObtenerReporteDeudas}
                setValores={setDatosBusqueda}
              />
            </div>
          </div>          
          <SpinnerLoading loading={loading} error={error}>
            {
              reporteDeudas.tabla &&  <ResultadoReporteDeudas
                reporteDeudas={reporteDeudas}
                datosBusqueda={datosBusqueda}
              />
            }
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteDeudores;