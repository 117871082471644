import React from "react";
import "./InfoEncuesta.scss";
import ListaAvatars from "../../ListaAvatars";
import * as moment from "moment";
//Redux
import { connect } from "react-redux";

const InfoEncuesta = (props) => {
  //State redux
  const { encuesta } = props;

  return (
    <>
      <div className="info-encuesta">
        <div className="content info-encuesta__resumen-encuesta">
          <div className="content-title">
            <h5 className="title">Resumen</h5>
          </div>
          <div className="content-description">
            <div className="content-description-item">
              <p className="subTitle">Nombre</p>
              <p className="info">{encuesta.nombre_formulario}</p>
            </div>
            <div className="content-description-item">
              <p className="subTitle">Fecha creación</p>
              <p className="info">
                {moment(encuesta.fecha_creacion).format("DD MMM YYYY")}
              </p>
            </div>
            <div className="content-description-item">
              <p className="subTitle">Estado</p>
              <p className="info">{encuesta.nombre_tp_estado}</p>
            </div>
          </div>
        </div>

        <div className="content info-encuesta__respondidos mb-5">
          <div className="content-title">
            <h5 className="title">
              {encuesta.completo_discente !== undefined &&
                encuesta.completo_discente.length}{" "}
              Respondidos
            </h5>
          </div>
          {/* <div className="content-description">
            <ListaAvatars avatars={encuesta.completo_discente} />
          </div> */}
        </div>
        {/* <div className="content info-encuesta__respondidos">
          <div className="content-title">
            <h5 className="title">
              {encuesta.incompleto_discente !== undefined &&
                encuesta.incompleto_discente.length}{" "}
              Faltantes
            </h5>
          </div>
          <div className="content-description">
            <ListaAvatars avatars={encuesta.incompleto_discente} />
          </div>
        </div> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  encuesta: state.encuestas.encuesta,
});

export default connect(mapStateToProps, {})(InfoEncuesta);
