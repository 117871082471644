import React, { useState } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DatosMetodoPago.scss';
import ContenidoPlan from '../ContenidoPlan';
import { useSelector, useDispatch } from 'react-redux';
import { nuevaMatricula } from '../../../../actions/matriculasActions';

const DatosMetodoPago = ({ proyecto }) => {

  const dispatch = useDispatch();
  const { estudiante } = useSelector(state => state.estudiantes);
  const { loading } = useSelector(state => state.matriculas);
  const { academico, planes } = proyecto;
  const [planesSelect, setPlanesSelect] = useState([]);
  const [plan, setPlan] = useState({});
  const [conceptosFinales, setConceptosFinales] = useState([]);
  const [valueSelecPlanes, setvalueSelectPlanes] = useState(''); 
  const [valueSelecPlan, setvalueSelectPlan] = useState(''); 
  const [checked, setChecked] = React.useState(false);
  const [trabajador, setTrabajador] = React.useState(false);
  const [becado, setBecado] = React.useState(false);
  

  const obtenerPlanes = (idModalidad) => {
    if(idModalidad === 2) {
      setPlanesSelect(planes.planPagoUnico);
    } else if(idModalidad === 1) {
      setPlanesSelect(planes.planPagosMensualidades)
    } else if(idModalidad === 3) {
      setPlanesSelect(planes.planPagoCuotas)
    }
  }

  const resetStates = () => {
    setvalueSelectPlan('');
    setConceptosFinales([]);
    setvalueSelectPlanes('')
    setPlan({});
  }

  const handleEditarConcepto = (idConceptoFinal, fechaLimite) => {
    const copiaConceptosFinales = [...conceptosFinales];
    copiaConceptosFinales.forEach(concepto => {
      if(concepto.id_dt_plan === idConceptoFinal) {
        concepto.fecha_limite = fechaLimite;
      }
    });
    setConceptosFinales(copiaConceptosFinales);
  }

  const handleSubmit = e => {
    e.preventDefault();
    const datosMatricula = {
      id_plan: plan.id_plan,
      id_estudiante: estudiante.id_estudiante,
      pago_recurrente: checked,
      es_trabajador:trabajador,
      es_becado:becado,
      pension: conceptosFinales
    }
    dispatch(nuevaMatricula(datosMatricula));
  }

  return ( 
    <div className="DatosMetodoPago">
      <div className='form-header'>
        <div className='DatosMetodoPago__nombre-proyecto'>
          <FontAwesomeIcon icon={faBook} />
          <span title={`${academico.nombre_proyecto}`}>{academico.nombre_proyecto}</span>
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>Seleccionar método de pago</Form.Label>
            <Form.Control as='select' value={valueSelecPlanes} onChange={
              e => {
                resetStates();
                setvalueSelectPlanes(e.target.value);
                obtenerPlanes(parseInt(e.target.value));
              }
            }>
            <option value="" disabled>--Seleccione--</option>
            <option value="2">INVERSION AL CONTADO</option>
            <option value="3">CUOTAS</option>
            <option value="1">MENSUALIDADES</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>Seleccionar el plan</Form.Label>
            <Form.Control
              as='select'
              value={valueSelecPlan}
              onChange={e => {
                setvalueSelectPlan(e.target.value);
                let planValue = planesSelect.find(plan => plan.id_plan === parseInt(e.target.value));
                setPlan(planValue);
              }}
            >
            <option value="" disabled>--Seleccione--</option>
            {
              planesSelect.length > 0 ? (
                planesSelect.map(plan => (
                  <option key={plan.id_plan} value={plan.id_plan}>{plan.nombre_plan} - {plan.tp_plan}</option>
                ))
              ) : (
                null
              )
            }
          </Form.Control>
        </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={12}>
            <span className='form-header'>Contenido</span>
            <ContenidoPlan
              plan={plan}
              setConceptosFinales={setConceptosFinales}
              conceptosFinales={conceptosFinales}
              handleEditarConcepto={handleEditarConcepto}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md={2} className='text-left'>
        
        <Form.Check type="checkbox" label="Es trabajador" value={trabajador} onChange={() => {setTrabajador(!trabajador);}}/>
        </Form.Group>
        <Form.Group as={Col} md={2} className='text-left'>
        
        <Form.Check type="checkbox" label="Es pago recurrente" value={checked} onChange={() => {setChecked(!checked);}}/>
        </Form.Group>
        <Form.Group as={Col} md={2} className='text-left'>
        
        <Form.Check type="checkbox" label="Es Becado" value={becado} onChange={() => {setBecado(!becado);}}/>
        </Form.Group>
        <Form.Group as={Col} md={6} className='text-right'>
          <Button
            disabled={conceptosFinales.length && !loading > 0 ? false : true}
            className='btn-spinner'
            variant='info'
            type='submit'
          >
            {
              loading && <Spinner as='span' animation='border' size='sm' />
            }
            Matricular Discente
          </Button>
        </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default DatosMetodoPago;