import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import FormEditarConfiguracionAreaTrabajador from './FormEditarConfiguracionAreaTrabajador';

const ClasificacionAreaTrajador = ({ area, clasificacion }) => {

  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(
      updateStateModalBasic(
        <FormEditarConfiguracionAreaTrabajador
          datosConfiguracion={{area, clasificacion}}
        />, 'Editar configuracion de clasificación'
      )
    )
  }

  return ( 
    <div className="ClasificacionAreaTrajador">
      <div className="ClasificacionAreaTrajador__head px-3 py-3 border-bottom d-flex justify-content-between">
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon icon={faListUl} />
          <h5 className='ListaClasificacion__title ml-1'>Clasificación</h5>
        </div>
        <div className='d-flex align-items-center cursor-pointer'>
          <FontAwesomeIcon icon={faEdit} className='my-1' />
          <span onClick={handleModal}>Editar</span>
        </div>
      </div>
      <div className="ClasificacionAreaTrajador__body p-3">
        <Row>
          <Col md={2}><p className="font-weight-bold">Clasificación:</p></Col>
          <Col md={10}>{clasificacion.nombre_clasificacion}</Col>
        </Row>
        <br/>
        <Row>
          <Col md={2}><p className="font-weight-bold">Área:</p></Col>
          <Col md={10}>{area.nombre_tp_nivel_usuario}</Col>
        </Row>
      </div>
    </div>
  );
}
 
export default ClasificacionAreaTrajador;
