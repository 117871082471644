import React from 'react';
import MaterialTableDemo from '../../../plugins/MaterialTableDemo';

const TablaReporteDeudores = ({ tabla }) => {
  return ( 
    <div className="box box-primary mt-3">
      <div className="box-body">
        <MaterialTableDemo
          colums={columns()}
          tabla={tabla}
          addConfig={{key_column: 'id_temp'}}
        />
      </div>
    </div>
  );
}
 
export default TablaReporteDeudores;

const columns = () => [
  {
    key: 'index',
    text: '#',
    cell: (_, index) => index+1,
    className: "text-center",
    sortable: true,
  },
  {
    key: 'nombre_discente',
    text: 'Nombres y apellidos',
    sortable: true
  },
  {
    key: 'periodo',
    text: 'Periodo',
    className: "text-center",
    sortable: true
  },
  {
    key: 'nombre_proyecto',
    text: 'Evento',
    cell: record => <p title={`${record.nombre_proyecto}`}>
      {`${record.nombre_proyecto.substring(0, 100)} ${record.nombre_proyecto.length > 100 ? '...' : ''}`}
    </p>
  },
  {
    key: 'dni',
    text: 'DNI',
    className: "text-center",
    sortable: true
  },
  {
    key: 'email',
    text: 'Correo'
  },
  {
    key: 'celular',
    text: 'Celular',
    width: 100
  },
  {
    key: 'concepto',
    text: 'Concepto',
    className: "text-uppercase text-center",
    width: 130
  },
  {
    key: 'fecha_limite',
    text: 'Fecha límite',
    className: 'text-center',
    sortable: true
  },
  {
    key: 'monto_deuda',
    text: 'Monto deuda (S/.)',
    className: "text-right",
    sortable: true,
    cell: record => record.monto_deuda ? parseFloat(record.monto_deuda).toFixed(2) : '0.00'
  }
]