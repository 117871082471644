import React, {useState, useEffect} from 'react';
import Editor from './EditorPopUp'


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { actualizarpopup } from '../../../actions/popupsActions';

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioPopUp = ({popupsDatos}) => {

const [datosPop, setPopUpDatos] = useState(popupsDatos);
const [mostrarImagen,setMostrarImagen] = useState(datosPop.SOLO_IMAGEN==="0"?false:true)
const {fondos} = useSelector(state => state.popUp)
const {FONDO} = datosPop
   const dispatch = useDispatch();
 const handleChange= (event) => {
        const value = event.target.value;
        setPopUpDatos({
          ...datosPop,
          [event.target.name]: value
        });
    
 }

 const setMensaje = (a) => {
    const value = a;
    //console.log(value);
    setPopUpDatos({
        ...datosPop,
        MENSAJE: value
    });
 }


  const handleSubmit = (e) =>{
    e.preventDefault();
  
  
   //console.log(datosPop)
    dispatch(actualizarpopup(datosPop))
  }


 return (
    <div className="box box-primary">
        <div className="box-body">
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Titulo:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="TITULO"
                        onChange={handleChange}
                        value={datosPop.TITULO}
                        type="text"
                        className="form-control"
                        id="titulopop"
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Boton "Saber más":
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="ENLACE"
                        onChange={handleChange}
                        value={datosPop.ENLACE}
                        type="text"
                        className="form-control"
                        id="enlace"
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Mostrar sólo la Imagen:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                         type="checkbox"  checked={mostrarImagen} onChange={() => {
                           setMostrarImagen(!mostrarImagen);
                           setPopUpDatos({
                                ...datosPop,
                                SOLO_IMAGEN: !mostrarImagen
                            });
                           }}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row">
                  <div className="col-md-6">
                  <label> Mensaje: </label>
                    <Editor
                      setContenido={setMensaje}
                      dataDefault={datosPop.MENSAJE}
                    />
                  </div>
                  <div className="col-md-6">
                  <label>Fondo:</label>
                  <br/>
                  <select className="form-control" value={FONDO} name="FONDO" onChange={handleChange} required>
                  <option value=""> Seleccione un Fondo</option>
                  {
                    fondos ? fondos.map((fondo_,i)=>(
                      <option key={i} value={fondo_.archivo}>{fondo_.archivo}</option>
                    )) : ''
                  }
                  </select>
                  <br/>
                  {
                    FONDO ? <img src={`${process.env.REACT_APP_API_URL}/files_inedi/fondos_pop/${FONDO}`} className="img-fluid" /> : ''
                  }
                  </div>
                  </div>
                  <button type="submit" className="btn mt-3" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
        </div>
      </div> 
    );
};

export default FormularioPopUp;
