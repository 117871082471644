import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Layout from '../../../../app/Layout';
import DatosMetodoPago from '../../../components/Matriculas/DatosMetodosPago';
import FormDiscente from '../../../components/Matriculas/FormDiscente';
import TablaProyectosMatricula from '../../../components/Matriculas/TablaProyectosMatricula';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import useGetProyectos from '../../../../hooks/useGetProyectos';
import { useDispatch, useSelector } from 'react-redux';
import { listarProyecto } from '../../../../actions/cursosActions';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import Fatal from '../../../components/MessageAlert/Fatal';
import './NuevaMatricula.scss';
import { removerStateEstudiante } from '../../../../actions/estudiantesActions';

const NuevaMatricula = () => {

  const { proyectos } = useGetProyectos();
  const dispatch = useDispatch();
  const { proyecto, loading, error } = useSelector(state => state.cursos);
  const { estudiante } = useSelector(state => state.estudiantes);
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null);
  const [mostrarContenido, setMostrarContenido] = useState(false);

  const mostrarMetodosPago = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    if(proyecto && proyectoSeleccionado) return <DatosMetodoPago
      proyecto={proyecto}
    />
    return <Alert variant='info'>
      Seleccione un proyecto para ver las modalidades de pago
    </Alert>;
  }

  useEffect(() => {
    dispatch(removerStateEstudiante());
  }, [])

  useEffect(() => {
    if(proyectoSeleccionado) {
      dispatch(listarProyecto(proyectoSeleccionado.id_proyecto));
    }
  }, [proyectoSeleccionado])

  return ( 
    <Layout>
      <div className="NuevaMatricula">
        <div className="NuevaMatricula__header">
          <HeaderContent title='Nueva matrícula' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/pagos", nombre: "Pagos" },
            ]}
          />
        </div>
        <div className="NuevaMatricula__body box">
            <div className="box-header with-border">
              <h5 className='box-title'>
                Registro de matrícula
              </h5>
            </div>
          <div className="box-body">
          <FormDiscente
            setMostrarContenidoProyectos={setMostrarContenido}
          />
          {
            Object.keys(estudiante).length > 0 && mostrarContenido ? (
              <>
                <Row className='mt-3'>
                  <Col md={6}>
                    <TablaProyectosMatricula 
                      proyectos={proyectos}
                      setProyectoSeleccionado={setProyectoSeleccionado}
                    />
                  </Col>
                  
                  <Col md={6}>
                    {mostrarMetodosPago()}
                  </Col>
                </Row>
              </>
            ) : (
              null
            )
          }
          </div>
        </div>
      </div>
    </Layout>
  );
}
 
export default NuevaMatricula;