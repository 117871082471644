import React from 'react';
import ResumenCvWithTrabajador from '../../../../../../containers/TrabajadoresContainers/ResumenCvWithTrabajador';
import ArchivosAdjuntosWithTrabajadores from '../../../../../../containers/TrabajadoresContainers/ArchivosAdjuntosWithTrabajadores';
import './VistaPerfilProfesionalTrabajador.scss';

const VistaPerfiProfesionaTrabajador = () => {
  return ( 
    <div className="VistaPerfilProfesionalTrabajador">
      <div className="VistaPerfilProfesionalTrabajador__container">
        <ResumenCvWithTrabajador />
        <ArchivosAdjuntosWithTrabajadores />
      </div>
    </div>
   );
}
 
export default VistaPerfiProfesionaTrabajador;