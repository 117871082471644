import React, {useEffect, useState} from 'react';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { editarEstadoMatriculaDiscente } from '../../../../actions/matriculasActions';
import { useParams } from 'react-router-dom';

const EstadoMatricula = ({ matricula }) => {

  const { id_tp_estado, pago_recurrente } = matricula;
  const dispatch = useDispatch();
  const { loadingEstado } = useSelector(state => state.matriculas);
  const { idMatricula } = useParams();
  const formik = useFormik({
    initialValues: {
      estado: id_tp_estado || '',
      pago_r: pago_recurrente || '0'
    },
    validationSchema: yup.object({
      estado: yup.string().required('Este campo es requerido.'),
      pago_r: yup.string().required('Este campo es requerido.')
    }),
    onSubmit: valores => {
      dispatch(editarEstadoMatriculaDiscente(idMatricula, valores.estado,valores.pago_r));
    }
  });

  return ( 
    <div className="EstadoMatricula mt-2">
      <div className="box-header with-border mb-4">
        <h6 className='title-head mb-0'>
          <FontAwesomeIcon icon={faUserCog} />
          Configuraciones de la matrícula
        </h6>
      </div>
      <div className="box-body py-2 px-4">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Form.Label column md={3} className='font-weight-bold'>Pago Recurrente</Form.Label>
              <Form.Group as={Col} md={3}>
                <Form.Control
                  as='select'
                  name='pago_r'
                  value={formik.values.pago_r}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.pago_r}
                >
                  <option value="1">Si</option>
                  <option value="0">No</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.estado}
                </Form.Control.Feedback>
              </Form.Group>
            <Form.Label column md={3} className='font-weight-bold'>Estado de la matrícula</Form.Label>
            <Form.Group as={Col} md={3}>
              <Form.Control
                as='select'
                name='estado'
                value={formik.values.estado}
                onChange={formik.handleChange}
                isInvalid={formik.errors.estado}
              >
                <option value="">--Seleccione--</option>
                <option value="1">Activo</option>
                <option value="2">Desertado</option>
                <option value="3">Culminado</option>
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {formik.errors.estado}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group className='text-right pr-2 mt-4'>
            <Button type='submit' disabled={loadingEstado} variant='secondary' className='btn-spinner'>
              {
                loadingEstado && <Spinner as='span' animation='border' size='sm' />
              }
              Guardar
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}
 
export default EstadoMatricula;