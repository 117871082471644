import React from 'react';

const Voucher = props => {

    const {rutaImagen}=props
    
    return (
        <>
             <img src={rutaImagen} alt="imagen" />
        </>
    );
};

export default Voucher;