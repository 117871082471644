import { LISTAR_NOTAS, EDITAR_NOTAS, REMOVER_ESTADO_NOTAS} from './types'

import authAxios from '../config/authAxios'

export const listarNotas = idSesion => async dispatch => {
    let notas = await authAxios.get(`/admin/mostrarAsignarNotasPorSesion/${idSesion}`)
    
    dispatch({
        payload: notas.data,
        type: LISTAR_NOTAS
    })
}

export const modificarNotas = (idSesion, dataNotas) => async dispatch => {
    
    const notas = {
        notas: dataNotas
    }
    let respuesta = await authAxios.put(`/admin/insertarNotasSesion/${idSesion}`, notas)
   // console.log(respuesta)
    const payload = {
        notas: respuesta.data.datos,
        statusEditar: respuesta.status
    } 

    dispatch({
        payload: payload,
        type: EDITAR_NOTAS
    })

   // console.log(respuesta)
}

export const removerEstadoNotas = () => dispatch => {
    dispatch({
        payload: 0,
        type: REMOVER_ESTADO_NOTAS
    })
}