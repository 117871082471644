import React from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import * as moment from 'moment'
import './TbResumenCtrlCapacitaciones.scss';
//Redux
import { useSelector } from 'react-redux';

const TbResumenCtrlCapacitaciones = () => {

  const { idCapacitacion } = useParams()
  const datos = useSelector(state => state.control_capacitacion.area_trabajo.datos)
  
  const capacitacion = datos[idCapacitacion] ? datos[idCapacitacion].datos_proyecto : {}

  return ( 
    <div className='tb-resumen-capacitacion box box-primary'>
      <Table striped>
        <tbody>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Producto</td>
            <td>{capacitacion.nombre_proyecto}</td>
          </tr>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Fecha de inicio</td>
            <td>{moment(capacitacion.fecha_inicio_proyecto).format('DD MMM YYYY')}</td>
          </tr>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Fecha de culminación</td>
            <td>{moment(capacitacion.fecha_fin_proyecto).format('DD MMM YYYY')}</td>
          </tr>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Instituciones certificadoras</td>
            <td>{capacitacion.certificado}</td>
          </tr>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Sede</td>
            <td>{capacitacion.sede}</td>
          </tr>
          <tr>
            <td className='tb-resumen-capacitacion__col--color'>Tutor</td>
            <td>{capacitacion.nombre_usuario} {capacitacion.apellido_usuario}</td>
          </tr>
        </tbody>
      </Table>
    </div>
   );
}
 
export default TbResumenCtrlCapacitaciones ;