import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import * as moment from 'moment'

const ModalEditar = ({asistencia, editarAsistencia}) => {

    const horaRef = useRef('')

    const actualizarAsistencia = e => {
        e.preventDefault()
        
        const time = moment(moment(horaRef.current.value,'HH:mm:ss')._d).format('LTS')
        editarAsistencia(asistencia.id_estudiante_sesion, time)
        
    }

    useEffect(() => {
        horaRef.current.value = asistencia.hora
    }, [asistencia])

    return (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        id="editar-asistencia"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Editar asistencia
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={e => actualizarAsistencia(e)}>
                <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      DNI:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="recipient-name"
                      defaultValue={asistencia.dni}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Nombre:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      disabled
                      defaultValue={asistencia.nombre_usuario}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Hora:
                    </label>
                    <input
                        type="time"
                        className='form-control'
                        defaultValue={asistencia.hora}
                        ref={horaRef}
                    />
                  </div>
                  <div className="modal-footer border-0">
                    <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    >
                    Close
                    </button>
                    <button type='submit' className="btn btn-danger">
                        Guardar
                    </button>
                </div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default ModalEditar;