import React from "react";

const Sesiones = ({ sesion, arrayNombreSesion, setArrayNombreSesion}) => {
  
  const guardarSeleccion = (e,sesion) => {
    e.stopPropagation()
    //guardar el botton
    const buttonCheck = e.target
    //verficar el estado
    if (buttonCheck.checked) {
      //guardar en el state
      console.log('me check')
      setArrayNombreSesion([
        ...arrayNombreSesion,
        {
          nombre_sesion: sesion.nombre_sesion,
          id_sesion_actual: sesion.id_sesion,
          fecha_sesion:''
        }
      ])
      return
    }
    //eliminar en el state
      console.log('me deschek')
      let nuevoArray = arrayNombreSesion.filter(sesionL => sesionL.id_sesion_actual !== sesion.id_sesion)

      setArrayNombreSesion(nuevoArray)
    
    
  }
  
  return (
    <React.Fragment>
      {(Object.keys(sesion).length) > 0 ?
        <li className="list-group-item">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            onChange={e => guardarSeleccion(e, sesion)}
            id={sesion.id_sesion}
          />
          <label className="custom-control-label" htmlFor={sesion.id_sesion}>
            {sesion.nombre_sesion}
          </label>
        </div>
      </li>
    
    : null}
      
    </React.Fragment>
  );
};

export default Sesiones;
