import React, { useState } from "react";

const FormInfoEmpresa = () => {
  const [infoEmpresa, setInfoEmpresa] = useState({
    social: "",
    comercial: "",
    ruc: "",
    direccion: "",
    direccionica: "",
  });

  const { social, comercial, ruc, direccion, direccionica } = infoEmpresa;

  const onChange = (e) => {
    setInfoEmpresa({
        ...infoEmpresa,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="social" className="col-form-label">
              Razon social:
            </label>
            <input
              type="text"
              className="form-control"
              id="social"
              name="social"
              onChange={onChange}
              value={social}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="comercial" className="col-form-label">
              Nombre comercial:
            </label>
            <input
              type="text"
              name="comercial"
              id="comercial"
              className="form-control"
              onChange={onChange}
              value={comercial}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="ruc">R.U.C.:</label>
            <input
                type="number"
                name="ruc"
                id="ruc"
                className="form-control"
                onChange={onChange}
                value={ruc}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="direccion">Direccion:</label>
            <input
              type="text"
              name="direccion"
              id="direccion"
              className="form-control"
              onChange={onChange}
              value={direccion}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="direccion-ica">Direccion Ica:</label>
        <input
          type="text"
          name="direccionica"
          id="direccion-ica"
          className="form-control"
            onChange={onChange}
            value={direccionica}
        />
      </div>
      <div className="modal-footer">
        <button type="reset" className="btn btn-secondary" data-dismiss="modal">
          Cancelar
        </button>
        <button type="submit" className="btn btn-primary">
          Guardar
        </button>
      </div>
    </form>
  );
};

export default FormInfoEmpresa;
