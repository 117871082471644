import React from "react";
import MaterialTableDemo from "../../../plugins/MaterialTableDemo";
import useGetCapacitacionControl from "../../../../../hooks/useGetCapacitacionControl";
import { useParams } from 'react-router-dom';
import * as moment from 'moment'; 
import './TablaControlCapacitaciones.scss';


const TablaControlCapacitaciones = ({ colums }) => {

  const { idCapacitacion } = useParams()

  const [{datos}] = useGetCapacitacionControl();
  
  const tabla_sesiones = datos[idCapacitacion] ? datos[idCapacitacion].tabla_sesiones : {}

  return (
    <div className="p-0 mt-4 bg-white tabla-control-capacitaciones">
      <div className="continer-fluid border-bottom-0 border">
        <h5
          className="p-3 d-flex align-items-center mb-0"
          style={{ color: "#434343" }}
        >
          <i className="fas fa-list pr-2"></i>Area de detalles
        </h5>
      </div>
      <div className="tabla border">
        <MaterialTableDemo
          colums={colums}
          tabla={tabla_sesiones} 
          className='table-responsive'  
          addConfig={{
            show_filter:true,
            key_column: 'id_sesion',
            filename: `control-capacitacion_${moment(new Date()).format('DD/MM/YYYY')}`
          }}
        />
      </div>
    </div>
  );
};

export default TablaControlCapacitaciones;

