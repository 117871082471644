import { faFilePdf, faListOl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import MaterialTableDemo from '../../plugins/MaterialTableDemo';
import * as moment from 'moment';

// Redux
import { useSelector } from 'react-redux';

const TablaFactura = () => {

  const { todos } = useSelector(state => state.finanzasReportes.facturas);

  const exportar = (idFactura) => {
    return (
      <Button href={`${process.env.REACT_APP_API_URL}/admin/pdf/factura/${idFactura}`} variant='danger' size='xs'>
        <FontAwesomeIcon icon={faFilePdf} />
      </Button>
    )
  }

  return (
    <>
    <h4 className='mb-3'>Resultado búsqueda</h4>
    <div className="box box-primary">
      <div className="box-body">
        <MaterialTableDemo
          colums={columns(exportar)}
          tabla={todos}
          addConfig={{key_column: 'id_factura'}}
        />
      </div>
    </div>
    </>
  );
}
 
export default TablaFactura;

const columns = (exportar) => [
  {
    key: 'index',
    text: '#',
    className: 'text-center',
    cell: (_, index) => index+1
  },
  {
    key: 'id_factura',
    text: 'ID Factura',
    className: 'text-center',
    width: 50
  },
  {
    key: 'nombre_discente',
    text: 'Nombres y apellidos'
  },
  {
    key: 'concepto',
    text: 'Concepto'
  },
  {
    key: 'nombre_proyecto',
    text: 'Evento',
    cell: record => <p title={`${record.nombre_proyecto}`}>
      {`${record.nombre_proyecto.substring(0, 100)} ${record.nombre_proyecto.length > 100 ? '...' : ''}`}
    </p>
  },
  {
    key: 'fecha',
    text: 'Fecha de pago',
    cell: record => moment(record.fecha).format('L')
  },
  {
    key: 'nombre_tp_estado_pago',
    text: 'Estado',
    className: 'text-center',
    cell: (record) => (
      <span className={`badge ${record.nombre_tp_estado_pago === 'PAGADO' ? 'badge-success' : 'badge-danger'}`}>
        {record.nombre_tp_estado_pago}
      </span>
    )
  },
  {
    key: 'monto_establecido',
    text: 'Total (S/.)',
    className: 'text-right'
  },
  {
    key: 'monto_saldo',
    text: 'Saldo (S/.)',
    className: 'text-right'
  },
  {
    key: 'acciones',
    text: 'Descargar',
    className: 'text-center',
    cell: record => exportar(record.id_factura)
  }
]
