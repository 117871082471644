import React from "react";
import PieChart from "../../components/Estadisticos/PieChart";
import BarChart from "../../components/Estadisticos/BarChart";

const RigthContent = () => {
  return (
    <div className='estadisticos'>
      <div className="title">
        <h3>Graficos</h3>
      </div>
      <div className="grafico pie">
        <p className="mb-0">Resumen</p>
        <PieChart />
      </div>

      <div className="grafico bar">
        <p>Mejores asistentes</p>
        <BarChart />
      </div>

      <div className="grafico bar">
        <p>Mayores inasistentes</p>
        <BarChart />
      </div>
    </div>
  );
};

export default RigthContent;
