import {
  EDITAR_ESTADO_PAGOS_C,
  LISTAR_DETALLES_PAGOS,
  REMOVER_INFORMACION_PAGOS,
  ACTUALIZAR_PAGO,
  LISTAR_EVENTOS_COMPRADOS,
  EDITAR_DETALLES_PAGOS,
  pagosTypes,
} from "../actions/types";

const initialState = {
  motivosCargosDescuentos: {
    datos: null,
    loading: false,
    error: null
  },
  conceptosPagos: {
    datos: null,
    loading: false,
    error: null
  },
  bancosAsocionado: [],
  datosFormulario: {
    tipo: "dni",
    valor: "",
  },
  datosFinanzasDiscente: {
    todo: {},
    matriculaActiva: {},
    cuotasPensiones: {
      todo: {},
      loading: null,
      loadingNuevoDescuentoCargo: false,
      errorNuevoDescuentoCargo: null,
      loadingInsertar: false,
      error: null,
    },
    detallePago: {
      modalidad: "",
      idPensionFraccion: "",
      numeroDocumento: "",
      error: null,
      loading: false,
    },
  },

  loading: null,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LISTAR_DETALLES_PAGOS:
      return {
        ...state,
        pagosEstudiantes: action.payload,
      };
    case ACTUALIZAR_PAGO:
      return {
        ...state,
        pagosEstudiantes: action.payload,
      };
    case EDITAR_ESTADO_PAGOS_C:
      return {
        ...state,
        pagosEstudiantes: action.payload,
      };
    case LISTAR_EVENTOS_COMPRADOS:
      return {
        ...state,
        proyectosComprados: action.payload.proyectos,
        modulosComprados: action.payload.modulos,
      };
    case EDITAR_DETALLES_PAGOS:
      return {
        ...state,
        pagosEstudiantes: action.payload,
      };
    case pagosTypes.UPDATE_FORMULARIO_BUSQUEDA:
      return {
        ...state,
        datosFormulario: {
          ...state.datosFormulario,
          [action.payload.name]: action.payload.value,
        },
      };
    case pagosTypes.OBTENER_MATRICULAS_DISCENTE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case pagosTypes.OBTENER_MATRICULAS_DISCENTE_EXITO:
      return {
        ...state,
        loading: null,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: action.payload,
          matriculaActiva: {},
        },
      };
    case pagosTypes.OBTENER_MATRICULAS_DISCENTE_ERROR:
    case pagosTypes.OBTENER_MATRICULAS_DISCENTE_NO_ENCONTRADO:
      return {
        ...state,
        loading: null,
        error: null,
      };
    case pagosTypes.MATRICULA_ACTIVA_DISCENTE:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          matriculaActiva: action.payload,
        },
      };
    case pagosTypes.OBTENER_CUOTAS_FRACCIONADAS:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            loading: true,
            error: null,
          },
        },
      };
    case pagosTypes.OBTENER_CUOTAS_FRACCIONADAS_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: action.payload,
            loading: false,
            error: null,
          },
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            modalidad: "",
            idPensionFraccion: "",
            numeroDocumento: "",
          },
        },
      };
    case pagosTypes.OBTENER_CUOTAS_FRACCIONADAS_ERROR:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            loading: false,
            error: action.payload,
          },
        },
      };
    case pagosTypes.INSERTAR_CUOTA_FRACCIONADA_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: {
              ...state.datosFinanzasDiscente.cuotasPensiones.todo,
              cuotas: [
                ...state.datosFinanzasDiscente.cuotasPensiones.todo.cuotas,
                action.payload,
              ],
            },
          },
        },
      };
    case pagosTypes.ACTUALIZAR_TIPO_PENSION:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: {
              ...state.datosFinanzasDiscente.cuotasPensiones.todo,
              ...action.payload,
            },
          },
        },
      };
    case pagosTypes.ACTUALIZADO_ESTADOS_TABLA_CUOTAS:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: {
            ...state.datosFinanzasDiscente.todo,
            matriculas: [...state.datosFinanzasDiscente.todo.matriculas].map(
              (matricula) =>
                matricula.id_matricula !== action.payload.idMatricula
                  ? matricula
                  : {
                      ...matricula,
                      cuotas: {
                        ...matricula,
                        ordinarios: matricula.cuotas.ordinarios.map(
                          (cuotaOrdinario) =>
                            cuotaOrdinario.id_dt_matricula !==
                            action.payload.idPension
                              ? cuotaOrdinario
                              : {
                                  ...cuotaOrdinario,
                                  ...action.payload.datos,
                                }
                        ),
                      },
                    }
            ),
          },
          matriculaActiva: {
            ...state.datosFinanzasDiscente.matriculaActiva,
            cuotas: {
              ...state.datosFinanzasDiscente.matriculaActiva.cuotas,
              ordinarios: [
                ...state.datosFinanzasDiscente.matriculaActiva.cuotas
                  .ordinarios,
              ].map((cuotaOrdinario) =>
                cuotaOrdinario.id_dt_matricula !== action.payload.idPension
                  ? cuotaOrdinario
                  : {
                      ...cuotaOrdinario,
                      ...action.payload.datos,
                    }
              ),
            },
          },
        },
      };
    case pagosTypes.REGISTRAR_DETALLES_PAGOS:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            modalidad: action.payload.modalidad,
            idPensionFraccion: action.payload.idPensionFraccion,
          },
        },
      };
    case pagosTypes.OBTENER_ID_BOLETA_SIGUIENTE_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            numeroDocumento: action.payload,
          },
        },
      };
    case pagosTypes.PAGAR_CUOTA:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            loading: true,
            error: null,
          },
        },
      };
    case pagosTypes.NUEVO_DESCUENTO_CARGO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            loadingNuevoDescuentoCargo: true,
            errorNuevoDescuentoCargo: null,
          }
        }
      }
    case pagosTypes.PAGAR_CUOTA_EXITO:
    case pagosTypes.ANULAR_PAGO_ORDINARIO_EXITO:
    case pagosTypes.NUEVO_DESCUENTO_CARGO_EXITO:
    case pagosTypes.ELIMINAR_DESCUENTO_CARGO_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: {
            ...state.datosFinanzasDiscente.todo,
            matriculas: [...state.datosFinanzasDiscente.todo.matriculas].map(
              (matricula) =>
                matricula.id_matricula !== action.payload.idMatricula
                  ? matricula
                  : {
                      ...matricula,
                      cuotas: {
                        ...matricula.cuotas,
                        ordinarios: matricula.cuotas.ordinarios.map(
                          (ordinario) =>
                            ordinario.id_dt_matricula ===
                            action.payload.datos.id_dt_matricula
                              ? action.payload.datos
                              : ordinario
                        ),
                      },
                    }
            ),
          },
          matriculaActiva: {
            ...state.datosFinanzasDiscente.matriculaActiva,
            cuotas: {
              ...state.datosFinanzasDiscente.matriculaActiva.cuotas,
              ordinarios: [
                ...state.datosFinanzasDiscente.matriculaActiva.cuotas
                  .ordinarios,
              ].map((cuota) =>
                cuota.id_dt_matricula === action.payload.datos.id_dt_matricula
                  ? action.payload.datos
                  : cuota
              ),
            },
          },
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: action.payload.datos,
            loadingNuevoDescuentoCargo: false,
            errorNuevoDescuentoCargo: null,
          },
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            loading: false,
            error: null,
          },
        },
      };
    case pagosTypes.NUEVO_DESCUENTO_CARGO_ERROR:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            loadingNuevoDescuentoCargo: false,
            errorNuevoDescuentoCargo: action.payload,
          }
        }
      }
    case pagosTypes.PAGAR_CUOTA_ERROR:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            loading: false,
            error: null,
          },
        },
      };
    case pagosTypes.REGISTRAR_PAGO_EXTRAORDINARIO_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: {
            ...state.datosFinanzasDiscente.todo,
            matriculas: [...state.datosFinanzasDiscente.todo.matriculas].map(
              (matricula) =>
                matricula.id_matricula !== action.payload.idMatricula
                  ? matricula
                  : {
                      ...matricula,
                      cuotas: {
                        ...matricula.cuotas,
                        extraordinarios: [
                          ...matricula.cuotas.extraordinarios,
                          action.payload.datos
                        ]
                      },
                    }
            ),
          },
          matriculaActiva: {
            ...state.datosFinanzasDiscente.matriculaActiva,
            cuotas: {
              ...state.datosFinanzasDiscente.matriculaActiva.cuotas,
              extraordinarios: [
                ...state.datosFinanzasDiscente.matriculaActiva.cuotas.extraordinarios,
                action.payload.datos
              ]
            },
          },
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: action.payload.datos,
          },
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            loading: false,
            error: null,
          },
        },
      };
    case pagosTypes.PAGAR_CUOTA_EXTRA_EXITO:
    case pagosTypes.ANULAR_PAGO_EXTRA_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: {
            ...state.datosFinanzasDiscente.todo,
            matriculas: [...state.datosFinanzasDiscente.todo.matriculas].map(
              (matricula) =>
                matricula.id_matricula !== action.payload.idMatricula
                  ? matricula
                  : {
                      ...matricula,
                      cuotas: {
                        ...matricula.cuotas,
                        extraordinarios: [...matricula.cuotas.extraordinarios].map(
                          e => e.id_pago_extraordinario === action.payload.datos.id_pago_extraordinario
                              ? action.payload.datos
                              : e
                        )
                      },
                    }
            ),
          },
          matriculaActiva: {
            ...state.datosFinanzasDiscente.matriculaActiva,
            cuotas: {
              ...state.datosFinanzasDiscente.matriculaActiva.cuotas,
              extraordinarios: [...state.datosFinanzasDiscente.matriculaActiva.cuotas.extraordinarios].map(
                e => e.id_pago_extraordinario === action.payload.datos.id_pago_extraordinario
                        ? action.payload.datos
                        : e
              )
            },
          },
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: action.payload.datos,
          },
          detallePago: {
            ...state.datosFinanzasDiscente.detallePago,
            loading: false,
            error: null,
          },
        },
      };
    case pagosTypes.ELIMINAR_CUOTA_FRACCION_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          cuotasPensiones: {
            ...state.datosFinanzasDiscente.cuotasPensiones,
            todo: {
              ...state.datosFinanzasDiscente.cuotasPensiones.todo,
              cuotas: [...state.datosFinanzasDiscente.cuotasPensiones.todo.cuotas].filter(
                cuota => cuota.id_fraccion !== action.payload.idFraccion  
              )
            }
          }
        }
      }
    case pagosTypes.ELIMINAR_CUOTA_EXTRA_EXITO:
      return {
        ...state,
        datosFinanzasDiscente: {
          ...state.datosFinanzasDiscente,
          todo: {
            ...state.datosFinanzasDiscente.todo,
            matriculas: [...state.datosFinanzasDiscente.todo.matriculas].map(
              (matricula) =>
                matricula.id_matricula !== action.payload.idMatricula
                  ? matricula
                  : {
                      ...matricula,
                      cuotas: {
                        ...matricula.cuotas,
                        extraordinarios: [...matricula.cuotas.extraordinarios].filter(
                          e => e.id_pago_extraordinario !== action.payload.idPagoExtra)
                      },
                    }
            ),
          },
          matriculaActiva: {
            ...state.datosFinanzasDiscente.matriculaActiva,
            cuotas: {
              ...state.datosFinanzasDiscente.matriculaActiva.cuotas,
              extraordinarios: [...state.datosFinanzasDiscente.matriculaActiva.cuotas.extraordinarios].filter(
                e => e.id_pago_extraordinario !== action.payload.idPagoExtra)
            },
          }
        }
      }
    case pagosTypes.OBTENER_MOTIVOS_DESCUENTOS:
      return {
        ...state,
        motivosCargosDescuentos: {
          ...state.motivosCargosDescuentos,
          loading: true,
          error: null
        }
      }
    case pagosTypes.OBTENER_MOTIVOS_DESCUENTOS_EXITO:
        return {
          ...state,
          motivosCargosDescuentos: {
            ...state.motivosCargosDescuentos,
            datos: action.payload,
            loading: false,
            error: null
          }
        }
    case pagosTypes.OBTENER_MOTIVOS_DESCUENTOS_ERROR:
      return {
        ...state,
        motivosCargosDescuentos: {
          ...state.motivosCargosDescuentos,
          loading: false,
          error: action.payload
        }
      }
    case pagosTypes.OBTENER_CONCEPTOS_PAGOS:
      return {
        ...state,
        conceptosPagos: {
          ...state.conceptosPagos,
          loading: true,
          error: null
        }
      }
    case pagosTypes.OBTENER_CONCEPTOS_PAGOS_EXITO:
      return {
        ...state,
        conceptosPagos: {
          ...state.conceptosPagos,
          datos: action.payload,
          loading: false,
          error: null
        }
      }
    case pagosTypes.OBTENER_CONCEPTOS_PAGOS_ERROR:
      return {
        ...state,
        conceptosPagos: {
          ...state.conceptosPagos,
          loading: false,
          error: action.payload
        }
      }
    case REMOVER_INFORMACION_PAGOS:
      return {
        pagosEstudiantes: [],
        proyectosComprados: [],
        modulosComprados: [],
      };
    default:
      return state;
  }
}
