import { 
    REMOVER_USUARIO,
    LISTAR_USUARIOS_ACCESO,
    INSERTAR_USUARIO,
    ELIMINAR_USUARIO_SISTEMA,
    LISTAR_USAURIO_SISTEMA,
    EDITAR_USUARIO_SISTEMA,
    EDITAR_NOMBRE_USUARIO_SISTEMA,
    EDITAR_CLAVE_USUARIO_SISTEMA
} from "./types";

import { deleteJWt, getJWT, setJwt} from '../helpers/functions'
import authAxios from "../config/authAxios";
import authToken from "../config/authToken";

export const loginUsuario = dataUser => async dispatch => {
    
    dispatch({
        type: 'VALIDAR_INICIO_SESION',
    })

    try {
        let respuesta = await authAxios.post(`/loginUsuarioAreas `, dataUser)
        if (respuesta.data.error) {
            dispatch({
                payload: respuesta.data.error,
                type: 'VALIDAR_INICIO_SESION_ERROR'
            })
            setTimeout(() => {
                dispatch({
                    type: 'VALIDAR_INICIO_SESION_RESET'
                })
            }, 3000);
            return
        }

        setJwt(respuesta.data.encode)
        authToken(getJWT())
        dispatch({
            payload: respuesta.data.decode.datos[0],
            type: 'VALIDAR_INICIO_SESION_EXITO'
        })
    } catch (error) {
        console.log(error)
    }
    
}

export const validarInicioSesion = () => {
    return async dispatch => {

        dispatch({
            type: 'VALIDAR_INICIO_SESION',
        })    

        authToken(getJWT())
        const decode = await authAxios.get('/decode');
        if (decode.data.datos) {
            dispatch({
                payload: decode.data.datos[0],
                type: 'VALIDAR_INICIO_SESION_EXITO'
            });
          } else {
            dispatch({
                payload: 'Hubo un error',
                type: 'VALIDAR_INICIO_SESION_ERROR'
            })
          }
    }
}

export const removerUsuario = () => dispatch => {
    deleteJWt();
    dispatch({
      type: REMOVER_USUARIO
    });
}


//sistema

export const listarUsuariosSistema = () => async dispatch => {
    try {
        const usuarios = await authAxios.get(`/admin/us_mostrarUsuariosAreas`)

        dispatch({
            payload: usuarios.data,
            type: LISTAR_USUARIOS_ACCESO
        })
    } catch (error) {
        console.log(error)
    }
}

export const insertarNuevoUsuario = dataNuevoUsuario => async dispatch => {
    try {
        const respuesta = await authAxios.post(`/admin/us_insertar_UsuariosAreas`, dataNuevoUsuario)
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: INSERTAR_USUARIO
            })

            return{mensaje: true, textMensaje: 'Creado nuevo usuario correctamente!'}
        }
        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}

export const eliminarUsuario = idUsuario => async dispatch => {
    try {
        const respuesta = await authAxios.delete(`/admin/us_eliminar_UsuariosAreas/${idUsuario}`)
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: ELIMINAR_USUARIO_SISTEMA
            })
            return {mensaje: true, textMensaje: 'Eliminado correctmente'}
        }
        return {mensaje: false, textMensaje: 'No se pudo eliminar usuario'}
        
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: 'Ocurrio un error en el sistema'}
    }
}

export const listarUsuario = idUsuario => async dispatch => {
    try {

        const usuarioSistema = await authAxios.get(`/admin/us_mostrarUsuariosAreas__Id_Usuario/${idUsuario}`) 
        
            dispatch({
                payload: usuarioSistema.data,
                type: LISTAR_USAURIO_SISTEMA
            })

    } catch (error) {
        console.log(error)
    }
}

export const cargarCumples = idUsuario => async dispatch => {
    try {

        const cumples = await authAxios.get(`/admin/cargarCumples`) 
        
        if(!cumples.error)
        {
            return cumples.data.data
        }
        
        return false

    } catch (error) {
        console.log(error)
    }
}


export const editarUsuario = (idUsuario, infoUsuario) => async dispatch => {
    try {
        const respuesta = await authAxios.put(`/admin/us_editar_UsuariosAreas/${idUsuario}`, infoUsuario)

       // console.log('me ejecute')
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: EDITAR_USUARIO_SISTEMA
            })
            return{mensaje: true, textMensaje: 'Usuario actualizado correctamente'}
        }
        return{mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}

export const editarNombreUsuario = (idUsuario, dataEditarUsuario) => async dispatch => {
    try {
        const respuesta = await authAxios.put(`/admin/us_editar_NameUsuarioAreas/${idUsuario}`, dataEditarUsuario)
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: EDITAR_NOMBRE_USUARIO_SISTEMA
            })

            return{mensaje: true, textMensaje: 'Se cambio el usuario correctamente'}
        }
        return{mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}

export const editarClaveUsuario = (idUsuario, dataEditarClave) => async dispatch => {
    try {
        const respuesta = await authAxios.put(`/admin/us_editar_PasswordUsuarioAreas/${idUsuario}`, dataEditarClave)
        
        if (!respuesta.data.error) {
            dispatch({
                payload: respuesta.data,
                type: EDITAR_CLAVE_USUARIO_SISTEMA
            })

            return {mensaje: true, textMensaje: 'Se cambio la contraseña correctamente'}
        }

        return {mensaje: false, textMensaje: respuesta.data.error}
    } catch (error) {
        console.log(error)
        return {mensaje: false, textMensaje: error}
    }
}