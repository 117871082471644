import React from 'react';
import { useParams } from 'react-router-dom'; 

//Redux
import { useDispatch } from 'react-redux';
import { cambiarEstadoDocente } from '../../../../../actions/controlCapacitacionesActions';

const TablaItemPonente = ({ docente = {} }) => {

  const { idCapacitacion } = useParams()
  const dispatch = useDispatch();

  const handleCambiarEstado = (idEstado) => {
    dispatch( cambiarEstadoDocente(docente.id_filtro_docente, idEstado, idCapacitacion) )
  }

  return ( 
    <tr>
      <td>{docente.nombre_usuario} {docente.apellido_usuario}</td>
      <td>
        <select
          value={docente.id_estado_capacitacion}
          onChange={e => handleCambiarEstado(e.target.value)}
          className='form-control'
        >
          <option value="1">Ninguno</option>
          <option value="2">Invitado</option>
          <option value="3">Confirmado</option>
          <option value="4">Contratado</option>
        </select>
      </td>
    </tr>
   );
}
 
export default TablaItemPonente;