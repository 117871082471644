import React from 'react';
import {Button} from "react-bootstrap";
const Opcion = ({id_opcion,opcion,id_pregunta}) => {


  return ( 
    <>
    <div style={{lineHeight:'25px'}}> <input id={'opcion'+id_opcion} className="form-check-input" type="radio" value={id_opcion} name={'pregunta'+id_pregunta} required /><label htmlFor={'opcion'+id_opcion}>{opcion} </label></div>
    </>
  );
}
 
export default Opcion;