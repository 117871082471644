import React from 'react';
//REDUX
import {connect} from 'react-redux'; 
import { listarSesionesCursos} from '../../../../../../actions/cursosActions'


const ListaCursos = ({ curso, listarSesionesCursos}) => {
    return ( 
        
        <li className="list-group-item active" onClick={e => {
            e.preventDefault();
            listarSesionesCursos(curso.id_curso, 'import')
        }}>{curso.nombre_curso}</li>
     );
}

const mapStateToprops = state => ({
    cursos: state.cursos.cursos
})

export default connect(mapStateToprops, { listarSesionesCursos})(ListaCursos);