import React from "react";
import LightboxReact from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Lightbox = props => {
  const { toggler, slide = 1, sources = [], handleClose, handleNext, handlePrev } = props
  return (
    <>
      {toggler && (
          <LightboxReact
            mainSrc={sources[slide]}
            nextSrc={sources[(slide + 1) % sources.length]}
            prevSrc={sources[(slide + sources.length - 1) % sources.length]}
            onCloseRequest={() => handleClose()}
            onMovePrevRequest={() => handlePrev()}
            onMoveNextRequest={() => handleNext()} 
          />
        )}
    </>
  );
}

export default Lightbox;
