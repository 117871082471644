import React, { useEffect } from "react"
import {ThroughProvider} from 'react-through'
import authToken from "./config/authToken";
import { getJWT } from "./helpers/functions";
import AppRouter from "./routes/AppRouter";



function App() {
  
  useEffect(() => {
    authToken(getJWT());
  }, [])

  return (
    <ThroughProvider>
      <AppRouter />
    </ThroughProvider>
  );
}
export default App;