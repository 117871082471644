import React from 'react';

//Redux
import { useSelector } from 'react-redux';

const withListarTemasArea = WrappedComponent => props => {
  
  const temas = useSelector(state => state.profesores.area.temas)

  return (
    <WrappedComponent
      {...props}
      temasArea={temas}
      labelDefinido='tema_area'
      valueDefinido='id_tema_area_dc'
    />
   );
}
 
export default withListarTemasArea;