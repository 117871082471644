import React from 'react';

//Redux
import { useSelector } from 'react-redux';
import TablaDatosCuotaFraccion from '../../view/components/Finanzas/RegistroFactura/TablaDatosCuotaFraccion';
import TablaDatosPension from '../../view/components/Finanzas/RegistroFactura/TablaDatosPension';

const TablaPensionFraccion = () => {
  
  const cuotasPensiones = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones.todo);
  const { modalidad, idPensionFraccion } = useSelector(state => state.pagos.datosFinanzasDiscente.detallePago);

  //En caso de que el pago sea de una fracción
  const obtenerFraccion = () => {
    const cuotas = cuotasPensiones.cuotas;
    const cuotaPagar = cuotas.find(cuota => cuota.id_fraccion === idPensionFraccion);
    return cuotaPagar;
  }

  const mostrarTabla = () => {
    if(modalidad === 'fraccion'){
      const resultado = obtenerFraccion();
      return <TablaDatosCuotaFraccion
        {...resultado}
      />
    }
    return <TablaDatosPension
      {...cuotasPensiones}
    />
  }

  return ( 
    <> 
      {mostrarTabla()}
    </>
  );
}
 
export default TablaPensionFraccion;