import React from 'react'
import ReactHtmlParser  from "react-html-parser";
//Redux
import {connect, useDispatch} from 'react-redux'
import shortid from 'shortid';
import { ACTUALIZAR_TAB_MENSAJERIA } from '../../../../actions/types';


const VistaMensaje = (props) => {

    //State
    const {mensaje} = props
    const dispatch=useDispatch();
    return (
      <div className="container-fluid px-0 shadow-sm">
        <div
          className="container-mensaje bg-white border"
          style={{ minHeight: "500px" }}
        >
          {Object.keys(mensaje).length > 0 ? (
            <React.Fragment>
              <div className="header-mensaje border-bottom py-2 px-3">
                <h3 className="" style={{ color: "#27314A" }}>
                  {mensaje.datosMensaje.asunto}
                </h3>
                <div className="row">
                  <div className="col-md-3">
                    <img
                      className="img img-fluid p-1 border"
                      src={
                        mensaje.datosMensaje.img_emisor
                          ? `${process.env.REACT_APP_API_URL}/${mensaje.datosMensaje.img_emisor}`
                          : "/images/user.png"
                      }
                      alt=""
                      style={{ height: "100px", width: "100px" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div class="row">
                      <div className="col-md-12">
                        <p className="mb-0" style={{ color: "#999" }}>
                          {`${mensaje.datosMensaje.nombre_emisor} ${mensaje.datosMensaje.apellido_emisor}`}
                        </p>
                        <p className="mb-0 text-capitalize">
                          {mensaje.datosMensaje.nombre_nivel_usuario_emisor}
                        </p>
                      </div>
                      <div className="col-md-12">
                        <p className="float-right">
                          {mensaje.datosMensaje.hora_msj}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="row">
                      <div className="col-2">
                        <label htmlFor="" className="font-weight-bold">
                          Para:{" "}
                        </label>
                      </div>
                      <div className="col-6">
                        <p htmlFor="">
                        {
                            `${mensaje.datosMensaje.nombre_receptor} ${mensaje.datosMensaje.apellido_receptor}`
                            
                        ||
                            `${mensaje.datosMensaje.nombre_emisor} ${mensaje.datosMensaje.apellido_emisor}`
                        }
                          
                        </p>
                      </div>
                      <div className="col-2">
                      <button className="btn btn-primary" onClick={()=>{
                        dispatch({
                          type:ACTUALIZAR_TAB_MENSAJERIA,
                          payload:'responder'
                        })
                      }} >Responder</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contenedor-mensaje p-2">
                <div className="mensaje mb-1" style={{ minHeight: "220px" }}>
                  {ReactHtmlParser(mensaje.datosMensaje.mensaje)}
                </div>
                <div className="content-archivo border-top">
                  <h6>Archivo adjunto: </h6>
                  {mensaje.datosArchivoMensaje.length > 0
                    ? mensaje.datosArchivoMensaje.map((item) => (
                        <React.Fragment key={shortid.generate()}>
                          <div className="bg-white border ror">
                            <div className="col-md-7 align-items-center d-flex">
                              <a
                                href={`${process.env.REACT_APP_API_URL}/${mensaje.datosArchivoMensaje[0].ruta_archivo}`}
                                target="_black"
                                className="mb-0"
                              >
                                <i className="fas fa-paperclip"></i>
                                {item.nombre_archivos_mensaje}
                              </a>
                            </div>
                            <div className="col-md-5 align-items-center d-flex"></div>
                          </div>
                        </React.Fragment>
                      ))
                    : null}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="h-100 w-100">
              <p className="m-auto bg-secondary py-2 px-3 text-white text-center w-100">
                No hay nada para ver <br /> Seleccione un mensaje del listado
                anterior
              </p>
            </div>
          )}
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
    mensaje: state.mensajes.mensaje
})

export default connect(mapStateToProps, {})(VistaMensaje);