import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import SimpleSelectwithConfig from '../../../../../containers/SimpleSelectwithConfig';
import PropTypes from 'prop-types';
import { avisoError } from '../../../AvisosMensajes/MensajesSwalf';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AreasDictado = ({ areas=[], defaultValue, handleSetAreaActiva, handleGuardarArea }) => {

  const [areaSeleccionada, setAreaSeleccionada] = useState(null)

  
  const handleSubmit = e => {
    e.preventDefault();
    if (!areaSeleccionada) {
      avisoError('No hay area seleccionada');
      return;
    }
    handleGuardarArea(areaSeleccionada.value);
  }

  //Guardar el area Activo cuando se haga un onchange en el select
  useEffect(() => {
    if (areaSeleccionada) {
      handleSetAreaActiva(areaSeleccionada.value)
    }
  }, [areaSeleccionada])
  
  return (
    <div className="AreasDictado">
      <div className='AreasDictado__container'>
        <Form
          className='AreasDictado__form'
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>Área:</Form.Label>
            <SimpleSelectwithConfig 
              options={areas}
              defaultValue={defaultValue}
              setValuesOption='id'
              setLabelOptions='nombre'
              getSelection={setAreaSeleccionada}
            />
          </Form.Group>
          <Form.Group className='text-right'>
            <Button 
              type='submit'
              variant='info'
              className='text-white btn-icon'
            >
              <FontAwesomeIcon icon={faSave} />
              Guardar
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

AreasDictado.propTypes = {
  handleSetAreaActiva: PropTypes.func.isRequired,
  handleGuardarArea: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  defaultValue: PropTypes.object
}

export default AreasDictado;
