import React, { useState } from "react";

//Redux
import { connect } from "react-redux";
import { subirRecurso } from "../../../actions/recursosActions";

import {
  avisoCorrecto,
  avisoError,
} from "../../components/AvisosMensajes/MensajesSwalf";
import { buscarId } from "../../../helpers/functions";
import DropZone from "../../components/plugins/Dropzone";
import { Modal } from "react-bootstrap";

const ModalNuevo = (props) => {
  //State props
  const { idSesion, usuario } = props;
  //Funciones props
  const { subirRecurso } = props;

  const [nombreArchivo, setNombreArchivo] = useState("");
  const [tipoArchivo, setTipoArchivo] = useState("2");
  const [contenidoArchivo, setContenidoArchivo] = useState();

  const submitForm = async (e) => {
    e.preventDefault();

    const dato = new FormData();
    const date = new Date();

    let dia = date.getDay();
    let mes = date.getMonth();
    let year = date.getFullYear();

    if (idSesion === "" || tipoArchivo === "" || nombreArchivo === "") {
      avisoError("Todos los campos son obligatorios");
      return;
    }

    if (tipoArchivo === "3") {
      dato.append(
        "archivo",
        `<iframe
          loading="lazy"
          src=${contenidoArchivo}
          allowfullscreen="allowfullscreen"
          data-rocket-lazyload="fitvidscompatible"
          data-lazy-src="//player.vimeo.com/video/33787818?color=c9ff23"
          className="lazyloaded"
          data-was-processed="true"
          width="700"
          height="337"
          frameborder="0"
        ></iframe>`
      );
    } else {
      dato.append("archivo", contenidoArchivo);
    }

    dato.append("id_sesion", idSesion);
    dato.append("id_tipo_archivo", tipoArchivo);
    dato.append("fecha", `${year}-${mes}-${dia}`);
    dato.append("id_usuario", usuario.id_usuario);
    dato.append("aprobado", usuario.id_tp_nivel_usuario!=="2" ? 1:0);

    dato.append("nombre_archivo", nombreArchivo);
    // dato.append('link_archivo', 'acacsa')
    let config = {
      header: {
        "Content-Type": "application/json",
        "Content-Type": "image/jpeg",
        "Content-Type": "image/jpg",
        "Content-Type": "multipart/form-data",
      },
    };

    const { mensaje, textMensaje } = await subirRecurso(dato, config);

    if (mensaje) {
      avisoCorrecto(textMensaje);
      const formSubir = buscarId("form-subir-recurso");
      formSubir.reset();
      setContenidoArchivo()
      return;
    }
    avisoError(textMensaje);
  };

  return (
    <div className="recursos__modal-nuevo">
      <div
        className="modal fade"
        id="modal-nuevo"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Nuevo recurso
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={(e) => submitForm(e)} id="form-subir-recurso">
              <Modal.Body className="modal-body">
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    onChange={(e) => setNombreArchivo(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Tipo:
                  </label>
                  <select
                    name="categoria"
                    id="categoria"
                    className="form-control"
                    value={tipoArchivo}
                    onChange={(e) => {
                      setTipoArchivo(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      --Seleccione--
                    </option>
                    <option value="2">Archivo</option>
                    <option value="5">Archivo en la nube</option>
                 { usuario.id_tp_nivel_usuario!=="2" ? <>  <option value="3">Video</option>
                    <option value="4">Sesión en vivo</option></> :  null}
                  </select>
                </div>
                <div className="form-group">
                  {tipoArchivo === "2" || tipoArchivo === "1" ? (
                    <>
                      <DropZone getContenidoArchivo={setContenidoArchivo} />
                      <div className="">
                        {contenidoArchivo !== undefined ? (
                          <p>{contenidoArchivo.name}</p>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <InputLink setContenidoArchivo={setContenidoArchivo} />
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-dark"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-info">
                  <i className="fas fa-upload mr-1"></i>
                  Subir
                </button>
              </Modal.Footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuario,
});

const mapDispatchToProps = {
  subirRecurso,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevo);

const InputLink = (props) => {
  const { setContenidoArchivo } = props;

  return (
    <>
      <label htmlFor="link">Link (URL)</label>

      <input
        type="text"
        name="link"
        id="link"
        className="form-control"
        onChange={(e) => {
          setContenidoArchivo(e.target.value);
        }}
      />
    </>
  );
};
