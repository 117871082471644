import React, {useState} from 'react';
//


//Redux
import { useDispatch } from 'react-redux';
import { subirFondoPopUp } from '../../../actions/popupsActions';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const NuevoFondo = () => {

const [archivo, setArchivo] = useState(null);
const dispatch = useDispatch();

const subirArchivo = e=>{
  setArchivo(e)
}

const handleSubmit = (e) =>
{
    e.preventDefault();
    dispatch(subirFondoPopUp(archivo))

}

 

 return (
 
  <form id="formNuevo2" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>
                  
        <div className="form-row align-items-center mb-3">
        <div className="col-md-3">
            <label htmlFor="enlace" className="mb-0">
            Seleccionar Imagen:
            </label>
        </div>
        <div className="col-md-7">
            <input
            type="file"
            className="form-control"
            onChange={(e)=> subirArchivo(e.target.files[0])}
            />
        </div>
        <div className="col-md-2"></div>
        </div>
        
        <div className="form-group">
        
        </div>
        <button type="submit" className="btn" style={style.boton}>
        <i className="fa fa-upload"></i> Subir Imagen
        </button>
    </form>
  
    );
};

export default NuevoFondo;
const initialValues = {
    TITULO:'',
    MENSAJE:'',
    ENLACE:''
  }