import React, { useCallback} from 'react';
import { useDropzone } from "react-dropzone";

import styled from 'styled-components';


const getColor = (props) => {
    if (props.isDragAccept) {
        return '#3498DB';
    }
    if (props.isDragReject) {
        return '#F39C12';
    }
    if (props.isDragActive) {
        return '#F39C12';
    }
    return '#4a5568';
  }

  const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #edf2f7;
  color: #3f4047;
  font-weight: 500;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const DropZone = (props) => {

    const {getContenidoArchivo} = props
  
      const onDrop = useCallback((acceptedFiles) => {
       // console.log(acceptedFiles)
        getContenidoArchivo(acceptedFiles[0])
      }, []);
  
      const {
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        } = useDropzone({onDrop});
  
        
  
      return (
          <>
          <div className="content-dropzone">
              <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                  <p className='cursor-pointer'>Suelta el archivo aquí.</p>
                  ) : (
                  <p className='cursor-pointer'>
                      Arrastre y suelte el archivos aquí, o haga clic para seleccionar...
                  </p>
                  )}
              </Container>
          </div>
          </>
      )
  }

  export default DropZone;