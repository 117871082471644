import React from 'react';
import Layout from '../../../../app/Layout';
import ModalBasicwithRedux from '../../../../containers/ModalBasicwithRedux';
import FormularioModulo from '../../../components/ControlModulos/FormularioModulo';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import './ModuloProyecto.scss';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const ModuloProyecto = () => {

  const dispatch = useDispatch();
  const handleModal = () => {
    dispatch(updateStateModalBasic(<FormularioModulo />, 'Nuevo módulo'));
  }

  return ( 
    <Layout>
      <div className="ModuloProyecto">
        <ModalBasicwithRedux />
        <div className="ModuloProyecto__header">
          <HeaderContent title='Nuevo módulo' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/pagos", nombre: "Módulo nuevo" },
            ]}
          />
        </div>
        <div className="ModuloProyecto__body">
          <div className="formulario-container box box-primary">
            <div className="box-header with-border">
              <h4 className='title-head mb-0'>Formulario</h4>
            </div>
            <div className="box-body">
              <FormularioModulo />
            </div>
          </div>
        </div>
      </div>
    </Layout>
   );
}
 
export default ModuloProyecto;