import { Card, Table } from 'react-bootstrap';
import React from 'react';
import PieChart from './PieChart'
import "./indicadores.css"
const Indicador = ({datos}) => {

  //let porcentaje = datos.porcentaje;
    return (
        <div className="col-12 col-md-6 mb-3 text-center">
        <Card>
            <Card.Header> 
            <div className="row text-center p-1" >
                <div className="col-12 col-md-3">
                 <img src={datos.img_usuario ?  process.env.REACT_APP_API_URL+datos.img_usuario : datos.gender==='F' ? 'https://sistema.inedi.edu.pe/images/user_2.png' : 'https://sistema.inedi.edu.pe/images/user_3.png' } style={{borderRadius:'100%', maxWidth:'80px'}} alt='' />
                </div>
                <div className="col-12 col-md-9" style={{marginTop:'20px', textAlign:'left'}}>
                   <h3>{datos.nombre_usuario}</h3> 
                </div>
            </div>   
            </Card.Header>
            <Card.Body>
            <div className="row text-center">
            <div className="col-12" style={{display:'block', marginBottom:'15px'}}>
                <PieChart info={datos.grafico} />
            </div>
            </div>
           <div className="row text-center p-2" style={{marginTop:'15px'}}>
            <div className="col-12">
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>Base Llamadas</th>
                <th>Contestados</th>
                <th>No Contestados</th>
                <th>Posibles Clientes</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>{datos.base_leads}</td>
                <td>{datos.contestados}</td>
                <td>{datos.no_contestados}</td>
                <td>{datos.posibles_clientes}</td>
                </tr>
            </tbody>
            </Table>
            </div>
           <div className="col-12 mt-2">
           <Table striped bordered hover>
            <thead>
                <tr>
                <th>Producto</th>
                <th>Leads</th>
                <th>Llamadas</th>
                <th>Por llamar</th>
                </tr>
            </thead>
            <tbody>
            {
                datos.productos ? datos.productos.map((producto,i)=>(
                        <tr key={i}>
                        <td>{producto.nombre_proyecto}</td>
                        <td>{producto.leads}</td>
                        <td>{producto.llamados}</td>
                        <td>{producto.por_llamar}</td>
                        </tr>
                )) : ''
            }
               
            </tbody>
            </Table>
           </div>
            </div>
            </Card.Body>
        </Card>
        </div>
    );
};

export default Indicador;