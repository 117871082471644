import React, {useState, useEffect} from 'react';
import Editor from './EditorPopUp'


//Redux
import { useDispatch } from 'react-redux';
import { insertarComunicado } from '../../../actions/popupsActions';

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioPopUp = ({setComunicados}) => {

const [datosPop, setPopUpDatos] = useState(initialValues);
   const dispatch = useDispatch();
 const handleChange= (event) => {
        const value = event.target.value;
        setPopUpDatos({
          ...datosPop,
          [event.target.name]: value
        });
    
 }

 const setMensaje = (a) => {
    const value = a;
    //console.log(value);
    setPopUpDatos({
        ...datosPop,
        MENSAJE: value
    });
 }

  const handleSubmit = (e) =>{
    e.preventDefault();
    const retornarListaActualizada = async () =>{
     await (dispatch(insertarComunicado(datosPop)))
    }
  
    
  // console.log(datosPop)
  retornarListaActualizada()
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Titulo:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="TITULO"
                        onChange={handleChange}
                        value={datosPop.TITULO}
                        type="text"
                        className="form-control"
                        id="titulopop"
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Boton "Saber más":
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input
                        name="ENLACE"
                        onChange={handleChange}
                        value={datosPop.ENLACE}
                        type="text"
                        className="form-control"
                        id="enlace"
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-group">
                    <Editor
                      setContenido={setMensaje}
                      dataDefault={datosPop.MENSAJE}
                    />
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioPopUp;
const initialValues = {
    TITULO:'',
    MENSAJE:'',
    ENLACE:''
  }