import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';
import EditarClasificacionTrabajador from '../../containers/TrabajadoresContainers/EditarClasificacionTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../actions/modalActions';

const withClasificacionTrabajador = WrappedComponent => props => {

  const [trabajador] = useGetTrabajador();
  const dispatch = useDispatch(); 

  const handleEditar = () => {
    dispatch( updateStateModalCustom(<EditarClasificacionTrabajador/>, 'Editar clasificación del trabajador', 'modal-w30' ) )
  }

  return ( 
    <WrappedComponent
      {...props}
      nombre_estado={trabajador.clasificacion.nombre_clasificacion}
      handleEditar={handleEditar}
    />
   );
}
 
export default withClasificacionTrabajador;