import React, {useState, useEffect} from 'react';
import useGetProyectos from '../../../hooks/useGetProyectos';
import { useDispatch, useSelector, connect } from 'react-redux';
import { getEnlaceWhatsApp, updateGrupo } from '../../../actions/cursosActions';
import { } from '../../../actions/cursosActions';
import SimpleSelect from 'react-select';
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import NuevoExamen from "../Examenes/nuevoExamen";
import { Container} from "react-bootstrap";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import MensajeLoading from "../../components/MensajeLoading";
const Grupos = (props) => {

    const {
        loading,
        examen
      } = props;
      const { usuario } = useSelector(state => state.usuario);
    const { proyectos } = useGetProyectos();
    const [datoProyecto, setDatoProyecto] = useState({
        proyectoSeleccion: null
      });

    const [grupo,setGrupo]=useState("");

      const handleChange= (event) => {
        const value = event.target.value;
        setGrupo(value);
      }
    
      const actualizarGrupo = ()=>
      {
        const datos = {proyecto:datoProyecto.proyectoSeleccion.id_proyecto,enlace:grupo}
     
       dispatch(updateGrupo(datos))
      }
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () =>{
        if(datoProyecto.proyectoSeleccion) {
           setGrupo("Cargando...")
           const respuesta = await dispatch(getEnlaceWhatsApp(datoProyecto.proyectoSeleccion.id_proyecto));
          
           setGrupo(respuesta.data.enlace_grupo ? respuesta.data.enlace_grupo  : '')
          }
        }
        fetchData();
    }, [datoProyecto.proyectoSeleccion])


    return (
        <Layout>
        <HeaderContent title="WhatsApp" subItem={usuario.id_tp_nivel_usuario==='5' ? 'Con el cliente a todos lados.' : null} />
        <Breadcrumb
          link={[
            { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
            { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "Grupos WhatsApp" },
          ]}
        />
        <div className="box box-primary">
          <div className="box-body">
            <div className="row encuetas__content-filtro">
            <div className="col-md-4 filtro">
                <label htmlFor="">Producto</label>
                <SimpleSelect
              options={proyectos}
              isLoading={loading}
              getOptionLabel={item =>  item.nombre_proyecto}
              getOptionValue={item => item.id_proyecto}
              value={datoProyecto.proyectoSeleccion}
              onChange={e => setDatoProyecto(
                datos => ({...datos, proyectoSeleccion: e})
              )}
            />
            </div>
              <div className="col-md-4 filtro">
                <label htmlFor="">Enlace de WhatsApp</label>
                <input
                  className="form-control"
                  name="grupo"
                  value={grupo}
                  onChange={handleChange}
                />
              </div> 
              <div className="col-md-4 filtro p-4">
                <button className="btn btn-primary" onClick={()=>{actualizarGrupo()}}>Actualizar</button>
              </div>   
            </div>
          </div>
        </div>
      </Layout>
    );

};
const mapStateToProps = (state) => ({
  loading: state.examenes.loading,
  examen: state.examenes.examen
});
export default connect(mapStateToProps)(Grupos) ;