import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl,faEdit } from '@fortawesome/free-solid-svg-icons'

const ListaClasificacionHead = ({ handleModalEditar }) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <FontAwesomeIcon icon={faListUl} />
        <h5 className='ListaClasificacion__title ml-1'>Clasificación</h5>
      </div>
      <div className='d-flex align-items-center cursor-pointer'>
        <FontAwesomeIcon icon={faEdit} className='my-1' />
        <span onClick={() => handleModalEditar()}>Editar</span>
      </div>
    </>
   );
}
 
export default ListaClasificacionHead;