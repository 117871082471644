import React from 'react';
import { Link } from 'react-router-dom';
import MaterialTableDemo from '../../../plugins/MaterialTableDemo';
import { Button } from 'react-bootstrap';
import { faEye, faTrash, faMailBulk, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eliminarMatricula } from '../../../../../actions/matriculasActions';
import { useDispatch, useSelector } from 'react-redux';
import { enviarCorreo} from '../../../../../actions/estudiantesActions';
import { avisoCorrecto, avisoError } from '../../../AvisosMensajes/MensajesSwalf';
const TablaMatricula = ({ matriculas, idEvento }) => {

  const dispatch = useDispatch();
  
  console.log(matriculas)
  const handleEliminarMatricula = (idMatricula) => {
    dispatch(eliminarMatricula(idMatricula));
  }

  const enviarCredenciales =  (datosAlumno) => {
    dispatch(enviarCorreo(datosAlumno))
   }
 

  const botonesAcciones = (record) => {
    return (
      <div className="d-flex justify-content-between">
        {  /*   <Button
          href={`${process.env.REACT_APP_API_URL}/admin/pdf/reporte/matriculas?idEvento=${idEvento}&usuario=${record.id_usuario}`}
          variant='success'
          size='xs'
        >
          <FontAwesomeIcon icon={faListAlt} />
        </Button>*/
          }
        <Link to={`/matriculas/${record.id_matricula}`} className='btn btn-xs btn-warning'>
          <FontAwesomeIcon icon={faEye} />
        </Link>
        <Button
          variant='danger'
          size='xs'
          onClick={() => handleEliminarMatricula(record.id_matricula)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <button className="btn btn-info mr-1 btn-xs" onClick={() => enviarCredenciales(record)} type="button" title="Enviar Correo">
          <FontAwesomeIcon icon={faMailBulk} />
        </button>
      </div>
    )
  }

  return ( 
    <div className="TablaMatricula">
      <MaterialTableDemo
        colums={columns(botonesAcciones)}
        tabla={matriculas}
        addConfig={{ key_column: 'id_matricula' }}
      />
    </div>
  );
}
 
export default TablaMatricula;

const columns = (botonesAcciones) => [
  {
    key: 'index',
    text: '#',
    cell: (_, index) => index+1,
    width: 50,
    className: 'text-center'
  },
  {
    key: 'nombre_discente',
    text: 'Nombres y apellidos',
    sortable: true
  },
  {
    key: 'fecha_nac',
    text: 'Cumpleaños',
    sortable: true
  },
  {
    key: 'dni',
    text: 'DNI',
    className: 'text-center'
  },
  {
    key: 'celular',
    text: 'Celular',
    className: 'text-center'
  },
  {
    key: 'email',
    text: 'Correo'
  },
  {
    key: 'nombre_proyecto',
    text: 'Evento',
    width: 500,
    sortable: true
  },
  {
    key: 'fecha_matricula',
    text: 'Fecha de Matrícula',
    className: 'text-center',
    sortable: true
  },
  {
    key: 'estado',
    text: 'Estado',
    className: 'text-center',
    cell: (record) => <span className={`badge ${record.id_tp_estado === '1' ? 'badge-success' : 'badge-danger'}`}>
      {record.estado}
    </span>
  },
  {
    key: 'acciones',
    text: 'Acciones',
    className: 'text-center',
    cell: record => botonesAcciones(record)
  }
]