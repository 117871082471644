import React from "react";
import HeaderContent from "../General/HeaderContent";
import FormularioEstudiante from "./creacionEstudiante/FormularioEstudiante";
import Breadcrumbs from "../General/Breadcrumb";
import Layout from "../../../app/Layout";

const NuevoEstudiante = () => {

  return (
    <Layout>
      <HeaderContent
        title={"Nuevo discente"}
        icon={"people_alt"}
      />
      <Breadcrumbs
        link={[
          {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
          {url:'/estudiantes', nombre: 'Discentes'},
          {url:'', nombre: "Nuevo discente"}
        ]}
        />

      <div className="container-fluid">
        <FormularioEstudiante />
      </div>
    </Layout>
  );
};


export default NuevoEstudiante;
