import {
  LISTAR_TRABAJADORES_SISTEMA, 
  LISTAR_TRABAJADORES_SISTEMA_EXITO,
  LISTAR_TRABAJADORES_SISTEMA_ERROR,
  ELIMINAR_TRABAJADOR_SISTEMA,
  INSERTAR_NUEVO_TRABAJADOR,
  OBTENER_TRABAJADOR,
  OBTENER_TRABAJADOR_EXITO,
  OBTENER_TRABAJADOR_ERROR,
  EDITAR_CLASIFICACION_TRABAJADOR,
  EDITAR_CV_TRABAJADOR,
  EDITAR_HONORARIOS_TRABAJADOR,
  ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR,
  ELIMINAR_ARCHIVO_TRABAJADOR,
  EDITAR_NOMBRE_USUARIO_TRABAJADOR,
  EDITAR_PASSWORD_TRABAJADOR,
  EDITAR_REGISTRO_TRABAJADOR,
  ACTUALIZAR_AVATAR_TRABAJADOR,
  AREA_TRABAJADOR_ACTIVO,
  AREAS_TRABAJADOR_OBTENIDO,
  AGREGAR_TEMA_TRABAJADOR,
  ELIMINAR_TEMA_TRABAJADOR,
  GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR,
  trabajadoresTypes
} from './types';
import authAxios from '../config/authAxios';
import Swal from 'sweetalert2';
import { avisoCargando, avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf';
import response from '../config/response';
import { updateStateModalBasic } from './modalActions';

const mostrarError = error => {
  const msg = error.response !== undefined ? error.response.data.msg : error;
  avisoError(msg)
}

export const listarTrabajadores = () => {
  return async dispatch => {
    dispatch({
      type: LISTAR_TRABAJADORES_SISTEMA
    })
    try {
      const trabajadores = await authAxios.get(`/admin/us_mostrarUsuariosAreas`)

      dispatch({
          payload: trabajadores.data,
          type: LISTAR_TRABAJADORES_SISTEMA_EXITO
      })
    } catch (error) {
        console.log(error)
        dispatch({
          type: LISTAR_TRABAJADORES_SISTEMA_ERROR
        })
    }
  }
}

export const nuevoTrabajador = datos => {
  return async dispatch => {
    avisoCargando('Guardando...')
    try {
      if (datos.clasificacion === '' || datos.area === '') {
        avisoError('Faltan llenar campos obligatorios');
        return;
      }

      datos.id_tp_nivel_usuario = datos.area;
      datos.id_tp_clasificacion_usuario = datos.clasificacion;
      delete datos.clasificacion;
      delete datos.area;

      const respuesta = await authAxios.post(`/admin/empleado/insertarEmpleadoEmpresa`, datos)
      
      dispatch({
          payload: respuesta.data,
          type: INSERTAR_NUEVO_TRABAJADOR
      })
      avisoCorrecto('Nuevo trabajador registrado correctamente');
    } catch (error) {
        console.log(error)
        mostrarError(error)
    }
  }
}

export const obtenerTrabajador = idTrabajador => {
  return async (dispatch, getState) => {
    try {
      if (!getState().trabajadores.trabajadores.length) {
        await dispatch( listarTrabajadores() )
      }
  
      //Verificar si esta guardado en el state
      const existeTrabajador = getState().trabajadores.trabajador[idTrabajador];
  
      if (!existeTrabajador) {
          dispatch({
            type: OBTENER_TRABAJADOR
          })
          //Llamar a la api
          const respuesta = await authAxios(`/admin/empleado/mostrarDatosEmpleado_IdEmpleado/${idTrabajador}`);
          const trabajadoresSeleccionados = getState().trabajadores.trabajador;

          dispatch({
              type: OBTENER_TRABAJADOR_EXITO,
              payload: {...trabajadoresSeleccionados, [idTrabajador]: respuesta.data}
          })
         
      }
  
    } catch (error) {
      console.log(error)
      dispatch({
        type: OBTENER_TRABAJADOR_ERROR
      })
      mostrarError(error)
    }

    
    //Obterner el area activa del docnte
    // const existeArea = getState().profesores.docente[idDocente].area.id_tp_area_dictado;
    // if (existeArea) {
    //     dispatch( setAreaActiva(existeArea)) 
    // }
  }
}

export const editarRegistroTrabajador = (datos, idTrabajador) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    try {
      const respuesta = await authAxios.put(`/admin/empleado/actualizarDatosEmpleado/${idTrabajador}`, datos);

      dispatch({
        type: EDITAR_REGISTRO_TRABAJADOR,
        payload: {
          nuevosDatos: respuesta.data,
          idTrabajador
        }
      })
      avisoCorrecto('Guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarError(error)
    }
  }
}

export const editarAvatarUsuario = (file, idTrabajador) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro?',
          text: "No se podrá recuperar la imagen anterior.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              const datos = new FormData();
              datos.append('img_usuario', file);
              const respuesta = await authAxios.post(`/admin/empleado/SubirImgUsuario/${idTrabajador}`, datos)
              dispatch({
                  payload: {
                    idTrabajador,
                    nuevoAvatar: respuesta.data.img_usuario
                  },
                  type: ACTUALIZAR_AVATAR_TRABAJADOR
              })
              return true;
          } catch (error) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Hecho!",
              "Avatar actualizado correctamente",
              "success"
          );
          }
      });
  }
}

export const editarClasificacionTrabajador = (idTrabajador, idClasificacion) => {
  return async dispatch => {
    try {
      avisoCargando('Guardando...')
      const datos = {
        id_tp_clasificacion_usuario: idClasificacion
      }

      const respuesta = await authAxios.put(`/admin/empleado/actualizarClasificacionEmpleado/${idTrabajador}`, datos)
    
      dispatch({
        type: EDITAR_CLASIFICACION_TRABAJADOR,
        payload: {
          idTrabajador,
          clasificacion: respuesta.data
        }
      });
      avisoCorrecto('Clasifiacion guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarError(error)
    }
  }
}

export const listarAreasTrabajadores = () => {
  return async dispatch => {
    try {
      const respuesta = await authAxios.get('/admin/empleado/area');
      dispatch({
        type: AREAS_TRABAJADOR_OBTENIDO,
        payload: respuesta.data 
      })
    } catch (error) {
      console.log(error)
      mostrarError(error)
    }
  }
}

export const editarConfiguracionAreaTrabajador = (datos, idTrabajador) => {
  return async dispatch => {
    dispatch({
      type: trabajadoresTypes.EDITAR_CONFIGURACION_AREA_TRABAJADOR
    })
    try {
      const respuesta = await authAxios.put(`/admin/empleado/clasificacion_area/${idTrabajador}`, datos)
      dispatch({
        type: trabajadoresTypes.EDITAR_CONFIGURACION_AREA_TRABAJADOR_EXITO,
        payload: {
          idTrabajador,
          data: respuesta.data
        }
      })
      dispatch(updateStateModalBasic());
      avisoCorrecto('Se actualizo correctamente.')
    } catch (error) {
      response.error(error, dispatch, trabajadoresTypes.EDITAR_CONFIGURACION_AREA_TRABAJADOR_ERROR);
    }
  }
}

export const insertarTemaTrabajador = (idTema , idTrabajador) => {
  return async dispatch => {
    avisoCargando('Agregando...');
    try {

      if (!idTema) {
        avisoError('No hay tema seleccionado');
        return;
      }

      const datos = {
        id_tema_area_em: idTema,
        id_empleado: idTrabajador
      }

      const respuesta = await authAxios.post('/admin/empleado/insertarTema_IdTemaAreaIdEmp', datos);

      dispatch({
        type: AGREGAR_TEMA_TRABAJADOR,
        payload: {
          nuevoTema: respuesta.data,
          idTrabajador
        }
      })
      avisoCorrecto('Tema agregado correctamente')
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg)
      mostrarError(error)
    }
  }
}

export const eliminarTemaTrabajador = (idTemaTrabajador, idTrabajador) => {
  return async dispatch => {
    dispatch({
      type: ELIMINAR_TEMA_TRABAJADOR,
      payload: {
        idTrabajador,
        idTemaTrabajador
      }
    });

    try {
      await authAxios.delete(`/admin/empleado/eliminarDtTemasAreaEmpleado__IdTemaArea/${idTemaTrabajador}`)
    } catch (error) {
      console.log(error)
      mostrarError(error)
    }
  }
}

export const guardarOrdenTemas = (temas, idTrabajador) => {
  return async dispatch => {
    
    avisoCargando('Guardando...')
      
    if (!temas) {
        avisoError('No temas a guardar.');
        return;
    }
    const datos = {
        id_empleado: idTrabajador,
        temas
    }

    try {
        const respuesta = await authAxios.put('/admin/empleado/ordenarDtTemasAreaEmpleado__ArrayTema', datos)
        
        dispatch({
            type: GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR,
            payload: {
              idTrabajador,
              nuevoOrden: respuesta.data
            }
        })
        avisoCorrecto('Orden guardado correctamente')
    } catch (error) {
        console.log(error)
        mostrarError(error)
    }
  }
}

export const setAreaActivaTrabajador = (idArea) => {
  return (dispatch, getState) => {
      const areas = getState().trabajadores.areas.todo;
      const areaActiva = areas.find(area => area.id === parseInt(idArea));
     
      dispatch({
          type: AREA_TRABAJADOR_ACTIVO,
          payload: areaActiva
      })
  }
}

export const editarResumenCvTrabajador = (idTrabajador, descripcion) => {
  return async dispatch => {
    avisoCargando('Guardando...');
   
    try {
      await authAxios.put(`/admin/docente/actualizarResumenCV_IdDocente/${idTrabajador}`, { descripcion });
    
      dispatch({
        type: EDITAR_CV_TRABAJADOR,
        payload: {
          idTrabajador,
          descripcion
        }
      });
      avisoCorrecto('Guardado correctamente');
    } catch (error) {
      console.log(error)
      mostrarError(error)
    }
  }
}

export const subirArchivosAdjuntosCv = (datos, idTrabajador, idTipo) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    try {
      
      const file = new FormData;
      file.append('file', datos);
      file.append('id_empleado', idTrabajador);
      file.append('id_tp_documento_usuario', idTipo);

      const respuesta = await authAxios.post('/admin/empleado/registrarDtDocumentoEmpleado', file);
      const nombreDocumento = getTipoArchivo(idTipo)
      dispatch({
          type: ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR,
          payload: {
              idTrabajador,
              nombreDocumento,
              nuevoArchivo: respuesta.data
          }
      });
      avisoCorrecto('Archivo subido correctamente');

    } catch (error) {
      console.log(error);
      mostrarError(error)
    }
  }
}

export const eliminarArchivoAdjunto = (idDocumento, idTipo, idTrabajador) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Estas seguro de eliminar este archivo?',
          text: "No se podra recuperar el archivo",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/empleado/eliminarDocumentoUser__IdDocEmp/${idDocumento}`);
              const nombreDocumento = getTipoArchivo(idTipo)

              dispatch({
                  type: ELIMINAR_ARCHIVO_TRABAJADOR,
                  payload: {
                      idTrabajador,
                      nombreDocumento
                  }
              })
              return true;
          } catch (error) {
              console.log(error)
              const msg = error.response !== undefined ? error.response.data.msg : error 
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se elimino el archivo correctamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Se elimino el archivo correctamente",
              "success"
          );
          }
      });
  }
}

const getTipoArchivo = idTipo => {
  switch (idTipo) {
      case 1:
          return 'Curriculum_vitae';
      case 3:
          return 'Titulo';
      case 4:
          return 'Experiencia';
      case 5:
          return 'Publicaciones';
      default:
          break;
  }
}

export const editarHonorariosTrabajador = (idTrabajador, honorarios) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    try {
      
      await authAxios.put(`/admin/empleado/registrarHonorariosEmpleado/${idTrabajador}`, honorarios);

      dispatch({
        type: EDITAR_HONORARIOS_TRABAJADOR,
        payload: {
          idTrabajador,
          honorarios
        }
      });
      avisoCorrecto('Guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarError(error)
    }
  }
}

export const editarNombreUsuario = (nombreUsuario, idTrabajador) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    try {

      const datos = {
        usuario: nombreUsuario
      }

      await authAxios.put(`/admin/empleado/actualizarUserEmpleado/${idTrabajador}`, datos)

      dispatch({
        type: EDITAR_NOMBRE_USUARIO_TRABAJADOR,
        payload: {
          idTrabajador,
          nombreUsuario
        }
      })
      avisoCorrecto('Guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarError(error);
    }
  }
}

export const editarPasswordTrabajador = (nuevoPassword, idTrabajador) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    try {
      const datos = {
        "n_password": nuevoPassword,
        "m_password": nuevoPassword
      };

      const respuesta = await authAxios.put(`/admin/empleado/actualizarPasswordEmpleado/${idTrabajador}`, datos);

      dispatch({
        type: EDITAR_PASSWORD_TRABAJADOR,
        payload: {
          idTrabajador,
          nuevoHash: respuesta.data.password
        }
      })

      avisoCorrecto('Guardado correctamente');
    } catch (error) {
      console.log(error);
      mostrarError(error);
    }
  }
}

export const eliminarTrabajadorSistema = idUsuario => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: 'Estas seguro?',
          text: "Se eliminaran toda información con relacion con el trabajdor",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/us_eliminar_UsuariosAreas/${idUsuario}`)
              dispatch(listarTrabajadores())
              return true;
          } catch (error) {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Trabajador se elimino correctamente",
              "success"
          );
          }
      });
  }
}

export const obtenerListaClasificacionTrabajadores = () => {
  return async dispatch => {
    dispatch({
      type: trabajadoresTypes.OBTENER_LISTA_CLASIFICACION
    })
    try {
      const respuesta = await authAxios.get('/admin/empleado/clasificacion');
      dispatch({
        type: trabajadoresTypes.OBTENER_LISTA_CLASIFICACION_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      response.error(error, dispatch, trabajadoresTypes.OBTENER_LISTA_CLASIFICACION_ERROR);
    }
  }
}
