import React from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../actions/modalActions';

const withModalCustomWidth = WrappedComponent => props => {
  
  const dispatch = useDispatch();
  const modalCustom = useSelector(state => state.modal.modalCustom)

  const handleUpdateShowModal = () => {
    dispatch( updateStateModalCustom() )
  }

  return ( 
    <WrappedComponent
      {...props}
      {...modalCustom}
      updateShowModalCustom={handleUpdateShowModal}
    />
   );
}
 
export default withModalCustomWidth;