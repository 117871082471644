import { reporteCalificacionTypes } from "../actions/types";

const initialState = {
  reporteCalificacion: {},
  loading: false,
  error: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION:
      return {
        ...state,
        loading: true,
        error: null
      }
    case reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION_EXITO:
      return {
        ...state,
        loading: false,
        reporteCalificacion: action.payload,
        error: null
      }
    case reporteCalificacionTypes.OBTENER_REPORTE_CALIFICACION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reporteCalificacion: {}
      }
      case reporteCalificacionTypes.LIMPIAR_RESULTADO_BUSQUEDA:
      return initialState; 
    default:
      return state;
  }
}