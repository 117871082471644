import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { listarBases } from "../../../actions/basesCertActions";
import FormularioFiltro from "./FormularioFiltro";
import FormularioBaseCertificado from "./FormularioBaseCertificado";
 
const BasesCertificados = () => {
  //State
  const {bases, loading} = useSelector(state=>state.bases)
  const {usuario} = useSelector(state=>state.usuario)

  const dispatch = useDispatch();
  const meses = [
    {
      id:1,
      value:"Enero"
    },
    {
      id:2,
      value:"Febrero"
    },
    {
      id:3,
      value:"Marzo"
    },
    {
      id:4,
      value:"Abril"
    }
    ,
    {
      id:5,
      value:"Mayo"
    }
    ,
    {
      id:6,
      value:"Junio"
    }
    ,
    {
      id:7,
      value:"Julio"
    }
    ,
    {
      id:8,
      value:"Agosto"
    }
    ,
    {
      id:9,
      value:"Septiembre"
    }
    ,
    {
      id:10,
      value:"Octubre"
    }
    ,
    {
      id:11,
      value:"Noviembre"
    }
    ,
    {
      id:12,
      value:"Diciembre"
    }
]

  useEffect(() => {
    dispatch(listarBases())
  }, []);

  const handleNuevaPlantilla = () => {
    dispatch(updateStateModalLarge(<FormularioBaseCertificado /> , 'Registrar Nueva Plantilla'));
  }

  const handleEliminar = (id) =>{
   // dispatch(eliminarBoleta(id))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Base de control de Certificados" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Base de Control" },
              ]}
            />
          <button className="btn btn-primary" onClick={()=>handleNuevaPlantilla()}> Nueva Plantilla </button>
          <div className="row mt-2">
            <div className="col-md-6">
              <FormularioFiltro />
            </div>
          </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Listado
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar)}
                    tabla={bases}
                    keyCol="id_cert"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BasesCertificados;

/**====================== TABLA =========== */

const colums = (handleEliminar) => [
  {
    text: 'ID',
    key: 'id_cert',
    className: 'id_registro',
    width: 50
  }
  ,
  
  {
    text: "Título",
    key: "titulo",
    align: "center",
    sortable: true,
    className: "titulo",
  },
  {
    text: "Periodo",
    key: "periodo",
    align: "center",
    sortable: true,
    className: "periodo",
  }
  ,
  {
    text: "Producto",
    key: "nombre_proyecto",
    align: "center",
    className: "producto",
    sortable: true
  }
  ,
  {
    text:"N° Evento",
    key: "n_evento",
    align: "center",
    className: "n_evento",
    sortable: true
  }
  ,
  {
    text:"N° Libro",
    key: "n_libro",
    align: "center",
    className: "n_libro",
    sortable: true
    
  }
  ,
  {
    text:"Contenido",
    key: "tabla_contenido",
    align: "center",
    className: "tabla_contenido",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
        <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/src/Controllers/Proyecto/Certificado/assets/img/${record.tabla_contenido}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>
      </div>
    
  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_registro)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </>
  }
  
];
