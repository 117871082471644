import React from 'react';
import Cursos from './listas/Cursos'
import shortid from 'shortid'
//REDUX
import {connect} from 'react-redux' 

const TablaCursos = ({ cursos}) => {



    return ( 
        <div className="container-fluid">
            <h3>Lista de Cursos</h3>
            <div className="container-fluid">
                <ul className="list-group" style={{ maxHeight: "500px", overflowY: "scroll" }}>
                    {cursos.length > 0 ?
                        cursos.map(curso => (
                            <React.Fragment key={shortid.generate()}>
                                <Cursos
                                    curso={curso}
                                />
                            </React.Fragment>
                        ))
                        : null}
                </ul>
            </div>
        </div>
     );
}

const mapStateToProps = state => ({
    cursos: state.cursos.cursos
})
 
export default connect(mapStateToProps, {})(TablaCursos);