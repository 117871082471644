import React from 'react';
import { Button } from 'react-bootstrap';
import { faMoneyBill, faTimes, faPercentage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccionPagarPension = ({ handleModalPagar, handleFraccionarPension, estadoCuota }) => {
  return ( 
    <div className='AccionPagarPension w-100 text-center'>
    {
      estadoCuota !== 'PAGADO'  ? <>
      <Button variant='danger' size='lg' className='btn-icon' onClick={handleFraccionarPension}>
        <FontAwesomeIcon icon={faPercentage} />
        Fraccionar Pago
      </Button>
      <Button variant='info' size='lg' className='btn-icon' onClick={handleModalPagar}>
        <FontAwesomeIcon icon={faMoneyBill} />
        Registrar Pago
      </Button>
      </> : <>
        <div className='d-flex align-items-center'>
          <h3 className='mr-2'>ESTA PENSION YA ESTA PAGADA</h3> 
          <Button variant='danger' className='btn-icon' size='lg' onClick={handleModalPagar}>
            <FontAwesomeIcon icon={faTimes} />
            Anular pago
          </Button>
        </div>
      </>
    }
      
    </div>
  );
}
 
export default AccionPagarPension;