import {
  INSERTAR_ASISTENCIA,
  REMOVER_ASSITENCIAS_INFO,
  EDITAR_ASISTENCIA,
  asistenciaTypes
} from "./types";

import authAxios from "../config/authAxios";
import response from  "../config/response";
import { avisoCorrecto } from "../view/components/AvisosMensajes/MensajesSwalf";

export const listarAsistencias = (idEstado, idSesion) => async (dispatch) => {
  dispatch({
    type: asistenciaTypes.OBTENER_ASISTENCIAS
  })
  try {
    const asistencias = await authAxios.get(
      `/admin/mostrarAsistenciaPorIdTipoEntrada/${idSesion}/${idEstado}`
    );
    if (!asistencias.data.error) {
      dispatch({
        payload: asistencias,
        type: asistenciaTypes.OBTENER_ASISTENCIAS_EXITO,
      });
      return { mensaje: true, textMensaje: "Correcto" };
    }
    dispatch({
      type: asistenciaTypes.OBTENER_ASISTENCIAS_ERROR,
      payload: asistencias.data.error
    });
    return { mensaje: false, textMensaje: asistencias.data.error };
  } catch (error) {
    console.log(error);
    dispatch({
      type: asistenciaTypes.OBTENER_ASISTENCIAS_ERROR
    });
    return { mensaje: false, textMensaje: "Error en el sistema" };
  }
};

export const insertarAsistencia = (dni, data) => async (dispatch) => {
  try {
    const respuesta = await authAxios.put(
      `/admin/buscarAlumnoDniAsistencia/${dni}`,
      data
    );
    if (!respuesta.data.errors && !respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: INSERTAR_ASISTENCIA,
      });
      return { mensaje: true };
    }

    return { mensaje: false };
  } catch (error) {
    console.log(error);
    return { mensaje: false };
  }
};

export const editarAsistecia = (idEstudiSesion, hora, idTipo) => async (
  dispatch
) => {
  try {
    const respuesta = await authAxios.put(
      `/admin/modificarHoraAsistencia/${idEstudiSesion}/${hora}/${idTipo}`
    );

    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data,
        type: EDITAR_ASISTENCIA,
      });

      return { mensaje: true, textMensaje: "Asistencia actualizada" };
    }

    return { mensaje: false, textMensaje: respuesta.data.error };
  } catch (error) {
    console.log(error);
    return { mensaje: false, textMensaje: error };
  }
};

export const actualizarAsistenciasMasivo = (idSesion, datos) => {
  return async dispatch => {
    dispatch({
        type: asistenciaTypes.ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO
    })
    try {
        const respuesta = await authAxios.put(`/admin/matricula/asistencia/metodo_check/${idSesion}`, datos)
        avisoCorrecto('Se actualizó corretamente el estado de las asistencias.');
        dispatch({
            type: INSERTAR_ASISTENCIA,
            payload: respuesta.data
        })
    } catch (error) {
        response.error(error, dispatch, asistenciaTypes.ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO_ERROR);
    }
  }
};

export const removerInfoAsistencia = () => (dispatch) => {
  dispatch({
    type: REMOVER_ASSITENCIAS_INFO,
  });
};
