import React, { useState } from 'react'
import {avisoError,avisoCargando} from '../../components/AvisosMensajes/MensajesSwalf'

//Redux
import {connect} from 'react-redux'
import { useEffect } from 'react'

const FormularioUsuario = (props) => {

    //State
    const {idUsuario, usuarioSistema} = props
    //Funciones
    const {nuevoUsuario, editarUsuarioSistema} = props

    const [nombre, setNombre] = useState('')
    const [apellido, setApellido] = useState('')
    const [fechaNac, setFechaNac] = useState('')
    const [area, setArea] = useState('')
    const [cargo, setCargo] = useState('')
    const [email, setEmail] = useState('')
    const [dni, setDni] = useState('')
    const [direccion, setDireccion] = useState('')
    const [distrito, setDistrito] = useState('')
    const [provincia, setProvincia] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [celular, setCelular] = useState({
        propio: null,
        referencia: null
    })
    const [userName, setuserName] = useState('')
    const [clave, setClave] = useState('')
    const [imgUsuario, setImgUsuario] = useState('')
    const [idEstado, setIdEstado] = useState('1')

    const submitFormulario = e => {
        e.preventDefault()
        if (!idUsuario) {
            nuevoUsuarioSistema()
            return
        }
        editarUsuarioInfo()
    }

    const nuevoUsuarioSistema = () => {
        if (nombre === ''|| apellido === '' || email === '' || isNaN(dni) || dni === '' || isNaN(celular.propio) || celular.propio === null
            || userName === '' || clave === '' || area === '' || cargo === '') {
                avisoError('Hay campos requeridos sin llenar')
                return
        }

        avisoCargando('Guardando ...')

        const dataNuevoUsuario = {
            nombre_usuario: nombre,
            apellido_usuario: apellido,
            fecha_nac: fechaNac,
            dni: dni,
            direccion: direccion,
            provincia: provincia,
            distrito: distrito,
            departamento: departamento,
            celular: celular.propio,
            celular_ref: celular.referencia,
            email: email,
            usuario: userName,
            password: clave,
            id_tp_estado: 1,
            id_tp_nivel_usuario: area,
            cargo: cargo
        }
        
        //mandar los datos al state de nuevo usuario
        nuevoUsuario(dataNuevoUsuario)
    }

    const editarUsuarioInfo = () => {

        if (nombre === ''|| apellido === '' || isNaN(dni) || dni === '' 
            || isNaN(celular.propio) || celular.propio === null
            || area === '' || cargo === '') {
            avisoError('Hay campos requeridos sin llenar')
            return
        }

        avisoCargando('Guardando ...')

        const dataNuevoUsuario = {
            nombre_usuario: nombre,
            apellido_usuario: apellido,
            fecha_nac: fechaNac,
            dni: dni,
            direccion: direccion,
            provincia: provincia,
            distrito: distrito,
            departamento: departamento,
            celular: celular.propio,
            celular_ref: celular.referencia,
            email: email,
            id_tp_estado: 1,
            id_tp_nivel_usuario: area,
            cargo: cargo
        }
        
        //mandar los datos al state de editar usuario
        editarUsuarioSistema(dataNuevoUsuario)
    }

    //metodo para el completar para el editar
    const llenarStates = () => {
        setNombre(usuarioSistema.nombre_usuario)
        setApellido(usuarioSistema.apellido_usuario)
        setFechaNac(usuarioSistema.fecha_nac)
        setArea(usuarioSistema.id_tp_nivel_usuario)
        setCargo(usuarioSistema.cargo)
        setEmail(usuarioSistema.email)
        setDni(usuarioSistema.dni)
        setDireccion(usuarioSistema.direccion)
        setDistrito(usuarioSistema.distrito)
        setProvincia(usuarioSistema.provincia)
        setDepartamento(usuarioSistema.departamento)
        setCelular({
            propio: usuarioSistema.celular,
            referencia: usuarioSistema.celular_ref
        })
    }

    useEffect(() => {
        if (idUsuario) {
            llenarStates()    
        }
        

    },[usuarioSistema])

    return (
      <div className="row">
        <div className="col-lg-8 order-lg-2">
          <form className="form_llenar" onSubmit={(e) => submitFormulario(e)}>
            <div className="form-group row">
              <label className="label-required col-lg-3 col-form-label form-control-label">
                Nombre
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={nombre}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="label-required col-lg-3 col-form-label form-control-label">
                Apellido
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setApellido(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={apellido}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="area"
                className="label-required col-lg-3 col-form-label form-control-label"
              >
                Área
              </label>
              <div className="col-lg-9">
                <select
                    value={`${area}`}
                    id="area"
                    onChange={e => setArea(e.target.value)}
                    className="form-control"
                >
                  <option value="">--Seleccione--</option>
                  <option value="1">Administración</option>
                  <option value="4">Académica</option>
                  <option value="5">Posventa</option>
                  <option value='6'>Contabildad</option>
                  <option value='7'>Tutoria</option>
                  <option value='8'>T.I.C</option>
                </select>
              </div>
            </div>  
            <div className="form-group row">
              <label
                htmlFor="cargo"
                className="label-required col-lg-3 col-form-label form-control-label"
              >
                Cargo
              </label>
              <div className="col-lg-9">
                <input
                  type="text"
                  onChange={(e) => setCargo(e.target.value)}
                  name="cargo"
                  id="cargo"
                  className="form-control"
                  defaultValue={cargo}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label form-control-label">
                Fecha Nacimiento
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setFechaNac(e.target.value);
                  }}
                  className="form-control"
                  type="date"
                  defaultValue={fechaNac}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="label-required col-lg-3 col-form-label form-control-label">
                E-mail
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-control"
                  type="email"
                  defaultValue={email}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="label-required col-lg-3 col-form-label form-control-label">
                DNI
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setDni(parseInt(e.target.value));
                  }}
                  className="form-control text-uppercase"
                  defaultValue={dni}
                  type="number"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className=" col-lg-3 col-form-label form-control-label">
                Dirección
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setDireccion(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={direccion}
                  placeholder=""
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label form-control-label"></label>
              <div className="col-lg-3">
                <input
                  onChange={(e) => {
                    setDistrito(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={distrito}
                  placeholder="Distrito"
                />
              </div>
              <div className="col-lg-3">
                <input
                  onChange={(e) => {
                    setProvincia(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={provincia}
                  placeholder="Provincia"
                />
              </div>
              <div className="col-lg-3">
                <input
                  onChange={(e) => {
                    setDepartamento(e.target.value);
                  }}
                  className="form-control text-uppercase"
                  type="text"
                  defaultValue={departamento}
                  placeholder="Región"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="label-required col-lg-3 col-form-label form-control-label">
                Tel/Celular
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setCelular({
                      ...celular,
                      propio: parseInt(e.target.value),
                    });
                  }}
                  className="form-control text-uppercase"
                  defaultValue={celular.propio}
                  type="number"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-3 col-form-label form-control-label">
                Celular Ref.
              </label>
              <div className="col-lg-9">
                <input
                  onChange={(e) => {
                    setCelular({
                      ...celular,
                      referencia: parseInt(e.target.value),
                    });
                  }}
                  className="form-control text-uppercase"
                  defaultValue={celular.referencia}
                  type="number"
                />
              </div>
            </div>
            {!idUsuario ?
                <> 
                    <div className="form-group row">
                    <label className="label-required col-lg-3 col-form-label form-control-label">
                        Username
                    </label>
                    <div className="col-lg-9">
                        <input
                        onChange={(e) => {
                            setuserName(e.target.value);
                        }}
                        className="form-control text-uppercase"
                        type="text"
                        defaultValue={userName}
                        />
                    </div>
                    </div>
                    <div className="form-group row">
                    <label className="label-required col-lg-3 col-form-label form-control-label">
                        Password
                    </label>
                    <div className="col-lg-9">
                        <input
                        onChange={(e) => {
                            setClave(e.target.value);
                        }}
                        className="form-control text-uppercase"
                        type="password"
                        defaultValue={clave}
                        />
                    </div>
                    </div>
                </>
            :null}
            

            <div className="form-group row">
              <div className="col-md-3"></div>
              <div className="col-md-9"></div>
              <label className="col-lg-3 col-form-label form-control-label"></label>
              <div className="boton_aceptar col-lg-9">
                <input
                  type="submit"
                  className="btn btn-danger mr-1"
                  value="Guardar"
                />
                <button type="button" className="btn btn-primary">
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="subir_foto col-lg-4 order-lg-1 text-center">
          <img
            src="/images/user.png"
            className="mx-auto avatar-usuario img-fluid img-circle d-block"
            alt="avatar"
          />
          <label className="custom-file">
            <input type="file" id="file" className="custom-file-input" />
            <span className="custom-file-control btn btn-success">
              Subir Imagen
            </span>
          </label>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
    usuarioSistema: state.usuario.usuarioSistema
})

const mapDispatchToProps = {

}
 
export default connect(mapStateToProps, mapDispatchToProps)(FormularioUsuario);