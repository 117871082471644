import React from 'react';
import ClasificacionAreaTrajador from './ClasificacionAreaTrabajador';

const ConfiguracionTrabajador = ({ trabajador = {} }) => {
  return ( 
    <div className="ConfiguracionTrabajador">
      <ClasificacionAreaTrajador 
        area={trabajador.area}
        clasificacion={trabajador.clasificacion}
      />
    </div>
  );
}
 
export default ConfiguracionTrabajador;