import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import FormNuevoPlan from '../FormNuevoPlan';
import { useSelector } from 'react-redux';
import CardPlan from '../CardPlan';
import FormPlanCuotas from '../FormPlanCuotas';
import HeaderSeccionModalidad from '../HeaderSeccionModalidad';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';

const PlanesCuotas = ({ onShowModal, onCloseModal, handleEliminarPlan }) => {

  const { planPagoCuotas = [] } = useSelector(state => state.cursos.proyecto.planes) || [];
  const handleClick = () => {
    onShowModal(<FormNuevoPlan 
      onCloseModal={onCloseModal}
      idTipoModalidad={3}
    />, 'Nuevo plan');
  }

  const handleExaminar = (plan) => {
    onShowModal(<FormPlanCuotas
      onCloseModal={onCloseModal}
      plan={plan}
    />, 'Examinar plan');
  }

  const handleEditar = (plan) => {
    onShowModal(<FormNuevoPlan
      onCloseModal={onCloseModal}
      idTipoModalidad={3}
      plan={plan}
    />, 'Editar plan');
  }

  const handleEliminar = (idPlan) => {
    handleEliminarPlan(3, idPlan)
  }

  const handleMostrarPlanes = () => {
    if(planPagoCuotas && planPagoCuotas.length > 0) {
      return [...planPagoCuotas].map(plan => (
        <Col lg={2} md={3} sm={6} key={plan.id_plan}>
          <CardPlan 
            plan={plan}
            handleExaminar={handleExaminar}
            handleEliminar={handleEliminar}
            handleEditar={handleEditar}
          />
        </Col>
      ))
    }
    return <Col sm={12}>
      <Alert variant='info'>
        No hay planes registrados 
      </Alert>
    </Col>
  }

  return ( 
    <div className="PlanesCuotas">
      <div className="PlanesCuotas__accion-boton text-right">
        <HeaderSeccionModalidad
          title='CUOTAS'
          icon={faListAlt}
          handleClick={handleClick}
        />
      </div>
      <div className="PlanesCuotas__lista">
        <Row>
          {handleMostrarPlanes()}
        </Row>
      </div>
    </div>
  );
}
 
export default PlanesCuotas;
