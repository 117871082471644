import {
    LISTAR_POP_UP,
    ACTUALIZAR_POP_UP_ERROR,
    ACTUALIZAR_POP_UP,
    OBTENIENDO_POPUP,
    MOSTRAR_COMUNICADO,
    LISTAR_FONDOS_INTRANET,
    SET_ID_PROVEEDOR
} from '../actions/types';

const initialState = {
    popups: {},
    loading: false,
    error: null,
    errorEdit:false,
    show:false,
    fondos:null,
    id_proveedor:null,
}

export default function(state = initialState, action){
    switch (action.type) {
        case OBTENIENDO_POPUP:
            return {
                ...state,
                loading: true,
                popups: action.payload,
                error:null
            }
        case LISTAR_POP_UP:
            return {
                ...state,
                loading: false,
                popups: action.payload,
                error:null
            }
        case LISTAR_FONDOS_INTRANET:
            return {
                ...state,
                fondos: action.payload
            }
    
        case ACTUALIZAR_POP_UP:
            return {
                ...state,
                errorEdit: false
            }
        case ACTUALIZAR_POP_UP_ERROR:
            return {
                ...state,
                errorEdit: true
            }
        case MOSTRAR_COMUNICADO:
                return {
                    ...state,
                    show: action.payload.show
                }
        case SET_ID_PROVEEDOR:
                return{
                    ...state,
                    id_proveedor:action.payload
                }
            
        default:
            return state
    }
}