import React, { useState, useEffect } from "react";

import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//import { avisoCorrecto } from "../../components/AvisosMensajes/MensajesSwalf";
import ModalBasic from "../../components/Modals/ModalBasic";
import ListaPreguntas from "./ListarPreguntas";
import NuevaPregunta from "./NuevaPreguntaExamen";
const AgregarPregunta = (props) => {
 

    const {idExamen, guardarPregunta, eliminarPregunta} = props;
    const [showModal, setShowModal] = useState(false);
    const [formulario, setFormulario] = useState(null);
    const {preguntas}= useSelector(state=>state.examenes);
    //const dispatch = useDispatch();



    const guardarFormulario = (componente) => {
        updateShowModal(true);
        setFormulario(componente);
      };
    
    const updateShowModal = (valor) => {
        setShowModal(valor);
      };
    

      useEffect(()=>{
       /* if(preguntas)
        console.log(preguntas);*/
      },[preguntas])

  return (
<>
        <ModalBasic
          show={showModal}
          title={'Nueva Pregunta'}
          updateShowModal={updateShowModal}
        >
          {formulario}
        </ModalBasic>
    <div className="col-md-6 nuevo-examen">
    <Card>
        <Card.Body>
        <div>{preguntas ? <ListaPreguntas preguntas={preguntas} eliminarPregunta={eliminarPregunta}/>: ''}</div>
        
        </Card.Body>
        <Card.Footer>
          <Button variant='info'   onClick={() => {
                  guardarFormulario(
                    <NuevaPregunta idExamen={idExamen} updateShowModal={updateShowModal} guardarPregunta={guardarPregunta} />
                  );
                }}>Agregar Pregunta</Button>
        </Card.Footer>
    </Card>
    </div>
    </>
  );
};

export default AgregarPregunta;

