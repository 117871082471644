import React from 'react';
import { Modal } from 'react-bootstrap'

import './ModalBasic.scss'


const ModalBasic = (props) => {

  const { show, updateShowModal, title, children } = props

  return ( 
    <Modal
      size="md"
      show={show}
      onHide={() => updateShowModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className='modal-basic'
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h4>{ title }</h4>
        </Modal.Title>
      </Modal.Header>
        { children }
    </Modal>
   );
}
 
export default ModalBasic;