import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DocentesRelacionados from './DocentesRelacionados';
import TodoDocentesControlContainer from '../../../../../containers/TodoDocentesControlContainer';
import './RelacionDocentes.scss';

const ControlInsertDeleteList = () => {

  return (
    <div className='relacion-docentes'>
      <Row>
        <Col md={7}>
          <TodoDocentesControlContainer/>
        </Col>
        <Col md={5}>
          <DocentesRelacionados/>
        </Col>
      </Row>
    </div>
  )
}
 
export default ControlInsertDeleteList;
