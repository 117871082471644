import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalLarge } from '../../../../../actions/modalActions';
import ControlInsercionDocentesCapacitacion from '../../../../../containers/ControlInsercionDocentesCapacitacion';

const BotonesAcciones = () => {
  
  const dispatch = useDispatch()

  const handleModal = () => {
    dispatch(updateStateModalLarge(<ControlInsercionDocentesCapacitacion/>, 'Docentes'))
  }

  return ( 
    <div className="ponente-control-acciones">
      <button
        className='btn btn-info'
        onClick={() => handleModal()}
      >
        <FontAwesomeIcon icon={ faPlus } />
      </button>
    </div>
   );
}
 
export default BotonesAcciones;