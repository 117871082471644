import React, { useState } from 'react';
import { Col, Table } from 'react-bootstrap';
import PensionesItem from '../view/components/ExaminarProyecto/PensionesItem/PensionesItem';
import Fatal from '../view/components/MessageAlert/Fatal';

const useListaPension = (pensionesParams, idModalidad) => {
  
  const [pensiones, setPensiones] = useState(pensionesParams);

  const handleEliminarCuota = (id) => {
    const nuevasCuotas = [...pensiones].filter(cuota => cuota.id_dt_plan !== id );
    setPensiones(nuevasCuotas);
  }

  const mostrarPensiones = () => {
    if(!pensiones.length) {
      return <Fatal message='No hay pensiones registradas' />
    } else {
      let cantidadMensualidad = 0;
      return (
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Concepto</th>
              <th>Monto</th>
              <th>Fecha límite</th>
              <th>Eliminar</th>
            </tr>
          </thead>
            <tbody>
            {[...pensiones].map((pension, index) => {
                let indexPension = null;
                let nombreConcepto = '';
                if(parseInt(pension.id_tp_concepto) === 2) {
                  cantidadMensualidad+=1;
                  if(idModalidad === 3) {
                    nombreConcepto = 'CUOTA';
                    indexPension = index;
                  } else if (idModalidad === 1) {
                    nombreConcepto = 'MENSUALIDAD';
                    indexPension = cantidadMensualidad-1;
                  }
                } else if(parseInt(pension.id_tp_concepto) === 1) {
                  indexPension = null;
                  nombreConcepto = 'MATRICULA'
                } else {
                  indexPension = null;
                  nombreConcepto = 'CERTIFICADO'
                }
                return (
                  <PensionesItem
                    key={index}
                    item={index}
                    index={indexPension}
                    nombreConcepto={nombreConcepto}
                    handleEliminarCuota={handleEliminarCuota}
                    {...pension}
                  />
                )
              })}
            </tbody>
          </Table>
      )
    }
  }

  return { pensiones, setPensiones, mostrarPensiones };
}
 
export default useListaPension;