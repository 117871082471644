import React, { useEffect } from 'react';
import Layout from '../../../../../app/Layout';
import HeaderContent from '../../../General/HeaderContent';
import Breadcrumb from '../../../General/Breadcrumb';
import { useParams } from 'react-router-dom'
import { Tabs, Tab } from 'react-bootstrap'
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';

import './NuevaEncuesta.scss';
import VistaEncuesta from '../../../../components/CreacionEncuesta/VistaEncuesta';
import InfoEncuesta from '../../../../components/CreacionEncuesta/InformacionEncuesta';

//Redux
import { connect } from 'react-redux';
import { obtenerEncuestaID } from '../../../../../actions/encuestasAction';

const NuevaEncuesta = props => {

  const params = useParams()

  const { idEvento, idEventoTutor, idFormulario } = params

  //State redux
  const { loading } = props;
  //Funciones redux
  const { obtenerEncuestaID } = props;

  useEffect(() => {
    obtenerEncuestaID(idFormulario);
  }, []);
  

  return ( 
    <Layout>
      <HeaderContent
        title='Nueva encuesta'
      />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/tutor/eventos", nombre: "Eventos" },
          { url: `/tutor/eventos/${idEvento}/${idEventoTutor}/encuestas`, nombre: "Encuestas" },
          { url: ``, nombre: "Nueva encuesta" },
        ]}
      />

      <div className="nueva-encuesta w-75 m-auto bg-white">
        <SpinnerLoading loading={loading}>
          <Tabs defaultActiveKey="creacion" id="uncontrolled-tab-example">
            <Tab eventKey="creacion" title="Creación">
              <VistaEncuesta />
            </Tab>
            <Tab eventKey="informacion" title="Información">
              <InfoEncuesta />
            </Tab>
          </Tabs>
        </SpinnerLoading>
      </div>
    </Layout>
   );
}

const mapStateToProps = (state) => ({
  loading: state.encuestas.loading
});

const mapDispatchToProps = {
  obtenerEncuestaID
};
 
export default connect(mapStateToProps, mapDispatchToProps)(NuevaEncuesta);