import React from 'react';
import { Image } from 'react-bootstrap'

import './MensajeLoading.scss';

const PIMER_MENSAJE = 'Elije las opciones en la parte de arriba';
const SEGUNDO_MENSAJE = 'para comenzar una nueva búsqueda'
const IMAGEN = '/images/search.png'

const MensajeLoading = ({primerMensaje = PIMER_MENSAJE, segundoMensaje = SEGUNDO_MENSAJE, imagen = IMAGEN}) => {

  return ( 
    <div className="mensaje-loading">
      <div className="aviso-cargar text-white p-3">
          <div className="anuncio">
            <Image src={imagen}/>
            <h3>Comenzar la búsqueda</h3>
            <p className='mb-0'>{primerMensaje}</p>
            <p className='mb-0'>{segundoMensaje}</p>
          </div>              
          
        </div>
    </div>
   );
}
 
export default MensajeLoading;