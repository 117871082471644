import React from "react";
import { Spinner } from "react-bootstrap";
import { avisoError } from "../../AvisosMensajes/MensajesSwalf";
import "./SpinnerLoading.scss";

const SpinnerLoading = (props) => {
  const { children, loading = true, error } = props;

  if (error) {
    return (
      <> { avisoError('Error al cargar los datos')}</>
    
    )
  }
  if (loading) {
    return (
      <div className='SpinnerLoading'>
        <Spinner animation='border' variant='primary' />
      </div>
    );
  }
  return <>{children}</>;
};

export default SpinnerLoading;
