import React, { useEffect } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Layout from '../../../../app/Layout';
import AvatarUsuario from '../../../components/Avatar/AvatarUsuario';
import DatosFinanzasResumen from '../../../components/Matriculas/DatosFinanzasResumen';
import DatosMatriculasResumen from '../../../components/Matriculas/DatosMatriculaResumen';
import EstadoMatricula from '../../../components/Matriculas/EstadoMatricula';
import VistaDatosPersonales from '../../../components/RegistroUsuarios/DatosPersonales/VistaDatosPersonales/VistaDatosPersonalesDiscente';
import Breadcrumb from '../../General/Breadcrumb';
import HeaderContent from '../../General/HeaderContent';
import { useParams } from 'react-router-dom';
import './InspeccionarMatricula.scss';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerMatriculaDiscente } from '../../../../actions/matriculasActions';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';
import Fatal from '../../../components/MessageAlert/Fatal';

const InspeccionarMatricula = () => {

  const dispatch = useDispatch();
  const { idMatricula } = useParams();
  const { matricula, loading, error } = useSelector(state => state.matriculas);
  //console.log(matricula)
  const mostrarContenido = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    if(matricula) return <Row>
      <Col lg={4}>
        <div className="box box-secondary">
          <div className="box-header with-border">
            <h6 className='title-head mb-0'>
              <FontAwesomeIcon icon={faUser} />
              Datos de usuario
            </h6>
          </div>
          <div className="box-body">
            <AvatarUsuario
              urlAvatar={matricula.img_usuario}
            />
            <div className='mt-3'>
              <VistaDatosPersonales
                datos={matricula}
                handleEditar={null}
              />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={8}>
        <div className="box box-secondary resumen-datos-matricula">
          <DatosMatriculasResumen 
            matricula={matricula}
          />
          <DatosFinanzasResumen 
            matricula={matricula}
          />
          <EstadoMatricula 
            matricula={matricula}
          />
        </div>
      </Col>
    </Row>
  }

  useEffect(() => {
    dispatch(obtenerMatriculaDiscente(idMatricula));
  }, [dispatch, idMatricula]);

  return (
    <Layout>
      <div className="InspeccionarMatricula">
        <div className="InspeccionarMatricula__header">
          <HeaderContent title='Inspeccionar matrícula' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/matriculas", nombre: "Discentes matriculados" },
              { url: "", nombre: "Inspeccionar matrícula" },
            ]}
          />
        </div>
        <div className="InspeccionarMatricula__body">
          {mostrarContenido()}
        </div>
      </div>
    </Layout>
  );
}
 
export default InspeccionarMatricula;