import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { eliminarRelacionDocente } from '../../../../../actions/controlCapacitacionesActions';
import ListDocenteItem from './ListDocenteItem';

//Redux
import { useSelector, useDispatch } from 'react-redux';

const DocentesRelacionados = () => {

  const { idCapacitacion } = useParams()
  const { docentes_filtro } = useSelector(state => state.control_capacitacion.area_trabajo.datos[idCapacitacion])
  const dispatch = useDispatch()

  const handleDeleteRelation = (idFiltroDocente) => {
    dispatch(eliminarRelacionDocente(idFiltroDocente, idCapacitacion))
  }

  if(!docentes_filtro) return null

  return ( 
    <section className="docentes-relacionados">
      <h5>Relacionados</h5>
      <div className='docentes-relacionados__lista'>
        {docentes_filtro.length ? (
          <ul>
            <ListDocenteItem
              docentes={docentes_filtro}
              render = {(docente) => (
                <button 
                  className='btn-danger btn btn-sm'
                  onClick={() => handleDeleteRelation(docente.id_filtro_docente)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button> 
              )} 
            />
          </ul>
        ) : (
          <p>No hay docentes relacionados a esta capacitación.</p>
        )}
      </div>
    </section>
   );
}
 
export default DocentesRelacionados;