import React, { useEffect, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import "./Cabecera.scss";
import { useInputValue } from "../../../../hooks/useInputValue";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { limpiarApunteEditar } from '../../../../actions/apuntesActions';

const Cabecera = (props) => {

  const { handleNuevo, handleEditar } = props;
  const dispatch = useDispatch()
  const apunte = useSelector((state) => state.apuntes.apunteEditar);
  const limpiar = () => dispatch(limpiarApunteEditar());
  const [contenido, setContenido] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(apunte).length > 0) {
      handleEditar(contenido, apunte.id_dt_reptec_desertado)
    } else {
      handleNuevo(contenido);
    }
    limpiar()
  };

  useEffect(() => {
    setContenido(apunte.comentario || '');
  }, [apunte]);

  return (
    <div className="cabecera">
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col md={10}>
            <Form.Control
              md={8}
              name="contenido"
              placeholder="Ingresa tu comentario aquí"
              value={contenido}
              onChange={(e) => setContenido(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Button type="submit" className='btn-block mt-0' md={4}>
              {Object.keys(apunte).length > 0 ? 'Guardar' : 'Agregar'}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  );
};

export default Cabecera;
