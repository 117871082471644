import React from 'react';
import ListDocenteItem from '../RelacionDocentes/ListDocenteItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { eliminarDocenteTentativoConfirmado } from '../../../../../actions/controlCapacitacionesActions';

const ListaDocentesConfirmados = () => {

  const sesionActivo = useSelector(state => state.control_capacitacion.area_trabajo.activo) 
  const dispatch = useDispatch()

  const { docente_confirmado } = sesionActivo;

  const handleDeleteButton = ({ id_filtro_sesion }) => {
    dispatch(eliminarDocenteTentativoConfirmado(id_filtro_sesion))
  }

  return ( 
    <div className="lista-docentes-confirmados relacion-docentes">
      <p className='acciones-tabla-control__subtitle'>
        Docente confirmado
      </p>
      <div className='lista-docentes__scroll'>
        {docente_confirmado.length ? (
          <ListDocenteItem
            docentes={docente_confirmado}
            render={(docente) => (
              <div>
                <button 
                  className='btn-danger btn btn-sm'
                  onClick={() => handleDeleteButton(docente)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button> 
              </div>
            )}
          />
        ) : (
          <p>No hay docente confirmado.</p>
        )}
      </div>
    </div>
   );
}
 
export default ListaDocentesConfirmados;