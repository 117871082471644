import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { buscarId } from "../../../../helpers/functions";
//Redux
import { connect } from "react-redux";
import { modificarNotas } from "../../../../actions/notasActions";
import { useRef } from "react";
import "../notas.scss";

const TablaListadoNotas = (props) => {
  //ref
  const notaPromedioRef = useRef("");
  //state
  const { notasSesiones, estudiantesEditados, setEstudiantesEditados } = props;

  const editarNota = (e, estudianteNota, examen = "", puntos = "") => {
    if (examen !== "") {
      let nuevoEstudiantesEditados = estudiantesEditados.filter(
        (estudiantes) =>
          estudiantes.id_estudiante_sesion !==
          estudianteNota.id_estudiante_sesion
      );
      let puntos = 0;
      estudiantesEditados.map((estudiante) => {
        if (
          estudiante.id_estudiante_sesion ===
          estudianteNota.id_estudiante_sesion
        ) {
          puntos = estudiante.puntos_adicionales;
        }
        //cambiar el valor de nota promedio
        let inputNota = buscarId(`${estudianteNota.id_estudiante}`);
        inputNota.value = examen;
      });
      nuevoEstudiantesEditados = [
        ...nuevoEstudiantesEditados,
        {
          id_estudiante_sesion: estudianteNota.id_estudiante_sesion,
          nota: !isNaN(examen) ? examen : 0,
          puntos_adicionales: !isNaN(puntos) ? puntos : 0,
          promedio_nota: !isNaN(examen) ? examen : 0,
          id_modulo: estudianteNota.id_modulo,
          id_proyecto: estudianteNota.id_proyecto,
          id_estudiante: estudianteNota.id_estudiante
        },
      ];
      setEstudiantesEditados(nuevoEstudiantesEditados);
      return;
    }
    let nuevoEstudiantesEditados = estudiantesEditados.filter(
      (estudiantes) =>
        estudiantes.id_estudiante_sesion !== estudianteNota.id_estudiante_sesion
    );
    let notaExam = 0;
    estudiantesEditados.map((estudiante) => {
      if (
        estudiante.id_estudiante_sesion === estudianteNota.id_estudiante_sesion
      ) {
        notaExam = estudiante.nota;
      }
    });
    nuevoEstudiantesEditados = [
      ...nuevoEstudiantesEditados,
      {
        id_estudiante_sesion: estudianteNota.id_estudiante_sesion,
        nota: !isNaN(notaExam) ? notaExam : 0,
        puntos_adicionales: !isNaN(puntos) ? puntos : 0,
        promedio_nota: !isNaN(notaExam) ? notaExam : 0,
        id_modulo: estudianteNota.id_modulo,
        id_proyecto: estudianteNota.id_proyecto,
        id_estudiante: estudianteNota.id_estudiante,
        id_usuario:estudianteNota.id_usuario
      },
    ];
    setEstudiantesEditados(nuevoEstudiantesEditados);
    return;
  };

  useEffect(() => {
    if (notasSesiones.length > 0) {
      setEstudiantesEditados(notasSesiones);
    }
  }, [notasSesiones.length, notasSesiones]);

  return (
    <React.Fragment>
      <div className="TablaListadoNotas tableFixHead">
        <table className="tabla notas">
          <thead className="thead-horario">
            <tr>
              <th>Id Estudiante</th>
              <th>Estudiante</th>
              <th>Exam.</th>
              <th>Ptos.</th>
              <th>Promedio</th>
            </tr>
          </thead>
          <tbody>
            {notasSesiones.length > 0 ? (
              notasSesiones.map((nota) => (
                <React.Fragment key={nota.id_estudiante_sesion}>
                  <FilaTabla nota={nota} editarNota={editarNota} />
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="5">No Hay Alumnos en esta Sesion</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <button type="submit" className="btn btn-danger">
        Guardar
      </button>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  notasSesiones: state.notas.notasSesiones,
});

export default connect(mapStateToProps, { modificarNotas })(TablaListadoNotas);

const FilaTabla = ({ nota, editarNota }) => {
  return (
    <tr>
      <td className="p-1">{nota.id_estudiante}</td>
      <td className="p-1">
        {nota.nombre_usuario} {nota.apellido_usuario}
      </td>
      <td className="p-1">
        <input
          type="number"
          className="form-control form-control-sm"
          onChange={(e) => editarNota(e, nota, parseInt(e.target.value))}
          name=""
          defaultValue={nota.nota}
          id=""
        />
      </td>
      <td className="p-1">
        <input
          type="number"
          className="form-control form-control-sm"
          onChange={(e) => editarNota(e, nota, "", parseInt(e.target.value))}
          defaultValue={nota.puntos_adicionales}
        />
      </td>
      <td className="p-1">
        {" "}
        <input
          type="number"
          className="form-control form-control-sm"
          name=""
          disabled
          defaultValue={nota.promedio_nota}
          id={`${nota.id_estudiante}`}
        />
      </td>
    </tr>
  );
};
