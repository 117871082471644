import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import {actualizarRecursoCampaign} from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { useSelector } from 'react-redux';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const EditarRecursoC = ({registro}) => {


  const [nombre_recurso, setNombreRecurso]=useState(registro.nombre_recurso)
  const [url_recurso, setUrlRecurso]=useState(registro.url_recurso?registro.url_recurso:'')
  const [estado, setEstado]=useState(registro.estado)
  const {recursos} = useSelector(state=>state.campaigns)
  const dispatch = useDispatch();

  const editarCampaign = async (e) =>{
    e.preventDefault();
    
    const datosCamp={
      id_recurso:registro.id_recurso,
      nombre_recurso: nombre_recurso!==''?nombre_recurso:'',
      url_recurso: url_recurso!==''?url_recurso:null,
      estado: estado!==''?estado:''
    }

    await dispatch(actualizarRecursoCampaign(datosCamp,recursos))
   // console.log(datosLead)
  }
 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={(e) => editarCampaign(e)}>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Nombre Recurso:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="nombre_recurso" value={nombre_recurso} onChange={(e)=>setNombreRecurso(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        URL:
                      </label>
                    </div>
                    <div className="col-md-7">
                      <input className="form-control" name="url_recurso" value={url_recurso} onChange={(e)=>setUrlRecurso(e.target.value)} />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                       Estado
                      </label>
                    </div>
                    <div className="col-md-7">
                    <select name="estado" className="form-control" value={estado} onChange={(e)=>setEstado(e.target.value)} >
                      <option value="">Seleccione una opción</option>
                      <option value="1">Idea</option>
                      <option value="2">Completado</option>
                    </select>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default EditarRecursoC;
