import { 
  ENCUESTAS_OBTENIDAS,
  OBTENIENDO_ENCUESTAS_TUTOR,
  ERROR_OBTENIENDO_ENCUESTAS,
  ENCUESTA_ELIMINADO,
  OBTENIENDO_ENCUESTA_URL,
  ENCUESTA_OBTENIDA,
  ERROR_OBTENIENDO_ENCUESTA,
  GUARDAR_ESTADO_ENCUESTA,
  CREANDO_NUEVA_ENCUESTA,
  NUEVA_ENCUESTA_CREADA,
  REMOVER_ESTADO_CREADO,
  GUARDANDO_ENCABEZADO,
  GUARDANDO_PREGUNTA,
  GUARDANDO_CAMBIOS,
  OBTENIENDO_REPORTES_TUTOR,
  OBTENIENDO_REPORTE_TUTOR,
  REPORTE_TUTOR_OBTENIDO,
  REPORTES_TUTOR_OBTENIDO,
  REPORTE_GUARDADO,
  REMOVER_ESTADO_GUARDADO_REPORTE,
  OBTENER_ENCUESTAS_DOCENTE,
  OBTENER_ENCUESTAS_DOCENTE_EXITO
 } from '../actions/types';

 const initalState = {
  encuestas: {
    formularios: [],
  },
  encuesta: {},
  reportesTutor: [],
  encuestasDocente: [],
  encuestaDocente: [],
  reporteTutor: {
    preguntas: []
  },
  loading: false,
  loadingReportes: false,
  encuestaCreada: false,
  reporteTutorGuardado: false,
  error: null
 }

export default function(state = initalState, action) {
  switch (action.type) {
    case OBTENIENDO_ENCUESTAS_TUTOR:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENIENDO_REPORTE_TUTOR:
      return {
        ...state,
        loading: true,
        error: null
      }
    case CREANDO_NUEVA_ENCUESTA:
      return {
        ...state,
        
        error: null
      }
    case OBTENIENDO_ENCUESTA_URL:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENIENDO_REPORTES_TUTOR:
      return {
        ...state,
        loadingReportes: true
      }
    case ENCUESTAS_OBTENIDAS:
      return {
        ...state,
        loading: false,
        error: null,
        encuestas: action.payload
      }
    case ENCUESTA_OBTENIDA:
      return {
        ...state,
        loading: false,
        error: null,
        encuesta: action.payload
      }
    case OBTENER_ENCUESTAS_DOCENTE:
      return {
        ...state,
        loadingReportes: true 
      }
    case OBTENER_ENCUESTAS_DOCENTE_EXITO:
      return {
        ...state,
        loadingReportes: false,
        encuestasDocente: action.payload 
      }
    case GUARDAR_ESTADO_ENCUESTA:
      return {
        ...state,
        encuestas: action.payload
      }
    case GUARDANDO_ENCABEZADO:
      return {
        ...state,
        encuesta: action.payload
      }
    case GUARDANDO_PREGUNTA:
      return {
        ...state,
        encuesta: action.payload
      }
    case GUARDANDO_CAMBIOS:
      return {
        ...state,
        encuesta: action.payload
      }
    case 'ENCUESTA_DOCENTE_OBTENIDO':
      return {
        ...state,
        encuestaDocente: action.payload
      }
    case REPORTES_TUTOR_OBTENIDO:
      return {
        ...state,
        loadingReportes: false,
        reportesTutor: action.payload
      }
    case NUEVA_ENCUESTA_CREADA:
      return {
        ...state,
        encuesta: action.payload,
        encuestaCreada: true,
        error: null
      }
    case REPORTE_TUTOR_OBTENIDO:
      return {
        ...state,
        reporteTutor: action.payload,
        loading: false,
        error: null
      }
    case REPORTE_GUARDADO: 
      return {
        ...state,
        reporteTutor: action.payload,
        reporteTutorGuardado: true
      }
    case REMOVER_ESTADO_GUARDADO_REPORTE: 
      return {
        ...state,
        reporteTutorGuardado: false
      }
    case REMOVER_ESTADO_CREADO:
      return {
        ...state,
        encuestaCreada: false
      }
    case ENCUESTA_ELIMINADO:
      return {
        ...state,
        loading: false,
        error: null,
        encuestas: action.payload
      }
    default:
      return state
  }
}