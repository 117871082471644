import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { insertarArchivosAdjuntos, eliminarArchivosAdjuntos } from '../../actions/profesoresActions';

const withArchivosAdjuntosDocentes = WrappedComponent => props => {
  
  const [docente, idDocente] = useGetDocente();
  const dispatch = useDispatch();

  const handleSubirArchivoDocente = (datos, idTipo) => {
    dispatch( insertarArchivosAdjuntos(datos, idDocente, idTipo) )
  }

  const handleEliminarArchivo = (idDocumento, idTipo) => {
    dispatch( eliminarArchivosAdjuntos(idDocumento, idTipo, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      {...docente.documentos}
      handleSubirArchivo={handleSubirArchivoDocente}
      handleEliminarArchivo={handleEliminarArchivo}
    />
   );
}
 
export default withArchivosAdjuntosDocentes;