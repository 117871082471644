import React from 'react';
import { Table } from 'react-bootstrap';
import './TablaResumenGenerales.scss';

const TablaResumenGenerales = ({ banco, cheque, efectivo, tarjeta, total }) => {
  return ( 
    <div className="TablaResumenGenerales box box-primary p-0 mb-5">
      <Table>
        <thead>
          <tr>
            <th>Efectivo</th>
            <th>Banco</th>
            <th>Tarjeta</th>
            <th>Cheque</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align='center'>{efectivo}</td>
            <td align='center'>{banco}</td>
            <td align='center'>{tarjeta}</td>
            <td align='center'>{cheque}</td>
            <td align='center'>{total}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
 
export default TablaResumenGenerales;