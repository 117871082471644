import { indicadoresTypes } from "../actions/types";

const initialState = {
  indicadores: {},
  loading: false,
  error: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case indicadoresTypes.OBTENER_INDICADORES:
      return {
        ...state,
        loading: true,
        error: null
      }
    case indicadoresTypes.OBTENER_INDICADORES_EXITO:
      return {
        ...state,
        loading: false,
        indicadores: action.payload,
        error: null
      }
    case indicadoresTypes.OBTENER_INDICADORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        indicadores: {}
      }
      case indicadoresTypes.LIMPIAR_INDICADORES_BUSQUEDA:
      return initialState; 
    default:
      return state;
  }
}