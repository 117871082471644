import React, { useState} from 'react';
import {buscarId, handleFileSelect } from "../../../../helpers/functions";

import Editor from './Editor'
import ModalContactos from './ModalContactos';
import MultiSelect from '../../../components/plugins/MultiSelect';

//Redux
import {connect} from 'react-redux';
import { useEffect } from 'react';
import { enviarMensaje} from '../../../../actions/mensajesActions'
import { avisoCorrecto, avisoError, avisoCargando } from '../../../components/AvisosMensajes/MensajesSwalf';

const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
}

const CrearMensaje = (props) => {

  //State
  const { contactos, usuario, hilo} = props

  //Funciones
  const { enviarMensaje} = props

    const [submit, setSubmit] = useState(false)
    const [destino, setDestino] = useState()
    const [asunto, setAsunto] = useState()
    const [archivo, setArchivo] = useState()
    const [mensaje, setMensaje] = useState(hilo?hilo:'')
    const [subir, setSubir] = useState(false)
    const [defaultSelect, setDefaultSelect] = useState([])

    //State para el multiselect
    const [dataContactos, setDataContacots] = useState([])

    const [nombreArchivo, setNombrearchivo] = useState("");
    const [tamanoArchivo, setTamanoArchivo] = useState("");

    const abrirInputFile = () => {
      const input = buscarId("fileArchivo");
      input.click();
    };

     const mostrarArchivo = (e) => {
       setSubir(true);
       handleFileSelect(e);
       e.preventDefault();
       const divMostrar = buscarId("subidaArchivo");
       console.log(e.target.files[0]);
       setArchivo(e.target.files[0])
       setNombrearchivo(e.target.files[0].name);
       setTamanoArchivo(`${e.target.files[0].size / 1000} KB`);
     };
     
     //Enviar mensajes
      const enviarMensajeNuevo = async (e) => {
        
        e.preventDefault()
        setSubmit(true)
        avisoCargando('Enviando...')
        var dato = new FormData()

        dato.append('id_emisor', usuario.id_usuario)//idusuario
        dato.append('mensaje', mensaje)
        dato.append('asunto', asunto)
        dato.append('receptores', JSON.stringify(defaultSelect))
        dato.append('file_mensaje', archivo)
        console.log(defaultSelect)

        const config = {
          header: {
            'Content-Type': 'application/json',
            'Content-Type': 'image/jpeg',
            'Content-Type': 'image/jpg',
            'Content-Type': 'multipart/form-data'
          }
        }
        const respuesta = await enviarMensaje(dato, config)

        if (respuesta.mensaje) {
          avisoCorrecto(respuesta.textMensaje)
          const enviados = buscarId('menu-enviados')
          enviados.click()
          return
        }

        avisoError(respuesta.textMensaje)
      }

     const llenarDataContactos = () => {
       let contactosData = [];

       (contactos.estudiantes).map(contacto => {
         contactosData = [...contactosData,{
           value: contacto.id_usuario,
           label: `${contacto.nombre_usuario} ${contacto.apellido_usuario}`,
           id_receptor: contacto.id_usuario
         }]
       })
       setDataContacots(contactosData)
     }

    //Funcion para guardar los item seleccionados
    const guardarSeleccion = item => {
      if (item === null) {  
        setDefaultSelect([])
        return
      }
      setDefaultSelect(item)
    }

     useEffect(() => {
       if ( Object.keys(contactos).length > 0 ) {
         llenarDataContactos()
       }
     }, [contactos])

    return (
      <div className="contenetdor-nuevo border bg-white">
        <div className="cabecera">
          <div className="tile-nuevo-mensaje d-flex align-items-center border px-3 pt-2 pb-4">
            <i className="far fa-edit mr-1 ml-3"></i>
            <h5 className="mb-0">Redactar mensaje</h5>
          </div>
        </div>
        <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => enviarMensajeNuevo(e)}>
          <div className="form-row align-items-center mb-3">
            <div className="col-md-3">
              <label htmlFor="destino" className="mb-0">
                Para:
              </label>
            </div>
            <div className="col-md-7" style={{zIndex: '10'}}>
              <MultiSelect
                options={dataContactos}
                value={defaultSelect}
                guardarSeleccion={guardarSeleccion}
              />
            </div>
            <div className="col-md-2 clearfix">
              <button
                style={style.boton}
                type="button"
                data-toggle="modal"
                data-target=".bd-example-modal-lg.modal-contactos"
                className="btn btn-primary text-white float-right"
              >
                <i className="far fa-address-book"></i>
              </button>
            </div>
          </div>
          <div className="form-row align-items-center mb-3">
            <div className="col-md-3">
              <label htmlFor="asunto" className="mb-0">
                Asunto:
              </label>
            </div>
            <div className="col-md-7">
              <input
                name="asunto"
                onChange={e => setAsunto(e.target.value)}
                type="text"
                className="form-control"
                id="asunto"
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="form-row">
           <div className="col-md-6">
            <p>Nota: Para adjuntar más de un archivo, debe comprimirlos en un archivo ZIP o RAR</p>
            </div>
            <div className="col-md-6">
              <button
                style={style.buttonText}
                type="button"
                className="btn px-1 text-white float-right"
                onClick={() => abrirInputFile()}
              >
                <i className="fas fa-paperclip"></i>Adjuntar Archivos
              </button>
            </div>
            <div className=" container-fluid w-100 my-2" id="subidaArchivo">
              <div className={`bg-white border row ${subir ? "" : "d-none"}`}>
                <div
                  className={`col-md-7 align-items-center d-flex ${
                    subir ? "d-block" : "d-none"
                  }`}
                >
                  <p className="mb-0" style={style.textArchivo}>
                    <i className="fas fa-paperclip"></i>
                    {nombreArchivo} ({tamanoArchivo})
                  </p>
                </div>

                <div
                  className={`col-md-5 px-0 ${subir ? "d-block" : "d-none"}`}
                >
                  <div id="progress_bar" className="loading ">
                    <div
                      className="progress-bar rounded percent progress-bar-striped "
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{lineHeight: '15px', fontSize: '10px'}}
                    >
                      0
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input
              type="file"
              multiple
              name="files[]"
              onChange={e => mostrarArchivo(e)}
              id="fileArchivo"
              className="form-control d-none"
            />
          </div>
          <div className="form-group">
            <Editor
              setMensaje={setMensaje}
              dataDefault={mensaje}
            />
          </div>
          <button type="submit" className="btn" style={style.boton}>
            <i className="far fa-paper-plane"></i> Enviar
          </button>
        </form>
        <ModalContactos />
      </div>
    );
}

const mapStateToProps = state => ({
  contactos: state.mensajes.contactos,
  usuario: state.usuario.usuario
})
 
export default connect(mapStateToProps, { enviarMensaje})(CrearMensaje);