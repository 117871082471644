import React from 'react';
import { Table } from 'react-bootstrap'
import * as moment from 'moment';
//Redux
import { connect } from 'react-redux';

import './TablaResumenEventoEncuesta.scss';


const TablaResumenEventoEncuesta = props => {

  const { reporteTutoria: {detalle_evento = {}} } = props

  return ( 
    <div className="tabla-resumen-evento-encuesta">
      <Table striped bordered hover responsive className='border'>
        <tbody>
          <tr className='bg-primary'>
              <td colSpan='3' className='text-center text-white title'>
                { detalle_evento.nombre_proyecto }
              </td>
            </tr>
          <tr>
            <td className='text-left'>Fecha de inico</td>
            <td className='text-center'>{moment(detalle_evento.fecha_inicio).format('DD MMM YYYY')}</td>
          </tr>
          <tr>
            <td className='text-left'>Fecha de término</td>
            <td className='text-center'>{moment(detalle_evento.fecha_fin).format('DD MMM YYYY')}</td>
          </tr>
          <tr>
            <td className='text-left'>Cantidad de discentes inscritos</td>
            <td className='text-center'>{detalle_evento.cant_inscritos}</td>
          </tr>
          <tr>
            <td className='text-left'>Cantidad de discentes activos</td>
            <td className='text-center'>{detalle_evento.cant_activos}</td>
          </tr>
          <tr>
            <td className='text-left'>Cantidad de discentes desertados</td>
            <td className='text-center'>{detalle_evento.cant_desertado}</td>
          </tr>
          <tr>
            <td className='text-left'>Porcentaje de fidelización</td>
            <td className='text-center'>{parseFloat(detalle_evento.porc_fidelizacion * 100).toFixed(2)}%</td>
          </tr>
          <tr>
            <td className='text-left'>Porcentaje de deserción</td>
            <td className='text-center'>{parseFloat(detalle_evento.porc_dicersion * 100).toFixed(2)}%</td>
          </tr>
        </tbody>
      </Table>
    </div>
   );
}

const mapStateToProps = state => ({
  reporteTutoria: state.reporte.reporteTutoria
})
 
export default connect(mapStateToProps, {})(TablaResumenEventoEncuesta);