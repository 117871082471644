import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'
import Comentarios from './Comentarios';
import { useDispatch, useSelector } from 'react-redux';
import { traerComentariosVideos } from '../../../actions/cursosActions';



//Redux

const ComentariosVideos = () => {

  const dispatch=useDispatch()
  const usuario = useSelector(state=> state.usuario.usuario);
  const comentarios = useSelector(state=> state.comentarios.comentarios);
  const [datosComentarios, setDatosComentarios] = useState(null)
  const datosFetch = {
    docente: usuario.id_empleado
  }
  useEffect(()=>{
    const fetchData = async () =>{

        const rpta = await(dispatch(traerComentariosVideos(datosFetch)))

        if(rpta)
        {   
           // setLoading(false)
          // setDatosComentarios(rpta)
        }

    }

    fetchData()
    },[])

  
    useEffect(()=>{
      if(comentarios)
      {
        setDatosComentarios(comentarios)
      }
      },[comentarios])
  

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Comentarios' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Comentarios" }
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
           
             <Comentarios comentarios={comentarios} /> 
            
            </div>
          </div>    
        </div>
      </div>
    </Layout>
  );
}
 
export default ComentariosVideos;