import React, { useState , useEffect} from 'react';
import MaterialTable from '../../components/plugins/MaterialTableDemo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useSelector } from 'react-redux';
import ModalBasic from '../../components/Modals/ModalBasic';
import EditarRecursos from '../../components/Formularios/EditarRecursos';
import { Button } from 'react-bootstrap';

const SesionesVivo = (props) => {

  const { tp_sesion_en_vivo: sesionesVivo = [] } = useSelector(
    (state) => state.recursos.recursos
  );
 
  const {eliminarRecurso} = props

  const [recurso, setRecurso] = useState({})
  const [showModal, setShowModal] = useState(false)


  const botonesAcciones = (record) => (
    <>
      <Button
        variant="danger"
        size="xs"
        onClick={() => eliminarRecurso(record.id_archivo_sesion)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Button
        type="button"
        variant="info"
        size="xs"
        onClick={() => guardarRecursosEditar(record)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    </>
  );

  const guardarRecursosEditar = archivo => {
    setRecurso(archivo)
    setShowModal(true)
  }

  const updateShowModal = valor => {
    setShowModal(valor)
  }

    return ( 
        <div className="recursos__sesiones-vivo">
            <div className="p-0 bg-white">
                <div className="tabla">
                    <MaterialTable
                      colums={colums(botonesAcciones)}
                      tabla={sesionesVivo}
                      addConfig={{ key_column: 'id_archivo_sesion' }}
                    />
                </div>
            </div>
            <ModalBasic
              show={showModal}
              title='Editar recurso'
              updateShowModal={updateShowModal}
            >
              <EditarRecursos
                recurso={recurso}
              />
            </ModalBasic>
        </div>
     );
}

export default SesionesVivo;

const colums = (botonesAcciones) => [
    {
      text: "#",
      key: "index",
      align: "center",
      sortable: true,
      width: 70,
      className: "text-center",
      cell: (_, index) => index+1
    },
    {
      text: "Nombre de la ruta",
      key: "nombre_archivo",
      align: "center",
      sortable: true,
      className: "type_of_food"
    },
    {
      text: "Ruta",
      key: "ruta_archivo",
      align: "center",
      sortable: true,
      cell: (record) => (
        <Button variant='link' target='_blank' href={record.ruta_archivo}>
          {record.ruta_archivo}
        </Button>
      )
    },
    {
      text: "Publicado",
      key: "fecha",
      align: "center",
      width: 150,
      sortable: true,
      className: "text-center"
    },
    {
      text: "Acciones",
      key: "acciones",
      align: "center",
      className: "text-center",
      width: 130,
      cell: (record) => botonesAcciones(record)
    }
  ]
 