import { reporteNaranjaTypes } from "../actions/types";

const initialState = {
  reporteRecurrente:{},
  reporteNaranja: {},
  loading: false,
  error: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case reporteNaranjaTypes.OBTENER_REPORTE_NARANJA:
      return {
        ...state,
        loading: true,
        error: null
      }
    case reporteNaranjaTypes.OBTENER_REPORTE_NARANJA_EXITO:
      return {
        ...state,
        loading: false,
        reporteNaranja: action.payload,
        error: null
      }
    case reporteNaranjaTypes.OBTENER_REPORTE_NARANJA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reporteNaranja: {}
      }
      case reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE:
        return {
          ...state,
          loading: true,
          error: null
        }
      case reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE_EXITO:
        return {
          ...state,
          loading: false,
          reporteRecurrente: action.payload,
          error: null
        }
      case reporteNaranjaTypes.OBTENER_REPORTE_RECURRENTE_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
          reporteRecurrente: {}
        }
      case reporteNaranjaTypes.LIMPIAR_RESULTADO_BUSQUEDA:
      return initialState; 
    default:
      return state;
  }
}