import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { obtenerIndicadoresProyectos} from "../../../actions/finanzasReportesActions"
//Reduxt
import {useDispatch } from "react-redux";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import ListarIndicadores from "./ListarIndicadores";
import useSelectProyecto from "../../../hooks/useMultiSelectProyectos";

 
const IndicadorFinanciero = () => {
  const dispatch = useDispatch();
  const[loading, setLoading] = useState(false)
  const[listaProyectos,setListaProyectos] = useState(null)
  const [proyecto, SelectProyecto] = useSelectProyecto(null)
  
  const fetchData = async () =>{
    setLoading(true);
    const respuesta = await (dispatch(obtenerIndicadoresProyectos(proyecto)))
    setListaProyectos(respuesta.proyectos)
    setLoading(false);
  }
  const traerDatos = () => {
    fetchData();
  }
  const ordenarDiscentes = () => {
    const arreglo = [...listaProyectos]
    const nuevoArreglo = arreglo.sort((a,b)=>{return b.cantidad-a.cantidad})
  //  console.log(nuevoArreglo)
    setListaProyectos(nuevoArreglo);
  }
  const ordenarDesercion = () => {
    const arreglo = [...listaProyectos]
    const nuevoArreglo = arreglo.sort((a,b)=>{return b.desercion-a.desercion})
   // console.log(nuevoArreglo)
    setListaProyectos(nuevoArreglo);
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Indicador de Viabilidad" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/indicadores-proyectos", nombre: "Indicador de Viabilidad" },
              ]}
            />
          </div>
          <div className="box p-3">
            <div className="row">
            
              <div className="col-4">
                <label htmlFor="">Producto</label>
                <SelectProyecto/>
              </div>
              <div className="col-4 pt-4">
                <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
                <button className="btn btn-primary" onClick ={ordenarDiscentes} > <i className="fa fa-arrow-down" style={{marginRight:'5px'}}>Discentes</i></button>
                <button className="btn btn-primary" onClick ={ordenarDesercion}> <i className="fa fa-arrow-down" style={{marginRight:'5px'}}>Deserción</i></button>
                
              </div>
            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
            <SpinnerLoading loading={loading}>
                <ListarIndicadores proyectos={listaProyectos}/>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndicadorFinanciero;