import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { guardarEditarUsuarioDocente } from '../../actions/profesoresActions';


const withEditarUsuarioSesion = WrappedComponent => props => {
  
  const [docente, idDocente] = useGetDocente();
  const dispatch = useDispatch();

  const handleSubmitUsuario = nombreUsuario => {
    dispatch( guardarEditarUsuarioDocente(nombreUsuario, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      usuario={docente.usuario}
      handleSubmitUsuario={handleSubmitUsuario}
    />
   );
}
 
export default withEditarUsuarioSesion;