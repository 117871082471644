import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarTiposRecursosCampaign } from '../actions/campaignsActions';

const useSelectTiposRecurso = (initialSelect = null) => {

  const dispatch = useDispatch(); 
  const tipos_recursos = useSelector(state => state.campaigns.tipos_recursos)

  const [tipoSelected, setTipoSelected] = useState(null); 

  const handleInitialAction = () => {
    if(initialSelect) {
      const tipoSeleccionado = tipos_recursos.find(tipo => tipo.id_recurso === parseInt(initialSelect)); 
      if(tipoSeleccionado) {
        setTipoSelected(tipoSeleccionado);
      }
    }
  }

  useEffect(() => {
    if(!tipos_recursos.length) {
      dispatch(listarTiposRecursosCampaign())
    }
  }, [])

  useEffect(() => {
    handleInitialAction();
  }, [tipos_recursos])

  const SimpleSelect = props => {
    return (
      <Select
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!tipos_recursos.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={tipo => setTipoSelected(tipo)}
        value={tipoSelected}
        getOptionValue={tipos_recursos => tipos_recursos.id_recurso}
        getOptionLabel={tipos_recursos => tipos_recursos.nombre_tipo_r}
        options={tipos_recursos}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [tipoSelected, SimpleSelect, setTipoSelected]

}
 
export default useSelectTiposRecurso;