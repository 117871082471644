import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ link }) => {
  if (!link) {
    return (
      <nav aria-label="breadcrumb" className="mb-3">
        <ol className="breadcrumb mb-0 bg-white">
          <li className="breadcrumb-item">
            <a href='#'>Library</a>
          </li>
          <li className="breadcrumb-item active">Data</li>
        </ol>
      </nav>
    );
  }
  return (
    <nav aria-label="breadcrumb" className='mb-3'>
      <ol className="breadcrumb" style={{background:'transparent', paddingLeft: '0'}}>
        {link.map((item, index) => (
          <li key={index} className={`breadcrumb-item text-secondary`}>
            {index === link.length - 1 ? (
              <b>{item.nombre}</b>
            ) : (
              <>
                <i className={`${item.icon} mr-1`}></i>
                <Link to={`${item.url}`}>{item.nombre}</Link>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;

// <li className="breadcrumb-item"><a href="#">Library</a></li>
// <li className="breadcrumb-item active" >Data</li>
