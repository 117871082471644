import {
  LISTAR_DETALLES_PAGOS,
  EDITAR_ESTADO_PAGOS_C,
  REMOVER_INFORMACION_PAGOS,
  ACTUALIZAR_PAGO,
  LISTAR_EVENTOS_COMPRADOS,
  EDITAR_DETALLES_PAGOS,
  pagosTypes
} from './types'

import Swal from 'sweetalert2'
import authAxios from '../config/authAxios'
import { mostrarErrorApi } from '../helpers/functions'
import { avisoCargando, avisoCorrecto, avisoError } from '../view/components/AvisosMensajes/MensajesSwalf'
import { updateStateModalBasic, updateStateModalCustom } from './modalActions'
import response from '../config/response'

export const listarDatallesPagos = (idProyecto, idModulo, idEstudiante) => async dispatch => {
    let detallesPagos = await authAxios.get(`/admin/dtMatricula__IdEst_PrytMdlo/${idEstudiante}/${idProyecto}/${idModulo}`)
   // console.log(detallesPagos.data)
    dispatch({
        payload: detallesPagos.data[0],
        type: LISTAR_DETALLES_PAGOS
    })
}

//Para cambiar los estados de moroso, pendiente, pagado
export const editarEstadoPagos = (id_dt_pago, id_estado, tipo) => async dispatch => {
  
  let respuesta = await authAxios.put(
      `/admin/actualizarEstadoPagado/${id_dt_pago}/${id_estado}/${tipo}`
    );
      
    if (!(respuesta.data.error)) {
        dispatch({
          payload: respuesta.data[0],
          type: ACTUALIZAR_PAGO
        });

        return {mensaje: true}
    }

    return {mensaje: false}
}


export const editarEstadosPagosComplementarios = (id_dt_pg_comp, id_estado, tipo) => async dispatch => {
  let respuesta = await authAxios.put(`/admin/dt_actualizarPagosComplementario/${id_dt_pg_comp}/${id_estado}/${tipo}`)
 
  if (!respuesta.data.error) {
    dispatch({
      payload: respuesta.data[0],
      type: EDITAR_ESTADO_PAGOS_C
    })

    return {mensaje: true, textMensaje: 'Actualizado correctamente'}
  }

  return {mensaje: false, textMensaje: 'Error al actualizar pago'}
  
}

export const listarEventosComprados = idEstudiante => async dispatch => {
  try {
    let evento = await authAxios.get(`/admin/dt_mostrarMdloProyt__IdEstudiante/${idEstudiante}`)
    
    dispatch({
      payload: {
        proyectos: evento.data.proyectos,
        modulos: evento.data.modulos
      },
      type: LISTAR_EVENTOS_COMPRADOS
    })
  } catch (error) {
    console.log(error)
  }
}

export const actualizarDetallesPagos = (idDetallesPago, tipo, datos) => async dispatch => {
  try {
    const respuesta = await authAxios.put(`/admin/actualizarEstadoPagado_detalles/${idDetallesPago}/${tipo}`,
      datos
    )
 //   console.log(respuesta.data)
    if (!respuesta.data.error) {
      dispatch({
        payload: respuesta.data[0],
        type: EDITAR_DETALLES_PAGOS
      })

      return {mensaje: true, textMensaje: 'Editado correctamente'}
    }

    return {mensaje: false, textMensaje: respuesta.data.error}
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
} 

export const removerInformacionTodo = () => dispatch => {
  dispatch({
    type: REMOVER_INFORMACION_PAGOS
  })
}

export const formularioBusquedaRegistro = (name, value) => {
  return dispatch => {
    dispatch({
      type: pagosTypes.UPDATE_FORMULARIO_BUSQUEDA,
      payload: {
        name,
        value
      }
    })
  }
}

export const obtenerMatriculasDiscente = (tipo, value) => {
  return async dispatch => {    
    dispatch({
      type: pagosTypes.OBTENER_MATRICULAS_DISCENTE
    })
    try {
      const respuesta = await authAxios.get(`/admin/matricula?q=${tipo}&value=${value}`);
      
      if(respuesta.data.ok) {
        dispatch({
          type: pagosTypes.OBTENER_MATRICULAS_DISCENTE_EXITO,
          payload: respuesta.data.datos
        })
        return;
      }
      dispatch({
        type: pagosTypes.OBTENER_MATRICULAS_DISCENTE_NO_ENCONTRADO,
        payload: respuesta.data.msg
      })
    
      avisoError('Error: '+ respuesta.data.msg);
    } catch (error) {

      dispatch({
        type: pagosTypes.OBTENER_MATRICULAS_DISCENTE_ERROR,
        payload: error
      })
     mostrarErrorApi(error);
    }
  }
}

export const matriculaDiscenteActiva = (idMatricula) => {
  return (dispatch, getState) => {
    const matriculas = getState().pagos.datosFinanzasDiscente.todo.matriculas;
    const matriculaActivaState = getState().pagos.datosFinanzasDiscente.matriculaActiva;
    
    if(!matriculas.length) {
      avisoError('No hay matrículas registradas');
      return;
    }

    if(idMatricula === matriculaActivaState.id_matricula) {
      return;
    }

    const matriculaActiva = matriculas.find(matricula => matricula.id_matricula === idMatricula)
    if(!matriculaActiva) {
      avisoError('No se encontro la matrícula');
      return;
    }

    dispatch({
      type: pagosTypes.MATRICULA_ACTIVA_DISCENTE,
      payload: matriculaActiva
    })
  }
} 

//Funcion para traer la matricula y activarlo, en la pagina "examinar pension"
export const obtenerMatriculasDiscenteActivarMatricula = (tipo, value, idMatricula) => {
  return async dispatch => {
    await dispatch(obtenerMatriculasDiscente(tipo, value))
    await dispatch(matriculaDiscenteActiva(idMatricula))
  }
}

export const obtenerCuotasFraccionadas = (tipo, valor, matricula, idPension) => {
  return async (dispatch, getState) => {
    dispatch({
      type: pagosTypes.OBTENER_CUOTAS_FRACCIONADAS
    })
    try {
      const datosFinanzasDiscente = getState().pagos.datosFinanzasDiscente.todo;
      if(!Object.keys(datosFinanzasDiscente).length) {
        await dispatch(obtenerMatriculasDiscenteActivarMatricula(tipo, valor, matricula));
      }
      const respuesta = await authAxios.get(`/admin/matricula/pago/${idPension}`); 
  
      if(!respuesta.data.ok || !respuesta.data.datos) {
        dispatch({
          type: pagosTypes.OBTENER_CUOTAS_FRACCIONADAS_ERROR,
          payload: 'No se encontraron resultados' 
        })
        return;
      }

      dispatch({
        type: pagosTypes.OBTENER_CUOTAS_FRACCIONADAS_EXITO,
        payload: respuesta.data.datos
      })
    } catch (error) {
      console.log(error);
      dispatch({
        type: pagosTypes.OBTENER_CUOTAS_FRACCIONADAS_ERROR,
        payload: 'Se ha producido un error' 
      })
    }
  }
}

export const insertarCuotaFraccionada = (datos) => {
  return async dispatch => {
    avisoCargando('Guardando...')
    dispatch({
      type: pagosTypes.INSERTAR_CUOTA_FRACCIONADA
    })
    try {
      const respuesta = await authAxios.post(`/admin/matricula/pago/fraccionar`, datos);

      dispatch({
        type: pagosTypes.INSERTAR_CUOTA_FRACCIONADA_EXITO,
        payload: respuesta.data.datos
      })
      avisoCorrecto('Cuota fracción ingresada correctamente.');
      dispatch(updateStateModalBasic());
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg)
      dispatch({
        type: pagosTypes.INSERTAR_CUOTA_FRACCIONADA_ERROR,
        payload: 'Se ha producido un error al insertar' 
      })
    }
  }
}

const actualizandoEstadoTablaCuotas = (datos, idPension) => {
  return (dispatch, getState) => {
    const matriculaActiva = getState().pagos.datosFinanzasDiscente.matriculaActiva;
    if(Object.keys(matriculaActiva).length === 0) return;
    dispatch({
      type: pagosTypes.ACTUALIZADO_ESTADOS_TABLA_CUOTAS,
      payload: {
        idMatricula: matriculaActiva.id_matricula,
        idPension,
        datos
      }
    })
  }
}


export const cambiarEstadoPensionFraccion = (idPension, modalidad) => {
  return async dispatch => {

    const titulo = modalidad !== '2' ? '¿Estas seguro de fraccionar este pago?' : '¿Estas seguro de desfraccionar este pago?';
    const tituloExito = modalidad !== '2' ? 'Cuota fraccionada' : 'Cuota desfraccionada';

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: titulo,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              const respuesta = await authAxios.put(`/admin/matricula/pago/${idPension}/tipo_cuota `)
              if(respuesta.data.ok) {
                dispatch({
                    type: pagosTypes.ACTUALIZAR_TIPO_PENSION,
                    payload: respuesta.data.data
                })
                dispatch(actualizandoEstadoTablaCuotas(respuesta.data.data, idPension));
                return;
              }
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: respuesta.data.msg
              })
              return true;
          } catch (error) {
              console.log(error)
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Hubo un error inesperado"
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              tituloExito,
              "",
              "success"
          );
          }
      });
  }
}

export const obtenerIDBoletaSiguiente = (serie) => {
  return async dispatch => {
    dispatch({
      type: pagosTypes.OBTENER_ID_BOLETA_SIGUIENTE
    })
    try {
      if(!serie) {
        dispatch({
          type: pagosTypes.OBTENER_ID_BOLETA_SIGUIENTE_EXITO,
          payload: ''
        })
        return;
      }
      const respuesta = await authAxios.get(`/admin/matricula/numero_documento/${serie}`);
      dispatch({
        type: pagosTypes.OBTENER_ID_BOLETA_SIGUIENTE_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      dispatch({
        type: pagosTypes.OBTENER_ID_BOLETA_SIGUIENTE_ERROR,
      })
      console.log('Hubo un error al traer el id del pago')
    }
  }
}

export const pagarPensionFraccion = (datos, idMatricula) => {
  return async (dispatch, getState) => {
    dispatch({
      type: pagosTypes.PAGAR_CUOTA
    })
    try {
      avisoCargando('Pagando...')
      delete datos.fecha_pago;
      delete datos.id_tp_medio_serie;
      delete datos.dni_ruc;

      const modalidad = getState().pagos.datosFinanzasDiscente.detallePago.modalidad;
      const idModalidad = modalidad === 'fraccion' ? '2' : '1';
      datos.id_tp_cuota = idModalidad;
      datos.monto_pagado = '0';

      const respuesta = await authAxios.post('/admin/matricula/pagos/registro', datos);
    
      dispatch({
        type: pagosTypes.PAGAR_CUOTA_EXITO,
        payload: {
          idMatricula,
          datos: respuesta.data.datos
        }
      })
      dispatch(updateStateModalCustom());
      avisoCorrecto('Operación realizado correctamente');
    } catch (error) {
      console.log(error);
      dispatch({
        type: pagosTypes.PAGAR_CUOTA_ERROR
      })
      avisoError(error.response.data.msg);
    }
  }
}

export const registrarDetallesPagos = (modalidad, idPensionFraccion) => {
  return async dispatch => {
    dispatch({
      type: pagosTypes.REGISTRAR_DETALLES_PAGOS,
      payload: {
        modalidad,
        idPensionFraccion
      }
    })
  }
}

export const registrarPagoExtraordinario = datos => {
  return async (dispatch, getState) => {
    avisoCargando('Guardando...')
    dispatch({
      type: pagosTypes.REGISTRAR_PAGO_EXTRAORDINARIO
    })
    try {
      const { id_matricula: idMatricula } = getState().pagos.datosFinanzasDiscente.matriculaActiva;
      const respuesta = await authAxios.post('/admin/matricula/pago/extraordinario', datos);
    
      dispatch({
        type: pagosTypes.REGISTRAR_PAGO_EXTRAORDINARIO_EXITO,
        payload: {
          idMatricula,
          datos: respuesta.data.data
        }
      })
      dispatch(updateStateModalBasic());
      avisoCorrecto('Nuevo pago extraordinario insertado correctamente');
    } catch (error) {
      console.log(error);
      mostrarErrorApi(error);
      dispatch({
        type: pagosTypes.REGISTRAR_PAGO_EXTRAORDINARIO_ERROR
      })
    }
  }
}

export const pagarCuotaExtra = (datos, idMatricula) => {
  return async dispatch => {
    avisoCargando('Guardando...');
    dispatch({
      type: pagosTypes.PAGAR_CUOTA_EXTRA,
    })
    try {
      delete datos.fecha_pago;
      delete datos.id_tp_medio_serie;
      delete datos.dni_ruc;

      datos.id_tp_cuota = '1';
      const respuesta = await authAxios.post('/admin/matricula/pagos/registros/extraordinario', datos);
      dispatch({
        type: pagosTypes.PAGAR_CUOTA_EXTRA_EXITO,
        payload: {
          idMatricula,
          datos: respuesta.data.data
        }
      })
      avisoCorrecto('Pago registrado correctamente');
      dispatch(updateStateModalCustom());
    } catch (error) {
      mostrarErrorApi(error);
      dispatch({
        type: pagosTypes.PAGAR_CUOTA_EXTRA_ERROR
      })
    }
  }
}

export const eliminarCuotaFraccion = (idFraccion) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Esta seguro que desea eliminar esta cuota?',
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/matricula/pago/fraccion/${idFraccion}`)
              dispatch({
                  type: pagosTypes.ELIMINAR_CUOTA_FRACCION_EXITO,
                  payload: {
                    idFraccion
                  }
              })
              return true;
          } catch (error) {
              console.log(error)
              Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Cuota eliminada correctamente",
              "",
              "success"
          );
          }
      });
  }
}

export const eliminarCuotaExtra = (idMatricula, idPagoExtra) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Esta seguro que desea eliminar esta cuota?',
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              await authAxios.delete(`/admin/matricula/pago/extraordinario/${idPagoExtra}`)
              dispatch({
                  type: pagosTypes.ELIMINAR_CUOTA_EXTRA_EXITO,
                  payload: {
                    idPagoExtra,
                    idMatricula
                  }
              })
              return true;
          } catch (error) {
              console.log(error)
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Cuota eliminada correctamente",
              "",
              "success"
          );
          }
      });
  }
}

export const anularPago = (datos) => {
  return async dispatch => {
    avisoCargando('Anulando...')
    try {
      const tipoAnulado = datos.tipo;
      const respuesta = await authAxios.put(`/admin/matricula/pagos/registro/anular`, datos);
      
      switch (tipoAnulado) {
        case 'extraordinario':
          dispatch({
            type: pagosTypes.ANULAR_PAGO_EXTRA_EXITO,
            payload: {
              datos: respuesta.data.data
            }
          })
          break;
        case 'cuota':
        case 'fraccion':
          dispatch({
            type: pagosTypes.ANULAR_PAGO_ORDINARIO_EXITO,
            payload: {
              datos: respuesta.data.data
            }
          })
          break;
        default:
          break;
      }
      dispatch(updateStateModalBasic());
      avisoCorrecto('Pago anulado')
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: pagosTypes.ANULAR_PAGO_ERROR
      })
    }
  }
}

export const nuevoDescuentoCargos = (datos) => {
  return async dispatch => {
    dispatch({
      type: pagosTypes.NUEVO_DESCUENTO_CARGO
    })
    try {
      delete datos['motivoLlave'];
      const respuesta = await authAxios.post('/admin/matricula/pago/adicion', datos);
      dispatch({
        type: pagosTypes.NUEVO_DESCUENTO_CARGO_EXITO,
        payload: {
          datos: respuesta.data.data 
        }
      })
      dispatch(updateStateModalBasic());
      avisoCorrecto('Registrado correctamente');
    } catch (error) {
      response.error(error, dispatch, pagosTypes.NUEVO_DESCUENTO_CARGO_ERROR)
    }
  }
}

export const eliminarDescuentoCargo = (idCargoDescuento) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: '¿Esta seguro que desea seguir?',
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
              const respuesta = await authAxios.delete(`/admin/matricula/pago/adicion/${idCargoDescuento}`)
              dispatch({
                  type: pagosTypes.ELIMINAR_DESCUENTO_CARGO_EXITO,
                  payload: {
                    datos: respuesta.data.data
                  }
              })
              return true;
          } catch (error) {
              console.log(error)
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado correctamente",
              "",
              "success"
          );
          }
      });
  }
}

export const obtenerMotivosCargosDescuentos = () => {
  return async dispatch => {
    dispatch({
      type: pagosTypes.OBTENER_MOTIVOS_DESCUENTOS
    })
    try {
      const respuesta = await authAxios.get('/admin/motivos/descuentos_cargos');
      dispatch({
        type: pagosTypes.OBTENER_MOTIVOS_DESCUENTOS_EXITO,
        payload: respuesta.data.data
      })
    } catch (error) {
      response.error(error, dispatch, pagosTypes.OBTENER_MOTIVOS_DESCUENTOS_ERROR);
    }
  }
}

export const obtenerConceptosPagos = () => {
  return async dispatch => {
    dispatch({
      type: pagosTypes.OBTENER_CONCEPTOS_PAGOS
    })
    try {
      const respuesta = await authAxios.get('/admin/matricula/conceptos');
      dispatch({
        type: pagosTypes.OBTENER_CONCEPTOS_PAGOS_EXITO,
        payload: respuesta.data.data
      })
    } catch (error) {
      response.error(error, dispatch, pagosTypes.OBTENER_CONCEPTOS_PAGOS_ERROR);
    }
  }
}
