import React from 'react';
import './ListaCardControlCapacitaciones.scss';
//Redux
import { useSelector } from 'react-redux';
import CardControlCapacitacion from './CardControlCapacitaiones';
import { Row, Col } from 'react-bootstrap';

const ListaCardControlCapacitacion = () => {

  const { todos: capacitaciones } = useSelector(state => state.control_capacitacion.capacitaciones)

  return ( 
    <div className="lista-capacitacion">
      <Row>
        {capacitaciones.map(capacitacion => (
          <Col key={capacitacion.id_proyecto} lg={3} md={4} sm={6} xs={12}>
            <CardControlCapacitacion
              capacitacion={capacitacion} 
            />
          </Col>
        ))}
      </Row>
      
    </div>
   );
}
 
export default ListaCardControlCapacitacion;