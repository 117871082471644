import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {
  REQUIRED,
  TYPENUMBER,
  TYPE_NUMBER_INT,
  TYPE_NUMBER_POSITIVE,
  TYPE_DATE,
} from "../../../../helpers/validationForms";

import { useDispatch, useSelector } from 'react-redux';
import { editarModulo, insertarModulo } from "../../../../actions/modulosActions";

const FormularioModulo = ({ datosModulo, onCloseModal }) => {
  const dispatch = useDispatch();
  const { moduloLoading } = useSelector(state => state.modulos)

  const [datosForm,setDatosForm] = useState(initialValues)

  const handleChange = (e) =>{
    setDatosForm({
      ...datosForm,
      [e.target.name]:e.target.value
    })
  }

  const handleCheckBox = (e) =>{
    
    setDatosForm({
      ...datosForm,
      mostrar_calificacion:!datosForm.mostrar_calificacion
    })
   // console.log(datosForm.mostrar_calificacion)
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    //console.log(datosForm)
    if(datosModulo) {
      dispatch(editarModulo(datosModulo.id_modulo, datosForm, onCloseModal));
    } else {
      dispatch(insertarModulo(datosForm, onCloseModal));
    }
  }

  useEffect(()=>{
    if(datosModulo)
    {
     // console.log(datosModulo)
      setDatosForm({
        nombre_modulo: datosModulo.nombre_modulo,
        cantidad_lapso: datosModulo.cantidad_lapso,
        id_tp_lapso: datosModulo.id_tp_lapso,
        fecha_inicio_modulo: datosModulo.fecha_inicio_modulo,
        fecha_fin_modulo: datosModulo.fecha_fin_modulo,
        id_tp_programa: 2,
        mostrar_calificacion:datosModulo.mostrar_calificacion!==0?true:false,
      })
    }
    else
    {
      setDatosForm(initialValues)
    }
  },[datosModulo])

  /*const formik = useFormik({
    initialValues: datosModulo || initialValues(),
    validationSchema: yup.object(validationSchema()),
    onSubmit: (valores) => {
      if(datosModulo) {
        dispatch(editarModulo(datosModulo.id_modulo, valores, onCloseModal));
      } else {
        dispatch(insertarModulo(valores, onCloseModal));
      }
    },
  });*/


  return (
    <div className="FormularioModulo">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label htmlFor="nombre_modulo" column md="4">
              Nombre de Sub-Producto
            </Form.Label>
            <Col md={8}>
              <Form.Control
                type="text"
                id="nombre_modulo"
                name="nombre_modulo"
                value={datosForm.nombre_modulo}
                onChange={handleChange}
              
              />
             
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={4}>
              Duración
            </Form.Label>
            <Col md={8}>
              <Col md={6} className="px-0">
                <Form.Control
                  type="text"
                  className="mr-2"
                  name="cantidad_lapso"
                  value={datosForm.cantidad_lapso}
                  onChange={handleChange}
            
                />
        
              </Col>
              <Col md={6} className="pr-0">
                <Form.Control
                  as="select"
                  name="id_tp_lapso"
                  value={datosForm.id_tp_lapso}
                  onChange={handleChange}
               
                >
                  <option value="" disabled>
                    --Seleccione--
                  </option>
                  <option value="1">DIAS</option>
                  <option value="2">MESES</option>
                </Form.Control>
              
              </Col>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={4}>
              Fecha inicio
            </Form.Label>
            <Col md={8}>
              <Form.Control
                type="date"
                name="fecha_inicio_modulo"
                value={datosForm.fecha_inicio_modulo}
                onChange={handleChange}
         
              />
            
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={4}>
              Fecha culminación
            </Form.Label>
            <Col md={8}>
              <Form.Control
                type="date"
                name="fecha_fin_modulo"
                value={datosForm.fecha_fin_modulo}
                onChange={handleChange}
          
              />
            
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column md={4}>
              Mostrar Calificaciones
            </Form.Label>
            <Col md={8}>
              <input
                type="checkbox"
                name="mostrarCalf"
                style={{marginTop:'8px'}}
                value={datosForm.mostrar_calificacion}
                checked={datosForm.mostrar_calificacion}
                onChange={(e)=> handleCheckBox(e)}
              
              />
             
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant='dark'
            onClick={onCloseModal}
            disabled={moduloLoading}
          >
            Cancelar
          </Button>
          <Button
        
            type="submit"
            className='btn-spinner'
            variant="info"
            disabled={moduloLoading}
          >
            { moduloLoading && <Spinner as='span' animation='border' size='sm'  /> }
            { datosModulo ? 'Guardar cambios' : 'Crear módulo'}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
 
  );
};

export default FormularioModulo;

const initialValues = () => ({
  nombre_modulo: "",
  cantidad_lapso: "",
  id_tp_lapso: '',
  fecha_inicio_modulo: "",
  fecha_fin_modulo: "",
  id_tp_programa: 2,
  mostrar_calificacion:false,
})

const validationSchema = () => ({
  nombre_modulo: yup.string().required(REQUIRED),
  cantidad_lapso: yup
    .number()
    .required(REQUIRED)
    .typeError(TYPENUMBER)
    .integer(TYPE_NUMBER_INT)
    .positive(TYPE_NUMBER_POSITIVE),
  id_tp_lapso: yup
    .string()
    .required(REQUIRED),
  fecha_inicio_modulo: yup
    .date()
    .required(REQUIRED)
    .typeError(TYPE_DATE),
  fecha_fin_modulo: yup
    .date()
    .required(REQUIRED)
    .typeError(TYPE_DATE),
});
