import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import ResultadoReporteMatriculas from '../../components/Matriculas/ResultadoReporteMatriculas';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading';
import HeaderContent from '../General/HeaderContent';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../General/Breadcrumb';
import FormularioFiltroMatricula from '../../components/Matriculas/FormularioFiltroMatricula.js';
import Fatal from '../../components/MessageAlert/Fatal';
import { limpiarResultadoMatriculas } from '../../../actions/matriculasActions';

const Matriculas = () => {

  const [datosBusqueda, setDatosBusqueda] = useState({});
  const { loading, error, matriculas } = useSelector(state => state.matriculas);
  const { usuario } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  const mostrarContenido = () => {
    if(loading) return <SpinnerLoading />
    if(error) return <Fatal message={error} />
    if(!!matriculas.length) {
      return <ResultadoReporteMatriculas
      matriculas={matriculas}
      datosBusqueda={datosBusqueda}
      />
    }
  }

  useEffect(() => {
    dispatch(limpiarResultadoMatriculas());
  }, [])

  return ( 
    <Layout>
      <div className="Discentes">
        <div className="Matriculas__head">
          <HeaderContent title={usuario.id_tp_nivel_usuario==='5'? 'Discentes':'Discentes Matriculados'}   subItem={usuario.id_tp_nivel_usuario==='5'? '¡Tu sistema solar es CLIENTECÉNTRICO!':null}  />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "/reporte-matriculas", nombre: "Discentes matriculados" },
            ]}
          />
        </div>
        <div className="Matriculas__body">
          <div className="box">
            <div className="box-body">
              <FormularioFiltroMatricula 
                setValores={setDatosBusqueda}
                loading={loading}
              />
            </div>
          </div>
          {mostrarContenido()}
        </div>
      </div>
    </Layout>
  );
}
 
export default Matriculas;