import React, {useState, useEffect} from 'react';

//Redux
import { useDispatch } from 'react-redux';
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import { insertarCampaign } from '../../../actions/campaignsActions';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }

const FormularioCampaña = ({setComunicados}) => {

const [proyecto, SelectProyecto] = useSelectProyecto(null)
const [fechaInicio, setFechaInicio]=useState(null)
const [fechaFin, setFechaFin]=useState(null)

  const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    e.preventDefault();
    const datosCamp={
      producto: proyecto?proyecto.id_proyecto:null,
      fechaInicio: fechaInicio ? moment(fechaInicio).format('YYYY-MM-DD') : null,
      fechaFin: fechaFin ? moment(fechaFin).format('YYYY-MM-DD') : null,
    }
    const retornarListaActualizada = async () =>{
     await (dispatch(insertarCampaign(datosCamp)))
    }
  
    
  // console.log(datosPop)
  retornarListaActualizada()
  }

 

 return (
 
                  <form id="formNuevo2" className="px-md-5 py-3" onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Producto:
                      </label>
                    </div>
                    <div className="col-md-7">
                        <SelectProyecto/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha inicio
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fechaInicio}
                        onChange={(date) =>setFechaInicio(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaFiltro"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="enlace" className="mb-0">
                        Fecha Fin
                      </label>
                    </div>
                    <div className="col-md-7">
                      <DatePicker
                        selected={fechaFin}
                        onChange={(date) =>setFechaFin(date)}
                        
                        dateFormat="dd/MM/yyyy"
                        
                        name="fechaFiltro"
                      // startDate={formik.values.fechaInicio}
                        className="form-control"
                      
                      // endDate={formik.values.fechaFin}
                      />
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-floppy-o"></i> Guardar
                  </button>
                </form>
  
    );
};

export default FormularioCampaña;
