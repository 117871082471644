import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editor = (props) => {

  const {setContenido, dataDefault=''} = props

  return ( 
    <div className="Editor">
        <CKEditor
        style={{height: "200px"}}
          editor={ClassicEditor}
          data={dataDefault}
          config={{
                removePlugins:['Image','ImageCaption','ImageStyle','ImageTextAlternative','ImageToolbar','ImageUpload','AutoImage'],
                         link:{
                      addTargetToExternalLinks: true
                  }
          }}
          onInit={editor => {
            // You can store the "editor" and use when it is needed.
            setContenido(dataDefault)
            //console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
           setContenido(data)
            //console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            // console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            // console.log("Focus.", editor);
          }}
        />
      </div>
   );
}
 
export default Editor;