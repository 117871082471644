import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { listarProyectos } from '../actions/cursosActions';

const useSelectProyecto = props => {

  const dispatch = useDispatch(); 
  const proyectos = useSelector(state => state.cursos.proyectos)
  const selectAll ={
    id_proyecto:0,
    nombre_proyecto:"Seleccionar Todos ..."
  }
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null); 

    const handleChange = proyectoSeleccionado =>{
      const found = proyectoSeleccionado ? proyectoSeleccionado.find(proyecto=> proyecto.id_proyecto===0) : undefined
      if(found !== undefined && proyectoSeleccionado && found.id_proyecto===0)
      {
        setProyectoSeleccionado(proyectos);
      }
      else
      {
        setProyectoSeleccionado(proyectoSeleccionado);
      }
    }

  useEffect(() => {
    if(!proyectos.length) {
      dispatch(listarProyectos())
    }
  }, [])

  const SimpleSelect = props => {
    return (
      <Select
        isMulti
        isDisabled={false}
        isClearable={true}
        isRtl={false}
        isLoading={!proyectos.length}
        loadingMessage={() => 'Cargando...'}
        isSearchable={true}
        onChange={handleChange}
        value={proyectoSeleccionado}
        getOptionValue={proyectos => proyectos.id_proyecto}
        getOptionLabel={proyectos => proyectos.nombre_proyecto}
        options={props.selectAll ? [selectAll, ...proyectos]: proyectos}
        placeholder='--Seleccione--'
        noOptionsMessage={() => 'No hay resultados'}
        {...props}
      />
    )
  }

  return [proyectoSeleccionado, SimpleSelect, setProyectoSeleccionado]

}
 
export default useSelectProyecto;