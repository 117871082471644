import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TablaCuotasVariables from "./TablaCargosVariables";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateStateModalBasic } from "../../../../actions/modalActions";
import FormularioDescuentoCargos from "../FormularioDescuentoCargos";

const CuotasVariables = ({ estadoCuota, nuevo = true }) => {
  const dispatch = useDispatch();
  const { cargos_variables = [] } = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones.todo);

  const handleModal = () => {
    dispatch(
      updateStateModalBasic(
        <FormularioDescuentoCargos
          id_tp_adicional={2}
          tipoRegistro="cargos"
        />,
        "Nuevo cargo"
      )
    );
  };

  return (
    <div className="CuotasVariables">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="title-head">Cargos Variables</h4>
        {
          nuevo && (
            <span
              className={`btn-span btn-icon-scope ${
                estadoCuota === "PAGADO" ? "error" : "auto"
              }`}
              onClick={handleModal}
            >
              <FontAwesomeIcon icon={faPlus} />
              Nuevo
            </span>
          )
        }
      </div>
      <div className="box">
        <div className="box-body">
          <TablaCuotasVariables
            cargos_variables={cargos_variables}
          />
        </div>
      </div>
    </div>
  );
};

export default CuotasVariables;
