import React from 'react';
import PropTypes from 'prop-types';
import EditarHonorariosContainer from '../../../../../containers/DocentesContainers/EditarHonorariosContainer';
import './Honorarios.scss';
//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalCustom } from '../../../../../actions/modalActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faEdit, faTable } from '@fortawesome/free-solid-svg-icons';

const Honorarios = ({ pago_hora, pago_mes,n_cuenta,cci,banco, handleEditar }) => {

  const dispatch = useDispatch();

  return ( 
    <div className="Honorarios">
      <div className="Honorarios__container">
        <div className="Honorarios__head d-flex align-items-center justify-content-between border-bottom p-3">
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faDollarSign} className='mr-1' />
            <h5>Honorarios</h5>
          </div>
          <div className='d-flex align-items-center cursor-pointer'>
            <FontAwesomeIcon icon={faEdit} className='mr-1' />
            <span onClick={() => handleEditar()}>Editar</span>
          </div>
        </div>
        <div className="Honorarios__body p-3 my-3">
          <div className="Honorarios__list">
            <div className="Honorarios__item d-flex align-items-center">
              <FontAwesomeIcon icon={faTable} className='mr-1' />
              <span>Banco</span>
              <span className='font-weight-bold ml-4'>{banco}</span>
            </div>
            <div className="Honorarios__item mt-3 d-flex align-items-center">
              <FontAwesomeIcon icon={faTable} className='mr-1' />
              <span>N° Cuenta</span>
              <span className='font-weight-bold ml-4'>{n_cuenta}</span>
            </div>
            <div className="Honorarios__item mt-3 d-flex align-items-center">
              <FontAwesomeIcon icon={faTable} className='mr-1' />
              <span>CCI</span>
              <span className='font-weight-bold ml-4'>{cci}</span>
            </div>
            <div className="Honorarios__item mt-3 d-flex align-items-center">
              <FontAwesomeIcon icon={faTable} className='mr-1' />
              <span>Por hora</span>
              <span className='font-weight-bold ml-4'>{pago_hora}</span>
            </div>
            <div className="Honorarios__item mt-3 d-flex align-items-center">
              <FontAwesomeIcon icon={faTable} className='mr-1' />
              <span>Por mes</span>
              <span className='font-weight-bold ml-4'>{pago_mes}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}

Honorarios.propTypes = {
  pago_hora: PropTypes.string.isRequired,
  pago_mes: PropTypes.string.isRequired,
  handleEditar: PropTypes.func.isRequired
}
 
export default Honorarios;