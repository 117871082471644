import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import SimpleSelectwithConfig from '../../../../../containers/SimpleSelectwithConfig';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TemasDisponibles = ({ temasArea=[], labelDefinido, valueDefinido, handleAsignarTemas }) => {
  
  const [temaSeleccionado, setTemaSeleccionado] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    handleAsignarTemas(temaSeleccionado.value)
  }

  return ( 
    <div className="TemasDisponibles">
      <div className="TemasDisponieble__container">
        <Form
          onSubmit={handleSubmit}
          className='TemasDisponible__form'
        >
          <Form.Group>
            <Form.Label>Temas disponibles para selección:</Form.Label>
            <SimpleSelectwithConfig 
              options={temasArea}
              setLabelOptions={labelDefinido}
              setValuesOption={valueDefinido}
              getSelection={setTemaSeleccionado}
            />
          </Form.Group>
          <Form.Group className='text-right'>
            <Button 
              type='submit'
              variant='info'
              className='text-white btn-icon'
              disabled={temaSeleccionado === null ? true : false}
            >
              <FontAwesomeIcon icon={faPlus} />
              Agregar
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
   );
}

TemasDisponibles.propTypes = {
  temasArea: PropTypes.array.isRequired,
  handleAsignarTemas: PropTypes.func
}
 
export default TemasDisponibles;