import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import * as moment from 'moment';
import { getColorEstadoPago } from '../../../../helpers/functions';

const TablaDatosCuotaFraccion = ({ monto_establecido, fecha_limite, fecha_pago, nombre_tp_estado_pago }) => {

  return (
    <div className="TablaDatosCuotaFraccion">
      <Table striped responsive className='w-100'>
        <thead>
            <tr>
              <th className='text-center'>#</th>
              <th className='text-center'>Monto establecido (S/)</th>
              <th className='text-center'>Fecha límite</th>
              <th className='text-center'>Fecha de pago</th>
              <th className='text-center'>Estado</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">1</td>
            <td className='text-center'>{monto_establecido}</td>
            <td className="text-center">
              {fecha_limite ? (
                moment(fecha_limite).format("DD MMM YYYY")
              ) : (
                <p className="mb-0 text-center">---</p>
              )}
            </td>
            <td className="text-center">
              {fecha_pago ? (
                moment(fecha_pago).format("DD MMM YYYY")
              ) : (
                <p className="mb-0 text-center">---</p>
              )}
            </td>
            <td className="text-center">
              <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`} >
                {nombre_tp_estado_pago}
              </Badge>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default TablaDatosCuotaFraccion;