import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { filtrarWebinars, listarWebinars } from '../../../actions/campaignsActions';
import { updateStateModalLarge } from '../../../actions/modalActions';
import { campaigntypes } from '../../../actions/types';
import useSelectCampaigns from '../../../hooks/useSelectCampaigns';
import ComentarioWebinar from './ComentarioWebinar';

//import * as moment from 'moment';
//import { Button } from 'react-bootstrap';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioFiltro = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const [campaign, SelectCampaigns] = useSelectCampaigns(null)
const {resumen_webinar} = useSelector(state=>state.campaigns)
/*
const handleUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/toky/${usuario.id_tp_nivel_usuario}/${asesor?asesor.id_usuario:null}/${proyecto?proyecto.id_proyecto:null}/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}/${estado?estado.id_estado:null}`;
}
const handleMailUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/mailchimp/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}`;
}*/

const handleComentario = (tipo)=>{
  const queComentar = {
    conclusion_mkt: () => dispatch(updateStateModalLarge(<ComentarioWebinar campaign={resumen_webinar.id_p} tipo={tipo} />, 'Conclusión MKT')),
    conclusion_comercial: () => dispatch(updateStateModalLarge(<ComentarioWebinar campaign={resumen_webinar.id_p} tipo={tipo} />, 'Conclusión Comercial')),
    sugerencia_mkt: () => dispatch(updateStateModalLarge(<ComentarioWebinar campaign={resumen_webinar.id_p} tipo={tipo} />, 'Sugerencia MKT')),
    
  }

  if(queComentar[tipo])
  {
    queComentar[tipo]()
  }
}

const traerDatos = () =>{
  if(!campaign)
  {
    dispatch(listarWebinars())
  }
  else
  {

      const datosFiltro = 
      {
        proyecto: campaign ? campaign : null,
      }

     // console.log(datosFiltro)

      dispatch(filtrarWebinars(datosFiltro))
  }
}

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
      <div className="row p-2">
        <div className="col-7 mb-2">
          <label htmlFor="">Campaña:</label>
          <SelectCampaigns/>
        </div>
        <div className="col-12 pt-4">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        { resumen_webinar.id_p ? usuario.id_tp_nivel_usuario==="13" ? <> <button className="btn btn-primary" onClick={()=>handleComentario('conclusion_comercial')} > Conclusión Comercial </button> </> : usuario.id_tp_nivel_usuario==="12" ? <><button className="btn btn-primary" onClick={()=>handleComentario('conclusion_mkt')} > Conclusión MKT </button> <button className="btn btn-primary" onClick={()=>handleComentario('sugerencia_mkt')} > Sugerencia MKT </button> </> : '' : '' }
        </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltro;
