
import React from 'react';
import Pregunta from './PreguntaExamen';

const listarPreguntasExamen = ({ preguntas, handleChange }) => {

  const mostrarPreguntas = () => {
    if(preguntas.length > 0) {
      return preguntas.map(pregunta => (
          <Pregunta 
          key={pregunta.pregunta.id_pregunta}
          handleChange={handleChange}
          {...pregunta}
        />
      ))
    } else {
      return <p>No hay Preguntas registradas</p>
    }
  }

  return ( 
    <>
      {mostrarPreguntas()}
    </>
  );
}
 
export default listarPreguntasExamen;