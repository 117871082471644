import { matriculasTypes } from "../actions/types";

const initialState = {
  matriculas: [],
  matricula: null,
  loading: false,
  loadingEstado: false, 
  error: null,
  errorEstado: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case matriculasTypes.NUEVA_MATRICULA:
    case matriculasTypes.OBTENER_TODAS_MATRICULAS:
    case matriculasTypes.OBTENER_MATRICULA_DISCENTE:  
      return {
        ...state,
        loading: true,
        error: null
      }
    case matriculasTypes.NUEVA_MATRICULA_EXITO:
      return {
        ...state,
        loading: false,
        error: null
      }
    case matriculasTypes.NUEVA_MATRICULA_ERROR:
    case matriculasTypes.OBTENER_TODAS_MATRICULAS_ERROR:
    case matriculasTypes.OBTENER_MATRICULA_DISCENTE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case matriculasTypes.OBTENER_MATRICULA_DISCENTE_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        matricula: action.payload
      }
    case matriculasTypes.OBTENER_TODAS_MATRICULAS_EXITO:
      return {
        ...state,
        loading: false,
        error: null,
        matriculas: action.payload
      }
    case matriculasTypes.LIMPIAR_RESULTADO_MATRICULAS:
      return {
        ...state,
        loading: false,
        error: null,
        matriculas: []
      };
    case matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE:
      return {
        ...state,
        loadingEstado: true,
        errorEstado: null
      }
    case matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE_ERROR:
      return {
        ...state,
        loadingEstado: false,
        errorEstado: action.payload
      }
    case matriculasTypes.EDITAR_ESTADO_MATRICULA_DISCENTE_EXITO:
      return {
        ...state,
        loadingEstado: false,
        errorEstado: null
      }
    case matriculasTypes.ELIMINAR_MATRICULA_EXITO:
      return {
        ...state,
        matriculas: [...state.matriculas].filter(matricula => matricula.id_matricula !== action.payload.idMatricula)
      }
    default:
      return state;
  }
}