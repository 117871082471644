import {comunicadostypes} from '../actions/types'

const initialState = {
    comunicados: [],
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case comunicadostypes.LISTAR_COMUNICADOS_EXITO:
            return{
                ...state,
                comunicados: action.payload,
                loading:false,
            }
        case comunicadostypes.LISTAR_COMUNICADOS:
                return{
                    ...state,
                    loading:true,
                }
        default:
            return state
    }
} 