import React, {useState} from 'react';
import Layout from '../../../app/Layout';
import HeaderContent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import MaterialTableDemo from '../../components/plugins/MaterialTableDemo';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
//Redux
import {connect} from 'react-redux'
import {listarTareaEstudiantes} from '../../../actions/tareasActions'

const TareasEstudiantes = (props) => {

  const { idTarea } = useParams();
  //State props
  const {tareasEstudiantes} = props
  //funcions props
  const {listarTareaEstudiantes} = props

    const [dataTareas, setDataTareas] = useState([])

    const llenarDataTareas = () => {
      let dataGenerado = []
      if(tareasEstudiantes.tareas_discente === undefined){
        return 
      }       

      tareasEstudiantes.tareas_discente.map(tarea => {
        dataGenerado = [...dataGenerado, {
          id: tarea.id_tareas_estudiante,
          id_tarea_dis: tarea.id_tareas_estudiante,
          discente: `${tarea.nombre_estudiante} ${tarea.apellido_estudiante}`,
          fecha_presentado: tarea.fecha_tarea_presentada,
          hora_presentado: tarea.hora_tarea_presentada,
          button: <>
            <a
              href={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/${tarea.ruta_tarea_est}`}
              target='__black'
              className='btn btn-sm btn-warning'
            >
              <i className='fas fa-eye'></i>
            </a>
          </>
        }]
      })

      setDataTareas(dataGenerado)
    }

    useEffect(() => {
      listarTareaEstudiantes(idTarea)
    }, [])

    useEffect(() => {
      if(tareasEstudiantes){
        llenarDataTareas()
      }
      
    }, [tareasEstudiantes])

    return ( 
        <Layout>
            <HeaderContent
                title='Tareas'
            />

        <Breadcrumb
            link={[
            {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
            {url:'/recursos', nombre: 'Recursos'},
            {url:'', nombre:'Tareas'}
            ]}
        />

        <div className="clearfix">

            <div className="p-0 mb-5 bg-white">
            <div className="continer-fluid border-bottom-0 border">
                <h5
                className="p-3 d-flex align-items-center mb-0"
                style={{ color: "#434343" }}
                >
                <i className="fas fa-list pr-2"></i>Lista de Sesiones
                </h5>
            </div>
            <div className="tabla border">
                
                <MaterialTableDemo
                    colums={colums}
                    tabla={dataTareas}
                />
               
            </div>
            </div>
        </div>
        </Layout>
     );
}
 
const mapStateToProps = state => ({
  tareasEstudiantes: state.tareas.tareasEstudiantes
})

const mapDispatchToProps = {
  listarTareaEstudiantes
}

export default connect(mapStateToProps, mapDispatchToProps)(TareasEstudiantes);

const colums = [
    {
      text: "ID",
      key: "id_tarea_dis",
      align: "left",
      sortable: true,
      className: "type_of_food"
    },
    {
      text: "Discente",
      key: "discente",
      align: "left",
      sortable: true,
      className: "type_of_food"
    },
    {
      text: "Fecha presentado",
      key: "fecha_presentado",
      align: "left",
      sortable: true,
      className: "type_of_food"
    },
    {
      text: "Hora presentado",
      key: "hora_presentado",
      align: "left",
      sortable: true,
      className: "type_of_food"
    },
    {
    text: "Acciones",
    key: "button",
    align: "left",
    sortable: true,
    className: "type_of_food"
  }
  ]
 