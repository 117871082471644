import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTableDemo from "../../plugins/MaterialTableDemo";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import "./CuotasDiscente.scss";
import { Table } from "react-bootstrap";
import FilaTablaPensiones from "./FilaTablaPensiones";
import { useSelector } from "react-redux";

const CuotasDiscenteMatricula = ({ tipo, valor }) => {
  const { matriculaActiva } = useSelector(
    (state) => state.pagos.datosFinanzasDiscente
  );
  const { cuotas: { ordinarios = [] } = {} } = matriculaActiva;

  const mostrarPensiones = () => {
    if (!ordinarios || !ordinarios.length) {
      return (
        <tr>
          <td colSpan="9" align="center">
            No Hay Cuotas para mostrar
          </td>
        </tr>
      );
    } else {
      return ordinarios.map((ordinario, index) => (
        <FilaTablaPensiones
          key={ordinario.id_dt_matricula}
          index={index}
          tipo={tipo}
          valor={valor}
          cuotaOrdinaria={ordinario}
        />
      ));
    }
  };

  return (
    <div className="CuotasDiscente">
      <div className="box">
        <div className="box-header with-border">
          <h5 className='title-head mb-0'>
            <FontAwesomeIcon icon={faMoneyCheck} />
            Pagos del discente
          </h5>
        </div>
        <div className="box-body">
          <Table hover striped responsive="md">
            <thead>
              <tr>
                <th>#</th>
                <th>Concepto</th>
                <th>Fecha límite</th>
                <th>Modalidad pago</th>
                <th>Subtotal(S/.)</th>
                <th>Total(S/.)</th>
                <th>Saldo(S/.)</th>
                <th>Estado</th>
                <th>Registrar</th>
              </tr>
            </thead>
            <tbody>{mostrarPensiones()}</tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

CuotasDiscenteMatricula.protTypes = {
  tipo: PropTypes.string,
  valor: PropTypes.string,
};

export default CuotasDiscenteMatricula;
