import React, { useEffect } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import './FormBusquedaDiscente.scss';
import { formularioBusquedaRegistro, obtenerMatriculasDiscente } from '../../../../actions/pagosActions';
import { useHistory, useLocation } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';

const FormBusquedaDiscente = () => {

  const stateForm = useSelector(state => state.pagos.datosFormulario);
  const datosDiscente = useSelector(state => state.pagos.datosFinanzasDiscente.todo);
  const loading = useSelector(state => state.pagos.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params  = new  URLSearchParams(location.search);
  const valorQ =  params.get("value");
  const tipoQ =  params.get("q")

  const { tipo, valor } = stateForm; 

  const handleChange = e => {
    dispatch(formularioBusquedaRegistro(e.target.name, e.target.value))
  }

  const handleSubmit = e => {
    e.preventDefault();

    if(tipo === '' || valor === '' || isNaN(valor)) {
      avisoError('Ambos parámetros son requeridos para la búsqueda');
      return;
    }
    history.push(`?q=${tipo}&value=${valor}`);
  }

  useEffect(() => {
    if(tipoQ && valorQ) {
      dispatch(formularioBusquedaRegistro('tipo', tipoQ));
      dispatch(formularioBusquedaRegistro('valor', valorQ));
      if(datosDiscente[tipoQ] !== valorQ) {
       dispatch(obtenerMatriculasDiscente(tipoQ, valorQ));
      }
    }
  }, [tipoQ, valorQ, datosDiscente, dispatch])

  return (
    <div className="FormBusquedaDiscente">
      <h4 className='title-head'>
        <FontAwesomeIcon icon={faSearch} />
        Buscador
      </h4>
      <div className="FormBusquedaDiscente__container box box-primary">
        <div className="box-body">
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Col sm={8}>
                <Form.Group as={Row}>
                  <Col md={4}>
                    <Form.Label>
                      Criterio:
                    </Form.Label>
                    <Form.Control
                      as='select'
                      value={tipo}
                      name='tipo'
                      onChange={handleChange}
                      disabled={loading}
                    >
                      <option value='' disabled>--Seleccione--</option>
                      <option value='dni'>DNI</option>
                    </Form.Control>
                  </Col>
                  <Col md={4}>
                    <Form.Label>
                      Valor:
                    </Form.Label>
                    <Form.Control
                      type='number'
                      maxLength="8"
                      name='valor'
                      value={valor}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={4} className='content-btn-submit'>
                <Button type='submit' className='btn-icon' disabled={loading} variant='primary'>
                  {
                    loading ? <> 
                        <Spinner size='sm' animation='border' className='mr-1' /> 
                        Buscando...
                      </> : <>
                        <FontAwesomeIcon icon={faSearch} />
                        Buscar
                      </>
                  }
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
 
export default FormBusquedaDiscente;