import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEdit, faPassport } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import './VistaDatosSesion.scss';

const VistaDatosSesion = ({ usuario, handleEditar }) => {

  return ( 
    <div className="VistaDatosSesion">
      <div className="VistaDatosSesion__container">
        <div className="VistaDatosSesion__head d-flex align-items-center justify-content-between border-bottom p-3">
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faUser} className='mr-1' />
            <h5>Datos sesión</h5>
          </div>
          <div className='d-flex align-items-center cursor-pointer'>
            <FontAwesomeIcon icon={faEdit} className='mr-1' />
            <span onClick={() => handleEditar()}>Editar</span>
          </div>
        </div>
        <div className="VistaDatosSesion__body p-3 my-3">
          <div className="VistaDatosSesion__list">
            <div className="VistaDatosSesion__item">
              <Row>
                <Col md={2}>
                  <FontAwesomeIcon icon={faUser} className='mr-1' />
                  <span className='font-weight-bold '>Usuario:</span>
                </Col>
                <Col md={10}>
                  <span>{usuario}</span>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={2}>
                  <FontAwesomeIcon icon={faPassport} className='mr-1' />
                  <span className='font-weight-bold '>Contraseña:</span>
                </Col>
                <Col md={10}>
                  <span>**************</span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}

VistaDatosSesion.propTypes = {
  usuario: PropTypes.string.isRequired,
  handleEditar: PropTypes.func
}
 
export default VistaDatosSesion;