import React from 'react';
import DropZone from '../../components/plugins/Dropzone';
import { useState } from 'react';

//Redux
import {connect} from 'react-redux'
import {insertarTarea} from '../../../actions/tareasActions'
import { avisoError, avisoCorrecto, avisoCargando } from '../../components/AvisosMensajes/MensajesSwalf';

const ModalInsertarTarea = (props) => {

  const {insertarTarea, idSesion, usuario} = props

    const [contenidoArchivo, setContenidoArchivo] = useState({})
    const [tarea, setTarea] = useState({
      nombre: ''
    })
    

    const {nombre} = tarea

    const onChange = e => {
      setTarea({
        [e.target.name]: e.target.value
      })
    }

    const submitForm =  async e => {
      e.preventDefault()
      if (nombre === '' || idSesion === '' || Object.keys(contenidoArchivo).length === 0) {
        avisoError('Todos los campos son obligatorio')
        return
      }

      avisoCargando('Guardando ...')
      const form = new FormData()

      form.append('nombre_tarea', nombre)
      form.append('id_sesion', idSesion)
      form.append('id_usuario', usuario.id_usuario)
      form.append('tarea_admin', contenidoArchivo)
      
      const {mensaje , textMensaje} = await insertarTarea(form)

      if (mensaje) {
        setTarea({
          nombre: ''
        })
        setContenidoArchivo({})

        avisoCorrecto(textMensaje)
        return
      }

      avisoError(textMensaje)
      
    }   

    return ( 
        <div className="recursos__modal-nuevo">
      <div
        className="modal fade"
        id="modal-tarea"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Nueva tarea
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={e => submitForm(e)} id='form-subir-recurso'>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    name='nombre'
                    value={nombre}
                    onChange={onChange}
                  />
                </div>
                
                <div className="form-group">
                
                  <>
                    <DropZone
                      getContenidoArchivo={setContenidoArchivo}
                    />
                    <div className="">
                      {contenidoArchivo !== undefined ? 
                        <p>
                          {contenidoArchivo.name}
                        </p>
                      :null}
                    </div>
                  </>
             
                </div>
                <div className="modal-footer mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                  <button type="submit" className="btn btn-primary">
                  <i className="fas fa-upload mr-1"></i>
                    Subir
                  </button>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
    </div>
     );
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  insertarTarea
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalInsertarTarea);