import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
//Redux
import { useDispatch, useSelector } from 'react-redux';

import './ContratoProveedor.scss';
import { eliminarContrato } from '../../../../actions/programacionesActions';
import { useParams } from 'react-router-dom';

const ContratoProveedor = ({ idProyecto }) => {
  
  const { idProgramacion } = useParams();
  const dispatch = useDispatch();
  const { contratoActivo } = useSelector(state => state.programaciones.programacion);

  const handleEliminarContrato = () => {
    const { idProveedorProyecto} = contratoActivo;
    dispatch(eliminarContrato(idProgramacion, idProyecto, idProveedorProyecto));
  }

  return (
    <div className="ContratoProveedor">
    <Modal.Body>
      <Row>
        <Col md={4}>
          <span>Título</span> <span>:</span>
        </Col>
        <Col md={8}>{contratoActivo.nombre}</Col>
        <Col md={4}>
          <span>Tipo</span><span>:</span>
        </Col>
        <Col md={8}>{contratoActivo.nombreProveedor}</Col>
        <Col md={4}>
          <span>Fecha de registro</span><span>:</span>
        </Col>
        <Col md={8}>{moment(contratoActivo.fecha).format('DD MMM YYYY')}</Col>
        <Col md={4}>
          <span>Contrato adjunto</span><span>:</span>
        </Col>
        <Col md={8}>
          <a href={`${process.env.REACT_APP_API_URL}/${contratoActivo.ruta}`} target='_blank'>
            <FontAwesomeIcon icon={faFileAlt} className='mr-1' />
            Ver contrato
          </a>
        </Col>
      </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='danger'
          onClick={handleEliminarContrato}
        >
          Eliminar
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default ContratoProveedor;