import {
  OBTERNET_APUNTES,
  OBTERNET_APUNTES_EXITO,
  AGREGAR_APUNTE_EXITO,
  AGREGAR_APUNTE_ERROR,
  EDITAR_APUNTE_EXITO,
  EDITAR_APUNTE_ERROR,
  GUARDAR_APUNTE_EXITO,
  ELIMINAR_APUNTE_EXITO,
  ACTUALIZAR_ESTADO_APUNTE
} from './types';
import authAxios from '../config/authAxios';

export const guardarApuntes = (id) => {
  return async dispatch => {

    dispatch({
      type: OBTERNET_APUNTES
    })

    try {
      const respuesta = await authAxios.get(`/admin/matr_mostrarComentario__Idreportec02/${id}`)
    
      dispatch({
        type: OBTERNET_APUNTES_EXITO,
        payload: {
          datos: respuesta.data,
          id
        }
      })
    } catch (error) {
      console.log(error)
    }

    
  }
}

export const nuevoApunte = (comentario, id) => {
  return async dispatch => {
    
    try {
      const respuesta = await authAxios.post(`/admin/rptec__agregarComentario__IdReporte/${id}`, {comentario})
  
      dispatch({
        type: AGREGAR_APUNTE_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error)

      dispatch({
        type: AGREGAR_APUNTE_ERROR,
        payload: error
      })
    }
    
  }
}

export const guardarApunteEditar = (comentario) => {
  return dispatch => {
    dispatch({
      type: GUARDAR_APUNTE_EXITO,
      payload: comentario
    })
  }
} 

export const limpiarApunteEditar = () => {
  return dispatch => {
    dispatch({
      type: 'LIMPIAR_EDITAR_STATE'
    })
  }
}

export const editarApunte = (comentario, id) => {
  return async dispatch => {

    try {
      const respuesta = await authAxios.put(`/admin/rptec__editarComentario__IdDtReporte/${id}`, {comentario})
      
      dispatch({
        type: EDITAR_APUNTE_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: EDITAR_APUNTE_ERROR,
        payload: error
      })
    }
  }
}

export const eliminarApunte = (id) => {
  return async dispatch => {
    try {
      const respuesta = await authAxios.delete(`/admin/rptec__eliminarComentario__IdDtReporte/${id}`)
    
      dispatch({
        type: ELIMINAR_APUNTE_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const actualizarEstado = id => {
  return async dispatch => {
    try {
      const respuesta = await authAxios.put(`/admin/rptec__editarEstadoComentario__IdDtReporte/${id}`)

      dispatch({
        type: ACTUALIZAR_ESTADO_APUNTE,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error)

    }
  }
}