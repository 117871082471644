import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import { nuevoContrato } from '../../../../actions/programacionesActions';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const FormularioProveedor = ({ idProyecto }) => {

  const { idProgramacion } = useParams();
  const dispatch = useDispatch();
  const progresoSubida = useSelector(state => state.programaciones.programacion.progresoSubidaArchivo);
  const initialState = {
    nombre: '',
    idProveedor: ''
  }

  const [datosProveedor, setDatosProveedor] = useForm(initialState);
  const [file, setFile] = useState(null);

  const {nombre, idProveedor} = datosProveedor;

  const handleSubmit = e => {
    e.preventDefault();
    if(nombre === '' || idProveedor === '' || !file){
      avisoError('Todos los campos son requeridos');
      return;
    }
    const data = new FormData();
    data.append('nombre', nombre);
    data.append('file', file);
    data.append('idProveedor', idProveedor);
    dispatch(nuevoContrato(data, idProgramacion, idProyecto));
  }

  return ( 
    <div className="FormularioProveedor">
      <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label className='label-required'>Título de contrato</Form.Label>
          <Form.Control
            type='text'
            name='nombre'
            value={nombre}
            onChange={setDatosProveedor}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>Tipo de contrato</Form.Label>
          <Form.Control
            as='select'
            name='idProveedor'
            value={idProveedor}
            onChange={setDatosProveedor}
          >
            <option value='' disabled >--Seleccione--</option>
            <option value='1' >Auditorio</option>
            <option value='2' >Hospedaje/Alimentación</option>
            <option value='3' >Transporte interprovincial</option>
            <option value='4' >Transporte local</option>
            <option value='5' >Cofee break</option>
            <option value='6' >Impresión</option>
            <option value='7' >Productor AudioVisual</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>Archivo adjunto (.doc, .docx, .pdf)</Form.Label>
          <Form.File
            id="exampleFormControlFile1"
            onChange={e => setFile(e.target.files[0])}
            accept=".doc, .docx, .pdf"
          />
        </Form.Group>
        <Form.Group>
          { progresoSubida && <>
            <Form.Label>Subiendo archivo al servidor</Form.Label>
            <ProgressBar
              animated
              variant='success'
              now={progresoSubida}
              label={`${progresoSubida}%`}
            />
          </> }
        </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='light'
            className='btn-icon'
            onClick={() => dispatch(updateStateModalBasic())}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button type='submit' className='btn-icon'>
            <FontAwesomeIcon icon={faSave} />
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
}
 
export default FormularioProveedor;