import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';
import DatosPersonalesWithEditar from '../../containers/TrabajadoresContainers/DatosPersonalesWithEditar';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalBasic } from '../../actions/modalActions';

const withDatosPersonales = WrappedComponent => props => {

  const [trabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleEditar = () => {
    dispatch( updateStateModalBasic(<DatosPersonalesWithEditar/>, 'Editar datos del trabajador') )
  }

  return ( 
    <WrappedComponent
      {...props}
      datos={trabajador}
      handleEditar={handleEditar}
    />
   );
}
 
export default withDatosPersonales;