import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import TablaItemPonente from './TablaItemPonente';
import './TablaPonenteCtrl.scss';

//Redux
import { useSelector } from 'react-redux';
import BotonesAcciones from './BotonesAcciones';

const TablaPonenteCtrl = () => {

  const { idCapacitacion } = useParams()
  const datos = useSelector(state => state.control_capacitacion.area_trabajo.datos)
  
  const docentesFiltro = datos[idCapacitacion] ? datos[idCapacitacion].docentes_filtro : []
 
  return ( 
    <div className="ponente-ctrl">
      <Row>
        <Col lg={9} className='px-0'>
        <div className="box box-primary tableFixHead">
          <Table striped>
            <thead>
              <tr>
                <th className='text-center'>Docente</th>
                <th className='border-left text-center'>Clasificacion</th>
              </tr>
            </thead>
            <tbody>
              {docentesFiltro.length ? (
                  <>
                  {docentesFiltro.map(docente => (
                    <TablaItemPonente
                      key={docente.id_filtro_docente}
                      docente={docente}
                    />
                  ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan='2'>No hay docentes agregados</td>
                  </tr>
                )}
            </tbody>
         </Table>
        </div>
        </Col>
        <Col lg={3}>
          <BotonesAcciones />
        </Col>
      </Row>
    </div>
   );
}
 
export default TablaPonenteCtrl;