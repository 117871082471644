import React, { useEffect } from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAreaActivaTrabajador, listarAreasTrabajadores } from '../../actions/trabajadoresActions';

const withAreasDictado = WrappedComponent => props => {
  
  const [trabajador] = useGetTrabajador();
  const { todo } = useSelector(state => state.trabajadores.areas);
  const dispatch = useDispatch();

  const handleSetAreaActiva = (idArea) => {
    dispatch( setAreaActivaTrabajador(idArea) );
  }

  const handleGuardarArea = () => {

  }

  useEffect(() => {
    if (!todo.length) {
      dispatch( listarAreasTrabajadores() )
    }
  }, [])

  useEffect(() => {
    //Si ya hay areas para seleccionar, guardara la area definida al trabajador
    if (todo.length) {
      handleSetAreaActiva(trabajador.id_tp_nivel_usuario)
    }
  }, [todo])

  return (
    <WrappedComponent
      {...props}
      areas={todo}
      defaultValue={{
        id: trabajador.id_tp_nivel_usuario,
        nombre: trabajador.nombre_tp_nivel_usuario
      }}
      handleSetAreaActiva={handleSetAreaActiva}
      handleGuardarArea={handleGuardarArea}
    />
  );
}
 
export default withAreasDictado;