import React, { useEffect, useState } from "react";
import {
  faSearch,
  faUser,
  faUserFriends,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Col, Button, Spinner, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from 'yup';
import useGetDiscente from "../../../../hooks/useGetDiscente";
import * as moment from 'moment';
import { TYPENUMBER, TYPE_NUMBER_INT, REQUIRED, TYPE_NUMBER_POSITIVE } from "../../../../helpers/validationForms";

const FormDiscente = ({ setMostrarContenidoProyectos }) => {
  const { estudiante: discente, obtenerDiscente, loading } = useGetDiscente();
  const [mostrarContenido, setMostrarContenido] = useState(false);
  const formik = useFormik({
    initialValues: {
      dni_buscar: '',
      nombre_usuario: '',
      apellido_usuario: '',
      email: '',
      usuario: '',
      observaciones: ''
    },
    validationSchema: yup.object({
      dni_buscar: yup.string()
        .length(8, 'Debe tener exáctamente 8 caracteres')
        .matches(/[0-9]+/gi, TYPENUMBER).required(REQUIRED)
    }),
    onSubmit: valores => {
      obtenerDiscente(valores.dni_buscar, "dni");
    }
  });

  useEffect(() => {
    if(Object.keys(discente).length > 0) {
      formik.setValues({
        ...discente,
        dni_buscar: formik.values.dni_buscar
      });
      setMostrarContenidoProyectos(true);
    } else {
      formik.resetForm();
      setMostrarContenido(true);
    }
  }, [discente])

  if(!mostrarContenido) return null;

  return (
    <div className="FormDiscente">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                DNI
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name='dni_buscar'
                  value={formik.values.dni_buscar}
                  onChange={formik.handleChange}
                  pattern="^[0-9]+"
                  maxLength={8}
                  isInvalid={formik.errors.dni_buscar}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.dni_buscar}
                </Form.Control.Feedback>
              </Col>
              <Col md={1}>
                <Button disabled={loading} variant="info" type="submit">
                  {loading ? (
                    <Spinner as="span" animation="border" size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faSearch} />
                  )}
                </Button>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Fecha
              </Form.Label>
              <Col md={8}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="text" defaultValue={moment().format('DD/MM/YYYY')} disabled />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Nombre
              </Form.Label>
              <Col md={8}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name='nombre_usuario'
                    value={formik.values.nombre_usuario}
                    onChange={formik.handleChange}
                    disabled
                  />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Correo
              </Form.Label>
              <Col md={8}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>@</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="email"
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    disabled
                  />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Apellido
              </Form.Label>
              <Col md={8}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name='apellido_usuario'
                    value={formik.values.apellido_usuario}
                    onChange={formik.handleChange}
                    disabled
                  />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Observaciones
              </Form.Label>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name='observaciones'
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  disabled
                />
              </Col>
            </Form.Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Row>
              <Form.Label column md={3}>
                Usuario
              </Form.Label>
              <Col md={8}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUserFriends} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name='usuario'
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    disabled
                  />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
};

export default FormDiscente;
