import React, { useEffect } from "react";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumbs from "../../General/Breadcrumb";
import Layout from "../../../../app/Layout";
import ClasificacionDocente from "../../../components/RegistroUsuarios/Clasificacion/VistaClasificacion";
import PerfilProfesional from "../../../components/RegistroUsuarios/PerfilProfesional/VistaPerfilProfesional/VistaPerfilProfesionalDocente";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import DatosPersonaleswithDocentes from "../../../../containers/DocentesContainers/DatosPersonaleswithDocentes";
import VistaHonorariosContainer from "../../../../containers/DocentesContainers/VistaHonorariosContainer";
import VistaDatosSesionContainer from "../../../../containers/DocentesContainers/VistaDatosSesionContainer";
import AvatarUsuarioWithDocente from "../../../../containers/AvatarContainers/AvatarUsuarioWithDocente";
import VistaPerfil from "../../../components/RegistroUsuarios/DatosPersonales/VistaDatosPersonales/VistaPerfil";
import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './InspeccionarDocente.scss';

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { listarDocente } from "../../../../actions/profesoresActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faGraduationCap, faUser, faUserCog, faUsersCog } from "@fortawesome/free-solid-svg-icons";


const InspeccionarProfesor = () => {

  const { idDocente } = useParams();
  const dispatch = useDispatch()
  const docente = useSelector(state => state.profesores.docente[idDocente]);

  useEffect(() => {
    dispatch( listarDocente(idDocente) )
  }, [])

  return (
    <Layout>
      <HeaderContent
        title={"Docente"}
        icon={"person"}
      />

      <Breadcrumbs
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/profesores", nombre: "Docentes" },
          { url: "", nombre: "Editar docente" },
        ]}
      />
      <div className="InspeccionarDocente container-fluid mt-3 py-4">
        <SpinnerLoading loading={!docente}>
        {/* <FormularioProfesor idProfesor={idProfesor} /> */}
          <Row>
            <Col md={4}>
              <div className='bg-white py-4 shadow-sm border'>
                <AvatarUsuarioWithDocente />
                <VistaPerfil datos={docente} />
              </div>
            </Col>
            <Col md={8}>
              <div className='bg-white shadow-sm border'>
                <Tabs
                  defaultActiveKey="perfil"
                  className='InspeccionarDocente__panelTab'
                  id="docente-tab-example"
                >
                  <Tab eventKey="perfil" title={
                    <>
                      <div className='text-center w-100 mb-2'>
                        <FontAwesomeIcon icon={faGraduationCap} />
                      </div>
                      CV
                    </>
                  }>
                    <PerfilProfesional/>
                  </Tab>
                  <Tab eventKey="categoria" title={
                    <>
                      <div className='text-center w-100 mb-2'>
                        <FontAwesomeIcon icon={faUserCog} />
                      </div>
                      Categoria
                    </>
                  }>
                    <ClasificacionDocente/>
                  </Tab>
                 <Tab eventKey="contacto" title={
                    <>
                      <div className='text-center w-100 mb-2'>
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                      Contacto
                    </>
                  }>
                     <DatosPersonaleswithDocentes/>
                  </Tab>
                  <Tab eventKey="honorarios" title={
                    <>
                      <div className='text-center w-100 mb-2'>
                        <FontAwesomeIcon icon={faDollarSign} />
                      </div>
                      Honorarios
                    </>
                  }>
                    <VistaHonorariosContainer/>
                  </Tab>
                  <Tab eventKey="usuario" title={
                    <>
                      <div className='text-center w-100 mb-2'>
                        <FontAwesomeIcon icon={faUsersCog} />
                      </div>
                      Datos de usuarios
                    </>
                  }>
                    <VistaDatosSesionContainer/>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </SpinnerLoading>
      </div>
    </Layout>
  );
};

export default InspeccionarProfesor;
