import React from 'react';
import {Table,Card} from 'react-bootstrap';
const resultadoCalidad = ({calidad}) => {

    if(calidad===undefined || !calidad || calidad.lenght===0) return (
        <>
        <Card className="p-3 mb-3">
         <Card.Body>
            No hay datos para mostrar
         </Card.Body>
        </Card>
        </>
    );
    return (
        <>
        
        
        {
            
            calidad.map((proyecto,j)=>(
    <Card key={j} className="p-3 mb-3">
         <Card.Body>      
   
       <Table  striped bordered hover>
        <thead>
            <tr>
            <th colSpan="4">{proyecto.nombre_proyecto}</th>
            </tr>
            <tr>
            <th colSpan="4">Realizado del {proyecto.fecha_inicio}  hasta {proyecto.fecha_fin}</th>
            </tr>
            <tr>
            <th>Evaluado</th>
            <th>Meta</th>
            <th>Calificación</th>
            <th>Brecha</th>
            <th> </th>
            </tr>
        </thead>
        <tbody>
        {
            proyecto.datos.map((dato,i)=>(
                <tr key={i}>
         
            <td className="text-center">{dato.tema}</td>
            <td className="text-center">100 %</td>
            <td className="text-center">{dato.porcentaje_mayor}</td>
            <td className="text-center">{dato.brecha}</td>
            <td><span className={parseFloat(dato.porcentaje_mayor)>90?'semaforo_rn verde':'semaforo_rn rojo'} style={{margin:'auto'}}></span></td>
            </tr>

            ))
        }
           
        </tbody>
        </Table>
        </Card.Body>
        </Card>

            ))}
   
        </>
    );
};

export default resultadoCalidad;
