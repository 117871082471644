import React from 'react';
import useGetTrabajador from '../../hooks/useGetTrabajador';

//Redux
import { useDispatch } from 'react-redux';
import { editarResumenCvTrabajador } from '../../actions/trabajadoresActions';

const withEditarResumenCv = WrappedComponent => props => {

  const [trabajador, idTrabajador] = useGetTrabajador();
  const dispatch = useDispatch();

  const handleSubmit = descripcion => {
    dispatch( editarResumenCvTrabajador(idTrabajador, descripcion) );
  }

  return ( 
    <WrappedComponent
      {...props}
      resumenCv={trabajador.descripcion}
      handleSubmit={handleSubmit}
    />
   );
}
 
export default withEditarResumenCv;