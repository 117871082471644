import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { faPlus, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormularioProveedor from '../FormularioProveedor';
import ContratoProveedor from '../ContratoProveedor';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { contratoActivo } from '../../../../actions/programacionesActions';

const Proveedores = () => {

  const dispatch = useDispatch();
  const { idProgramacion } = useParams();
  const programaciones = useSelector(state => state.programaciones.programacion.todo);
  const proyectos = (programaciones !== null && programaciones[idProgramacion] !== undefined) ? programaciones[idProgramacion] : [];

  const handleModalNuevo = (idProyecto) => {
    dispatch(updateStateModalBasic(<FormularioProveedor idProyecto={idProyecto} />, 'Nuevo proveedor'))
  }

  const handleModalEditar = async (idProyecto, idProveedor) => {
    await dispatch(contratoActivo(idProgramacion, idProyecto, idProveedor))
    dispatch(updateStateModalBasic(<ContratoProveedor idProyecto={idProyecto} />, 'Proveedor'))
  }
  
  const mostrarFilas = (numeroCiclo) => {
    return proyectos.length ? proyectos.map(proyecto => {
      if (parseInt(proyecto.idCiclo) === numeroCiclo) {
        return <tr key={proyecto.id}>
          <td>{proyecto.nombre}</td>
          {
            proyecto.proveedores.map((proveedor) => (
              <React.Fragment key={proveedor.id}>
                {
                  proveedor.nombre ? 
                    <td>
                      <span
                        className='cursor-pointer text-primary'
                        onClick={() => handleModalEditar(proyecto.id, proveedor.id)}
                      >
                        <FontAwesomeIcon icon={faFileAlt} className='mr-1' />
                        Contrato
                      </span>
                    </td>
                  : <td>
                      <span>
                        No hay contrato
                      </span>
                    </td>
                }
              </React.Fragment>
            ))
          }
          <td className='text-center'>
            <Button variant='info' size='xs' onClick={() => handleModalNuevo(proyecto.id)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </td>
        </tr>
      }
      return null;
    }) : 
    <tr>
      <td>No hay proyectos para mostrar</td>
    </tr>
  }

  return ( 
    <div className="Proveedores">
      <Table hover responsive>
        <thead>
          <tr>
            <th>Productos</th>
            <th>Auditorio</th>
            <th>Hospedaje/Alimentación</th>
            <th>Transporte interprovincial</th>
            <th>Transporte local</th>
            <th>Coffe break</th>
            <th>Impresión</th>
            <th>Productor AudioVisual</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='nombre-ciclo font-weight-bold' colSpan='8'>Ciclo I</td>
          </tr>
          {mostrarFilas(1)}
          <tr>
            <td className='nombre-ciclo font-weight-bold' colSpan='8'>Ciclo II</td>
          </tr>
          {mostrarFilas(2)}
        </tbody>
      </Table>
    </div>
  );
}
 
export default Proveedores;