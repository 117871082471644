import React, { useState } from "react";
import ListaPropuestas from "./ListaPropuestas";
import FormularioPropuesta from "./FormularioPropuesta";
import Rating from "../plugins/Rating";
import { useEffect } from "react";

const Propuestas = (props) => {
  const {
    pregunta,
    desabilitarBoton,
    guardarColeccionPropuestas,
    editarColeccionPropuestas,
    eliminarColeccionPropuestas,
    guardarEstrellas
  } = props;

  const [estrellas, setEstrellas] = useState(null)
  const [propuestas, setPropuestas] = useState([]);
  const [propuestaEditar, setPropuestaEditar] = useState({});

  const guardarPropuesta = (infoPropuesta) => {
    setPropuestas([...propuestas, infoPropuesta]);
    guardarColeccionPropuestas(pregunta.id_tp_tema_encuesta, infoPropuesta);
  };

  const editarPropuesta = (infoPropuesta) => {
    let copiaPropuestas = [...propuestas];

    copiaPropuestas.map((propuesta) => {
      if (propuesta.id_sugerencia_tutor === infoPropuesta.id_sugerencia_tutor) {
        propuesta.propuesta = infoPropuesta.propuesta;
      }
    });

    setPropuestas(copiaPropuestas);
    editarColeccionPropuestas(pregunta.id_tp_tema_encuesta, infoPropuesta);
    setPropuestaEditar({});
  };

  const guardarEditado = (infoPropuesta) => {
    setPropuestaEditar(infoPropuesta);
  };

  const eliminarPropuesta = (idPropuestaEliminar) => {
    let copiaPropuestas = [...propuestas];

    copiaPropuestas = copiaPropuestas.filter(
      (propuesta) => propuesta.id_sugerencia_tutor !== idPropuestaEliminar
    );
    eliminarColeccionPropuestas(
      pregunta.id_tp_tema_encuesta,
      idPropuestaEliminar
    );
    setPropuestas(copiaPropuestas);
  };

  useEffect(() => {
    setPropuestas(pregunta.propuestas);
  }, [pregunta]);

  useEffect(() => {
    if (estrellas !== null) {
      guardarEstrellas(pregunta.id_tp_tema_encuesta, estrellas)
    }
  }, [estrellas])

  return (
    <div className="content-preguntas__item mb-5">
      <div className="head d-flex justify-content-between align-items-center mb-3">
        <p className="label">{pregunta.nombre_tp_tema_encuesta}</p>
        <div className="estrella">
          <Rating
            index={pregunta.id_tp_tema_encuesta}
            defaultStars={parseInt(pregunta.calificacion)}
            getStars={setEstrellas}
            labelsTooltip={labelsTooltip}
          />
        </div>
      </div>
      <div className="aportes">
        <FormularioPropuesta
          guardarPropuesta={guardarPropuesta}
          editarPropuesta={editarPropuesta}
          propuestaEditar={propuestaEditar}
          desabilitarBoton={desabilitarBoton}
        />
        <ListaPropuestas
          propuestas={propuestas}
          guardarEditado={guardarEditado}
          eliminarPropuesta={eliminarPropuesta}
          desabilitarBoton={desabilitarBoton}
        />
      </div>
    </div>
  );
};

export default Propuestas;

const labelsTooltip = {
  1: '😟 Mejorare',
  2: '😐 Mejorare mejor',
  3: '😏 Medio',
  4: '😊 Bien',
  5: '🤩 Excelente',
};
