import React, { useEffect, useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import useSelectProyecto from '../../../../hooks/useSelectProyectos';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { obtenerMatriculas } from '../../../../actions/matriculasActions';

const FormularioFiltroMatricula = ({ setValores, loading }) => {
  
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = new URLSearchParams(window.location.search);
  const idProyectoQ = queryString.get('proyecto');
  const estadoQ = queryString.get('estado');

  const [proyectoSelect, SelecProyectos] = useSelectProyecto(idProyectoQ);
  const formik = useFormik({
    initialValues: {
      idEvento: idProyectoQ || null,
      estado: estadoQ ||'9',
    },
    onSubmit: valores => {
      history.push(`?proyecto=${valores.idEvento}&estado=${valores.estado}`);
    }
  });

  useEffect(() => {
    if(idProyectoQ && estadoQ) {
      dispatch(obtenerMatriculas(formik.values));
      setValores({
        idEvento: idProyectoQ,
        estado: estadoQ
      })
    }
  }, [idProyectoQ, estadoQ, dispatch])

  useEffect(() => {
    if (proyectoSelect) {
      setError(null);
      formik.setFieldValue('idEvento', proyectoSelect.id_proyecto);
    } else {
      formik.setFieldValue('idEvento', null);
    }
  }, [proyectoSelect])

  return ( 
    <div className="FormularioFiltroMatricula">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Productos</Form.Label>
            <SelecProyectos />
            {error && <Form.Text className='text-danger'>
              {error}
            </Form.Text>}
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label className='w-100'>Estado matricula</Form.Label>
            <Form.Control
              as='select'
              name='estado'
              value={formik.values.estado}
              onChange={formik.handleChange}
            >
              <option value="" disabled>--Seleccione--</option>
              <option value="1">Activo</option>
              <option value="2">Desertado</option>
              <option value="3">Culminado</option>
              <option value="9">Todos los estados</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={6} className='d-flex align-items-end'>
            <Button type='submit' disabled={loading} className='btn-icon'>
              <FontAwesomeIcon icon={faSearch} />
              Buscar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormularioFiltroMatricula;