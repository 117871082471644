import React, { useEffect, useState } from "react";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import widthModal from "../../../hoc/widthModal";
import ModalLarge from "../../components/Modals/ModalLarge/ModalLarge";
import Agenda from "../../components/Agenda";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import MensajeLoading from "../../components/MensajeLoading";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import "./ReporteDesertados.scss";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { obternerReporteDesertados, guardarProyectoSelect } from "../../../actions/reportesActions";
import { guardarApuntes, eliminarApunte } from '../../../actions/apuntesActions'
import { compose } from "redux";

const ReporteDesertados = (props) => {

  const { updateModalLarge, show } = props;
  const dispatch = useDispatch();

  const {cantidadesTotales, reporteDesertados, proyectoSelect } = useSelector((state) => state.reporte.desertados);
  const { loading } = useSelector((state) => state.reporte);
  const guardarObservaciones = (id) => dispatch(guardarApuntes(id))
  const listarReporteDesertados = (idProyecto) => dispatch(obternerReporteDesertados(idProyecto));

  const [desertado, setDesertado] = useState({})
  const [proyecto, SelectProyectos] = useSelectProyecto(proyectoSelect)
  const { usuario } = useSelector(state => state.usuario);

  //Funcion para hacer el filtro de acuerdo al click
  const filtroDesetadoSeleccionado = id => {
    const desertados = [...reporteDesertados]
    const desertadofiter = desertados.filter(d => d.id_rptec_desertado === id)

    setDesertado(desertadofiter[0])
    guardarObservaciones(id)
  }

  //Funciopn que retorna boton para la columna
  const btnExaminar = ({id_rptec_desertado}) => {
    
    return <Button onClick={() => {
        updateModalLarge()
        filtroDesetadoSeleccionado(id_rptec_desertado)
      }}
      className='btn-success'
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  }

  const selectEstado = () => {
    return 
  }
  
  //Funcion para guardar el select proyecto
  const guardarSeleccion = datos => {
    dispatch( guardarProyectoSelect(datos) )
  }

  useEffect(() => {
    if (proyecto && proyecto.id_proyecto) {
      listarReporteDesertados(proyecto.id_proyecto);
      guardarSeleccion(proyecto)
    }
  }, [proyecto]);

  return (
    <Layout>
      <HeaderContent title="SOS" icon='insert_chart_outlined' subItem={usuario.id_tp_nivel_usuario==='5' ? 'Tu misión: que tu cliente le saque el jugo a su tiempo e inversión.' : null} />
      <Breadcrumb
        link={[
          { nombre: "Inicio", url: "/inicio", icon: "fas fa-home" },
          { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "SOS" },
        ]}
      />
      
      <div className="reporte-desertados">
        <div className="row content-select-curso mt-4">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 filtro">
                <label htmlFor="">Producto</label>
                <SelectProyectos/>
              </div>
              <div className="col-md-4 filtro"></div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        {
          !proyecto ? (
            <MensajeLoading/>
          ) : (
            <SpinnerLoading loading={loading}>
              <div className="p-0 mt-4 bg-white">
                <div className="continer-fluid border-bottom-0 border">
                  <h5
                    className="p-3 d-flex align-items-center mb-0"
                    style={{ color: "#434343" }}
                  >
                    <i className="fas fa-list pr-2"></i>Lista de proyectos
                  </h5>
                </div>
                <div className="tabla border">
                  <MaterialTableDemo
                    colums={colums(btnExaminar)}
                    tabla={reporteDesertados}
                    className='table-responsive-lg'
                  />

                  <div style={{marginTop:'15px', padding:'8px' , display:'flex'}}>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total 20% a cobrar:</span> S/. {cantidadesTotales.total_20}
                    </div>
                    <div style={{fontSize:'18px', margin:'8px'}}>
                      <span style={{fontWeight:'bold'}}>Total 100% a cobrar:</span> {cantidadesTotales.total_100}
                    </div>
                 </div>
                </div>
              </div>
            </SpinnerLoading>
          )
        }
       
      </div>
      <ModalLarge
        show={show}
        updateModalLarge={updateModalLarge}
        title={`Anotaciones ${desertado.nombre}`}
      >
        <Agenda/>
      </ModalLarge>
    </Layout>
  );
};

export default compose(widthModal)(ReporteDesertados);

const colums = (btnExaminar) => [
  {
    text: "ID",
    key: "id_matricula",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Discente",
    key: "nombre",
    align: "center",
    sortable: true,
    width: 500,
    className: "type_of_food td-discente"
  },
  {
    text: "Contrato firmado",
    key: "contrato",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "N° de celular",
    key: "celular",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Solicitud de desersión dentro del plazo (15 días calendarios)",
    key: "solicitud_plazo",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-solicitud"
  },
  {
    text: "Aplica devolución",
    key: "devolucion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Sesiones asistidas",
    key: "cantidad_asistencia",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Inversión (Precio Total)",
    key: "inversion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Monto abonado",
    key: "monto_abonado",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "N° Sesiones",
    key: "cantidad_sesiones",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Inversión por sesión",
    key: "inversion_por_sesion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "20% (% DSCTO OBLIGATORIO SIN ASISTENCIA)",
    key: "20%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar-2"
  },
  {
    text: "100% (% COBRO OBLIGATORIO CON ASISTENCIA)",
    key: "cobro_obligatorio_100%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar-2"
  },
  {
    text: "Monto a cobrar 20%",
    key: "monto_a_cobrar_20%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar"
  },
  {
    text: "Monto a cobrar 100%",
    key: "monto_a_cobrar_100%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar"
  },
  {
    key: "action",
    text: "Anotaciones",
    className: "action text-center",
    width: 100,
    align: "center",
    sortable: false,
    cell: record => btnExaminar(record)
}
]
