import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";

//Redux
import { eliminarEvidencia } from "../../../../../actions/controlCapacitacionesActions";
import { updateToogleLigthBox } from '../../../../../actions/lightBoxActions';
import { updateStateModalLarge } from '../../../../../actions/modalActions';
import { useDispatch } from 'react-redux';

const ImagenConfirmacion = ({imagen, imagenes, index}) => {

  const { idCapacitacion } = useParams();
  const dispatch = useDispatch()

  const handleEliminarImagen = (idImagen) => {
    dispatch( eliminarEvidencia(idImagen, idCapacitacion) )
  }
  const handleVerImage = () => {
    dispatch( updateStateModalLarge() )
    dispatch( updateToogleLigthBox(imagenes, index))
  }

  return (
    <>
      <picture>
        <img
          className='img img-fluid'
          src={`${process.env.REACT_APP_API_URL}/${imagen.ruta_archivo}`}
          alt="Imagen de confirmación"
          onClick={() => handleVerImage()}
        />
        <button
          type='button'
          className='btn btn-sm btn-danger'
          onClick={() => handleEliminarImagen(imagen.id_archivo_confirmacion)}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </picture>
    </>
  )
}

export default ImagenConfirmacion;