import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
const ResultadoClasificacion = ({ nombre_estado }) => {
  return ( 
    <>
      <FontAwesomeIcon icon={faBriefcase} />
      <span className='font-weight-normal ml-1 mr-3'>Resultado:</span>
      <span>{ nombre_estado }</span>
    </>
   );
}
 
export default ResultadoClasificacion;