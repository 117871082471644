import React from 'react';
import { Table } from 'react-bootstrap';
import FilaCargosVariables from './FilaCargosVariables';

const TablaCuotasVariables = ({ cargos_variables = [], acciones = true }) => {


  const mostrarFilas = () => {
    if(!cargos_variables.length || !cargos_variables) {
      return <tr>
        <td colSpan={`${acciones ? '6' : '5'}`} align='center'>No hay cargos variables</td>
      </tr>
    } else {
      return cargos_variables.map((cargo, index) => (
        <FilaCargosVariables
          key={cargo.id_cargo_variable}
          cargo={cargo}
          index={index}
          acciones={acciones}
        />
      ))
    }
  }

  return ( 
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Concepto</th>
          <th>Subtotal (S/)</th>
          <th>Cantidad</th>
          <th>Total (S/)</th>
          {acciones && <th>Eliminar</th>}
        </tr>
      </thead>
      <tbody>
        {mostrarFilas()}
      </tbody>
    </Table>
  );
}
 
export default TablaCuotasVariables;