import React from 'react';
import Layout from '../../../app/Layout';
import HeaderComponent from '../General/HeaderContent';
import Breadcrumb from '../General/Breadcrumb';
import './scss/usuarios.scss'
import FormularioUsuario from './FormularioUsuario';
import { avisoCorrecto, avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
import { useHistory } from 'react-router-dom';

//Redux
import {connect} from 'react-redux'
import {insertarNuevoUsuario} from '../../../actions/userActions'

const NuevoUsuario = (props) => {

    //Funciones
    const {insertarNuevoUsuario} = props

    const history = useHistory()

    //Crear nuevo usuario
    const nuevoUsuario = async dataNuevoUsuario => {
        const {mensaje, textMensaje} = await insertarNuevoUsuario(dataNuevoUsuario)

        if (mensaje) {
            avisoCorrecto(textMensaje)
            history.push('/trabajadores')
            return
        }
        avisoError(textMensaje)
    }


    return ( 
        <Layout>
            <HeaderComponent
                title='Nuevo trabajador'
                icon='person_add'
            />
            <Breadcrumb
              link={[
                {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
                {url:'/trabajadores', nombre: 'Trabajadores'},
                {url:'', nombre: 'Nuevo trabajador'}
              ]}
            />

            <div className="content_new_usuario">
                <FormularioUsuario
                    nuevoUsuario={nuevoUsuario}
                />
            </div>
        </Layout>
     );
}

const mapStateToProps = state => ({

})

const mapDispachtToProps = {
    insertarNuevoUsuario
}
 
export default connect(mapStateToProps, mapDispachtToProps)(NuevoUsuario);