import { Card } from 'react-bootstrap';
import React from 'react';
import ProgressGradientBar from './ProgressGradientBar/ProgressGradientBar'

import CircularGradient from './CircularGradient'
import "./indicadores.css"
const Indicador = ({datos}) => {

  let porcentaje = datos.porcentaje;
    return (
        <div className="col-12 col-md-4 mb-3 text-center">
        <Card>
            <Card.Header>
                {datos.nombre_proyecto}
            </Card.Header>
            <Card.Body>
            <div className="row text-center">
            <div className="col-12" style={{display:'block', marginBottom:'15px'}}>
            <p>{datos.porcentaje ? datos.porcentaje : 0}% de progreso</p>
            <p>Sesiones: {datos.sesiones_realizadas}/{datos.n_sesiones}</p>
            </div>
           { //<ProgressGradientBar truePercent={porcentaje ? parseInt(porcentaje): 0} />
           }
            <CircularGradient percentage={porcentaje ? parseInt(porcentaje): 0} strokeWidth={8} />
            </div>
            <div className="row text-center p-3" style={{marginTop:'25px'}}>
           <div className="col-12 col-md-3">
                <img src={`https://sistema.inedi.edu.pe/images/${datos.flecha}`} className={'indicador '+datos.orientacion}/>
            </div>
            <div className="col-12 col-md-9">
               <div className="row" style={{border:'1px dashed #ebe', padding:'3px'}}>
                   <div className="col-md-4" style={{borderRight:'1px dashed #ebe'}}><p style={{fontSize:'12px', marginBottom:'4px'}}>Total Discentes</p><p>{datos.cantidad}</p></div>
                   <div className="col-md-4" style={{borderRight:'1px dashed #ebe'}}><p style={{fontSize:'12px', marginBottom:'4px'}}>Total Desertados</p><p>{datos.desertado ? datos.desertado : 0}</p></div>
                   <div className="col-md-4"><p style={{fontSize:'12px', marginBottom:'4px'}}>Deserción (%)</p><p>{datos.desercion ? datos.desercion : 0}</p></div>
               </div>
            </div>
            </div>
            </Card.Body>
        </Card>
        </div>
    );
};

export default Indicador;