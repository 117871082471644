import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { filtrarAnuncios, listarAnunciosCampaign } from '../../../actions/campaignsActions';
import useSelectProyecto from '../../../hooks/useSelectProyectos';
//import * as moment from 'moment';
//import { Button } from 'react-bootstrap';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioFiltro = () => {
const dispatch = useDispatch();
const {usuario} = useSelector(state=>state.usuario)
const [proyecto, SelectProyecto] = useSelectProyecto(null)

/*
const handleUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/toky/${usuario.id_tp_nivel_usuario}/${asesor?asesor.id_usuario:null}/${proyecto?proyecto.id_proyecto:null}/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}/${estado?estado.id_estado:null}`;
}
const handleMailUrlDescarga = () => {
  return `${process.env.REACT_APP_API_URL}/admin/csv/export/mailchimp/${fechaFiltro?moment(fechaFiltro).format('YYYY-MM-DD'):null}`;
}*/

const traerDatos = () =>{
  if(!proyecto)
  {
    dispatch(listarAnunciosCampaign())
  }
  else
  {

      const datosFiltro = 
      {
        proyecto: proyecto ? proyecto : null,
      }

      console.log(datosFiltro)

      dispatch(filtrarAnuncios(datosFiltro))
  }
}

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faSearch} /> Acciones de Filtro
                  </h5>
                
                </div>
      <div className="row p-2">
        <div className="col-7 mb-2">
          <label htmlFor="">Producto:</label>
          <SelectProyecto/>
        </div>
        <div className="col-12 pt-4">
        <button className="btn btn-primary" onClick = {traerDatos} >Consultar</button>
        </div>
      </div>
    </div>
  
  
    );
};

export default FormularioFiltro;
