import React, { useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REQUIRED } from '../../../../helpers/validationForms';
import { useDispatch, useSelector } from 'react-redux';
import { insertarProyecto } from '../../../../actions/cursosActions';
import { useHistory } from 'react-router-dom';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const FormularioProyecto = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { loadingAdd, errorAdd, redireccion, proyectos } = useSelector(state => state.cursos);
  const formik = useFormik({
    initialValues: {
      nombre_proyecto: ''
    },
    validationSchema: yup.object({
      nombre_proyecto: yup.string().required(REQUIRED)
    }),
    onSubmit: valores => {
      dispatch(insertarProyecto(valores));
    }
  });

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }
  const mostrarSpinner = () => {
    if(loadingAdd) return <Spinner animation='border' size='sm' />;
    if(errorAdd) avisoError(errorAdd);
  }

  useEffect(() => {
    if(!loadingAdd && redireccion) {
      history.push(`/proyectos/${proyectos[proyectos.length - 1].id_proyecto}`);
    }
  }, [redireccion])

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre del proyecto</Form.Label>
          <Form.Control
            type= 'text'
            name='nombre_proyecto'
            value={formik.values.nombre_proyecto}
            onChange={formik.handleChange}
            isInvalid={!!formik.errors.nombre_proyecto}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.nombre_proyecto}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={handleModal}>
          Cancelar
        </Button>
        <Button
          type='submit'
          variant='info'
          className='btn-spinner'
          disabled={loadingAdd}
        >
          {mostrarSpinner(handleModal)}
          Crear proyecto
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormularioProyecto;