import {basesCertTypes} from '../actions/types'

const initialState = {
    bases: [],
    aptos: [],
    emitidos:[],
    csv_firma:null,
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case basesCertTypes.OBTENER_BASES_EXITO:
            return{
                ...state,
                bases: action.payload,
                loading:false,
            }
        case basesCertTypes.OBTENER_BASES:
                return{
                    ...state,
                    loading:true,
                }
        case basesCertTypes.OBTENER_APTOS:
            return{
                ...state,
                loading:true,
            }
        case basesCertTypes.OBTENER_APTOS_EXITO:
            return{
                ...state,
                aptos:action.payload,
                loading:false,
            }
        case basesCertTypes.OBTENER_EMITIDOS:
            return{
                ...state,
                loading:true,
            }
        case basesCertTypes.OBTENER_EMITIDOS_EXITO:
            return{
                ...state,
                emitidos:action.payload.emitidos,
                csv_firma:action.payload.archivo_csv,
                loading:false,
            }
        default:
            return state
    }
} 