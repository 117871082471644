import React, { useState } from "react";

const widthModal = (WrappedComponent) => (props) => {
  const [show, setShow] = useState(false);

  const updateModalLarge = () => {
    setShow(!show);
  };

  return (
    <WrappedComponent
      {...props}
      show={show}
      setShow={setShow}
      updateModalLarge={updateModalLarge}
    />
  );
};

export default widthModal;
