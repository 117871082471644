import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';
import EditarTemasAreaDocente from '../../view/components/RegistroUsuarios/Clasificacion/EditarTemasArea/EditarTemasAreaDocente';
import { updateStateModalCustom } from '../../actions/modalActions';
import { useDispatch } from 'react-redux';

const withTemasAreaDocente = WrappedComponent => props => {
  
  const [docente] = useGetDocente();
  const dispatch = useDispatch()

  const handleEditar = () => {
    dispatch(updateStateModalCustom(<EditarTemasAreaDocente />, 'Editar temas del docente', 'modal-w30'))
  }

  return ( 
    <WrappedComponent
      {...props}
      {...docente.area}
      temas={docente.temas}
      handleEditar={handleEditar}
    />
   );
}
 
export default withTemasAreaDocente;