import {
  
    basesCertTypes
  
  } from "./types";
  
  
  import authAxios from "../config/authAxios";
  import response from '../config/response';
  import { avisoCargando, avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";
  import Swal from "sweetalert2/src/sweetalert2";
  import { updateStateModalLarge } from "./modalActions";

  export const listarBases = () => {

    return async dispatch => {
        dispatch({
          type: basesCertTypes.OBTENER_BASES
        });
        try {
    
            const bases = await authAxios.get(
                `/admin/listarBasesCertificados`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: bases.data.bases,
              type: basesCertTypes.OBTENER_BASES_EXITO
            });
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
      }
  };

  export const listarCertificadosEmitidos = (datos) => {

    return async dispatch => {
        dispatch({
          type: basesCertTypes.OBTENER_EMITIDOS
        });
        try {
    
            const bases = await authAxios.get(
                `/admin/listarCertificadosEmitidos/${datos.proyecto}`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: bases.data,
              type: basesCertTypes.OBTENER_EMITIDOS_EXITO
            });
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
      }
  };

  export const verificarAptos = (datos) => {

    return async dispatch => {
        dispatch({
          type: basesCertTypes.OBTENER_APTOS
        });
        try {
    
            const bases = await authAxios.get(
                `/admin/verificarAptos/${datos.proyecto}`
              );
            //  console.log(popup.data.data);
            
            dispatch({
              payload: bases.data.aptos,
              type: basesCertTypes.OBTENER_APTOS_EXITO
            });
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
      }
  };


  export const filtrarBases = (datosFiltro) => {

    return async dispatch => {
        dispatch({
          type: basesCertTypes.OBTENER_BASES
        });
        try {
    
            const bases = await authAxios.post(
                `/admin/filtrarBasesCertificados`,datosFiltro
              );
           
            dispatch({
              payload: bases.data.bases,
              type: basesCertTypes.OBTENER_BASES_EXITO
            });
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
      }
  };

  export const traerUltimoEvento = () => {

    return async dispatch => {
        try {
    
            const bases = await authAxios.get(
                `/admin/traerUltimoEvento`
              );
            //  console.log(popup.data.data);
            
            if(!bases.error)
            {
              return bases.data.datos
            }
            else{
              return null
            }
        
        } catch (error) {
          console.log(error)
          avisoError('Hubo un error')
        }
    }
  };


  export const nuevaPlantilla = (datos) => async dispatch => {
  
    const file = new FormData();

    
      file.append('archivo', datos.tabla_contenido);
      file.append('titulo',datos.titulo);
      file.append('n_evento',datos.n_evento);
      file.append('periodo',datos.periodo);
      file.append('n_libro',datos.n_libro);
      file.append('responsable',datos.responsable);
      file.append('producto',datos.producto.id_proyecto);

    try 
    {
          avisoCargando('Subiendo plantilla, espere por favor...')
          const datos = await authAxios.post(
                  `/admin/nuevaPlantilla`,file,{headers:{'Context-type':'multipart/form-data'}}
                );

          // console.log(subida)
            dispatch(updateStateModalLarge())
            dispatch({
              type: basesCertTypes.OBTENER_BASES_EXITO,
              payload:datos.data.respuesta
            })
           // dispatch(listar(usuario))
    } catch (error) {
          console.log(error)
          avisoError('Hubo un error al subir los archivos')
        }
 };

 export const emitirPDFs = (aptos,usuario) => {

  return async dispatch => {
  
    const swalWithBootstrapButtons = Swal.mixin({
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estás seguro?",
        text: "Este proceso generará los certificados PDF con los datos de la lista, si ya existen certificados es posible sean sustituidos...",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          try {
  
            const docsPDF = await authAxios.post(
                `/admin/emitirDocumentosPDF/${usuario.id_usuario}`,aptos
              );
          
            if(docsPDF.error)
            {
              return false
            }
            return true
           
        
        } catch (error) {
          swalWithBootstrapButtons.fire(
            "Error!",
            "Ocurrió un error inesperado, vuelva a intentar...",
            "error"
          );
        }
      },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        // console.log(result)
        if (result.value === true) {
        
          swalWithBootstrapButtons.fire(
            "Documentos",
            "Los documentos se generaron correntamente!!",
            "success"
          );
         
        }
        else{
          swalWithBootstrapButtons.fire(
            "Proceso cancelado",
            "No se emitieron los documentos PDF",
            "warning"
          );
        }
      });
  };
}
 
  export const eliminarBoleta = (id) => {

    return async dispatch => {
      const datos={
        id_registro:id,
      }
      const swalWithBootstrapButtons = Swal.mixin({
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Estas seguro?",
          text: "Se eliminara todo registro",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
            try {
    
              const boletas = await authAxios.delete(
                  `/admin/eliminarBoleta/${datos.id_registro}`
                );
            
              dispatch({
                payload: boletas.data.boletas,
                type: basesCertTypes.OBTENER_BOLETAS_EXITO
              })
              return true
             
          
          } catch (error) {
            avisoError('Hubo un error al eliminar el documento')
          }
        },
          allowOutsideClick: () => !Swal.isLoading()
        })
        .then(result => {
          //mensaje que se cambio la contraseña satisfactoriamente
          // console.log(result)
          if (result.value === true) {
          
            swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Documento eliminado correctamente",
              "success"
            );
           
          }
        });
  };
}
