import React from 'react';
import { useParams } from 'react-router-dom';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerTemasArea, setAreaActiva ,asignarAreaDictadoDocente } from '../../actions/profesoresActions';
import { useEffect } from 'react';

const withListarAreasDictado = WrappedComponent => props => {

  const dispatch = useDispatch();
  const { idDocente } = useParams();
  const areas = useSelector(state => state.profesores.area.areas);
  const areaDocente = useSelector(state => state.profesores.docente[idDocente].area)
  const areaActiva = useSelector(state => state.profesores.area.areaActiva)

  //Obtener los temas al seleccionar el area en el select
  const handleObtenerTemasArea = idArea => {
    dispatch( obtenerTemasArea(idArea) );
  }

  const handleGuardarArea = idArea => {
    dispatch( asignarAreaDictadoDocente(idDocente, idArea) )
  }

  const handleSetAreaActiva = (idArea) => {
    dispatch( setAreaActiva(idArea) )
  }

  useEffect(() => {
    if (Object.keys(areaActiva).length) {
      handleObtenerTemasArea(areaActiva.id)
    }
  }, [areaActiva])

  return ( 
    <WrappedComponent
      {...props}
      areas={areas}
      handleSetAreaActiva={handleSetAreaActiva}
      handleGuardarArea={handleGuardarArea}
      defaultValue={{
        value: parseInt(areaDocente.id_tp_area_dictado),
        label: areaDocente.nombre_area_dictado
      }}
    />
   );
}
 
export default withListarAreasDictado;
