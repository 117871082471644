import React from 'react';
import IndicadorEstado from '../../../../IndicadorEstado';

const Indicador = ({ nombre_proceso, id, handleEstadoIndicador,  estado, index }) => {

  const handleClick = () => {
    handleEstadoIndicador(id, estado)
  }

  return ( 
    <div className="Indicador d-flex bg-light justify-content-between align-items-center p-3 mb-2">
      <span>{index+1}.- {nombre_proceso}</span>
      <IndicadorEstado
        variant={`${estado === 'Completo' ? 'success' : 'danger'}`}
       
      >
        {estado}
      </IndicadorEstado>
    </div>
   );
}
 
export default Indicador;