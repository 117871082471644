import React from 'react';
import { Form, Modal, Button, Spinner, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REQUIRED, TYPENUMBER, TYPE_NUMBER_INT, TYPE_NUMBER_POSITIVE } from '../../../../helpers/validationForms';
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { guardarPension } from '../../../../actions/cursosActions';

const FormPlanPagoUnico = ({ onCloseModal, plan }) => {

  const { idProyecto } = useParams();
  const { id_plan: idPlan, dt_plan: cuotasPlan } = plan;
  const { monto: cuotaMonto, id_dt_plan } = cuotasPlan.length > 0 ? cuotasPlan[0] : {};
  const dispatch = useDispatch();
  const { loadingPlan } = useSelector(state => state.cursos);

  const formik = useFormik({
    initialValues: {
      monto: cuotaMonto
    },
    validationSchema: yup.object({
      monto: yup.number()
        .typeError(TYPENUMBER)
        .positive(TYPE_NUMBER_POSITIVE)
        .required(REQUIRED)
    }),
    onSubmit: async valores => {
      const pension = [{
        monto: valores.monto,
        id_dt_plan: id_dt_plan || `pu-${new Date().getTime()}`,
        id_tp_concepto: 2
      }]
      dispatch(guardarPension(idProyecto, idPlan, pension, 2, onCloseModal));
    }
  });

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nombre de plan</Form.Label>
          <Form.Control type='text' defaultValue={plan.nombre_plan} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Tipo de plan</Form.Label>
          <Form.Control type='text' defaultValue={plan.tp_plan} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Costo del pago único</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>S/</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              name='monto'
              value={formik.values.monto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.monto}
            />
          </InputGroup>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.monto}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button disabled={loadingPlan} className='btn-spinner' variant='info' type='submit'>
          {
            loadingPlan && <Spinner as='span' animation='border' size='sm' />
          }
          Guardar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormPlanPagoUnico;