import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap'
import shortid from 'shortid'
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';

import './FormularioPropuesta.scss'

const FormularioPropuesta = props => {
  
  const { desabilitarBoton, guardarPropuesta, propuestaEditar, editarPropuesta } = props

  const [infoPropuesta, setInfoPropuesta] = useState( initialState() )

  const { propuesta } = infoPropuesta

  const handleChange = e => {
    setInfoPropuesta({
      ...infoPropuesta,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault()

    if (propuesta === '') {
      avisoError('No hay propuesta')
      return
    }

    if (Object.keys(propuestaEditar).length > 0) {
      editarPropuesta(infoPropuesta)
    }else {
      infoPropuesta.id_sugerencia_tutor = shortid.generate()
      guardarPropuesta(infoPropuesta)
    }
    setInfoPropuesta( initialState() )
  }

  const guardarAutocompletado = () => {
    if (Object.keys(propuestaEditar).length > 0) {
      setInfoPropuesta(propuestaEditar)
    }
  }

  useEffect(() => {
    guardarAutocompletado()
  }, [propuestaEditar])

  return ( 
    <div className="form-propuesta">
      <Form onSubmit={onSubmit}>
        <Form.Row>
          <Col md={10}>
            <Form.Control type="text" value={propuesta} onChange={handleChange} name='propuesta' placeholder="Tu propuesta" />
          </Col>
          <Col md={2}> 
            <Button type='submit' disabled={desabilitarBoton}>
              {(Object.keys(propuestaEditar).length > 0) ? 'Guardar' : 'Agregar'}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
   );
}
 
export default FormularioPropuesta;

const initialState = () => ({
  id_sugerencia_tutor: '',
  propuesta: ''
})