import React from 'react';
import { Link, useHistory } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux'; 
import { guardarCapacitacionActivo } from '../../../actions/controlCapacitacionesActions';

const CardControlCapacitacion = ({id_proyecto_}) => {

  const { todos: capacitaciones } = useSelector(state => state.control_capacitacion.capacitaciones)
  const capacitacion = capacitaciones.filter(item=>item.id_proyecto===id_proyecto_)
  const history = useHistory()
  const dispatch = useDispatch()

  const redireccionAreaTrabajo = (id_pro) => {
    dispatch( guardarCapacitacionActivo(capacitacion) )
    history.push(`/control-capacitaciones/${id_pro}/espacio-trabajo`)
  }

  return ( 
    <div className="card-capacitacion rounded">
      <div className="content detalles-evento p-3">
        <button
          className='btn btn-success mt-4'
          onClick={() => redireccionAreaTrabajo(id_proyecto_)}
        >
          Ir a panel de control
        </button>
      </div>
    </div>
   );
}
 
export default CardControlCapacitacion;