import React, { useEffect, useState } from 'react';
import {useParams, useHistory } from 'react-router-dom';
import { useDispatch, connect, useSelector } from 'react-redux';
//import { traerTema } from '../actions/cursosActions';
import { faBackward, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NuevoComentario from './NuevoComentario';
import Comentarios from './Comentarios';
import Layout from '../../../app/Layout';
import { traerTema } from '../../../actions/cursosActions';

const TemaForo = (props) => {

    const {tema_actual} = props
    const history = useHistory();
    const dispatch = useDispatch()
    const {tema} = useParams()
    const [loading,setLoading]=useState(true)
    const [temaF,setTema]=useState(null)
    const usuario = useSelector(state=> state.usuario.usuario);
    useEffect(()=>{
        const fetchData = async () =>{
            const rpta = await dispatch(traerTema(tema,usuario.id_usuario))
      
            if(rpta)
            {  
                setLoading(false)
            }
        }
        fetchData()
    },[])

    useEffect(()=>{
        if(tema_actual)
        {
            setTema(tema_actual[0])
        }
    },[tema_actual])

    return (

    <Layout>
       
    {  temaF ? <div className="container">

                    <div className="row">
                        <div className="box content-recursos-tab">
                            <div className="box-body">
                                        <h3>Tema y Comentarios</h3>
                                        <div className="d-flex justify-content-end mb-2">
                                        <button className="btn btn-danger" onClick={()=> history.goBack()}><FontAwesomeIcon icon={faBackward}/> Regresar</button>
                                        </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="row p-3 bg-white shadow-sm">
                        <div className="col-md-2">
                        {
                            temaF.img_usuario === null ? <img
                        
                                alt=""
                                src='/images/user.png'
                                style={{width:'70px', height:'70px', borderRadius:'100%'}}
                                /> :
                            <img
                                alt=""
                                src={`${process.env.REACT_APP_API_URL}/${temaF.img_usuario}`}
                                style={{width:'70px', height:'70px', borderRadius:'100%'}}
                                />
                            
                        }
                        </div>
                        <div className="col-md-6">
                        <h4>{temaF.titulo_tema}</h4>
                        <p>Por {temaF.nombre_usuario + ' ' + temaF.apellido_usuario} | <span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faComment} /></span>{temaF.cantidad_comentarios} comentarios</p>
                        </div>
                        <div className="col-md-4 p-3 text-right">
                        <p>Publicado el: {temaF.fecha_registro}</p>
                        <NuevoComentario id_tema={temaF.id_tema} slug={temaF.slug} propietario={temaF.autor} />
                        </div>
                        <div className="col-12 p-3 m-2" style={{borderTop:'1px solid #ebe'}}>
                       
                            <p>{temaF.descripcion_tema}</p>
                        </div>
                    </div>

                     <div className="row p-3 bg-white shadow-sm mt-2">
                     {
                      <Comentarios comentarios={temaF.comentarios} />
                     }
                    </div>  
            </div>:  <div className="row p-3 bg-white shadow-sm"><p>Lo sentimos, el tema en consulta no existe...</p></div>}
        
    </Layout>

    );
};

const mapStateToProps = state => ({
    loading: state.foro.loading,
    tema_actual : state.foro.tema_actual
})
export default connect(mapStateToProps, {})(TemaForo);