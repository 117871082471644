import React, { useState } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agregarModuloProyecto } from '../../../../actions/cursosActions';
import { REQUIRED } from '../../../../helpers/validationForms';
import useGetModulos from '../../../../hooks/useGetModulos';
import useSimpleSelect from '../../../../hooks/useSimpleSelect';
import { useParams } from 'react-router-dom';

const FormControlModulosProyetos = ({ onCloseModal }) => {
  
  const { idProyecto } = useParams();
  const { modulos } = useGetModulos();
  const { loadingEdit } = useSelector(state => state.cursos);
  const { SimpleSelect } = useSimpleSelect(modulos);
  const dispatch = useDispatch();

  const [moduloSeleccionado, setModuloSeleccionado] = useState(null);
  const [errorEleccion, setErrorEleccion] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    if(!moduloSeleccionado) {
      setErrorEleccion(REQUIRED);
      return;
    }
    dispatch(agregarModuloProyecto(idProyecto, moduloSeleccionado.id_modulo, onCloseModal));
  }

  return ( 
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Seleccione módulo</Form.Label>
          <SimpleSelect
            isClearable={true}
            getOptionValue={proyectos => proyectos.id_modulo}
            getOptionLabel={proyectos => proyectos.nombre_modulo}
            onChange={e => {
              setModuloSeleccionado(e);
              setErrorEleccion(null)
            }}
            value={moduloSeleccionado}
          />
          {
            errorEleccion && (
              <Form.Text className='text-danger'>
                {errorEleccion}
              </Form.Text>
            )
          }
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={onCloseModal}>
          Cancelar
        </Button>
        <Button disabled={loadingEdit} className='btn-spinner' type='submit' variant='info'>
          {
            loadingEdit && <Spinner as='span' animation='border' size='sm' />
          }
          Agregar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormControlModulosProyetos;