import React from "react";
import CountUp from "react-countup";

const dataContadores = [
  { final: 9, descript: "Años de experiencia", className: "contador" },
  {
    final: 45000,
    descript: "Estudiantes capacitados",
    className: "contador borde",
  },
  {
    final: 150,
    descript: "Institutciones capacitadas",
    className: "contador borde",
  },
  { final: 50, descript: "Programas realizados", className: "contador borde" },
];

const Contadores = () => {
  return (
    <>
      <div className="row">
        {dataContadores.map((contador, index) => (
          <div className="col-md-3" key={index}>
            <div className={contador.className}>
              <Contador final={contador.final} />
              <div className="descripcion-contador">
                <p>{contador.descript}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Contadores;

const onComplete = () => {
  console.log("Completed! 👏");
};

const onStart = () => {
  console.log("Started! 💨");
};

const Contador = (props) => {
  const { final } = props;

  return (
    <CountUp
      className="account-balance"
      start={0}
      end={final}
      duration={3}
      useEasing={true}
      useGrouping={true}
      separator=" "
      // prefix="+"
      // suffix=" left"
      onComplete={onComplete}
      onStart={onStart}
    />
  );
};
