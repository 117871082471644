import React, { useEffect } from "react";
import Layout from "../../../../app/Layout";
import HeaderContent from "../../General/HeaderContent";
import Breadcrumb from "../../General/Breadcrumb";
import MaterialTableDemo from "../../../components/plugins/MaterialTableDemo";
import { Badge } from 'react-bootstrap';

//Redux
import { connect } from "react-redux";
import { obtenerTutores, eliminarTutor } from "../../../../actions/tutoresActions";

import "./Tutores.scss";
import SpinnerLoading from "../../../components/Spinners/SpinnerLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Tutores = (props) => {
  //funciones de redux
  const { obtenerTutores, eliminarTutor } = props;
  //state de redux
  const { tutores, loading } = props;

  const initalColumns = () => {
    return [
      {
        text: '#',
        key: 'index',
        className: 'text-center',
        sortable: true,
        width: 50,
        cell: (_, index) => index+1
      },
      {
        text: "Id",
        key: "id_tutor",
        align: "center",
        sortable: true,
        className: 'text-center',
        width: 50
      },
      {
        text: "Nombre",
        key: "nombre_usuario",
        align: "center",
        sortable: true,
        className: "type_of_food",
        TrOnlyClassName: "table-head--primary",
      },
      {
        text: "Apellido",
        key: "apellido_usuario",
        align: "center",
        sortable: true,
        className: "type_of_food",
        TrOnlyClassName: "table-head--primary",
      },
      {
        text: "DNI",
        key: "dni",
        align: "center",
        sortable: true,
        className: "text-center",
      },
      {
        text: "Celular",
        key: "celular",
        align: "center",
        sortable: true,
        className: "text-center",
      },
      {
        text: "Estado",
        key: "nombre_tp_estado",
        align: "center",
        sortable: true,
        className: "text-center",
        cell: (record) => <Badge variant={`${record.id_tp_estado === '1' ? 'success' : 'danger'}`}>
          {record.nombre_tp_estado}
        </Badge>
      },
      {
        text: "Detalles",
        key: "detalles",
        aling: "center",
        sortable: true,
        className: "text-center",
        width: 90,
        cell: (response) => {
          return (
            <>
            <Link
              className="btn btn-warning btn-xs"
              to={`/tutores/${response.id_tutor}/detalles`}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <button className="btn btn-danger btn-xs" onClick={()=>eliminarTutor(response.id_usuario)}><i className="fa fa-trash"></i></button>
            </>
          );
        },
      },
    ];
  };

  useEffect(() => {
    if (!tutores.length) {
      obtenerTutores();
    }
  }, []);

  return (
    <Layout>
      <HeaderContent title="Tutores" />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Gestión del servicio" },
          { url: "", nombre: "Tutores" },
        ]}
      />
      <div className="tutores">
        <SpinnerLoading loading={loading}>
          <div className="container-fluid px-0 content-actions my-3">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <Link
                  className="btn btn-primary float-right text-white"
                  to="/tutores/asignar"
                >
                  <FontAwesomeIcon icon={faPlus} className='mr-2' />
                  Nueva asignación
                </Link>
              </div>
            </div>
          </div>
          <div className="tutores__main-tabla mb-5 p-0 bg-white">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h5 style={{ color: "#434343" }}>
                <i className="fas fa-list pr-2"></i>Lista de tutores
                </h5>
              </div>
              <div className="box-primar">
                <MaterialTableDemo
                  colums={initalColumns()}
                  tabla={[...tutores]}
                />
              </div>
            </div>
          </div>
        </SpinnerLoading>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  tutores: state.tutores.tutores,
  loading: state.tutores.loading,
});

const mapDispatchToProps = {
  obtenerTutores,
  eliminarTutor
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutores);
