import {examenesTypes} from '../actions/types'

const initialState = {
    examenes: [],
    examen:{},
    preguntas:[],
    loading:false
}

export default function(state = initialState, action){
    switch (action.type) {
        case examenesTypes.INSERTAR_EXAMEN:
            return{
                ...state,
                examen: action.payload
            }
        case examenesTypes.LISTAR_EXAMENES:
            return{
                ...state,
                loading: true
            }
        case examenesTypes.LISTAR_EXAMENES_EXITO:
                return{
                    ...state,
                    examenes: action.payload,
                    loading:false
                }
        case examenesTypes.OBTENER_EXAMEN_SESION:
                return{
                    ...state,
                    loading:true
                }
        case examenesTypes.OBTENER_EXAMEN_SESION_EXITO:
            return{
                ...state,
                loading:false,
                examen:action.payload.examen,
                preguntas: action.payload.preguntas
            }
        case examenesTypes.INSERTAR_PREGUNTA:
                return{
                    ...state,
                    loading:false,
                    preguntas:action.payload.preguntas
                }
    
        default:
            return state
    }
} 