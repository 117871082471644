import React, { useState } from 'react';
import { getFechaNormalizada, exportarZohoLead } from '../../../../actions/finanzasReportesActions';
import BotonesDescargas from '../BotonesDescargas';
import TablaReporteDeudores from './TablaReporteDeudores';
import { Button, Spinner } from 'react-bootstrap';
import { avisoCorrecto, avisoError } from '../../AvisosMensajes/MensajesSwalf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

const ResultadoReporteDeudas = ({ reporteDeudas, datosBusqueda = {} }) => {

  const { tabla, deuda_total } = reporteDeudas;
  const [loading, setLoading] = useState(null);

  const handleUrlDescarga = (tipo) => {
    if (!datosBusqueda) return;  
    const { idProyecto, fechaInicio, fechaFin } = datosBusqueda;
    const fechaInicioConvertido = getFechaNormalizada(fechaInicio)
    const fechaFinConvertido = getFechaNormalizada(fechaFin);
    return `${process.env.REACT_APP_API_URL}/admin/${tipo}/reporte/deudas/${idProyecto}/${fechaInicioConvertido}/${fechaFinConvertido}`;
  }

  
  const handleExportar = async () => {
    setLoading(true);
    const respuesta = await exportarZohoLead(reporteDeudas.tabla);
    setLoading(false);
    if(respuesta.exito) {
      avisoCorrecto(respuesta.message);
    } else {
      avisoError(respuesta.message);
    }
  }

  return ( 
    <div className="ResultadoReporteDeudas">
      <div className='d-flex justify-content-between align-items-end'>
        <BotonesDescargas 
          handleUrlDescarga={handleUrlDescarga}
          pdf={true}
          excel={true}
          csv={true}
        />
        <div>
          <Button className='btn-spinner btn-icon' disabled={loading} variant='info' onClick={handleExportar}>
            {
              !loading ? (
                <FontAwesomeIcon icon={faFileExport} />
              ) : (
                <Spinner as='span' animation='border' size='sm' />
              )
            }
            Exportar a Zoho
          </Button>
        </div>
      </div>
      <TablaReporteDeudores
        tabla={tabla}
      />
      <div className="resumen-generales d-flex justify-content-end align-items-center pr-3 mb-3">
        <h3 className="title-head mr-2">Total: S/. {deuda_total}</h3>
      </div>
    </div>
  );
}
 
export default ResultadoReporteDeudas;