import React, { useEffect, useState } from 'react';
import Layout from '../../../app/Layout';
import FormularioFiltroReporte from '../../components/ReporteNaranja/FormularioFiltroReporteNaranja';
import Breadcrumb from '../General/Breadcrumb';
import HeaderContent from '../General/HeaderContent';
import SpinnerLoading from '../../components/Spinners/SpinnerLoading'



//Redux
import { useSelector, useDispatch } from 'react-redux';
import { limpiarResultadoBusqueda, obtenerReporteNaranja } from '../../../actions/finanzasReportesActions';
import ResultadoRecaudacion from './resultadoRecaudacionVr2';
import ResultadoCalidad from './resultadoCalidad';
import ResultadoFidelidad from './resultadoFidelizacion';
import { Card } from 'react-bootstrap';

const ReporteNaranja = () => {

  const [datosBusqueda, setDatosBusqueda] = useState(null);
  const { loading, error, reporteNaranja } = useSelector(state => state.reporteNaranja);
  const dispatch = useDispatch();

  const handleObtenerReporteNaranja = (datos) => {
    //console.log(datos);
    dispatch(obtenerReporteNaranja(datos));
  }

  useEffect(() => {
    dispatch(limpiarResultadoBusqueda());
  }, [])

  return ( 
    <Layout>
      <div className="ControlReportes">
        <div className="ControlReportes__header">
          <HeaderContent title='Reporte Naranja' />
          <Breadcrumb
            link={[
              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
              { url: "", nombre: "Reportes" },
          { url: "", nombre: "Reporte Naranja" },
            ]}
          />
        </div>
        <div className="ControlReportes__body">
          <div className="box box-primary">
            <div className="box-body">
              <FormularioFiltroReporte
                onSubmit={handleObtenerReporteNaranja}
                setValores={setDatosBusqueda}
                ingresos
              />
            </div>
          </div>    
          <SpinnerLoading loading={loading} error={error}>
            {
            //console.log(reporteNaranja)
             <ResultadoRecaudacion recaudacion={reporteNaranja.recaudacion} />
            }
          </SpinnerLoading>
          <SpinnerLoading loading={loading} error={error}>
            <Card className="p-3 mb-3">
              <Card.Title>Reporte de Calidad</Card.Title>
              <Card.Body>
              {
              //console.log(reporteNaranja)
            <ResultadoCalidad calidad={reporteNaranja.calidad} />
              }
              </Card.Body>
            </Card>

          </SpinnerLoading>
          <SpinnerLoading loading={loading} error={error}>
          <Card className="p-3 mb-3">
         <Card.Title>Reporte de Fidelizacion</Card.Title>
         <Card.Body>
            {
            //console.log(reporteNaranja)
            <ResultadoFidelidad fidelizacion={reporteNaranja.fidelizacion} />
            }

            </Card.Body>
        </Card>
          </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default ReporteNaranja;