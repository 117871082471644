import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const useSimpleSelect = (opciones = [], seleccionInicial) => {
  
  const [defaultValue, setDefaultValue] = useState(null);

  const initialSeleccion = () => {
    const criterioBusqueda = Object.keys(seleccionInicial)[0];
    if(!!opciones.length) {
      const itemSeleccion = opciones.find(item => item[criterioBusqueda] === seleccionInicial[criterioBusqueda])
      if(itemSeleccion) {
        setDefaultValue(itemSeleccion);
      }
    }
  }

  useEffect(() => {
    if(seleccionInicial && !defaultValue) {
      initialSeleccion();
    }
  }, [opciones, defaultValue]);

  const SimpleSelect = props => {
    return (
      <Select
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay resultados'}
        placeholder='--Seleccione--'
        options={opciones}
        {...props}
      />
    )
  }

  return { defaultValue, SimpleSelect };
}
 
export default useSimpleSelect;