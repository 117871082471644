import React from 'react';
import { Badge, Button, Table } from 'react-bootstrap';
import * as moment from 'moment';
import { getColorEstadoPago } from '../../../../helpers/functions';
//Redux
import { useSelector } from 'react-redux';
import './TablaPension.scss';

const TablaPension = () => {

  const { todo: pension } = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones);
  const {
    concepto_pago,
    tp_cuota,
    fecha_limite,
    subtotal,
    nombre_tp_estado_pago
  } = pension;

  return (
    <div className='TablaPension box'>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Concepto</th>
            <th>Tipo</th>
            <th>Fecha límite</th>
            <th>Subtotal</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align='center'>1</td>
            <td align='center'>{concepto_pago}</td>
            <td align='center'>{tp_cuota}</td>
            <td align='center'>{fecha_limite && moment(fecha_limite).format('DD MMM YYYY')}</td>
            <td align='center'>{subtotal}</td>
            <td align='center'>
              <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`} >
                {nombre_tp_estado_pago}
              </Badge>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
 
export default TablaPension;