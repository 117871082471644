import React, { useEffect } from 'react';
import Layout from '../../../../../app/Layout';
import HeaderContent from '../../../General/HeaderContent';
import Breadcrumb from '../../../General/Breadcrumb';
import './PageCapacitaciones.scss'
import ListaCardControlCapacitacion from '../../../../components/ListaCardControlCapacitaciones';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerTodosCapacitacionesControl } from '../../../../../actions/controlCapacitacionesActions';
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';

const PageCapacitaciones = () => {

  const dispatch = useDispatch()
  const { todos: capacitacionesTodos, loading } = useSelector(state => state.control_capacitacion.capacitaciones)

  useEffect(() => {
    if (!capacitacionesTodos.length) {
      dispatch( obtenerTodosCapacitacionesControl() )
    }
  }, [])

  return ( 
    <Layout>
      <HeaderContent title='Capacitaciones'/>
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/", nombre: "Capacitaciones" },
        ]}
      />
      <div className="capacitaciones">
        <SpinnerLoading loading={loading}>
          <ListaCardControlCapacitacion/>
        </SpinnerLoading>
      </div>
    </Layout>
   );
}
 
export default PageCapacitaciones;