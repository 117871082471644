import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import configRouting from './configRouting';

const DashboardRoutes = () => {

  const { usuario, isLogin } = useSelector(state => state.usuario);

  if(isLogin === undefined) return null;

  return ( 
    <Switch>
      {configRouting.map((route, index) => {
        if(route.rol.indexOf(usuario.nombre_tp_nivel_usuario) >= 0) {
          return <Route key={index} path={route.path} exact={route.exact}>
            <route.page />
          </Route>
        } else {
          return null
        }
      })}
      <Redirect to='/inicio' />
    </Switch>
   );
}
 
export default DashboardRoutes;