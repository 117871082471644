import React from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateToogleLigthBox, nextSlide, prevSlide } from '../actions/lightBoxActions';

const withLightbox = WrapedComponent => props => {
  
  const dispatch = useDispatch()
  const lightBox = useSelector(state => state.lightBox)

  const handleClose = () => {
    dispatch( updateToogleLigthBox() )
  }

  const handleNext = () => {
    dispatch( nextSlide() )
  }

  const handlePrev = () => {
    dispatch( prevSlide() )
  }

  return ( 
    <WrapedComponent
      {...props}
      {...lightBox}
      handleClose={handleClose}
      handleNext={handleNext}
      handlePrev={handlePrev}
    />
   );
}
 
export default withLightbox;