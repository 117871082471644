import React from 'react';
import { useParams } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { editarPasswordTrabajador } from '../../actions/trabajadoresActions';

const withEditarPasswordSesion = WrappedComponent => props => {
  
  const dispatch = useDispatch();
  const { idTrabajador } = useParams();

  const handleEditarPassword = (nuevoPassword) => {
    dispatch( editarPasswordTrabajador(nuevoPassword, idTrabajador) )
  }

  return ( 
    <WrappedComponent
      {...props}
      handleEditarPassword={handleEditarPassword}
    />
  );
}
 
export default withEditarPasswordSesion;