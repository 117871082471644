import React from 'react';
import './InicioTutor.scss'
import Contadores from '../../components/plugins/Contadores';
import HeaderContent from '../General/HeaderContent';

const InicioDocente = () => {
  return ( 
    <div className="inicio-tutor">
      <HeaderContent title='Inicio'/>
      <div className="inicio-tutor__content box">
        <div className="vision-mision row">
          <div className="mision m-4 col-md-6">
            <p className="titulo">Bienvenido a la interfaz del docente</p>
            <p className="texto">
             Desde este espacio podrá responder las dudas o consultas comentadas en las sesiones que ha desarrollado.
            </p>
          </div>
        </div>
        </div>
    </div>
   );
}
 
export default InicioDocente;