import React from 'react';
import Sidemenu from './Sidemenu'
import Navigation from './Navigation'
import Footer from './Footer'

const Layout = ({children}) => {
    return (
      <div className="nav-md">
        <div className="container body">
          <div className="main_container">
            <div
              className="col-md-3 left_col"
            >
              <Sidemenu />
            </div>
            <div>
              <Navigation />
              
            </div>
            <div
              className="right_col"
              role="main"
              style={{ minHeight: "610px" }}
            >
                {children}
            </div>
            <div>
                <Footer/>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default Layout;