import {
  OBTENIENDO_TUTORES,
  TUTORES_OBTENIDOS,
  ERROR_OBTENER_TUTORES,
  GUARDAR_TUTORES_SELECT,
  OBTENIENDO_EVENTOS_TUTOR,
  EVENTOS_TUTOR_OBTENIDO,
  ELIMINAR_EVENTO_TUTOR_EXITO,
  ELIMINAR_TUTOR
} from "../actions/types";

const initialState = {
  tutores: [],
  selectTutores: [],
  eventosTutor: {
    eventos_tutor: []
  },
  encuestas: {
    encuesta_tutor: []
  },
  loading: false,
  error: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case OBTENIENDO_TUTORES:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENIENDO_EVENTOS_TUTOR:
      return {
        ...state,
        loading: true,
        error: null
      }
    
    case TUTORES_OBTENIDOS:
      return {
        ...state,
        loading: false,
        error: null,
        tutores: action.payload
      }
    case EVENTOS_TUTOR_OBTENIDO:
      return {
        ...state,
        loading: false,
        error: null,
        eventosTutor: action.payload
      }
    case ELIMINAR_EVENTO_TUTOR_EXITO:
      return {
        ...state,
        eventosTutor: action.payload
      }
    case GUARDAR_TUTORES_SELECT:
      return {
        ...state,
        loading: false,
        error: null,
        selectTutores: action.payload
      }
    case ERROR_OBTENER_TUTORES:
      return {
        ...state,
        error: action.payload
      }
    case ELIMINAR_TUTOR:
        return {
          ...state,
          tutores: [...state.tutores].filter(
            tutor => tutor.id_usuario !== action.payload
          )
    };
    default:
      return state;
  }
}