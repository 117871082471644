import React from 'react';
import { Card, Table } from "react-bootstrap";
const contenidoReporte = ({resultado}) => {

    let color_recursos = ''

    if(resultado.organizacion.recursos_academicos.examenes.semaforo==='verde' && resultado.organizacion.recursos_academicos.materiales.semaforo==='verde')
    {  
        color_recursos='verde'
    }
    else if(resultado.organizacion.recursos_academicos.examenes.semaforo==='ambar' || resultado.organizacion.recursos_academicos.materiales.semaforo==='ambar')
    {
        color_recursos='ambar'
    }
    else
    {
        color_recursos='rojo'
    }

    return (
        <div className="row">
            <div className="col-md-6">
            <Card className="p-3 mb-3">
            <Card.Title>Implementación</Card.Title>
            <Card.Body>

            <Table >
                <thead>
                    <tr style={{backgroundColor:'#262F48', color:'#fff'}}>
                        <th colSpan={3}>
                        Implementar producto
                        </th>
                    </tr>
                    <tr style={{backgroundColor:'#657FC5', color:'#fff'}}>
                        <th>
                        N° de sesiones
                        </th>
                        <th colSpan={2}>
                        Sesiones confirmadas
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">{resultado.implementacion.confirmacion.numero_sesiones_mes}</td>
                        <td className="text-center">{resultado.implementacion.confirmacion.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.implementacion.confirmacion.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                </tbody>
            </Table>

            <Table className="mt-3">
                <thead>
                    <tr style={{backgroundColor:'#262F48', color:'#fff'}}>
                        <th colSpan={3}>
                        Capacitar docentes 
                        </th>
                    </tr>
                    <tr style={{backgroundColor:'#657FC5', color:'#fff'}}>
                        <th>
                        N° de docentes
                        </th>
                        <th colSpan={2}>
                        Docentes Capacitados
                        </th>
                    
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="text-center">{resultado.implementacion.capacitacion.numero_sesiones_mes}</td>
                        <td className="text-center">{resultado.implementacion.capacitacion.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.implementacion.capacitacion.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                </tbody>
            </Table>
         
            </Card.Body>
            </Card>
            <Card className="p-3 mb-3">
            <Card.Title>Control</Card.Title>
            <Card.Body>
            <Table>
                <thead>
                    <tr style={{backgroundColor:'#262F48', color:'#fff'}}>
                        <th colSpan={3}>
                         Controlar calidad
                        </th>
                    </tr>

                    <tr style={{backgroundColor:'#657FC5', color:'#fff'}}>
                        <th >
                         Producto
                        </th>
                        <th colSpan={2} >
                         Promedio del mes
                        </th>
                    </tr>
                   
                </thead>
                <tbody>
   
                        { resultado.control.productos ?  resultado.control.productos.map((proyecto,i)=>(
                            <tr key={i}>
                                    <td className="text-center">{proyecto.nombre_proyecto}</td>
                                    <td className="text-center">{proyecto.promedio_bueno ? proyecto.promedio_bueno : 0.0} %</td>
                                    <td><span className={parseFloat(proyecto.promedio_bueno)>90?'semaforo_rn verde':'semaforo_rn rojo'} style={{margin:'auto'}}></span></td>
                             </tr>   
                        )) :
                            <tr>
                            <td colSpan={3} className="text-center">Aún no hay encuestas respondidas</td>
                            </tr>
                        }

                        <tr>
                                    <td className="text-center"><strong>Promedio global:</strong></td>
                                    <td className="text-center">{resultado.control.promedio? parseFloat(resultado.control.promedio).toFixed(2) : 0.0} %</td>
                                    <td><span className={parseFloat(resultado.control.promedio)>90?'semaforo_rn verde':'semaforo_rn rojo'} style={{margin:'auto'}}></span></td>
                        </tr> 
             
                </tbody>
            </Table>
            </Card.Body>
            </Card>
            </div>
            <div className="col-md-6">
            <Card className="p-3 mb-3">
            <Card.Title>Organización</Card.Title>
            <Card.Body>
            <Table >
                <thead>
                    <tr>
                        <th style={{backgroundColor:'#262F48', color:'#fff'}} colSpan={3}>
                        Registrar logística 
                        </th>
                    </tr>
                   
                </thead>
                <tbody>
                    <tr>
                        <td style={{backgroundColor:'#657FC5',color:'#fff', fontWeight:'bold'}}>
                        Productos en Ejecución:
                        </td>
                        <td colSpan={2} className="text-center">{resultado.organizacion.logistica.auditorio.numero_productos_activos}</td>
                      
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Contratos de Auditorio</td>
                        <td className="text-center">{resultado.organizacion.logistica.auditorio.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.logistica.auditorio.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Contratos de Impresión</td>
                        <td className="text-center">{resultado.organizacion.logistica.impresion.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.logistica.impresion.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Contratos de Productor Audio Visual</td>
                        <td className="text-center">{resultado.organizacion.logistica.productor.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.logistica.productor.semaforo} style={{margin:'auto'}}></span></td>
                     </tr>
                </tbody>
            </Table>
            <Table className="mt-3">
                <thead>
                    <tr>
                        <th style={{backgroundColor:'#262F48', color:'#fff'}} colSpan={3}>
                        Validar recursos 
                        </th>
                    </tr>
                 
                </thead>
                <tbody>
                    <tr>
                        <td style={{backgroundColor:'#657FC5', color:'#fff', fontWeight:'bold'}}>
                        N° de sesiones
                        </td>
                        <td colSpan={2} className="text-center">{resultado.organizacion.recursos_academicos.examenes.numero_sesiones_mes}</td>
                        
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con PodCasts</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.podcasts.sesiones_reg}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.recursos_academicos.podcasts.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con Clase Online</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.online.sesiones_reg}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.recursos_academicos.online.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con Clases OffLine</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.offline.sesiones_reg}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.recursos_academicos.offline.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con Materiales</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.materiales.sesiones_reg}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.recursos_academicos.materiales.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con Actividades</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.actividades.sesiones_trabajadas}</td>
                        <td><span className="semaforo_rn" style={{margin:'auto'}}></span></td>
                    </tr>
                    <tr>
                        <td style={{backgroundColor:'#F6F8FA', fontWeight:'bold'}}>Sesiones con Quizzes</td>
                        <td className="text-center">{resultado.organizacion.recursos_academicos.examenes.sesiones_trabajadas}</td>
                        <td><span className={"semaforo_rn "+resultado.organizacion.recursos_academicos.examenes.semaforo} style={{margin:'auto'}}></span></td>
                    </tr>
                   
                </tbody>
            </Table>
            </Card.Body>
            </Card>
            </div>
            <div className="col-md-6">
         
            </div>
        </div>
    );
};

export default contenidoReporte;