import React, { useEffect } from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb';
import ListaEventos from '../../../components/ListaEventos';
import SpinnerLoading from '../../../components/Spinners/SpinnerLoading';

//Redux
import { connect } from 'react-redux'
import { obtenerEventosTutor } from '../../../../actions/tutoresActions';

import './EventosTutor.scss';

const EventosTutor = props => {

  const { obtenerEventosTutor, loading, usuario } = props

  useEffect(() => {
    obtenerEventosTutor(usuario.id_empleado)
  }, [])

  return ( 
    
    <Layout>
      <div className="eventos-tutor">
        <HeaderContent
          title='Eventos'
        />
        <Breadcrumb
          link={[
            {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
            {url:'', nombre: 'Eventos'}
          ]}
        />
     
          <SpinnerLoading loading={loading}>
            <div className="main-eventos">
              <ListaEventos/>
            </div>
          </SpinnerLoading>
        
      </div>
    </Layout>
  
   );
}
 
const mapStateToProps = state => ({
  loading: state.tutores.loading,
  usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  obtenerEventosTutor
}

export default connect(mapStateToProps, mapDispatchToProps)(EventosTutor);