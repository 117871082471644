import zIndex from '@material-ui/core/styles/zIndex';
import React, { Component } from 'react';
import Portal from './Portal';
class Modal extends Component {

   

    render() {

     const {children,toggle,active} = this.props
      return( <Portal>
        {active && 
            <div onClick={toggle} style={styles.wrapper}>
                <div style={styles.window}>
                    <div>{children}</div>
                </div>
           
            </div>
        }
       </Portal>
       )
    }
}

export default Modal;
const styles={
    wrapper:{
        position:'fixed',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        zIndex:11,
        background:'rgba(0,0,0,0.5)',
    },
    window:{
        position:'relative',
        background: "url('images/fondo_pop_up.png') no-repeat", 
        backgroundSize:"cover",
        borderRadius:5,
        padding:0,
        boxShadow:'2px 2px 10px rgba(0,0,0,0.3)',
        zIndex:12,
        maxWidth:800,
        width:600,
        height:619
    },


}