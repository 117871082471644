import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { listarEstudiante } from '../actions/estudiantesActions';

export default (value, criterio) => {
  const { estudiante } = useSelector(state => state.estudiantes);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const obtenerDiscente = async (valueParam, criterioParam) => {
    setLoading(true);
    await dispatch(listarEstudiante(valueParam, criterioParam));
    setLoading(false);
  }

  useEffect(() => {
    if(value || criterio){
      dispatch(listarEstudiante(value, criterio));
    }
  }, [])

  return { estudiante, loading, obtenerDiscente };
}  