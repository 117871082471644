import React from 'react';
import ModalBasicwithRedux from '../../../../../containers/ModalBasicwithRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faBriefcase,
  faAddressCard,
  faBirthdayCake,
  faPhone,
  faHome,
  faEnvelope,
  faUserClock,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import './VistaDatosPersonales.scss';


const getEdad = (fechaNacimiento) => {
  if(fechaNacimiento === undefined) {
    return 'No hay datos';
  }
  const dateNacimiento = new Date(fechaNacimiento);
  return moment(new Date()).diff(moment(dateNacimiento), "years")
}

const VistaDatosPersonales = ({ datos, handleEditar }) => {

  const { dni, fecha_nac, nombre_usuario, apellido_usuario, celular, direccion, email} = datos;
  
  return ( 
    <div className="VistaDatosPersonales">
      <section className='VistaDatosPersonales__container'>
          <div className="box-header with-border d-flex align-items-center justify-content-between">
            <h6 className='title-head mb-0'>
             
              
            </h6>
            {
              handleEditar && <div className='cursor-pointer'>
                <FontAwesomeIcon icon={faEdit} className='mr-1' />
                <span onClick={handleEditar}>Editar</span>
              </div>
            }
          </div>
        <div className='VistaDatosPersonales__body p-3 py-4'>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faAddressCard}/>
              <span>Usuario</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{nombre_usuario}{' '}{apellido_usuario}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faAddressCard}/>
              <span>Documento de Identidad</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{dni ? dni : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faBirthdayCake} />
              <span>Fecha de cumpleaños</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{(fecha_nac && fecha_nac !== '0000-00-00') ? moment(fecha_nac).format('DD MMM') : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faUserClock} />
              <span>Edad</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{(fecha_nac && fecha_nac !== '0000-00-00') ? getEdad(fecha_nac) : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faPhone} />
              <span>Teléfono móvil</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{celular ? celular : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faHome} />
              <span>Dirección</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{direccion ? direccion : 'No hay datos'}</span>
            </div>
          </div>
          <div className="datos-info">
            <div className='datos-info__top'>
              <FontAwesomeIcon icon={faEnvelope} />
              <span>Correo personal</span>
            </div>
            <div className='datos-info__bottom'>
              <span>{email ? email : 'No hay datos'}</span>
            </div>
          </div>
     
        </div>
      </section>
      <ModalBasicwithRedux/>
    </div>
   );
}
 
export default VistaDatosPersonales;