import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioWebinar from './FormularioWebinar';
import FormularioFiltro from "./FormularioFiltro";
import ResultadosFiltro from "./ResultadosFiltro"
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { eliminarWebinar, listarWebinars } from "../../../actions/campaignsActions";
import { Button } from "react-bootstrap";
import EditarWebinar from "./EditarWebinar";
import ComentarioWebinar from "./ComentarioWebinar";
 
const ListaWebinars = () => {
  //State
  const dispatch = useDispatch();

  const {webinars,loading,resumen_webinar} = useSelector(state=>state.campaigns)

  useEffect(() => {
   dispatch(listarWebinars())
  }, []);

  const handleModalNuevo = () => {
    dispatch(updateStateModalLarge(<FormularioWebinar />, 'Nuevo Webinar'));
  }
  const handleEditar = (registro) => {
    dispatch(updateStateModalLarge(<EditarWebinar registro={registro} />, 'Editar Webinar'));
  }
  const handleEliminar = (id) =>{
    dispatch(eliminarWebinar(id))
  }
  
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Webinars" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "", nombre: "Webinars" },
              ]}
            />
            <button className="btn btn-primary" onClick={()=>handleModalNuevo()}> Nuevo Webinar </button>
            <div className="row mt-1">
            <div className="col-md-4">
              <FormularioFiltro/>
            </div>
            {
              resumen_webinar && resumen_webinar.campaign ?  <div className="col-md-8"> <ResultadosFiltro resultados={resumen_webinar}/> </div>: ''
            }
            </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 mt-1 content-actions">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-1">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Webinars
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(handleEliminar,handleEditar)}
                    tabla={webinars}
                    keyCol="id_webinar"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaWebinars;

/**====================== TABLA =========== */

const colums = (handleEliminar,handleEditar) => [
  {
    text: 'ID',
    key: 'id_webinar',
    className: 'id_webinar',
    width: 50
  }
  ,
  {
    text: "Fecha Realización",
    key: "fecha_realizacion",
    align: "center",
    sortable: true,
    className: "fecha_realizacion"
  }
  ,
  {
    text: "Nombre Evento",
    key: "nombre_evento",
    align: "center",
    sortable: true,
    className: "nombre_evento",
  },
  {
    text: "Campaña",
    key: "nombre_proyecto",
    align: "center",
    sortable: true,
    className: "nombre_proyecto"
  }
  ,
  {
    text: "Desde",
    key: "desde",
    align: "center",
    className: "desde",
    sortable: true,
  }
  ,
  {
    text: "Hasta",
    key: "hasta",
    align: "center",
    className: "hasta",
    sortable: true
  }
  ,

  {
    text: "Cant. Leads",
    key: "cantidad_leads",
    align: "center",
    className: "cantidad_leads",
    sortable: true
  },
  {
    text: "Total Asistentes",
    key: "total_asistentes",
    align: "center",
    sortable: true,
    className: "total_asistentes"
  }
  ,
  {
    text: "Prom. Asist. Máx.",
    key: "promedio_asistentes_mx",
    align: "center",
    sortable: true,
    className: "promedio_asistentes_mx"
  }
  ,
  {
    text: "Prom. Asist. Mín.",
    key: "promedio_asistentes_mn",
    align: "center",
    sortable: true,
    className: "promedio_asistentes_mn"
  }
  ,
  {
    text: "Inscritos",
    key: "inscritos",
    align: "center",
    sortable: true,
    className: "inscritos"
  }
  ,
  {
    text: "% Asistencia",
    key: "p_asistencia",
    align: "center",
    sortable: true,
    className: "p_asistencia"
  }
  ,
  {
    text: "% Inscritos",
    key: "p_inscritos",
    align: "center",
    sortable: true,
    className: "p_inscritos"
  }
  ,
  {
    text: "Observaciones",
    key: "observaciones",
    align: "center",
    sortable: true,
    className: "observaciones"
  }
  ,
  {
    text: "Grabación",
    key: "grabacion",
    align: "center",
    sortable: true,
    className: "grabacion",
    cell:(record) => record.grabacion ? <span style={{display:'inline'}}><a href={record.grabacion} className='btn btn-info' aria-label='see' target='_blank'><i className='fas fa-link'></i></a></span> : ''

  }
  ,
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <p className='text-center'>
    <Button
      variant='warning'
      size='xs'
      type="button"
      onClick={() => handleEditar(record)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
    <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_webinar)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  </p>
  }
  
];
