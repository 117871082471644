import {respuestasComentariostypes} from '../actions/types'

const initialState = {
    comentarios: [],
    loading:false,
}

export default function(state = initialState, action){
    switch (action.type) {
        case respuestasComentariostypes.LISTAR_COMENTARIOS_EXITO:
            return{
                ...state,
                comentarios: action.payload.comentarios,
                loading:false,
            }
        case respuestasComentariostypes.LISTAR_COMENTARIOS:
                return{
                    ...state,
                    loading:true,
                }
        default:
            return state
    }
} 