import React from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import {
  obtenerEncuestasReportesTutor,
  obtenerEncuestaDocente,
} from "../../../actions/encuestasAction";
import { eliminarEventosTutor } from "../../../actions/tutoresActions";
import "./CardEvento.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";
import ListaReportesTutor from "../ListaReportesTutor";
import ListaEncuestasDocente from "../ListaEncuestasDocente";

const CardEvento = (props) => {
  
  const {
    usuario,
    evento,
    setListaReporte,
    updateShowModal,
    obtenerEncuestasReportesTutor,
    obtenerEncuestaDocente,
    eliminarEventosTutor,
  } = props;

  const listarEncuestasTutor = () => {
    setListaReporte(<ListaReportesTutor />);
    obtenerEncuestasReportesTutor(evento.id_tutor_proyecto);
    updateShowModal(true);
  };

  const listarEncuestasDocente = () => {
    setListaReporte(<ListaEncuestasDocente />);
    obtenerEncuestaDocente(evento.id_proyecto);
    updateShowModal(true);
  };

  return (
    <Container fluid className="main-evento w-100 bg-white p-4 mb-4">
      <Row>
        <Col md={12} className="main-evento__right-content">
          <div className="content detalles-evento">
            <h5 className="mb-3 sub-title">Nombre de evento</h5>

            <p>{evento.nombre_proyecto}</p>

            <div className="botones mt-5 d-flex justify-content-between">
              <Dropdown>
                <Dropdown.Toggle variant="info" id="dropdown-basic">
                  Opciones
                </Dropdown.Toggle>

                <Dropdown.Menu className="lista-botones">
                  <Dropdown.Item>
                    <Link
                      to={`/tutor/eventos/${evento.id_proyecto}/${evento.id_tutor_proyecto}/encuestas`}
                      className="lista-botones__item"
                    >
                      Encuestas discentes
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => listarEncuestasTutor()}
                  >
                    Encuesta tutor
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => listarEncuestasDocente()}
                  >
                    Encuesta docente
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {usuario.id_tp_nivel_usuario !== "7" ? (
                <Tooltip title="Eliminar">
                  <Button
                    variant=""
                    hidden={false}
                    onClick={() =>
                      eliminarEventosTutor(evento.id_tutor_proyecto)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ fontSize: "20px" }}
                    />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuario,
});

const mapDispatchToProps = {
  obtenerEncuestasReportesTutor,
  eliminarEventosTutor,
  obtenerEncuestaDocente,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardEvento);
