import React from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { nuevoDocente } from '../../actions/profesoresActions';

const withRegistrarDocente = WrappedComponent => props => {
  
  const dispatch = useDispatch();


  const handleSubmitAction = datos => {
    dispatch( nuevoDocente(datos) )
  }

  return (  
    <>
    <WrappedComponent
      {...props}
      handleSubmitAction={handleSubmitAction}
    />
    </>
  );
}
 
export default withRegistrarDocente;