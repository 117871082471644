import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faListAlt, faMailBulk, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
//import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import DataTableComponent from "../../components/plugins/DataTableComponent";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalBasic } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalBasicwithRedux";
import FormularioLead from './FormularioLead';
import FormularioFiltro from './FormularioFiltro'
import "./Trabajadores.scss";

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { enviarCorreoLeadProducto, listarEstados, listarLeads, listarObservaciones, registrarClick} from "../../../actions/leadsActions";
import { Button } from "react-bootstrap";
import EditarLead from "./EditarLead";
import FormularioAsignacionManual from "./FormularioAsignacionManual";
//import { avisoCorrecto, avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
import FormularioFiltroAsesor from "./FormularioFiltroAsesor";
import ResultadosImportacion from "./ResultadosImportacion";
import { avisoCorrecto, avisoError } from "../../components/AvisosMensajes/MensajesSwalf";
 
const ListaLeads = () => {
  //State
  const dispatch = useDispatch();

  const {leads,loading,respuesta_importacion} = useSelector(state=>state.leads)
  const {usuario} = useSelector(state=>state.usuario)
  const [busqueda,setBusqueda]=useState('')
  const [asignarSeleccion, setSeleccion]=useState(null)
  const nivel_usu = [11,13]
  const handleChange = e => {
    setBusqueda(e.target.value)
  }
  useEffect(() => {
  // dispatch(listarLeads(usuario))
   dispatch(listarEstados())
   dispatch(listarObservaciones())
  }, []);

  const handleModalNuevo = () => {
    dispatch(updateStateModalBasic(<FormularioLead />, 'Importar Leads'));
  }
  const handleEditarRegistro = (registro) =>{
    dispatch(updateStateModalBasic(<EditarLead registro={registro} />, 'Editar Lead'));
  }
  const handleRegistrarClick = (registro,tipo)=>{
    const datos = {
      autor:usuario.id_usuario,
      id_lead: registro.id_lead,
      tipo:tipo,
    }

    dispatch(registrarClick(datos));
  }
  const handleAsignarManualmente = () =>{
    dispatch(updateStateModalBasic(<FormularioAsignacionManual leads={asignarSeleccion.selectedRows} resetSeleccion={setSeleccion} />, 'Asignar Leads'));
  }
  const handleEliminar = (id) =>{
   // dispatch(eliminarComunicado(id))
  }

  const handleEnviarCorreo =  (datos) => {
    dispatch(enviarCorreoLeadProducto(datos))
   }
  
  const leadsBusqueda = leads ? leads.filter(
    lead =>
        lead.nombres &&  
        lead
        .nombres
        .toLowerCase()
        .includes(busqueda.toLowerCase()) ||
        lead
        .apellidos && 
        lead
        .apellidos
        .toLowerCase()
        .includes(busqueda.toLowerCase()) ||
        lead
        .correo &&
        lead
        .correo
        .toLowerCase()
        .includes(busqueda.toLowerCase()) ||
        lead
        .fuente &&
        lead
        .fuente 
        .toLowerCase()
        .includes(busqueda.toLowerCase())  
    

  ) : null

    
   return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            
              <HeaderContent title="Leads" icon="group" />
              <Breadcrumb
                      link={[
                              { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                              { url: "", nombre: "Leads" },
                            ]}
                    />
                    <div className="row">
                    <div className="col-md-6 d-flex flex-row"> 
                  { !nivel_usu.includes(parseInt(usuario.id_tp_nivel_usuario)) ? 
                        <button className="btn btn-primary mb-2" onClick={()=>handleModalNuevo()}> Importar Leads </button>
                        : '' 
                  }
                  { usuario.id_tp_nivel_usuario!=="11" && asignarSeleccion && asignarSeleccion.selectedCount>0 ?  
                        <> <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Acciones de Selección
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" onClick={()=>handleAsignarManualmente()}> Asignar Manualmente </a>
                            </div>
                          </div></>
                        : '' 
                  }
                  </div>
                  </div>
              <div className="row">
                      {
                      usuario.id_tp_nivel_usuario!=='11' ? <div className="col-md-6">
                      <FormularioFiltro />
                      </div>
                      :
                      <div className="col-md-6">
                      <FormularioFiltroAsesor />
                      </div> 
                      }

                      {
                        respuesta_importacion ?  <div className="col-md-6"><ResultadosImportacion resultados={respuesta_importacion} /></div>  : ''
                      }
              </div>
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-1">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-2">
                <div className="box-header with-border clearfix">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Leads
                  </h5>
                  <div className="col-3 d-flex align-items-center pull-right">
                    <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} placeholder="Filtrar por Nombres o Apellidos..." />
                    <FontAwesomeIcon icon={faSearch} size="lg"/>
                  </div>
                </div>
                <div className="box-primary">
                  <DataTableComponent
                    colums={colums(handleEliminar,handleEditarRegistro,handleRegistrarClick,handleEnviarCorreo)}
                    tabla={leadsBusqueda}
                    keyField="id_lead"
                    setSeleccion={setSeleccion}
                    select={usuario.id_tp_nivel_usuario!=="11"?true:false}
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListaLeads;

/**====================== TABLA =========== */

const colums = (handleEliminar,handleEditarRegistro,handleRegistrarClick,handleEnviarCorreo) => [
  {
    name: 'Canal',
    selector: 'fuente',
    className: 'fuente',
    width:"100px"
  }
  ,
  {
    name: "Fecha Registro",
    selector: "fecha_registro_lead",
    align: "center",
    className: "fecha_registro_lead",
    sortable: true,
    width:"150px",
    compact:true
  },
  {
    name: "Nombres",
    selector: "nombres",
    align: "center",
    sortable: true,
    className: "nombres",
    compact:true,
    wrap:true,
    width:"100px"
  },
  {
    name: "Apellidos",
    selector: "apellidos",
    align: "center",
    sortable: true,
    className: "apellidos",
    compact:true,
    wrap:true,
    width:"100px"
  }
  ,
  {
    name: "Correo",
    selector: "correo",
    align: "center",
    className: "correo",
    sortable: true,
    compact:true,
    wrap:true
  }
  ,
  {
    name: "Teléfono",
    selector: "telefono",
    align: "center",
    className: "telefono",
    sortable: true,
    compact:true,
    wrap:true
  }
  ,
  {
    name: "WhatsApp/Toky",
    selector: "telefono",
    align: "center",
    className: "telefono",
    width: "150px",
    //<a href={`tel:${record.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,2)}><i className='fas fa-mobile-alt'></i></a>
    cell:(record) => <div className="d-flex justify-content-between"><a href={`tel:${record.telefono}`} className='btn btn-warning' aria-label='see' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,2)}><i className='fas fa-mobile-alt'></i></a> <a href={`https://api.whatsapp.com/send?phone=${record.telefono}&name=`} className='btn btn-success' aria-label='see' target='_blank' rel='noopener noreferrer' onClick={()=> handleRegistrarClick(record,1)}><i className='fab fa-whatsapp'></i></a><a href={`https://app.toky.co/business/dialer#?call=${record.telefono}`} className='btn btn-info' aria-label='see' target='_blank' rel='noopener noreferrer'><i className='fa fa-phone'></i></a></div>
  }
  ,
  {
    name: "Producto",
    selector: "nombre_proyecto",
    align: "center",
    className: "proyecto",
    sortable: true,
    width:"100px",
    wrap:true
  }
  ,
  {
    name: "Estado",
    selector: "nombre_estado",
    align: "center",
    className: "estado",
    sortable: true,
    width:"100px",
    wrap:true
  }

  ,
  {
    name: "Asesor",
    selector: "nombre_usuario",
    align: "center",
    className: "nombre_asesor",
    sortable: true,
    width:"100px",
    wrap:true,
  }
  ,
  {
    name: "Notas",
    selector: "notas",
    align: "center",
    className: "notas",
    sortable: true
  }
  ,
  {
    name: "Observaciones",
    selector: "observacion_n",
    align: "center",
    className: "notas",
    sortable: true
  }
  ,
  {
    name: "Fecha Llamada",
    selector: "fecha_llamada",
    align: "center",
    className: "proyecto",
    sortable: true,
    width:"80px",
  }
  
  ,
  /*{
    name: "Fecha Captado",
    selector: "fecha_registro_externo",
    align: "center",
    className: "fecha_registro_externo",
    sortable: true
  }
  
  ,*/
  {
    name: "Enviado a Zoho",
    selector: "fecha_zoho",
    align: "center",
    className: "proyecto",
    sortable: true,
    width:"80px",
  }
  
  ,
  {
    name: "Acciones",
    selector: "Acciones",
    align: "center",
    width:"80px",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <p>
    <Button
        variant='warning'
        size='xs'
        type="button"
        onClick={() => handleEditarRegistro(record)}
      >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
    <button className="btn btn-info mr-1 btn-xs" onClick={() => handleEnviarCorreo(record)} type="button" title="Enviar Correo">
          <FontAwesomeIcon icon={faMailBulk} />
     </button>
   { /* <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_lead)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>*/}
  </p>
  }
  
];
