import React from 'react';
import useGetCapacitacionControl from '../../../../../../hooks/useGetCapacitacionControl';
import Indicador from '../../../../../../containers/ControlCapacitacionesComtainers/Ajuste';

const ListaIndicadores = () => {

  const [{ activo: {calidad} }] = useGetCapacitacionControl();

  return ( 
    <div className="ListaIndicadores my-3">
      {[...calidad].map((item, index) => (
        <Indicador key={item.id} index={index} {...item} />
      ))}
    </div>
   );
}
 
export default ListaIndicadores;