import React, { useState } from "react";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { faCalendarAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './FormHorarioProyecto.scss';
import { REQUIRED, TYPENUMBER, TYPE_NUMBER_POSITIVE, TYPE_NUMBER_INT } from "../../../../helpers/validationForms";
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { editarHorarioProyecto } from "../../../../actions/cursosActions";
import { avisoCorrecto, avisoError } from "../../AvisosMensajes/MensajesSwalf";

const FormHorarioProyecto = ({ datosHorario = {} }) => {
  
  const [turno, setTurno] = useState(datosHorario.id_tp_turno);
  const { loadingEdit, errorEdit } = useSelector(state => state.cursos);
  const dispatch = useDispatch();
  const { idProyecto } = useParams();  

  const formik = useFormik({
    initialValues: datosHorario || initialValues(),
    validationSchema: yup.object(validationSchema(turno)),
    onSubmit: async valores => {
      await dispatch(editarHorarioProyecto(idProyecto, valores));
  
    }
  });

  const inputsDia = () => (
    <>
      <Form.Group as={Col} md={4}>
        <Form.Label>Ingreso Mañana</Form.Label>
        <Form.Control 
          type="time"
          name='hora_ingreso_tur_m'
          value={formik.values.hora_ingreso_tur_m}
          onChange={formik.handleChange}
          isInvalid={formik.errors.hora_ingreso_tur_m}  
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.hora_ingreso_tur_m}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md={4}>
        <Form.Label>Salida mañana</Form.Label>
        <Form.Control
          type='time'
          name='hora_salida_tur_m'
          value={formik.values.hora_salida_tur_m}
          onChange={formik.handleChange}
          isInvalid={formik.errors.hora_salida_tur_m}  
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.hora_salida_tur_m}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )

  const inputsTarde = () => (
    <>
      <Form.Group as={Col} md={4}>
        <Form.Label>Ingreso Tarde</Form.Label>
        <Form.Control
          type="time"
          name='hora_ingreso_tur_t'
          value={formik.values.hora_ingreso_tur_t}
          onChange={formik.handleChange}
          isInvalid={formik.errors.hora_ingreso_tur_t}  
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.hora_ingreso_tur_t}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md={4}>
        <Form.Label>Salida Tarde</Form.Label>
        <Form.Control
          type='time'
          name='hora_salida_tur_t'
          value={formik.values.hora_salida_tur_t}
          onChange={formik.handleChange}
          isInvalid={formik.errors.hora_salida_tur_t}  
        />
        <Form.Control.Feedback type='invalid'>
          {formik.errors.hora_salida_tur_t}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
  
  return (
    <Form className='FormHorarioProyecto' onSubmit={formik.handleSubmit}>
      <section>
        <header className="form-header">
          <FontAwesomeIcon icon={faCalendarAlt} />
          Configuración fechas
        </header>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Fecha de inicio</Form.Label>
            <Form.Control
              type="date"
              name='fecha_inicio_proyecto'
              value={formik.values.fecha_inicio_proyecto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_inicio_proyecto}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.fecha_inicio_proyecto}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Fecha de culminación</Form.Label>
            <Form.Control
              type="date"
              name='fecha_fin_proyecto'
              value={formik.values.fecha_fin_proyecto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_fin_proyecto}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.fecha_fin_proyecto}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Fecha de Entrega Certificado</Form.Label>
            <Form.Control
              type="date"
              name='fecha_entrega_cert'
              value={formik.values.fecha_entrega_cert}
              onChange={formik.handleChange}
              isInvalid={formik.errors.fecha_entrega_cert}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.fecha_entrega_cert}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md={4}>
            <Form.Label>Dia de clase</Form.Label>
            <Form.Control 
              type='text'
              name='dias_clase'
              value={formik.values.dias_clase}
              onChange={formik.handleChange}
              
            />
            
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Frecuencia</Form.Label>
            <Form.Control
              as="select"
              name='frecuencia_clases'
              value={formik.values.frecuencia_clases}
              onChange={formik.handleChange}
              isInvalid={formik.errors.frecuencia_clases}  
              >
              <option value="" disabled>
                --Seleccione--
              </option>
              <option value="Diario">Diario</option>
              <option value="Interdiario">Interdiario</option>
              <option value="Semanal">Semanal</option>
              <option value="Quincenal">Quincenal</option>
              <option value="Mensual">Mensual</option>
              <option value="Bimestral">Bimestral</option>
              <option value="Trimestral">Trimestral</option>
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {formik.errors.frecuencia_clases}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Tiempo de tolerancia</Form.Label>
            <Form.Control
              type="text"
              name='tiempo_tolerancia'
              value={formik.values.tiempo_tolerancia}
              onChange={formik.handleChange}
              isInvalid={formik.errors.tiempo_tolerancia}  
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.tiempo_tolerancia}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      </section>
      <section>
        <header className="form-header">
          <FontAwesomeIcon icon={faCalendarAlt} />
          Configuración de turnos
        </header>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Turno</Form.Label>
            <Form.Control
              className='select-turno'
              as="select"
              name='id_tp_turno'
              value={formik.values.id_tp_turno}
              onChange={e => {
                setTurno(parseInt(e.target.value))
                formik.setFieldValue('id_tp_turno', parseInt(e.target.value));
              }}
              isInvalid={formik.errors.id_tp_turno}  
            >
              <option value="" disabled>
                --Seleccione--
              </option>
              <option value="1">Mañana</option>
              <option value="2">Tarde</option>
              <option value="3">Ambos</option>
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {formik.errors.id_tp_turno}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {
            turno === 1 || turno === 3 ? (
              inputsDia()
            ) : null
          }
        </Form.Row>
        <Form.Row>
          {
            turno === 2 || turno === 3 ? (
              inputsTarde()
            ) : null
          }
        </Form.Row>
        <hr/>
        <Form.Row>
          <Col sm={12}>
            <Button disabled={loadingEdit} variant='info' className='btn-icon btn-spinner' type='submit'>
              {
                loadingEdit ? <Spinner as='span' animation='border' size='sm' /> : (
                  <FontAwesomeIcon icon={faSave} />
                )
              }
              Guardar configuración de horario
            </Button>
          </Col>
        </Form.Row>
      </section>
    </Form>
  );
};

export default FormHorarioProyecto;

const initialValues = () => ({
  fecha_inicio_proyecto: '',   
  fecha_fin_proyecto: '',
  fecha_entrega_cert: '',   
  dias_clase: '', 
  horas_frecuencia: '', 
  frecuencia_clases: '', 
  hora_ingreso_tur_m: '',
  hora_salida_tur_m: '', 
  hora_ingreso_tur_t: '', 
  hora_salida_tur_t: '', 
  tiempo_tolerancia: '',
  id_tp_turno: ''
})

const validationSchema = (turno) => {
  let initialSchema = {
    fecha_inicio_proyecto : yup.date().required(REQUIRED).nullable(),   
    fecha_fin_proyecto : yup.date().required(REQUIRED).nullable(), 
    fecha_entrega_cert : yup.date().required(REQUIRED).nullable(),   
    dias_clase : yup.string().required(REQUIRED).nullable(), 
    frecuencia_clases : yup.string().required(REQUIRED).nullable(),
    id_tp_turno: yup.number()
      .integer(TYPE_NUMBER_INT)
      .typeError(TYPENUMBER)
      .positive(TYPE_NUMBER_POSITIVE)
      .required(REQUIRED),
    tiempo_tolerancia: yup.number()
    .integer(TYPE_NUMBER_INT)
    .typeError(TYPENUMBER)
    .positive(TYPE_NUMBER_POSITIVE)
    .required(REQUIRED)
    .nullable()
  }
  if(turno === 1) {
    initialSchema = {
      ...initialSchema,
      hora_ingreso_tur_m: yup.string().required(REQUIRED).nullable(),
      hora_salida_tur_m: yup.string().required(REQUIRED).nullable(), 
    }
  } else if (turno === 2) {
    initialSchema = {
      ...initialSchema,
      hora_ingreso_tur_t: yup.string().required(REQUIRED).nullable(), 
      hora_salida_tur_t: yup.string().required(REQUIRED).nullable(), 
    }
  } else if (turno === 3) {
    initialSchema = {
      ...initialSchema,
      hora_ingreso_tur_m: yup.string().required(REQUIRED).nullable(),
      hora_salida_tur_m: yup.string().required(REQUIRED).nullable(),
      hora_ingreso_tur_t: yup.string().required(REQUIRED).nullable(), 
      hora_salida_tur_t: yup.string().required(REQUIRED).nullable()
    }
  }
  return initialSchema;
}

