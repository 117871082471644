import React from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FormNuevoPlan from '../FormNuevoPlan';
import CardPlan from '../CardPlan';
import FormPlanPagoUnico from '../FormPlanPagoUnico';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderSeccionModalidad from '../HeaderSeccionModalidad';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import Fatal from '../../MessageAlert/Fatal';

const PlanesPagoUnico = ({ onShowModal, onCloseModal, handleEliminarPlan }) => {

  const { planPagoUnico = [] } = useSelector(state => state.cursos.proyecto.planes) || [];
  const handleClick = () => {
    onShowModal(<FormNuevoPlan 
      onCloseModal={onCloseModal}
      idTipoModalidad={2}
    />, 'Nuevo plan');
  }

  const handleEditar = (plan) => {
    onShowModal(<FormNuevoPlan
      onCloseModal={onCloseModal}
      idTipoModalidad={2}
      plan={plan}
    />, 'Editar plan');
  }

  const handleExaminar = (plan) => {
    onShowModal(<FormPlanPagoUnico
      onCloseModal={onCloseModal}
      plan={plan}
    />, 'Contenido de plan');
  }

  const handleEliminar = (idPlan) => {
    handleEliminarPlan(2, idPlan)
  }

  const handleMostrarPlanes = () => {
    if(planPagoUnico && planPagoUnico.length > 0) {
      return [...planPagoUnico].map(plan => (
        <Col lg={2} md={3} sm={6} key={plan.id_plan}>
          <CardPlan
            plan={plan}
            handleExaminar={handleExaminar}
            handleEliminar={handleEliminar}
            handleEditar={handleEditar}
          />
        </Col>
      ))
    }
    return <Col sm={12}>
      <Alert variant='info'>
        No hay planes registrados 
      </Alert>
    </Col>
  }

  return ( 
    <div className="PlanesUnico">
      <div className="PlanesUnico__accion-boton text-right">
        <HeaderSeccionModalidad
          title='INVERSIÓN AL CONTADO'
          icon={faCheckSquare}
          handleClick={handleClick}
        />
      </div>
      <div className="PlanesUnico__lista">
        <Row>
          {handleMostrarPlanes()}
        </Row>
      </div>
    </div>
  );
}
 
export default PlanesPagoUnico;