import React, { useState } from 'react'
import MaterialTable from '../../components/plugins/MaterialTableDemo'
import ModalBasic from '../../components/Modals/ModalBasic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'

import { useSelector } from 'react-redux' 
import EditarRecursos from '../../components/Formularios/EditarRecursos'
import { Badge, Button } from 'react-bootstrap'

const PodCast = (props) => {

    const { tp_podcast: archivos = [] } = useSelector(state => state.recursos.recursos);
    const { eliminarRecurso } = props
    const [recurso, setRecurso] = useState({})
    const [showModal, setShowModal] = useState(false)

    const botonesAcciones = (record) => (
      <>
        <Button
          variant='danger'
          size='xs'
          onClick={() => eliminarRecurso(record.id_archivo_sesion)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          variant='warning'
          size='xs'
          href={record.id_tipo_archivo!=="5" ? `${process.env.REACT_APP_API_URL}/${record.ruta_archivo}` : `${record.ruta_archivo}`}
          target='__black'
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
        <Button
          type='button'
          variant='info'
          size='xs'
          onClick={() => guardarRecursosEditar(record)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </>
    )

    const guardarRecursosEditar = archivo => {
      setRecurso(archivo)
      setShowModal(true)
    }

    const updateShowModal = valor => {
      setShowModal(valor)
    }

    return ( 
        <div className="recursos__archivos">
            <div className="p-0 bg-white">
                <div className="tabla">
                    <MaterialTable
                      colums={colums(botonesAcciones)}
                      tabla={archivos}
                      addConfig={{key_column: 'id_archivo_sesion'}}
                    />
                </div>
            </div>

            <ModalBasic
              show={showModal}
              title='Editar recurso'
              updateShowModal={updateShowModal}
            >
              <EditarRecursos
                recurso={recurso}
              />
            </ModalBasic>
        </div>
     );
}
 
export default PodCast;

const colums = (botonesAcciones) => [
    {
      text: "#",
      key: "index",
      align: "center",
      sortable: true,
      width: 70,
      className: 'text-center',
      cell: (_, index) => index+1
    },
    {
      text: "Nombre de archivo",
      key: "nombre_archivo",
      align: "center",
      sortable: true,
    },
    {
      text: "Formato",
      key: "formato_archivo",
      align: "center",
      width: 100,
      sortable: true,
      className: "text-center"
    },
    {
      text: "Publicado",
      key: "fecha",
      align: "center",
      sortable: true,
      width: 150,
      className: "text-center"
    },
    {
      text: "Estado",
      key: "aprobado",
      align: "center",
      sortable: true,
      width: 150,
      className: "text-center",
      cell : (record) => <Badge variant={record.aprobado==="1" ? 'success':'warning'} >{record.aprobado==="1" ? 'Aprobado':'Pendiente'}</Badge> 
    },
    {
      text: "Acciones",
      key: "button",
      align: "center",
      sortable: true,
      className: 'text-center',
      width: 130,
      cell: (record) => botonesAcciones(record)
    }
  ]
 