import React from 'react';
import Pregunta from './Pregunta';

const ListaPreguntas = ({ preguntas, eliminarPregunta }) => {

  const mostrarPreguntas = () => {
    if(preguntas.length > 0) {
      return preguntas.map(pregunta => (
        <Pregunta 
          key={pregunta.id_pregunta}
          eliminarPregunta={eliminarPregunta}
          {...pregunta}
        />
      ))
    } else {
      return <p>No hay Preguntas registradas</p>
    }
  }

  return ( 
    <div className="ListaPreguntas">
      {mostrarPreguntas()}
    </div>
  );
}
 
export default ListaPreguntas;