import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { anularPago } from '../../../../actions/pagosActions';

const FormularioAnularPago = ({ id: idPagoAnular, tipo }) => {
  
  const dispatch = useDispatch();
  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      id: idPagoAnular,
      motivoLlave: '',
      tipo: tipo,
      motivo: ''
    },
    validationSchema: yup.object({
      motivoLlave: yup.string()
          .required('Esta entrada es requerida'),
      motivo: yup.string().required('Este campo es requerido.')
    }),
    onSubmit: valores => {
      dispatch(anularPago(valores));
    }
  });

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }


  return ( 
    <div className="FormularioAnularPago">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Razón para anular</Form.Label>
            <Form.Control
              as='select'
              id='motivoLlave'
              value={values.motivoLlave}
              onChange={e => {
                  setFieldValue('motivoLlave', e.target.value);
                  let texto = e.target.options[e.target.selectedIndex].innerText;
                  if(texto.toLowerCase() === 'otro') {
                    setFieldValue('motivo', '');
                  } else {
                    setFieldValue('motivo', texto);
                  }
                }}
              isInvalid={!!errors.motivoLlave}
            >
              <option value="" disabled>--Seleccionar--</option>
              <option value="prueba">Pruebas del sistema</option>
              <option value="error_registro">Devolución</option>
              <option value="monto_equivocado">Puedo detallar el error</option>

            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.motivoLlave}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button variant='dark' onClick={handleModal}>
            Cancelar
          </Button>
          <Button type='submit' variant='info'>
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
}
 
export default FormularioAnularPago;