import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import './ChartArea.scss';

const ChartArea = props => {

  const { categories = [], data = [], titulo = '' } = props

  const options = {
    chart: {
      type: 'area'
    },
    backgroundColor: '#27314A',
    colors: ['#27314A', '#18BB60', '#90ee7e', '#7798BF', '#aaeeee', '#ff0066',
        '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    title: {
      text: titulo
    },
    subtitle: {
      text: 'Fuente: Pagina de encuesta inedi.edu.pe'
    },
    xAxis: {
      categories: categories,
      tickmarkPlacement: 'on',
      title: {
        enabled: false
      },
      gridLineWidth: 1,
          labels: {
              style: {
                  fontSize: '10px'
              }
          }
    },
    yAxis: {
      labels: {
        format: '{value}%'
      },
      title: {
        enabled: false
      }
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}%</b>',
      split: true
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#ffffff',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#ffffff'
        },
        accessibility: {
          pointDescriptionFormatter: function (point) {
            function round(x) {
              return Math.round(x * 100) / 100;
            }
            return (point.index + 1) + ', ' + point.category + ', ' +
              point.y + ' millions, ' + round(point.percentage) + '%, ' +
              point.series.name;
          }
        }
      }
    },
    series: [
      {
        name: 'Resultado',
        data: data
      }
      
    ]
  }

  return ( 
    <div className="chart-aera">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
   );
}
 
export default ChartArea;