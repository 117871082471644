import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import Fatal from '../../MessageAlert/Fatal';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { nuevoDescuentoCargos, obtenerMotivosCargosDescuentos } from '../../../../actions/pagosActions';
import { updateStateModalBasic } from '../../../../actions/modalActions';

const FormularioDescuentoCargos = ({ id_tp_adicional = 1, tipoRegistro }) => {

  const queryParams = new URLSearchParams(window.location.search);
  const idTpMatricula = queryParams.get('pension');
  const { datos: motivosCargosDescuentos = {}, loading, error } = useSelector(state => state.pagos.motivosCargosDescuentos);
  const { loadingNuevoDescuentoCargo } = useSelector(state => state.pagos.datosFinanzasDiscente.cuotasPensiones);
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(updateStateModalBasic());
  }

  const formik = useFormik({
    initialValues: {
      id_dt_matricula: idTpMatricula,
      id_tp_motivo: '',
      cantidad: '1',
      monto: '',
      id_tp_adicional: id_tp_adicional
    },
    validationSchema: yup.object({
      id_tp_motivo: yup.string().required('Este campo es requerido.'),
      monto: yup.number()
        .typeError('Entrada no válida: Número por favor')
        .positive('El número tiene que ser positivo')
        .required('Este campo es requerido.'),
      cantidad: yup.number()
        .typeError('Entrada no válida: Número por favor')
        .positive('El número tiene que se positivo')
        .integer('El número tiene que se de tipo entero')
        .required('Este campo es requerido'),
      id_tp_adicional: yup.number()
        .required('Este campo es requerido.')
    }),
    onSubmit: valores => {
      dispatch(nuevoDescuentoCargos(valores));
    }
  });

  useEffect(() => {
    if(!motivosCargosDescuentos) {
      dispatch(obtenerMotivosCargosDescuentos());
    }
  }, []);

  if(loading && !motivosCargosDescuentos) return <Spinner className='mx-auto my-3' as='span' animation='border' />
  if(error) return <Modal.Body>
    <Fatal message={error} />
  </Modal.Body> 

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label htmlFor='id_tp_motivo'>Motivo</Form.Label>
          <Form.Control
            as='select'
            id='id_tp_motivo'
            name='id_tp_motivo'
            value={formik.values.id_tp_motivo}
            onChange={formik.handleChange}
            onBlur={formik.errors.id_tp_motivo}
            isInvalid={formik.errors.id_tp_motivo}
          >
            <option value="">--Seleccione--</option>
            {motivosCargosDescuentos && motivosCargosDescuentos[tipoRegistro].map(motivo => (
              <OptionsMotivos
                key={motivo.id_tp_motivo}
                {...motivo}
              />
            ))}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            {formik.errors.id_tp_motivo}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='cantidad'>Cantidad</Form.Label>
          <InputGroup>
            <Form.Control
              type='text'
              id='cantidad'
              name='cantidad'
              value="1"
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.cantidad}
              readOnly
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.cantidad}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor='monto'>Monto</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                S/.
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type='text'
              id='monto'
              name='monto'
              value={formik.values.monto}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.monto}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.monto}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className='text-right'>
        <Button
          variant='dark'
          onClick={handleModal}
          disabled={loadingNuevoDescuentoCargo}
        >
          Cancelar
        </Button>
        <Button disabled={loadingNuevoDescuentoCargo} type='submit' className='btn-spinner' variant='info'>
          {
            loadingNuevoDescuentoCargo && <Spinner as='span' animation='border' size='sm' />
          }
          Agregar
        </Button>
      </Modal.Footer>
    </Form>
  );
}
 
export default FormularioDescuentoCargos;

const OptionsMotivos = ({id_tp_motivo: idMotivo, motivo}) => {
  return (
    <option value={idMotivo}>{motivo}</option>
  )
}
