import React from 'react';
import './InicioTutor.scss'
import HeaderContent from '../General/HeaderContent';

const InicioEmpresa = () => {
  return ( 
    <div className="inicio-tutor">
      <HeaderContent title='Inicio'/>
      <div className="inicio-tutor__content box">
        <div className="vision-mision row">
          <div className="mision m-4 col-md-6">
            <p className="titulo">Bienvenido</p>
            <p className="texto">
             Desde este espacio podrá publicar ofertas laborales, que serviran de nexo para reclutar perfiles especializados.
            </p>
          </div>
        </div>
        </div>
    </div>
   );
}
 
export default InicioEmpresa;