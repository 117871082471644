import { faFileImport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { Button, Table } from 'react-bootstrap';
import * as moment from 'moment';
//Redux
const ResultadosFiltro = ({resultados}) => {

 return (
 
    <div className="box">
     <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faFileImport} /> Resultados de Consulta
                  </h5>
                
                </div>
      <div className="row p-2">
        <div className="col-12 mb-2">
         <p>Campaña: {resultados.campaign} </p>
         <br/>
         <Table striped bordered hover style={{textAlign:'center'}}>
            <thead>
                <tr>
                <th>Cantidad de Webinars</th>
                <th>% Inscritos</th>
                <th>Conclusión MKT</th>
                <th>Sugerencia MKT</th>
                <th>Conclusión Comercial</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td>{resultados.total_webinars}</td>
                  <td>{resultados.p_inscritos}%</td>
                  <td style={{textAlign:'justify'}}>{resultados.conclusion_mkt}</td>
                  <td style={{textAlign:'justify'}}>{resultados.sugerencia_mkt}</td>
                  <td style={{textAlign:'justify'}}>{resultados.conclusion_comercial}</td>
                </tr>
               
            </tbody>
            </Table>
         
        </div>     
      </div>
    </div>
  
  
    );
};

export default ResultadosFiltro;
