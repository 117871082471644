import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';
import RegistroDocentewithEditar from '../../containers/DocentesContainers/RegistroDocentewithEditar';

//Redux
import { useDispatch } from 'react-redux';
import { updateStateModalBasic } from '../../actions/modalActions';

const withVistaDatosPersonales = WrappedComponent => props => {
  
  const dispatch = useDispatch();
  const [docente] = useGetDocente();

  const handleEditar = () => {
    dispatch( updateStateModalBasic(<RegistroDocentewithEditar />, 'Editar datos del docente') )
  }

  return ( 
    <WrappedComponent
      {...props}
      datos={docente}
      handleEditar={handleEditar}
    />
   );
}
 
export default withVistaDatosPersonales;