import React from 'react';
import { useParams } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { asignarTemaAreaDocente } from '../../actions/profesoresActions';

const withInsertarTemasDocente = WrappedComponent => props => {

  const { idDocente } = useParams();
  const dispatch = useDispatch();

  const handleAsignarTemas = (idTema) => {
    dispatch( asignarTemaAreaDocente(idTema, idDocente) )
  }

  return ( 
    <WrappedComponent
      {...props}
      handleAsignarTemas={handleAsignarTemas}
    />
   );
}
 
export default withInsertarTemasDocente;