import React,{ useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Form, Alert } from 'react-bootstrap';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const SortableItem = SortableElement(
  ({value, handleClickButton}) => <li className='lista__item p-2 rounded pl-0 mb-2 list-unstyled border'>
      <div className='d-flex justify-content-between align-items-center '>
        <span>{value.tema_area}</span>
        <Button
          type='button'
          variant='light'
          size='sm'
          onClick={() => handleClickButton(value.id_tema_area)}
        >
          &times;
        </Button>
      </div>
    </li>
)

const SortableContent = SortableContainer(({children}) => {
  return <ul className='pl-0'>{children}</ul>
})

const TemasSeleccionados = ({ temas, handleGuardarNuevoOrden, handleEliminarTema }) => {

  const onSortEnd = ({oldIndex, newIndex}) => {
    setTemas(temas => arrayMove(temas, oldIndex, newIndex));
  };
  const [temasArea, setTemas] = useState(temas)

  const handleSubmit = e => {
    e.preventDefault();
    handleGuardarNuevoOrden(temasArea)
  }

  useEffect(() => {
    if (temas) {
      setTemas(temas)
    }
  }, [temas])

  return ( 
    <div className="TemasSeleccionados">
      <div className="TemasSeleccionados__container">
      <Form onSubmit={handleSubmit}>
        <Form.Label>Temas seleccionados:</Form.Label>
        <Form.Group className='TemasSeleccionados__lista'>
          <SortableContent onSortEnd={onSortEnd}>
            {temasArea.length ? temasArea.map((item, index) => (
              <SortableItem 
                key={`item-${index}`}
                index={index}
                value={item}
                handleClickButton={handleEliminarTema}
              />
            )) : <Alert variant='info'>No hay temas seleccionados</Alert>}
          </SortableContent>
        </Form.Group>
        <Form.Group className='text-right'>
          {
            temasArea.length ? (
              <Button
                variant='info'
                className='text-white btn-icon'
                type='submit'
                disabled={temasArea === temas}
              >
                <FontAwesomeIcon icon={faSave} />
                Guardar
              </Button>
            ) : null
          }
        </Form.Group>
        </Form>
      </div>
    </div>
   );
}

TemasSeleccionados.propTypes = {
  temas: PropTypes.array.isRequired,
  handleGuardarNuevoOrden: PropTypes.func.isRequired,
  handleEliminarTema: PropTypes.func.isRequired
}
 
export default TemasSeleccionados;