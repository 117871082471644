import React from 'react';
import '../EditarTemasArea.scss';
import AreaDictadoWithTrabajador from '../../../../../../containers/TrabajadoresContainers/AreaDictadoWithTrabajador';
import { Modal } from 'react-bootstrap';

const EditarTemasAreaTrabajo = () => {

  return ( 
    <div className="EditarTemasAreaTrabajo">
      <Modal.Body>
        <AreaDictadoWithTrabajador />
      </Modal.Body>
    </div>
  );
}
 
export default EditarTemasAreaTrabajo;