import React from 'react';
import RangoProfesional from './RangoProfesional';
import PropTypes from 'prop-types';

const RangoProfesionalSeleccionados = ({ listaSeleccionado, estado, handleDeleteIndicador }) => {
  
  return ( 
    <div className="RangoProfesionalSeleccionados">
      <div className="RangoProfesionalSeleccionados__container">
        <div className='Category'>
          <span className='Category__title'>Lista de clasificación seleccionados</span>
          <div className='my-3'>
            {listaSeleccionado.map(item => (
              <RangoProfesional
                key={item.id}
                {...item}
                handleDeleteIndicador={handleDeleteIndicador}
              />
            ))}
          </div>
        </div>
        <div className='Category__resultado'>
          <span className='Category__title'>Resultado</span>
          <span className='d-flex mt-3'>{estado}</span>
        </div>
      </div>
    </div>
   );
}
 
RangoProfesionalSeleccionados.propTypes = {
  listaSeleccionado: PropTypes.array.isRequired,
  estado: PropTypes.string.isRequired,
  handleDeleteIndicador: PropTypes.func.isRequired
}

export default RangoProfesionalSeleccionados;