import React from 'react';

const FormCuentaB = () => {
    return ( 
        <form>
            <div className="form-group">
                <label htmlFor="banco">
                    Nombre banco:
                </label>
                <input
                    type="text"
                    name="banco"
                    id="banco"
                    className="form-control"
                />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Send message
              </button>
            </div>
        </form>
     );
}
 
export default FormCuentaB;