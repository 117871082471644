import {
  MOSTRAR_CURSOS,
  ELIMINAR_MODULO,
  MOSTRAR_CURSO,
  EDITAR_SESION,
  ELIMINAR_SESION,
  LISTAR_SESION,
  LISTAR_SESIONES_CURSO_IMPORT,
  LISTAR_SESIONES_CURSOS,
  INSERTAR_SESIONES_MANUAL,
  LLENAR_SELECT_PROYECTOS,
  LLENAR_SELECT_CURSOS,
  LLENAR_SELECT_CURSOS_POSGRADO,
  LLENAR_SELECT_TC,
  REMOVER_MODULO,
  LISTAR_SESIONES_MODULO,
  LISTAR_PROYECTOS,
  ELIMINAR_PROYECTO,
  LISTAR_PROYECTO,
  REMOVER_PROYECTO,
  LISTAR_MODULO_PROYECTO,
  LISTAR_MODULOS_PROYECTO_TODO,
  REMOVER_SESION,
  REMOVER_SESIONES_MODULO,
  REMOVER_STATE_MODULO,
  EDITAAR_PROYECTO,
  EDITAR_MODULO,
  GUARDANDO_SESIONES_EVENTOS_SELECT,
  LISTAR_PROYECTOS_EXITO,
  proyectosTypes,
  GUARDAR_GRUPO
} from "../actions/types";

const initialState = {
    modulos: [],
    curso: {},
    proyectos: [],
    proyecto: null,
    loading: false,
    loadingAdd: false,
    loadingEdit: false,
    loadingPlan: false,
    errorAdd: null,
    error: null,
    errorEdit: null,
    errorPlan: null,
    redireccion: false,
    modulos_proyecto: [],
    select_proyectos: [],
    selectTipoCurso: [],
    selectCursos: [],
    selectCursoPosgrado: [],
    selectSesiones: [],
    sesionesCurso: [],
    sesionesCursoImport: [],
    sesion: {},
    grupo_whatsapp:""
}

export default function(state = initialState, action){
    switch (action.type) {
      case LISTAR_PROYECTOS:
        return{
          ...state,
          loading: true,
          error: null
        }
      case LISTAR_PROYECTOS_EXITO:
        return{
          ...state,
          proyectos: action.payload,
          loading: false,
          error: null
        }
      case GUARDAR_GRUPO:
        return{
          ...state,
          grupo_whatsapp: action.payload,
        }
      case proyectosTypes.NUEVO_PROYECTO:
        return {
          ...state,
          loadingAdd: true,
          errorAdd: null,
        }
      case proyectosTypes.NUEVO_PROYECTO_EXITO:
        return {
          ...state,
          loadingAdd: false,
          errorAdd: null,
          proyectos: [...state.proyectos, action.payload],
          redireccion: true
        }
      case proyectosTypes.NUEVO_PROYECTO_ERROR:
      case proyectosTypes.OBTENER_PROYECTO_ERROR:
        return {
          ...state,
          loadingAdd: false,
          errorAdd: action.payload,
        }
      case proyectosTypes.NUEVO_PROYECTO_EXITO_RESET_REDIRECCION:
        return {
          ...state,
          redireccion: false
        }
      case proyectosTypes.EDITAR_PROYECTO_NIVEL_ACADEMICO:
        return {
          ...state,
          loadingAdd: true,
          errorAdd: true 
        }
      case proyectosTypes.OBTENER_PROYECTO:
        return {
          ...state,
          loading: true,
          error: null
        }
      case proyectosTypes.OBTENER_PROYECTO_EXITO:
        return {
          ...state,
          loading: false,
          error: null,
          proyecto: action.payload
        }
      case proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO:
      case proyectosTypes.EDITAR_PROYECTO_HORARIO:
      case proyectosTypes.AGREGAR_MODULO_PROYECTO:
      case proyectosTypes.ELIMINAR_MODULO_PROYECTO:
        return {
          ...state,
          loadingEdit: true,
          errorEdit: null
        }
      case proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO_EXITO:
      case proyectosTypes.EDITAR_PROYECTO_HORARIO_EXITO:
        return {
          ...state,
          loadingEdit: false,
          errorEdit: null
        }
      case proyectosTypes.ACTUALIZAR_NIVEL_ACADEMICO_ERROR:
      case proyectosTypes.EDITAR_PROYECTO_HORARIO_ERROR:
      case proyectosTypes.AGREGAR_MODULO_PROYECTO_ERROR:
      case proyectosTypes.ELIMINAR_MODULO_PROYECTO_ERROR:
        return {
          ...state,
          loadingEdit: false,
          errorEdit: action.payload
        }
      case proyectosTypes.NUEVO_PLAN:
      case proyectosTypes.EDITAR_PLAN_PROYECTO:
      case proyectosTypes.GUARDAR_PENSION:
        return {
          ...state,
          loadingPlan: true,
          errorPlan: null
        }
      case proyectosTypes.NUEVO_PLAN_EXITO:
        return {
          ...state,
          loadingPlan: false,
          errorPlan: null,
          proyecto: {
            ...state.proyecto,
            planes: {
              ...state.proyecto.planes,
              [action.payload.modalidad]: [
                ...state.proyecto.planes[action.payload.modalidad],
                action.payload.data
              ]
            }
          }
        }
      case proyectosTypes.EDITAR_PLAN_PROYECTO_EXITO:
        return {
          ...state,
          loadingPlan: false,
          errorPlan: null,
          proyecto: {
            ...state.proyecto,
            planes: {
              ...state.proyecto.planes,
              [action.payload.modalidad]: [...state.proyecto.planes[action.payload.modalidad]].map(
                plan => plan.id_plan === action.payload.plan.id_plan ? action.payload.plan : plan
              )
            }
          }
        }
      case proyectosTypes.EDITAR_PLAN_PROYECTO_ERROR:
      case proyectosTypes.NUEVO_PLAN_ERROR:
      case proyectosTypes.GUARDAR_PENSION_ERROR:
        return {
          ...state,
          loadingPlan: false,
          errorPlan: action.payload 
        }
      case proyectosTypes.GUARDAR_PENSION_EXITO:
        return {
          ...state,
          loadingPlan: false,
          errorPlan: null,
          proyecto: {
            ...state.proyecto,
            planes: {
              ...state.proyecto.planes,
              [action.payload.modalidad]: [...state.proyecto.planes[action.payload.modalidad]].map(
                plan => plan.id_plan === parseInt(action.payload.datos.id_plan) ? action.payload.datos : plan
              )
            }
          }
        }
      case proyectosTypes.AGREGAR_MODULO_PROYECTO_EXITO:
        return {
          ...state,
          loadingEdit: false,
          errorEdit: null,
          proyecto: {
            ...state.proyecto,
            modulos: [...state.proyecto.modulos, action.payload]
          }
        }
      case proyectosTypes.ELIMINAR_PLAN_PROYECTO_EXITO:
        return {
          ...state,
          proyecto: {
            ...state.proyecto,
            planes: {
              ...state.proyecto.planes,
              [action.payload.modalidad]: [...state.proyecto.planes[action.payload.modalidad]].filter(
                plan => plan.id_plan !== parseInt(action.payload.idPlan)
              )
            }
          }
        }
      case proyectosTypes.ELIMINAR_MODULO_PROYECTO_EXITO:
        return {
          ...state,
          proyecto: {
            ...state.proyecto,
            modulos: [...state.proyecto.modulos].filter(modulo => (
              modulo.id_proyecto_modulo !== action.payload.idModuloProyecto
            ))
          }
        }
      case LISTAR_PROYECTO:
        return{
          ...state,
          proyecto: action.payload
        }
      case REMOVER_PROYECTO:
        return{
          ...state,
          proyecto: action.payload
        }
      case MOSTRAR_CURSO:
        return{
          ...state,
          curso: action.payload
        }
      case LISTAR_MODULO_PROYECTO:
        return{
          ...state,
          proyecto: action.payload
        }
      case LISTAR_MODULOS_PROYECTO_TODO:
        return{
          ...state,
          modulos_proyecto: action.payload
        }
      case ELIMINAR_MODULO:
        return{
          ...state,
          modulos: [...state.modulos].filter(modulo => modulo.id_modulo !== action.payload)
        }
      case ELIMINAR_PROYECTO:
        return{
          ...state,
          proyectos: [...state.proyectos].filter(proyecto => proyecto.id_proyecto !== action.payload)
        }
      case LLENAR_SELECT_PROYECTOS:
        return{
          ...state,
          select_proyectos: action.payload
        }
      case LLENAR_SELECT_TC: 
        return{
          ...state,
          selectTipoCurso: action.payload
        }
      case LLENAR_SELECT_CURSOS:
        return{
          ...state,
          selectCursos: action.payload
        }
      case LISTAR_SESIONES_CURSOS:
        return{
          ...state,
          sesionesCurso: action.payload
        }
      case INSERTAR_SESIONES_MANUAL:
        return{
          ...state,
          sesionesCurso: action.payload
        }
      case LISTAR_SESIONES_CURSO_IMPORT:
        return{
          ...state,
          sesionesCursoImport: action.payload
        }
      case LLENAR_SELECT_CURSOS_POSGRADO:
        return{
          ...state,
          selectCursoPosgrado: action.payload
        }
      case LISTAR_SESION:
        return{
          ...state,
          sesion: action.payload.datos
        }
      case LISTAR_SESIONES_MODULO:
        return{
          ...state,
          sesionesCurso: action.payload
        }
      case ELIMINAR_SESION:
        return{
          ...state,
          sesionesCurso: action.payload
        }
      case EDITAR_SESION:
        return{
          ...state,
          sesionesCurso: action.payload,
          sesion: {}
        }
      case EDITAAR_PROYECTO:
        return {
          ...state,
          proyectos: action.payload
        }
      case EDITAR_MODULO:
        return {
          ...state,
          modulos: action.payload
        }
      case GUARDANDO_SESIONES_EVENTOS_SELECT:
        return {
          ...state,
          selectSesiones: action.payload
        }
      case REMOVER_SESION:
        return{
          ...state,
          sesion: {}
        }
      case REMOVER_STATE_MODULO:
        return {
          ...state,
          curso: {}
        }
      case REMOVER_SESIONES_MODULO:
        return {
          ...state,
          sesionesCurso: []
        }
      case REMOVER_MODULO:
        return{
          ...state,
          modulos: [],
          curso: {},
          proyectos: [],
          proyecto: {},
          modulos_proyecto: [],
          select_proyectos: [],
          selectTipoCurso: [],
          selectCursos: [],
          selectCursoPosgrado: [],
          sesionesCurso: [],
          sesionesCursoImport: [],
          sesion: {}
        }
      default:
        return state
    }
}