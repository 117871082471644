import React, { useState } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import  * as yup from 'yup'; 
import './FormAccionMasiva.scss';
import { REQUIRED } from '../../../../helpers/validationForms';
import { useDispatch } from 'react-redux';
import { actualizarAsistenciasMasivo } from '../../../../actions/asistenciaActions';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';

const FormAccionMasiva = props => {

  const { asistenciasChecks = [], horarioSeleccion, sesionSeleccion = {}} = props;
  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id_tp_tiempo_ingreso: ''
    },
    validationSchema: yup.object({
      id_tp_tiempo_ingreso: yup.string()
        .required(REQUIRED)
    }),
    onSubmit: async valores => {
      if(!sesionSeleccion.id_sesion) {
        avisoError('No se selecciono sesión');
        return;
      }
      if(!asistenciasChecks.length) {
        avisoError('No se ha seleccionado asistencias.');
        return;
      }
      const datos = {
        id_tipo: horarioSeleccion.value,
        id_tp_tiempo_ingreso: valores.id_tp_tiempo_ingreso,
        asistencias: asistenciasChecks
      }
      setLoading(true);
      await dispatch(actualizarAsistenciasMasivo(sesionSeleccion.id_sesion, datos));
      formik.handleReset();
      setLoading(false);
    }
  });

  return ( 
    <div className="FormAccionMasiva">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Control 
              as='select'
              name='id_tp_tiempo_ingreso'
              value={formik.values.id_tp_tiempo_ingreso}
              onChange={formik.handleChange}
              isInvalid={formik.errors.id_tp_tiempo_ingreso}
            >
              <option value="" disabled>Seleccione estado</option>
              <option value="1">PUNTUALIDAD</option>
              <option value="2">TARDANZA</option>
              <option value="3">INASISTENCIA</option>
              <option value="4">PENDIENTE</option>
              <option value="5">JUSTIFICADO</option>
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {formik.errors.id_tp_tiempo_ingreso}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={9}>
            <Button
              type='submit'
              variant='info'
              className='btn-icon btn-spinner'
              disabled={loading}
            >
            {
              loading ? (
                <Spinner as='span' animation='border' size='sm' />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )
            }
              Aplicar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormAccionMasiva;