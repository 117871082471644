import React from 'react';
import './IndicadorEstado.scss';

const IndicadorEstado = ({ children, variant, handleClick }) => {
  return ( 
    <div className='IndicadorEstado' onClick={handleClick}>
      <span className={`estado ${variant} p-1 px-2 rounded`}>
        {children}
      </span>
    </div>
   );
}
 
export default IndicadorEstado;