import { 
  ENCUESTAS_OBTENIDAS,
  OBTENIENDO_ENCUESTAS_TUTOR,
  ERROR_OBTENIENDO_ENCUESTAS,
  ELIMINANDO_ENCUESTA,
  ENCUESTA_ELIMINADO,
  ERROR_ELIMINAR_ENCUESTA,
  OBTENIENDO_ENCUESTA_URL,
  ENCUESTA_OBTENIDA,
  GUARDAR_ESTADO_ENCUESTA,
  ERROR_CREANDO_ENCUESTA,
  CREANDO_NUEVA_ENCUESTA,
  NUEVA_ENCUESTA_CREADA,
  REMOVER_ESTADO_CREADO,
  GUARDANDO_ENCABEZADO,
  INSERTANDO_PREGUNTA,
  GUARDANDO_PREGUNTA,
  EDITANDO_PREGUNTA,
  GUARDANDO_CAMBIOS,
  ELIMINANDO_PREGUNTA,
  OBTENIENDO_REPORTES_TUTOR,
  REPORTES_TUTOR_OBTENIDO,
  OBTENIENDO_REPORTE_TUTOR,
  REPORTE_TUTOR_OBTENIDO,
  GUARDANDO_REPORTE,
  REPORTE_GUARDADO,
  REMOVER_ESTADO_GUARDADO_REPORTE,
  OBTENER_ENCUESTAS_DOCENTE,
  OBTENER_ENCUESTAS_DOCENTE_EXITO,
  ERROR
 } from './types'
 import Swal from 'sweetalert2/dist/sweetalert2.js'
 import authAxios from '../config/authAxios';
 import { avisoError, avisoCargando, avisoCorrecto } from '../view/components/AvisosMensajes/MensajesSwalf';



  
 export const obtenerRespuestasSugerencias = (idSesion) => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/obtenerSugerencias`,idSesion)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, datos: respuesta.data}
    }
    else
      {   
        return {mensaje: false, datos: null}
      }
    
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
  
}

export const obtenerRespuestasProfundizar = (idSesion) => async dispatch => {
  try {
    let respuesta = await authAxios.post(`/admin/obtenerRespuestasProfundizar`,idSesion)
    if (!respuesta.data.error) 
    {
      return {mensaje: true, datos: respuesta.data}
    }
    else
      {   
        return {mensaje: false, datos: null}
      }
    
  } catch (error) {
    console.log(error)
    return {mensaje: false, textMensaje: error}
  }
  
}
  



const loading = type => {
  return {
    type
  };
};


const error = type => {
  return {
    type
  };
};

const encuestaCreada = encuesta => {
  return {
    type: NUEVA_ENCUESTA_CREADA,
    payload: encuesta
  }
}

const guardarEncuestas = encuestas => {

  return {
    type: ENCUESTAS_OBTENIDAS,
    payload: encuestas
  }
}

const guardarNuevaEncuestas = encuestas => {
  return {
    type: ENCUESTA_ELIMINADO,
    payload: encuestas
  }
}

const guardandoEncabezado = encuesta => {
  return {
    type: GUARDANDO_ENCABEZADO,
    payload: encuesta
  }
}

const guardarEncuesta = encuesta => {
  return {
    type: ENCUESTA_OBTENIDA,
    payload: encuesta
  }
}

const removerEstado = type => {
  return {
    type
  }
}

export const agregarEncabezado = (idFormulario, data) => {
  return async dispatch => {

    try {
      const respuesta = await authAxios.put(`/admin/rprtet_editarDatosFormulario/${idFormulario}`, data)
  
      if (!respuesta.data.error) {
        dispatch( guardandoEncabezado(respuesta.data) )
        return
      }

      avisoError(respuesta.data.error)
    } catch (error) {
      console.log(error)
      avisoError(error)
    }
  }
}

export const nuevaEncuesta = data => {
  return async dispatch => {
    dispatch( loading(CREANDO_NUEVA_ENCUESTA))
    avisoCargando('Creando nueva encuesta...')
    try {
      const encuesta = await authAxios.post(`/admin/rprtet_insertarDatosFormulario`, data)

      if (!encuesta.data.error) {
        dispatch( encuestaCreada(encuesta.data) )
        dispatch( removerEstado(REMOVER_ESTADO_CREADO) )
        avisoCorrecto('Encuesta creada')
        return
      }

      avisoError(encuesta.data.error)
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_CREANDO_ENCUESTA) )
    }
  }
}

const preguntaInsertada = encuesta => {
  return {
    type: GUARDANDO_PREGUNTA,
    payload: encuesta
  }
}

export const insertarNuevaPregunta = (idFormulario, data) => {
  return async dispatch => {
    dispatch( loading(INSERTANDO_PREGUNTA) )

    try {
      const respuesta = await authAxios.post(`/admin/rprtet_insertarPreguntasFormulario/${idFormulario}`, data)

      if (!respuesta.data.error) {
        dispatch( preguntaInsertada(respuesta.data) )
        return
      }

      avisoError(respuesta.data.error)
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR) )
    }
  }
}

const guardandoCambios = encuesta => {
  return {
    type: GUARDANDO_CAMBIOS,
    payload: encuesta
  }
}

export const editarPregunta = (idPreguntaReporte, data) => {
  return async dispatch => {
    dispatch( loading(EDITANDO_PREGUNTA) )

    try {
      const respuesta = await authAxios.put(`/admin/rprtet_editarPreguntasFormulario/${idPreguntaReporte}`, data)

      if (!respuesta.data.error) {
        dispatch( guardandoCambios(respuesta.data) )
        return
      }

      avisoError(respuesta.data.error)
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR) )
    }
  }
}

export const eliminarPreguntaForm = idPreguntaReporte => {
  return async dispatch => {
    dispatch( loading(ELIMINANDO_PREGUNTA) )

    try {
      const respuesta = await authAxios.delete(`/admin/rprtet_eliminarPreguntasFormulario/${idPreguntaReporte}`)

      if (!respuesta.data.error) {
        dispatch( guardandoCambios(respuesta.data) )
        return
      }

      avisoError(respuesta.data.error)
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR) )
    }
  }
} 

export const obtenerEncuestasTutor = (idSesion, idEventoTutor) => {
  return async dispatch => {
    dispatch( loading(OBTENIENDO_ENCUESTAS_TUTOR) )

    try {
      const encuestas = await authAxios.get(`/admin/rprtet_mostrarEncuesta__IdTutorSesion/${idEventoTutor}/${idSesion}`)
    
      dispatch( guardarEncuestas(encuestas.data) )
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_OBTENIENDO_ENCUESTAS) )
    }
  }
}

const guardarEncuestaDocente = encuestas => {
  return {
    type: OBTENER_ENCUESTAS_DOCENTE_EXITO,
    payload: encuestas
  }
}

export const obtenerEncuestaDocente = idProyecto => {
  return async dispatch => {
    dispatch( loading(OBTENER_ENCUESTAS_DOCENTE) )

    try {
      const encuestas = await authAxios.get(`/admin/rprtet_mostrarEncuestasDocentes__IdProyecto/${idProyecto}`)
    
      dispatch( guardarEncuestaDocente(encuestas.data) )
    } catch (error) {
      console.log(error)
      dispatch( error(ERROR_OBTENIENDO_ENCUESTAS) )
    }
  }
}

export const eliminarEncuesta = idEncuesta => {
  return async dispatch => {
    dispatch( loading(ELIMINANDO_ENCUESTA) )

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Estas seguro?',
        text: 'No se podran recuperar los datos de esta encuesta',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {

          try {
            const respuesta = await authAxios.delete(`/admin/rptet_eliminarFormulario_IdForm/${idEncuesta}`)
          
            //consultando para ver el mensaje de aprovación
            if(!respuesta.error) {
              dispatch( guardarNuevaEncuestas(respuesta.data) )
              return {
                resultado: true,
                textResultado: 'Eliminado correctamente'
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: respuesta.date.error
              });
            }
          } catch (error) {
            console.log()
            dispatch( error(ERROR_ELIMINAR_ENCUESTA) )
          }
          
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        const {resultado, textResultado} = result.value || {}

        if (resultado === true) {
          swalWithBootstrapButtons.fire(
            'Eliminado',
            textResultado,
            "success"
          );
        }
      });

    
  }
}

export const obtenerEncuestaUrl = url => {
  return async dispatch => {
    dispatch( loading(OBTENIENDO_ENCUESTA_URL) )
    
    try {
      const encuesta = await authAxios.get(`/admin/rprtet_mostrarFormulario_IdForm/${url}`)
      if (!encuesta.data.error) {
        dispatch( guardarEncuesta(encuesta.data) )
        return
      }
      avisoError(error)
    } catch (error) {
      console.log(error)
      avisoError(error)
    }
  }
}

export const obtenerEncuestaID = idForm => {
  return async dispatch => {
    dispatch( loading(OBTENIENDO_ENCUESTA_URL) )
    
    try {
      const encuesta = await authAxios.get(`/admin/rprtet_mostrarFormulario_IdForm/${idForm}`)
      if (!encuesta.data.error) {
        dispatch( guardarEncuesta(encuesta.data) )
        return
      }
      avisoError(error)
    } catch (error) {
      console.log(error)
      avisoError(error)
    }
  }
}

const guardarEstado = encuestas => {
  return {
    type: GUARDAR_ESTADO_ENCUESTA,
    payload: encuestas
  }
}

export const cambiarEstadoForm = (idForm, idEstado)  => {
  return async dispatch => {
    avisoCargando('Guardando ...')
    
    try {
      const respuesta = await authAxios.put(`/admin/rptet_editarEstadoFormulario_IdForm/${idForm}/${idEstado} `)
      
      if (!respuesta.data.error) {
        dispatch( guardarEstado(respuesta.data) )
        avisoCorrecto('Estado cambiado')
        return
      }
     
      avisoError(respuesta.data.error)
      
    } catch (error) {
      console.log(error)

    }
  }
}

const encuestaTutorObtenida = encuesta => {
  return {
    type: REPORTES_TUTOR_OBTENIDO,
    payload: encuesta
  }
}

export const obtenerEncuestasReportesTutor = idTutorProyecto => {
  return async dispatch => {
    dispatch ( loading(OBTENIENDO_REPORTES_TUTOR) )

    try {
      const encuesta = await authAxios.get(`/admin/rprtet_mostrarFormularioTutor__IdTutorProyecto/${idTutorProyecto}`)
    
      if (!encuesta.data.error) {
        dispatch( encuestaTutorObtenida(encuesta.data) )
        return
      }

      avisoError(encuesta.data.error)

    } catch (error) {
      console.log(error)
      avisoError(error)
    }
  }
}

const reporteObtenido = reporte => {
  return {
    type: REPORTE_TUTOR_OBTENIDO,
    payload: reporte
  }
}

export const obtenerReporte = idTutorForm => {
  return async dispatch => {
    dispatch( loading(OBTENIENDO_REPORTE_TUTOR) )

    try {
      const reporte = await authAxios.get(`/admin/rprtet_mostrarFormularioTutor__IdTutorFormulario/${idTutorForm}`)
    
      if (!reporte.data.error) {
        dispatch( reporteObtenido(reporte.data) )
        return
      }

      avisoError(reporte.data.error)
    } catch (error) {
      console.log(error)
      avisoError(error)
    }
  }
}

const reporteGuardado = reporte => {
  return {
    type: REPORTE_GUARDADO,
    payload: reporte
  }
}

export const guardarReporteTutor = dataReporte => {
  return async dispatch => {

    Swal.fire({
      title: 'Aviso',
      text: "Una vez enviado el reporte no se podra editar nuevamente",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#218838',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, enviar reporte'
    }).then( async (result) => {
      if (result.value) {
        dispatch( loading(GUARDANDO_REPORTE) )
        avisoCargando('Guardando reporte')
        try {
          const respuesta = await authAxios.post(`/admin/rptet_insertarCalificacionTutor`, dataReporte)
        
          if (!respuesta.data.error) {
            dispatch( reporteGuardado(respuesta.data) )
            dispatch( removerEstado(REMOVER_ESTADO_GUARDADO_REPORTE) )
            avisoCorrecto('Reporte guardado')
            return
          }
          avisoError(respuesta.data.error)
        } catch (error) {
          console.log(error)
          avisoError(error)
        }
      }
    })
    
  }
}