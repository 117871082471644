import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import useSelectProyecto from '../../../../hooks/useSelectProyectos';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { obtenerReporteCargosDescuentos } from '../../../../actions/finanzasReportesActions';

const FormularioFiltroCargosDescuentos = () => {
  
  const [proyectoSeleccionado, SimpleSelect] = useSelectProyecto();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      idProyecto: '',
      criterioBusqueda: 'descuento'
    },
    validationSchema: yup.object({

    }),
    onSubmit: valores => {
      dispatch(obtenerReporteCargosDescuentos(valores));
    }
  })

  useEffect(() => {
    formik.setFieldValue(
      'idProyecto',
      proyectoSeleccionado ? proyectoSeleccionado.id_proyecto : null
    )
  }, [proyectoSeleccionado])

  return ( 
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group as={Row}>
        <Form.Group as={Col} md={2}>
          <Form.Label>Evento</Form.Label>
          <SimpleSelect />
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label className='w-100'>Criterio</Form.Label>
          <div className='mt-3'>
            <Form.Check
              type='radio'
              label='Descuentos'
              name='criterioBusqueda'
              id='descuentos'
              value='descuento'
              checked={formik.values.criterioBusqueda === 'descuento'}
              onChange={formik.handleChange}
              inline
            />
            <Form.Check
              type='radio'
              label='Mora'
              name='criterioBusqueda'
              id='mora'
              value='mora'
              checked={formik.values.criterioBusqueda === 'mora'}
              onChange={formik.handleChange}
              inline
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} className='d-flex align-items-end'>
          <Button type='submit' className='btn-icon'>
            <FontAwesomeIcon icon={faSearch} />
            Buscar
          </Button>
        </Form.Group>
      </Form.Group>
    </Form>
  );
}
 
export default FormularioFiltroCargosDescuentos;