import React from 'react';
import { Table } from 'react-bootstrap';

const ListaConceptos = ({ conceptos }) => {
  return ( 
    <div className="ListaConceptos">
      <Table striped className='datatable'>
        <thead>
          <tr>
            <th>#</th>
            <th>Concepto</th>
            <th>Monto (S/)</th>
            <th>Fecha límite</th>
          </tr>
        </thead>
        <tbody>
          {
            conceptos.map((concepto, index) => (
              <FilaConceptos
                key={concepto.id_dt_matricula}
                index={index}
                nombreConcepto={concepto.concepto}
                fechaLimite={concepto.fecha_limite}
                monto={concepto.monto_establecido}
              />
            ))
          }
        </tbody>
      </Table>
      
    </div>
  );
}
 
export default ListaConceptos;

export const FilaConceptos = ({ nombreConcepto, fechaLimite, monto, index }) => {
  return (
    <tr>
      <td align='center'>{index+1}</td>
      <td>{nombreConcepto}</td>
      <td align='right'>{monto}</td>
      <td align='center'>{fechaLimite}</td>
    </tr>
  )
}