import React, { useEffect } from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { matriculaDiscenteActiva, obtenerMatriculasDiscente } from '../../../../actions/pagosActions';
import TablaOtroPagos from '../../../components/Finanzas/TablaOtrosPagos';
import CuotasDiscenteMatricula from '../../../components/Finanzas/CuotasDiscenteMatricula';


const ExaminarMatricula = () => {

	const dispatch = useDispatch();
	const datosFinanzasDiscente = useSelector(state => state.pagos.datosFinanzasDiscente.todo);
	const params = new URLSearchParams(window.location.search);
  const tipo = params.get('q')
  const valor = params.get('value')
  const matricula = params.get('matricula')


	useEffect(() => {
		const obtenerDatos = async () => {
			if(!Object.keys(datosFinanzasDiscente).length) {
				await dispatch(obtenerMatriculasDiscente(tipo, valor));
			}
			dispatch(matriculaDiscenteActiva(matricula));
		}
		obtenerDatos()
	}, [])

  return ( 
    <Layout>
      <div className='ExaminarMatricula'>
        <div className='ExaminarMatricula__head'>
					<HeaderContent title='Registro de Pagos' />
				</div>
				<div className='ExaminarMatricula__body'>
					<CuotasDiscenteMatricula
						tipo={tipo}
						valor={valor}
					/>
          <TablaOtroPagos
						idMatricula={matricula}
						tipo={tipo}
						valor={valor}
					/>
				</div>
      </div>
    </Layout>
  );
}
 
export default ExaminarMatricula;