import React from 'react';
import Layout from '../../../../app/Layout';
import HeaderContent from '../../General/HeaderContent';
import Breadcrumb from '../../General/Breadcrumb';
import FormEventosTutor from '../../../components/Formularios/FormEventosTutor'

import './TutoresAsignacion.scss';

const TutoresAsignación = () => {
  return ( 
    <Layout>
      <HeaderContent
        title='Nueva asignación'
      />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Tutores" },
        ]}
      />
      <div className="tutores-asignacion">
        <div className="main-form">
          <FormEventosTutor />
        </div>
      </div>
    </Layout>
   );
}
 
export default TutoresAsignación;