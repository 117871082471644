import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router-dom';

//REdux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { nuevoProyecto, editarProyectoProgramacion } from '../../../../actions/programacionesActions';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';

const FormNuevoProyecto = () => {
  const { idProgramacion } = useParams();
  const initialState = {
    nombre: '',
    fechaInicio: '',
    fechaFin: '',
    prototipa: '',
    formula: '',
    color: '',
    idProgrmAnual: idProgramacion,
    idCiclo: null
  }
  const { errorProgramacion } = useSelector(state => state.programaciones);
  const { proyectoActivo } = useSelector(state => state.programaciones.programacion);
  const [datosProyecto, setDatosProyecto, resetValues] = useForm(proyectoActivo || initialState);

  const dispatch = useDispatch();
  const { nombre, fechaInicio, fechaFin, prototipa, 
    formula, color, idCiclo } = datosProyecto;

  const handleSubmit = async e => {
    e.preventDefault();
    if(nombre === '' || fechaInicio === ''|| fechaFin === '' || idCiclo === null) {
      avisoError('Faltan llenar campos obligatorios');
      return;
    }
    //Valida si es editar o insertar
    if (proyectoActivo) {
      await dispatch(editarProyectoProgramacion(datosProyecto));
    } else {
      await dispatch(nuevoProyecto(datosProyecto));
    }
    if (errorProgramacion) resetValues();
  }

  const handleClose = () => {
    dispatch(updateStateModalBasic());
  }

  return ( 
    <div className="FormNuevoProyecto">
      <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label className='label-required'>Nombre de producto</Form.Label>
          <Form.Control type='text' name='nombre' value={nombre} onChange={setDatosProyecto} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>Fecha de inicio</Form.Label>
          <Form.Control type='date' name='fechaInicio' value={fechaInicio} onChange={setDatosProyecto} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>Fecha de culminación</Form.Label>
          <Form.Control type='date' name='fechaFin' value={fechaFin} onChange={setDatosProyecto} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Color de proyecto</Form.Label>
          <Form.Control type='color' name='color' value={color} onChange={setDatosProyecto} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>Seleccionar ciclo</Form.Label>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Check
                type='radio'
                label='Ciclo I'
                name='idCiclo'
                id='formRadioCiclo1'
                checked={parseInt(idCiclo) === 1 ? true : false}
                value={1}
                onChange={setDatosProyecto}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Check
                type='radio'
                label='Ciclo II'
                name='idCiclo'
                id="formRadioCiclo2"
                checked={parseInt(idCiclo) === 2 ? true : false}
                value={2}
                onChange={setDatosProyecto}
              />
            </Form.Group>
          </Form.Row>
        </Form.Group>
 {/*       <Form.Group>
          <Form.Label className='label-required'>URL del prototipado</Form.Label>
          <Form.Control type='text' name='prototipa' value={prototipa} onChange={setDatosProyecto} />
        </Form.Group>
        <Form.Group>
          <Form.Label className='label-required'>URL de formulación</Form.Label>
          <Form.Control type='text' name='formula' value={formula} onChange={setDatosProyecto} />
        </Form.Group>*/}
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            type='button'
            className='btn-icon'
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button
            variant='info'
            type='submit'
            className='btn-icon'
          >
             {
              proyectoActivo ?
               'Guardar cambios'
              : <>
                <FontAwesomeIcon icon={faPlus} />
                Crear nuevo
              </>
            }
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
}
 
export default FormNuevoProyecto;