import React from "react";
import SimpleSelect from '../../../components/plugins/SimpleSelect';

//Redux
import {connect} from 'react-redux'
import {insertarDocenteSesion} from '../../../../actions/profesoresActions'
import { useEffect } from "react";
import { useState } from "react";
import { avisoCorrecto, avisoError, avisoCargando } from "../../../components/AvisosMensajes/MensajesSwalf";

const ModalInsertarProfesores = (props) => {

  //State
  const {profesoresCurso, idSesion} = props
  //Funciones
  const {insertarDocenteSesion} = props

  const [dataProfesor, setDataProfesor] = useState([])
  const [idProfesorCurso, setIdProfesorCurso] = useState({})
  const llenarDataProfesores = () => {
    let dataGenerado = []

    profesoresCurso.map(profesor => {
      dataGenerado = [...dataGenerado, {
        label: `${profesor.nombre_usuario} ${profesor.apellido_usuario}`,
        value: profesor.id_docente_modulo
      }]
    })
    setDataProfesor(dataGenerado)
  }

  const agregarProfesorSesion = async e => {
    e.preventDefault()

    if (Object.keys(idProfesorCurso).length === 0) {
      avisoError('No hay seleccion')
      return
    }

    avisoCargando('Guardando...')
    const dataInsercion = {
      id_docente_curso_p: idProfesorCurso.value,
      array_sesiones:[
        {id_sesion: idSesion}
      ] 
    }
    const {mensaje, textMensaje} = await insertarDocenteSesion(dataInsercion)

    if (mensaje) {
      avisoCorrecto(textMensaje)
      return
    }

    avisoError(textMensaje)
  }

  useEffect(() => {
      llenarDataProfesores()
  }, [profesoresCurso])

  return (
    <div
      className="modal fade insertar-profesores-sesiones"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Añadir Profesores
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={e => agregarProfesorSesion(e)}>
              <div className="form-group">
                <label htmlFor="profesores">Prefosores</label>
                <SimpleSelect
                  options={dataProfesor}
                  getSelection={setIdProfesorCurso}
                />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button type="submit" className="btn btn-danger">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  profesoresCurso: state.profesores.profesoresCurso
})

export default connect(mapStateToProps, {insertarDocenteSesion})(ModalInsertarProfesores);
