import React, { useEffect, useState } from "react";
import SimpleSelect from "../plugins/SimpleSelect";
import Divider from "@material-ui/core/Divider";
import { Form } from "react-bootstrap";

//Redux
import { connect } from "react-redux";
import { listarProyectosSelect } from "../../../actions/cursosActions";

import "./FiltroReporteEncuesta.scss";

const FiltroReporteEncuesta = (props) => {
  const {
    infoProyecto,
    categoriesEncuestados,
    sesionesSeleccionadas,
    dataEncuestados,
    listarProyectosSelect,
    selectProyectos,
    setProyecto,
    guardarCategorias,
    guardarData,
    guardarSesiones,
  } = props;

  const [categorias, setCategorias] = useState([])
  const [data, setData] = useState([])

  const filtro = ()  => {
   
    const encuestado = ["docente", "tutor", "docente_tutor"];

    let categoriasReturn = {
      docente: [],
      tutor: [],
      docente_tutor: []
    };

    let dataReturn = {
      docente: [],
      tutor: [],
      docente_tutor: []
    };

    encuestado.map((encu) => {
      let graphCategories = new Array();
      let graphDatos = new Array();
      
      for (let index = 0; index < categorias[encu].length; index++) {
        if (categorias[encu][index].estado !== sesionesSeleccionadas[index]) {
          graphCategories.push(categorias[encu][index].valor);
        }
      }
     
      for (let index = 0; index < data[encu].length; index++) {
        if (data[encu][index].estado !== sesionesSeleccionadas[index]) {
          graphDatos.push(data[encu][index].valor);
        }
      }
    
      categoriasReturn[encu] = graphCategories;
      dataReturn[encu] = graphDatos;
    });
    guardarCategorias(categoriasReturn);
    guardarData(dataReturn);
  };


  useEffect(() => {

    setCategorias(categoriesEncuestados)
    setData(dataEncuestados)

  }, [categoriesEncuestados, dataEncuestados])

  useEffect(() => {
    listarProyectosSelect();
  }, []);

  useEffect(() => {
    if(Object.keys(categorias).length > 0){
      filtro()
     // console.log('sss')
    }
  }, [sesionesSeleccionadas])

  return (
    <div className="filtro-respuesta p-3">
      <h3>Filtro</h3>
      <p>Productos</p>
      <SimpleSelect
        options={selectProyectos}
        getSelection={setProyecto}
        defaultValue={infoProyecto}
      />
      <Divider className="my-3" />
      
      { Object.keys(infoProyecto).length > 0 ? (
        <div className="lista-sesiones">
        {sesionesSeleccionadas.map((sesion, index) => (
          <ListaSesiones
            key={index}
            index={index}
            filtro={filtro}
            sesion={sesion}
            guardarSesiones={guardarSesiones}
          />
        ))}
      </div>
      ) : ( null)}
      
    </div>
  );
};

const mapSateToProps = (state) => {
  let categoriesEncuestados = {};
  let dataEncuestados = {};

  const encuestado = ["docente", "tutor", "docente_tutor"];

  encuestado.map((encu) => {
    let categorias = new Array();
    let datos = new Array();
    if (state.reporte.reporteTutoria[encu]) {
      categorias = new Array();
      datos = new Array();

      state.reporte.reporteTutoria[encu].categories.map((item) => {
        categorias.push({ valor: item, estado: false });
      });

      state.reporte.reporteTutoria[encu].data.map((item) => {
        datos.push({ valor: item * 100, estado: false });
      });
    }

    categoriesEncuestados[encu] = categorias;
    dataEncuestados[encu] = datos;
  });

  return {
    selectProyectos: state.cursos.select_proyectos,
    categoriesEncuestados,
    dataEncuestados,
  };
};

const mapDispatchToProps = {
  listarProyectosSelect,
};

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(FiltroReporteEncuesta);

const ListaSesiones = (props) => {
  const { index, guardarSesiones, sesion } = props;
  
  return (
    <>
      <Form.Check
        onChange={(e) => guardarSesiones(index, e.target.checked)}
        value={index}
        checked={sesion}
        inline
        label={`Sesión ${index + 1}`}
        type="checkbox"
        id={`inline-checkbox-${index}`}
      />{" "}
      <br />
    </>
  );
};
