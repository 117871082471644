import authAxios from "../config/authAxios"
import { modulosTypes } from "./types"
import Swal from 'sweetalert2';
import { avisoCorrecto, avisoError } from "../view/components/AvisosMensajes/MensajesSwalf";

export const obtenerModulos = () => {
  return async dispatch => {
    dispatch({
      type: modulosTypes.OBTENER_MODULOS
    })
    try {
      const respuesta = await authAxios.get(`/admin/mdlo_mostrarModulos`);
      dispatch({
        type: modulosTypes.OBTENER_MODULOS_EXITO,
        payload: respuesta.data
      })
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: modulosTypes.OBTENER_MODULOS_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}

export const eliminarModulo = idModulo => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Estas seguro?",
          text: "Se eliminara todo registro",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          try {
            const respuesta = await authAxios.delete(`/admin/mdlo_eliminarModulo__idModulo/${idModulo}`);
            if (!(respuesta.data.error)) {
              dispatch({
                payload: idModulo,
                type: modulosTypes.ELIMINAR_MODULO_EXITO
              })
            }
            return true;
          } catch (error) {
              console.log(error)
              const msg = error.response !== undefined ? error.response.data.msg : error 
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: msg
              })
          }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
          //mensaje que se elimino el archivo correctamente
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Eliminado!",
              "Se elimino el modulo correctamente",
              "success"
          );
          }
      });
  }
}

export const insertarModulo = (datos, onCloseModal) => {
  return async dispatch => {
    dispatch({
      type: modulosTypes.NUEVO_MODULO
    })
    try {
      const respuesta = await authAxios.post(`/admin/modulo`, datos);
      dispatch({
        type: modulosTypes.NUEVO_MODULO_EXITO,
        payload: respuesta.data.data
      })
      avisoCorrecto('Se inserto módulo correctamente');
      if(onCloseModal) onCloseModal();
    } catch (error) {
      console.log(error.response.data.msg);
      dispatch({
        type: modulosTypes.NUEVO_MODULO_ERROR
      })
    }
  }
}

export const editarModulo = (idModulo, datos, onCloseModal) => {
  return async dispatch => {
    dispatch({
      type: modulosTypes.EDITAR_MODULO
    })
    try {
      const respuesta = await authAxios.put(`/admin/modulo/${idModulo}`, datos);
      dispatch({
        type: modulosTypes.EDITAR_MODULO_EXITO,
        payload: {
          idModulo,
          datos: respuesta.data.data
        }
      })
      avisoCorrecto('Se edito correctamente el módulo');
      if(onCloseModal) onCloseModal();
    } catch (error) {
      console.log(error);
      avisoError(error.response.data.msg);
      dispatch({
        type: modulosTypes.EDITAR_MODULO_ERROR,
        payload: error.response.data.msg
      })
    }
  }
}
