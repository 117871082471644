import React, { useState, useEffect } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import "./recursos.scss";
import Breadcrumb from "../General/Breadcrumb";
import SimpleSelect from "../../components/plugins/SimpleSelect";
import Videos from "./Videos";
import SesionesVivo from "./SesionesVivo";
import Archivos from "./Archivos";
import ModalNuevo from "./ModalNuevo";
//Redux
import { connect } from "react-redux";
import {
  listarProyectos,
  listarSesionesCursos,
  traerProyectosDocente,
  traerSesionesDocente,
} from "../../../actions/cursosActions";
import {
  listarRecurso,
  eliminarRecursos,
} from "../../../actions/recursosActions";
import { metodoConfimacion } from "../../../helpers/functions";
import { Button, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAudio,
  faFileUpload,
  faFilm,
  faLink,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import PodCast from "./PodCast";

const Recursos = (props) => {
  //State props
  const { proyectos, sesionesCurso,usuario } = props;
  //Funciones
  const {
    listarProyectos,
    listarSesionesCursos,
    eliminarRecursos,
    listarRecurso,
    traerProyectosDocente,
    traerSesionesDocente
  } = props;

  const [selectProyecto, setSelecProyecto] = useState([]);
  const [selectSesiones, setSelectSesiones] = useState([]);
  const [idProyecto, setIdProyecto] = useState();
  const [idSesion, setIdSesion] = useState();
  const llenarSelectProyectos = async () => {
    let dataGenerado = [];
    //console.log(usuario)
    if(usuario.id_tp_nivel_usuario!=="2") 
    {   proyectos.map((proyecto) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: proyecto.id_proyecto,
          label: proyecto.nombre_proyecto,
        },
      ];
    });
    }
    else
    {
     const proyectosDocente = await traerProyectosDocente(usuario.id_empleado)

     proyectosDocente.map((proyecto) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: proyecto.id_proyecto,
          label: proyecto.nombre_proyecto,
        },
      ];
    });
    }

    setSelecProyecto(dataGenerado);
  };

  const llenarSelectSesiones = async () => {
    let dataGenerado = [];

    if(usuario.id_tp_nivel_usuario!=="2") 
     {   sesionesCurso.map((sesion, index) => {
      dataGenerado = [
        ...dataGenerado,
        {
          value: sesion.id_sesion,
          label: `${index+1}.- ${sesion.nombre_sesion}`,
        },
      ];
    });}
    else
    {
      if(idProyecto)
      {  const sesionesDocente = await traerSesionesDocente(usuario.id_empleado,idProyecto.value)
       // console.log(sesionesDocente)
       sesionesDocente.map((sesion, index) => {
          dataGenerado = [
            ...dataGenerado,
            {
              value: sesion.id_sesion,
              label: `${index+1}.- ${sesion.nombre_sesion}`,
            },
          ];
        });
      }
    }
    setSelectSesiones(dataGenerado);
  };

  //eliminar recurso
  const eliminarRecurso = (idArchivoSesion) => {
    const dataMetodo = {
      funcion: eliminarRecursos,
      id: idArchivoSesion,
      mensajeAdvertencia: "No se podra recuperar el recurso",
      mensajeExito: "Recurso eliminado correctamente",
    };

    metodoConfimacion(dataMetodo);
  };

  //Listando los proyectos
  useEffect(() => {
    listarProyectos();
  }, []);

  //Listar sesiones del proyecto cuando se seleccione un proyecto
  useEffect(() => {
    if (idProyecto) {
      listarSesionesCursos(idProyecto.value);
      listarRecurso(99999);
    }
  }, [idProyecto]);

  //Llenar el select de proyectos
  //Llenar el select de sesiones
  useEffect(() => {
    llenarSelectProyectos();
    llenarSelectSesiones();
  }, [proyectos, sesionesCurso]);

  useEffect(() => {
    if (idSesion !== undefined && Object.keys(idSesion).length > 0) {
      listarRecurso(idSesion.value);
    }
  }, [idSesion, idProyecto]);

  return (
    <Layout>
      <HeaderContent title="Recursos" icon="create_new_folder" />
      <Breadcrumb
        link={[
          { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "/recursos", nombre: "Recursos" },
        ]}
      />

      <div className="container-fluid px-0 recursos">
        <div className="row">
          <div className="col-md-9">
            <div className="row recursos__content-filtro">
              <div className="col-md-4 filtro">
                <label htmlFor="">Producto</label>
                <SimpleSelect
                  options={selectProyecto}
                  getSelection={setIdProyecto}
                />
              </div>
              <div className="col-md-4 filtro">
                <label htmlFor="">Sesiones</label>
                <SimpleSelect
                  options={selectSesiones}
                  getSelection={setIdSesion}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 recursos__content-nuevo">
            <Button
              variant='primary'
              className="btn recursos__btn-nuevo btn-icon"
              data-toggle="modal"
              data-target="#modal-nuevo"
              data-whatever="@mdo"
            >
              <FontAwesomeIcon icon={faPlus} />
              Nuevo recurso
            </Button>
          </div>
        </div>

        <div className="box content-recursos-tab">
          <div className="box-body">
          {
            usuario.id_tp_nivel_usuario!=="2" ? <>
            <Tabs
              defaultActiveKey="archivos"
              id="recursos-tab"
            >
            
              <Tab
                eventKey="archivos"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faFileUpload} />
                    </div>
                    Los Materiales
                  </>
                }
              >
                <Archivos eliminarRecurso={eliminarRecurso} />
              </Tab>
              <Tab
                eventKey="videos"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faFilm} />
                    </div>
                   La clase Offline
                  </>
                }
              >
                <Videos eliminarRecurso={eliminarRecurso} />
              </Tab>
              <Tab
                eventKey="sesionesvivo"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faLink} />
                    </div>
                    La clase Online
                  </>
                }
              >
                <SesionesVivo eliminarRecurso={eliminarRecurso} />
              </Tab>
              <Tab
                eventKey="podcast"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faFileAudio} />
                    </div>
                    PodCasts
                  </>
                }
              >
                <PodCast eliminarRecurso={eliminarRecurso} />
              </Tab>
            </Tabs>
            </> : 
            <Tabs
              defaultActiveKey="archivos"
              id="recursos-tab"
            >
              <Tab
                eventKey="archivos"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faFileUpload} />
                    </div>
                    Recursos
                  </>
                }
              >
                <Archivos eliminarRecurso={eliminarRecurso} />
              </Tab>
              <Tab
                eventKey="podcast"
                title={
                  <>
                    <div className="text-center w-100 mb-2">
                      <FontAwesomeIcon icon={faFileAudio} />
                    </div>
                    PodCasts
                  </>
                }
              >
                <PodCast eliminarRecurso={eliminarRecurso} />
              </Tab>
            </Tabs>
          }
          </div>
        </div>
        <ModalNuevo idSesion={idSesion !== undefined ? idSesion.value : ""} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  proyectos: state.cursos.proyectos,
  sesionesCurso: state.cursos.sesionesCurso,
  usuario: state.usuario.usuario,
});

const mapDispatchToProps = {
  listarProyectos,
  listarSesionesCursos,
  listarRecurso,
  eliminarRecursos,
  traerSesionesDocente,
  traerProyectosDocente,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recursos);
