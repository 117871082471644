import React from 'react';
import ListaClasificacionHead from './ListaClasificacionHead';
import ResultadoClasificacion from './ResultadoClasificacion';
import './VistaClasificacion.scss';
import ListaTemasAreaWithTrabajador from '../../../../../containers/TrabajadoresContainers/ListaTemasAreaWithTrabajador';

const VistaClasificacionTrabajador = ({ nombre_estado, handleEditar }) => {

  return ( 
    <div className="VistaClasificacionTrabajador">
      <div className='VistaClasificacionTrabajador__head border-bottom py-3 d-flex justify-content-between align-items-center'>
        <ListaClasificacionHead
          handleModalEditar={handleEditar}
        />
      </div>
      <div className='VistaClasificacionTrabajador__body my-4'>
        <div className='VistaClasificacionTrabajador__resultado'>
          <ResultadoClasificacion
            nombre_estado={nombre_estado}            
          />
        </div>
      </div>  
      <ListaTemasAreaWithTrabajador />
    </div>
   );
}
 
export default VistaClasificacionTrabajador;