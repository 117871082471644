import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap'
import { useEffect } from 'react';

//Redux
import { connect } from 'react-redux';
import { editarReporteEstudiante } from '../../../../actions/reportesActions';

const UpdateObservationsReporte = (props) => {

  const { reporte, editarReporteEstudiante, proyectos } = props

  const [dataEditar, setDataEditar] = useState( initialState() )

  const { observaciones } = dataEditar

  const guardarValor = reporte => {
    setDataEditar({
      observaciones: reporte.observaciones || ''
    })
  }

  const handleChange = e => {
    setDataEditar({
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()



    editarReporteEstudiante(reporte.id_reporte_pryt, dataEditar, proyectos)
  }

  useEffect(() => {
    guardarValor(reporte)
  }, [reporte])

  return ( 
    <div>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              defaultValue={reporte.nombre_usuario}
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Observaciones</Form.Label>

            <Form.Control 
              as='select'
              value={observaciones}
              onChange={handleChange}
              name='observaciones'
            >
              <option value=''>--seleccionar--</option>
              <option value='cancela_deuda'>Cancelara deuda</option>
              <option value='activo'>Estará activo</option>
              <option value='justificar_asistencia'>Justificará asistencia</option>
              <option value="participa_clase">Participará mas en clases</option>
              <option value="rendira_evaluacion">Rendirá evaluación extemporánea</option>
            </Form.Control>
          
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' variant='info'>
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </div>
   );
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
  editarReporteEstudiante
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UpdateObservationsReporte);

const initialState = () => ({
  observaciones: ''
})