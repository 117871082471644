import { finanzasReportesTypes } from "../actions/types";

const initialState = {
  reporteIngresos: {},
  reporteDeudas: {},
  facturas: {
    todos: [],
    activo: {}
  },
  reporteDescuentosCargos: null,
  loading: false,
  error: null
}

export default function(state = initialState, action){
  switch (action.type) {
    case finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS:
    case finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS:
    case finanzasReportesTypes.OBTENER_FACTURAS:
    case finanzasReportesTypes.OBTENER_DATOS_FACTURA:
    case finanzasReportesTypes.OBTENER_REPORTE_CARGOS_DESCUENTOS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS_EXITO:
      return {
        ...state,
        loading: false,
        reporteIngresos: action.payload,
        error: null
      }
    case finanzasReportesTypes.OBTENER_REPORTE_INGRESOS_FINANZAS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reporteIngresos: {}
      }
    case finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS_EXITO:
      return {
        ...state,
        reporteDeudas: action.payload,
        loading: false,
        error: null
      }
    case finanzasReportesTypes.OBTENER_REPORTE_DEUDAS_FINANZAS_ERROR:
      return {
        loading: false,
        error: action.payload,
        reporteIngresos: {}
      }
    case finanzasReportesTypes.OBTENER_FACTURAS_EXITO:
      return {
        ...state,
        facturas: {
          ...state.facturas,
          todos: action.payload
        },
        loading: false,
        error: null 
      }
    case finanzasReportesTypes.OBTENER_FACTURAS_ERROR:
      return {
        ...state,
        facturas: {
          ...state.facturas,
          todos: []
        },
        error: action.payload,
        loading: false
      }
    case finanzasReportesTypes.OBTENER_DATOS_FACTURA_EXITO:
      return {
        ...state,
        facturas: {
          ...state.facturas,
          activo: action.payload
        },
        error: null,
        loading: false
      }
    case finanzasReportesTypes.OBTENER_DATOS_FACTURA_ERROR:
      return {
        ...state,
        facturas: {
          ...state.facturas,
          todos: []
        },
        error: action.payload,
        loading: false
      }
    case finanzasReportesTypes.OBTENER_REPORTE_DESCUENTOS_EXITO:
    case finanzasReportesTypes.OBTENER_REPORTE_CARGOS_EXITO:
      return {
        ...state,
        reporteDescuentosCargos: action.payload,
        error: null,
        loading: false
      }
    case finanzasReportesTypes.OBTENER_REPORTE_CARGOS_DESCUENTOS_ERROR:
      return {
        ...state,
        reporteCargos: null,
        error: action.payload,
        loading: false
      }
    case finanzasReportesTypes.LIMPIAR_RESULTADO_BUSQUEDA:
      return initialState;
    default:
      return state;
  }
}