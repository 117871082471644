import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import shortid from "shortid";
import "./NuevaPregunta.scss";
import { avisoError } from "../../../AvisosMensajes/MensajesSwalf";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { useEffect } from "react";

const PurpleSwitch = withStyles({
  switchBase: {
    color: blue[700],
    "&$checked": {
      color: blue[800],
    },
    "&$checked + $track": {
      backgroundColor: blue[800],
    },
  },
  checked: {},
  track: {},
})(Switch);

const NuevaPregunta = (props) => {
  const {
    preguntaEditar,
    agregarPregunta,
    actualizarPregunta,
    eliminarPregunta,
  } = props;

  const [infoPregunta, setInfoPregunta] = useState(initialState());

  const {
    pregunta,
    id_tp_pregunta,
    id_tp_tema_encuesta,
    id_obligacion,
    descripcion,
  } = infoPregunta;

  const toggleChecked = () => {
    setInfoPregunta({
      ...infoPregunta,
      id_obligacion: !id_obligacion,
    });
  };

  const handleChange = (e) => {
    setInfoPregunta({
      ...infoPregunta,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (id_tp_pregunta === "") {
      avisoError("Falta ingresar tipo de pregunta");
      return;
    }

    if (id_tp_pregunta === "1") {
      if (
        id_tp_pregunta === "" ||
        pregunta === "" ||
        id_tp_tema_encuesta === ""
      ) {
        avisoError("Todos los campos son obligatorios");
        return;
      }
    }

    if (id_tp_pregunta === "2") {
      delete infoPregunta["id_tp_tema_encuesta"];
      if (id_tp_pregunta === "" || pregunta === "") {
        avisoError("Todos los campos son obligatorios");
        return;
      }
    }
    if (preguntaEditar === undefined) {
      infoPregunta.id_pregunta_reporte = shortid.generate();
      agregarPregunta(infoPregunta);
    } else {
      actualizarPregunta(infoPregunta);
    }
    setInfoPregunta(initialState());
  };

  const guardarAutoCompletadoEditar = () => {
    if (preguntaEditar !== undefined) {
      setInfoPregunta(preguntaEditar);
    }
  };

  useEffect(() => {
    guardarAutoCompletadoEditar();
  }, [preguntaEditar]);

  return (
    <div className="nueva-pregunta">
      <Form onSubmit={onSubmit}>
        <Modal.Body>
        <Form.Group>
          <Form.Label>Pregunta</Form.Label>
          <Form.Control
            name="pregunta"
            type="text"
            value={pregunta}
            onChange={handleChange}
            placeholder="Tu pregunta"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Tipo</Form.Label>
          <Form.Control
            onChange={handleChange}
            name="id_tp_pregunta"
            value={id_tp_pregunta}
            as="select"
            size="lg"
          >
            <option value="">--Seleccionar--</option>
            <option value="1">Calificación</option>
            <option value="2">Texto</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <FormControlLabel
            control={
              <PurpleSwitch
                size="small"
                checked={
                  typeof id_obligacion === "string"
                    ? (id_obligacion === '1' ? true : false)
                    : id_obligacion
                }
                onChange={toggleChecked}
              />
            }
            label="Obligatorio"
          />
        </Form.Group>
        {id_tp_pregunta !== "2" ? (
          <Form.Group>
            <Form.Label>Dirigido</Form.Label>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                checked={id_tp_tema_encuesta === "1" ? true : false}
                value="1"
                onChange={handleChange}
                inline
                label="Docente"
                name="id_tp_tema_encuesta"
                type="radio"
                id={`inline-radio-1`}
              />
              <Form.Check
                checked={id_tp_tema_encuesta === "2" ? true : false}
                value="2"
                onChange={handleChange}
                inline
                label="Tutor"
                name="id_tp_tema_encuesta"
                type="radio"
                id={`inline-radio-2`}
              />
              <Form.Check
                checked={id_tp_tema_encuesta === "3" ? true : false}
                value="3"
                onChange={handleChange}
                inline
                label="Buenas prácticas"
                name="id_tp_tema_encuesta"
                type="radio"
                id={`inline-radio-3`}
              />
            </div>
          </Form.Group>
        ) : null}
        <Form.Group>
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as='textarea'
            name='descripcion'
            value={descripcion}
            onChange={handleChange}
          />
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {preguntaEditar !== undefined ? (
            <Button
              onClick={() => eliminarPregunta(preguntaEditar)}
              className="btn btn-danger"
              type="button"
            >
              Eliminar
            </Button>
          ) : null}
          <Button variant='info' type="submit">Guardar</Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default NuevaPregunta;

const initialState = () => ({
  id_pregunta_reporte: "",
  pregunta: "",
  id_tp_pregunta: "",
  id_tp_tema_encuesta: "",
  id_obligacion: false,
  descripcion: "",
});
