import React from "react";

const Search = ({ buscar, buscarRef}) => {
  return (
    <div className="content-search d-flex align-items-center">
      <label htmlFor="buscar" className='mb-0'>Buscar:</label>
      <input
        type="search"
        name=""
        onChange={(e) => buscar(e.target.value)}
        className="form-control search form-control-sm"
        ref={buscarRef}
      />
    </div>
  );
};

export default Search;
