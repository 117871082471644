import React from 'react';
import TablaDescuentos from '../Descuentos/TablaDescuentos';

const DescuentosFactura = ({ descuentos }) => {
  return ( 
    <div className="DescuentosFactura">
      <h4 className='title-head'>Descuentos</h4>
      <div className="box">
        <div className="box-body">
         <TablaDescuentos
            acciones={false}
            descuentos={descuentos}
          />
        </div>
      </div>
    </div>
  );
}
 
export default DescuentosFactura;