import React, { useEffect, useState } from "react";
import Layout from "../../../app/Layout";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile, faListAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import MaterialtableDemo from "../../components/plugins/MaterialTableDemo";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import { updateStateModalLarge } from "../../../actions/modalActions";
import ModalBasicwithRedux from "../../../containers/ModalLargewhithRedux";
import FormularioSubidaBoletas from './FormularioSubidaBoletas';

//Reduxt
import {useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { eliminarBoleta, listarBoletas } from "../../../actions/boletasActions";
 
const BoletasDePago = () => {
  //State
  const {boletas, loading} = useSelector(state=>state.boletas)
  const {usuario} = useSelector(state=>state.usuario)

  const dispatch = useDispatch();
  const meses = [
    {
      id:1,
      value:"Enero"
    },
    {
      id:2,
      value:"Febrero"
    },
    {
      id:3,
      value:"Marzo"
    },
    {
      id:4,
      value:"Abril"
    }
    ,
    {
      id:5,
      value:"Mayo"
    }
    ,
    {
      id:6,
      value:"Junio"
    }
    ,
    {
      id:7,
      value:"Julio"
    }
    ,
    {
      id:8,
      value:"Agosto"
    }
    ,
    {
      id:9,
      value:"Septiembre"
    }
    ,
    {
      id:10,
      value:"Octubre"
    }
    ,
    {
      id:11,
      value:"Noviembre"
    }
    ,
    {
      id:12,
      value:"Diciembre"
    }
]

  useEffect(() => {
    dispatch(listarBoletas(usuario))
  }, []);

  const handleSubidaBoletas = () => {
    dispatch(updateStateModalLarge(<FormularioSubidaBoletas />, 'Cargar Boletas de Pago'));
  }

  const handleEliminar = (id) =>{
    dispatch(eliminarBoleta(id))
  }
  return (
    <Layout>
      <div className="Trabajadores">
        <div className="Trabajadores__container">
          <div className="Trabajadores__head">
            <HeaderContent title="Boletas de Pago" icon="group" />
            <Breadcrumb
              link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
          { url: "", nombre: "Boletas de Pago" },
              ]}
            />
          { usuario.id_tp_nivel_usuario==="1" || usuario.id_tp_nivel_usuario==="6" ?  <button className="btn btn-primary" onClick={()=>handleSubidaBoletas()}> Cargar Documentos </button> : null}
          </div>
          <div className="Trabajadores__body container-fluid  px-0 content-actions mt-3">
          <ModalBasicwithRedux />
            <SpinnerLoading loading={loading}>
              <div className="box mt-5">
                <div className="box-header with-border">
                  <h5
                      style={{ color: "#434343" }}
                    >
                    <FontAwesomeIcon icon={faListAlt} /> Lista de Documentos
                  </h5>
                
                </div>
                <div className="box-primary">
                  <MaterialtableDemo
                    colums={colums(meses,usuario,handleEliminar)}
                    tabla={boletas}
                    keyCol="id_registro"
                  />
                </div>
              </div>
            </SpinnerLoading>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BoletasDePago;

/**====================== TABLA =========== */

const colums = (meses, usuario,handleEliminar) => [
  {
    text: 'ID',
    key: 'id_registro',
    className: 'id_registro',
    width: 50
  }
  ,
  
  {
    text: "Fecha Registro",
    key: "fecha_registro",
    align: "center",
    sortable: true,
    className: "titulo",
  },
  {
    text: "Periodo",
    key: "periodo",
    align: "center",
    sortable: true,
    className: "periodo",
  }
  ,
  {
    text: "Mes",
    key: "mes",
    align: "center",
    className: "mes",
    sortable: true,
    cell:(record)=> `${meses.find(mes=>mes.id==record.mes).value}`
  }
  ,
  {
    text:"Trabajador",
    key: "empleado",
    align: "center",
    className: "mes",
    sortable: true
  }
  ,
  {
    text:"Documento",
    key: "archivo",
    align: "center",
    className: "mes",
    sortable: true,
    cell: (record) => 
      <div className="d-flex justify-content-center">
        <a className="btn btn-info btn-xs" href={`${process.env.REACT_APP_API_URL}/files_inedi/boletas_trabajadores/firmadas/${record.archivo}`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>
  
        {
        /* <a className="btn btn-info btn-xs" href={`watana:?url_logo=${process.env.REACT_APP_API_URL}/files_inedi/logo_inedi712.png&url_descarga=${process.env.REACT_APP_API_URL}/files_inedi/boletas_trabajadores/firmadas/${record.archivo}&url_subida=${process.env.REACT_APP_API_URL}/subirCert/${record.archivo}&firma_x=20&firma_y=705&firma_largo=250&firma_alto=70&firma_pagina=1&firma_texto=%3CFIRMANTE%3E%0A%3CORGANIZACION%3E%0A%3CTITULO%3E%0A%3CCORREO%3E%0AFecha%3A%20%3CFECHA%3E%0A`} target="_blank" download><FontAwesomeIcon icon={faFile}/></a>
          */}
      </div>
    
  }
  ,
/*  {
    text:"Estado",
    key: "nombre_empleado",
    align: "center",
    className: "mes",
    sortable: true
  }
  ,
  {
    text:"Fecha Firma",
    key: "fecha_firmado",
    align: "center",
    className: "mes",
    sortable: true
  }
  ,*/
  {
    text: "Acciones",
    key: "Acciones",
    align: "center",
    className: "Acciones",
    sortable: false,
    cell:(record) =>  <>
    {usuario.id_tp_nivel_usuario==="1"? <Button
      variant='danger'
      size='xs'
      type="button"
      onClick={() => handleEliminar(record.id_registro)}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>: 'No disponible'}
  </>
  }
  
];
