import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarRespuestaComentario } from '../../../actions/cursosActions';

const Respuesta = ({respuesta}) => {
    const dispatch = useDispatch()
    const usuario = useSelector(state=> state.usuario.usuario);
    const eliminarRespuesta = (id)=>
    {
        dispatch(eliminarRespuestaComentario(id))
    }

    return (
        <div className="row" style={{marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
        <div className="col-md-1">
        {
            respuesta.img_usuario === null ? <img
           
                  alt=""
                  src='/images/user.png'
                 style={{width:'70px', height:'70px', borderRadius:'100%'}}
                /> :
              <img
                  alt=""
                  src={`${process.env.REACT_APP_API_URL}/${respuesta.img_usuario}`}
                  style={{width:'70px', height:'70px', borderRadius:'100%'}}
                />
            
        }
        </div>
        <div className="col-md-6">
        <h4>{respuesta.nombre_usuario + ' ' + respuesta.apellido_usuario}</h4>
        <p>Publicado el: {respuesta.fecha_registro}</p>
        </div>
        { respuesta.autor === usuario.id_usuario ? <div className="col-4 p-3 text-right"> <button className="btn btn-danger" onClick={()=>eliminarRespuesta({id_respuesta:respuesta.id_respuesta,docente:usuario.id_empleado})}><FontAwesomeIcon icon={faTrash} /></button> </div> : ''}
        <div className="col-12 p-3 mt-2" style={{fontSize:'13px', borderTop:'1px solid #ebe'}} > <p>{respuesta.respuesta}</p></div>
</div>
    );
};

export default Respuesta;