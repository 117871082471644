import React from 'react';
import HeaderContent from '../../pages/General/HeaderContent'
import Breadcrumb from '../../pages/General/Breadcrumb'
import Layout from '../../../app/Layout';
import {useHistory} from 'react-router-dom'
import './profesor.scss'
import {connect} from 'react-redux'
import RegistroDocente from '../../components/RegistroUsuarios/RegistroUsuarios';

const NuevoProfesor = (props) => {

    return (
      <Layout>
        
            <HeaderContent title={"Nuevo docente"} icon={"person"} />
            <Breadcrumb
              link={[
                {url:'/inicio', nombre: 'Inicio', icon: 'fas fa-home'},
                {url:'/profesores', nombre: 'Docentes'},
                {url:'', nombre: 'Nuevo docente'}
              ]}
              />
        <div className="container-fluid px-2">
        <RegistroDocente/>
        </div>
            
      </Layout>
    );
}

const mapStateToProps = state => ({
  usuario: state.usuario.usuario
})

export default connect(mapStateToProps, {})(NuevoProfesor);