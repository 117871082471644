import React, {useState, useEffect} from 'react';
//


//Redux
import { useDispatch, useSelector } from 'react-redux';
import { asignarLeadsManualmente } from '../../../actions/leadsActions';
import useSelectAsesores from "../../../hooks/useSelectAsesores";
import { avisoError } from '../../components/AvisosMensajes/MensajesSwalf';
const style = {
    boton: {
        background: "#28314A",
        color: "#fff"
    },
    buttonText: {
        fontSize: "13px",
        background: "#28314A",
        color: '#fff'
    },
    textArchivo: {
        fontSize: "12px",
        fontWeight: "600",
        color: "#aaa"
    }
  }


const FormularioAsignacionManual = ({leads, resetSeleccion}) => {

const [asesor, SelectAsesor] = useSelectAsesores(null)
const {usuario} = useSelector(state=>state.usuario)
const dispatch = useDispatch();

const initialState = {
  asesor: null,
  leads_: leads
}
const[datosLead,setdatosLead] = useState(initialState)


useEffect(()=>{
  if(asesor)
  {
    setdatosLead({
      ...datosLead,
      asesor: asesor
    });
  }
},[asesor])

const handleSubmit = (e) =>
{
    e.preventDefault();
    if(datosLead.desde_!==0 && datosLead.hasta_!==0 && asesor)
      {
       dispatch(asignarLeadsManualmente(datosLead,usuario))
       //console.log(datosLead)
       resetSeleccion(null)
       
      }
    else
      { 
        avisoError("Debe Seleccionar un asesor, y colocar un rango")
      }

}

 

 return (
 
  <form id="formNuevo3" style={{padding:'15px'}} onSubmit={e => handleSubmit(e,this)}>
               
                  <div className="form-row align-items-center mb-3">
                    <div className="col-md-3">
                      <label htmlFor="titulopop" className="mb-0">
                        Asesor:
                      </label>
                    </div>
                    <div className="col-md-7">
                       <SelectAsesor/>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
         
                  <div className="form-group">
                   
                  </div>
                  <button type="submit" className="btn" style={style.boton}>
                    <i className="fa fa-upload"></i> Asignar Leads
                  </button>
                </form>
  
    );
};

export default FormularioAsignacionManual;
