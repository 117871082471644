import React from 'react';
import TemaArea from './TemaArea';
import PropType from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faEdit, faBriefcase } from '@fortawesome/free-solid-svg-icons';

const ListaTemasArea = ({ temas = [], nombre_area_dictado, handleEditar }) => {
  
  return ( 
    <div className="ListaTemasArea">
      <div className='ListaTemasArea__head d-flex border-bottom py-3 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon icon={faListUl} />
          <h5 className='ListaTemasArea__title ml-1'>Listado de temas</h5>
        </div>
        <div className='d-flex align-items-center cursor-pointer'>
          <FontAwesomeIcon icon={faEdit} className='mr-1' />
          <span onClick={() => handleEditar()}>Editar</span>
        </div>        
      </div>
      <div className="ListaTemasArea__body my-3">
        <div className='ListaTemasArea__dictado d-flex align-items-center'>
          <FontAwesomeIcon icon={faBriefcase} className='mr-1' />
          <p>Área: <span>{ nombre_area_dictado }</span></p>
        </div>
        <ul className='ListaTemasArea__lista pl-0 mt-3'>
          {temas && temas.length ? temas.map((tema, index) => (
            <TemaArea key={index} {...tema}/>
          )) : (
              <p>No hay temas elejidos</p>
          )}
        </ul>
      </div>
    </div>
   );
}

ListaTemasArea.protTypes = {
  temas: PropType.array.isRequired,
  nombre_area_dictado: PropType.string,
  handleModalEditar: PropType.func
}
 
export default ListaTemasArea;