import React from 'react';
import { useParams } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux'; 

const withCtrInsertDocentes = (WrappedComponent) => props => {

  const { idCapacitacion } = useParams()
  
  const datos = useSelector(state => state.control_capacitacion.area_trabajo.datos) 
  const docentesFiltro = datos[idCapacitacion] ? datos[idCapacitacion].docentes_filtro : []

  return ( 
    <WrappedComponent
      {...props}
      datos={docentesFiltro}
    />
   );
}
 
export default withCtrInsertDocentes;