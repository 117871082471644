import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getJWT } from '../helpers/functions'

const PrivateRoute = ({ component: Component, ...rest}) => {

  return (
      <Route
          {...rest}
          render={
              props => getJWT() ? (
                  <Component {...props} />
              ) : (
                  <Redirect to='/login' />
              )
          }
      />
  )
}


export default PrivateRoute;