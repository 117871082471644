import React from "react";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import "./Rating.scss";
import { useEffect, useState } from "react";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function HalfRating(props) {
  const classes = useStyles();

  const [stars, setStars] = useState(0);
  const [hover, setHover] = React.useState(-1);
  const { defaultStars = 0, index, getStars, labelsTooltip = {}, disabled = false } = props;

  const handleChange = (newValue) => {
    setStars(newValue || 0);
    getStars(newValue || 0);
  };

  useEffect(() => {
    setStars(defaultStars);
  }, [defaultStars]);

  return (
    <div className="rating">
      <Tooltip title={(stars !== null ) && labelsTooltip[hover !== -1 ? hover : stars]} placement="top-start">
        <div className={classes.root}>
          <Rating
            name={`size-large-${index}`}
            onChange={(_, newValue) => handleChange(newValue)}
            value={stars}
            onChangeActive={(_, newHover) => {
              setHover(newHover);
            }}
            size="large"
            disabled={disabled}
          />
        </div>
      </Tooltip>
    </div>
  );
}
