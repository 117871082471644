import React, { useState } from 'react';
import ModalBasic from '../view/components/Modals/ModalBasic';


const useModalBasic = (initialState) => {
  
  const [showModal, setShowModal] = useState(initialState || false);
  const onShowModal = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  return { showModal, onShowModal, onCloseModal };
}
 
export default useModalBasic;