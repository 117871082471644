import React from 'react';
import DropZone from '../../../plugins/Dropzone';
import { useParams } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { agregarNuevaImagen } from "../../../../../actions/controlCapacitacionesActions";
import ImagenConfirmacion from './ImagenConfirmacion';

const ImagesConfirmacion = () => {

  const { idCapacitacion } = useParams();
  const dispatch = useDispatch()
  const sesionActiva = useSelector(state => state.control_capacitacion.area_trabajo.activo)
  const { archivo_confirmacion: imagenes, id_sesion } = sesionActiva

  const getContenidoArchivo = archivo => {
    const form = new FormData();
    form.append('archivo[]', archivo);
    form.append('id_sesion', id_sesion)

    dispatch( agregarNuevaImagen(form, idCapacitacion) )
  }

  return ( 
    <div className="images-confirmacion">
      <p className='acciones-tabla-control__subtitle'>
        Copia del correo de confirmación (solo imágenes) - Usar el botón de captura de pantalla del teclado (Impr Pant), luego buscar la imagen en la carpeta "Capturas de Pantalla"
      </p>
      <DropZone
        getContenidoArchivo={getContenidoArchivo}
      />
      <div className="images-confirmacion__content">
        {imagenes.map((imagen, index) => (
          <ImagenConfirmacion 
            key={index}
            index={index}
            imagen={imagen}
            imagenes={imagenes}
          />
        ))}
      </div>
    </div>
   );
}
 
export default ImagesConfirmacion;
