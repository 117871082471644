import React from 'react';
import useGetDocente from '../../hooks/useGetDocente';

//Redux
import { useDispatch } from 'react-redux';
import { editarRegistroDocente } from '../../actions/profesoresActions';

const withEditarRegistroDocente = WrappedComponent => props => {

  const dispatch = useDispatch();
  const [docente, idDocente] = useGetDocente();

  const handleEditar = datos => {
    dispatch( editarRegistroDocente(datos, idDocente) );
  }

  return ( 
    <WrappedComponent
      {...props}
      docenteState={docente}
      handleSubmitAction={handleEditar}
      editar={true}
    />
  );
}
 
export default withEditarRegistroDocente;