import React, { useState} from 'react';
import Sesiones from './listas/Sesiones'
import Alerta from "../../../../components/AvisosMensajes/Alerta";
import { limpiarForm} from '../../../../../helpers/functions'
import Error from '../../../../components/AvisosMensajes/Error'
//REDUX 
import {connect} from 'react-redux'
import { useEffect } from 'react';
import { insertarSesionesManual} from '../../../../../actions/cursosActions'

const TablaNombreSesiones = ({ sesionesCursoImport, insertarSesionesManual, idCurso}) => {

    const [arrayNombreSesion, setArrayNombreSesion] = useState([])
    const [idCursoActual, setIdCursoActual] = useState()
    const [error, setError] = useState()

    const insertarNuevoSesionMasivo = e => {
        e.preventDefault()
        if (arrayNombreSesion.length < 1) {
            setError(true)
            return
        }
        //CONSTRUIR EL DATA
        const dataInsercionMasiva = {
            id_curso: idCurso,
            array_nombre_sesion: arrayNombreSesion
        }
        //ENVIAR Y GUARDAR
        insertarSesionesManual(dataInsercionMasiva)
        limpiarForm('form-insertar-sesiones')
    }

    const limpiar = e => {
        e.preventDefault();
        setArrayNombreSesion([])
        limpiarForm('form-insertar-sesiones')
    }

    
    return (
        <div>
            <h3>Tabla sesiones</h3>
            <form onSubmit={e => insertarNuevoSesionMasivo(e)} id="form-insertar-sesiones">
            {sesionesCursoImport.length !== 0 ?
                <React.Fragment>
                    <ul className="list-group list-group-flush">
                            {sesionesCursoImport.map(sesion => (
                                <Sesiones
                                    key={sesion.id_sesion}
                                    sesion={sesion}
                                    setArrayNombreSesion={setArrayNombreSesion}
                                    arrayNombreSesion={arrayNombreSesion}
                                />
                            ))}
                    </ul>
                    {error ? 
                    error ? 
                                <Error mensaje={'No se selecciono ningun elemento'} />

                : null:null}

                    <div className="content-botones mt-3">
                        <button type="submit" className="btn btn-danger mr-2">Agregar</button>
                        <button type="button" onClick={e => limpiar(e)}className="btn btn-dark">Cancelar</button>
                    </div>
                </React.Fragment>
                : <Alerta mensaje={'Seleccione un curso'} />}
                
            </form>
            
        </div>
     );
}

const mapStateToProps = state => ({
    sesionesCursoImport: state.cursos.sesionesCursoImport
})

export default connect(mapStateToProps, { insertarSesionesManual})(TablaNombreSesiones);